import React, { useCallback, useState } from "react";
import { MailOutlined } from "@ant-design/icons";
import { Input, Card, Form, Button } from "antd";
import styled from "styled-components";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { useSelector } from "react-redux";

import AccountService from "../../services/account";
import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";
import useMainTheme from "../../hooks/useMainTheme";

import { errorNotification, successNotification } from "../../lib/utils/notification";
import { required, max32characters, az09 } from "../../lib/utils/formValidation";
import logoUrl from "../../lib/images/logo.png";
import logoLightUrl from "../../lib/images/logo-light.png";

import ReturnSignInFooter from "../../components/ReturnSignInFooter";
import TurnstileValidation from "../../components/TurnstileValidation";

const { Item } = Form;

const translations = defineMessages({
  userData: {
    id: "PasswordForgotForm.userData",
    defaultMessage: "Please input your username or email address",
  },
  notValidEmail: {
    id: "general.notValidEmail",
    defaultMessage: "Invalid email format",
  },
  returnSignInLinkTitle: {
    id: "PasswordForgotForm.returnSignInLinkTitle",
    defaultMessage: "Back to",
  },
});

const PasswordForgotForm = () => {
  const { isDarkTheme } = useMainTheme();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { resetFields } = form;

  const [disabledSend, setDisabledSend] = useState(true);
  const [userDataValue, setUserDataValue] = useState("");
  const turnstileToken = useSelector(ACCOUNT_SELECTORS.getTurnstileToken);

  const handleFinish = useCallback(
    async (formData) => {
      AccountService.forgotPassword(
        { ...formData, turnstileToken },
        {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
        }
      );
      resetFields();
    },
    [formatMessage, resetFields, turnstileToken]
  );

  const onChange = (event) => {
    if (event.target.value) {
      setUserDataValue(event.target.value);
      setDisabledSend(false);
    } else {
      setUserDataValue("");
      setDisabledSend(true);
    }
  };

  const validationRules = () => {
    if (userDataValue.includes("@") || userDataValue.includes(".")) {
      return [{ type: "email", message: formatMessage(translations.notValidEmail) }, required];
    }

    return [az09, max32characters, required];
  };

  return (
    <StyledFormContainer>
      <Card
        title={<FormattedMessage id="PasswordForgotForm.forgotPassword" defaultMessage="Forgot Password" />}
        extra={<img src={isDarkTheme ? logoUrl : logoLightUrl} alt="quickstream logo" width="200" />}
      >
        <StyledForm onFinish={handleFinish} form={form}>
          <Item name="userData" rules={validationRules()} onChange={onChange}>
            <Input prefix={<MailOutlined />} placeholder={formatMessage(translations.userData)} />
          </Item>
          <StyledButton type="primary" htmlType="submit" disabled={disabledSend} loading={!turnstileToken}>
            <FormattedMessage id="general.send" defaultMessage="Send email" />
          </StyledButton>

          <TurnstileValidation />
        </StyledForm>
      </Card>
      <ReturnSignInFooter linkTitle={formatMessage(translations.returnSignInLinkTitle)} />
    </StyledFormContainer>
  );
};

const StyledForm = styled(Form)`
  min-width: 400px;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
  margin: 20px 0 0 0;
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const PasswordForgotContainer = () => {
  return <PasswordForgotForm />;
};

export default PasswordForgotContainer;
