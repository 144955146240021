import React from "react";
import { Tabs, Row } from "antd";
import PropTypes from "prop-types";

import StatisticsView from "./StatisticsView";
import StatisticsGraph from "./StatisticsGraph";
import convertNodeStatsFormat from "../../../lib/utils/convertNodeStatsFormat";

import StatisticOutputTabName from "./StatisticOutputTabName";

const StatisticOutput = ({ statistics, channelId, type, isChannelStarted }) => {
  const channelData = {};

  if (statistics && statistics.length > 0) {
    statistics.forEach((statOutputArray) => {
      // eslint-disable-next-line no-unused-expressions
      statOutputArray &&
        statOutputArray.forEach((statistic) => {
          channelData[statistic.channelName] = [
            ...(channelData[statistic.channelName] ? channelData[statistic.channelName] : []),
            statistic,
          ];
        });
    });
  }

  const parsedTabPane = () => {
    return Object.entries(channelData).map((parsedChannel) => {
      const channelName = parsedChannel[0];
      const statData = parsedChannel[1];
      const parsedStatData = {};

      if (statData && statData.length > 0) {
        statData.forEach((statArray) => {
          parsedStatData[statArray.channelName] = [
            ...(parsedStatData[statArray.channelName] ? parsedStatData[statArray.channelName] : []),
            statArray,
          ];
        });
      }

      const { username, permissionId, statType } = statData[0];

      const newestChannelOutStats = statData[statData.length - 1];

      const newestStatsTime = newestChannelOutStats?.time;
      const filteredStatData = statData.filter((stat) => stat?.time === newestStatsTime);

      return {
        label: (
          <StatisticOutputTabName
            channelName={channelName}
            permissionId={permissionId}
            username={username}
            channelId={channelId}
          />
        ),
        key: channelName,
        children: (
          <Row justify="start" gutter={24}>
            <StatisticsGraph
              data={statData || []}
              type={statType === "stream" ? statType : type}
              channelId={channelId}
              isChannelStarted={isChannelStarted}
            />
            {newestChannelOutStats && <StatisticsView data={convertNodeStatsFormat(filteredStatData)} />}
          </Row>
        ),
      };
    });
  };

  return <Tabs items={parsedTabPane()} />;
};

StatisticOutput.propTypes = {
  channelId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  statistics: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        ccErrors: PropTypes.string,
        ccErrorsPerHour: PropTypes.string,
        ccErrorsTotal: PropTypes.string,
        channelId: PropTypes.string,
        channelName: PropTypes.string,
        cloudId: PropTypes.string,
        mbpsBandwidth: PropTypes.string,
        mbpsRecvRate: PropTypes.string,
        mbpsSendRate: PropTypes.string,
        msRtt: PropTypes.string,
        msTimestamp: PropTypes.string,
        permissionId: PropTypes.string,
        pktRcvBelated: PropTypes.string,
        pktRcvDrop: PropTypes.string,
        pktRcvLoss: PropTypes.string,
        pktRcvRetrans: PropTypes.string,
        pktRecv: PropTypes.string,
        pktRetrans: PropTypes.string,
        pktSent: PropTypes.string,
        pktSndDrop: PropTypes.string,
        pktSndLoss: PropTypes.string,
        time: PropTypes.number,
        username: PropTypes.string,
      })
    )
  ).isRequired,
  isChannelStarted: PropTypes.bool.isRequired,
};

export default StatisticOutput;
