import React, { useEffect, useState, useCallback } from "react";
import { Card, Spin, Row, Col, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import store from "../../../store";
import { selectors as NODE_SELECTORS, actions as NODE_ACTIONS } from "../../../ducks/node";
import { actions as LOADING_ACTIONS } from "../../../ducks/loadingData";
import { selectors as MQTT_SELECTORS } from "../../../ducks/mqtt";

import NodeV4ChannelsList from "../NodeV4ChannelsList";
import NodeV5ChannelsList from "../NodeV5ChannelsList";
import GlobalErrorBoundaries from "../../../components/ErrorBoundaries/GlobalErrorBoundaries";
import SearchInputChannelsList from "../../../components/SearchInputChannelsList";

import MQTTService from "../../../services/mqtt";
import useNodeStunAddress from "../../../hooks/useNodeStunAddress";
import useNodeChannels from "../../../hooks/useNodeChannels";
import useNodeCapabilities from "../../../hooks/useNodeCapabilities";
import useNodeVersion from "../../../hooks/useNodeVersion";
import useRequestNodeLiveStats from "../../../hooks/useRequestNodeLiveStats";

import { MQTT_STATUS, MESSAGE_TYPE } from "../../../lib/utils/constants";

const NodeDashboard = ({ hostName, MQTTConnection, MQTTStatus, nodes }) => {
  const [statisticChannelId, setStatisticChannelId] = useState();
  const { cwid } = useParams();

  useNodeStunAddress();
  useNodeCapabilities();
  useRequestNodeLiveStats();
  const nodeChannels = useNodeChannels();
  const { isV5Node, isV4Node } = useNodeVersion();

  useEffect(() => {
    const [...channelIds] = nodeChannels && nodeChannels.keys();
    const isChannelIdOnNodeChannels = nodeChannels.has(statisticChannelId);

    if (!statisticChannelId || !isChannelIdOnNodeChannels) {
      setStatisticChannelId(channelIds[0]);
    }
  }, [nodeChannels, statisticChannelId]);

  const nodeData = nodes.find((node) => node.cwid === cwid);
  const isConnection = MQTTConnection && MQTTStatus === MQTT_STATUS.CONNECTED;

  const getHostname = useCallback(() => {
    if (!hostName) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_HOST_NAME,
        },
      });

      store.dispatch(LOADING_ACTIONS.SET_LOADING(MESSAGE_TYPE.GET_HOST_NAME));
    }
  }, [cwid, hostName]);

  useEffect(() => {
    if (isConnection) {
      getHostname();
    }
  }, [MQTTConnection, MQTTStatus, getHostname, isConnection]);

  useEffect(() => {
    if (nodeData?.cwid) {
      store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    }
  }, [nodeData]);

  if (!isConnection) {
    return (
      <GlobalErrorBoundaries>
        <Row justify="center">
          <Col span={24}>
            <Spin
              tip={
                <FormattedMessage id="NodeDashboard.waitingForCloud" defaultMessage="Waiting for cloud connection" />
              }
            >
              <Skeleton active />
            </Spin>
          </Col>
        </Row>
      </GlobalErrorBoundaries>
    );
  }

  return (
    <GlobalErrorBoundaries>
      <Card title={<SearchInputChannelsList />}>
        {isV5Node && <NodeV5ChannelsList />}
        {isV4Node && <NodeV4ChannelsList />}
      </Card>
    </GlobalErrorBoundaries>
  );
};

NodeDashboard.propTypes = {
  MQTTStatus: PropTypes.string,
  hostName: PropTypes.string,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      cnn: PropTypes.string.isRequired,
      cwid: PropTypes.string.isRequired,
    })
  ).isRequired,
  MQTTConnection: PropTypes.bool,
};

NodeDashboard.defaultProps = {
  MQTTStatus: null,
  hostName: null,
  MQTTConnection: null,
};

const mapStateToProps = (state) => ({
  hostName: NODE_SELECTORS.getCnn(state),
  MQTTConnection: MQTT_SELECTORS.getMqttConnection(state),
  MQTTStatus: MQTT_SELECTORS.getStatus(state),
  nodes: MQTT_SELECTORS.getNodes(state),
});

export default connect(mapStateToProps, null)(NodeDashboard);
