import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import OrganizationsServices from "../../../../services/organizations";
import { selectors as ORGANIZATION_SELECTORS } from "../../../../ducks/organizations";

import { errorNotification } from "../../../../lib/utils/notification";

const { Item } = Form;
const { Option } = Select;

const VirtualChannelOrganization = () => {
  const { formatMessage } = useIntl();
  const [loadingData, setLoadingData] = useState(false);
  const organizationList = useSelector(ORGANIZATION_SELECTORS.getOrganizationsList);
  const publicDistributors = useSelector(ORGANIZATION_SELECTORS.getPublicDistributors);

  useEffect(() => {
    OrganizationsServices.getOrganizations({
      errorNotification: errorNotification(formatMessage),
      setLoadingData,
    });
  }, []);

  const handleNormalize = (value) => {
    if (!value) {
      return null;
    }

    return value;
  };

  return (
    <>
      <Item
        label={<FormattedMessage id="VirtualChannelOrganization.organization" defaultMessage="Organization" />}
        name="organizationId"
        normalize={handleNormalize}
      >
        <Select
          loading={loadingData}
          showSearch
          allowClear
          placeholder={<FormattedMessage id="general.pleaseSelect" defaultMessage="Please select" />}
          optionFilterProp="children"
        >
          {organizationList.map((organization) => (
            <Option value={organization.id} key={organization.id}>
              {organization.name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        label={<FormattedMessage id="VirtualChannelOrganization.distributor" defaultMessage="Distributor" />}
        name="distributorId"
        normalize={handleNormalize}
      >
        <Select
          loading={loadingData}
          showSearch
          allowClear
          placeholder={<FormattedMessage id="general.pleaseSelect" defaultMessage="Please select" />}
          optionFilterProp="children"
        >
          {publicDistributors.map((distributor) => (
            <Option value={distributor.id} key={distributor.id}>
              {distributor.name}
            </Option>
          ))}
        </Select>
      </Item>
    </>
  );
};

export default VirtualChannelOrganization;
