import { PhoneOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "antd";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useRole from "../../hooks/useRole";
import globalTranslation from "../../lib/translations";
import { ORGANIZATION_PROFILES, VALIDATION_STATUS } from "../../lib/utils/constants";
import COUNTRIES_LIST from "../../lib/utils/countries";
import {
  max220characters,
  max79characters,
  min2characters,
  required,
  trim,
  max130characters,
} from "../../lib/utils/formValidation";
import { errorNotification, successNotification } from "../../lib/utils/notification";
import OrganizationsServices from "../../services/organizations";
import OrganizationFormPublic from "./OrganizationFormPublic";

import { selectors as ADMIN_SELECTORS } from "../../ducks/admin";
import AdminService from "../../services/admin";
import UploadLogo from "../UploadLogo";

const { Item: FormItem } = Form;
const { TextArea } = Input;
const { Option } = Select;

const OrganizationForm = ({ setModalVisible, setOrganization, selectedOrganizationData }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { resetFields } = form;
  const [validationStatus, setValidationStatus] = useState(null);

  const [validationHelp, setValidationHelp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const { isAdmin, isSupport } = useRole();

  useEffect(() => {
    if (isAdmin || isSupport) {
      AdminService.getAccountList({ errorNotification: errorNotification(formatMessage) });
    }
  }, []);

  const accountList = useSelector(ADMIN_SELECTORS.getAccountList);

  const canChangeOrganizationOwner = () => {
    if (isAdmin) {
      return true;
    }

    if (isSupport) {
      return true;
    }

    return false;
  };

  const showOrganizationCreator = canChangeOrganizationOwner();

  const organizationName = Form.useWatch("name", form);

  const logoKey = !!selectedOrganizationData && selectedOrganizationData?.logoUrl?.split("/").slice(-3);

  const imageHash = Date.now();

  const initialLogo =
    selectedOrganizationData && selectedOrganizationData?.logoUrl
      ? [{ url: `${selectedOrganizationData.logoUrl}?${imageHash}`, key: logoKey && logoKey.join("/") }]
      : [];

  const [fileList, setFileList] = useState(initialLogo);

  const parsedSetFileList = (list) => {
    setFileList(list);
    setFormChanged(true);
  };

  const isEditMode = !!selectedOrganizationData;

  const { profiles, ...parsedOrganizationData } = selectedOrganizationData || {};
  const initialValues = selectedOrganizationData?.profiles ? selectedOrganizationData : parsedOrganizationData;

  const onFinish = (formData) => {
    const logoUrl =
      fileList.length > 0 && fileList[0]?.response
        ? fileList[0].response?.data?.url
        : (fileList.length > 0 && fileList[0]?.url?.split("?")[0]) || null;

    if (!formChanged && selectedOrganizationData.logoUrl === logoUrl) {
      setFileList([]);
      resetFields();
      setFormChanged(false);
      setModalVisible(false);

      return;
    }

    if (isEditMode) {
      OrganizationsServices.editOrganization(
        { ...formData, organizationId: selectedOrganizationData.id, logoUrl },
        {
          errorNotification: errorNotification(formatMessage),
          setModalVisible,
        }
      );
    } else {
      OrganizationsServices.createOrganization(
        { ...formData, logoUrl },
        {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
          setOrganization,
          setModalVisible,
        }
      );
    }

    setFileList([]);
    resetFields();
    setFormChanged(false);
  };

  const onUniqueValidation = (data) => {
    OrganizationsServices.uniqueValidation(data, {
      errorNotification: errorNotification(formatMessage),
      setValidationStatus,
      setValidationHelp,
    });
  };

  const debouncedSearch = useCallback(
    debounce((nextValue) => onUniqueValidation({ name: nextValue }), 600),
    []
  );

  const handleUniqueValidation = (event) => {
    event.persist();
    const { value } = event.target;

    const isTrimmed = trim.pattern.test(value);

    if (value === initialValues?.name) {
      return;
    }

    if (value && value.length > 1 && value.length <= 79 && isTrimmed) {
      setValidationStatus(VALIDATION_STATUS.VALIDATING);
      debouncedSearch(value);
    } else {
      setValidationHelp(false);
      setValidationStatus(VALIDATION_STATUS.ERROR);
    }
  };

  const handleChangedForm = () => {
    setFormChanged(true);
  };

  const handleNormalize = (value) => {
    if (!value) {
      return null;
    }

    return value;
  };

  return (
    <Form
      onValuesChange={handleChangedForm}
      name="OrganizationForm"
      onFinish={onFinish}
      autoComplete="off"
      initialValues={initialValues}
      form={form}
    >
      <Row gutter={[24, 12]}>
        <Col span={12}>
          <FormItem
            label={<FormattedMessage id="OrganizationForm.organizationName" defaultMessage="Organization name" />}
            name="name"
            rules={[required, min2characters, max130characters, trim]}
            hasFeedback
            validateStatus={validationStatus}
            help={
              validationStatus === VALIDATION_STATUS.ERROR && validationHelp ? (
                <FormattedMessage
                  id="OrganizationForm.organizationNameTaken"
                  defaultMessage="Organization name already taken"
                />
              ) : null
            }
          >
            <Input onChange={handleUniqueValidation} />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label={
              <FormattedMessage id="OrganizationForm.organizationProfiles" defaultMessage="Organization profiles" />
            }
            name="profiles"
          >
            <Select
              mode="multiple"
              placeholder={<FormattedMessage id="general.pleaseSelect" defaultMessage="Please select" />}
            >
              {ORGANIZATION_PROFILES.map(({ label, value }) => (
                <Option value={value} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label={<FormattedMessage id="OrganizationForm.organizationAddress1" defaultMessage="Address (line 1)" />}
            name="address1"
            rules={[min2characters, max220characters, trim]}
          >
            <Input />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label={<FormattedMessage id="OrganizationForm.organizationAddress2" defaultMessage="Address (line 2)" />}
            name="address2"
            rules={[min2characters, max220characters, trim]}
          >
            <Input />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem
            label={<FormattedMessage id="OrganizationForm.organizationCountry" defaultMessage="Country" />}
            name="country"
            normalize={handleNormalize}
          >
            <Select
              allowClear
              showSearch
              placeholder={<FormattedMessage id="general.pleaseSelect" defaultMessage="Please select" />}
              optionFilterProp="children"
            >
              {COUNTRIES_LIST.map((country) => (
                <Option value={country.code} key={country.code}>
                  {country.name} ({country.code})
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label={<FormattedMessage id="OrganizationForm.organizationTaxId" defaultMessage="Tax ID" />}
            name="taxId"
            rules={[min2characters, max79characters, trim]}
          >
            <Input />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label={<FormattedMessage id="OrganizationForm.phone" defaultMessage="Phone" />}
            name="phone"
            rules={[min2characters, max79characters, trim]}
          >
            <Input addonBefore={<PhoneOutlined />} />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            name="description"
            label={<FormattedMessage id="OrganizationForm.description" defaultMessage="Description" />}
          >
            <TextArea rows={3} showCount maxLength={1000} />
          </FormItem>
        </Col>
        <Col span={24}>
          <UploadLogo
            setFileList={parsedSetFileList}
            fileList={fileList}
            organizationName={organizationName}
            setLoading={setLoading}
          />
        </Col>

        {showOrganizationCreator && (
          <>
            <Col span={20}>
              <FormItem label={<FormattedMessage id="OrganizationForm.owner" defaultMessage="Owner" />} name="creator">
                <Select
                  showSearch
                  placeholder={<FormattedMessage id="general.pleaseSelect" defaultMessage="Please select" />}
                  optionFilterProp="children"
                >
                  {accountList.map((account) => (
                    <Option value={account.id} key={account.id}>
                      {account.username} ({account.role})
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </>
        )}
        <Col span={24}>
          <OrganizationFormPublic />
        </Col>
      </Row>

      <StyledSubmit>
        <Button
          type="primary"
          htmlType="submit"
          icon={<SaveOutlined />}
          disabled={loading || (!!validationStatus && validationStatus !== VALIDATION_STATUS.SUCCESS)}
          loading={loading}
        >
          <span>{isEditMode ? formatMessage(globalTranslation.save) : formatMessage(globalTranslation.create)}</span>
        </Button>
      </StyledSubmit>
    </Form>
  );
};

const StyledSubmit = styled(FormItem)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }
`;

OrganizationForm.propTypes = {
  setModalVisible: PropTypes.func.isRequired,
  setOrganization: PropTypes.func,
  selectedOrganizationData: PropTypes.shape({
    address: PropTypes.string,
    country: PropTypes.string,
    creator: PropTypes.number,
    id: PropTypes.number,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    profiles: PropTypes.arrayOf(PropTypes.string),
    taxId: PropTypes.string,
    status: PropTypes.string,
  }),
};

OrganizationForm.defaultProps = {
  selectedOrganizationData: null,
  setOrganization: null,
};

export default OrganizationForm;
