import React, { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import { Col, Button, Row } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";

import { GRAPH_OPTIONS, GRAPH_DATASET_OPTIONS } from "../../../../lib/style/theme";

const NodeSystemStatsGraph = ({ data, label }) => {
  const statRef = useRef(null);
  useEffect(() => {
    Chart.register(zoomPlugin);
  }, []);

  const handleResetZoom = () => {
    statRef.current.resetZoom();
  };

  const parsedData = {
    labels: data.map((statistic) => statistic.time),
    datasets: [
      {
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        data: data.map((statistic) => statistic.value),
        label: `${label} [%]`,
        pointBorderColor: "rgba(75,192,192,1)",
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        ...GRAPH_DATASET_OPTIONS,
      },
    ],
  };

  return (
    <>
      <Row justify="end">
        <Col>
          <Button type="primary" onClick={handleResetZoom}>
            <FormattedMessage id="NodeSystemStatsGraph.resetZoom" defaultMessage="Reset zoom" />
          </Button>
        </Col>
      </Row>
      <Line data={parsedData} options={GRAPH_OPTIONS} ref={statRef} />
    </>
  );
};

NodeSystemStatsGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      cup: PropTypes.number,
      ram: PropTypes.number,
    })
  ).isRequired,
};

NodeSystemStatsGraph.defaultProps = {};

export default NodeSystemStatsGraph;
