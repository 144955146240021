import { useEffect } from "react";
import { useSelector } from "react-redux";

import store from "../store";

import MQTTService from "../services/mqtt";
import { selectors as NODE_SELECTORS } from "../ducks/node";
import { actions as LOADING_ACTIONS } from "../ducks/loadingData";

import { MESSAGE_TYPE } from "../lib/utils/constants";

function useNodeStunAddress() {
  const cwid = useSelector(NODE_SELECTORS.getNodeCwid);
  const stundAddress = useSelector(NODE_SELECTORS.getStundAddress);

  useEffect(() => {
    const requestStunAddress = () => {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.STUND_ADDRESS,
        },
      });

      store.dispatch(LOADING_ACTIONS.SET_LOADING(MESSAGE_TYPE.STUND_ADDRESS));
    };

    const isConnection = cwid;

    if (isConnection) {
      requestStunAddress();
    }
  }, [cwid]);

  return stundAddress;
}

export default useNodeStunAddress;
