import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { SELECT_AWS_REGIONS } from "../../../lib/utils/constants";

export default function NodesListCloudNodeTooltip({ region }) {
  const regionData = SELECT_AWS_REGIONS.find((selectorRegion) => selectorRegion.value === region);

  return (
    <>
      <StyledCenterText>
        <FormattedMessage id="NodesListCloudNodeTooltip.cloudNode" defaultMessage="Cloud node" />
      </StyledCenterText>
      <div>
        <FormattedMessage id="NodesListCloudNodeTooltip.region" defaultMessage="Region" />: {regionData?.label}
      </div>
    </>
  );
}

const StyledCenterText = styled.div`
  text-align: center;
`;
