import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import store from "../store";

import MQTTService from "../services/mqtt";
import { selectors as NODE_CHANNEL_SELECTORS, actions as NODE_CHANNEL_ACTIONS } from "../ducks/nodeChannels";
import { selectors as MQTT_SELECTORS } from "../ducks/mqtt";

import { MESSAGE_TYPE, MQTT_STATUS } from "../lib/utils/constants";

function useNodeChannels() {
  const { cwid } = useParams();
  const MQTTStatus = useSelector(MQTT_SELECTORS.getStatus);
  const MQTTConnection = useSelector(MQTT_SELECTORS.getMqttConnection);
  const isConnection = MQTTConnection && MQTTStatus === MQTT_STATUS.CONNECTED;
  const nodeChannels = useSelector(NODE_CHANNEL_SELECTORS.getChannels);
  const noNodeChannels = nodeChannels.size === 0;

  useEffect(() => {
    const getNodeChannels = () => {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_NODE_CHANNELS,
        },
      });
    };

    if (isConnection && noNodeChannels) {
      store.dispatch(NODE_CHANNEL_ACTIONS.SET_LOADING_NODE_CHANNELS(true));
      getNodeChannels();
    }
  }, [isConnection, cwid, noNodeChannels]);

  return nodeChannels;
}

export default useNodeChannels;
