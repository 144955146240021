import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../../ducks/cloudChannels";
import CloudChannelsServices from "../../../services/cloudChannels";

import { CHANNEL_SOURCE } from "../../../lib/utils/constants";
import { errorNotification } from "../../../lib/utils/notification";

const DetailSectionPermissionLabel = ({ channelId, permissions, permissionId, outputId, outputsConfigs, info }) => {
  const { cwid } = useParams();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (permissionId > 0) {
      const permissionDataExist = permissions && !!permissions[channelId];
      if (!permissionDataExist) {
        const cloudId = permissionId.toString().slice(0, 9);

        CloudChannelsServices.getChannelPermissionsDescription(
          { cloudIds: `[${cloudId}]`, channelId, cwid },
          {
            errorNotification: errorNotification(formatMessage),
          }
        );
      }
    }
  }, []);

  if (permissionId <= +CHANNEL_SOURCE.nodeChannel) {
    const output = outputsConfigs.find((config) => config.outputId === outputId);

    return (
      <>
        {output?.outputName}
        {info && <> ({info})</>}
      </>
    );
  }

  const permissionDataExist = permissions && !!permissions[channelId];
  if (permissionDataExist) {
    const permissionDescriptionArray = permissions[channelId];
    const descriptionData = permissionDescriptionArray.find((permission) => +permission.permissionId === +permissionId);

    return `${descriptionData?.description} (${descriptionData?.username} - ${info})`;
  }

  return `${permissionId} - ${info}`;
};

DetailSectionPermissionLabel.propTypes = {
  permissionId: PropTypes.number,
  permissions: PropTypes.object,
  outputId: PropTypes.number.isRequired,
  info: PropTypes.string.isRequired,
};

DetailSectionPermissionLabel.defaultProps = {
  permissionId: null,
  permissions: null,
};

export const mapStateToProps = (state) => ({
  permissions: CLOUD_CHANNELS_SELECTORS.getChannelPermissions(state),
});

export default connect(mapStateToProps)(DetailSectionPermissionLabel);
