import { ROLE } from "./constants";
import themeColor from "../style/theme";

const roleColors = (role) => {
  switch (role) {
    case ROLE.ADMIN:
      return themeColor.defaultBlueColor;
    case ROLE.SUPPORT:
      return themeColor.green;
    default:
      return themeColor.orange;
  }
};

export default roleColors;
