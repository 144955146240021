import React from "react";
import { Col, Form, Select } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { required } from "../../../../../../../../lib/utils/formValidation";
import { MPEG2AUDIO_BITRATE, AAC_PROFILE } from "../../../../../../../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

function AACCodec({ fieldPrefix, disabled }) {
  const TRANSCODED_MPEG2AUDIO_BITRATE = MPEG2AUDIO_BITRATE.slice(1);

  return (
    <>
      <Col span={8}>
        <Item
          name={[...fieldPrefix, "b"]}
          label={<FormattedMessage id="Mpeg2AudioCodec.bitrate" defaultMessage="Bitrate" />}
          rules={[required]}
        >
          <Select disabled={disabled}>
            {TRANSCODED_MPEG2AUDIO_BITRATE.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>

      <Col span={8}>
        <Item
          name={[...fieldPrefix, "aacProfile"]}
          label={<FormattedMessage id="Mpeg2AudioCodec.profile" defaultMessage="Profile" />}
          rules={[required]}
        >
          <Select disabled={disabled}>
            {AAC_PROFILE.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
    </>
  );
}

AACCodec.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

export default AACCodec;
