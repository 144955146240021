import React, { useCallback } from "react";
import { Col, Form, Select } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import {
  MPEG2VIDEO_PIX_FORMAT,
  OPEN_H264_PIX_FORMAT,
  SELECT_STREAM_CODEC_AUDIO,
  SELECT_STREAM_CODEC,
  STREAMS_TYPE,
  X264_PIX_FORMAT,
  LICENSES_TYPES,
  OUTPUTS_TYPES,
  OUTPUT_RTMP_PIX_FORMAT,
} from "../../../../lib/utils/constants";
import { required } from "../../../../lib/utils/formValidation";
import changeCodecType from "../../../../lib/utils/changeCodecType";
import codecCopyDisabled from "../../../../lib/utils/codecCopyDisabled";

import GeneralVideoCodec from "./GeneralVideoCodec";
import Mpeg2AudioCodec from "./Mpeg2AudioCodec";
import Mpeg2VideoCodec from "./Mpeg2VideoCodec";
import X264VideoCodec from "./X264VideoCodec";
import X264VideoCodecAdvanced from "./X264VideoCodecAdvanced";
import AACCodec from "./AACCodec";

const { Item } = Form;
const { Option } = Select;

const StreamCodec = ({ disabled, inputType, fieldPrefix, form, prefix, prePrefix }) => {
  const { setFieldValue, getFieldValue } = form;
  const outputType = Form.useWatch([prefix[0], prefix[1], "type"], form);
  const isOutputRtmp = outputType === OUTPUTS_TYPES.outputRtmp.value;
  const streamCodecType = Form.useWatch([...prefix, "type"], form);

  const isAudioStream = isOutputRtmp && prefix[2] === "audioStream";
  const isVideoStream = isOutputRtmp && prefix[2] === "videoStream";

  const licenseId = getFieldValue(["licenseId"]);
  const isCloudLicense = licenseId === LICENSES_TYPES.CLOUD;

  const streamType = Form.useWatch([...prePrefix, "type"], form);

  const isSelectedVideoType = streamType === STREAMS_TYPE[0].value;
  const isSelectedAudioType = streamType === STREAMS_TYPE[1].value;

  const handleChangeCodecType = (codecType) => {
    changeCodecType({ codecType, prefix, setFieldValue, isOutputRtmp });
  };

  const isMpeg2videoType = streamCodecType === SELECT_STREAM_CODEC[1].value;
  const isMpeg2audioType = streamCodecType === SELECT_STREAM_CODEC_AUDIO[1].value;
  const isAACType = streamCodecType === SELECT_STREAM_CODEC_AUDIO[2].value;
  const isOpenH264Type = streamCodecType === SELECT_STREAM_CODEC[2].value;
  const isH264Type = streamCodecType === SELECT_STREAM_CODEC[3].value;
  const showVideoCodec = isSelectedVideoType && (isMpeg2videoType || isOpenH264Type || isH264Type);

  const setAdditionalBasicFields = useCallback(() => {
    switch (streamCodecType) {
      case SELECT_STREAM_CODEC[1].value:
        return <Mpeg2VideoCodec fieldPrefix={fieldPrefix} disabled={disabled} />;
      case SELECT_STREAM_CODEC[3].value:
        return (
          <X264VideoCodec
            prefix={prefix}
            fieldPrefix={fieldPrefix}
            disabled={disabled}
            form={form}
            isOutputRtmp={isOutputRtmp}
          />
        );
      default:
        return null;
    }
  }, [streamCodecType, fieldPrefix, disabled, form]);

  const setAdditionalAdvancedFields = useCallback(() => {
    switch (streamCodecType) {
      case SELECT_STREAM_CODEC[3].value:
        return <X264VideoCodecAdvanced fieldPrefix={fieldPrefix} disabled={disabled} />;
      default:
        return null;
    }
  }, [streamCodecType, disabled]);

  const setPixelFormat = () => {
    if (isOutputRtmp) {
      return OUTPUT_RTMP_PIX_FORMAT;
    }

    switch (streamCodecType) {
      case SELECT_STREAM_CODEC[1].value:
        return MPEG2VIDEO_PIX_FORMAT;
      case SELECT_STREAM_CODEC[2].value:
        return OPEN_H264_PIX_FORMAT;
      case SELECT_STREAM_CODEC[3].value:
        return X264_PIX_FORMAT;
      default:
        return [];
    }
  };

  const RTMP_VIDEO_STREAM_CODECS = [SELECT_STREAM_CODEC[2], SELECT_STREAM_CODEC[3]];

  return (
    <>
      <Col span={24}>
        <Item
          name={[...fieldPrefix, "type"]}
          label={<FormattedMessage id="StreamCodec.codec" defaultMessage="Codec" />}
          rules={[required]}
        >
          <Select onChange={handleChangeCodecType} disabled={disabled}>
            {isOutputRtmp &&
              isVideoStream &&
              RTMP_VIDEO_STREAM_CODECS.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            {isSelectedVideoType &&
              SELECT_STREAM_CODEC.map((option) => (
                <Option
                  key={option.value}
                  value={option.value}
                  // disabled "Copy" codec for RMTP
                  disabled={
                    (!isCloudLicense && option.value === SELECT_STREAM_CODEC[3].value) ||
                    (codecCopyDisabled({ outputType, inputType }) && option.value === SELECT_STREAM_CODEC[0].value)
                  }
                >
                  {!isCloudLicense && option.value === SELECT_STREAM_CODEC[3].value ? (
                    <>
                      {option.label} (
                      <FormattedMessage
                        id="general.availableCloudLicense"
                        defaultMessage="Available only for cloud license"
                      />
                      )
                    </>
                  ) : (
                    option.label
                  )}
                </Option>
              ))}
            {isOutputRtmp && isAudioStream && (
              <Option key={SELECT_STREAM_CODEC_AUDIO[2].value} value={SELECT_STREAM_CODEC_AUDIO[2].value}>
                {SELECT_STREAM_CODEC_AUDIO[2].label}
              </Option>
            )}
            {isSelectedAudioType &&
              SELECT_STREAM_CODEC_AUDIO.map((option) => (
                <Option
                  key={option.value}
                  value={option.value}
                  disabled={
                    (!isCloudLicense && option.value === SELECT_STREAM_CODEC_AUDIO[2].value) ||
                    (codecCopyDisabled({ outputType, inputType }) && option.value === SELECT_STREAM_CODEC[0].value)
                    // (isRtmpOutput && option.value !== SELECT_STREAM_CODEC_AUDIO[2].value)
                  }
                >
                  {!isCloudLicense && option.value === SELECT_STREAM_CODEC_AUDIO[2].value ? (
                    <>
                      {option.label} (
                      <FormattedMessage
                        id="general.availableCloudLicense"
                        defaultMessage="Available only for cloud license"
                      />
                      )
                    </>
                  ) : (
                    option.label
                  )}
                </Option>
              ))}
          </Select>
        </Item>
      </Col>
      {(showVideoCodec || isVideoStream) && (
        <GeneralVideoCodec
          additionalBasicFields={setAdditionalBasicFields()}
          additionalAdvancedFields={setAdditionalAdvancedFields()}
          disabled={disabled}
          disableInterlaced={isOpenH264Type || isOutputRtmp}
          isOutputRtmp={isOutputRtmp}
          prefix={prefix}
          fieldPrefix={fieldPrefix}
          pixelFormat={setPixelFormat()}
          form={form}
        />
      )}
      {(isSelectedAudioType || isAudioStream) && isMpeg2audioType && (
        <Mpeg2AudioCodec disabled={disabled} fieldPrefix={fieldPrefix} />
      )}
      {(isSelectedAudioType || isAudioStream) && isAACType && (
        <AACCodec disabled={disabled} fieldPrefix={fieldPrefix} />
      )}
    </>
  );
};

StreamCodec.propTypes = {
  disabled: PropTypes.bool.isRequired,
  inputType: PropTypes.string,
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  form: PropTypes.object.isRequired,
};

StreamCodec.defaultProps = {
  inputType: null,
};

export default StreamCodec;
