import React from "react";
import { Form, Button, InputNumber, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import LicenseServices from "../../../../services/license";

import { required } from "../../../../lib/utils/formValidation";
import { errorNotification, successNotification } from "../../../../lib/utils/notification";

const { Item } = Form;

const ExtendLicense = ({ selectedModalLicense, handleCloseModal }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const { resetFields } = form;

  const handleFinish = async ({ expiryDays }) => {
    await LicenseServices.extendLicense(
      { expiryDays, licenseId: selectedModalLicense },
      {
        errorNotification: errorNotification(formatMessage),
        successNotification: successNotification(formatMessage),
      }
    );
    await LicenseServices.getLicenseList({ errorNotification: errorNotification(formatMessage) });
    resetFields();
    handleCloseModal();
  };

  return (
    <Form form={form} onFinish={handleFinish}>
      <Item
        name="expiryDays"
        label={<FormattedMessage id="ExtendLicense.expiryDays" defaultMessage="Add number of days to expire" />}
        rules={[required]}
      >
        <InputNumber min={1} max={9999} type="number" />
      </Item>
      <Row justify="end">
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="ExtendLicense.submit" defaultMessage="Extend license" />
        </Button>
      </Row>
    </Form>
  );
};

ExtendLicense.propTypes = {
  selectedModalLicense: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
};

ExtendLicense.defaultProps = {
  selectedModalLicense: null,
};

export default ExtendLicense;
