import React from "react";
import moment from "moment";
import { Row, Col, Tooltip, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { LICENSES_TYPES, PERMANENT_LICENSE } from "../../../../lib/utils/constants";
import themeColor from "../../../../lib/style/theme";

const LicenseListLicenseExpire = ({ record, isAdmin, handleShowModal, setShowModal }) => {
  if (record?.licenseExpire === PERMANENT_LICENSE) {
    return <span>{record?.licenseExpire}</span>;
  }

  if (record.licenseType === LICENSES_TYPES.FREE) {
    return PERMANENT_LICENSE;
  }

  if (moment().isAfter(record?.licenseExpire)) {
    return (
      <span>
        <Row justify="space-between" align="middle">
          <Col>
            {record?.licenseExpire}{" "}
            <Tooltip title={<FormattedMessage id="LicenseList.licenseExpired" defaultMessage="License expired" />}>
              <ExclamationCircleOutlined style={{ color: `${themeColor.darkRed}` }} />
            </Tooltip>
          </Col>
          {isAdmin && (
            <Col>
              <Button type="primary" onClick={() => handleShowModal(record.licenseId, setShowModal)}>
                <FormattedMessage id="LicenseList.extend" defaultMessage="Extend" />
              </Button>
            </Col>
          )}
        </Row>
      </span>
    );
  }

  return (
    <Row justify="space-between" align="middle">
      <Col>{record?.licenseExpire}</Col>
      {isAdmin && (
        <Col>
          <Button type="primary" onClick={() => handleShowModal(record.licenseId, setShowModal)}>
            <FormattedMessage id="LicenseList.extend" defaultMessage="Extend" />
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default LicenseListLicenseExpire;
