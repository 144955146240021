import React from "react";
import styled from "styled-components";
import { Col, Popconfirm, Switch, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";

import store from "../../store";

import { selectors as ACCOUNT_SELECTORS, actions as ACCOUNT_ACTIONS } from "../../ducks/account";
import AccountService from "../../services/account";

import globalTranslation from "../../lib/translations";
import { errorNotification, successNotification } from "../../lib/utils/notification";
import themeColor from "../../lib/style/theme";

export default function EmailOptIn() {
  const { formatMessage } = useIntl();
  const emailOptIn = !!useSelector(ACCOUNT_SELECTORS.getEmailOptIn);

  const onConfirmChangeEmailOptIn = async () => {
    const optInChangeSuccess = await AccountService.editEmailOptIn(
      { emailOptIn: !emailOptIn },
      {
        errorNotification: errorNotification(formatMessage),
        successNotification: successNotification(formatMessage),
      }
    );
    if (optInChangeSuccess) {
      store.dispatch(ACCOUNT_ACTIONS.SET_EMAIL_OPTIN(!emailOptIn));
    }
  };

  return (
    <StyledCenterCol span={24}>
      <StyledBold>
        <FormattedMessage id="account.emailOptIn" defaultMessage="Email opt-in" />:{" "}
      </StyledBold>
      <Popconfirm
        placement="top"
        title={
          <FormattedMessage
            id="account.emailOptInChange"
            defaultMessage="Are you sure you want to change email opt-in status?"
          />
        }
        onConfirm={onConfirmChangeEmailOptIn}
        okText={formatMessage(globalTranslation.yes)}
        cancelText={formatMessage(globalTranslation.no)}
      >
        <Switch
          checkedChildren={<FormattedMessage id="general.on" defaultMessage="On" />}
          unCheckedChildren={<FormattedMessage id="general.off" defaultMessage="Off" />}
          checked={emailOptIn}
        />{" "}
      </Popconfirm>
      <StyledTooltip
        placement="top"
        title={
          <>
            <FormattedMessage
              id="account.emailOptInDescription"
              // eslint-disable-next-line max-len
              defaultMessage="We will send you only important information related to Quickstream. We promise to respect your inbox and send"
            />
            <StyledText>
              <FormattedMessage id="EmailOptIn.max1Email" defaultMessage="maximum 1 email per month" />
            </StyledText>
            .
          </>
        }
      >
        <QuestionCircleFilled style={{ fontSize: "18px" }} />
      </StyledTooltip>
    </StyledCenterCol>
  );
}

const StyledTooltip = styled(Tooltip)`
  margin: 0 5px;
`;

const StyledBold = styled.b`
  min-width: 100px;
  display: inline-block;
  margin-right: 15px;
`;

const StyledCenterCol = styled(Col)`
  margin: 5px 0px;
  display: flex;
  align-items: center;
`;

const StyledText = styled.span`
  margin: 0 0 0 5px;
  color: ${themeColor.orange};
`;
