import React from "react";
import { Divider, Col, Form, Select, Switch, Row } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";

import {
  MPEG2VIDEO_PRESETS,
  SELECT_DEFAULT_AUTO,
  SELECT_MPEG2VIDEO_PRESETS,
  SELECT_STREAM_CODEC_FPS,
  SELECT_STREAM_CODEC_RESIZE,
  X264_PROFILE,
  X264_PIX_FORMAT,
  SELECT_STREAM_CODEC,
} from "../../../../../lib/utils/constants";
import { required } from "../../../../../lib/utils/formValidation";
import globalTranslations from "../../../../../lib/translations";
import mpeg2VideDefaultValues from "../../../../../lib/utils/defaultValues/mpeg2VideoDefaultValues";

import InputNumberField from "../../../../Fields/InputNumberField";
import VideoCodecBitrateField from "../../../../VideoCodecBitrateField";
import PixFormatLabel from "./PixFormatLabel";
import VideoCodecGOPLengthField from "../../../../VideoCodecGOPLengthField";
import AdvancedSettings from "../../../../AdvancedSettings";

const { Item } = Form;
const { Option } = Select;

const translations = defineMessages({
  bitrate: {
    id: "GeneralVideoCodec.bitrate",
    defaultMessage: "Bitrate [kbps]",
  },
  sarNum: {
    id: "GeneralVideoCodec.sarNum",
    defaultMessage: "Video Sample Ratio (sarNum)",
  },
  sarDen: {
    id: "GeneralVideoCodec.sarDen",
    defaultMessage: "Video Sample Ratio (sarDen)",
  },
  gopStructure: {
    id: "GeneralVideoCodec.gopStructure",
    defaultMessage: "B-Frames [frames]",
  },
});

const HiQualityPresets = [
  SELECT_MPEG2VIDEO_PRESETS[0].value,
  SELECT_MPEG2VIDEO_PRESETS[1].value,
  SELECT_MPEG2VIDEO_PRESETS[2].value,
];

const GeneralVideoCodec = ({
  disabled,
  disableInterlaced,
  prefix,
  fieldPrefix,
  additionalBasicFields,
  additionalAdvancedFields,
  pixelFormat,
  form,
  isOutputRtmp,
}) => {
  const { getFieldValue, setFieldValue } = form;

  const { formatMessage } = useIntl();
  const initialInterlaced = Form.useWatch([...prefix, "interlaced"], form) || false;
  const initialChangeGOP = Form.useWatch([...prefix, "changeGOP"], form) || false;

  const selectedCodecResize = Form.useWatch([...prefix, "resize"], form);
  const selectedCustomSar = !!Form.useWatch([...prefix, "customSar"], form);
  const isSelectedCodecResizeAuto = selectedCodecResize === SELECT_DEFAULT_AUTO.value;
  const selectedCodecPresetId = Form.useWatch([...prefix, "presetId"], form);
  const isSwitchChangeGOP = Form.useWatch([...prefix, "changeGOP"], form);
  const gValue = Form.useWatch([...prefix, "g"], form);

  const isSelectedCodecCustomPresetId = selectedCodecPresetId === SELECT_MPEG2VIDEO_PRESETS[7].value;
  const isSelectedCodecHIPresetId = HiQualityPresets.includes(selectedCodecPresetId);

  const x264ProfileValue = Form.useWatch([...prefix, "x264Profile"], form);
  const selectedCodecType = Form.useWatch([...prefix, "type"], form);
  const isNDICodecType = selectedCodecType === SELECT_STREAM_CODEC[3].value;

  const handleSwitchChangeGOP = (value) => {
    const codec = getFieldValue(prefix);

    if (value) {
      const codecUpdate = {
        changeGOP: value,
        g: 25,
        bf: 0,
      };

      setFieldValue(prefix, { ...codec, ...codecUpdate });
    } else {
      delete codec.g;
      delete codec.bf;
      setFieldValue(prefix, { ...codec, changeGOP: value });
    }
  };

  const handleChangeCodecResize = (codecResize) => {
    const codec = getFieldValue(prefix);
    delete codec.width;
    delete codec.height;
    delete codec.sarNum;
    delete codec.sarDen;
    delete codec.presetId;
    delete codec.customSar;

    if (codecResize !== SELECT_DEFAULT_AUTO.value) {
      const codecUpdate = {
        resize: codecResize,
        presetId: 1,
        width: MPEG2VIDEO_PRESETS.preset4K.width,
        height: MPEG2VIDEO_PRESETS.preset4K.height,
        customSar: MPEG2VIDEO_PRESETS.preset4K.customSar,
      };
      setFieldValue(prefix, { ...codec, ...codecUpdate });
    } else {
      const codecUpdate = {
        resize: codecResize,
      };
      setFieldValue(prefix, { ...codec, ...codecUpdate });
    }
  };

  const handleChangePresetId = (presetId) => {
    mpeg2VideDefaultValues({ prefix, presetId, getFieldValue, setFieldValue });
  };

  const handleChangeCustomSar = (value) => {
    const codec = getFieldValue(prefix);
    delete codec.sarDen;
    delete codec.sarNum;

    setFieldValue(prefix, { ...codec, customSar: value });
  };

  const handleChangePixFormat = (selectedPixFormat) => {
    if (isNDICodecType) {
      // if (
      //   [X264_PIX_FORMAT[4].value, X264_PIX_FORMAT[5].value].includes(selectedPixFormat) &&
      //   x264ProfileValue !== X264_PROFILE[1].value
      // ) {
      //   const codec = getFieldValue(prefix);
      //   codec.x264Profile = X264_PROFILE[1].value;

      //   setFieldValue(prefix, { ...codec, x264Profile: X264_PROFILE[1].value });
      // }

      // update profile for YUV422P pixel format
      if (selectedPixFormat === X264_PIX_FORMAT[2].value && x264ProfileValue !== X264_PROFILE[1].value) {
        const codec = getFieldValue(prefix);
        setFieldValue(prefix, { ...codec, x264Profile: X264_PROFILE[2].value });
      }

      // update profile for YUV444P pixel format
      if (selectedPixFormat === X264_PIX_FORMAT[3].value && x264ProfileValue !== X264_PROFILE[2].value) {
        const codec = getFieldValue(prefix);
        setFieldValue(prefix, { ...codec, x264Profile: X264_PROFILE[2].value });
      }
    }
  };

  return (
    <>
      {additionalBasicFields && additionalBasicFields}
      <Col span={8}>
        <Item
          name={[...fieldPrefix, "resize"]}
          label={<FormattedMessage id="GeneralVideoCodec.resize" defaultMessage="Scale video" />}
          rules={[required]}
        >
          <Select onChange={handleChangeCodecResize} disabled={disabled}>
            {SELECT_STREAM_CODEC_RESIZE.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={8}>
        <Item
          name={[...fieldPrefix, "fps"]}
          label={<FormattedMessage id="GeneralVideoCodec.fps" defaultMessage="Frame rate" />}
          rules={[required]}
        >
          <Select disabled={disabled}>
            {SELECT_STREAM_CODEC_FPS.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={8} style={isOutputRtmp ? { visibility: "hidden", display: "none" } : null}>
        <Item
          name={[...fieldPrefix, "pixFormat"]}
          label={<PixFormatLabel isNDICodecType={isNDICodecType} />}
          rules={[required]}
        >
          <Select disabled={disabled} onChange={handleChangePixFormat}>
            {pixelFormat.map((option) => (
              <Option
                key={option.value}
                value={option.value}
                disabled={
                  // disable for high422
                  (x264ProfileValue === X264_PROFILE[1].value &&
                    ![X264_PIX_FORMAT[2].value, X264_PIX_FORMAT[0].value].includes(option.value)) ||
                  // disable for high444
                  (x264ProfileValue === X264_PROFILE[2].value &&
                    ![X264_PIX_FORMAT[3].value, X264_PIX_FORMAT[0].value].includes(option.value)) ||
                  // disable for high10
                  // (x264ProfileValue === X264_PROFILE[1].value &&
                  //   ![X264_PIX_FORMAT[4].value, X264_PIX_FORMAT[5].value, X264_PIX_FORMAT[0].value].includes(
                  //     option.value
                  //   )) ||
                  // disable for high, main, baseline
                  ([X264_PROFILE[0].value, X264_PROFILE[3].value, X264_PROFILE[4].value].includes(x264ProfileValue) &&
                    ![X264_PIX_FORMAT[1].value, X264_PIX_FORMAT[0].value].includes(option.value))
                }
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={8} style={disableInterlaced ? { visibility: "hidden", display: "none" } : null}>
        <Item
          name={[...fieldPrefix, "interlaced"]}
          valuePropName="checked"
          label={<FormattedMessage id="GeneralVideoCodec.interlaced" defaultMessage="Interlaced output" />}
        >
          <Switch
            defaultChecked={initialInterlaced}
            checkedChildren={formatMessage(globalTranslations.on)}
            unCheckedChildren={formatMessage(globalTranslations.off)}
            disabled={disabled || disableInterlaced}
          />
        </Item>
      </Col>
      <Col span={8}>
        <VideoCodecBitrateField
          disabled={disabled}
          name={[...fieldPrefix, "b"]}
          label={formatMessage(translations.bitrate)}
        />
      </Col>
      {!isSelectedCodecResizeAuto && (
        <>
          <Divider />
          <Col span={24}>
            <Item
              name={[...fieldPrefix, "presetId"]}
              label={<FormattedMessage id="GeneralVideoCodec.presetId" defaultMessage="Encoding Preset" />}
              rules={[required]}
            >
              <Select onChange={handleChangePresetId} disabled={disabled}>
                {SELECT_MPEG2VIDEO_PRESETS.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={8}>
            <InputNumberField
              name={[...fieldPrefix, "width"]}
              label={formatMessage(globalTranslations.width)}
              rules={[required]}
              disabled={!isSelectedCodecCustomPresetId || disabled}
            />
          </Col>
          <Col span={8}>
            <InputNumberField
              name={[...fieldPrefix, "height"]}
              label={formatMessage(globalTranslations.height)}
              rules={[required]}
              disabled={!isSelectedCodecCustomPresetId || disabled}
            />
          </Col>
          <Col span={8}>
            <Item
              name={[...fieldPrefix, "customSar"]}
              valuePropName="checked"
              label={
                <FormattedMessage id="GeneralVideoCodec.customSar" defaultMessage="Custom video sample aspect ratio" />
              }
            >
              <Switch
                disabled={!isSelectedCodecCustomPresetId || disabled}
                defaultChecked={selectedCustomSar}
                checkedChildren={formatMessage(globalTranslations.on)}
                unCheckedChildren={formatMessage(globalTranslations.off)}
                onChange={handleChangeCustomSar}
              />
            </Item>
          </Col>
          {!isSelectedCodecHIPresetId && selectedCustomSar && (
            <>
              <Col span={8}>
                <InputNumberField
                  name={[...fieldPrefix, "sarNum"]}
                  label={formatMessage(translations.sarNum)}
                  rules={[required]}
                  disabled={!isSelectedCodecCustomPresetId || disabled}
                />
              </Col>
              <Col span={8}>
                <InputNumberField
                  name={[...fieldPrefix, "sarDen"]}
                  label={formatMessage(translations.sarDen)}
                  rules={[required]}
                  disabled={!isSelectedCodecCustomPresetId || disabled}
                />
              </Col>
            </>
          )}
        </>
      )}
      <AdvancedSettings>
        <Row gutter={[24, 24]}>
          {additionalAdvancedFields && additionalAdvancedFields}
          <Col span={8}>
            <Item
              name={[...fieldPrefix, "changeGOP"]}
              valuePropName="checked"
              label={<FormattedMessage id="GeneralVideoCodec.changeGOP" defaultMessage="Custom GoP" />}
            >
              <Switch
                disabled={disabled}
                onChange={handleSwitchChangeGOP}
                defaultChecked={initialChangeGOP}
                checkedChildren={formatMessage(globalTranslations.on)}
                unCheckedChildren={formatMessage(globalTranslations.off)}
              />
            </Item>
          </Col>
          {isSwitchChangeGOP && (
            <>
              <Col span={8}>
                <VideoCodecGOPLengthField disabled={disabled} name={[...fieldPrefix, "g"]} />
              </Col>
              <Col span={8}>
                <InputNumberField
                  disabled={disabled}
                  name={[...fieldPrefix, "bf"]}
                  label={formatMessage(translations.gopStructure)}
                  rules={[required]}
                  min={0}
                  max={gValue}
                />
              </Col>
            </>
          )}
        </Row>
      </AdvancedSettings>
    </>
  );
};

GeneralVideoCodec.propTypes = {
  additionalBasicFields: PropTypes.object,
  additionalAdvancedFields: PropTypes.object,
  disabled: PropTypes.bool,
  disableInterlaced: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  form: PropTypes.object.isRequired,
  pixelFormat: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  isOutputRtmp: PropTypes.bool.isRequired,
};

GeneralVideoCodec.defaultProps = {
  additionalBasicFields: null,
  additionalAdvancedFields: null,
  disabled: null,
  disableInterlaced: null,
};

export default GeneralVideoCodec;
