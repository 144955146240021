import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

export default function PacketSizeLabel() {
  return (
    <div>
      <Tooltip
        placement="top"
        title={
          <FormattedMessage id="PacketSizeLabel.description" defaultMessage="Force packets size to be 1316 bytes" />
        }
      >
        <span>
          <FormattedMessage id="PacketSizeLabel.label" defaultMessage="Packet size" />
        </span>{" "}
        <QuestionCircleFilled />
      </Tooltip>
    </div>
  );
}
