import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Divider } from "antd";

import themeColor from "../../../../lib/style/theme";

const ProbeDrawerElementText = ({ data, divided, text }) => {
  return (
    <StyledText>
      {text}: <span>{data || "N/A"}</span>
      {divided && <Divider type="vertical" />}
    </StyledText>
  );
};

const StyledText = styled.span`
  span {
    color: ${themeColor.orange};
  }
`;

ProbeDrawerElementText.propTypes = {
  divided: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.object.isRequired,
};

ProbeDrawerElementText.defaultProps = {
  divided: null,
  data: null,
};

export default ProbeDrawerElementText;
