import React from "react";
import styled from "styled-components";
import { Modal, Row, Col, Form } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import LicenseField from "../../../components/LicenseField";
import ChannelName from "../../../components/NodeChannelFormV4/GeneralSection/ChannelName";
import InputTextFiled from "../../../components/Fields/InputTextField";

import { FORM_VERSION, MESSAGE_TYPE, LICENSES_TYPES } from "../../../lib/utils/constants";
import { actions as LOADING_ACTIONS, selectors as LOADING_SELECTORS } from "../../../ducks/loadingData";

import MQTTService from "../../../services/mqtt";
import store from "../../../store";

const translations = defineMessages({
  channelName: {
    id: "BasicModeGeneralSection.channelName",
    defaultMessage: "Channel name",
  },
});

const NewNodeChannelModal = ({ cwid, setVisibleNewNodeChannelForm, visibleNewNodeChannelForm }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { getFieldValue, validateFields, resetFields, scrollToField } = form;
  const loadingDataType = useSelector(LOADING_SELECTORS.getLoadingDataType);
  const isLoading = loadingDataType === MESSAGE_TYPE.ADD_NODE_CHANNEL;

  const handleOK = async () => {
    if (isLoading) {
      return;
    }

    try {
      await validateFields();
      const formData = getFieldValue();

      store.dispatch(LOADING_ACTIONS.SET_LOADING(MESSAGE_TYPE.ADD_NODE_CHANNEL));
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.ADD_NODE_CHANNEL,
          channelConfig: JSON.stringify(formData),
        },
      });
      setVisibleNewNodeChannelForm(false);
    } catch (error) {
      scrollToField(error.errorFields[0].name);
    }
  };

  const handleCancel = () => {
    resetFields();
    setVisibleNewNodeChannelForm(false);
  };

  const initialFormValues = {
    licenseId: LICENSES_TYPES.CLOUD,
    version: FORM_VERSION,
  };

  return (
    <Form form={form} initialValues={initialFormValues}>
      <StyledModal
        zIndex={100}
        centered
        title={<FormattedMessage id="NewNodeChannelModal.newChannel" defaultMessage="New channel" />}
        open={visibleNewNodeChannelForm}
        onOk={handleOK}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isLoading, loading: isLoading }}
        forceRender
      >
        <Row gutter={24}>
          <Col span={12}>
            <ChannelName name="name" label={formatMessage(translations.channelName)} />
          </Col>
          <Col span={12}>
            <LicenseField getFieldValue={getFieldValue} />
          </Col>
          <StyledHiddenCol span={4}>
            <InputTextFiled name="version" />
          </StyledHiddenCol>
        </Row>
      </StyledModal>
    </Form>
  );
};

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledHiddenCol = styled(Col)`
  visibility: hidden;
  display: none;
`;

NewNodeChannelModal.propTypes = {
  cwid: PropTypes.string,
  setVisibleNewNodeChannelForm: PropTypes.func.isRequired,
  visibleNewNodeChannelForm: PropTypes.bool.isRequired,
};

NewNodeChannelModal.defaultProps = {
  cwid: null,
};

export default NewNodeChannelModal;
