import React, { useEffect, useState } from "react";
import { QuestionCircleFilled, WarningOutlined } from "@ant-design/icons";
import { Select, Tooltip, Form } from "antd";
import PropTypes from "prop-types";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { connect } from "react-redux";
import store from "../../store";

import { selectors as NODE_SELECTORS } from "../../ducks/node";
import { actions as LOADING_ACTIONS, selectors as LOADING_SELECTORS } from "../../ducks/loadingData";

import MQTTService from "../../services/mqtt";

import themeColor from "../../lib/style/theme";
import parseJwt from "../../lib/utils/parseJwt";
import { required } from "../../lib/utils/formValidation";
import { LOADING_DATA_TYPE, MESSAGE_TYPE, LICENSES_TYPES } from "../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

const translations = defineMessages({
  label: {
    id: "LicenseField.label",
    defaultMessage: "License",
  },
  licenseIdTooltip: {
    id: "LicenseField.licenseIdTooltip",
    defaultMessage:
      "Select the license you want to assign to this channel. More information about licensing is available here:",
  },
});

const LicenseField = ({ cwid, getFieldValue, licenseList, loading, loadingDataType }) => {
  const { formatMessage } = useIntl();
  const [selectedLicenseId, setSelectedLicenseId] = useState(getFieldValue("licenseId"));

  useEffect(() => {
    if (cwid) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: { msgType: MESSAGE_TYPE.GET_LICENSES, command: MESSAGE_TYPE.GET_LICENSES },
      });
      store.dispatch(LOADING_ACTIONS.SET_LOADING(LOADING_DATA_TYPE.nodeLicenseList));
    }
  }, [cwid]);

  const LicenseIdTooltip = (
    <>
      <div>{formatMessage(translations.licenseIdTooltip)}</div>
      <a href="https://help.quickstream.tech/portal/kb/articles/licencing" target="_blank" rel="noopener noreferrer">
        https://help.quickstream.tech/portal/kb/articles/licencing
      </a>
    </>
  );

  const nodeLicensesList = licenseList[cwid] || [];

  const parsedNodeLicensesList = nodeLicensesList.map((license) => {
    return { ...parseJwt(license.licenseToken), id: license.id, validLicense: license.validLicense };
  });

  const licenseIdLabel = (
    <StyledLabel>
      <span>{formatMessage(translations.label)}</span>
      <Tooltip placement="top" title={LicenseIdTooltip}>
        <QuestionCircleFilled />
      </Tooltip>
    </StyledLabel>
  );

  const handleChangeLicenseId = (selectedLicenseIdValue) => {
    setSelectedLicenseId(selectedLicenseIdValue);
  };

  const isLoadingLicenses = loading && loadingDataType === LOADING_DATA_TYPE.nodeLicenseList;
  const isFreeQuickstreamLicense = selectedLicenseId === LICENSES_TYPES.FREE;

  return (
    <>
      <StyledItem name="licenseId" label={licenseIdLabel} rules={[{ ...required }]}>
        <Select loading={isLoadingLicenses} onChange={handleChangeLicenseId}>
          <Option value={LICENSES_TYPES.CLOUD}>
            <FormattedMessage id="LicenseField.cloud" defaultMessage="Cloud" />
          </Option>
          {parsedNodeLicensesList.map((option) => (
            <Option key={option.licenseId} value={option.licenseId} disabled={option.validLicense !== 1}>
              {option.licenseId}
            </Option>
          ))}
        </Select>
      </StyledItem>
      {isFreeQuickstreamLicense && (
        <StyledWarning>
          <WarningOutlined />
          <StyledWarningText>
            <FormattedMessage
              id="LicenseField.freeLicenseWarning"
              // eslint-disable-next-line max-len
              defaultMessage="This license allows only for one output and must have a Quickstream input or output"
            />
          </StyledWarningText>
        </StyledWarning>
      )}
    </>
  );
};

LicenseField.propTypes = {
  cwid: PropTypes.string,
  getFieldValue: PropTypes.func.isRequired,
  licenseList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingDataType: PropTypes.string.isRequired,
};

LicenseField.defaultProps = {
  cwid: null,
};

const StyledWarning = styled.span`
  color: ${themeColor.orange};
`;

const StyledWarningText = styled.span`
  margin-left: 5px;
`;

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

const StyledItem = styled(Item)`
  .ant-form-item-control {
    max-width: 80%;
  }
`;

const mapStateToProps = (state) => ({
  cwid: NODE_SELECTORS.getNodeCwid(state),
  loading: LOADING_SELECTORS.getLoading(state),
  loadingDataType: LOADING_SELECTORS.getLoadingDataType(state),
  licenseList: NODE_SELECTORS.getNodeLicenses(state),
});

export default connect(mapStateToProps, null)(LicenseField);
