import React, { useState } from "react";
import { Card, Button, Row, Col, Modal, Empty } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import RemoveButton from "../../../../../RemoveButton";
import InputTextFiled from "../../../../../Fields/InputTextField";
import InputNumberField from "../../../../../Fields/InputNumberField";

import ProgramStreams from "./ProgramStreams";

import { max32characters, az09space, required, ASCIICharacters } from "../../../../../../lib/utils/formValidation";
import { NEW, OUTPUTS_TYPE, MESSAGE_TYPE } from "../../../../../../lib/utils/constants";
import { warningNotification } from "../../../../../../lib/utils/notification";

import MQTTService from "../../../../../../services/mqtt";

import { selectors as NODE_SELECTORS } from "../../../../../../ducks/node";

import MuxerProgramsElement from "./MuxerProgramsElement";

const translations = defineMessages({
  confirmRemoveText: {
    id: "MuxerPrograms.confirmRemove",
    defaultMessage: "Are you sure, you want to remove this program?",
  },
  serviceName: {
    id: "MuxerPrograms.serviceName",
    defaultMessage: "Service Name",
  },
  serviceProvider: {
    id: "MuxerPrograms.serviceProvider",
    defaultMessage: "Service Provider",
  },
  id: {
    id: "MuxerPrograms.serviceID",
    defaultMessage: "Service ID",
  },
  serviceValidation: {
    id: "MuxerPrograms.serviceValidation",
    defaultMessage: "Please fill required fields",
  },
  nameAlreadyExist: {
    id: "MuxerPrograms.nameAlreadyExist",
    defaultMessage: "This service name already exist",
  },
});

const MuxerPrograms = ({ disabled, handleFinish, id, isNewMuxedOutput, prefix, form }) => {
  const cwid = useSelector(NODE_SELECTORS.getNodeCwid);
  const { formatMessage } = useIntl();
  const [programVisible, setProgramVisible] = useState(null);
  const { getFieldValue, resetFields, validateFields, setFieldsValue } = form;
  const initialOutputPrograms = getFieldValue(prefix);
  const [outputPrograms, setOutputPrograms] = useState(initialOutputPrograms);

  const numberOfPrograms = outputPrograms ? outputPrograms.length : 0;

  const handleClick = (index) => {
    setProgramVisible(index);
    resetFields();
  };

  const handleAddProgram = async () => {
    try {
      await validateFields([[OUTPUTS_TYPE.muxedOutputs, prefix[1], "name"]]);
      await validateFields([[OUTPUTS_TYPE.muxedOutputs, prefix[1], "url"]]);

      setProgramVisible(NEW);
    } catch (error) {
      warningNotification(formatMessage)(translations.serviceValidation);
    }
  };

  const handleOKProgram = async () => {
    try {
      await validateFields();
      const updatedFormData = getFieldValue();
      const updatedPrograms = getFieldValue(prefix);

      setOutputPrograms(updatedPrograms);

      handleFinish(updatedFormData);
      setProgramVisible(null);

      if (isNewMuxedOutput) {
        isNewMuxedOutput(prefix[1]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error - formValidation", error);
    }
  };

  const handleCancelProgram = () => {
    resetFields([[...prefix]]);
    setProgramVisible(null);
  };

  const handleRemoveProgram = (serviceName) => {
    const channelConfig = getFieldValue();
    const programs = getFieldValue(prefix);

    const muxedOutputs = getFieldValue(prefix[0]);
    const parsedMuxerPrograms = programs && programs.filter((program) => program.serviceName !== serviceName);

    muxedOutputs[prefix[1]].muxer.programs = parsedMuxerPrograms;
    const updatedFormData = { ...channelConfig, muxedOutputs };

    setFieldsValue({ muxedOutputs });
    setOutputPrograms(parsedMuxerPrograms);

    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.UPDATE_CHANNEL,
        channelId: id,
        channelConfig: JSON.stringify(updatedFormData),
      },
    });

    setProgramVisible(null);
  };

  const uniqueValidator = (rule, value) => {
    const programs = getFieldValue(prefix);
    const fieldIndex = rule.field.split(".")[4];
    const filteredPrograms = programs.filter((program, index) => {
      if (index === +fieldIndex) {
        return false;
      }

      return true;
    });

    const parsedFilteredPrograms = filteredPrograms.map((program) => program.serviceName);
    const duplicatedProgramName = parsedFilteredPrograms.includes(value);

    if (duplicatedProgramName) {
      return Promise.reject(formatMessage(translations.nameAlreadyExist));
    }

    return Promise.resolve();
  };

  const isMuxerPrograms = outputPrograms && outputPrograms.length > 0;

  if (!isMuxerPrograms && disabled) {
    return (
      <Card title={<FormattedMessage id="MuxerPrograms.services" defaultMessage="Services" />}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Card>
    );
  }

  return (
    <Card title={<FormattedMessage id="MuxerPrograms.services" defaultMessage="Services" />}>
      {outputPrograms &&
        outputPrograms.map((program, index) => {
          return (
            <span key={`${prefix.join()},${program?.serviceName}`}>
              <MuxerProgramsElement
                label={program?.serviceName}
                title={program?.serviceProvider}
                streams={program?.streams}
                index={index}
                handleClick={handleClick}
                incomplete={program.incomplete}
              />
              <StyledModal
                zIndex={100}
                centered
                title={<FormattedMessage id="MuxerPrograms.editServices" defaultMessage="Edit Services" />}
                open={programVisible === index}
                onOk={handleOKProgram}
                onCancel={handleCancelProgram}
                forceRender
                footer={disabled ? null : undefined}
              >
                <StyledCard
                  title={program?.serviceName}
                  extra={
                    !disabled && (
                      <RemoveButton
                        handleRemove={handleRemoveProgram}
                        fieldName={program?.serviceName}
                        confirmRemoveText={formatMessage(translations.confirmRemoveText)}
                      />
                    )
                  }
                >
                  <Row justify="start" gutter={24}>
                    <Col span={8}>
                      <InputTextFiled
                        disabled={disabled}
                        name={[...prefix, index, "serviceName"]}
                        label={formatMessage(translations.serviceName)}
                        rules={[{ validator: uniqueValidator }, max32characters, az09space]}
                      />
                    </Col>
                    <Col span={8}>
                      <InputTextFiled
                        disabled={disabled}
                        name={[...prefix, index, "serviceProvider"]}
                        label={formatMessage(translations.serviceProvider)}
                        rules={[max32characters, ASCIICharacters]}
                      />
                    </Col>
                    <Col span={8}>
                      <InputNumberField
                        disabled={disabled}
                        name={[...prefix, index, "id"]}
                        label={formatMessage(translations.id)}
                        rules={[required]}
                      />
                    </Col>
                    <Col span={24}>
                      <ProgramStreams disabled={disabled} prefix={[...prefix, index]} form={form} />
                    </Col>
                  </Row>
                </StyledCard>
              </StyledModal>
            </span>
          );
        })}
      {!disabled && (
        <StyledAddButton type="dashed" icon={<PlusOutlined />} onClick={handleAddProgram}>
          <span>
            <FormattedMessage id="MuxerPrograms.addService" defaultMessage="Add Service" />
          </span>
        </StyledAddButton>
      )}
      {programVisible === NEW && (
        <StyledModal
          zIndex={100}
          centered
          title={<FormattedMessage id="MuxerPrograms.addService" defaultMessage="Add Service" />}
          open={programVisible === NEW}
          onOk={handleOKProgram}
          onCancel={handleCancelProgram}
          forceRender
        >
          <StyledCard title={<FormattedMessage id="MuxerPrograms.newService" defaultMessage="New Service" />}>
            <Row justify="start" gutter={24}>
              <Col span={8}>
                <InputTextFiled
                  disabled={disabled}
                  name={[...prefix, numberOfPrograms, "serviceName"]}
                  label={formatMessage(translations.serviceName)}
                  rules={[{ validator: uniqueValidator }, max32characters, az09space]}
                />
              </Col>
              <Col span={8}>
                <InputTextFiled
                  disabled={disabled}
                  name={[...prefix, numberOfPrograms, "serviceProvider"]}
                  label={formatMessage(translations.serviceProvider)}
                  rules={[max32characters, ASCIICharacters]}
                />
              </Col>
              <Col span={8}>
                <InputNumberField
                  disabled={disabled}
                  name={[...prefix, numberOfPrograms, "id"]}
                  label={formatMessage(translations.id)}
                  rules={[required]}
                />
              </Col>
              <Col span={24}>
                <ProgramStreams
                  disabled={disabled}
                  prefix={[...prefix, numberOfPrograms]}
                  name={[...prefix, numberOfPrograms]}
                  form={form}
                />
              </Col>
            </Row>
          </StyledCard>
        </StyledModal>
      )}
    </Card>
  );
};

const StyledAddButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledCard = styled(Card)`
  margin-bottom: 10px;
`;

MuxerPrograms.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleFinish: PropTypes.func.isRequired,
  id: PropTypes.string,
  isNewMuxedOutput: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  form: PropTypes.object.isRequired,
};

MuxerPrograms.defaultProps = {
  id: null,
};

export default MuxerPrograms;
