import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

export default function NewNodeRegionLabel() {
  const RegionTooltipInfo = (
    <>
      <span>
        <FormattedMessage id="InstallNewNode.regionInfo" defaultMessage="The region in which node will be created" />.
      </span>
      <div>
        <span>
          <FormattedMessage id="InstallNewNode.youCan" defaultMessage="You can" />
        </span>
        <StyledLink type="link" href="https://aws-latency-test.com" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="InstallNewNode.check" defaultMessage="check" />
        </StyledLink>
        <span>
          <FormattedMessage id="InstallNewNode.nearestRegion" defaultMessage="nearest region latency" />.
        </span>
      </div>
    </>
  );

  return (
    <>
      <Tooltip placement="top" title={RegionTooltipInfo}>
        <FormattedMessage id="InstallNewNode.region" defaultMessage="Region" /> <QuestionCircleFilled />
      </Tooltip>
    </>
  );
}

const StyledLink = styled.a`
  margin: 0 4px;
`;
