import React from "react";
import { connect } from "react-redux";
import { defineMessages, useIntl } from "react-intl";
import { Select, Form } from "antd";
import PropTypes from "prop-types";

import { selectors as LOADING_SELECTORS } from "../../../ducks/loadingData";

import { required } from "../../../lib/utils/formValidation";
import { LOADING_DATA_TYPE, SELECT_DEFAULT_AUTO } from "../../../lib/utils/constants";

import useNodeInterfacesHook from "../../../hooks/useNodeInterfaces";

const { Option } = Select;

const { Item } = Form;

const translations = defineMessages({
  networkInterfaceLabel: {
    id: "NetworkField.label",
    defaultMessage: "Network Interface",
  },
  auto: {
    id: "general.auto",
    defaultMessage: "Auto",
  },
});

const NetworkField = ({ disabled, name, label, loading, loadingDataType, requiredField, hidden, disableAutomatic }) => {
  const { formatMessage } = useIntl();

  const nodeInterfaces = useNodeInterfacesHook();

  const parsedInterfaces = nodeInterfaces.map((config) => ({
    label: `${config.ip}`,
    value: `${config.ip}`,
  }));

  const defaultInterfaces = disableAutomatic ? parsedInterfaces : [SELECT_DEFAULT_AUTO, ...parsedInterfaces];

  const isLoadingNetworkInterfaces = loading && loadingDataType === `${name}-${LOADING_DATA_TYPE.networkInterfaces}`;

  return (
    <Item
      hidden={hidden}
      name={name}
      label={label || formatMessage(translations.networkInterfaceLabel)}
      {...(requiredField ? { rules: [{ ...required }] } : {})}
    >
      <Select loading={isLoadingNetworkInterfaces} disabled={disabled}>
        {defaultInterfaces.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

NetworkField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loading: PropTypes.bool.isRequired,
  loadingDataType: PropTypes.string.isRequired,
  requiredField: PropTypes.bool,
  hidden: PropTypes.bool,
  disableAutomatic: PropTypes.bool,
};

NetworkField.defaultProps = {
  disabled: null,
  label: "Network Interface",
  requiredField: true,
  hidden: false,
  disableAutomatic: false,
};

const mapStateToProps = (state) => ({
  loading: LOADING_SELECTORS.getLoading(state),
  loadingDataType: LOADING_SELECTORS.getLoadingDataType(state),
});

export default connect(mapStateToProps, null)(NetworkField);
