import React, { useState } from "react";
import { Col, Switch, Row, Form } from "antd";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import { IPFieldV5, PortField } from "../Fields/Fields";
import InputNumberField from "../Fields/InputNumberField";
import NetworkField from "../Fields/NetworkField";
import PassphraseField from "../Fields/PassphraseField";

import { selectors as CONNECTION_SELECTORS } from "../../ducks/node";

import { required } from "../../lib/utils/formValidation";
import globalTranslations from "../../lib/translations";
import { AUTO_IP, DEFAULT_VALUES, DIRECT_ENGINE_OUTPUTS_PREFIX } from "../../lib/utils/constants";

import OverheadBandwidthLabel from "../NodeChannelFormV5/Labels/OverheadBandwidthLabel";
import LatencyLabel from "../NodeChannelFormV5/Labels/LatencyLabel";
import IPTOSFields from "../IPTOSFields";
import IPTTLFields from "../IPTTLFields";
import LocalPortLabel from "../LocalPortLabel";

const { Item } = Form;

const InputStreamQSSection = ({
  disabled,
  getFieldValue,
  isInput,
  isTranscodingOutput,
  isDirectOutput,
  name,
  prefix,
  setFieldsValue,
  stundAddress,
  form,
}) => {
  const { formatMessage } = useIntl();
  const initialValue = getFieldValue(prefix);
  const [autoIP, setAutoIp] = useState(initialValue?.cloudIp === AUTO_IP || initialValue?.autoIp);

  const handleSwitchAutoIp = (autoIPActive) => {
    if (isDirectOutput) {
      const outputs = getFieldValue([DIRECT_ENGINE_OUTPUTS_PREFIX]);
      if (autoIPActive) {
        outputs[prefix[1]].cloudIp = stundAddress;
      } else {
        outputs[prefix[1]].cloudIp =
          initialValue?.cloudIp === AUTO_IP ? stundAddress : initialValue?.cloudIp || DEFAULT_VALUES.IP;
      }
      setFieldsValue({ outputs });
    }

    if (isTranscodingOutput) {
      const outputs = getFieldValue([DIRECT_ENGINE_OUTPUTS_PREFIX]);
      if (autoIPActive) {
        outputs[prefix[1]].urlDestinations[prefix[3]].cloudIp = stundAddress;
      } else {
        outputs[prefix[1]].urlDestinations[prefix[3]].cloudIp =
          initialValue?.cloudIp === AUTO_IP ? stundAddress : initialValue?.cloudIp || DEFAULT_VALUES.IP;
      }
      setFieldsValue({ outputs });
    }

    setAutoIp(autoIPActive);
  };

  return (
    <Row gutter={24}>
      {!isInput && (
        <Col md={24} lg={12}>
          <Row justify="space-between" align="middle" gutter={24}>
            <Col md={8} lg={8}>
              <Item name={[...name, "autoIp"]} valuePropName="checked">
                <Switch
                  disabled={disabled || !stundAddress}
                  defaultChecked={autoIP}
                  checkedChildren={formatMessage(globalTranslations.autoIPON)}
                  unCheckedChildren={formatMessage(globalTranslations.autoIPOFF)}
                  onChange={handleSwitchAutoIp}
                />
              </Item>
            </Col>
            <Col md={16} lg={16}>
              <IPFieldV5
                label={formatMessage(globalTranslations.ownIPAddress)}
                name={[...name, "cloudIp"]}
                disabled={disabled || autoIP}
              />
            </Col>
          </Row>
        </Col>
      )}
      <Col md={24} lg={12}>
        <NetworkField name={[...name, "interfaceIp"]} disabled={disabled} />
      </Col>
      {isInput && (
        <Col span={12}>
          <PortField name={[...name, "localPort"]} label={<LocalPortLabel />} min={0} />
        </Col>
      )}
      <Col md={24} lg={8}>
        <InputNumberField
          disabled={disabled}
          name={[...name, "srtOverhead"]}
          label={OverheadBandwidthLabel()}
          rules={[required]}
          min={5}
          max={100}
        />
      </Col>
      <Col md={24} lg={8}>
        <InputNumberField
          disabled={disabled}
          name={[...name, "srtLatency"]}
          label={LatencyLabel("input")}
          rules={[required]}
        />
      </Col>
      {isInput && (
        <Col md={24} lg={24}>
          <PassphraseField disabled={disabled} prefix={[...name]} type={prefix[0]} form={form} />
        </Col>
      )}
      <IPTOSFields prefix={prefix} form={form} disabled={disabled} />
      <IPTTLFields prefix={prefix} form={form} disabled={disabled} />
    </Row>
  );
};

InputStreamQSSection.propTypes = {
  disabled: PropTypes.bool,
  getFieldValue: PropTypes.func.isRequired,
  // To refactor - props
  initialValue: PropTypes.shape({
    connectionMethod: PropTypes.string,
    dstPort: PropTypes.number,
    ffmpegStreamProperties: PropTypes.shape({
      connectTimeout: PropTypes.number,
      mode: PropTypes.string,
      oheadbw: PropTypes.number,
      rcvlatency: PropTypes.number,
    }),
    interfaceIp: PropTypes.string,
    cloudIp: PropTypes.string,
  }),
  isInput: PropTypes.bool,
  isDirectOutput: PropTypes.bool,
  isTranscodingOutput: PropTypes.bool,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  prefix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  stundAddress: PropTypes.string.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
};

InputStreamQSSection.defaultProps = {
  disabled: null,
  initialValue: null,
  isInput: null,
  isDirectOutput: null,
  isTranscodingOutput: null,
};

const mapStateToProps = (state) => ({
  stundAddress: CONNECTION_SELECTORS.getStundAddress(state),
});

export default connect(mapStateToProps, null)(InputStreamQSSection);
