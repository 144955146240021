import React from "react";
import { Tooltip, Switch } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import MQTTService from "../../../../services/mqtt";

import { NODE_CHANNEL_STATE, MESSAGE_TYPE } from "../../../../lib/utils/constants";

const BackupSwitch = ({ channelId, usesBackup, requestedStatusText }) => {
  const { cwid } = useParams();

  const isValidBackupStatus = [NODE_CHANNEL_STATE.CONNECTED].includes(requestedStatusText);

  const handleSwitchBackup = (value) => {
    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.STREAM_SET_BACKUP,
        channelId,
        useBackup: value,
      },
    });
  };

  return (
    <Tooltip
      placement="top"
      title={
        !isValidBackupStatus ? (
          <FormattedMessage
            id="NodeChannelDetailView.channelMustBeRunning"
            defaultMessage="Channel must be connected"
          />
        ) : null
      }
    >
      <Switch
        unCheckedChildren={
          <FormattedMessage id="NodeChannelDetailView.switchToBackup" defaultMessage="Switch to Backup Input" />
        }
        checkedChildren={
          <FormattedMessage id="NodeChannelDetailView.switchToMain" defaultMessage="Switch to Main Input" />
        }
        checked={usesBackup}
        onChange={handleSwitchBackup}
        disabled={!isValidBackupStatus}
      />
    </Tooltip>
  );
};

BackupSwitch.propTypes = {
  channelId: PropTypes.string.isRequired,
  requestedStatusText: PropTypes.string.isRequired,
  usesBackup: PropTypes.bool.isRequired,
};

export default BackupSwitch;
