import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Select, Form, Spin, Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { connect, useSelector } from "react-redux";
import ImmutablePropTypes from "react-immutable-proptypes";

import store from "../../../../store";
import MQTTService from "../../../../services/mqtt";

import { SET_LOADING_FORMATS, selectors as FORMAT_SELECTORS } from "../../../../ducks/nodeFormats";
import { selectors as CONNECTION_SELECTORS } from "../../../../ducks/node";

import { required } from "../../../../lib/utils/formValidation";
import { SELECT_DEFAULT_AUTO, MESSAGE_TYPE } from "../../../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

const InputSDI = ({ getFieldValue, inputs, disabled, loadingFormats, formats, setFieldsValue, type }) => {
  const [selectedDevice, setSelectedDevice] = useState(getFieldValue(["input", "device"]));
  const cwid = useSelector(CONNECTION_SELECTORS.getNodeCwid);

  useEffect(() => {
    const isFormatData = !!formats.get(selectedDevice);

    if (!isFormatData && selectedDevice) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_FORMATS,
          device: selectedDevice,
          type,
        },
      });

      store.dispatch(SET_LOADING_FORMATS(true));
    }
  }, [selectedDevice, type]);

  const selectedFormats =
    (formats.get(selectedDevice) &&
      formats.get(selectedDevice).map((format) => {
        return { label: format, value: format };
      })) ||
    [];

  const FORMATS_ARRAY = [SELECT_DEFAULT_AUTO, ...selectedFormats];
  const isSelectedFormats = selectedFormats && selectedFormats.length > 0;

  const INPUTS_SELECT = inputs.map((input) => {
    return {
      label: input.desc,
      value: input.device,
    };
  });

  const handleChangeDevice = (value) => {
    setSelectedDevice(value);
    const inputForm = getFieldValue("input");
    setFieldsValue({ input: { device: inputForm.device, format: SELECT_DEFAULT_AUTO.value, type: inputForm.type } });
  };

  return (
    <Row gutter={24}>
      <Col span={12}>
        <Item
          name={["input", "device"]}
          label={<FormattedMessage id="InputSDI.device" defaultMessage="Device" />}
          rules={[required]}
        >
          <Select disabled={disabled} onChange={handleChangeDevice}>
            {INPUTS_SELECT.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={12}>
        <Spin
          spinning={!isSelectedFormats}
          tip={
            <FormattedMessage
              id="InputSDI.waitingFormNodeFormats"
              defaultMessage="Waiting for node formats or device to be selected..."
            />
          }
        >
          <Item
            name={["input", "format"]}
            label={<FormattedMessage id="InputSDI.format" defaultMessage="Format" />}
            rules={[required]}
          >
            <Select
              disabled={disabled || !isSelectedFormats}
              notFoundContent={loadingFormats ? <Spin size="small" /> : null}
            >
              {FORMATS_ARRAY.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Spin>
      </Col>
    </Row>
  );
};

InputSDI.propTypes = {
  disabled: PropTypes.bool,
  getFieldValue: PropTypes.func.isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string,
      device: PropTypes.string,
    })
  ).isRequired,
  loadingFormats: PropTypes.bool.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  formats: ImmutablePropTypes.map.isRequired,
  type: PropTypes.string.isRequired,
};

InputSDI.defaultProps = {
  disabled: null,
};

const mapStateToProps = (state) => ({
  formats: FORMAT_SELECTORS.getFormats(state),
  loadingFormats: !!FORMAT_SELECTORS.getLoadingFormat(state),
});

export default connect(mapStateToProps, null)(InputSDI);
