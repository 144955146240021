import React from "react";
import { Col, Form, Switch } from "antd";
import { useIntl, defineMessages } from "react-intl";
import styled from "styled-components";

import { max128characters } from "../../lib/utils/formValidation";
import globalTranslations from "../../lib/translations";

import InputTextFiled from "../Fields/InputTextField";

const translations = defineMessages({
  streamId: {
    id: "StreamIdFields.streamId",
    defaultMessage: "Stream ID",
  },
  stream: {
    id: "StreamIdFields.stream",
    defaultMessage: "Stream",
  },
});

const { Item } = Form;

const StreamIdFields = ({ prefix, form, disabled, hidden }) => {
  const { formatMessage } = useIntl();

  const setStreamIdValue = Form.useWatch([...prefix, "setStreamId"], form) || false;

  return (
    <StyledDiv>
      <Col md={24} lg={6}>
        <Item
          name={[...prefix, "setStreamId"]}
          label={formatMessage(translations.stream)}
          valuePropName="checked"
          hidden={hidden}
        >
          <Switch
            defaultChecked={setStreamIdValue}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
            disabled={disabled}
          />
        </Item>
      </Col>
      {setStreamIdValue && (
        <Col md={24} lg={8}>
          <InputTextFiled
            name={[...prefix, "streamId"]}
            label={formatMessage(translations.streamId)}
            rules={[max128characters]}
            disabled={disabled}
          />
        </Col>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
`;

export default StreamIdFields;
