import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const SET_ORGANIZATIONS = createAction("CLOUD/SET_ORGANIZATIONS");
export const CREATE_NEW_ORGANIZATION = createAction("CLOUD/CREATE_NEW_ORGANIZATION");
export const CREATE_NEW_ORGANIZATION_PUBLIC = createAction("CLOUD/CREATE_NEW_ORGANIZATION_PUBLIC");
export const UPDATE_ORGANIZATION = createAction("CLOUD/UPDATE_ORGANIZATION");
export const UPDATE_ORGANIZATION_ROLE = createAction("CLOUD/UPDATE_ORGANIZATION_ROLE");
export const LEAVE_ORGANIZATION_ROLE = createAction("CLOUD/LEAVE_ORGANIZATION_ROLE");
export const DELETE_ORGANIZATION_ROLE = createAction("CLOUD/DELETE_ORGANIZATION_ROLE");
export const DELETE_ORGANIZATION = createAction("CLOUD/DELETE_ORGANIZATION");
export const CHANGE_ORGANIZATION_STATUS = createAction("CLOUD/CHANGE_ORGANIZATION_STATUS");
export const CHANGE_ORGANIZATION_ROLE_STATUS = createAction("CLOUD/CHANGE_ORGANIZATION_ROLE_STATUS");
export const CLEAR_ORGANIZATIONS_DATA = createAction("CLOUD/CLEAR_ORGANIZATIONS_DATA");

const defaultState = new Map({
  list: [],
  data: null,
  roles: [],
  public: [],
  publicDistributors: [],
});

const reducer = handleActions(
  {
    [SET_ORGANIZATIONS]: (state, { payload }) => state.merge({ ...payload }),
    [CREATE_NEW_ORGANIZATION]: (state, { payload }) => state.updateIn(["list"], (array) => array.concat([payload])),
    [CREATE_NEW_ORGANIZATION_PUBLIC]: (state, { payload }) =>
      state.updateIn(["public"], (array) => array.concat([payload])),
    [UPDATE_ORGANIZATION]: (state, { payload }) => state.merge({ data: payload }),
    [UPDATE_ORGANIZATION_ROLE]: (state, { payload }) => {
      return state.updateIn(["roles"], (roles) => {
        const rolesIds = roles.map((role) => role.id);
        const isNewRole = !rolesIds.includes(payload.id);

        if (isNewRole) {
          return [...roles, payload];
        }

        return roles.map((role) => {
          if (role.id === payload.id) {
            return payload;
          }

          return role;
        });
      });
    },
    [LEAVE_ORGANIZATION_ROLE]: (state) => state.merge({ data: null }),
    [DELETE_ORGANIZATION_ROLE]: (state, { payload }) =>
      state.updateIn(["roles"], (roles) => roles.filter((role) => role.id !== payload)),
    [DELETE_ORGANIZATION]: (state, { payload }) =>
      state.updateIn(["list"], (array) => array.filter((organization) => organization.id !== payload)),
    [CHANGE_ORGANIZATION_STATUS]: (state, { payload }) =>
      state.updateIn(["list"], (array) => {
        const parsedArray = array.map((organization) => {
          if (organization.id === payload.id) {
            return payload;
          }

          return organization;
        });

        return parsedArray;
      }),
    [CHANGE_ORGANIZATION_ROLE_STATUS]: (state, { payload }) =>
      state.updateIn(["roles"], (array) => {
        const parsedArray = array.map((organization) => {
          if (organization.id === payload.id) {
            return payload;
          }

          return organization;
        });

        return parsedArray;
      }),
    [CLEAR_ORGANIZATIONS_DATA]: () => defaultState,
  },
  defaultState
);

export const selectors = {
  getOrganizationsList: (state) => state.getIn(["organizations", "list"]),
  getOrganizationsPublicList: (state) => state.getIn(["organizations", "public"]),
  getPublicDistributors: (state) => state.getIn(["organizations", "publicDistributors"]),
  getOrganizationId: (state) => state.getIn(["organizations", "data", "organizationId"]),
  getOrganizationRolePublic: (state) => state.getIn(["organizations", "data", "public"]),
  getOrganizationRoleStatus: (state) => state.getIn(["organizations", "data", "status"]),
  getOrganizationRoleId: (state) => state.getIn(["organizations", "data", "id"]),
  getOrganizationData: (state) => state.getIn(["organizations", "data"]),
  getOrganizationsRoles: (state) => state.getIn(["organizations", "roles"]),
  getOrganizationRoles: (state, organizationId) => {
    const allRoles = state.getIn(["organizations", "roles"]);
    const filteredRoles = allRoles.filter((role) => role.organizationId === organizationId);

    return filteredRoles;
  },
};

export default reducer;
