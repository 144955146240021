import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Divider, Badge } from "antd";

import IncompleteConfig from "../IncompleteConfig";

import themeColor from "../../lib/style/theme";

const { Ribbon } = Badge;

const WebcamElement = ({
  audioTitle,
  bitsPerSample,
  channels,
  fps,
  handleClick,
  height,
  label,
  sampleRate,
  videoTitle,
  width,
  incomplete,
  title,
}) => {
  const noIncomplete = !incomplete || (incomplete && incomplete.length === 0);

  return (
    <Ribbon
      text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
      color={noIncomplete ? themeColor.green : themeColor.orange}
    >
      <StyledContainer onClick={handleClick}>
        <StyledAsideType>{label}</StyledAsideType>
        <StyledBoldText>{title}</StyledBoldText>
        {videoTitle && (
          <>
            <div>
              <StyledText>
                <FormattedMessage id="WebcamElement.video" defaultMessage="Video" />:
              </StyledText>
              <StyledBoldText>{videoTitle}</StyledBoldText>
            </div>
            <StyledDiv>
              <div>
                <StyledText>
                  <FormattedMessage id="WebcamElement.size" defaultMessage="Size" />:
                </StyledText>
                <StyledBoldText>{`${width}x${height}`}</StyledBoldText>
              </div>
              <Divider type="vertical" />
              <div>
                <StyledText>
                  <FormattedMessage id="WebcamElement.fps" defaultMessage="fps" />:
                </StyledText>
                <StyledBoldText>{fps}</StyledBoldText>
              </div>
            </StyledDiv>
          </>
        )}
        {audioTitle && (
          <>
            <div>
              <StyledText>
                <FormattedMessage id="WebcamElement.audio" defaultMessage="Audio" />:
              </StyledText>
              <StyledBoldText>{audioTitle}</StyledBoldText>
            </div>
            <StyledDiv>
              <div>
                <StyledText>
                  <FormattedMessage id="WebcamElement.bitsPerSample" defaultMessage="Bits per sample" />:
                </StyledText>
                <StyledBoldText>{bitsPerSample}</StyledBoldText>
              </div>
              <Divider type="vertical" />
              <div>
                <StyledText>
                  <FormattedMessage id="WebcamElement.sampleRate" defaultMessage="Sample rate" />:
                </StyledText>
                <StyledBoldText>{sampleRate}</StyledBoldText>
              </div>
              <Divider type="vertical" />
              <div>
                <StyledText>
                  <FormattedMessage id="WebcamElement.channels" defaultMessage="Channels" />:
                </StyledText>
                <StyledBoldText>{channels}</StyledBoldText>
              </div>
            </StyledDiv>
          </>
        )}
      </StyledContainer>
    </Ribbon>
  );
};

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 115px;
  margin: 0 0 15px 30px;
  padding: 10px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  color: #fff;
  padding: 5px;
  line-height: 24px;
  position: absolute;
  top: 114px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 115px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;

WebcamElement.propTypes = {
  audioTitle: PropTypes.string,
  bitsPerSample: PropTypes.number,
  channels: PropTypes.number,
  fps: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  sampleRate: PropTypes.string,
  videoTitle: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  incomplete: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.string.isRequired,
};

WebcamElement.defaultProps = {
  audioTitle: null,
  bitsPerSample: null,
  channels: null,
  fps: null,
  height: null,
  sampleRate: null,
  videoTitle: null,
  width: null,
  incomplete: null,
};

export default WebcamElement;
