import { useIntl, defineMessages } from "react-intl";

const translations = defineMessages({
  CONNECTED: {
    id: "TranslateStatus.connected",
    defaultMessage: "CONNECTED",
  },
  UNCONFIGURED: {
    id: "TranslateStatus.uncofigured",
    defaultMessage: "UNCONFIGURED",
  },
  CONFIG_INCOMPLETE: {
    id: "TranslateStatus.configIncomplete",
    defaultMessage: "CONFIG INCOMPLETE",
  },
  STOPPED: {
    id: "TranslateStatus.stopped",
    defaultMessage: "STOPPED",
  },
  CONNECTING: {
    id: "TranslateStatus.connecting",
    defaultMessage: "CONNECTING",
  },
  RECONNECTING: {
    id: "TranslateStatus.reconnecting",
    defaultMessage: "RECONNECTING",
  },
  CLOSING: {
    id: "TranslateStatus.closing",
    defaultMessage: "CLOSING",
  },
  ERROR: {
    id: "TranslateStatus.error",
    defaultMessage: "ERROR",
  },
  PROCESS_DIED: {
    id: "TranslateStatus.PROCESS_DIED",
    defaultMessage: "PROCESS DIED",
  },
  EMPTY: {
    id: "TranslateStatus.EMPTY",
    defaultMessage: "EMPTY",
  },
  PAUSED: {
    id: "TranslateStatus.PAUSED",
    defaultMessage: "PAUSED",
  },
  PROCESS_DISCONNECTED: {
    id: "TranslateStatus.PROCESS_DISCONNECTED",
    defaultMessage: "REINITIALIZING",
  },
});

const TranslateStatus = ({ channelStatus }) => {
  const { formatMessage } = useIntl();

  const translationExist = channelStatus in translations;

  if (!translationExist) {
    return channelStatus;
  }

  return formatMessage(translations[channelStatus]);
};

export default TranslateStatus;
