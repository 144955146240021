import React, { useEffect } from "react";
import { Drawer, Button, Divider, Card, Spin, Empty } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";

import store from "../../../../store";
import MQTTService from "../../../../services/mqtt";

import { selectors as PROBE_SELECTORS, SET_PROBE_LOADING, CLEAR_PROBE } from "../../../../ducks/probe";
import { selectors as NODE_SELECTORS } from "../../../../ducks/node";

import { INPUT_STREAM_TYPE, MESSAGE_TYPE } from "../../../../lib/utils/constants";

import ProbeDrawerElement from "./ProbeDrawerElement";

const ProbeDrawer = ({
  getFieldValue,
  isBackup,
  loadingProbe,
  probeBackupData,
  probeMainData,
  setShowDrawer,
  showDrawer,
  validateFields,
}) => {
  const cwid = useSelector(NODE_SELECTORS.getNodeCwid);

  useEffect(() => {
    let probeTimeout;

    if (loadingProbe) {
      probeTimeout = setTimeout(() => {
        store.dispatch(SET_PROBE_LOADING(false));
      }, 20000);
    }

    return () => {
      clearTimeout(probeTimeout);
    };
  }, [loadingProbe]);

  const handleCloseDrawer = () => {
    store.dispatch(CLEAR_PROBE());

    setShowDrawer(false);
  };

  const probeRequest = async (type) => {
    try {
      await validateFields(["input"]);

      if (type === INPUT_STREAM_TYPE.main) {
        const { backup, ...inputConfig } = getFieldValue("input");

        MQTTService.sendMessage({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.PROBE_STREAM,
            input: inputConfig,
            type,
          },
        });

        store.dispatch(SET_PROBE_LOADING(true));
      }

      if (type === INPUT_STREAM_TYPE.backup) {
        const backupConfig = getFieldValue(["input", "backup", "backupConnection"]);

        MQTTService.sendMessage({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.PROBE_STREAM,
            input: backupConfig,
            type,
          },
        });
        store.dispatch(SET_PROBE_LOADING(true));
      }

      setShowDrawer(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Validation Error", error);
    }
  };

  return (
    <Drawer
      title={<FormattedMessage id="ProbeDrawer.probeStream" defaultMessage="Probe stream" />}
      placement="right"
      onClose={handleCloseDrawer}
      open={showDrawer}
      width="60%"
      closable
    >
      <Spin tip={<FormattedMessage id="ProbeDrawer.probing" defaultMessage="Probing..." />} spinning={loadingProbe}>
        <Card
          title={<FormattedMessage id="ProbeDrawer.mainStream" defaultMessage="Main Stream" />}
          extra={
            <Button
              type="primary"
              onClick={() => probeRequest(INPUT_STREAM_TYPE.main)}
              icon={probeMainData ? <ReloadOutlined /> : <SearchOutlined />}
            >
              <span>
                {!probeMainData && <FormattedMessage id="ProbeDrawer.probe" defaultMessage="Probe" />}
                {probeMainData && <FormattedMessage id="ProbeDrawer.reloadProbe" defaultMessage="Reload probe" />}
              </span>
            </Button>
          }
        >
          {!probeMainData && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          {probeMainData && <ProbeDrawerElement data={probeMainData} />}
        </Card>
        {isBackup && (
          <>
            <Divider />
            <Card
              title={<FormattedMessage id="ProbeDrawer.backupStream" defaultMessage="Backup Stream" />}
              extra={
                <Button
                  type="primary"
                  onClick={() => probeRequest(INPUT_STREAM_TYPE.backup)}
                  icon={probeBackupData ? <ReloadOutlined /> : <SearchOutlined />}
                >
                  <span>
                    {!probeBackupData && <FormattedMessage id="ProbeDrawer.probe" defaultMessage="Probe" />}
                    {probeBackupData && <FormattedMessage id="ProbeDrawer.reloadProbe" defaultMessage="Reload probe" />}
                  </span>
                </Button>
              }
            >
              {!probeBackupData && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              {probeBackupData && <ProbeDrawerElement data={probeBackupData} />}
            </Card>
          </>
        )}
      </Spin>
    </Drawer>
  );
};

ProbeDrawer.propTypes = {
  getFieldValue: PropTypes.func.isRequired,
  isBackup: PropTypes.bool.isRequired,
  loadingProbe: PropTypes.bool.isRequired,
  probeBackupData: PropTypes.object,
  probeMainData: PropTypes.object,
  setShowDrawer: PropTypes.func.isRequired,
  showDrawer: PropTypes.bool.isRequired,
  validateFields: PropTypes.func.isRequired,
};

ProbeDrawer.defaultProps = {
  probeBackupData: null,
  probeMainData: null,
};

const mapStateToProps = (state) => ({
  loadingProbe: PROBE_SELECTORS.getProbeLoading(state),
  probeMainData: PROBE_SELECTORS.getMainProbe(state),
  probeBackupData: PROBE_SELECTORS.getBackupProbe(state),
});

export default connect(mapStateToProps, null)(ProbeDrawer);
