import React, { useState } from "react";
import { DatePicker, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";
import dayjs from "dayjs";

import store from "../../store";

import { actions as MQTT_ACTIONS, selectors as MQTT_SELECTORS } from "../../ducks/mqtt";

const { RangePicker } = DatePicker;

export default function NodeNetworkTrafficDate({ showTitle }) {
  const selectedNetworkTrafficDate = useSelector(MQTT_SELECTORS.getNetworkOutputTrafficDate);

  const [dates, setDates] = useState(selectedNetworkTrafficDate);
  const [selectedDates, setSelectedDates] = useState(selectedNetworkTrafficDate);

  const handleChangeDate = (date) => {
    store.dispatch(MQTT_ACTIONS.NODE_SET_NETWORK_OUTPUT_DATE([dayjs(date[0]), dayjs(date[1])]));
    store.dispatch(MQTT_ACTIONS.NODE_CLEAR_NETWORK_OUTPUT_DATA());
    setSelectedDates(date);
  };

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }

    const tooLate = (dates[0] && current.diff(dates[0], "days") > 31) || (current && current > dayjs().endOf("day"));
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 31;

    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
    },
    {
      label: "Last Week",
      value: [dayjs().startOf("week"), dayjs().endOf("day")],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("day")],
    },
    {
      label: "Previous Month",
      value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")],
    },
  ];

  return (
    <>
      {showTitle && (
        <div>
          <FormattedMessage id="NodeNetworkTrafficDate.networkOutputTraffic" defaultMessage="Network output traffic" />
          <StyledTooltip
            placement="top"
            title={
              <FormattedMessage
                id="NodeNetworkTrafficDate.info2"
                defaultMessage="Total node network output traffic between selected date"
              />
            }
          >
            <QuestionCircleFilled />
          </StyledTooltip>
        </div>
      )}
      <StyledRangePicker
        value={dates || selectedDates}
        disabledDate={disabledDate}
        onCalendarChange={(val) => setDates(val)}
        onChange={handleChangeDate}
        onOpenChange={onOpenChange}
        allowClear={false}
        presets={rangePresets}
      />
    </>
  );
}

const StyledTooltip = styled(Tooltip)`
  margin-left: 4px;
`;

const StyledRangePicker = styled(RangePicker)`
  width: 250px;
`;
