import React from "react";
import { Skeleton, Col, Alert, Tag, Card, Row, Badge, Image, Input, Form } from "antd";
import { EnvironmentOutlined, DollarOutlined, UserOutlined, GlobalOutlined, PhoneOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import themeColor from "../../lib/style/theme";
import OrganizationColors from "../../lib/utils/organizationColors";
import COUNTRIES_LIST from "../../lib/utils/countries";
import { ORGANIZATION_PROFILES } from "../../lib/utils/constants";

import OrganizationValidation from "../OrganizationValidation";

const { Ribbon } = Badge;

const OrganizationDetail = ({ selectedOrganizationData }) => {
  const { public: isOrganizationPublic, status: organizationStatus, profiles } = selectedOrganizationData || {};

  const selectedCountryData = COUNTRIES_LIST.find((country) => country.code === selectedOrganizationData.country);
  const imageHash = Date.now();

  return (
    <>
      <StyledCol span={24}>
        <Ribbon
          text={
            <OrganizationValidation
              organizationData={selectedOrganizationData}
              organizationId={selectedOrganizationData.id}
            />
          }
          color={OrganizationColors({ isOrganizationPublic, organizationStatus })}
        >
          <StyledCard title={<FormattedMessage id="organizationDetail.title" defaultMessage="Organization detail" />}>
            <Row gutter={[24, 24]}>
              <Col span={4}>
                {selectedOrganizationData.logoUrl && (
                  <StyledImage>
                    <Image width={100} src={`${selectedOrganizationData.logoUrl}?${imageHash}`} />
                  </StyledImage>
                )}
                {!selectedOrganizationData.logoUrl && <Skeleton.Image />}
              </Col>
              <Col span={18}>{selectedOrganizationData.description}</Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>
                {profiles &&
                  profiles.length > 0 &&
                  profiles.map((profile) => {
                    const profileLabel = ORGANIZATION_PROFILES.find((orgProfile) => orgProfile.value === profile);

                    return (
                      <StyledTag color="default" key={profile}>
                        {profileLabel.label}
                      </StyledTag>
                    );
                  })}
              </Col>
            </Row>
            <br />
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item label={<FormattedMessage id="organizationDetail.name" defaultMessage="Name" />}>
                  <Input addonBefore={<UserOutlined />} disabled value={selectedOrganizationData.name} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={<FormattedMessage id="organizationDetail.address1" defaultMessage="Address (line 1)" />}
                >
                  <Input addonBefore={<EnvironmentOutlined />} disabled value={selectedOrganizationData.address1} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={<FormattedMessage id="organizationDetail.address2" defaultMessage="Address (line 2)" />}
                >
                  <Input addonBefore={<EnvironmentOutlined />} disabled value={selectedOrganizationData.address2} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<FormattedMessage id="organizationDetail.country" defaultMessage="Country" />}>
                  <Input addonBefore={<GlobalOutlined />} disabled value={selectedCountryData?.name} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<FormattedMessage id="organizationDetail.taxId" defaultMessage="Tax ID" />}>
                  <Input addonBefore={<DollarOutlined />} disabled value={selectedOrganizationData.taxId} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<FormattedMessage id="organizationDetail.phone" defaultMessage="Phone" />}>
                  <Input addonBefore={<PhoneOutlined />} disabled value={selectedOrganizationData.phone} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Alert
                  message={
                    <span>
                      <FormattedMessage
                        id="organizationDetail.organizationWarning"
                        defaultMessage="If you our organization database contains incorrect data. Please"
                      />{" "}
                      <a href="mailto: hello@quickstream.tech" aria-label="contact as">
                        <FormattedMessage id="organizationDetail.contactUs" defaultMessage="contact us" />
                      </a>
                      .
                    </span>
                  }
                  type="warning"
                  showIcon
                />
              </Col>
            </Row>
          </StyledCard>
        </Ribbon>
      </StyledCol>
    </>
  );
};

const StyledImage = styled.div`
  .ant-image-img {
    -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  }
`;

const StyledTag = styled(Tag)`
  margin-bottom: 5px;
`;

const StyledCard = styled(Card)`
  width: 100%;

  .ant-card-head {
    background-color: ${themeColor.orange};
  }

  .ant-card-head-title {
    color: #ffffff;
  }
`;

const StyledCol = styled(Col)`
  margin-top: 15px;
`;

OrganizationDetail.propTypes = {
  selectedOrganizationData: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    description: PropTypes.string,
    phone: PropTypes.string,
    country: PropTypes.string,
    creator: PropTypes.number,
    id: PropTypes.number,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    profiles: PropTypes.arrayOf(PropTypes.string),
    public: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    taxId: PropTypes.string,
    status: PropTypes.string,
  }),
};

OrganizationDetail.defaultProps = {
  selectedOrganizationData: null,
};

export default OrganizationDetail;
