import {
  MPEG2PROFILE,
  SELECT_DEFAULT_AUTO,
  SELECT_STREAM_CODEC,
  X264_PRESET,
  X264_TUNE,
  X264_PROFILE,
  X264_LEVEL,
  OUTPUT_RTMP_PIX_FORMAT,
} from "./constants";

export default ({ codecType, setFieldValue, prefix, isOutputRtmp }) => {
  const isMpeg2videoType = codecType === SELECT_STREAM_CODEC[1].value;
  const isOpenH264Type = codecType === SELECT_STREAM_CODEC[2].value;
  const isH264Type = codecType === SELECT_STREAM_CODEC[3].value;

  if (isMpeg2videoType) {
    const codec = {
      convert: false,
      pid: 0,
      videoCodec: {
        mpeg2Profile: MPEG2PROFILE[0].value,
        interlaced: false,
        changeGOP: false,
        type: codecType,
      },
      videoFormat: {
        resize: SELECT_DEFAULT_AUTO.value,
        pixFormat: SELECT_DEFAULT_AUTO.value,
      },
    };
    setFieldValue(prefix, codec);
  }

  if (isOpenH264Type) {
    const codec = {
      convert: false,
      pid: 0,
      videoCodec: {
        interlaced: false,
        changeGOP: false,
        type: codecType,
      },
      videoFormat: {
        resize: SELECT_DEFAULT_AUTO.value,
        pixFormat: isOutputRtmp ? OUTPUT_RTMP_PIX_FORMAT[0].value : SELECT_DEFAULT_AUTO.value,
      },
    };
    setFieldValue(prefix, codec);
  }

  if (isH264Type) {
    const codec = {
      convert: false,
      pid: 0,
      videoCodec: {
        mpeg2Profile: MPEG2PROFILE[0].value,
        interlaced: false,
        changeGOP: false,
        x264Preset: X264_PRESET[2].value,
        x264Tune: X264_TUNE[0].value,
        x264Profile: X264_PROFILE[3].value,
        x264Level: X264_LEVEL[0].value,
        x264VbvBufsize: 4,
        type: codecType,
      },
      videoFormat: {
        resize: SELECT_DEFAULT_AUTO.value,
        pixFormat: isOutputRtmp ? OUTPUT_RTMP_PIX_FORMAT[0].value : SELECT_DEFAULT_AUTO.value,
      },
    };
    setFieldValue(prefix, codec);
  }
};
