import PropTypes from "prop-types";

import { OUTPUTS_TYPES, PLAYOUT_OUTPUT_TYPES } from "../../../../lib/utils/constants";

const EnginePlayoutOutputElementLabel = ({ type }) => {
  const isNdiType = type === OUTPUTS_TYPES.outputNdi.value;
  const isRtmpTypes = type === OUTPUTS_TYPES.outputRtmp.value;
  const isOutputUrlType = type === OUTPUTS_TYPES.outputUrl.value;
  const isOutputSDIType = type === OUTPUTS_TYPES.outputDecklink.value;
  const isOutputPlayoutURL = type === PLAYOUT_OUTPUT_TYPES.playoutUrl.value;
  const isOutputPlayoutDecklink = type === PLAYOUT_OUTPUT_TYPES.playoutDecklink.value;

  if (isNdiType) {
    return OUTPUTS_TYPES.outputNdi.label;
  }

  if (isRtmpTypes) {
    return OUTPUTS_TYPES.outputRtmp.label;
  }

  if (isOutputUrlType) {
    return OUTPUTS_TYPES.outputUrl.label;
  }

  if (isOutputSDIType) {
    return OUTPUTS_TYPES.outputDecklink.label;
  }

  if (isOutputPlayoutURL) {
    return PLAYOUT_OUTPUT_TYPES.playoutUrl.label;
  }

  if (isOutputPlayoutDecklink) {
    return PLAYOUT_OUTPUT_TYPES.playoutDecklink.label;
  }

  return type && type.toUpperCase();
};

EnginePlayoutOutputElementLabel.propTypes = {
  type: PropTypes.string.isRequired,
};

export default EnginePlayoutOutputElementLabel;
