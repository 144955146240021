import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import { Badge } from "antd";

import { MQTT_STATUS, NODE_TYPES } from "../../lib/utils/constants";
import themeColor from "../../lib/style/theme";

export default function NodesListIcon({ online, type }) {
  return (
    <>
      {!online && type === NODE_TYPES.CLOUD && <LoadingOutlined />}
      {!online && type === NODE_TYPES.USER && <StyledBadge status="processing" color={themeColor.defaultBlueColor} />}
      {!online && type === NODE_TYPES.SHARED && <StyledBadge status="error" />}
      {online === MQTT_STATUS.DISCONNECTED && (
        <>
          <StyledBadge status="error" />
        </>
      )}
      {online === MQTT_STATUS.CONNECTED && (
        <>
          <StyledBadge status="processing" color={themeColor.green} />
        </>
      )}
    </>
  );
}

const StyledBadge = styled(Badge)`
  padding-right: 8px;
`;

NodesListIcon.propTypes = {
  online: PropTypes.string,
  type: PropTypes.string,
};

NodesListIcon.defaultProps = {
  online: null,
  type: null,
};
