import React, { useContext } from "react";
import { Col, Row, Form, Switch, Divider } from "antd";
import { defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";

import InputNumberField from "../../../../Fields/InputNumberField";
import InputTextField from "../../../../Fields/InputTextField";

import VideoStream from "./VideoStream";
import AudioStreams from "./AudioStreams";
import PacketSizeLabel from "./PacketSizeLabel";
import AdvancedSettings from "../../../../AdvancedSettings";

import { max32characters, az09space, required, ASCIICharacters } from "../../../../../lib/utils/formValidation";
import generalTranslations from "../../../../../lib/translations";

import { FormV5Context } from "../../../NodeChannelFormV5";

const translations = defineMessages({
  transportStreamId: {
    id: "EngineTranscodingMuxer.transportStreamId",
    defaultMessage: "Transport Stream ID",
  },
  originalNetworkId: {
    id: "EngineTranscodingMuxer.originalNetworkId",
    defaultMessage: "Original Network ID",
  },
  pmtStartPid: {
    id: "EngineTranscodingMuxer.pmtStartPID",
    defaultMessage: "PMT Start PID",
  },
  startPid: {
    id: "EngineTranscodingMuxer.startPid",
    defaultMessage: "Start PID",
  },
  muxrate: {
    id: "EngineTranscodingMuxer.muxrate[kbps]",
    defaultMessage: "Muxrate [kbps]",
  },
  CBRModeOn: {
    id: "EngineTranscodingMuxer.CBRModeOn",
    defaultMessage: "CBR Mode ON",
  },
  CBRModeOff: {
    id: "EngineTranscodingMuxer.CBRModeOff",
    defaultMessage: "CBR Mode OFF",
  },
  mpegtsServiceProvider: {
    id: "EngineTranscodingMuxer.mpegtsServiceProvider",
    defaultMessage: "Service provider",
  },
  mpegtsServiceName: {
    id: "EngineTranscodingMuxer.mpegtsServiceName",
    defaultMessage: "Service name",
  },
  mpegtsServiceId: {
    id: "EngineTranscodingMuxer.mpegtsServiceId",
    defaultMessage: "Service ID",
  },
});

const { Item } = Form;

function EngineTranscodingMuxer({ disabled, prefix, setMuxerData }) {
  const { formatMessage } = useIntl();

  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldsValue } = form;
  const useCBRModeSwitch = getFieldValue([...prefix, "mpegtsCBRMode"]);

  const handleChangeMuxrateSwitch = (muxrateSwitch) => {
    const outputs = getFieldValue(["outputs"]);

    outputs[[prefix[1]]].mpegtsCBRMode = muxrateSwitch;
    if (muxrateSwitch) {
      delete outputs[[prefix[1]]].mpegtsCBRMuxrate;
      outputs[[prefix[1]]].mpegtsCBRForcePacketSize = false;
    }
    setFieldsValue({ outputs });
    setMuxerData(outputs);
  };

  return (
    <Row justify="start" gutter={[24, 24]}>
      <Col span={24}>
        <Row>
          <Col span={6}>
            <Item name={[...prefix, "mpegtsCBRMode"]} valuePropName="checked">
              <Switch
                disabled={disabled}
                defaultChecked={useCBRModeSwitch}
                checkedChildren={formatMessage(translations.CBRModeOn)}
                unCheckedChildren={formatMessage(translations.CBRModeOff)}
                onChange={handleChangeMuxrateSwitch}
              />
            </Item>
          </Col>
          {useCBRModeSwitch && (
            <>
              <Col span={6}>
                <InputNumberField
                  disabled={disabled}
                  name={[...prefix, "mpegtsCBRMuxrate"]}
                  label={formatMessage(translations.muxrate)}
                  rules={[required]}
                  min={1}
                />
              </Col>
              <Col span={6}>
                <Item
                  name={[...prefix, "mpegtsCBRForcePacketSize"]}
                  valuePropName="checked"
                  label={<PacketSizeLabel />}
                  rules={[required]}
                >
                  <Switch
                    disabled={disabled}
                    defaultChecked={false}
                    checkedChildren={formatMessage(generalTranslations.on)}
                    unCheckedChildren={formatMessage(generalTranslations.off)}
                  />
                </Item>
              </Col>
            </>
          )}
        </Row>
        <AdvancedSettings>
          <Row justify="start" gutter={24}>
            <Col span={12}>
              <InputTextField
                label={formatMessage(translations.mpegtsServiceName)}
                name={[...prefix, "mpegtsServiceName"]}
                disabled={disabled}
                rules={[max32characters, az09space, required]}
              />
            </Col>
            <Col span={12}>
              <InputTextField
                label={formatMessage(translations.mpegtsServiceProvider)}
                name={[...prefix, "mpegtsServiceProvider"]}
                disabled={disabled}
                rules={[max32characters, ASCIICharacters, required]}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsServiceId"]}
                label={formatMessage(translations.mpegtsServiceId)}
                rules={[required]}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsTransportStreamId"]}
                label={formatMessage(translations.transportStreamId)}
                rules={[required]}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsOriginalNetworkId"]}
                label={formatMessage(translations.originalNetworkId)}
                rules={[required]}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsStartPid"]}
                label={formatMessage(translations.startPid)}
                rules={[required]}
                min={32}
                max={8186}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsPmtStartPid"]}
                label={formatMessage(translations.pmtStartPid)}
                rules={[required]}
                min={32}
                max={8186}
              />
            </Col>
          </Row>
        </AdvancedSettings>
      </Col>
      <VideoStream disabled={disabled} prefix={prefix} />
      <Divider />
      <AudioStreams disabled={disabled} prefix={prefix} />
    </Row>
  );
}

EngineTranscodingMuxer.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  setMuxerData: PropTypes.func.isRequired,
};

export default EngineTranscodingMuxer;
