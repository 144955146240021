import React from "react";
import { Form, Select } from "antd";
import { FormattedMessage } from "react-intl";

import VIRTUAL_CHANNEL_TYPES from "../../../../lib/utils/virtualChannelTypes";

const { Item } = Form;
const { Option } = Select;

const VirtualChannelType = () => {
  const handleNormalize = (value) => {
    if (!value) {
      return null;
    }

    return value;
  };

  return (
    <Item
      label={<FormattedMessage id="VirtualChannelType.channelType" defaultMessage="Channel type" />}
      name="channelType"
      normalize={handleNormalize}
    >
      <Select
        allowClear
        showSearch
        placeholder={<FormattedMessage id="general.pleaseSelect" defaultMessage="Please select" />}
        optionFilterProp="children"
      >
        {VIRTUAL_CHANNEL_TYPES.map((type) => (
          <Option value={type} key={type}>
            {type}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default VirtualChannelType;
