import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Divider, Spin, Badge } from "antd";

import IncompleteConfig from "../IncompleteConfig";

import themeColor from "../../lib/style/theme";
import formatLabels from "../../lib/utils/formatLabels";

const { Ribbon } = Badge;

const DecklinkElement = ({
  selectedInputDesc,
  label,
  handleClick,
  format,
  incomplete,
  title,
  audioChannels,
  audioSource,
  videoSource,
}) => {
  const noIncomplete = !incomplete || (incomplete && incomplete.length === 0);
  const parsedFormatLabel = format in formatLabels ? formatLabels[format] : format;

  return (
    <Ribbon
      text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
      color={noIncomplete ? themeColor.green : themeColor.orange}
    >
      <StyledContainer onClick={handleClick}>
        <StyledAsideType>{label}</StyledAsideType>
        <StyledBoldText>{title}</StyledBoldText>
        <StyledDiv>
          <StyledText>
            <FormattedMessage id="DecklinkElement.device" defaultMessage="Device" />:
          </StyledText>
          <StyledDevice>
            <Spin spinning={!selectedInputDesc}>{selectedInputDesc}</Spin>
          </StyledDevice>
        </StyledDiv>
        <StyledDiv>
          <div>
            <StyledText>
              <FormattedMessage id="DecklinkElement.format" defaultMessage="Format" />:
            </StyledText>
            <StyledBoldText>{parsedFormatLabel}</StyledBoldText>
          </div>
        </StyledDiv>
        <div>
          <StyledText>
            <FormattedMessage id="DecklinkElement.audioChannels" defaultMessage="Audio channels" />:
          </StyledText>
          <StyledBoldText>{audioChannels}</StyledBoldText>
        </div>

        <StyledDiv>
          <div>
            <StyledText>
              <FormattedMessage id="DecklinkElement.audioSource" defaultMessage="Audio source" />:
            </StyledText>
            <StyledBoldText>{audioSource}</StyledBoldText>
          </div>
          <Divider type="vertical" />
          <div>
            <StyledText>
              <FormattedMessage id="DecklinkElement.videoSource" defaultMessage="Video source" />:
            </StyledText>
            <StyledBoldText>{videoSource}</StyledBoldText>
          </div>
        </StyledDiv>
      </StyledContainer>
    </Ribbon>
  );
};

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledDevice = styled.span`
  min-width: 25px;
  font-weight: bold;
`;

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 105px;
  margin: 0 0 15px 30px;
  padding: 10px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  color: #fff;
  padding: 5px;
  line-height: 24px;
  position: absolute;
  top: 104px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 105px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;

DecklinkElement.propTypes = {
  selectedInputDesc: PropTypes.string,
  format: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  incomplete: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.string.isRequired,
};

DecklinkElement.defaultProps = {
  selectedInputDesc: null,
  incomplete: null,
};

export default DecklinkElement;
