import {
  MPEG2AUDIO_BITRATE,
  MPEG2AUDIO_SAMPLE_FORMAT,
  MPEG2AUDIO_SAMPLE_RATE,
  MPEG2PROFILE,
  SELECT_DEFAULT_AUTO,
  SELECT_STREAM_CODEC_AUDIO,
  SELECT_STREAM_CODEC,
  X264_PRESET,
  X264_TUNE,
  X264_PROFILE,
  X264_LEVEL,
  AAC_SAMPLE_FORMAT,
  AAC_SAMPLE_RATE,
  AAC_PROFILE,
  OUTPUT_RTMP_PIX_FORMAT,
} from "./constants";

export default ({ codecType, setFieldValue, prefix, isOutputRtmp }) => {
  const isMpeg2videoType = codecType === SELECT_STREAM_CODEC[1].value;
  const isOpenH264Type = codecType === SELECT_STREAM_CODEC[2].value;
  const isMpeg2audioType = codecType === SELECT_STREAM_CODEC_AUDIO[1].value;
  const isH264Type = codecType === SELECT_STREAM_CODEC[3].value;
  const isAACType = codecType === SELECT_STREAM_CODEC_AUDIO[2].value;

  if (isMpeg2videoType) {
    const codec = {
      resize: SELECT_DEFAULT_AUTO.value,
      fps: SELECT_DEFAULT_AUTO.value,
      pixFormat: SELECT_DEFAULT_AUTO.value,
      mpeg2Profile: MPEG2PROFILE[0].value,
      interlaced: false,
      changeGOP: false,
      type: codecType,
    };
    setFieldValue(prefix, codec);
  }

  if (isMpeg2audioType) {
    const codec = {
      b: MPEG2AUDIO_BITRATE[0].value,
      sampleFormat: MPEG2AUDIO_SAMPLE_FORMAT[0].value,
      sampleRate: MPEG2AUDIO_SAMPLE_RATE[0].value,
      type: codecType,
    };
    setFieldValue(prefix, codec);
  }

  if (isAACType) {
    const codec = {
      b: MPEG2AUDIO_BITRATE[0].value,
      sampleFormat: AAC_SAMPLE_FORMAT[0].value,
      sampleRate: AAC_SAMPLE_RATE[0].value,
      aacProfile: AAC_PROFILE[0].value,
      type: codecType,
    };
    setFieldValue(prefix, codec);
  }

  if (isOpenH264Type) {
    const codec = {
      resize: SELECT_DEFAULT_AUTO.value,
      fps: SELECT_DEFAULT_AUTO.value,
      pixFormat: isOutputRtmp ? OUTPUT_RTMP_PIX_FORMAT[0].value : SELECT_DEFAULT_AUTO.value,
      interlaced: false,
      changeGOP: false,
      type: codecType,
    };
    setFieldValue(prefix, codec);
  }

  if (isH264Type) {
    const codec = {
      resize: SELECT_DEFAULT_AUTO.value,
      fps: SELECT_DEFAULT_AUTO.value,
      pixFormat: isOutputRtmp ? OUTPUT_RTMP_PIX_FORMAT[0].value : SELECT_DEFAULT_AUTO.value,
      mpeg2Profile: MPEG2PROFILE[0].value,
      interlaced: false,
      changeGOP: false,
      x264Preset: X264_PRESET[2].value,
      x264Tune: X264_TUNE[0].value,
      x264Profile: X264_PROFILE[3].value,
      x264Level: X264_LEVEL[0].value,
      x264VbvBufsize: 4,
      type: codecType,
    };
    setFieldValue(prefix, codec);
  }
};
