import React, { useState, useEffect } from "react";
import { Form, Select, Input, Button, DatePicker } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useSelector } from "react-redux";
import debounce from "debounce-promise";
import dayjs from "dayjs";

import LicenseServices from "../../services/license";
import { required, max79characters, az09Extra, trim } from "../../lib/utils/formValidation";
import { errorNotification, successNotification } from "../../lib/utils/notification";

import { selectors as ADMIN_SELECTORS } from "../../ducks/admin";
import AdminService from "../../services/admin";

const { Item } = Form;
const { Option } = Select;

const translations = defineMessages({
  enginesNumberPlaceholder: {
    id: "NewPlayoutLicenseForm.enginesNumberPlaceholder",
    defaultMessage: "How many channels license can use",
  },
  licenseId: {
    id: "NewPlayoutLicenseForm.licenseId",
    defaultMessage: "Name of the license",
  },
  uniqLicense: {
    id: "NewPlayoutLicenseForm.uniqLicense",
    defaultMessage: "This license ID is already taken",
  },
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 17 },
  },
};

export default function NewPlayoutLicenseForm({ setShowNewLicenseModal }) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const accountList = useSelector(ADMIN_SELECTORS.getAccountList);
  const [username, setUserName] = useState(null);

  useEffect(() => {
    AdminService.getAccountList({ errorNotification });
  }, []);

  const { resetFields } = form;

  const handleChangeUsername = (value) => {
    setUserName(value);
    resetFields(["licenseId"]);
  };

  const validateIsUnique = debounce(async (rule, value) => {
    const prefixedLicenseId = `${username}-${value}`;

    const regex = /^[a-zA-Z0-9]$|^[a-zA-Z0-9]+[a-zA-Z0-9- !@#$%^&*()-_=+]*[a-zA-Z0-9-!@#$%^&*()-_=+]+$/g;
    const isCorrect = value.match(regex);

    if (isCorrect) {
      const { uniqLicense } = await LicenseServices.playoutUniqLicense(prefixedLicenseId, {
        errorNotification: errorNotification(formatMessage),
      });

      if (!uniqLicense) {
        return Promise.reject(formatMessage(translations.uniqLicense));
      }
    }

    return Promise.resolve();
  }, 500);

  const handleFinish = (formData) => {
    const prefixedData = { ...formData, licenseId: `${username}-${formData?.licenseId}` };

    LicenseServices.addNewPlayoutLicense(prefixedData, {
      errorNotification: errorNotification(formatMessage),
      successNotification: successNotification(formatMessage),
    });
    setShowNewLicenseModal(false);
    setUserName(null);
    resetFields();
  };

  return (
    <Form form={form} onFinish={handleFinish} {...formItemLayout}>
      <Item
        name="username"
        label={<FormattedMessage id="NewPlayoutLicenseForm.username" defaultMessage="License for user" />}
        rules={[required]}
      >
        <Select onChange={handleChangeUsername} showSearch>
          {accountList.map((account) => (
            <Option value={account.username} key={account.id}>
              {account.username}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        name="licenseId"
        label={<FormattedMessage id="NewPlayoutLicenseForm.licenseIdLabel" defaultMessage="License ID" />}
        rules={[required, trim, az09Extra, { validator: validateIsUnique }]}
      >
        <Input
          disabled={!username}
          addonBefore={username ? `${username}-` : ""}
          placeholder={formatMessage(translations.licenseId)}
          maxLength={79}
        />
      </Item>
      <Item
        name="companyName"
        label={<FormattedMessage id="NewPlayoutLicenseForm.companyName" defaultMessage="Company name" />}
        rules={[required, trim, az09Extra, max79characters]}
      >
        <Input maxLength={79} />
      </Item>
      <Item
        name="channelName"
        label={<FormattedMessage id="NewPlayoutLicenseForm.channelName" defaultMessage="Channel name" />}
        rules={[required, trim, az09Extra, max79characters]}
      >
        <Input maxLength={79} />
      </Item>
      <Item
        name="expireDate"
        label={<FormattedMessage id="NewPlayoutLicenseForm.licenseExpireDate" defaultMessage="License expire date" />}
        rules={[required]}
      >
        <DatePicker minDate={dayjs(dayjs(), "YYYY-MM-DD")} format="YYYY-MM-DD" />
      </Item>
      <Button block type="primary" htmlType="submit">
        <span>
          <FormattedMessage id="NewPlayoutLicenseForm.submit" defaultMessage="Create new license" />
        </span>
      </Button>
    </Form>
  );
}
