import React, { useCallback, useState } from "react";
import { Card, Table, Tag, Radio, Popconfirm, Space } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import OrganizationsServices from "../../services/organizations";

import { selectors as ORGANIZATIONS_SELECTORS } from "../../ducks/organizations";
import { errorNotification } from "../../lib/utils/notification";
import { SEO_STATUS } from "../../lib/utils/constants";
import globalTranslation from "../../lib/translations";
import themeColors from "../../lib/style/theme";

const OrganizationsRolesList = () => {
  const { formatMessage } = useIntl();
  const [confirmVisible, setConfirmVisible] = useState(null);

  const rolesList = useSelector(ORGANIZATIONS_SELECTORS.getOrganizationsRoles);
  const organizationsList = useSelector(ORGANIZATIONS_SELECTORS.getOrganizationsList);

  const showConfirm = useCallback((data) => {
    setConfirmVisible(data);
  }, []);

  const hideConfirm = useCallback(() => {
    setConfirmVisible(null);
  }, []);

  const handleChangeStatus = useCallback(() => {
    OrganizationsServices.changeOrganizationRoleStatus(confirmVisible, {
      errorNotification: errorNotification(formatMessage),
    });
    hideConfirm();
  }, [confirmVisible]);

  const columns = [
    {
      title: <FormattedMessage id="OrganizationsRolesList.organizationId" defaultMessage="Organization" />,
      dataIndex: "organizationId",
      render: (organizationId) => {
        const organizationData = organizationsList.find((organization) => organization.id === organizationId);

        return organizationData?.name;
      },
    },
    {
      title: <FormattedMessage id="OrganizationsRolesList.username" defaultMessage="Username" />,
      dataIndex: "username",
      render: (username) => <Link to={`/users/${username}`}>{username}</Link>,
    },
    // {
    //   title: <FormattedMessage id="OrganizationsRolesList.business" defaultMessage="Business" />,
    //   dataIndex: "business",
    //   render: (business) => {
    //     return business ? (
    //       <Tag color={themeColors.green}>
    //         <FormattedMessage id="OrganizationsRolesList.true" defaultMessage="True" />
    //       </Tag>
    //     ) : (
    //       <Tag color={themeColors.red}>
    //         <FormattedMessage id="OrganizationsRolesList.false" defaultMessage="False" />
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: <FormattedMessage id="OrganizationsRolesList.businessRole" defaultMessage="Business role" />,
      dataIndex: "businessRole",
    },
    {
      title: <FormattedMessage id="OrganizationsRolesList.name" defaultMessage="Name" />,
      dataIndex: "businessName",
    },
    {
      title: <FormattedMessage id="OrganizationsRolesList.businessEmail" defaultMessage="Business email" />,
      dataIndex: "businessEmail",
    },
    // {
    //   title: <FormattedMessage id="OrganizationsRolesList.technical" defaultMessage="Technical" />,
    //   dataIndex: "technical",
    //   render: (technical) => {
    //     return technical ? (
    //       <Tag color={themeColors.green}>
    //         <FormattedMessage id="OrganizationsRolesList.true" defaultMessage="True" />
    //       </Tag>
    //     ) : (
    //       <Tag color={themeColors.red}>
    //         <FormattedMessage id="OrganizationsRolesList.false" defaultMessage="False" />
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: <FormattedMessage id="OrganizationsRolesList.technicalRole" defaultMessage="Technical role" />,
      dataIndex: "technicalRole",
    },
    {
      title: <FormattedMessage id="OrganizationsRolesList.name" defaultMessage="Name" />,
      dataIndex: "technicalName",
    },
    {
      title: <FormattedMessage id="OrganizationsRolesList.technicalEmail" defaultMessage="Technical email" />,
      dataIndex: "technicalEmail",
    },
    {
      title: <FormattedMessage id="OrganizationsRolesList.public" defaultMessage="Public" />,
      dataIndex: "public",
      width: 80,
      render: (isPublic) => {
        return isPublic ? (
          <Tag color={themeColors.green}>
            <FormattedMessage id="OrganizationsRolesList.public" defaultMessage="Public" />
          </Tag>
        ) : (
          <Tag color={themeColors.lightRed}>
            <FormattedMessage id="OrganizationsRolesList.private" defaultMessage="Private" />
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        {
          text: SEO_STATUS.VERIFICATION.toUpperCase(),
          value: SEO_STATUS.VERIFICATION,
        },
        {
          text: SEO_STATUS.ACCEPTED.toUpperCase(),
          value: SEO_STATUS.ACCEPTED,
        },
        {
          text: SEO_STATUS.REJECTED.toUpperCase(),
          value: SEO_STATUS.REJECTED,
        },
      ],
      defaultFilteredValue: [SEO_STATUS.VERIFICATION],
      onFilter: (value, record) => record.status && record.status.indexOf(value) === 0,
      render: (status, { id }) => {
        return (
          <Popconfirm
            key={id}
            open={confirmVisible?.id === id}
            placement="top"
            title={
              <>
                <FormattedMessage
                  id="OrganizationsRolesList.change"
                  defaultMessage="Are you sure you want to change role to"
                />{" "}
                <b>{confirmVisible?.status.toUpperCase()}</b>
              </>
            }
            onConfirm={() => handleChangeStatus()}
            onCancel={hideConfirm}
            okText={formatMessage(globalTranslation.yes)}
            cancelText={formatMessage(globalTranslation.no)}
          >
            <Radio.Group value={status} onChange={(event) => showConfirm({ status: event.target.value, id })}>
              <Space direction="vertical" size="small">
                <Radio.Button value={SEO_STATUS.ACCEPTED}>{SEO_STATUS.ACCEPTED.toUpperCase()}</Radio.Button>

                <Radio.Button value={SEO_STATUS.REJECTED}>{SEO_STATUS.REJECTED.toUpperCase()}</Radio.Button>
              </Space>
            </Radio.Group>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <Card title={<FormattedMessage id="OrganizationsRolesList.title" defaultMessage="Roles list" />}>
      <Table rowKey={(record) => record.id} columns={columns} dataSource={rolesList} scroll={{ x: 1080 }} />
    </Card>
  );
};

export default OrganizationsRolesList;
