import store from "../../store";
import notificationHandler from "./notificationHandler";

import NodeTranslations from "../translations/nodeTranslations";

const nodeNotificationHandler = ({ msgType, message, from }) => {
  const cloudNodes = store.getState().getIn(["mqtt", "nodes"]);
  const parsedCWID = from.split("/")[1];

  const nodeData = cloudNodes.find((node) => node.cwid === parsedCWID);
  const translation = NodeTranslations[message] || NodeTranslations["warning-0"];

  notificationHandler[msgType]({
    message: `${translation.defaultMessage} (${nodeData?.cnn})`,
  });
};

export default nodeNotificationHandler;
