import * as React from "react";
import PropTypes from "prop-types";
import { Col } from "antd";
import { useIntl } from "react-intl";

import { IPField, PortField, TTLField } from "../../Fields/Fields";
import NetworkField from "../../Fields/NetworkField";

import globalTranslations from "../../../lib/translations";

import NetworkFieldLabel from "../Labels/NetworkFieldLabel";
import UDPTTLLabel from "../Labels/UDPTTLLabel";
import LocalPortLabel from "../../LocalPortLabel";

export const OutputStreamUdpSection = ({ disabled, prefix }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Col sm={12} md={12} lg={12}>
        <IPField
          label={formatMessage(globalTranslations.addressIp)}
          name={[...prefix, "destinationAddress"]}
          disabled={disabled}
        />
      </Col>
      <Col sm={12} md={12} lg={6}>
        <PortField name={[...prefix, "destinationPort"]} disabled={disabled} />
      </Col>
      <Col sm={12} md={12} lg={6}>
        <TTLField name={[...prefix, "udpTTL"]} disabled={disabled} label={<UDPTTLLabel />} />
      </Col>
      <Col sm={12} md={12} lg={12}>
        <NetworkField name={[...prefix, "interfaceIp"]} label={NetworkFieldLabel("output")} disabled={disabled} />
      </Col>
      <Col sm={12} md={12} lg={12}>
        <PortField name={[...prefix, "localPort"]} disabled={disabled} label={<LocalPortLabel />} min={0} />
      </Col>
    </>
  );
};

OutputStreamUdpSection.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

OutputStreamUdpSection.defaultProps = {
  disabled: null,
};

export default OutputStreamUdpSection;
