import PropTypes from "prop-types";

export const DESTINATIONS_TYPES = {
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      destinationAddress: PropTypes.string,
      destinationPort: PropTypes.number,
      encryptionEnabled: PropTypes.bool,
      interfaceIp: PropTypes.string,
      localPort: PropTypes.number,
      outputId: PropTypes.number,
      outputName: PropTypes.string,
      setIPTOS: PropTypes.bool,
      setIPTTL: PropTypes.bool,
      srtLatency: PropTypes.number,
      srtOverhead: PropTypes.number,
      type: PropTypes.string,
    })
  ),
};

export default DESTINATIONS_TYPES;
