import React from "react";
import { Form, Tooltip, Checkbox } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { QuestionCircleFilled } from "@ant-design/icons";

const { Item: FromItem } = Form;

const OrganizationFormPublic = () => {
  return (
    <FromItem
      valuePropName="checked"
      label={
        <>
          <StyledLabel>
            <FormattedMessage id="OrganizationFormPublic.public" defaultMessage="Public" />
          </StyledLabel>
          <Tooltip
            title={
              <FormattedMessage
                id="OrganizationFormPublic.publicDescription"
                defaultMessage="Do you want your organization to be visible in public catalog?"
              />
            }
          >
            <QuestionCircleFilled />
          </Tooltip>
        </>
      }
      name="public"
    >
      <Checkbox />
    </FromItem>
  );
};

const StyledLabel = styled.span`
  margin-right: 5px;
`;

export default OrganizationFormPublic;
