import React, { useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { Col, Descriptions, Modal, Card, Row, Button } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import { PlusOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";

import LicenseField from "../../LicenseField";
import ChannelName from "./ChannelName";

import InputTextFiled from "../../Fields/InputTextField";

import { FORM_VERSION, LICENSES_TYPES } from "../../../lib/utils/constants";

const { Item } = Descriptions;

const translations = defineMessages({
  channelName: {
    id: "GeneralSection.channelName",
    defaultMessage: "Channel name",
  },
  license: {
    id: "GeneralSection.license",
    defaultMessage: "License",
  },
  cloudLicense: {
    id: "GeneralSection.cloudLicense",
    defaultMessage: "Cloud",
  },
});

const GeneralSection = ({
  channelConfig,
  detailView,
  editMode,
  getFieldValue,
  handleFinish,
  id,
  resetFields,
  validateFields,
}) => {
  const { formatMessage } = useIntl();
  const [generalModalVisible, setGeneralModalVisible] = useState(!editMode);

  const handleOK = async () => {
    try {
      await validateFields();
      const name = getFieldValue("name");
      const licenseId = getFieldValue("licenseId");
      const updatedFormData = { ...channelConfig, name, licenseId, version: FORM_VERSION };

      handleFinish(updatedFormData);
      setGeneralModalVisible(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("validation error", error);
    }
  };

  const handleCancel = () => {
    resetFields();

    setGeneralModalVisible(false);
  };

  return (
    <Card
      title={
        <Row justify="space-between">
          <FormattedMessage id="GeneralSection.general" defaultMessage="General" />
          {editMode && !detailView && (
            <Col>
              <Button type="primary" onClick={() => setGeneralModalVisible(true)} icon={<EditOutlined />}>
                <span>
                  <FormattedMessage id="general.edit" defaultMessage="Edit" />
                </span>
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      {!editMode && (
        <Button type="dashed" style={{ width: "100%" }} onClick={() => setGeneralModalVisible(true)}>
          <PlusOutlined /> <FormattedMessage id="GeneralSection.addGeneralData" defaultMessage="Add general data" />
        </Button>
      )}
      {editMode && (
        <StyledSection onClick={() => (detailView ? null : setGeneralModalVisible(true))}>
          <Descriptions bordered column={2} size="small">
            <Item key={channelConfig?.name} label={formatMessage(translations.channelName)}>
              <StyledBoldText>{channelConfig?.name}</StyledBoldText>
            </Item>
            <Item key={channelConfig?.licenseId} label={formatMessage(translations.license)}>
              {channelConfig?.licenseId === LICENSES_TYPES.CLOUD ? (
                <StyledBoldText>{formatMessage(translations.cloudLicense)}</StyledBoldText>
              ) : (
                <StyledBoldText>{channelConfig?.licenseId}</StyledBoldText>
              )}
            </Item>
          </Descriptions>
        </StyledSection>
      )}
      <StyledModal
        centered
        title={<FormattedMessage id="GeneralSection.general" defaultMessage="General" />}
        open={generalModalVisible}
        onOk={handleOK}
        onCancel={handleCancel}
        forceRender
        okText={
          <span>
            <FormattedMessage id="general.save" defaultMessage="Save" />
          </span>
        }
        okButtonProps={{ icon: <SaveOutlined /> }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <ChannelName name="name" label={formatMessage(translations.channelName)} channelId={id} />
          </Col>
          <Col span={12}>
            <LicenseField getFieldValue={getFieldValue} />
          </Col>
          <StyledHiddenCol span={4}>
            <InputTextFiled name="version" />
          </StyledHiddenCol>
        </Row>
      </StyledModal>
    </Card>
  );
};

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledHiddenCol = styled(Col)`
  visibility: hidden;
  display: none;
`;

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledSection = styled.div`
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

GeneralSection.propTypes = {
  channelConfig: PropTypes.object,
  detailView: PropTypes.bool,
  editMode: PropTypes.bool,
  getFieldValue: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
  id: PropTypes.string,
  resetFields: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
};

GeneralSection.defaultProps = {
  channelConfig: null,
  detailView: null,
  editMode: null,
  id: null,
};

export default GeneralSection;
