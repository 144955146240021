import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import themeColor from "../../../../lib/style/theme";
import { NODE_CHANNEL_STATE } from "../../../../lib/utils/constants";

const NodeChannelsActionStartButton = ({ handleStartChannel, waitingButton, channelStatusText }) => {
  const shouldBeDisabled = waitingButton === "delete" || ![NODE_CHANNEL_STATE.STOPPED].includes(channelStatusText);

  if (shouldBeDisabled) {
    return (
      <Button disabled>
        <FormattedMessage id="ChannelList.action.start" defaultMessage="START" />
      </Button>
    );
  }

  return (
    <SuccessButton onClick={handleStartChannel} loading={waitingButton === "start"}>
      {waitingButton !== "start" ? <FormattedMessage id="ChannelList.action.start" defaultMessage="START" /> : <span />}
    </SuccessButton>
  );
};

const SuccessButton = styled(Button)`
  background-color: ${themeColor.green} !important;
  border-color: ${themeColor.green} !important;
  color: white !important;

  &:hover {
    background-color: ${themeColor.greenHover} !important;
    border-color: ${themeColor.greenHover} !important;
    color: white !important;
  }
`;

NodeChannelsActionStartButton.propTypes = {
  handleStartChannel: PropTypes.func.isRequired,
  waitingButton: PropTypes.string,
  channelStatusText: PropTypes.string.isRequired,
};

NodeChannelsActionStartButton.defaultProps = {
  waitingButton: null,
};

export default NodeChannelsActionStartButton;
