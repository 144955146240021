import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Spin, Tooltip } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { selectors as NODE_LIVE_STATISTIC_SELECTORS } from "../../../ducks/nodeLiveStats";

import { NODE_CHANNEL_STATE, INPUT_TYPES } from "../../../lib/utils/constants";
import themeColor from "../../../lib/style/theme";
import transferSpeedValue from "../../../lib/utils/transferSpeedValue";

const NodeChannelsListBitrate = ({ channelId, channelStatus, inputType }) => {
  const lastBitrateStat = useSelector((state) => NODE_LIVE_STATISTIC_SELECTORS.getLastBitrate({ state, channelId }));

  const isConnected = channelStatus === NODE_CHANNEL_STATE.CONNECTED;
  const isInputURLType = inputType === INPUT_TYPES.inputUrl.value;

  if ((!isConnected && isInputURLType) || !isInputURLType) {
    return (
      <Tooltip
        placement="top"
        title={<FormattedMessage id="NodeChannelsListBitrate.nodeData" defaultMessage="No data" />}
      >
        N/D
      </Tooltip>
    );
  }

  return (
    <>
      {!lastBitrateStat && <StyledSpin />}
      {lastBitrateStat && <StyledBitrate>{transferSpeedValue(lastBitrateStat)}</StyledBitrate>}
    </>
  );
};

const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: ${themeColor.maritime};
  }
`;

const StyledBitrate = styled.span`
  display: block;
  color: ${themeColor.maritime};
`;

NodeChannelsListBitrate.propTypes = {
  channelStatus: PropTypes.string.isRequired,
  channelId: PropTypes.number.isRequired,
  inputType: PropTypes.string,
};

NodeChannelsListBitrate.defaultProps = {
  inputType: null,
};
export default NodeChannelsListBitrate;
