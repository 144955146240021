import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { Button, Table, Affix } from "antd";
import PropTypes from "prop-types";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { useSearchParams, useNavigate } from "react-router-dom";

import store from "../../../store";

import { selectors as NODE_SELECTORS } from "../../../ducks/node";
import { actions as NODE_CHANNELS_ACTIONS, selectors as NODE_CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";

import CloudChannelsServices from "../../../services/cloudChannels";

import { QUICKSTREAM_METHOD_V2 } from "../../../lib/utils/constants";

import NewNodeChannelModalV5 from "./NewNodeChannelModalV5";
import NodeChannelsListColumns from "./NodeChannelsListColumns";

import themeColor from "../../../lib/style/theme";
import { errorNotification } from "../../../lib/utils/notification";

const type = "DraggableBodyRow";

function DraggableBodyRow({ index, moveRow, className, style, ...restProps }) {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
}

DraggableBodyRow.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string.isRequired,
  moveRow: PropTypes.func,
  style: PropTypes.object.isRequired,
};

DraggableBodyRow.defaultProps = {
  index: null,
  moveRow: null,
};

const NodeV5ChannelsList = () => {
  const channels = useSelector(NODE_CHANNEL_SELECTORS.getChannels);
  const { formatMessage } = useIntl();
  const [visibleNewNodeChannelForm, setVisibleNewNodeChannelForm] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const queryTab = searchParams.get("tab");
  const sortedNodeChannels = useSelector(NODE_CHANNEL_SELECTORS.getSortedChannels);
  const cwid = useSelector(NODE_SELECTORS.getNodeCwid);
  const cnn = useSelector(NODE_SELECTORS.getCnn);
  const isLoadingChannelList = useSelector(NODE_CHANNEL_SELECTORS.getLoadingChannels);

  const [dataFromCloudFlag, setDataFromCloudFlag] = useState(false);

  useEffect(() => {
    if (!queryTab) {
      navigate(`/node/${cwid}/${cnn}/channels?tab=channelList`, { replace: true });
    }
  }, [queryTab, cwid, cnn]);

  useEffect(() => {
    const pagination = window.localStorage.getItem(`${cwid}-paginationNodeChannelList`);

    if (pagination) {
      navigate(`/node/${cwid}/${cnn}/channels${pagination}`, { replace: true });
    }
  }, [cwid, cnn]);

  const channelsJs = channels && channels.toJS();

  useEffect(() => {
    if (channelsJs.length < 0 || dataFromCloudFlag) {
      return;
    }

    async function fetchPermissionDescription({ cloudIds, channelId }) {
      await CloudChannelsServices.getChannelPermissionsDescription(
        { cloudIds, channelId, cwid },
        {
          errorNotification: errorNotification(formatMessage),
        }
      );
    }

    const qsOutputs = {};
    Object.values(channelsJs).forEach((channel) => {
      const hasOutputs = channel.config?.outputs && channel.config?.outputs.length > 0;

      if (hasOutputs) {
        channel.config?.outputs.forEach((output) => {
          if (output.type === QUICKSTREAM_METHOD_V2.outQSDirect.value) {
            if (qsOutputs[channel.channelId]) {
              qsOutputs[channel.channelId] = [...qsOutputs[channel.channelId], output.cloudId];
            } else {
              qsOutputs[channel.channelId] = [output.cloudId];
            }
          }

          const hasUrlDestinations = output?.urlDestinations && output?.urlDestinations.length > 0;
          if (hasUrlDestinations) {
            output.urlDestinations?.forEach((outputDestination) => {
              if (outputDestination.type === QUICKSTREAM_METHOD_V2.outQSDirect.value) {
                if (qsOutputs[channel.channelId]) {
                  qsOutputs[channel.channelId] = [...qsOutputs[channel.channelId], outputDestination.cloudId];
                } else {
                  qsOutputs[channel.channelId] = [outputDestination.cloudId];
                }
              }
            });
          }
        });
      }
    });

    Object.entries(qsOutputs).forEach((output) => {
      const [channelId, cloudIds] = output;

      const stringCloudIds = JSON.stringify(cloudIds);

      fetchPermissionDescription({ channelId, cloudIds: stringCloudIds, cwid });
      setDataFromCloudFlag(true);
    });
  }, [channels, channelsJs, cwid, dataFromCloudFlag, formatMessage]);

  const handleChange = () => {
    window.localStorage.setItem(`${cwid}-paginationNodeChannelList`, `?tab=${queryTab}`);
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = sortedNodeChannels[dragIndex];

      const sortedData = update(sortedNodeChannels, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      const sortedNodeChannelsIds = sortedData.map((channel) => channel?.channelId);

      window.localStorage.setItem(`nodeSortOrder-${cwid}`, JSON.stringify(sortedNodeChannelsIds));

      store.dispatch(NODE_CHANNELS_ACTIONS.SET_NODES_SORTED_CHANNELS(sortedData));
    },
    [sortedNodeChannels, cwid]
  );

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <StyledTable
          loading={isLoadingChannelList}
          components={components}
          dataSource={sortedNodeChannels}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
          columns={NodeChannelsListColumns({ cwid, cnn })}
          rowClassName={(record) => {
            const isConfigWrongVersionRunning = record?.status?.configWrongVersionRunning;

            return isConfigWrongVersionRunning ? "wrongVersionRunning" : null;
          }}
          rowKey={(record) => record.channelId}
          scroll={{ x: 1150 }}
          onChange={handleChange}
          pagination={false}
        />
      </DndProvider>
      <AddButton setVisibleNewNodeChannelForm={setVisibleNewNodeChannelForm} />
      <NewNodeChannelModalV5
        setVisibleNewNodeChannelForm={setVisibleNewNodeChannelForm}
        visibleNewNodeChannelForm={visibleNewNodeChannelForm}
      />
    </>
  );
};

const StyledTable = styled(Table)`
  .searchAllFilter {
    .ant-table-filter-trigger {
      display: none;
    }
  }
  .wrongVersionRunning {
    background-color: ${themeColor.lightRed};
  }
  tr.drop-over-downward td {
    border-bottom: 2px dashed ${themeColor.orange};
  }
  tr.drop-over-upward td {
    border-top: 2px dashed ${themeColor.orange};
  }
`;

function AddButton({ setVisibleNewNodeChannelForm }) {
  return (
    <Affix offsetBottom={15}>
      <StyledAddButton type="primary" onClick={() => setVisibleNewNodeChannelForm(true)}>
        <FormattedMessage id="AddButton.create" defaultMessage="Create new channel" />
      </StyledAddButton>
    </Affix>
  );
}

AddButton.propTypes = {
  setVisibleNewNodeChannelForm: PropTypes.func.isRequired,
};

const StyledAddButton = styled(Button)`
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: center;
`;

export default NodeV5ChannelsList;
