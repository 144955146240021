import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { Button, Popconfirm, Divider, Row } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { NODE_CHANNEL_STATE, FORM_VERSION_V5, MESSAGE_TYPE } from "../../../../lib/utils/constants";
import { errorNotification } from "../../../../lib/utils/notification";

import NODE_CHANNELS_SERVICES from "../../../../services/node";
import MQTTService from "../../../../services/mqtt";

import { selectors as MQTT_SELECTORS } from "../../../../ducks/mqtt";

import StyledButton from "../../../../components/StyledButton";
import NodeChannelsActionStartButton from "./StartButton";

const translations = defineMessages({
  wrongConfigurationVersion: {
    id: "ChannelListActionButtons.wrongConfigurationVersion",
    defaultMessage: "Channel configuration version not supported",
  },
});

function NodeV5ChannelListActionButtons({
  detailView,
  channelConfig,
  status: { channelStatusText, requestedStatusText },
  channelId,
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [waitingButton, setWaitingButton] = useState(null);
  const timerRef = useRef(null);
  const nodeData = useSelector(MQTT_SELECTORS.getActiveNode);

  const {
    location: { search },
  } = window;

  const oldCloudIds = [];
  const oldPermissionId = +channelConfig?.input?.mainConnection?.permissionId || false;
  if (channelConfig?.outputs && channelConfig?.outputs.length > 0) {
    channelConfig.outputs.forEach((output) => {
      const oldCloudId = output?.cloudId;
      if (oldCloudId) {
        oldCloudIds.push(output?.cloudId);
      }

      if (output.urlDestinations) {
        output?.urlDestinations?.forEach((outputDestination) => {
          if (outputDestination?.cloudId) {
            oldCloudIds.push(outputDestination?.cloudId);
          }
        });
      }
    });
  }

  const cloudData = { oldPermissionId, oldCloudIds };

  useEffect(() => {
    setWaitingButton(null);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [channelStatusText]);

  function onActionClick(actionName) {
    setWaitingButton(actionName);

    timerRef.current = setTimeout(() => {
      setWaitingButton(null);
    }, 3000);
  }

  onActionClick.propTypes = {
    actionName: PropTypes.oneOf(["start", "stop", "delete"]),
  };

  const onConfirmRemove = useCallback(() => {
    NODE_CHANNELS_SERVICES.updateChannelData(
      { ...cloudData, cwid: nodeData.cwid },
      { errorNotification: errorNotification(formatMessage) }
    );
    NODE_CHANNELS_SERVICES.clearStatistic(channelId);
    onActionClick("delete");
    MQTTService.sendMessageV5({
      topic: `node/${nodeData.cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.DELETE_CHANNEL,
        channelId,
      },
    });
    if (detailView) {
      navigate(-1);
    }
  }, [channelId, detailView]);

  const handleEditChannel = useCallback(() => {
    if (+channelConfig.version > +FORM_VERSION_V5) {
      errorNotification(formatMessage)(translations.wrongConfigurationVersion);
    } else {
      navigate(`/node/${nodeData.cwid}/${nodeData.cnn}/channel/edit/${channelId}${search}`);
    }
  }, [channelConfig, formatMessage, channelId, search]);

  const handleStartChannel = useCallback(() => {
    MQTTService.sendMessageV5({
      topic: `node/${nodeData.cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.STREAM_PLAY,
        channelId,
      },
    });
    NODE_CHANNELS_SERVICES.clearStatistic(channelId);
    onActionClick("start");
  }, [channelId]);

  return (
    <Row type="flex" justify="center" align="middle">
      <NodeChannelsActionStartButton
        handleStartChannel={handleStartChannel}
        waitingButton={waitingButton}
        channelStatusText={channelStatusText}
      />

      <Divider type="vertical" />
      <StyledButton
        onClick={useCallback(() => {
          MQTTService.sendMessageV5({
            topic: `node/${nodeData.cwid}/in`,
            message: {
              msgType: MESSAGE_TYPE.STREAM_STOP,
              channelId,
            },
          });
          onActionClick("stop");
        })}
        loading={waitingButton === "stop"}
        disabled={
          ![NODE_CHANNEL_STATE.STARTED].includes(requestedStatusText) ||
          channelStatusText === NODE_CHANNEL_STATE.PROCESS_DISCONNECTED
        }
      >
        {waitingButton !== "stop" ? <FormattedMessage id="ChannelList.actionStop" defaultMessage="STOP" /> : <span />}
      </StyledButton>
      <Divider type="vertical" />
      <Button
        onClick={handleEditChannel}
        type="dashed"
        disabled={
          ![NODE_CHANNEL_STATE.STOPPED, NODE_CHANNEL_STATE.UNCONFIGURED, NODE_CHANNEL_STATE.CONFIG_INCOMPLETE].includes(
            channelStatusText
          )
        }
      >
        <FormattedMessage id="ChannelList.action.edit" defaultMessage="EDIT" />
      </Button>
      <Divider type="vertical" />

      <Popconfirm
        placement="topRight"
        disabled={
          waitingButton === "start" ||
          [NODE_CHANNEL_STATE.CONNECTED, NODE_CHANNEL_STATE.CONNECTING, NODE_CHANNEL_STATE.RECONNECTING].includes(
            channelStatusText
          )
        }
        title={
          <FormattedMessage
            id="NodeV5ChannelListActionButtons.confirmRemove"
            defaultMessage="Are you sure, you want to remove this channel?"
          />
        }
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        onConfirm={onConfirmRemove}
        okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
        cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
      >
        <Button
          type="primary"
          danger
          disabled={
            waitingButton === "start" ||
            [
              NODE_CHANNEL_STATE.STARTED,
              NODE_CHANNEL_STATE.CONNECTED,
              NODE_CHANNEL_STATE.CONNECTING,
              NODE_CHANNEL_STATE.RECONNECTING,
            ].includes(channelStatusText)
          }
          loading={waitingButton === "delete"}
          onClick={() => null}
        >
          {waitingButton !== "delete" ? (
            <FormattedMessage id="ChannelList.action.delete" defaultMessage="DELETE" />
          ) : (
            <span />
          )}
        </Button>
      </Popconfirm>
    </Row>
  );
}

const StyledActionButton = styled(StyledButton)`
  &.ant-btn-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

// eslint-disable-next-line no-underscore-dangle
StyledActionButton.__ANT_BUTTON = true;

NodeV5ChannelListActionButtons.propTypes = {
  detailView: PropTypes.bool,
  channelId: PropTypes.string.isRequired,
  status: PropTypes.shape({
    channelStatusText: PropTypes.string.isRequired,
    requestedStatusText: PropTypes.string.isRequired,
  }).isRequired,
};

NodeV5ChannelListActionButtons.defaultProps = {
  detailView: false,
};

export default NodeV5ChannelListActionButtons;
