import React, { useContext } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Divider } from "antd";

import { GLOBAL_CODEC_AUDIO_SAMPLE_RATE } from "../../../../../lib/utils/constants";

import themeColor from "../../../../../lib/style/theme";

import { FormV5Context } from "../../../NodeChannelFormV5";

function GlobalAudioCodecElement({ type, handleClick }) {
  const { getFieldValue } = useContext(FormV5Context);
  const globalAudioFormat = getFieldValue("globalAudioFormats");
  const firstGlobalAudioFormat = globalAudioFormat && globalAudioFormat[0];

  const channels = firstGlobalAudioFormat?.channels;

  // const { label: sampleFormat } = GLOBAL_CODEC_AUDIO_SAMPLE_FORMATS.find(
  //   (audioSampleFormat) => firstGlobalAudioFormat?.sampleFormat === audioSampleFormat.value
  // );

  const { label: sampleRate } = GLOBAL_CODEC_AUDIO_SAMPLE_RATE.find(
    (audioSampleRate) => audioSampleRate.value === firstGlobalAudioFormat?.sampleRate
  );

  return (
    <StyledContainer onClick={handleClick}>
      <StyledAsideType>{type}</StyledAsideType>
      <StyledBoldTitle>
        <FormattedMessage id="GlobalAudioCodecElement.processingAudioFormat" defaultMessage="Processing audio format" />
      </StyledBoldTitle>
      <div>
        <StyledText>
          <FormattedMessage id="GlobalAudioCodecElement.channels" defaultMessage="Channels" />
          {": "}
        </StyledText>

        <StyledBoldTitle>{channels}</StyledBoldTitle>
        {/* <Divider type="vertical" />
        <StyledText>
          <FormattedMessage id="GlobalAudioCodecElement.sampleFormat" defaultMessage="Sample format" />
          {": "}
        </StyledText> */}
        {/* <StyledBoldTitle>{sampleFormat}</StyledBoldTitle> */}
        <Divider type="vertical" />
        <StyledText>
          <FormattedMessage id="GlobalAudioCodecElement.sampleRate" defaultMessage="Sample rate" />
          {": "}
        </StyledText>
        <StyledBoldTitle>{sampleRate}</StyledBoldTitle>
      </div>
    </StyledContainer>
  );
}

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledBoldTitle = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 90px;
  margin: 0 0 15px 30px;
  padding: 10px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  color: #fff;
  padding: 5px 10px;
  line-height: 24px;
  position: absolute;
  top: 89px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 90px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;

GlobalAudioCodecElement.propTypes = {
  type: PropTypes.element.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default GlobalAudioCodecElement;
