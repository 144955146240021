import React from "react";
import { Row, Col } from "antd";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { IPField, PortField } from "../../../Fields/Fields";

import globalTranslations from "../../../../lib/translations";

const InputRTP = ({ disabled, prefix }) => {
  const { formatMessage } = useIntl();

  return (
    <Row gutter={24}>
      <Col md={24} lg={12}>
        <IPField disabled={disabled} label={formatMessage(globalTranslations.addressIp)} name={[...prefix, "ip"]} />
      </Col>
      <Col md={24} lg={12}>
        <PortField name={[...prefix, "port"]} disabled={disabled} />
      </Col>
    </Row>
  );
};

InputRTP.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

InputRTP.defaultProps = {
  disabled: null,
};

export default InputRTP;
