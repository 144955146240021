import React, { useContext } from "react";
import { Col, Form, Switch, Row } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";

import { FormV5Context } from "../../../../../NodeChannelFormV5";
import { required } from "../../../../../../../lib/utils/formValidation";
import globalTranslations from "../../../../../../../lib/translations";

import InputNumberField from "../../../../../../Fields/InputNumberField";
import VideoCodecGOPLengthField from "../../../../../../VideoCodecGOPLengthField";
// import RemoveButton from "../../../../../RemoveButton";

// eslint-disable-next-line max-len
import GlobalVideoCodecFormItem from "../../../../../EngineTranscoding/GlobalCodecSection/GlobalCodecVideo/GlobalVideoCodecFormItem";
import AdvancedSettings from "../../../../../../AdvancedSettings";
import InterlacedField from "../../../../../../InterlacedField";

const { Item } = Form;

const translations = defineMessages({
  sarNum: {
    id: "GeneralVideoCodec.sarNum",
    defaultMessage: "Video Sample Ratio (sarNum)",
  },
  sarDen: {
    id: "GeneralVideoCodec.sarDen",
    defaultMessage: "Video Sample Ratio (sarDen)",
  },
  gopStructure: {
    id: "GeneralVideoCodec.gopStructure",
    defaultMessage: "B-Frames [frames]",
  },
  automaticAs0Tooltip: {
    id: "GeneralVideoCodec.automaticAs0Tooltip",
    defaultMessage: "Select 0 for automatic",
  },
});

function GeneralVideoCodec({
  disabled,
  disableInterlaced,
  prefix,
  additionalBasicFields,
  additionalAdvancedFields,
  pixelFormat,
}) {
  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldValue } = form;
  const { formatMessage } = useIntl();

  const initialChangeGOP = Form.useWatch([...prefix, "changeGOP"], form) || false;

  const isSwitchChangeGOP = Form.useWatch([...prefix, "changeGOP"], form);
  const gValue = Form.useWatch([...prefix, "g"], form);

  const handleSwitchChangeGOP = (value) => {
    const codec = getFieldValue(prefix);

    if (value) {
      const codecUpdate = {
        changeGOP: value,
        g: 25,
        bf: 0,
      };

      setFieldValue(prefix, { ...codec, ...codecUpdate });
    } else {
      delete codec.g;
      delete codec.bf;
      setFieldValue(prefix, { ...codec, changeGOP: value });
    }
  };

  return (
    <>
      <Col span={24}>
        <AdvancedSettings>
          <Row gutter={24}>
            {additionalBasicFields && additionalBasicFields}
            <InputNumberField name={[...prefix, "outputId"]} label="OutputId" disabled={disabled} hidden />
            <Col span={12} style={disableInterlaced ? { visibility: "hidden", display: "none" } : null}>
              <InterlacedField prefix={prefix} disabled={disabled || disableInterlaced} />
            </Col>

            {additionalAdvancedFields && additionalAdvancedFields}

            <Col span={6}>
              <Item
                name={[...prefix, "changeGOP"]}
                valuePropName="checked"
                label={<FormattedMessage id="GeneralVideoCodec.changeGOP" defaultMessage="Custom GoP" />}
              >
                <Switch
                  disabled={disabled}
                  onChange={handleSwitchChangeGOP}
                  defaultChecked={initialChangeGOP}
                  checkedChildren={formatMessage(globalTranslations.on)}
                  unCheckedChildren={formatMessage(globalTranslations.off)}
                />
              </Item>
            </Col>
            {isSwitchChangeGOP && (
              <>
                <Col span={6}>
                  <VideoCodecGOPLengthField disabled={disabled} prefix={prefix} name={[...prefix, "g"]} form={form} />
                </Col>
                <Col span={6}>
                  <InputNumberField
                    disabled={disabled}
                    name={[...prefix, "bf"]}
                    label={formatMessage(translations.gopStructure)}
                    rules={[required]}
                    min={0}
                    max={gValue}
                  />
                </Col>
              </>
            )}

            <Col span={24}>
              <GlobalVideoCodecFormItem
                disabled={disabled}
                prefix={prefix}
                isVideoFormat
                pixelFormat={pixelFormat}
                isEnginePlayout
              />
            </Col>
          </Row>
        </AdvancedSettings>
      </Col>
    </>
  );
}

GeneralVideoCodec.propTypes = {
  disabled: PropTypes.bool,
  disableInterlaced: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  pixelFormat: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

GeneralVideoCodec.defaultProps = {
  disabled: null,
  disableInterlaced: null,
};

export default GeneralVideoCodec;
