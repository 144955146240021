import { DeliveredProcedureOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import globalTranslation from "../../lib/translations";
import { errorNotification } from "../../lib/utils/notification";
import { selectors as ORGANIZATION_SELECTORS } from "../../ducks/organizations";
import OrganizationsServices from "../../services/organizations";

const OrganizationStep0Nav = ({ isSelectedOrganizationData, selectedOrganizationData }) => {
  const { formatMessage } = useIntl();

  const organizationData = useSelector(ORGANIZATION_SELECTORS.getOrganizationData);

  const handleLeaveOrganization = useCallback(() => {
    OrganizationsServices.leaveOrganizationRole({
      errorNotification: errorNotification(formatMessage),
    });
  }, []);

  return (
    <StyledNextButton>
      {organizationData && (
        <Popconfirm
          placement="top"
          title={
            <span>
              <FormattedMessage
                id="OrganizationInformation.leaveOrganizationInfo"
                // eslint-disable-next-line max-len
                defaultMessage={`Are you sure, you want to delete all your data from organization: `}
              />
              {selectedOrganizationData?.name}?
            </span>
          }
          onConfirm={handleLeaveOrganization}
          okText={formatMessage(globalTranslation.yes)}
          cancelText={formatMessage(globalTranslation.no)}
        >
          <Button style={{ marginRight: 5 }} type="primary" danger icon={<LogoutOutlined />}>
            <span>
              <FormattedMessage id="OrganizationInformation.leaveOrganization" defaultMessage="Leave organization" />
            </span>
          </Button>
        </Popconfirm>
      )}
      <Space size="small">
        <Tooltip
          placement="top"
          title={<FormattedMessage id="general.nextAndSave" defaultMessage="Go to next step (data will be saved)" />}
        >
          <Button
            htmlType="submit"
            type="primary"
            disabled={!isSelectedOrganizationData}
            icon={<DeliveredProcedureOutlined />}
          >
            <span>
              <FormattedMessage id="general.next" defaultMessage="Next" />
            </span>
          </Button>
        </Tooltip>
      </Space>
    </StyledNextButton>
  );
};

const StyledNextButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

OrganizationStep0Nav.propTypes = {
  isSelectedOrganizationData: PropTypes.bool,
};

OrganizationStep0Nav.defaultProps = {
  isSelectedOrganizationData: false,
};

export default OrganizationStep0Nav;
