import React from "react";
import { useSelector } from "react-redux";
import { Select, Row, Col, Button, Divider } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import store from "../../store";

import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";
import { actions as NODE_ACTIONS } from "../../ducks/node";

export default function BasicModeQSSelectNodeStep({ setSelectedNode, selectedNodeCwid, handleNextStep }) {
  const onlineNodesSelector = useSelector(MQTT_SELECTORS.getOnlineNodesSelector);
  const nodesList = useSelector(MQTT_SELECTORS.getNodes);

  const handleChangeNode = (selectedCwid) => {
    const selectedNodeData = onlineNodesSelector.find((node) => node.value === selectedCwid);
    setSelectedNode({ cnn: selectedNodeData.label, cwid: selectedNodeData.value });
    const nodeData = nodesList.find((node) => node.cwid === selectedNodeData.value);
    store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
  };

  return (
    <>
      <Row justify="center">
        <Col span={12}>
          <Select
            value={selectedNodeCwid}
            showSearch
            placeholder={<FormattedMessage id="BasicModeQSSelectNodeStep.selectNode" defaultMessage="Select node" />}
            optionFilterProp="children"
            onChange={handleChangeNode}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={onlineNodesSelector}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Button disabled={!selectedNodeCwid} type="primary" onClick={handleNextStep}>
          <FormattedMessage id="general.next" defaultMessage="Next" />
        </Button>
      </Row>
    </>
  );
}

BasicModeQSSelectNodeStep.propTypes = {
  setSelectedNode: PropTypes.func.isRequired,
  selectedNodeCwid: PropTypes.string,
  handleNextStep: PropTypes.func.isRequired,
};

BasicModeQSSelectNodeStep.defaultProps = {
  selectedNodeCwid: null,
};
