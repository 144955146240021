/* eslint-disable consistent-return */
import { defineMessages } from "react-intl";
import { bindActionCreators } from "redux";
import {
  CHANGE_ORGANIZATION_ROLE_STATUS,
  CHANGE_ORGANIZATION_STATUS,
  CREATE_NEW_ORGANIZATION,
  CREATE_NEW_ORGANIZATION_PUBLIC,
  DELETE_ORGANIZATION,
  LEAVE_ORGANIZATION_ROLE,
  DELETE_ORGANIZATION_ROLE,
  SET_ORGANIZATIONS,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_ROLE,
} from "../ducks/organizations";
import { AuthAPI } from "../lib/api";
import getMessageFromErrorCode from "../lib/utils/getMessageFromErrorCode";
import store from "../store";

import { VALIDATION_STATUS, ROLE } from "../lib/utils/constants";

const translations = defineMessages({
  createOrganization: {
    id: "organizations.createOrganization",
    defaultMessage: "Organization was successfully created",
  },
});

const actions = bindActionCreators(
  {
    uploadLogo:
      (data, { errorNotification, token, setFileList, setUploading, setOpenModalImg }) =>
      async () => {
        try {
          setUploading(true);
          const uploadLogo = await AuthAPI.uploadLogo(data, token);
          const response = await uploadLogo.json();

          setFileList([response?.data]);
          setUploading(false);
          setOpenModalImg(false);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setUploading(false);
        }
      },
    createOrganization:
      (data, { errorNotification, successNotification, setOrganization, setModalVisible }) =>
      async (dispatch) => {
        try {
          const newOrganization = await AuthAPI.createOrganization(data, { errorNotification });
          dispatch(CREATE_NEW_ORGANIZATION(newOrganization.data));
          dispatch(CREATE_NEW_ORGANIZATION_PUBLIC(newOrganization.data));
          successNotification(translations.createOrganization);
          setOrganization({ organization: newOrganization.data.id });
          setModalVisible(false);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setModalVisible(false);
        }
      },
    editOrganization:
      (data, { errorNotification, setModalVisible }) =>
      async (dispatch) => {
        try {
          const editedOrganization = await AuthAPI.editOrganization(data, { errorNotification });

          let organizationsList = store.getState().getIn(["organizations", "list"]);
          const publicOrganizationsList = store.getState().getIn(["organizations", "public"]);
          let roles = store.getState().getIn(["organizations", "roles"]);
          const userId = store.getState().getIn(["account", "id"]);
          const userRole = store.getState().getIn(["account", "role"]);
          const isAdmin = userRole === ROLE.ADMIN;
          const isSupport = userRole === ROLE.SUPPORT;
          const isAdminOrSupport = isAdmin || isSupport;

          if (!isAdminOrSupport && editedOrganization?.data?.creator !== userId) {
            organizationsList = organizationsList.filter(
              (organization) => organization.id !== editedOrganization?.data?.id
            );

            roles = roles.filter((role) => role.organizationId !== editedOrganization?.data?.id);
          }

          const parsedPublicOrganizationList = publicOrganizationsList.map((organization) => {
            if (organization.id === editedOrganization?.data?.organizationId) {
              return editedOrganization.data;
            }

            return organization;
          });

          const parsedOrganizationList = organizationsList.map((organization) => {
            if (organization.id === editedOrganization?.data?.organizationId) {
              return editedOrganization.data;
            }

            return organization;
          });

          dispatch(SET_ORGANIZATIONS({ list: parsedOrganizationList, roles, public: parsedPublicOrganizationList }));
          setModalVisible(false);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setModalVisible(false);
        }
      },
    uniqueValidation:
      (data, { errorNotification, setValidationStatus, setValidationHelp }) =>
      async () => {
        try {
          const uniqueData = await AuthAPI.uniqueValidation(data, { errorNotification });
          setValidationStatus(uniqueData.valid ? VALIDATION_STATUS.SUCCESS : VALIDATION_STATUS.ERROR);
          setValidationHelp(!uniqueData.valid);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setValidationStatus(VALIDATION_STATUS.ERROR);
        }
      },
    removeOrganizationRole:
      ({ errorNotification, id }) =>
      async (dispatch) => {
        try {
          await AuthAPI.removeOrganizationRole({ errorNotification, id });

          dispatch(DELETE_ORGANIZATION_ROLE(id));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    leaveOrganizationRole:
      ({ errorNotification }) =>
      async (dispatch) => {
        try {
          await AuthAPI.leaveOrganizationRole({ errorNotification });

          dispatch(LEAVE_ORGANIZATION_ROLE());
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    deleteOrganization:
      ({ errorNotification, organizationId, setLoadingDeleteOrg }) =>
      async (dispatch) => {
        try {
          await AuthAPI.deleteOrganization({ errorNotification, organizationId });
          setLoadingDeleteOrg(false);
          const organizationData = store.getState().getIn(["organizations", "data"]);

          if (organizationData?.organizationId === organizationId) {
            dispatch(LEAVE_ORGANIZATION_ROLE());
          }

          dispatch(DELETE_ORGANIZATION(organizationId));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setLoadingDeleteOrg(false);
        }
      },
    changeOrganizationStatus:
      (data, { errorNotification }) =>
      async (dispatch) => {
        try {
          const updatedOrganization = await AuthAPI.changeOrganizationStatus(data, { errorNotification });

          dispatch(CHANGE_ORGANIZATION_STATUS(updatedOrganization.data));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    changeOrganizationRoleStatus:
      (data, { errorNotification }) =>
      async (dispatch) => {
        try {
          const updatedOrganizationRole = await AuthAPI.changeOrganizationRoleStatus(data, { errorNotification });

          dispatch(CHANGE_ORGANIZATION_ROLE_STATUS(updatedOrganizationRole.data));
          const organizationData = store.getState().getIn(["organizations", "data"]);
          if (organizationData?.organizationId === updatedOrganizationRole?.data?.organizationId) {
            dispatch(UPDATE_ORGANIZATION(updatedOrganizationRole?.data));
          }
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    deleteOrganizationLogo:
      (data, { errorNotification, setProcessDeleteLogo, setLoading }) =>
      async () => {
        try {
          const response = await AuthAPI.deleteOrganizationLogotype(data, { errorNotification });
          setProcessDeleteLogo(false);
          setLoading(false);

          return response;
        } catch (error) {
          setProcessDeleteLogo(false);
          setLoading(false);
        }
      },
    updateOrganization:
      (data, { errorNotification, afterUpdate }) =>
      async (dispatch) => {
        try {
          const updatedOrganization = await AuthAPI.updateOrganization(data, { errorNotification });
          const organizationData = store.getState().getIn(["organizations", "data"]);

          const newRoleData = {
            ...organizationData,
            ...updatedOrganization.data,
            ...(updatedOrganization?.data?.business === 0 && {
              businessRole: null,
              businessName: null,
              businessEmail: null,
            }),
            ...(updatedOrganization?.data?.technical === 0 && {
              technicalRole: null,
              technicalName: null,
              technicalEmail: null,
            }),
          };

          dispatch(UPDATE_ORGANIZATION(newRoleData));
          dispatch(UPDATE_ORGANIZATION_ROLE(newRoleData));
          afterUpdate();
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    getOrganizations:
      ({ errorNotification, setLoadingData }) =>
      async (dispatch) => {
        try {
          const organizationsList = await AuthAPI.getOrganizations({ errorNotification });
          dispatch(SET_ORGANIZATIONS(organizationsList));
          setLoadingData(false);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
  },
  store.dispatch
);

export default actions;
