export default ({ nodeVersion, url }) => {
  const currentUrl = window.location.href;
  const isClientVersionInUrl = currentUrl.split("://")[1].split("/")[0].split(".")[0].includes("v");
  const baseURL = currentUrl.split("://")[1].split("/")[0];

  let parsedUrl;

  const isHTTPS = window.location.href.includes("https");
  const sliceTo = isHTTPS ? 8 : 7;

  if (isClientVersionInUrl) {
    switch (+nodeVersion) {
      case 3:
      case 2:
      case 1:
        parsedUrl = [currentUrl.slice(0, sliceTo), `v${+nodeVersion}.`, baseURL.slice(3), url].join("");

        break;
      default:
        parsedUrl = [currentUrl.slice(0, sliceTo), baseURL.slice(3), url].join("");
    }
  } else {
    if (+nodeVersion >= 4) {
      return;
    }

    parsedUrl = [currentUrl.slice(0, sliceTo), `v${+nodeVersion}.`, baseURL, url].join("");
  }

  window.open(parsedUrl, "_self", "noopener,noreferrer");
};
