import React, { useState, useContext } from "react";
import { Card, Row, Button, Modal, Col, Spin, Popconfirm, Form, Space } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { PlusOutlined, SearchOutlined, QuestionCircleOutlined, SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import { selectors as NODE_DEVICES_SELECTORS } from "../../../../ducks/nodeDevices";

import parseEmergencyModeName from "../../../../lib/utils/parseEmergencyModeName";
import parseConnectionMethodName from "../../../../lib/utils/parseConnectionMethodName";
import transcodingInputURLDefaultValues, {
  DEFAULT_INPUT_ENGINE_PLAYOUT,
} from "../../../../lib/utils/defaultValues/transcodingInputURLDefaultValues";

import {
  QUICKSTREAM_METHOD_V2,
  INPUT_URL_TYPE,
  SELECT_DEFAULT_AUTO,
  INPUT_TYPES,
  INPUT_STREAM_TYPE,
} from "../../../../lib/utils/constants";

import InputTranscodingSectionTitle from "./InputTranscodingSectionTitle";
import InputTranscodingSectionContainer from "../InputTranscodingSectionContainer";
import InputBackupTranscodingSectionContainer from "../InputBackupTranscodingSectionContainer";
import ProbeDrawerV5 from "../../../ProbeDrawerV5/ProbeDrawerV5";
import InputStreamSourceElement from "../../../InputStreamSourceElement";
import DecklinkElement from "../../../DecklinkElement";
import WebcamElement from "../../../WebcamElement";
import SourceSwitch from "../../../SourceSwitch";
import InputNDIElement from "../../../InputNDIElement";
import InputTranscodingEmergency from "./InputTranscodingEmergency";
import SectionSwitch from "../../../Fields/SectionSwitch";

import useNodeChannelConfig from "../../../../hooks/useNodeChannelConfig";

import { FormV5Context } from "../../NodeChannelFormV5";

const translations = defineMessages({
  mainInput: {
    id: "InputTranscodingSection.mainInput",
    defaultMessage: "Main input",
  },
  backupInput: {
    id: "InputTranscodingSection.backupInput",
    defaultMessage: "Backup input",
  },
  main: {
    id: "InputTranscodingSection.main",
    defaultMessage: "Main",
  },
  backup: {
    id: "InputTranscodingSection.backup",
    defaultMessage: "Backup",
  },
  switching: {
    id: "InputTranscodingSection.switching",
    defaultMessage: "Switching",
  },
});

function InputTranscodingSection({
  channelConfig,
  detailView,
  handleFinish,
  id,
  requestedStatusText,
  sharedChannelList,
  stundAddress,
  playingSource,
  inputsStatus,
  prefix,
}) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);
  const { getFieldsValue, resetFields, validateFields, setFieldsValue, getFieldValue, setFieldValue } = form;
  const {
    isInput,
    isBackup,
    isMainQSMethod,
    isMainInputURLType,
    isMainInputWebcamType,
    isInputRTMPTypeSelected,
    isMainInputDecklinkType,
    isInputRTPTypeSelected,
    isInputNDITypeSelected,
    isBackupInputURLType,
    isBackupInputWebcamType,
    isBackupInputRTMPTypeSelected,
    isBackupInputDecklinkType,
    isBackupInputRTPTypeSelected,
    isBackupInputNDITypeSelected,
    isEngineTranscoding,
    isEnginePlayout,
    mainInputConfig,
    backupInputConfig,
  } = useNodeChannelConfig();

  const [inputStreamModalVisible, setInputStreamModalVisible] = useState(false);
  const [inputBackupStreamModalVisible, setInputBackupStreamModalVisible] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedInputType, setSelectedInputType] = useState(mainInputConfig?.type);
  const decklinkInputs = useSelector(NODE_DEVICES_SELECTORS.getDecklinkInputs);

  const liveSourceEnabled = Form.useWatch([...prefix, "enabled"], form);

  const selectedBackupInputType = Form.useWatch([...prefix, "backupInput", "type"], form);
  const selectedMainInputConnectionType = Form.useWatch([...prefix, "mainInput", "inputStreamCfg", "type"], form);
  const isMainInputQuickstreamMethod = selectedMainInputConnectionType === QUICKSTREAM_METHOD_V2.inQSDirect.value;
  const isInitialValueBackupInputUrlType =
    backupInputConfig?.type === INPUT_URL_TYPE.value && selectedBackupInputType !== INPUT_URL_TYPE.value;

  const handleOKInput = async () => {
    try {
      await validateFields();
      const formData = getFieldsValue();

      const updatedFormData = { ...channelConfig, ...formData };

      handleFinish(updatedFormData);
      setInputStreamModalVisible(false);
      setInputBackupStreamModalVisible(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("InputTranscodingSection form validation error", error);
    }
  };

  const handleCancelInput = () => {
    resetFields();
    setInputStreamModalVisible(false);
    setInputBackupStreamModalVisible(false);
  };

  const handleShowBackupEditModal = () => {
    if (!backupInputConfig) {
      setFieldsValue({
        ...channelConfig,
        backupSwitchMsecs: channelConfig?.backupSwitchMsecs || 500,
        backupReturnMsecs: channelConfig?.backupReturnMsecs || 5000,
        backupInput: transcodingInputURLDefaultValues({
          backupInput: backupInputConfig || {},
        }),
      });
    }

    setInputBackupStreamModalVisible(true);
  };

  const handleShowEditModal = () => {
    if (isEnginePlayout && !channelConfig?.liveSource?.mainInput) {
      setFieldValue(prefix, DEFAULT_INPUT_ENGINE_PLAYOUT);
      setFieldValue(
        [...prefix, "mainInput"],
        transcodingInputURLDefaultValues({
          input: channelConfig?.liveSource?.mainInput,
        })
      );
    }

    if (isEngineTranscoding && !mainInputConfig) {
      setFieldsValue({
        ...channelConfig,
        mainInput: transcodingInputURLDefaultValues({
          input: mainInputConfig,
        }),
      });
    }

    setInputStreamModalVisible(true);
  };

  const handleShowProbe = async () => {
    setShowDrawer(true);
  };

  const selectedInputDesc = decklinkInputs.find((input) => input.device === mainInputConfig?.device);
  const selectedBackupDesc = decklinkInputs.find((input) => input.device === backupInputConfig?.device);
  const parsedInputFormat =
    mainInputConfig?.format === SELECT_DEFAULT_AUTO.value ? SELECT_DEFAULT_AUTO.label : mainInputConfig?.format;
  const parsedBackupFormat =
    backupInputConfig?.format === SELECT_DEFAULT_AUTO.value ? SELECT_DEFAULT_AUTO.label : backupInputConfig?.format;

  return (
    <Card
      title={
        <Row justify="space-between">
          <span>
            {isEnginePlayout && (
              <Space>
                <FormattedMessage id="InputTranscodingSection.liveSource" defaultMessage="Live source" />
                <SectionSwitch prefix={[...prefix, "enabled"]} disabled={detailView} handleFinish={handleFinish} />
              </Space>
            )}
            {isEngineTranscoding && (
              <FormattedMessage id="InputTranscodingSection.inputStream" defaultMessage="Input stream" />
            )}
          </span>
          {isInput && !detailView && (
            <Col>
              <Button
                disabled={isMainQSMethod || isMainInputWebcamType}
                icon={<SearchOutlined />}
                onClick={handleShowProbe}
                type="primary"
              >
                <span>
                  <FormattedMessage id="InputTranscodingSection.probeStream" defaultMessage="Probe stream" />
                </span>
              </Button>
            </Col>
          )}
        </Row>
      }
      extra={
        detailView &&
        isBackup && (
          <SourceSwitch
            channelId={id}
            playingSource={playingSource}
            requestedStatusText={requestedStatusText}
            inputsStatus={inputsStatus}
            type={channelConfig?.type}
          />
        )
      }
    >
      {((isEnginePlayout && liveSourceEnabled) || isEngineTranscoding) && (
        <Row gutter={4} justify="center">
          <ProbeDrawerV5
            type={channelConfig?.type}
            getFieldValue={getFieldValue}
            isBackup={!!isBackup}
            isMainQSMethod={isMainQSMethod}
            setShowDrawer={setShowDrawer}
            showDrawer={showDrawer}
            validateFields={validateFields}
          />
          {isInput && (
            <Col md={24} xl={12} xxl={8}>
              <StyledSpin
                indicator={<> </>}
                spinning={!!playingSource && playingSource !== INPUT_STREAM_TYPE.main && detailView}
                tip={<FormattedMessage id="InputTranscodingSection.inactive" defaultMessage="Inactive" />}
                onClick={() => setInputStreamModalVisible(true)}
              >
                {isMainInputURLType && (
                  <InputStreamSourceElement
                    connectionType={mainInputConfig?.inputStreamCfg?.type}
                    cloudId={mainInputConfig?.inputStreamCfg?.cloudId}
                    destinationAddress={mainInputConfig?.inputStreamCfg?.destinationAddress}
                    port={
                      mainInputConfig?.inputStreamCfg?.destinationPort || mainInputConfig?.inputStreamCfg?.localPort
                    }
                    handleClick={handleShowEditModal}
                    interfaceIp={mainInputConfig?.inputStreamCfg?.interfaceIp}
                    label={formatMessage(translations.main)}
                    title={
                      mainInputConfig?.inputStreamCfg?.type &&
                      parseConnectionMethodName(mainInputConfig?.inputStreamCfg?.type)
                    }
                    incomplete={mainInputConfig?.inputStreamCfg?.incomplete}
                  />
                )}
                {isInputRTMPTypeSelected && (
                  <InputStreamSourceElement
                    connectionType={mainInputConfig?.inputStreamCfg?.type}
                    handleClick={handleShowEditModal}
                    label={formatMessage(translations.main)}
                    title={INPUT_TYPES.inputRtmp.label}
                    url={mainInputConfig?.url}
                    urlKey={mainInputConfig?.key}
                    incomplete={mainInputConfig?.incomplete}
                  />
                )}
                {isInputRTPTypeSelected && (
                  <InputStreamSourceElement
                    connectionType={mainInputConfig?.inputStreamCfg?.type}
                    handleClick={handleShowEditModal}
                    ip={mainInputConfig?.ip}
                    label={formatMessage(translations.main)}
                    port={mainInputConfig?.port}
                    title={INPUT_TYPES.inputMpegtsOverRtp.label}
                    incomplete={mainInputConfig?.incomplete}
                  />
                )}
                {isInputNDITypeSelected && (
                  <InputNDIElement
                    label={formatMessage(translations.main)}
                    handleClick={handleShowEditModal}
                    streamSource={mainInputConfig?.streamSource}
                    title={INPUT_TYPES.inputNdi.label}
                    incomplete={mainInputConfig?.incomplete}
                  />
                )}
                {isMainInputWebcamType && (
                  <WebcamElement
                    bitsPerSample={mainInputConfig?.bitsPerSample}
                    channels={mainInputConfig?.channels}
                    handleClick={handleShowEditModal}
                    label={formatMessage(translations.main)}
                    sampleRate={mainInputConfig?.sampleRate}
                    audioTitle={mainInputConfig?.micDevice}
                    videoTitle={mainInputConfig?.webcamDevice}
                    width={mainInputConfig?.width}
                    height={mainInputConfig?.height}
                    fps={mainInputConfig?.fps}
                    incomplete={mainInputConfig?.incomplete}
                    title={INPUT_TYPES.inputWebcam.label}
                  />
                )}
                {isMainInputDecklinkType && (
                  <DecklinkElement
                    label={formatMessage(translations.main)}
                    handleClick={handleShowEditModal}
                    selectedInputDesc={selectedInputDesc?.desc}
                    format={parsedInputFormat}
                    incomplete={mainInputConfig?.incomplete}
                    title={INPUT_TYPES.inputDecklink.label}
                    videoSource={
                      mainInputConfig?.videoSource === SELECT_DEFAULT_AUTO.value
                        ? SELECT_DEFAULT_AUTO.label
                        : mainInputConfig?.videoSource
                    }
                    audioSource={
                      mainInputConfig?.audioSource === SELECT_DEFAULT_AUTO.value
                        ? SELECT_DEFAULT_AUTO.label
                        : mainInputConfig?.audioSource
                    }
                    audioChannels={mainInputConfig?.audioChannels}
                  />
                )}
              </StyledSpin>
            </Col>
          )}
          {backupInputConfig && (
            <Col md={24} xl={12} xxl={8}>
              <StyledSpin
                indicator={<> </>}
                spinning={!!playingSource && playingSource !== INPUT_STREAM_TYPE.backup && detailView}
                tip={<FormattedMessage id="InputTranscodingSection.inactive" defaultMessage="Inactive" />}
                onClick={handleShowBackupEditModal}
              >
                {isBackupInputURLType && (
                  <InputStreamSourceElement
                    connectionType={backupInputConfig?.inputStreamCfg?.inputStreamCfg?.type}
                    destinationAddress={backupInputConfig?.inputStreamCfg?.destinationAddress}
                    port={
                      backupInputConfig?.inputStreamCfg?.destinationPort || backupInputConfig?.inputStreamCfg?.localPort
                    }
                    handleClick={handleShowBackupEditModal}
                    interfaceIp={backupInputConfig?.inputStreamCfg?.interfaceIp}
                    label={formatMessage(translations.backup)}
                    title={
                      backupInputConfig?.inputStreamCfg?.type &&
                      parseConnectionMethodName(backupInputConfig?.inputStreamCfg?.type)
                    }
                    incomplete={backupInputConfig?.incomplete}
                  />
                )}
                {isBackupInputRTMPTypeSelected && (
                  <InputStreamSourceElement
                    connectionType={backupInputConfig?.inputStreamCfg?.type}
                    handleClick={() => {}}
                    label={formatMessage(translations.backup)}
                    title={INPUT_TYPES.inputRtmp.label}
                    url={backupInputConfig?.url}
                    urlKey={backupInputConfig?.key}
                    incomplete={backupInputConfig?.incomplete}
                  />
                )}
                {isBackupInputRTPTypeSelected && (
                  <InputStreamSourceElement
                    connectionType={backupInputConfig?.inputStreamCfg?.type}
                    handleClick={() => {}}
                    ip={backupInputConfig?.ip}
                    label={formatMessage(translations.main)}
                    port={backupInputConfig?.port}
                    title={INPUT_TYPES.inputMpegtsOverRtp.label}
                    incomplete={backupInputConfig?.incomplete}
                  />
                )}
                {isBackupInputNDITypeSelected && (
                  <InputNDIElement
                    label={formatMessage(translations.main)}
                    handleClick={() => {}}
                    streamSource={backupInputConfig?.streamSource}
                    title={INPUT_TYPES.inputNdi.label}
                    incomplete={backupInputConfig?.incomplete}
                  />
                )}
                {isBackupInputWebcamType && (
                  <WebcamElement
                    bitsPerSample={backupInputConfig?.bitsPerSample}
                    channels={backupInputConfig?.channels}
                    handleClick={() => {}}
                    label={formatMessage(translations.backup)}
                    sampleRate={backupInputConfig?.sampleRate}
                    audioTitle={backupInputConfig?.micDevice}
                    videoTitle={backupInputConfig?.webcamDevice}
                    width={backupInputConfig?.width}
                    height={backupInputConfig?.height}
                    fps={backupInputConfig?.fps}
                    incomplete={backupInputConfig?.incomplete}
                    title={INPUT_TYPES.inputWebcam.label}
                  />
                )}
                {isBackupInputDecklinkType && (
                  <DecklinkElement
                    label={formatMessage(translations.backup)}
                    handleClick={() => {}}
                    selectedInputDesc={selectedBackupDesc?.desc}
                    format={parsedBackupFormat}
                    incomplete={backupInputConfig?.incomplete}
                    title={INPUT_TYPES.inputDecklink.label}
                    videoSource={
                      backupInputConfig?.videoSource === SELECT_DEFAULT_AUTO.value
                        ? SELECT_DEFAULT_AUTO.label
                        : backupInputConfig?.videoSource
                    }
                    audioSource={
                      backupInputConfig?.audioSource === SELECT_DEFAULT_AUTO.value
                        ? SELECT_DEFAULT_AUTO.label
                        : backupInputConfig?.audioSource
                    }
                    audioChannels={backupInputConfig?.audioChannels}
                  />
                )}
              </StyledSpin>
            </Col>
          )}
          {isEngineTranscoding && (
            <InputTranscodingEmergency
              form={form}
              emergencyFile={channelConfig?.emergencyFile}
              emergencySwitchMsecs={channelConfig?.emergencySwitchMsecs}
              emergencyReturnMsecs={channelConfig?.emergencyReturnMsecs}
              backupSwitchMsecs={channelConfig?.backupSwitchMsecs}
              backupReturnMsecs={channelConfig?.backupReturnMsecs}
              handleOKInput={handleOKInput}
              detailView={detailView}
              playingSource={playingSource}
              label={formatMessage(translations.switching)}
              title={
                <>
                  <FormattedMessage id="InputTranscodingSection.emergencyModeTile" defaultMessage="Emergency mode" />
                  {": "}
                  {parseEmergencyModeName(channelConfig?.emergencyMode)}
                </>
              }
            />
          )}
          {!isInput && !detailView && (
            <Button type="primary" style={{ width: "100%", margin: "10px" }} onClick={handleShowEditModal}>
              <PlusOutlined />{" "}
              <FormattedMessage id="InputTranscodingSection.addMainInput" defaultMessage="Add main input" />
            </Button>
          )}

          {!isBackup && !detailView && !isEnginePlayout && (
            <Button
              type="dashed"
              style={{ width: "100%", margin: "10px" }}
              onClick={handleShowBackupEditModal}
              disabled={isMainInputQuickstreamMethod || !isInput}
            >
              <PlusOutlined />{" "}
              <FormattedMessage id="InputTranscodingSection.addBackupInput" defaultMessage="Add backup input" />
            </Button>
          )}
        </Row>
      )}

      {inputStreamModalVisible && (
        <StyledModal
          zIndex={100}
          centered
          title={<FormattedMessage id="InputTranscodingSection.mainInputStreamForm" defaultMessage="Main Input" />}
          open={inputStreamModalVisible}
          onCancel={handleCancelInput}
          footer={
            detailView ? null : (
              <>
                <Button key="back" onClick={handleCancelInput}>
                  <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
                </Button>
                <Button key="ok" type="primary" onClick={handleOKInput} icon={<SaveOutlined />}>
                  <span>
                    <FormattedMessage id="general.save" defaultMessage="Save" />
                  </span>
                </Button>
              </>
            )
          }
        >
          <InputTranscodingSectionContainer
            isInput={!!isInput}
            channelList={sharedChannelList}
            disabled={detailView}
            initialValue={mainInputConfig}
            setSelectedInputType={setSelectedInputType}
            selectedInputType={selectedInputType}
            sharedChannelList={sharedChannelList}
            stundAddress={stundAddress}
            prefix={prefix}
          />
        </StyledModal>
      )}
      {inputBackupStreamModalVisible && (
        <StyledModal
          zIndex={100}
          centered
          title={
            <InputTranscodingSectionTitle
              setInputBackupStreamModalVisible={setInputBackupStreamModalVisible}
              handleFinish={handleFinish}
              channelConfig={channelConfig}
              disabled={detailView}
            />
          }
          open={inputBackupStreamModalVisible}
          onCancel={handleCancelInput}
          footer={
            detailView ? null : (
              <>
                <Button key="back" onClick={handleCancelInput}>
                  <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
                </Button>
                {isInitialValueBackupInputUrlType && (
                  <Popconfirm
                    title={
                      <StyledPopconfirmText>
                        <FormattedMessage
                          id="InputTranscodingSection.beAware"
                          // eslint-disable-next-line max-len
                          defaultMessage="Be aware that this accepting this change you will overwrite the all old input configuration (including input backup), and all direct outputs"
                        />
                      </StyledPopconfirmText>
                    }
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    onConfirm={handleOKInput}
                    okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
                    cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
                  >
                    <Button key="ok" type="primary">
                      <FormattedMessage id="general.ok" defaultMessage="OK" />
                    </Button>
                  </Popconfirm>
                )}
                {!isInitialValueBackupInputUrlType && (
                  <Button key="ok" type="primary" onClick={handleOKInput} icon={<SaveOutlined />}>
                    <span>
                      <FormattedMessage id="general.save" defaultMessage="Save" />
                    </span>
                  </Button>
                )}
              </>
            )
          }
        >
          <InputBackupTranscodingSectionContainer
            channelList={sharedChannelList}
            disabled={detailView}
            initialValue={backupInputConfig}
            sharedChannelList={sharedChannelList}
            stundAddress={stundAddress}
          />
        </StyledModal>
      )}
    </Card>
  );
}

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledSpin = styled(Spin)`
  .ant-spin-text {
    top: 30% !important;
    font-weight: bold;
  }
`;

const StyledPopconfirmText = styled.div`
  max-width: 350px;
`;

InputTranscodingSection.propTypes = {
  channelConfig: PropTypes.object,
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  id: PropTypes.string,
  requestedStatusText: PropTypes.string,
  sharedChannelList: PropTypes.array,
  stundAddress: PropTypes.string,
  playingSource: PropTypes.string.isRequired,
  inputsStatus: PropTypes.arrayOf(
    PropTypes.shape({
      connectionInfo: PropTypes.string.isRequired,
      errorCode: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      permissionId: PropTypes.number.isRequired,
      threadStatus: PropTypes.number.isRequired,
      threadStatusText: PropTypes.string.isRequired,
    })
  ).isRequired,
};

InputTranscodingSection.defaultProps = {
  channelConfig: null,
  detailView: null,
  id: null,
  requestedStatusText: null,
  sharedChannelList: null,
  stundAddress: null,
};

export default InputTranscodingSection;
