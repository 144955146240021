import React from "react";
import { Col, Row } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import styled from "styled-components";

import themeColor from "../../lib/style/theme";

const SeparationTwoColumns = () => (
  <Col span={24}>
    <Row justify="space-around" align="middle" gutter={48}>
      <StyledColCenter span={12}>
        <CaretDownOutlined />
      </StyledColCenter>
      <StyledColCenter span={12}>
        <CaretDownOutlined />
      </StyledColCenter>
    </Row>
  </Col>
);

const StyledColCenter = styled(Col)`
  text-align: center;
  color: ${themeColor.orange};
  font-size: 40px;
`;

export default SeparationTwoColumns;
