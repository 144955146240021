import PropTypes from "prop-types";

const STREAM_ID_TYPE = {
  setStreamId: PropTypes.bool,
  streamId: PropTypes.string,
};

const ENCRYPTION_TYPE = {
  encryptionEnabled: PropTypes.bool,
  encryptionPassword: PropTypes.string,
  encryptionKeyLen: PropTypes.number,
};

export const INPUT_BACKUP_TYPE = {
  backupConnection: PropTypes.shape({
    type: PropTypes.string.isRequired,
    interfaceIp: PropTypes.string.isRequired,
    destinationAddress: PropTypes.string,
    destinationPort: PropTypes.number,
  }),
};

export const INPUT_BACKUP_TRIGGERS_TYPE = {
  backupTriggers: PropTypes.shape({
    triggerBitrateEnabled: PropTypes.bool.isRequired,
    triggerBitrateLevel: PropTypes.number.isRequired,
    triggerBitrateTimeSecs: PropTypes.number.isRequired,
    triggerDisconnectEnabled: PropTypes.bool.isRequired,
    triggerConnectionTimeEnabled: PropTypes.bool.isRequired,
    triggerConnectionTimeSecs: PropTypes.number.isRequired,
  }),
};

export const MAIN_CONNECTION_TYPE = {
  mainConnection: PropTypes.shape({
    ...STREAM_ID_TYPE,
    ...ENCRYPTION_TYPE,
    type: PropTypes.string.isRequired,
    interfaceIp: PropTypes.string.isRequired,
    localPort: PropTypes.number,
    srtLatency: PropTypes.number,
    srtOverhead: PropTypes.number,
    destinationAddress: PropTypes.string,
    destinationPort: PropTypes.number,
  }),
};

export const INPUT_NODE_CHANEL_TYPE = PropTypes.shape({
  input: PropTypes.shape({
    type: PropTypes.string.isRequired,
    switchInputBackup: PropTypes.bool.isRequired,
    ...MAIN_CONNECTION_TYPE,
  }),
});
