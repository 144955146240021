import { NODE_CHANNEL_STATE } from "./constants";

export default (value) => {
  switch (value) {
    case NODE_CHANNEL_STATE.STARTED:
      return "started";
    case NODE_CHANNEL_STATE.PLAYING:
      return "processing";
    case NODE_CHANNEL_STATE.PROCESS_DISCONNECTED:
      return "error";
    case NODE_CHANNEL_STATE.STOPPED:
    case NODE_CHANNEL_STATE.CONNECTING:
    case NODE_CHANNEL_STATE.RECONNECTING:
      return "warning";
    case NODE_CHANNEL_STATE.CONNECTED:
      return "success";
    default:
      return "missing state";
  }
};
