import React, { useState } from "react";
import { Form, Select, Space, Divider, Input, Button } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";

import { SELECT_HUB_CHANNELS_RESOLUTION } from "../../../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

const translations = defineMessages({
  resolutionName: {
    id: "VirtualChannelResolutions.resolutionName",
    defaultMessage: "Enter resolution",
  },
});

const VirtualChannelResolutions = () => {
  const { formatMessage } = useIntl();
  const [resolutions, setNewResolutions] = useState(
    SELECT_HUB_CHANNELS_RESOLUTION.slice(0, -1).map((resolution) => resolution.label)
  );
  const [newResolution, setNeResolution] = useState("");

  const onChangeNewResolutionName = (event) => {
    if (event.target.value && event.target.value.length <= 32) {
      setNeResolution(event.target.value);
    }
  };

  const addResolution = (event) => {
    event.preventDefault();

    setNewResolutions([...resolutions, newResolution]);
    setNeResolution("");
  };

  return (
    <Item
      label={<FormattedMessage id="VirtualChannelResolutions.resolutions" defaultMessage="Resolutions" />}
      name="resolutions"
    >
      <Select
        mode="multiple"
        allowClear
        showSearch
        placeholder={<FormattedMessage id="general.pleaseSelect" defaultMessage="Please select" />}
        optionFilterProp="children"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Space
              align="center"
              style={{
                padding: "0 8px 4px",
              }}
            >
              <Input
                placeholder={formatMessage(translations.resolutionName)}
                value={newResolution}
                onChange={onChangeNewResolutionName}
              />
              <Button
                disabled={newResolution.length === 0 || resolutions.includes(newResolution)}
                type="primary"
                shape="round"
                onClick={addResolution}
                icon={<PlusOutlined />}
              >
                <span>
                  <FormattedMessage id="VirtualChannelResolutions.addResolution" defaultMessage="Add resolution" />
                </span>
              </Button>
            </Space>
          </>
        )}
      >
        {resolutions.map((resolution) => (
          <Option value={resolution} key={resolution}>
            {resolution}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default VirtualChannelResolutions;
