import * as React from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";

import { CONNECTION_METHOD_INPUT, ENGINE_TYPES } from "../../../../lib/utils/constants";

import { PortField } from "../../../Fields/Fields";
import InputNumberField from "../../../Fields/InputNumberField";
import NetworkField from "../../../Fields/NetworkField";
import AdvancedSettings from "../../../AdvancedSettings";

import { required } from "../../../../lib/utils/formValidation";

import OverheadBandwidthLabel from "../../Labels/OverheadBandwidthLabel";
import HostField from "../../../HostField";
import LatencyLabel from "../../Labels/LatencyLabel";
import LocalPortLabel from "../../../LocalPortLabel";
import PassphraseField from "../../../Fields/PassphraseField";

function InputStreamSrtSection({ prefix, disabled, connectionMethod, form, basicMode }) {
  const isCallerMode = connectionMethod === CONNECTION_METHOD_INPUT.inSrtCaller.value;
  const isListenerMode = connectionMethod === CONNECTION_METHOD_INPUT.inSrtListener.value;

  const { getFieldValue } = form || {};
  const type = getFieldValue("type");
  const isEngineDirect = type === ENGINE_TYPES.DIRECT;

  return (
    <>
      {isCallerMode && (
        <>
          <Col md={24} lg={12}>
            <HostField disabled={disabled} name={[...prefix, "destinationAddress"]} />
          </Col>
          <Col md={24} lg={12}>
            <PortField name={[...prefix, "destinationPort"]} disabled={disabled} />
          </Col>
        </>
      )}
      <Col md={24} lg={12}>
        <NetworkField name={[...prefix, "interfaceIp"]} requiredField disabled={disabled} />
      </Col>
      {isListenerMode && (
        <Col md={24} lg={12}>
          <PortField
            name={[...prefix, "localPort"]}
            disabled={disabled}
            label={<LocalPortLabel noTooltip={isListenerMode} />}
            min={1}
          />
        </Col>
      )}
      <Col md={24} lg={12}>
        <InputNumberField
          disabled={disabled}
          name={[...prefix, "srtLatency"]}
          label={LatencyLabel("input")}
          rules={[required]}
        />
      </Col>
      {isEngineDirect && !basicMode && (
        <Col span={24}>
          <AdvancedSettings>
            <Row gutter={24}>
              {isCallerMode && (
                <Col md={24} lg={12}>
                  <PortField
                    name={[...prefix, "localPort"]}
                    disabled={disabled}
                    label={<LocalPortLabel noTooltip={isListenerMode} />}
                    min={0}
                  />
                </Col>
              )}
              <Col md={24} lg={12}>
                <InputNumberField
                  disabled={disabled}
                  name={[...prefix, "srtOverhead"]}
                  label={OverheadBandwidthLabel()}
                  rules={[required]}
                  min={5}
                  max={100}
                />
              </Col>
              <Col md={24} lg={24}>
                <PassphraseField disabled={disabled} prefix={[...prefix]} type={prefix[0]} form={form} />
              </Col>
            </Row>
          </AdvancedSettings>
        </Col>
      )}
    </>
  );
}

InputStreamSrtSection.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  connectionMethod: PropTypes.string.isRequired,
  basicMode: PropTypes.bool,
};

InputStreamSrtSection.defaultProps = {
  disabled: null,
  basicMode: false,
};

export default InputStreamSrtSection;
