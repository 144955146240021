import React from "react";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";

const TooltipLabel = ({ label, description }) => (
  <>
    <StyledLabel>{label}</StyledLabel>
    <Tooltip placement="top" title={description}>
      <QuestionCircleFilled />
    </Tooltip>
  </>
);

const StyledLabel = styled.span`
  margin-right: 5px;
`;

TooltipLabel.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TooltipLabel;
