import {
  BankOutlined,
  CloudUploadOutlined,
  DatabaseOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  BarChartOutlined,
  DeploymentUnitOutlined,
  CloudOutlined,
  VideoCameraOutlined,
  IdcardOutlined,
  HomeOutlined,
  LineChartOutlined,
  ForkOutlined,
  BranchesOutlined,
  CodeOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import * as PropTypes from "prop-types";
import React, { useCallback, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import { Link, Outlet } from "react-router-dom";
import semver from "semver";
// import Cookies from "universal-cookie";

import store from "../../store";

import { actions as NAVIGATION_ACTIONS, selectors as NAVIGATION_SELECTORS } from "../../ducks/navigation";
import { selectors as MQTT_CONNECTION_SELECTORS } from "../../ducks/mqtt";
import { DELETE_LOGS } from "../../ducks/logs";
import { CLEAR_HIST_STATISTIC } from "../../ducks/nodeStatistics";
import { CLEAR_DEVICES_LIST } from "../../ducks/nodeDevices";
import { CLEAR_FORMATS } from "../../ducks/nodeFormats";
import { actions as NODE_ACTIONS, selectors as NODE_SELECTORS } from "../../ducks/node";
import { actions as NODE_CHANNELS_ACTIONS } from "../../ducks/nodeChannels";
import { actions as NODE_LICENSE_ACTIONS } from "../../ducks/nodeLicense";

import PageHeader from "../PageHeader";
import About from "../About";
import NodesListIcon from "../NodesList/NodesListIcon";
import useRole from "../../hooks/useRole";
import GlobalErrorBoundaries from "../ErrorBoundaries/GlobalErrorBoundaries";
import Header from "../Header";
import NodesListTitle from "../NodesList/NodesListTitle";

import checkClientVersion from "../../lib/utils/checkClientVersion";
import { MQTT_STATUS, DEFAULT_NODE_VERSION } from "../../lib/utils/constants";

const { Content, Sider } = Layout;

function getItem(label, key, icon, children, type, disabled) {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  };
}

const NavigationLayout = ({ activeKey, activeSubmenu }) => {
  const { isAdmin, isUser, isAdminOrSupport } = useRole();
  const [collapsed, setCollapsed] = useState(false);
  const cloudNodes = useSelector(MQTT_CONNECTION_SELECTORS.getNodes);
  const activeNodeCwid = useSelector(NODE_SELECTORS.getNodeCwid);

  useEffect(() => {
    // clear node capabilities on change active node CWID
    store.dispatch(NODE_ACTIONS.SET_NODE_CAPABILITIES(null));
  }, [activeNodeCwid]);

  const handleChannels = (nodeData) => {
    const { key, ...restNodeData } = nodeData;

    if (!activeKey.includes(key)) {
      store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(restNodeData));
      store.dispatch(NAVIGATION_ACTIONS.SET_CHANNELS_ACTIVE_TAB({ channelsActiveTab: "channelList" }));
      store.dispatch(DELETE_LOGS());
      store.dispatch(CLEAR_HIST_STATISTIC());
      store.dispatch(NODE_CHANNELS_ACTIONS.CLEAR_NODES_CHANNELS());
      store.dispatch(CLEAR_DEVICES_LIST());
      store.dispatch(CLEAR_FORMATS());
    }
  };

  const handleLicenses = (nodeData) => {
    const { key, ...restNodeData } = nodeData;
    store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(restNodeData));
    store.dispatch(NODE_LICENSE_ACTIONS.CLEAR_NODE_LICENSE_LIST());
  };

  const handleClearNodeData = () => {
    store.dispatch(DELETE_LOGS());
    store.dispatch(CLEAR_HIST_STATISTIC());
    store.dispatch(NODE_CHANNELS_ACTIONS.CLEAR_NODES_CHANNELS());
    store.dispatch(CLEAR_DEVICES_LIST());
    store.dispatch(CLEAR_FORMATS());
  };

  const handleSystem = (nodeData) => {
    const { key, ...restNodeData } = nodeData;
    store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(restNodeData));
  };

  const parsedCloudNodesList = cloudNodes.map((cloudNode) => {
    const { cnn, cwid, online, nodeVersion, type } = cloudNode;

    const isV4Node = nodeVersion && semver.satisfies(nodeVersion, "4.x.x");

    const parsedNodeVersion = (nodeVersion && nodeVersion.split(".")[0].replace("v", "")) || DEFAULT_NODE_VERSION;

    const changeClientVersion = checkClientVersion({ nodeVersion: parsedNodeVersion });

    return getItem(
      <NodesListTitle online={online} cwid={cwid} cnn={cnn} nodeVersion={parsedNodeVersion} type={type} />,
      `/node/${cwid}`,
      <NodesListIcon online={online} type={type} />,
      [
        getItem(
          <Link
            key={`/node/${cwid}/${cnn}/channels?tab=channelList`}
            to={`/node/${cwid}/${cnn}/channels?tab=channelList`}
            onClick={() => handleChannels({ ...cloudNode, key: `/node/${cwid}/${cnn}/channels` })}
          >
            <>
              <ForkOutlined /> <FormattedMessage id="NodesList.channels" defaultMessage="Channels" />
            </>
          </Link>,
          `/node/${cwid}/${cnn}/channels`
        ),
        ...(isV4Node
          ? [
              getItem(
                <Link
                  key={`/node/${cwid}/${cnn}/licenses`}
                  to={`/node/${cwid}/${cnn}/licenses`}
                  onClick={() => handleLicenses({ ...cloudNode, key: `/node/${cwid}/${cnn}/licenses` })}
                >
                  <DatabaseOutlined /> <FormattedMessage id="NodesList.Licenses" defaultMessage="Licenses" />
                </Link>,
                `/node/${cwid}/${cnn}/licenses`
              ),
            ]
          : []),
        getItem(
          <Link key={`/node/${cwid}/${cnn}/logs`} to={`/node/${cwid}/${cnn}/logs`} onClick={handleClearNodeData}>
            <>
              <CodeOutlined /> <FormattedMessage id="NodesList.logs" defaultMessage="Logs" />
            </>
          </Link>,
          `/node/${cwid}/${cnn}/logs`
        ),
        getItem(
          <Link key={`/node/${cwid}/${cnn}/stats`} to={`/node/${cwid}/${cnn}/stats`} onClick={handleClearNodeData}>
            <>
              <LineChartOutlined /> <FormattedMessage id="NodesList.statistics" defaultMessage="Statistics" />
            </>
          </Link>,
          `/node/${cwid}/${cnn}/stats`
        ),
        getItem(
          <Link key={`/node/${cwid}/${cnn}/ports`} to={`/node/${cwid}/${cnn}/ports`} onClick={handleClearNodeData}>
            <>
              <BranchesOutlined /> <FormattedMessage id="NodesList.ports" defaultMessage="Ports" />
            </>
          </Link>,
          `/node/${cwid}/${cnn}/ports`
        ),
        getItem(
          <Link
            key={`/node/${cwid}/${cnn}/system`}
            to={`/node/${cwid}/${cnn}/system`}
            onClick={() => handleSystem({ ...cloudNode, key: `/node/${cwid}/${cnn}/system` })}
          >
            <>
              <SlidersOutlined /> <FormattedMessage id="NodesList.system" defaultMessage="System" />
            </>
          </Link>,
          `/node/${cwid}/${cnn}/system`
        ),
      ],
      null,
      online !== MQTT_STATUS.CONNECTED || changeClientVersion
    );
  });

  const [showAboutModal, setShowAboutModal] = useState(false);

  const handleShowAboutModal = useCallback(() => {
    setShowAboutModal(!showAboutModal);
  }, [showAboutModal]);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleNavClick = useCallback((navElement) => {
    if (navElement.key === "/about") {
      handleShowAboutModal();
    }
  }, []);

  const handleChangeSubmenu = useCallback((openKeys) => {
    store.dispatch(NAVIGATION_ACTIONS.SET_ACTIVE_SUBMENU({ activeSubmenu: [...openKeys] }));
  }, []);

  const menuItems = [
    getItem(
      <Link key="/" to="/">
        <FormattedMessage id="NavigationLayout.dashboard" defaultMessage="Dashboard" />
      </Link>,
      "/",
      <HomeOutlined />
    ),
    getItem(
      <FormattedMessage id="NavigationLayout.quickstreamHub" defaultMessage="Quickstream Hub" />,
      "/qs-hub",
      <CloudOutlined />,
      [
        ...(isUser
          ? [
              getItem(
                <Link key="/qs-hub/organization" to="/qs-hub/organization">
                  <FormattedMessage id="NavigationLayout.myOrganization" defaultMessage="My organization" />
                </Link>,
                "/qs-hub/organization",
                <IdcardOutlined />
              ),
            ]
          : []),
        ...(isAdminOrSupport
          ? [
              getItem(
                <Link key="/qs-hub/organizations" to="/qs-hub/organizations">
                  <FormattedMessage id="NavigationLayout.organizations" defaultMessage="Organizations" />
                </Link>,
                "/qs-hub/organizations",
                <IdcardOutlined />
              ),
            ]
          : []),
        getItem(
          <Link key="/qs-hub/hub-channels" to="/qs-hub/hub-channels">
            <FormattedMessage id="NavigationLayout.hubChannels" defaultMessage="Hub channels" />
          </Link>,
          "/qs-hub/hub-channels",
          <VideoCameraOutlined />
        ),
      ]
    ),
    ...(isAdmin
      ? [
          getItem(
            <Link key="/admin/accounts" to="/admin/accounts">
              <FormattedMessage id="NavigationLayout.manageAccounts" defaultMessage="Accounts" />
            </Link>,
            "/admin/accounts",
            <TeamOutlined />
          ),
          getItem(
            <Link key="/licenses" to="/licenses">
              <FormattedMessage id="NavigationLayout.manageLicense" defaultMessage="All licenses" />
            </Link>,
            "/licenses",
            <DatabaseOutlined />
          ),
          getItem(
            <Link key="/cloud/licenses" to="/cloud/licenses">
              <FormattedMessage id="NavigationLayout.cloudLicenses" defaultMessage="Cloud licenses" />
            </Link>,
            "/cloud/licenses",
            <BarChartOutlined />
          ),
          getItem(
            <Link key="/cloud/nodes" to="/cloud/nodes">
              <FormattedMessage id="NavigationLayout.cloudNodes" defaultMessage="Cloud nodes" />
            </Link>,
            "/cloud/nodes",
            <DeploymentUnitOutlined />
          ),
        ]
      : [
          getItem(
            <Link key="/licenses" to="/licenses">
              <FormattedMessage id="NavigationLayout.licenses" defaultMessage="Licenses" />
            </Link>,
            "/licenses",
            <DatabaseOutlined />
          ),
        ]),
    getItem(
      <Link key="/nodes-management" to="/nodes-management">
        <FormattedMessage id="NavigationLayout.manageNodes" defaultMessage="Nodes management" />
      </Link>,
      "/nodes-management",
      <CloudUploadOutlined />
    ),
    getItem(
      <FormattedMessage id="NodesList.nodesConfiguration" defaultMessage="Nodes configuration" />,
      "/node",
      <UnorderedListOutlined />,
      [...parsedCloudNodesList]
    ),
    getItem(<FormattedMessage id="NavigationLayout.about" defaultMessage="About" />, "/about", <BankOutlined />),
  ];

  return (
    <StyledLayout>
      <Header />
      <Layout hasSider>
        <StyledSider collapsible collapsed={collapsed} onCollapse={onCollapse} width={250}>
          <Menu
            mode="inline"
            theme="dark"
            onClick={handleNavClick}
            selectedKeys={activeKey}
            openKeys={activeSubmenu}
            onOpenChange={handleChangeSubmenu}
            items={menuItems}
          />
        </StyledSider>
        <StyledMainLayout>
          <StyledContent $collapsed={collapsed}>
            <PageHeader />
            <GlobalErrorBoundaries>
              <Outlet />
            </GlobalErrorBoundaries>
          </StyledContent>
        </StyledMainLayout>
      </Layout>
      <About handleShowModal={handleShowAboutModal} showModal={showAboutModal} />
    </StyledLayout>
  );
};

const StyledMainLayout = styled(Layout)`
  margin-top: 55px;
`;

const StyledLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledSider = styled(Sider)`
  position: sticky;
  top: 56px;
  height: calc(100vh - 99px);
  overflow: auto;

  &::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: #404040;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #737272;
  }
`;

const StyledContent = styled(Content)`
  height: calc(100vh - 99px);
  overflow: auto;
  padding: 12px;
  margin: 0;
  min-height: 28px;
  transition: margin 200ms ease-in;

  &::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: #404040;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #737272;
  }
`;

NavigationLayout.propTypes = {
  activeKey: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  activeSubmenu: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.array]).isRequired,
};

const mapStateToProps = (state) => ({
  activeKey: NAVIGATION_SELECTORS.getActiveNavKey(state),
  activeSubmenu: NAVIGATION_SELECTORS.getActiveNavSubmenu(state),
});

export default connect(mapStateToProps, null)(NavigationLayout);
