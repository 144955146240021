import React, { useEffect } from "react";
import { Spin, Skeleton } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import AccountService from "../../services/account";
import { errorNotification } from "../../lib/utils/notification";

const Auth2FA = () => {
  const { formatMessage } = useIntl();
  const { token } = useParams();

  useEffect(() => {
    AccountService.auth2faLogin({ token2fa: token }, { errorNotification: errorNotification(formatMessage) });
  }, [token, formatMessage]);

  return (
    <StyledSpin
      tip={<FormattedMessage id="Auth2FA.pleaseWait" defaultMessage="Please wait for login to Quickstream Cloud..." />}
      type="info"
    >
      <Skeleton active />
    </StyledSpin>
  );
};

const StyledSpin = styled(Spin)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default Auth2FA;
