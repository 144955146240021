import React from "react";
import { useSelector } from "react-redux";
import { Form, Divider, Row, Button, Col } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";

import { selectors as NODE_SELECTORS } from "ducks/node";
import NodeService from "services/node";
import MQTTService from "services/mqtt";

import { DEFAULT_OUT_QUICKSTREAM_METHOD_V2 } from "../../lib/utils/defaultValues/defaultDirectOutput";
import { CONNECTION_TYPE, MESSAGE_TYPE } from "../../lib/utils/constants";
import { errorNotification } from "../../lib/utils/notification";
import { max32characters, az09space, required } from "../../lib/utils/formValidation";

import InputTextFiled from "../Fields/InputTextField";
import { PortField } from "../Fields/Fields";
import LocalPortLabel from "../LocalPortLabel";

const translations = defineMessages({
  outputName: {
    id: "BasicModeQSOutput.outputName",
    defaultMessage: "Output name",
  },
  destinationName: {
    id: "BasicModeQSOutput.destinationName",
    defaultMessage: "Destination name",
  },
  uniqueLocalPort: {
    id: "BasicModeQSOutput.uniqueLocalPort",
    defaultMessage: "Same local port as for input, different value required",
  },
});

export default function BasicModeQSOutput({
  generalSettings,
  handlePrevStep,
  selectedNode,
  setOpenModal,
  setCurrentStep,
  cloudId,
  channelName,
  inputSettings,
}) {
  const stundAddress = useSelector(NODE_SELECTORS.getStundAddress);

  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const handleFinish = async (formData) => {
    const cloudData = {
      ...selectedNode,
      cloudIds: [cloudId],
      type: CONNECTION_TYPE.NODE_CLOUD,
    };

    const parsedChannelConfig = {
      ...generalSettings,
      ...inputSettings,
      outputs: [
        {
          ...DEFAULT_OUT_QUICKSTREAM_METHOD_V2,
          ...formData,
          cloudId,
          autoIp: true,
          cloudIp: stundAddress,
          cloudName: channelName,
        },
      ],
    };

    await NodeService.connectFingerprintToChannel(cloudData, {
      errorNotification: errorNotification(formatMessage),
    });

    const newPermissionIdToConnect = inputSettings?.input?.mainConnection?.permissionId;

    if (newPermissionIdToConnect) {
      const channelData = {
        cnn: selectedNode?.cnn,
        cwid: selectedNode.cwid,
        permissionId: inputSettings?.input?.mainConnection?.permissionId,
        type: CONNECTION_TYPE.NODE_DIRECT,
      };

      await NodeService.connectFingerprintToPermission(channelData, {
        errorNotification: errorNotification(formatMessage),
      });
    }

    MQTTService.sendMessageV5({
      topic: `node/${selectedNode.cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.ADD_NODE_CHANNEL,
        channelConfig: JSON.stringify(parsedChannelConfig),
        newCloudIdsToConnect: [cloudId],
        newPermissionIdToConnect,
      },
    });

    setOpenModal(false);
    setCurrentStep(0);
  };

  const uniqueLocalPortValidator = (rule, value) => {
    if (value === inputSettings?.input?.mainConnection?.localPort) {
      return Promise.reject(formatMessage(translations.uniqueLocalPort));
    }

    return Promise.resolve();
  };

  return (
    <Form onFinish={handleFinish} form={form} initialValues={{ outputName: `Output-QS-${cloudId}` }}>
      <Row gutter={24}>
        <Col span={12}>
          <InputTextFiled
            name={["outputName"]}
            label={formatMessage(translations.destinationName)}
            rules={[max32characters, az09space, required]}
          />
        </Col>
        <Col span={12}>
          <PortField
            name={["localPort"]}
            label={<LocalPortLabel noTooltip />}
            min={1}
            uniqValidation={uniqueLocalPortValidator}
          />
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Button style={{ margin: "0 8px" }} onClick={handlePrevStep}>
          <FormattedMessage id="general.previous" defaultMessage="Previous" />
        </Button>
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="general.done" defaultMessage="Done" />
        </Button>
      </Row>
    </Form>
  );
}

BasicModeQSOutput.propTypes = {
  generalSettings: PropTypes.object.isRequired,
  handlePrevStep: PropTypes.func.isRequired,
  selectedNode: PropTypes.object,
  setOpenModal: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  cloudId: PropTypes.number.isRequired,
  channelName: PropTypes.string.isRequired,
  inputSettings: PropTypes.object.isRequired,
};

BasicModeQSOutput.defaultProps = {
  selectedNode: null,
};
