import { SEO_STATUS } from "./constants";
import themeColors from "../style/theme";

export const VALIDATION_COLORS = {
  ACCEPTED: themeColors.green,
  REJECTED: themeColors.darkRed,
  PRIVATE: themeColors.lightRed,
  DEFAULT: themeColors.gray,
};

export default ({ organizationStatus, isOrganizationPublic, isRolePublic, roleStatus }) => {
  const roleExist = typeof isRolePublic !== "undefined" && typeof roleStatus !== "undefined";

  if (roleExist) {
    if (organizationStatus === SEO_STATUS.VERIFICATION) {
      return VALIDATION_COLORS.DEFAULT;
    }

    if (!isOrganizationPublic || !isRolePublic) {
      return VALIDATION_COLORS.PRIVATE;
    }

    if (organizationStatus === SEO_STATUS.REJECTED || roleStatus === SEO_STATUS.REJECTED) {
      return VALIDATION_COLORS.REJECTED;
    }

    if (organizationStatus === SEO_STATUS.ACCEPTED && roleStatus === SEO_STATUS.ACCEPTED) {
      return VALIDATION_COLORS.ACCEPTED;
    }

    return VALIDATION_COLORS.DEFAULT;
  }

  if (organizationStatus === SEO_STATUS.VERIFICATION) {
    return VALIDATION_COLORS.DEFAULT;
  }

  if (!isOrganizationPublic) {
    return VALIDATION_COLORS.PRIVATE;
  }

  if (organizationStatus === SEO_STATUS.REJECTED) {
    return VALIDATION_COLORS.REJECTED;
  }

  if (organizationStatus === SEO_STATUS.ACCEPTED) {
    return VALIDATION_COLORS.ACCEPTED;
  }

  return VALIDATION_COLORS.DEFAULT;
};
