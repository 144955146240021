import { createAction, handleActions } from "redux-actions";
import { Map, removeIn } from "immutable";
import moment from "moment";

export const SET_LIVE_STATISTIC = createAction("STATISTICS/SET_LIVE_STATISTIC");
export const CLEAR_STATISTIC = createAction("STATISTIC/CLEAR_STATISTIC");
export const CLEAR_ALL_STATISTIC_LIVE = createAction("STATISTICS/CLEAR_ALL_STATISTIC_LIVE");

const defaultState = new Map({
  liveData: {},
});

const reducer = handleActions(
  {
    [SET_LIVE_STATISTIC]: (state, { payload: { data, channelId, from } }) => {
      const oldChannelStats = state.getIn(["liveData", `${from}`, `${channelId}`]) || [];
      const stateStatsChannel = state.getIn(["liveData", `${from}`]) || [];
      const stateStatsData = state.get("liveData");

      if (oldChannelStats && oldChannelStats.length > 120) {
        const [, ...parsedOldChannelStats] = oldChannelStats;

        return state.merge({
          liveData: {
            ...stateStatsData,
            [from]: { ...stateStatsChannel, [channelId]: [...parsedOldChannelStats, data] },
          },
        });
      }

      return state.merge({
        liveData: { ...stateStatsData, [from]: { ...stateStatsChannel, [channelId]: [...oldChannelStats, data] } },
      });
    },
    [CLEAR_STATISTIC]: (state, { payload: { channelId, from } }) => removeIn(state, ["liveData", `${from}`, channelId]),
    [CLEAR_ALL_STATISTIC_LIVE]: () => {
      return defaultState;
    },
  },
  defaultState
);

export const selectors = {
  getStatistics: ({ state, ownProps }) => {
    const { cwid } = ownProps;

    return state.getIn(["nodeLiveStats", "liveData", `${cwid}`]);
  },
  getLiveStatsV5: ({ state, cwid, channelId }) => {
    return state.getIn(["nodeLiveStats", "liveData", `${cwid}`, `${channelId}`]) || null;
  },
  getSelectedStatsTypes: ({ state, channelId, type }) => {
    const cwid = state.getIn(["node", "cwid"]);

    const channelStats = state.getIn(["nodeLiveStats", "liveData", cwid, channelId]) || [];

    const parsedChannelStatsTyped = () => {
      if (type === "outputs") {
        return channelStats.map((stat) => [...stat.muxedNonUrl, ...stat.muxedUrl, ...stat.nonMuxed]);
      }

      if (type === "outputsV5") {
        return channelStats.map((stat) => [...stat.outputs, ...stat.networkStreams]);
      }

      if (type === "stream") {
        return channelStats.map((stat) => [...stat.outputStreams]);
      }

      if (type === "channelV5") {
        return channelStats.map((stat) => [...stat.channel]);
      }

      if (type === "videoCodecV5") {
        return channelStats.map((stat) => {
          const filteredVideoCodecStats = stat.codecs.filter((codecStat) => codecStat.p2 === -1);

          return filteredVideoCodecStats;
        });
      }

      if (type === "audioCodecV5") {
        return channelStats.map((stat) => {
          const filteredAudioCodecStats = stat.codecs.filter((codecStat) => codecStat.p2 === 0);

          return filteredAudioCodecStats;
        });
      }

      return channelStats.map((stat) => stat[type]);
    };

    const channelStatsTyped = parsedChannelStatsTyped();
    const selectorData = new Set();
    channelStatsTyped.forEach((statArr) => {
      statArr.forEach((statParamsArr) => {
        if (
          (type === "channelV5" && statParamsArr.n === "switcherSourceMainPercent") ||
          (type === "channelV5" && statParamsArr.n === "switcherSourceBackupPercent") ||
          (type === "channelV5" && statParamsArr.n === "switcherSourceEmergencyPercent")
        ) {
          selectorData.add("processing");

          return;
        }

        if (statParamsArr.n) {
          selectorData.add(statParamsArr.n);
        }
      });
    });

    return [...selectorData];
  },
  getSelectedStatistics: ({ selectedStat, type, data, codecId, streamId }) => {
    const filteredData = [];
    const filteredLabels = [];

    if (!data) {
      return { data: filteredData, labels: filteredLabels };
    }

    if (type === "videoCodecV5" || type === "audioCodecV5") {
      const filteredOutputs = data.map((stat) => {
        return stat.filter((statElement) => {
          return +statElement.p1 === +codecId;
        });
      });

      filteredOutputs.forEach((statArr) => {
        statArr.forEach((statParamsArr) => {
          if (statParamsArr?.n === selectedStat) {
            filteredData.push(statParamsArr?.v);
            if (statParamsArr.time) {
              filteredLabels.push(moment.unix(statParamsArr?.time).format("YYYY-MM-DD HH:mm:ss"));
            }
          }
        });
      });

      return { data: filteredData, labels: filteredLabels };
    }

    if (type === "codecs") {
      const filteredOutputs = data.map((stat) => {
        return stat.filter((statElement) => {
          return +statElement.p1 === +codecId && +statElement.p2 === +streamId;
        });
      });

      filteredOutputs.forEach((statArr) => {
        statArr.forEach((statParamsArr) => {
          if (statParamsArr?.n === selectedStat) {
            filteredData.push(statParamsArr?.v);
            if (statParamsArr.time) {
              filteredLabels.push(moment.unix(statParamsArr?.time).format("YYYY-MM-DD HH:mm:ss"));
            }
          }
        });
      });

      return { data: filteredData, labels: filteredLabels };
    }

    if (type === "outputs") {
      data.forEach((statArr) => {
        if (statArr?.n === selectedStat) {
          filteredData.push(statArr?.v);
          if (statArr.time) {
            filteredLabels.push(moment.unix(statArr?.time).format("YYYY-MM-DD HH:mm:ss"));
          }
        }
      });

      return { data: filteredData, labels: filteredLabels };
    }

    if (type === "outputsV5" || type === "stream") {
      data.forEach((statArr) => {
        if (statArr?.n === selectedStat) {
          filteredData.push(statArr?.v);
          if (statArr.time) {
            filteredLabels.push(moment.unix(statArr?.time).format("YYYY-MM-DD HH:mm:ss"));
          }
        }
      });

      return { data: filteredData, labels: filteredLabels };
    }

    if (type === "channelV5" && selectedStat === "processing") {
      const mainData = [];
      const backupData = [];
      const emergencyData = [];
      const sourceLabelsSet = new Set();

      data.forEach((statArr) => {
        statArr.forEach((statData) => {
          if (statData?.n === "switcherSourceMainPercent") {
            mainData.push(statData?.v);
          }
          if (statData?.n === "switcherSourceBackupPercent") {
            backupData.push(statData?.v);
          }
          if (statData?.n === "switcherSourceEmergencyPercent") {
            emergencyData.push(statData?.v);
          }
          if (statData.time) {
            sourceLabelsSet.add(moment.unix(statData?.time).format("YYYY-MM-DD HH:mm:ss"));
          }
        });
      });

      return { data: { mainData, backupData, emergencyData }, labels: Array.from(sourceLabelsSet) };
    }

    data.forEach((statArr) => {
      statArr.forEach((statParamsArr) => {
        if (statParamsArr?.n === selectedStat) {
          filteredData.push(statParamsArr?.v);
          if (statParamsArr.time) {
            filteredLabels.push(moment.unix(statParamsArr?.time).format("YYYY-MM-DD HH:mm:ss"));
          }
        }
      });
    });

    return { data: filteredData, labels: filteredLabels };
  },
  getLastBitrate: ({ state, channelId }) => {
    const cwid = state.getIn(["node", "cwid"]);

    const liveStats = state.getIn(["nodeLiveStats", "liveData", `${cwid}`, `${channelId}`]);
    const lastLiveStats = liveStats && liveStats[liveStats.length - 1];
    const lastLiveInputBitrate =
      lastLiveStats && lastLiveStats.input && lastLiveStats.input.filter((inputStat) => inputStat.n === "mbpsRecvRate");

    return lastLiveInputBitrate && lastLiveInputBitrate[0] && lastLiveInputBitrate[0].v;
  },

  getLastBitrateV5: ({ state, channelId, playingSource }) => {
    const cwid = state.getIn(["node", "cwid"]);

    const liveStats = state.getIn(["nodeLiveStats", "liveData", `${cwid}`, `${channelId}`]);
    const lastLiveStats = liveStats && liveStats[liveStats.length - 1];
    const lastLiveInputBitrate =
      lastLiveStats &&
      lastLiveStats[`${playingSource}Input`] &&
      lastLiveStats[`${playingSource}Input`].filter((inputStat) => inputStat.n === "mbpsRecvRate");

    return lastLiveInputBitrate && lastLiveInputBitrate[0] && lastLiveInputBitrate[0].v;
  },
};

export default reducer;
