import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Select, Space, Button, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";

import TranslatedStatus from "../../TranslatedStatus";
import { INPUT_STATUS_FILTERS, NODE_CHANNEL_STATE } from "../../../lib/utils/constants";

const { Option } = Select;

const MultiSelectFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearch = (selectedKeys, confirm) => {
    searchParams.set("inputStatus", selectedKeys);
    setSearchParams(searchParams);

    confirm();
  };

  const handleReset = (clearFilters, setSelectedKeys) => {
    searchParams.delete("inputStatus");
    setSearchParams(searchParams);

    clearFilters();
    setSelectedKeys([]);
  };

  return {
    filters: INPUT_STATUS_FILTERS,
    filterDropdown: ({ selectedKeys, setSelectedKeys, filters, confirm, clearFilters }) => {
      const children = filters.map((filter) => <Option key={filter.value}>{filter.text}</Option>);

      return (
        <div style={{ padding: 8 }}>
          <StyledSelect
            mode="multiple"
            placeholder={<FormattedMessage id="NodeChannelList.pleaseSelect" defaultMessage="Please select" />}
            onChange={(values) => setSelectedKeys(values)}
            value={selectedKeys}
          >
            {children}
          </StyledSelect>
          <StyledSpace>
            <StyledButton
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
              size="small"
            >
              <span>
                <FormattedMessage id="general.search" defaultMessage="Search" />
              </span>
            </StyledButton>
            <StyledButton onClick={() => handleReset(clearFilters, setSelectedKeys)} size="small">
              <FormattedMessage id="general.reset" defaultMessage="Reset" />
            </StyledButton>
          </StyledSpace>
        </div>
      );
    },
    onFilter: (value, record) => record.status.channelStatusText.indexOf(value) === 0,
    render: (status) => {
      if (status?.channelStatusText === NODE_CHANNEL_STATE.PROCESS_DISCONNECTED) {
        return <Spin size="small" />;
      }

      return status?.channelStatusText ? <TranslatedStatus channelStatus={status?.channelStatusText} /> : null;
    },
  };
};

const StyledButton = styled(Button)`
  width: 90px;
  margin: 0 5px;
`;

const StyledSpace = styled(Space)`
  margin-top: 10px;
  display: block;

  .ant-space-item {
    display: inline-block;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  max-width: 300px;
`;

export default MultiSelectFilter;
