import React, { useState } from "react";
import { Col, Select, Form, Input, Button, Row, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { UserOutlined, CloudOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";

import CloudService from "../../services/cloud";
import MQTTService from "../../services/mqtt";
import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";

import NewNodeRegionLabel from "./NewNodeRegionLabel";
import InstallNewNodeLicenses from "./InstallNewNodeLicenses";

import { required, max32characters, az09space } from "../../lib/utils/formValidation";
import { errorNotification } from "../../lib/utils/notification";
import themeColor from "../../lib/style/theme";
import { SELECT_NODE_TYPES, SELECT_AWS_REGIONS, NODE_TYPES, MESSAGE_TYPE } from "../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

const InstallNewNode = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const [openNewCloudNode, setOpenNewCloudNode] = useState(false);
  const { resetFields } = form;

  const nodes = useSelector(MQTT_SELECTORS.getNodes);
  const cloudNodeLicenses = useSelector(MQTT_SELECTORS.getCloudNodeLicenses);
  const cloudNodes = nodes.filter((node) => node.type === NODE_TYPES.CLOUD);

  const usedTrialNodes = cloudNodes.filter((node) => node.instanceType === SELECT_NODE_TYPES[0].value).length;
  const usedMediumNodes = cloudNodes.filter((node) => node.instanceType === SELECT_NODE_TYPES[1].value).length;
  const usedLargeNodes = cloudNodes.filter((node) => node.instanceType === SELECT_NODE_TYPES[2].value).length;
  const usedXLargeNodes = cloudNodes.filter((node) => node.instanceType === SELECT_NODE_TYPES[3].value).length;
  const used2XLargeNodes = cloudNodes.filter((node) => node.instanceType === SELECT_NODE_TYPES[4].value).length;

  const handleNewCloudNode = async (formData) => {
    CloudService.newCloudNode(formData, { errorNotification: errorNotification(formatMessage) });
    setOpenNewCloudNode(false);
    resetFields();
  };

  const handleCancel = () => {
    setOpenNewCloudNode(false);
    resetFields();
  };

  const handleShowModal = () => {
    MQTTService.sendMessage({
      topic: `platform/in`,
      message: {
        msgType: MESSAGE_TYPE.CLOUD_NODE_LICENSES,
      },
    });

    setOpenNewCloudNode(true);
  };

  return (
    <Row gutter={24} justify="end">
      <Col span={12}>
        <Button
          href="https://quickstream.tech/download"
          target="_blank"
          rel="noopener noreferrer"
          block
          size="large"
          icon={<UserOutlined />}
          type="primary"
        >
          <span>
            <FormattedMessage id="InstallNewNode.instalUserNode" defaultMessage="Instal user node" />
          </span>
        </Button>
      </Col>
      <Col span={12}>
        <StyledCloudNodeButton type="default" block size="large" icon={<CloudOutlined />} onClick={handleShowModal}>
          <span>
            <FormattedMessage id="InstallNewNode.installCloudNode" defaultMessage="Install cloud node" />
          </span>
        </StyledCloudNodeButton>
      </Col>

      <Modal
        title={<FormattedMessage id="InstallNewNode.newCloudNode" defaultMessage="New cloud node" />}
        centered
        destroyOnClose
        open={openNewCloudNode}
        footer={null}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={handleNewCloudNode}>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                name="cnn"
                label={<FormattedMessage id="InstallNewNode.nodeName" defaultMessage="Node name" />}
                rules={[required, az09space, max32characters]}
              >
                <Input />
              </Item>
            </Col>
            <Col span={10}>
              <Item
                name="instanceType"
                label={
                  <>
                    <FormattedMessage id="InstallNewNode.type" defaultMessage="Type" />
                    <InstallNewNodeLicenses
                      usedTrialNodes={usedTrialNodes}
                      usedMediumNodes={usedMediumNodes}
                      usedLargeNodes={usedLargeNodes}
                      usedXLargeNodes={usedXLargeNodes}
                      used2XLargeNodes={used2XLargeNodes}
                      iconType
                    />
                  </>
                }
                rules={[required]}
              >
                <Select>
                  <Option
                    key={SELECT_NODE_TYPES[0].value}
                    value={SELECT_NODE_TYPES[0].value}
                    disabled={usedTrialNodes >= cloudNodeLicenses?.nodesTrial}
                  >
                    {SELECT_NODE_TYPES[0].label}
                  </Option>
                  <Option
                    key={SELECT_NODE_TYPES[1].value}
                    value={SELECT_NODE_TYPES[1].value}
                    disabled={usedMediumNodes >= cloudNodeLicenses?.nodesMedium}
                  >
                    {SELECT_NODE_TYPES[1].label}
                  </Option>
                  <Option
                    key={SELECT_NODE_TYPES[2].value}
                    value={SELECT_NODE_TYPES[2].value}
                    disabled={usedLargeNodes >= cloudNodeLicenses?.nodesLarge}
                  >
                    {SELECT_NODE_TYPES[2].label}
                  </Option>
                  <Option
                    key={SELECT_NODE_TYPES[3].value}
                    value={SELECT_NODE_TYPES[3].value}
                    disabled={usedXLargeNodes >= cloudNodeLicenses?.nodesXLarge}
                  >
                    {SELECT_NODE_TYPES[3].label}
                  </Option>
                  <Option
                    key={SELECT_NODE_TYPES[4].value}
                    value={SELECT_NODE_TYPES[4].value}
                    disabled={used2XLargeNodes >= cloudNodeLicenses?.nodes2XLarge}
                  >
                    {SELECT_NODE_TYPES[4].label}
                  </Option>
                </Select>
              </Item>
            </Col>
            <Col span={14}>
              <Item name="instanceRegion" label={<NewNodeRegionLabel />} rules={[required]}>
                <Select showSearch optionFilterProp="children">
                  {SELECT_AWS_REGIONS.map((region) => (
                    <Option key={region.value} value={region.value}>
                      {region.label}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <StyledRow justify="end" gutter={24}>
            <Col span={4}>
              <Button onClick={() => setOpenNewCloudNode(false)}>
                <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit">
                <FormattedMessage id="global.save" defaultMessage="Save" />
              </Button>
            </Col>
          </StyledRow>
        </Form>
      </Modal>
    </Row>
  );
};

const StyledCloudNodeButton = styled(Button)`
  background: ${themeColor.cloudBlue};
  color: #fff !important;
  border: 1px solid #000 !important;

  &:hover {
    background: ${themeColor.hoverCloudBlue} !important;
  }
`;

const StyledRow = styled(Row)`
  padding-top: 15px;
  border-top: 1px solid #303030;
`;

export default InstallNewNode;
