import React, { useContext } from "react";
import { Col, Form, Switch, Select } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { IPFieldV5 } from "../../../Fields/Fields";
import InputNumberField from "../../../Fields/InputNumberField";
import InputTextField from "../../../Fields/InputTextField";

import { required } from "../../../../lib/utils/formValidation";
import globalTranslations from "../../../../lib/translations";

import { FormV5Context } from "../../NodeChannelFormV5";

import useNodeNDISChannels from "../../../../hooks/useNodeNDIChannels";

const translations = defineMessages({
  stopTime: {
    id: "CasparFormItem.stopTime",
    defaultMessage: "Stop timeó [ms]",
  },
  casparIp: {
    id: "CasparFormItem.casparIp",
    defaultMessage: "IP",
  },
  addClearToAllItems: {
    id: "CasparFormItem.addClearToAllItems",
    defaultMessage: "Add clear to all items",
  },
  casparChannel: {
    id: "CasparFormItem.casparChannel",
    defaultMessage: "Channel",
  },
  casparPort: {
    id: "CasparFormItem.casparPort",
    defaultMessage: "Port",
  },
});

const { Item } = Form;

export default function CasparFormItem({ prefix, disabled }) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);
  const { setFieldValue } = form;

  const { nodeNDISSelector, loadingNdis } = useNodeNDISChannels();

  const addClearToAllItems = Form.useWatch([...prefix, "addClearToAllItems"], form);

  const handleChangeNdiId = (selectedAddress) => {
    setFieldValue([...prefix, "casparNdiAddr"], selectedAddress);
  };

  return (
    <>
      <Col span={12}>
        <InputTextField
          name={[...prefix, "customPath"]}
          disabled={disabled}
          label={<FormattedMessage id="CasparFormItem.customPathFiles" defaultMessage="Custom path files" />}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "stopTime"]}
          label={formatMessage(translations.stopTime)}
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={6}>
        <Item
          name={[...prefix, "addClearToAllItems"]}
          valuePropName="checked"
          label={formatMessage(translations.addClearToAllItems)}
          rules={[required]}
        >
          <Switch
            disabled={disabled}
            defaultChecked={addClearToAllItems}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
          />
        </Item>
      </Col>
      <Col span={6}>
        <IPFieldV5 name={[...prefix, "casparIp"]} label={formatMessage(translations.casparIp)} disabled={disabled} />
      </Col>
      <Col span={9}>
        <Item
          name={[...prefix, "casparNdiId"]}
          label={<FormattedMessage id="CasparFormItem.casparNdiId" defaultMessage="NDI ID" />}
          rules={[required]}
        >
          <Select
            allowClear
            showSearch
            loading={loadingNdis}
            disabled={disabled || loadingNdis}
            options={nodeNDISSelector}
            onChange={(_, option) => handleChangeNdiId(option?.streamAddr)}
          />
        </Item>
      </Col>
      <Col span={9}>
        <InputTextField
          name={[...prefix, "casparNdiAddr"]}
          disabled
          label={<FormattedMessage id="CasparFormItem.casparNdiAddr" defaultMessage="NDI Address" />}
          rules={[required]}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "casparChannel"]}
          label={formatMessage(translations.casparChannel)}
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "casparPort"]}
          label={formatMessage(translations.casparPort)}
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
    </>
  );
}

CasparFormItem.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
};
