import React from "react";
import { Divider, Col, Form, Select } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { required } from "../../../../../lib/utils/formValidation";
import {
  MPEG2AUDIO_SAMPLE_FORMAT,
  MPEG2AUDIO_SAMPLE_RATE,
  MPEG2AUDIO_BITRATE,
} from "../../../../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

const Mpeg2AudioCodec = ({ disabled, fieldPrefix }) => {
  return (
    <>
      <Divider />
      <Col span={8}>
        <Item
          name={[...fieldPrefix, "b"]}
          label={<FormattedMessage id="Mpeg2AudioCodec.bitrate" defaultMessage="Bitrate" />}
          rules={[required]}
        >
          <Select disabled={disabled}>
            {MPEG2AUDIO_BITRATE.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={8}>
        <Item
          name={[...fieldPrefix, "sampleFormat"]}
          label={<FormattedMessage id="Mpeg2AudioCodec.sampleFormat" defaultMessage="Sample format" />}
          rules={[required]}
        >
          <Select disabled={disabled}>
            {MPEG2AUDIO_SAMPLE_FORMAT.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={8}>
        <Item
          name={[...fieldPrefix, "sampleRate"]}
          label={<FormattedMessage id="Mpeg2AudioCodec.sampleRate" defaultMessage="Sample rate" />}
          rules={[required]}
        >
          <Select disabled={disabled}>
            {MPEG2AUDIO_SAMPLE_RATE.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
    </>
  );
};

Mpeg2AudioCodec.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

export default Mpeg2AudioCodec;
