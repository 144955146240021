import * as React from "react";
import { useIntl, defineMessages } from "react-intl";
import { Col } from "antd";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { selectors as CONNECTION_SELECTORS } from "../../../../ducks/node";

import { IPFieldV5, PortField } from "../../../Fields/Fields";
import NetworkField from "../../../Fields/NetworkField";

import NetworkFieldLabel from "../../Labels/NetworkFieldLabel";
import LocalPortLabel from "../../../LocalPortLabel";

import { CONNECTION_METHOD_INPUT, NODE_OS } from "../../../../lib/utils/constants";

const translations = defineMessages({
  ipAddressLabel: {
    id: "IPFieldV5.ipAddressLabel",
    defaultMessage: "IP Address",
  },
});

function InputStreamUdpSection({ prefix, connectionMethod, disabled, basicMode }) {
  const { formatMessage } = useIntl();
  const isMulticastType = connectionMethod === CONNECTION_METHOD_INPUT.inUdpMulticast.value;
  const isUnicastType = connectionMethod === CONNECTION_METHOD_INPUT.inUdpUnicast.value;
  const nodeOS = useSelector(CONNECTION_SELECTORS.getNodeOS);
  const isWindows = nodeOS === NODE_OS.WINDOWS;

  return (
    <>
      {isMulticastType && (
        <>
          <Col span={12}>
            <IPFieldV5
              name={[...prefix, "destinationAddress"]}
              label={formatMessage(translations.ipAddressLabel)}
              disabled={disabled}
              isInputUdp
            />
          </Col>
          <Col span={12}>
            <PortField name={[...prefix, "destinationPort"]} disabled={disabled} />
          </Col>
        </>
      )}

      {!basicMode && (
        <Col span={12}>
          <NetworkField
            name={[...prefix, "interfaceIp"]}
            label={NetworkFieldLabel("input")}
            disabled={disabled}
            disableAutomatic={isWindows && isMulticastType}
          />
        </Col>
      )}
      {isUnicastType && (
        <Col span={12}>
          <PortField
            name={[...prefix, "localPort"]}
            disabled={disabled}
            label={<LocalPortLabel noTooltip={isUnicastType} />}
            min={1}
          />
        </Col>
      )}
    </>
  );
}

InputStreamUdpSection.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  connectionMethod: PropTypes.string.isRequired,
  basicMode: PropTypes.bool,
};

InputStreamUdpSection.defaultProps = {
  disabled: null,
  basicMode: false,
};

export default InputStreamUdpSection;
