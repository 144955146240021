import React from "react";
import { Form, Button, Input } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import { az09space, max32characters } from "../../../lib/utils/formValidation";

const { Item, List } = Form;

const translations = defineMessages({
  email: {
    id: "PermissionInvitationDynamicForm.email",
    defaultMessage: "Business email",
  },
  shareName: {
    id: "PermissionInvitationDynamicForm.permissionName",
    defaultMessage: "Share name",
  },
  notValidEmail: {
    id: "general.notValidEmail",
    defaultMessage: "Invalid email format",
  },
});

const PermissionInvitationDynamicForm = ({ getFieldValue, setFieldsValue, channelName }) => {
  const { formatMessage } = useIntl();

  const setInitialFieldValue = () => {
    const fieldValue = getFieldValue("invitations");
    const initialUsersData = fieldValue.map((value) => {
      if (value?.shareName) {
        return value;
      }

      return { ...value, shareName: channelName };
    });

    setFieldsValue({ invitations: initialUsersData });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  return (
    <List name="invitations">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => (
              <Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={
                  index === 0 ? (
                    <FormattedMessage id="PermissionInvitationDynamicForm.emails" defaultMessage="Emails" />
                  ) : (
                    ""
                  )
                }
                required={false}
                key={field.key}
                fieldKey={field.fieldKey}
              >
                <Item
                  name={[field.name, "email"]}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: (
                        <FormattedMessage
                          id="PermissionInvitationDynamicForm.emailRequired"
                          defaultMessage="Please input email or delete this field."
                        />
                      ),
                    },
                    { type: "email", message: formatMessage(translations.notValidEmail) },
                  ]}
                  noStyle
                >
                  <Input placeholder={formatMessage(translations.email)} style={{ width: "45%", marginRight: 8 }} />
                </Item>
                <Item
                  name={[field.name, "shareName"]}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: (
                        <FormattedMessage
                          id="PermissionInvitationDynamicForm.shareNameRequired"
                          defaultMessage="Please input share name or delete this field."
                        />
                      ),
                    },
                    az09space,
                    max32characters,
                  ]}
                  noStyle
                >
                  <Input placeholder={formatMessage(translations.shareName)} style={{ width: "45%", marginRight: 8 }} />
                </Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                ) : null}
              </Item>
            ))}
            <Item>
              <StyledButton>
                <Button
                  type="dashed"
                  style={{ width: "50%" }}
                  onClick={() => {
                    add();
                    setInitialFieldValue();
                  }}
                >
                  <PlusOutlined />{" "}
                  <FormattedMessage
                    id="PermissionInvitationDynamicForm.addInvitation"
                    defaultMessage="Add invitation"
                  />
                </Button>
              </StyledButton>
            </Item>
          </>
        );
      }}
    </List>
  );
};

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
`;

PermissionInvitationDynamicForm.propTypes = {
  channelName: PropTypes.string,
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
};

PermissionInvitationDynamicForm.defaultProps = {
  channelName: null,
};

export default PermissionInvitationDynamicForm;
