import React from "react";
import { Input, Form } from "antd";
import { FormattedMessage } from "react-intl";

import { ASCIICharacters } from "../../../../lib/utils/formValidation";

const { TextArea } = Input;
const { Item } = Form;

export default function ChannelDescription() {
  return (
    <Item
      name="description"
      rules={[ASCIICharacters]}
      label={<FormattedMessage id="ChannelDescription.label" defaultMessage="Description" />}
    >
      <TextArea showCount maxLength={500} />
    </Item>
  );
}
