import { MPEG2VIDEO_PRESETS } from "../constants";

const mpeg2VideDefaultValues = ({ prefix, presetId, getFieldValue, setFieldValue }) => {
  const codec = getFieldValue(prefix);

  switch (presetId) {
    case 1:
      delete codec.sarNum;
      delete codec.sarDen;
      delete codec.g;
      delete codec.bf;
      setFieldValue(prefix, {
        ...codec,
        width: MPEG2VIDEO_PRESETS.preset4K.width,
        height: MPEG2VIDEO_PRESETS.preset4K.height,
        customSar: MPEG2VIDEO_PRESETS.preset4K.customSar,
        presetId,
      });
      break;
    case 2:
      delete codec.sarNum;
      delete codec.sarDen;
      delete codec.g;
      delete codec.bf;
      setFieldValue(prefix, {
        ...codec,
        width: MPEG2VIDEO_PRESETS.presetFullHD.width,
        height: MPEG2VIDEO_PRESETS.presetFullHD.height,
        customSar: MPEG2VIDEO_PRESETS.presetFullHD.customSar,
        presetId,
      });
      break;
    case 3:
      delete codec.sarNum;
      delete codec.sarDen;
      delete codec.g;
      delete codec.bf;
      setFieldValue(prefix, {
        ...codec,
        width: MPEG2VIDEO_PRESETS.presetHD.width,
        height: MPEG2VIDEO_PRESETS.presetHD.height,
        customSar: MPEG2VIDEO_PRESETS.presetHD.customSar,
        presetId,
      });
      break;
    case 4:
      delete codec.g;
      delete codec.bf;
      setFieldValue(prefix, {
        ...codec,
        width: MPEG2VIDEO_PRESETS.presetPALSD169.width,
        height: MPEG2VIDEO_PRESETS.presetPALSD169.height,
        customSar: MPEG2VIDEO_PRESETS.presetPALSD169.customSar,
        sarNum: MPEG2VIDEO_PRESETS.presetPALSD169.sarNum,
        sarDen: MPEG2VIDEO_PRESETS.presetPALSD169.sarDen,
        presetId,
      });
      break;
    case 5:
      delete codec.g;
      delete codec.bf;
      setFieldValue(prefix, {
        ...codec,
        width: MPEG2VIDEO_PRESETS.presetPALSD43.width,
        height: MPEG2VIDEO_PRESETS.presetPALSD43.height,
        customSar: MPEG2VIDEO_PRESETS.presetPALSD43.customSar,
        sarNum: MPEG2VIDEO_PRESETS.presetPALSD43.sarNum,
        sarDen: MPEG2VIDEO_PRESETS.presetPALSD43.sarDen,
        presetId,
      });
      break;
    case 6:
      delete codec.g;
      delete codec.bf;
      setFieldValue(prefix, {
        ...codec,
        width: MPEG2VIDEO_PRESETS.presetNTSCSD169.width,
        height: MPEG2VIDEO_PRESETS.presetNTSCSD169.height,
        customSar: MPEG2VIDEO_PRESETS.presetNTSCSD169.customSar,
        sarNum: MPEG2VIDEO_PRESETS.presetNTSCSD169.sarNum,
        sarDen: MPEG2VIDEO_PRESETS.presetNTSCSD169.sarDen,
        presetId,
      });
      break;
    case 7:
      delete codec.g;
      delete codec.bf;
      setFieldValue(prefix, {
        ...codec,
        width: MPEG2VIDEO_PRESETS.presetNTSCSD43.width,
        height: MPEG2VIDEO_PRESETS.presetNTSCSD43.height,
        customSar: MPEG2VIDEO_PRESETS.presetNTSCSD43.customSar,
        sarNum: MPEG2VIDEO_PRESETS.presetNTSCSD43.sarNum,
        sarDen: MPEG2VIDEO_PRESETS.presetNTSCSD43.sarDen,
        presetId,
      });
      break;
    default:
      setFieldValue(prefix, {
        ...codec,
        presetId,
      });
      break;
  }
};

export default mpeg2VideDefaultValues;
