import React, { useState, useCallback } from "react";
import { Button, Modal, Affix } from "antd";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";

import AdminService from "../../services/admin";
import { errorNotification } from "../../lib/utils/notification";

import RegistrationForm from "../RegistrationForm";
import TurnstileValidation from "../TurnstileValidation";

const CreateAccountModal = () => {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const afterSubmitCallback = useCallback(() => {
    handleShowModal();
    AdminService.getAccountList({ errorNotification: errorNotification(formatMessage) });
  }, [handleShowModal, formatMessage]);

  return (
    <>
      <Affix offsetBottom={0}>
        <StyledButton type="primary" onClick={handleShowModal}>
          <FormattedMessage id="CreateAccountModal.addNewAccount" defaultMessage="Add new account" />
        </StyledButton>
      </Affix>
      <Modal
        title={<FormattedMessage id="CreateAccountModal.title" defaultMessage="Add account form" />}
        open={showModal}
        footer={null}
        onCancel={handleShowModal}
      >
        <RegistrationForm afterSubmitCallback={afterSubmitCallback} adminMode />
        <TurnstileValidation />
      </Modal>
    </>
  );
};

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
`;

export default CreateAccountModal;
