import { useSelector } from "react-redux";

import { selectors as MQTT_SELECTORS } from "../ducks/mqtt";

import { MQTT_STATUS } from "../lib/utils/constants";

function useNodeConnection() {
  const MQTTStatus = useSelector(MQTT_SELECTORS.getStatus);
  const MQTTConnection = useSelector(MQTT_SELECTORS.getMqttConnection);
  const isConnection = MQTTConnection && MQTTStatus === MQTT_STATUS.CONNECTED;

  return isConnection;
}

export default useNodeConnection;
