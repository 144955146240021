import React, { useState, useEffect } from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Divider, Button, Row, Col, Popconfirm, Table } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useParams, useSearchParams } from "react-router-dom";

import MQTTService from "../../services/mqtt";

import SelectEngine from "../SelectEngine";

import { selectors as CHANNEL_SELECTORS } from "../../ducks/nodeChannels";
import { selectors as LOGS_SELECTORS } from "../../ducks/logs";

import themeColor from "../../lib/style/theme";
import { NODE, MESSAGE_TYPE } from "../../lib/utils/constants";

import useMainTheme from "../../hooks/useMainTheme";

import LogsLabel from "./LogsLabel";

const Logs = ({ channels, logs, channelDetailView }) => {
  const { cwid } = useParams();
  const { isDarkTheme } = useMainTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const channelId = searchParams.get("c") || "-1";

  const logsExist = logs && channelId in logs;
  const logsData = logsExist && logs[channelId];
  const lastLogsId = (logsData && logsData[logsData.length - 1] && logsData[logsData.length - 1].id) || 0;

  const [lastChannelLog, setLastChannelLog] = useState(lastLogsId);

  const handleSelectChange = (selectedChannelId) => {
    searchParams.set("c", selectedChannelId);
    setSearchParams(searchParams);
    setLastChannelLog(0);
  };

  useEffect(() => {
    const getLogsParams = {
      msgType: MESSAGE_TYPE.GET_LOGS,
      channelId: `${channelId}`,
      first: +lastChannelLog - 1,
      count: 10,
    };

    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: getLogsParams,
    });
  }, [channelId, lastChannelLog, cwid]);

  const channelsData = channels.toJS();

  const handleLoadMoreLogs = () => {
    if (+lastLogsId !== 1) {
      setLastChannelLog(+lastLogsId);
    }
  };

  const getClassName = (loglevel) => {
    switch (+loglevel) {
      case 0:
        return "critical";
      case 1:
        return "error";
      case 2:
        return "warning";
      case 3:
        return "info";
      case 4:
        return "debug";
      default:
        return "verbose";
    }
  };

  const handleRemoveLogs = () => {
    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: { msgType: MESSAGE_TYPE.DELETE_LOGS_FROM_DB },
    });
  };

  const getChannelName = (cloudId) => {
    switch (cloudId) {
      case "0":
        return NODE;
      default:
        return channelsData[cloudId]?.config?.name;
    }
  };

  const columns = [
    {
      title: <FormattedMessage id="Logs.id" defaultMessage="ID" />,
      dataIndex: "id",
      key: "id",
    },
    {
      title: <FormattedMessage id="Logs.time" defaultMessage="Time" />,
      dataIndex: "time",
      key: "time",
    },
    {
      title: <FormattedMessage id="Logs.logSource" defaultMessage="Log source" />,
      dataIndex: "channelId",
      key: "channelId",
      render: (value) => {
        const channelName = getChannelName(value);

        return channelName;
      },
    },
    {
      title: <FormattedMessage id="Logs.errorCode" defaultMessage="Error code" />,
      dataIndex: "errorCode",
      key: "errorCode",
    },
    {
      title: <FormattedMessage id="Logs.level" defaultMessage="Log level" />,
      dataIndex: "level",
      key: "level",
      render: (value) => {
        return <LogsLabel logValue={value} />;
      },
    },
    {
      title: <FormattedMessage id="Logs.description" defaultMessage="Description" />,
      dataIndex: "description",
      key: "description",
      render: (value) => {
        return <StyledDescription>{value}</StyledDescription>;
      },
    },
  ];

  return (
    <>
      <Row type="flex" justify="space-between">
        {!channelDetailView && (
          <Col span={12}>
            <StyledRightMargin>
              <FormattedMessage id="Logs.showLogs" defaultMessage="Show logs:" />
            </StyledRightMargin>
            <SelectEngine channelId={channelId} handleSelectChange={handleSelectChange} logList />
          </Col>
        )}
        <StyledRightCol span={channelDetailView ? 24 : 12}>
          <Popconfirm
            title={
              <FormattedMessage
                id="Logs.confirmRemove"
                defaultMessage="Are you sure, you want to remove all logs from database?"
              />
            }
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={handleRemoveLogs}
            okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
            cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
          >
            <Button type="primary" danger icon={<DeleteOutlined />}>
              <span>
                <FormattedMessage id="Logs.logsFromDB" defaultMessage="Delete logs" />
              </span>
            </Button>
          </Popconfirm>
        </StyledRightCol>
      </Row>
      <Divider />
      <StyledTable
        $isDarkTheme={isDarkTheme}
        columns={columns}
        dataSource={logsExist ? logsData : []}
        pagination={false}
        bordered
        role="table"
        rowKey={(record) => record.id}
        rowClassName={(record) => getClassName(record.level)}
      />
      <StyledButton type="primary" onClick={handleLoadMoreLogs}>
        <FormattedMessage id="Logs.loadMore" defaultMessage="Load more logs" />
      </StyledButton>
    </>
  );
};

const StyledRightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
`;

const StyledRightMargin = styled.span`
  margin-right: 5px;
`;

const StyledTable = styled(Table)`
  .critical {
    background-color: ${themeColor.logCritical};
    color: #000;
  }
  .error {
    background-color: ${themeColor.logError};
    color: #000;
  }
  .warning {
    background-color: ${themeColor.logWarning};
    color: #000;
  }
  .debug {
    background-color: ${themeColor.logDebug};
    color: #000;
  }
  .info {
    background-color: ${themeColor.logInfo};
    color: #000;
  }
  .verbose {
    background-color: ${themeColor.maritime};
    color: #000;
  }

  .ant-table-row {
    transition: color 0.3s ease;
    &:hover {
      color: ${(props) => (props.$isDarkTheme ? "#fff" : "#000")};
  }
`;

const StyledDescription = styled.span`
  word-break: break-all;
`;

export const mapStateToProps = (state) => ({
  channels: CHANNEL_SELECTORS.getChannels(state),
  logs: LOGS_SELECTORS.getLogs(state),
});

Logs.propTypes = {
  channelDetailView: PropTypes.bool,
  channels: ImmutablePropTypes.map.isRequired,
  logs: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        channelId: PropTypes.string,
        engineStatus: PropTypes.string.isRequired,
        errorCode: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
        srcOrDest: PropTypes.string,
        time: PropTypes.string.isRequired,
        threadId: PropTypes.string,
      })
    )
  ),
};

Logs.defaultProps = {
  channelDetailView: null,
  logs: {},
};

export default connect(mapStateToProps, null)(Logs);
