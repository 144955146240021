import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import SearchFilter from "../../../components/Filters/SearchFilter";
import MultiSelectFilter from "../../../components/Filters/MultiSelectFilter";
import ValidLicenseBadge from "../../../components/ValidLicenseBadge";
import NodeChannelsActionButtons from "../../../components/NodeChannelsActionButtons";
import NodeChannelsListBitrate from "./NodeChannelsListBitrate";
import BackupSwitch from "../../../components/NodeChannelFormV4/InputSection/BackupSwitch";
import ClientsConnected from "../../../components/NodeChannelFormV4/DetailSection/ClientsConnected";

const translations = defineMessages({
  name: { id: "NodeChannelsListColumns.name", defaultMessage: "Name" },
  logs: {
    id: "NodeChannelsListColumns.logs",
    defaultMessage: "Logs",
  },
  stats: {
    id: "NodeChannelsListColumns.stats",
    defaultMessage: "Stats",
  },
  mainInput: {
    id: "NodeChannelsListColumns.mainInput",
    defaultMessage: "Main",
  },
  backupInput: {
    id: "NodeChannelsListColumns.backupInput",
    defaultMessage: "Backup",
  },
  inputStatus: {
    id: "NodeChannelsListColumns.inputStatus",
    defaultMessage: "Input status",
  },
  inputSource: {
    id: "NodeChannelsListColumns.inputSource",
    defaultMessage: "Input source",
  },
  licenseStatus: {
    id: "NodeChannelsListColumns.licenseStatus",
    defaultMessage: "License status",
  },
  actions: {
    id: "NodeChannelsListColumns.actions",
    defaultMessage: "Actions",
  },
  inputBitrate: {
    id: "NodeChannelsListColumns.inputBitrate",
    defaultMessage: "Input bitrate",
  },
  connected: {
    id: "NodeChannelsListColumns.connected",
    defaultMessage: "Connected",
  },
});

const NodeChannelsListColumns = ({ cwid, cnn }) => {
  const { formatMessage } = useIntl();

  const [searchParams] = useSearchParams();
  const inputStatus = searchParams.get("inputStatus");
  const search = searchParams.get("search");

  const parsedInputStatus = inputStatus && inputStatus.includes(",") ? inputStatus.split(",") : [inputStatus];

  const columns = [
    {
      key: "name",
      title: formatMessage(translations.name),
      ...SearchFilter({ cwid, cnn, dataIndex: "searchAll", placeholder: "all", hidden: true }),
      ...(search ? { filteredValue: [search] } : { filteredValue: null }),
    },
    {
      key: "InputSource",
      align: "center",
      title: formatMessage(translations.inputSource),
      dataIndex: "status",
      width: 170,
      render: (status, { channelId, config }) => {
        const isBackup = config?.input?.backup;

        return (
          <>
            <div>
              {status?.usesBackup ? formatMessage(translations.backupInput) : formatMessage(translations.mainInput)}
            </div>
            {isBackup && (
              <BackupSwitch
                channelId={channelId}
                usesBackup={status?.usesBackup}
                requestedStatusText={status?.requestedStatusText}
              />
            )}
          </>
        );
      },
    },
    {
      key: "status",
      align: "center",
      title: formatMessage(translations.inputStatus),
      dataIndex: "status",
      ...MultiSelectFilter(),
      ...(inputStatus ? { filteredValue: [...parsedInputStatus] } : { filteredValue: null }),
    },
    {
      key: "status",
      align: "center",
      title: formatMessage(translations.inputBitrate),
      dataIndex: "status",
      render: (status, record) => {
        return (
          <NodeChannelsListBitrate
            channelId={status?.channelId}
            channelStatus={status?.channelStatusText}
            inputType={record?.config?.input?.type}
          />
        );
      },
    },
    {
      key: "status",
      align: "center",
      title: formatMessage(translations.connected),
      dataIndex: "status",
      render: ({ connected, outputs }) => {
        return <ClientsConnected connected={connected} outputs={outputs} />;
      },
    },
    {
      key: "licenseStatus",
      align: "center",
      title: formatMessage(translations.licenseStatus),
      dataIndex: "validLicense",
      render: (validLicense, record) => {
        return (
          <ValidLicenseBadge
            validLicense={validLicense}
            licensesUsed={record.licensesUsed}
            licenseId={record?.config?.licenseId}
          />
        );
      },
    },
    {
      key: "actions",
      fixed: "right",
      width: 400,
      title: formatMessage(translations.actions),
      render: (text, { status, channelId, config }) => {
        return <NodeChannelsActionButtons channelConfig={config} channelId={channelId} status={status} />;
      },
    },
  ];

  return columns;
};

export default NodeChannelsListColumns;
