import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { WarningFilled } from "@ant-design/icons";
import styled from "styled-components";
import { Card } from "antd";

import themeColor from "../../../lib/style/theme";

class GeneralErrorBoundaries extends Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorInfo: error };
  }

  componentDidCatch(error) {
    // eslint-disable-next-line no-console
    console.log("Error - GeneralErrorBoundaries", error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Card title={<FormattedMessage id="GeneralErrorBoundaries.error" defaultMessage="Error" />}>
          <StyledErrorBoundaries>
            <StyledIcon>
              <WarningFilled />
            </StyledIcon>
            <FormattedMessage
              id="GeneralErrorBoundaries.errorBoundaries"
              defaultMessage="Element cannot be displayed - render data error"
            />
            <StyledHelpdesk>
              <FormattedMessage
                id="GeneralErrorBoundaries.errorBoundariesHelpdesk"
                defaultMessage="For troubleshooting please contact with:"
              />{" "}
              <a href="mailto:hello@quickstream.tech">hello@quickstream.tech</a>.
            </StyledHelpdesk>
          </StyledErrorBoundaries>
        </Card>
      );
    }

    return children;
  }
}

const StyledHelpdesk = styled.div`
  display: block;
`;

const StyledIcon = styled.span`
  margin-right: 5px;
  font-size: 22px;
  color: ${themeColor.orangeHover};
`;

const StyledErrorBoundaries = styled.h3`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

GeneralErrorBoundaries.propTypes = {
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object, PropTypes.array]).isRequired,
};

export default GeneralErrorBoundaries;
