const DEFAULT_PLAYOUT_ENGINE = {
  audioStreamsCount: 1,

  playlistSource: {
    daysAhead: 1,
    pingInterval: 5,
    reconnectTimeout: 5,
    refreshInterval: 300,
    refreshIntervalOnErrors: 5,
    type: "MediaTool",
    sendInfoToApi: false,
    url: "",
  },

  liveSource: {
    enabled: false,
  },

  outputs: [],

  fileSource: {
    customPathFiles: "",
    useBackup: false,

    customAdBreakFile: "",
    audioMatrixForAdBreak:
      // eslint-disable-next-line max-len
      "1,0,0,0,0,0,0,0;0,1,0,0,0,0,0,0;1,0,0,0,0,0,0,0;0,1,0,0,0,0,0,0;1,0,0,0,0,0,0,0;0,1,0,0,0,0,0,0;1,0,0,0,0,0,0,0;0,1,0,0,0,0,0,0",
    customEmergencyFile: "",
    audioMatrixForEmergency:
      // eslint-disable-next-line max-len
      "1,0,0,0,0,0,0,0;0,1,0,0,0,0,0,0;1,0,0,0,0,0,0,0;0,1,0,0,0,0,0,0;1,0,0,0,0,0,0,0;0,1,0,0,0,0,0,0;1,0,0,0,0,0,0,0;0,1,0,0,0,0,0,0",

    bufferFrames: 100,
    bufferFramesStart: 10,
    fileCheckInterval: 15,
    fileNameSuffix: "",
    getFrameTimeout: 1000,
    maxInterstreamDistance: 2000,
    maxRestartTime: 5000,
    tcOutRelaxedLimitFrames: 100,
    openTimeout: 1000,
    reinitDelay: 1000,
  },

  cg: {
    mode: "off",
  },

  other: {
    clonedFramesBeforeEmergency: 25,
    outputBufferLength: 1000,
    playlistEndingAlert: 6,
    sourceAnalyzeDuration: 5000000,
    sourceProbeSize: 5000000,
    useHwDecoding: false,
  },

  subtitles: {
    enabled: false,
  },
  scte: {
    apiMode: "Placeholder",
    commandMode: "SpliceInsert",
    omitCueIn: false,
    repeat: 5,
  },
  monitoring: {
    enabled: false,
  },
  timecode: {
    enabled: false,
  },
};

export default DEFAULT_PLAYOUT_ENGINE;
