import { notification } from "antd";
import React from "react";

function NotificationHolder() {
  const [api, contextHolder] = notification.useNotification({ top: 60, placement: "topRight" });

  React.useEffect(() => {
    const handleMessage = (event) => {
      const { args, type } = event.detail;
      api[type](args);
    };

    document.addEventListener("notificationEvent", handleMessage);

    return () => {
      document.removeEventListener("notificationEvent", handleMessage);
    };
  }, [api]);

  return contextHolder;
}

export default NotificationHolder;
