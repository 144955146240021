import React, { useCallback, useEffect, useState } from "react";
import { Card, Tabs, Table, Badge, Empty } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

import store from "../../store";

import {
  selectors as NOTIFICATION_SELECTORS,
  actions as NOTIFICATION_ACTIONS,
  getErrorsTabsSelector,
  getWarningsTabsSelector,
} from "../../ducks/nodeNotifications";

import themeColor from "../../lib/style/theme";

import NotificationsPageTitle from "./NotificationsPageTitle";
import NotificationsPageColumns from "./NotificationsPageColumns";

const NotificationsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { formatMessage } = useIntl();
  const errorTabs = useSelector(getErrorsTabsSelector);
  const errors = useSelector(NOTIFICATION_SELECTORS.getErrors);
  const warningsTabs = useSelector(getWarningsTabsSelector);
  const warningsData = useSelector(NOTIFICATION_SELECTORS.getWarnings);
  const showPopup = useSelector(NOTIFICATION_SELECTORS.getShowPopup);
  const warningsCounter = useSelector(NOTIFICATION_SELECTORS.getWarningsCounter);
  const errorCounter = useSelector(NOTIFICATION_SELECTORS.getErrorCounter);
  const noWarnings = warningsTabs && warningsTabs.length === 0;
  const noErrors = errorTabs && errorTabs.length === 0;
  const [activeTab, setActiveTab] = useState(errorTabs.length > 0 ? errorTabs[0] : null);
  const [activeTabType, setActiveTabType] = useState("error");

  useEffect(() => {
    if (activeTab) {
      store.dispatch(NOTIFICATION_ACTIONS.SET_NOTIFICATION_VIEW_ACTIVE(`${activeTabType}&${activeTab}`));
      store.dispatch(NOTIFICATION_ACTIONS.CLEAR_NODE_UNREAD_NOTIFICATIONS({ activeTab, activeTabType }));
    }

    return () => {
      store.dispatch(NOTIFICATION_ACTIONS.SET_NOTIFICATION_VIEW_DEACTIVATED());
    };
  }, [activeTab, activeTabType]);

  const handleSwitchNotification = (value) => {
    store.dispatch(NOTIFICATION_ACTIONS.SET_NOTIFICATION_ACTIVE_STATE(value));
    window.localStorage.setItem("showPopupNotification", value);
  };

  const handleChangeNodeTab = useCallback((tab) => {
    setActiveTab(tab);
  }, []);
  const handleChangeTabType = useCallback(
    (tabType) => {
      setActiveTabType(tabType);

      if (tabType === "warning") {
        setActiveTab(warningsTabs && warningsTabs.length ? warningsTabs[0] : null);
      }

      if (tabType === "error") {
        setActiveTab(errorTabs && errorTabs.length ? errorTabs[0] : null);
      }
    },
    [warningsTabs, errorTabs]
  );

  const handleChangePagination = useCallback((paginationTable) => {
    searchParams.set("page", paginationTable.current);
    setSearchParams(searchParams);
  }, []);

  const tabItems = [
    {
      icon: (
        <StyledError>
          <WarningOutlined />
        </StyledError>
      ),
      key: "error",
      label: (
        <Badge count={errorCounter} size="small" offset={[10, 0]}>
          <FormattedMessage id="NotificationsPage.errors" defaultMessage="Errors" />
        </Badge>
      ),
      children: (
        <Card>
          {noErrors && (
            <StyledEmpty>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </StyledEmpty>
          )}
          {!noErrors && (
            <Tabs
              onTabClick={handleChangeNodeTab}
              activeKey={activeTab}
              items={errorTabs.map((node) => {
                const nodeErrors = errors.get(node);
                const unreadNodeCounter = nodeErrors.get("unreadCounter");
                const parsedNodeErrors = Object.values(nodeErrors.delete("unreadCounter").flatten(3).toJS());

                return {
                  key: node,
                  label: (
                    <Badge count={unreadNodeCounter} size="small" offset={[10, 0]}>
                      {node}
                    </Badge>
                  ),
                  children: (
                    <Table
                      rowKey={(record) => `${record?.channelId - record?.errorCode}`}
                      columns={NotificationsPageColumns({ formatMessage })}
                      dataSource={parsedNodeErrors}
                      onChange={handleChangePagination}
                    />
                  ),
                };
              })}
            />
          )}
        </Card>
      ),
    },
    {
      icon: (
        <StyledWarning>
          <InfoCircleOutlined />
        </StyledWarning>
      ),
      key: "warning",
      label: (
        <Badge count={warningsCounter} size="small" offset={[10, 0]}>
          <FormattedMessage id="NotificationsPage.warnings" defaultMessage="Warnings" />
        </Badge>
      ),
      children: (
        <Card>
          {noWarnings && (
            <StyledEmpty>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </StyledEmpty>
          )}
          {!noWarnings && (
            <Tabs
              onTabClick={handleChangeNodeTab}
              activeKey={activeTab}
              items={warningsTabs.map((node) => {
                const nodeWarnings = warningsData.get(node);
                const unreadNodeCounter = nodeWarnings.get("unreadCounter");
                const parsedNodeWarnings = Object.values(nodeWarnings.delete("unreadCounter").flatten(3).toJS());

                return {
                  label: (
                    <Badge count={unreadNodeCounter} size="small" offset={[10, 0]}>
                      {node}
                    </Badge>
                  ),
                  key: node,
                  children: (
                    <Table
                      rowKey={(record) => `${record?.channelId - record?.warningCode}`}
                      columns={NotificationsPageColumns({ formatMessage })}
                      dataSource={parsedNodeWarnings}
                      onChange={handleChangePagination}
                    />
                  ),
                };
              })}
            />
          )}
        </Card>
      ),
    },
  ];

  return (
    <Card
      title={<FormattedMessage id="NotificationsPage.title" defaultMessage="Notifications" />}
      extra={<NotificationsPageTitle showPopup={showPopup} handleSwitchNotification={handleSwitchNotification} />}
    >
      <Tabs defaultActiveKey="error" onTabClick={handleChangeTabType} activeKey={activeTabType} items={tabItems} />
    </Card>
  );
};

const StyledEmpty = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledError = styled.span`
  color: ${themeColor.lightRed};
`;

const StyledWarning = styled.span`
  color: ${themeColor.orange};
`;

export default NotificationsPage;
