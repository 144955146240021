import { bindActionCreators } from "redux";
import { defineMessages } from "react-intl";

import store from "../store";
import { actions as ADMIN_ACTIONS } from "../ducks/admin";

// import * as requests from "../lib/api";
import { AuthAPI } from "../lib/api";
import getMessageFromErrorCode from "../lib/utils/getMessageFromErrorCode";

const translations = defineMessages({
  deleteSuccess: {
    id: "account.deleteSuccess",
    defaultMessage: "Your account was successfully removed",
  },
});

const actions = bindActionCreators(
  {
    getAccountList:
      ({ errorNotification }) =>
      async () => {
        try {
          const accountsList = await AuthAPI.getAccountList({ errorNotification });

          store.dispatch(ADMIN_ACTIONS.SAVE_ACCOUNTS_LIST(accountsList.reverse()));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    deleteAccount: (userId, { errorNotification, successNotification }) => {
      return async () => {
        try {
          await AuthAPI.deleteAccount(userId, { errorNotification });
          successNotification(translations.deleteSuccess);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
  },

  store.dispatch
);

export default actions;
