import { useSelector } from "react-redux";

import { selectors as ACCOUNT_SELECTORS } from "../ducks/account";

import { ROLE } from "../lib/utils/constants";

function useRole() {
  const role = useSelector(ACCOUNT_SELECTORS.getRole);

  const isAdmin = role === ROLE.ADMIN;
  const isSupport = role === ROLE.SUPPORT;
  const isUser = role === ROLE.USER;
  const isAdminOrSupport = isAdmin || isSupport;

  return { isAdmin, isSupport, isUser, isAdminOrSupport };
}

export default useRole;
