import React from "react";
import { Row, Col, Space } from "antd";

import LogLevel from "../LogLevel";
import EditNodeName from "../EditNodeName";
import LocalPasswordForm from "../LocalPasswordForm";
import SourceSwitchTooltip from "../SourceSwitchTooltip";

import useNodeCapabilities from "../../../../hooks/useNodeCapabilities";

import LoadingNodeData from "../../../../components/LoadingNodeData";

export default function GeneralTab() {
  const nodeCapabilities = useNodeCapabilities();
  const cloudConfigCapabilities =
    nodeCapabilities?.otherCapabilities && nodeCapabilities?.otherCapabilities.includes("cloudconfig");

  if (!nodeCapabilities) {
    return <LoadingNodeData />;
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      <LogLevel />
      <Row gutter={24}>
        <Col md={24} lg={cloudConfigCapabilities ? 8 : 12}>
          <EditNodeName />
        </Col>
        <Col md={24} lg={cloudConfigCapabilities ? 8 : 12}>
          <LocalPasswordForm />
        </Col>
        {cloudConfigCapabilities && (
          <Col md={24} lg={8}>
            <SourceSwitchTooltip />
          </Col>
        )}
      </Row>
    </Space>
  );
}
