import { Button, Col, Row } from "antd";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const OrganizationStepIntroduction = () => {
  const navigate = useNavigate();

  const GetStarted = useCallback(() => {
    navigate("/organization/edit");
  }, []);

  return (
    <Row>
      <Col span={24}>
        <StyledTitle>
          <FormattedMessage
            id="OrganizationStepIntroduction.description"
            defaultMessage="Every Account in Quickstream may be a member of an Organization"
          />
        </StyledTitle>
      </Col>
      <Col span={24}>
        <StyledTitle>
          <FormattedMessage
            id="OrganizationStepIntroduction1.description"
            defaultMessage={`Currently, your Account does not belong to any Organization.
             Click Assign to complete your profile.`}
          />
        </StyledTitle>
      </Col>
      <Col style={{ textAlign: "center" }} span={24}>
        <Button type="primary" onClick={GetStarted}>
          <FormattedMessage id="OrganizationStepIntroduction.buttonText" defaultMessage="Assign" />
        </Button>
      </Col>
    </Row>
  );
};

const StyledTitle = styled.h3`
  text-align: center;
  margin-bottom: 2rem;
`;

export default OrganizationStepIntroduction;
