import React from "react";
import { Card, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { LineChartOutlined, BranchesOutlined, ControlOutlined, CloudOutlined } from "@ant-design/icons";

import useNodeVersion from "../../../hooks/useNodeVersion";
import useSetNodeConnection from "../../../hooks/useSetNodeConnection";
import useRequestNodeLiveStats from "../../../hooks/useRequestNodeLiveStats";

import NodeLiveStatistics from "./NodeLiveStatistics";
import NodeInterfaces from "./NodeInterfaces";
import GlobalErrorBoundaries from "../../../components/ErrorBoundaries/GlobalErrorBoundaries";
import NodeCloudLicenseUsage from "./NodeCloudLicenseUsage";
import GeneralTab from "./GeneralTab";

const NodeSystem = () => {
  const { isV5Node } = useNodeVersion();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryTab = searchParams.get("tab") || "general";

  useSetNodeConnection();
  useRequestNodeLiveStats();

  const items = [
    {
      icon: <ControlOutlined />,
      label: (
        <span>
          <FormattedMessage id="ChannelSystem.General" defaultMessage="General" />
        </span>
      ),
      key: "general",
      children: <GeneralTab />,
    },
    {
      icon: <LineChartOutlined />,
      label: (
        <span>
          <FormattedMessage id="ChannelSystem.statistics" defaultMessage="Statistics" />
        </span>
      ),
      key: "statistics",
      children: <NodeLiveStatistics />,
    },
    {
      icon: <BranchesOutlined />,
      label: (
        <span>
          <FormattedMessage id="ChannelSystem.network" defaultMessage="Network" />
        </span>
      ),
      key: "network",
      children: <NodeInterfaces />,
    },
    ...(isV5Node
      ? [
          {
            icon: <CloudOutlined />,
            label: (
              <span>
                <FormattedMessage id="ChannelSystem.nodeLicensesUsage" defaultMessage="Node licenses usage" />
              </span>
            ),
            key: "cloudLicenseUsage",
            children: <NodeCloudLicenseUsage />,
          },
        ]
      : []),
  ];

  const handleChangeTabs = (selectedTab) => {
    searchParams.set("tab", selectedTab);
    setSearchParams(searchParams);
  };

  return (
    <GlobalErrorBoundaries>
      <Card>
        <Tabs activeKey={queryTab} items={items} onChange={handleChangeTabs} />
      </Card>
    </GlobalErrorBoundaries>
  );
};

export default NodeSystem;
