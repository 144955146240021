import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { useSelector } from "react-redux";

import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";

import themeColor from "../../lib/style/theme";

export default function NodeNetworkOutput({ totalNodesNetworkOutput, instanceId, isCloudNode, onDemand }) {
  const cloudNodeTraffic = useSelector((state) => MQTT_SELECTORS.getCloudNodeNetworkTraffic(state, instanceId)) || {};
  const { sum: networkOutput } = cloudNodeTraffic;

  const parsedNetworkTraffic = totalNodesNetworkOutput !== undefined ? totalNodesNetworkOutput : networkOutput;

  if (!parsedNetworkTraffic && !isCloudNode) {
    return <span>N/D</span>;
  }

  if (parsedNetworkTraffic === 0 && isCloudNode) {
    return <StyledBOutput>{`${parsedNetworkTraffic} [B]`}</StyledBOutput>;
  }

  if (onDemand && !parsedNetworkTraffic && isCloudNode) {
    return "";
  }

  if (!parsedNetworkTraffic && isCloudNode) {
    return (
      <StyledOutput>
        <Spin spinning />
      </StyledOutput>
    );
  }

  if (parsedNetworkTraffic < 1000) {
    return <StyledBOutput>{`${parsedNetworkTraffic} [B]`}</StyledBOutput>;
  }
  if (parsedNetworkTraffic < 1000 * 1000) {
    const parsedNetworkOutput = (parsedNetworkTraffic / 1000).toFixed(2);

    return <StyledKBOutput>{`${parsedNetworkOutput} [KB]`}</StyledKBOutput>;
  }
  if (parsedNetworkTraffic < 1000 * 1000 * 1000) {
    const parsedNetworkOutput = (parsedNetworkTraffic / (1000 * 1000)).toFixed(2);

    return <StyledMBOutput>{`${parsedNetworkOutput} [MB]`}</StyledMBOutput>;
  }
  if (parsedNetworkTraffic < 1000 * 1000 * 1000 * 1000) {
    const parsedNetworkOutput = (parsedNetworkTraffic / (1000 * 1000 * 1000)).toFixed(2);

    return <StyledGBOutput>{`${parsedNetworkOutput} [GB]`}</StyledGBOutput>;
  }

  const parsedNetworkOutput = (parsedNetworkTraffic / (1000 * 1000 * 1000 * 1000)).toFixed(2);

  return <StyledTBOutput>{`${parsedNetworkOutput} [TB]`}</StyledTBOutput>;
}

const StyledOutput = styled.span`
  color: ${themeColor.maritime};

  .ant-spin-dot-item {
    background-color: ${themeColor.maritime};
  }
`;

const StyledBOutput = styled.span`
  color: ${themeColor.greyTitle};

  .ant-spin-dot-item {
    background-color: ${themeColor.greyTitle};
  }
`;

const StyledKBOutput = styled.span`
  color: ${themeColor.greenHover};

  .ant-spin-dot-item {
    background-color: ${themeColor.greenHover}
`;

const StyledMBOutput = styled.span`
  color: ${themeColor.yellowLinkHover};

  .ant-spin-dot-item {
    background-color: ${themeColor.yellowLinkHover};
  }
`;

const StyledGBOutput = styled.span`
  color: ${themeColor.orange};

  .ant-spin-dot-item {
    background-color: ${themeColor.orange};
  }
`;

const StyledTBOutput = styled.span`
  color: ${themeColor.lightRed};

  .ant-spin-dot-item {
    background-color: ${themeColor.lightRed};
  }
`;
