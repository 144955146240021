import React from "react";
import PropTypes from "prop-types";
import { Input, Form } from "antd";
import { useIntl } from "react-intl";

import globalTranslations from "../../../lib/translations";

const { Item } = Form;

function InputTextFiled({ name, label, rules, onChange, disabled, hidden, required }) {
  const { formatMessage } = useIntl();

  return (
    <Item
      hidden={hidden}
      label={label}
      name={name}
      rules={[
        ...rules,
        {
          required,
          message: formatMessage(globalTranslations.required),
        },
      ]}
    >
      <Input type="text" onChange={onChange} disabled={disabled} />
    </Item>
  );
}

InputTextFiled.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  onChange: PropTypes.func,
  rules: PropTypes.arrayOf(PropTypes.object),
  hidden: PropTypes.bool,
  required: PropTypes.bool,
};

InputTextFiled.defaultProps = {
  disabled: false,
  label: "",
  onChange: null,
  rules: [],
  hidden: false,
  required: false,
};

export default InputTextFiled;
