import React, { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, Tabs, Radio, Select, Row, Col, Button, Spin, Empty, Statistic } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import dayjs from "dayjs";

import store from "../../../store";

import { selectors as NODE_CHANNELS_SELECTOR } from "../../../ducks/nodeChannels";
import { actions as NODE_STATS_V5_ACTIONS, selectors as NODE_STATS_V5_SELECTOR } from "../../../ducks/nodeStatsV5";
import { selectors as NODE_LIVE_STATS_SELECTORS } from "../../../ducks/nodeLiveStats";
import CloudChannelsServices from "../../../services/cloudChannels";
import MQTTService from "../../../services/mqtt";

import { errorNotification } from "../../../lib/utils/notification";
import nodeTranslations from "../../../lib/translations/nodeTranslations";
import { MESSAGE_TYPE } from "../../../lib/utils/constants";
import { GRAPH_OPTIONS, GRAPH_DATASET_OPTIONS } from "../../../lib/style/theme";
import getTimeRangeData from "../../../lib/utils/getTimeRangeData";
import convertNodeStatsFormat from "../../../lib/utils/convertNodeStatsFormat";
import queryURL from "../../../lib/utils/queryURL";

import StatisticsGraphTitle from "./StatisticsGraphTitle";
import StatisticsView from "./StatisticsView";
import StatsScaleSelector from "../../../components/StatsScaleSelector";

const { Group: RadioGroup, Button: RadioButton } = Radio;
const { Countdown } = Statistic;

export default function StatisticHistoricalV5Outputs({ channelId }) {
  useEffect(() => {
    return () => {
      queryURL({ removeKey: "outputIdTab" });
      queryURL({ removeKey: "sourceTab" });
    };
  }, []);

  const outputStats = useSelector((state) => NODE_STATS_V5_SELECTOR.getOutputsStatsSelector(state, channelId));
  const isChannelStarted = useSelector((state) => NODE_CHANNELS_SELECTOR.getIsChannelStarted({ state, channelId }));
  const defaultDataRange = useSelector(NODE_STATS_V5_SELECTOR.getStatsRangeParam);
  const data = useSelector(NODE_STATS_V5_SELECTOR.getStatsData);
  const statScale = useSelector(NODE_STATS_V5_SELECTOR.getStatsScale);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeOutputTab = searchParams.get("outputIdTab");
  const activeSourceTab = searchParams.get("sourceTab");

  const { parentOutputs, uniqueOutputsIds, uniqueOutputStreamsIds, qsChannelIds } = outputStats;
  const { formatMessage } = useIntl();
  const { cwid } = useParams();
  const statRef = useRef(null);
  const [channelParams, setChannelParams] = useState({});
  const statisticsData = useSelector((state) => NODE_LIVE_STATS_SELECTORS.getLiveStatsV5({ state, cwid, channelId }));

  const newestStatData = statisticsData && statisticsData[statisticsData.length - 1];
  const newestOutputStatData = newestStatData && [
    ...newestStatData.networkStreams,
    ...newestStatData.outputs,
    ...newestStatData.outputStreams,
  ];

  const isAnyOutputs = Object.keys(uniqueOutputsIds).length > 0;

  const [selectedStat, setSelectedStat] = useState(null);
  const [selectedRange, setSelectedRange] = useState(getTimeRangeData(defaultDataRange));
  const [liveTimeInterval, setLiveTimeInterval] = useState(null);

  useEffect(() => {
    Chart.register(zoomPlugin);

    return () => {
      Chart.unregister(zoomPlugin);
    };
  }, []);

  useEffect(() => {
    CloudChannelsServices.getChannelPermissionsDescription(
      { cloudIds: qsChannelIds, channelId, cwid },
      {
        errorNotification: errorNotification(formatMessage),
      }
    );
  }, [cwid, channelId, qsChannelIds]);

  useEffect(() => {
    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.GET_STAT_PARAM_NAMES,
        channelId,
        start: selectedRange?.start,
        end: selectedRange?.end,
      },
    });
  }, [selectedRange, channelId]);

  const { p1: param1, p2: param2, p3: param3, s: source } = channelParams;
  const isChannelParams = Object.keys(channelParams).length > 0;

  useEffect(() => {
    if (selectedStat) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_STAT_VALUES,
          channelId,
          start: selectedRange?.start,
          end: selectedRange?.end,
          statName: selectedStat,
          param1,
          param2,
          param3,
          scale: statScale,
          source,
        },
      });
    }
  }, [param1, param2, param3, source, selectedRange, channelId, cwid, selectedStat, statScale]);

  useEffect(() => {
    const { value } = selectedRange;
    let liveInterval = null;

    if (value === 0.5 && isChannelParams && selectedStat) {
      setLiveTimeInterval(dayjs());
      liveInterval = setInterval(() => {
        const { start, end } = getTimeRangeData(value);
        setLiveTimeInterval(dayjs());

        MQTTService.sendMessage({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.GET_STAT_PARAM_NAMES,
            channelId,
            start,
            end,
          },
        });

        MQTTService.sendMessage({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.GET_STAT_VALUES,
            channelId,
            start,
            end,
            statName: selectedStat,
            param1,
            param2,
            param3,
            scale: statScale,
            source,
          },
        });
      }, 30000);
    } else {
      clearInterval(liveInterval);
    }

    return () => {
      clearInterval(liveInterval);
    };
  }, [selectedRange, param1, param2, param3, source, cwid, channelId, selectedStat, isChannelParams, statScale]);

  const parseStatisticName = (statisticName) => {
    const translationExist = statisticName in nodeTranslations;

    return translationExist ? formatMessage(nodeTranslations[statisticName]) : statisticName;
  };

  const parsedData = {
    labels: data.map((statistic) => statistic.timestamp),
    datasets: [
      {
        label: (selectedStat && parseStatisticName(selectedStat)) || "No statistic selected",
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        pointBorderColor: "rgba(75,192,192,1)",
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        data: data && data.map((statistic) => statistic.value),
        ...GRAPH_DATASET_OPTIONS,
      },
    ],
  };

  const handleChangeOutputTab = () => {
    store.dispatch(NODE_STATS_V5_ACTIONS.CLEAR_STATS_V5_DATA());
    setSelectedStat(null);
  };

  const handleResetZoom = () => {
    if (statRef.current) {
      statRef.current.resetZoom();
    }
  };

  const handleChangeChannelStatParam = ({ selectedStatName, outputConfig }) => {
    setChannelParams(outputConfig);
    setSelectedStat(selectedStatName);

    if (!selectedStatName) {
      store.dispatch(NODE_STATS_V5_ACTIONS.CLEAR_STATS_V5_DATA());
    }

    handleResetZoom();
  };

  const handleClickOutputTab = (selectedSource) => {
    searchParams.set("sourceTab", selectedSource);
    setSearchParams(searchParams);
  };

  const handleClickParentTab = (selectedParentId) => {
    searchParams.set("outputIdTab", selectedParentId);
    setSearchParams(searchParams);
  };

  const outputsTabsRender = (outputId) => {
    return [
      ...(uniqueOutputsIds[outputId] ? uniqueOutputsIds[outputId] : []),
      ...(uniqueOutputStreamsIds[outputId] ? uniqueOutputStreamsIds[outputId] : []),
    ].map((outputConfig) => {
      const filteredNewestOutputStatData =
        newestOutputStatData && newestOutputStatData.filter((stat) => stat.e === outputConfig.e);

      const selectorData = outputConfig.n.map((selectorValue) => ({
        value: selectorValue,
        label: (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
      }));

      return {
        label: outputConfig.name
          ? `${outputConfig.name} ${outputConfig.connectionMethod ? `(${outputConfig.connectionMethod})` : ""}`
          : outputConfig?.e,
        key: `${outputConfig.s}-${outputConfig.p1}-${outputConfig.p2}-${outputConfig.p3}`,
        children: (
          <Row justify="center" gutter={[24, 24]}>
            <Col span={18}>
              <Row justify="space-between">
                <Col>
                  <Select
                    autoFocus
                    style={{ width: 320 }}
                    options={selectorData}
                    onChange={(selectedStatName) => handleChangeChannelStatParam({ selectedStatName, outputConfig })}
                    allowClear
                  />
                  <StatisticsGraphTitle hideTitle />
                </Col>
                <Col>
                  <Button type="primary" onClick={handleResetZoom}>
                    <FormattedMessage id="StatisticHistoricalV5Outputs.resetZoom" defaultMessage="Reset zoom" />
                  </Button>
                </Col>
              </Row>
              <Spin
                tip={
                  <FormattedMessage
                    id="StatisticHistoricalV5Outputs.pleaseSelectStatistic"
                    defaultMessage="Please use statistic selector for data"
                  />
                }
                spinning={!selectedStat}
                indicator={<InfoCircleOutlined />}
              >
                <Line data={parsedData} options={GRAPH_OPTIONS} ref={statRef} />
              </Spin>
            </Col>
            {isChannelStarted && filteredNewestOutputStatData && (
              <StatisticsView data={convertNodeStatsFormat(filteredNewestOutputStatData)} />
            )}
          </Row>
        ),
      };
    });
  };

  const parentOutputsTabs = parentOutputs.map((outputConfig) => {
    return {
      label: outputConfig.name && `${outputConfig.name} (${outputConfig.connectionMethod})`,
      key: `${outputConfig.outputId}`,
      children: (
        <Card>
          <Tabs
            destroyInactiveTabPane
            items={outputsTabsRender(outputConfig.outputId)}
            onChange={handleChangeOutputTab}
            onTabClick={handleClickOutputTab}
            defaultActiveKey={activeSourceTab}
          />
        </Card>
      ),
    };
  });

  const handleChangeDateRange = (event) => {
    handleResetZoom();
    store.dispatch(NODE_STATS_V5_ACTIONS.CLEAR_STATS_V5_DATA());
    store.dispatch(NODE_STATS_V5_ACTIONS.SET_DATE_RANGE_PARAMS_V5(event.target.value));

    switch (event.target.value) {
      case 1:
        setSelectedRange(getTimeRangeData(1));
        setLiveTimeInterval(null);

        return;

      case 6:
        setSelectedRange(getTimeRangeData(6));
        setLiveTimeInterval(null);

        return;

      case 24:
        setSelectedRange(getTimeRangeData(24));
        setLiveTimeInterval(null);

        return;

      case 48:
        setSelectedRange(getTimeRangeData(48));
        setLiveTimeInterval(null);

        return;

      case 72:
        setSelectedRange(getTimeRangeData(72));
        setLiveTimeInterval(null);

        return;

      default:
        setSelectedRange(getTimeRangeData(0.5));
        store.dispatch(NODE_STATS_V5_ACTIONS.SET_STAT_SCALE_V5(1));
        if (selectedStat) {
          setLiveTimeInterval(dayjs());
        }
    }
  };

  const deadline30sFuture = liveTimeInterval && liveTimeInterval.add(30, "second");

  return (
    <Card
      extra={
        <>
          <StatsScaleSelector selectedRangeValue={selectedRange?.value} />
          <RadioGroup defaultValue={defaultDataRange} onChange={handleChangeDateRange} buttonStyle="solid">
            <RadioButton value={0.5}>
              {liveTimeInterval && (
                <StyledCountdown value={deadline30sFuture} prefix={<LoadingOutlined />} format="ss" />
              )}
              <FormattedMessage id="StatisticHistoricalV5.live" defaultMessage="Live" />
            </RadioButton>
            <RadioButton value={1}>
              <FormattedMessage id="StatisticHistoricalV5.1hour" defaultMessage="1 hour" />
            </RadioButton>
            <RadioButton value={6}>
              <FormattedMessage id="StatisticHistoricalV5.6hour" defaultMessage="6 hours" />
            </RadioButton>
            <RadioButton value={24}>
              <FormattedMessage id="StatisticHistoricalV5.1day" defaultMessage="1 day" />
            </RadioButton>
            <RadioButton value={48}>
              <FormattedMessage id="StatisticHistoricalV5.2days" defaultMessage="2 days" />
            </RadioButton>
            <RadioButton value={72}>
              <FormattedMessage id="StatisticHistoricalV5.3days" defaultMessage="3 days" />
            </RadioButton>
          </RadioGroup>
        </>
      }
    >
      {!isAnyOutputs && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {isAnyOutputs && (
        <Tabs
          onTabClick={handleClickParentTab}
          items={parentOutputsTabs}
          onChange={handleChangeOutputTab}
          destroyInactiveTabPane
          defaultActiveKey={activeOutputTab}
        />
      )}
    </Card>
  );
}

const StyledCountdown = styled(Countdown)`
  display: inline-block;
  margin-right: 4px;
  min-width: 28px;

  .ant-statistic-content {
    font-size: 10px;
  }
`;
