export default [
  {
    code: "int",
    name: "International",
  },
  {
    code: "am",
    name: "Amharic",
  },
  {
    code: "ar",
    name: "Arabic",
  },
  {
    code: "hy",
    name: "Armenian",
  },
  {
    code: "az",
    name: "Azerbaijani",
  },
  {
    code: "eu",
    name: "Basque",
  },
  {
    code: "be",
    name: "Belarusian",
  },
  {
    code: "bn",
    name: "Bengali; Bangla",
  },
  {
    code: "bs",
    name: "Bosnian",
  },
  {
    code: "br",
    name: "Breton",
  },
  {
    code: "bg",
    name: "Bulgarian",
  },
  {
    code: "my",
    name: "Burmese",
  },
  {
    code: "ca",
    name: "Catalan; Valencian",
  },
  {
    code: "cy",
    name: "Welsh",
  },
  {
    code: "zh",
    name: "Chinese",
  },
  {
    code: "co",
    name: "Corsican",
  },
  {
    code: "hr",
    name: "Croatian",
  },
  {
    code: "cs",
    name: "Czech",
  },
  {
    code: "da",
    name: "Danish",
  },
  {
    code: "nl",
    name: "Dutch",
  },
  {
    code: "en",
    name: "English",
  },
  {
    code: "eo",
    name: "Esperanto",
  },
  {
    code: "et",
    name: "Estonian",
  },
  {
    code: "fi",
    name: "Finnish",
  },
  {
    code: "fr",
    name: "French",
  },
  {
    code: "ka",
    name: "Georgian",
  },
  {
    code: "de",
    name: "German",
  },
  {
    code: "el",
    name: "Greek",
  },
  {
    code: "ht",
    name: "Haitian",
  },
  {
    code: "he",
    name: "Hebrew",
  },
  {
    code: "hi",
    name: "Hindi",
  },
  {
    code: "hu",
    name: "Hungarian",
  },
  {
    code: "id",
    name: "Indonesian",
  },
  {
    code: "ga",
    name: "Irish",
  },
  {
    code: "is",
    name: "Icelandic",
  },
  {
    code: "it",
    name: "Italian",
  },
  {
    code: "ja",
    name: "Japanese",
  },
  {
    code: "kk",
    name: "Kazakh",
  },
  {
    code: "ko",
    name: "Korean",
  },
  {
    code: "ky",
    name: "Kyrgyz",
  },
  {
    code: "ku",
    name: "Kurdish",
  },
  {
    code: "lb",
    name: "Luxembourgish, Letzeburgesch",
  },
  {
    code: "lt",
    name: "Lithuanian",
  },
  {
    code: "lv",
    name: "Latvian",
  },
  {
    code: "mk",
    name: "Macedonian",
  },
  {
    code: "mt",
    name: "Maltese",
  },
  {
    code: "no",
    name: "Norwegian",
  },
  {
    code: "fa",
    name: "Persian (Farsi)",
  },
  {
    code: "phi",
    name: "Philippines",
  },
  {
    code: "pl",
    name: "Polish",
  },
  {
    code: "pt",
    name: "Portuguese",
  },
  {
    code: "ro",
    name: "Romanian",
  },
  {
    code: "ru",
    name: "Russian",
  },
  {
    code: "sr",
    name: "Serbian",
  },
  {
    code: "sq",
    name: "Albanian",
  },
  {
    code: "gd",
    name: "Scottish",
  },
  {
    code: "sk",
    name: "Slovak",
  },
  {
    code: "sl",
    name: "Slovene",
  },
  {
    code: "es",
    name: "Spanish; Castilian",
  },
  {
    code: "sm",
    name: "Samoan",
  },
  {
    code: "so",
    name: "Somali",
  },
  {
    code: "sv",
    name: "Swedish",
  },
  {
    code: "tg",
    name: "Tajik",
  },
  {
    code: "th",
    name: "Thai",
  },
  {
    code: "tr",
    name: "Turkish",
  },
  {
    code: "tk",
    name: "Turkmen",
  },
  {
    code: "zu",
    name: "Zulu",
  },
  {
    code: "uk",
    name: "Ukrainian",
  },
  {
    code: "uz",
    name: "Uzbek",
  },
  {
    code: "vi",
    name: "Vietnamese",
  },
];
