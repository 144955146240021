import React from "react";
import { Breadcrumb } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import themeColor from "../../lib/style/theme";

import { selectors as CHANNEL_SELECTORS } from "../../ducks/nodeChannels";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";

import { CLOUD_CHANNEL_TYPE } from "../../lib/utils/constants";

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const channels = useSelector(CHANNEL_SELECTORS.getChannels);
  const ownChannelsList = useSelector(CLOUD_CHANNELS_SELECTORS.getOwnChannels);
  const allChannelsList = useSelector(CLOUD_CHANNELS_SELECTORS.getAllChannels);

  const createURLArray = ({ urlArray }) => {
    const isEditMode = urlArray.includes("edit");
    const isNode = urlArray[0] === "node";

    const isChannelId = isNode && isEditMode ? urlArray[5] : urlArray[4];
    const channelName = isChannelId && channels.getIn([`${isChannelId}`, "config", "name"]);

    const cloudChannels = urlArray[1] === CLOUD_CHANNEL_TYPE.all ? allChannelsList : ownChannelsList;
    const isCloudChannelId = urlArray[0] === "virtual-channel" && urlArray[2];
    const channelData =
      isCloudChannelId && cloudChannels && cloudChannels.find((channel) => channel.id === +isCloudChannelId);

    switch (urlArray[0]) {
      case "":
        return {
          path: ["DASHBOARD"],
          url: `/`,
        };
      case "node":
        return {
          path: isChannelId
            ? [
                `${urlArray[0].toUpperCase()} (${decodeURI(urlArray[2])})`,
                `${urlArray[3].toUpperCase()} (${channelName})`,
              ]
            : [`${urlArray[0].toUpperCase()} (${decodeURI(urlArray[2])})`, urlArray[3].replace("-", " ").toUpperCase()],
          url: `/${urlArray[0]}/${urlArray[1]}/${urlArray[2]}/channels`,
        };
      case "hub-channels":
        return {
          path: ["HUB CHANNELS", decodeURI(channelData?.channelName)],
          url: `/qs-hub/hub-channels`,
        };
      default:
        return { path: urlArray.map((url) => url.replace("-", " ").toUpperCase()), url: `/${urlArray.join("/")}` };
    }
  };

  const urlArray = pathname.substring(1).split("/");
  const parsedUrlArray = createURLArray({ urlArray });

  const breadCrumbsItems = parsedUrlArray.path.map((pathElement) => ({
    title: pathElement,
  }));

  return <Breadcrumb items={breadCrumbsItems} separator={<StyledCaretRightOutlined />} />;
};

const StyledCaretRightOutlined = styled(CaretRightOutlined)`
  color: ${themeColor.orange};
`;

export default Breadcrumbs;
