import React, { useCallback, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { DeleteOutlined, ShareAltOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Popconfirm, Button, Row, Col } from "antd";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import store from "../../../store";

import CloudService from "../../../services/cloud";
import { selectors as ACCOUNT_SELECTORS } from "../../../ducks/account";
import { selectors as MQTT_SELECTORS } from "../../../ducks/mqtt";
import { actions as NODE_ACTIONS } from "../../../ducks/node";

import { errorNotification } from "../../../lib/utils/notification";
import globalTranslation from "../../../lib/translations";
import { CLOUD_NODE_STATES, MQTT_STATUS, TRIAL_NODE_TYPE, NODE_TYPES } from "../../../lib/utils/constants";
import themeColor from "../../../lib/style/theme";

const NodesListActionButtons = ({ cwid, cnn, username, userId, owner, fingerprint }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const nodeType = useSelector((state) => MQTT_SELECTORS.getNodeType(state, cwid));
  const cloudNodeState = useSelector((state) => MQTT_SELECTORS.getCloudNodeStatus(state, cwid));
  const nodeOnlineStatus = useSelector((state) => MQTT_SELECTORS.getNodeOnline(state, cwid));
  const cloudNodeInstanceType = useSelector((state) => MQTT_SELECTORS.getCloudNodeInstanceType(state, cwid));
  const cloudNodeLicenses = useSelector(MQTT_SELECTORS.getCloudNodeLicenses);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [nodeOnlineStatus, setLoading]);

  const onConfirmRemove = useCallback(async () => {
    CloudService.removeNodeShareForUser({ cwid, username }, { errorNotification: errorNotification(formatMessage) });
  }, [formatMessage, username, cwid]);

  const handleNodeShare = () => {
    store.dispatch(NODE_ACTIONS.SET_NODE_FINGERPRINT(fingerprint));
    store.dispatch(NODE_ACTIONS.SET_NODE_CNN(cnn));
    navigate(`/node/${cwid}/${cnn}/manage-access`);
  };

  const handleStopCloudNode = () => {
    CloudService.changeCloudNodeStatus(
      { cwid, cloudStatus: CLOUD_NODE_STATES.STOPPED },
      { errorNotification: errorNotification(formatMessage), setLoading }
    );
  };

  const handleStartCloudNode = () => {
    CloudService.changeCloudNodeStatus(
      { cwid, cloudStatus: CLOUD_NODE_STATES.RUNNING },
      { errorNotification: errorNotification(formatMessage), setLoading }
    );
  };

  const isValidTrial = cloudNodeLicenses?.trialDaysLeft > 0;
  const isTrialInstance = cloudNodeInstanceType === TRIAL_NODE_TYPE;
  const isCloudNode = nodeType === NODE_TYPES.CLOUD;

  const showStopCloudNodeButton = isTrialInstance
    ? cloudNodeState === CLOUD_NODE_STATES.RUNNING && isValidTrial
    : cloudNodeState === CLOUD_NODE_STATES.RUNNING;

  const showStartCloudNodeButton = isTrialInstance
    ? cloudNodeState === CLOUD_NODE_STATES.STOPPED && isValidTrial
    : cloudNodeState === CLOUD_NODE_STATES.STOPPED;

  return (
    <StyledRow justify="start">
      <Col span={24}>
        {owner === userId && (
          <>
            <Button icon={<ShareAltOutlined />} type="dashed" onClick={handleNodeShare}>
              <span>
                <FormattedMessage id="NodesListActionButtons.manageAccess" defaultMessage="Manage access" />
              </span>
            </Button>
            {isCloudNode && showStopCloudNodeButton && (
              <Popconfirm
                disabled={loading || nodeOnlineStatus === MQTT_STATUS.DISCONNECTED}
                placement="top"
                title={
                  <FormattedMessage
                    id="NodesListActionButtons.stopInstanceQuestion"
                    defaultMessage="Are you sure, that you want to stop this instance?"
                  />
                }
                onConfirm={handleStopCloudNode}
                okText={formatMessage(globalTranslation.yes)}
                cancelText={formatMessage(globalTranslation.no)}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                <Button
                  type="primary"
                  disabled={loading || nodeOnlineStatus === MQTT_STATUS.DISCONNECTED || !nodeOnlineStatus}
                  loading={loading || nodeOnlineStatus === MQTT_STATUS.DISCONNECTED || !nodeOnlineStatus}
                >
                  <span>
                    <FormattedMessage id="general.stop" defaultMessage="Stop" />
                  </span>
                </Button>
              </Popconfirm>
            )}
            {isCloudNode && showStartCloudNodeButton && (
              <Popconfirm
                disabled={loading || !nodeOnlineStatus}
                placement="top"
                title={
                  <FormattedMessage
                    id="NodesListActionButtons.startInstanceQuestion"
                    defaultMessage="Are you sure, that you want to start this instance?"
                  />
                }
                onConfirm={handleStartCloudNode}
                okText={formatMessage(globalTranslation.yes)}
                cancelText={formatMessage(globalTranslation.no)}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                <SuccessButton disabled={loading || !nodeOnlineStatus} loading={loading || !nodeOnlineStatus}>
                  <span>
                    <FormattedMessage id="general.start" defaultMessage="Start" />
                  </span>
                </SuccessButton>
              </Popconfirm>
            )}
          </>
        )}
        <Popconfirm
          placement="top"
          title={
            <FormattedMessage
              id="NodesListActionButtons.deleteAccessQuestion"
              // eslint-disable-next-line max-len
              defaultMessage="Are you sure, that you want to remove access to this node? You will no longer be able to manage this node from cloud."
            />
          }
          onConfirm={onConfirmRemove}
          okText={formatMessage(globalTranslation.yes)}
          cancelText={formatMessage(globalTranslation.no)}
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        >
          <Button icon={<DeleteOutlined />} type="primary" danger onClick={() => null}>
            <span>
              <FormattedMessage id="global.remove" defaultMessage="Remove" />
            </span>
          </Button>
        </Popconfirm>
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  button {
    margin-right: 10px;
  }
`;

const SuccessButton = styled(Button)`
  background-color: ${themeColor.green} !important;
  border-color: ${themeColor.green} !important;
  color: white !important;

  &:hover {
    background-color: ${themeColor.greenHover} !important;
    border-color: ${themeColor.greenHover} !important;
    color: white !important;
  }
`;

NodesListActionButtons.propTypes = {
  cnn: PropTypes.string.isRequired,
  cwid: PropTypes.string.isRequired,
  fingerprint: PropTypes.string.isRequired,
  owner: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  username: ACCOUNT_SELECTORS.getUser(state),
  userId: ACCOUNT_SELECTORS.getUserId(state),
});

export default connect(mapStateToProps, null)(NodesListActionButtons);
