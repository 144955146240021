import React, { useState } from "react";
import styled from "styled-components";
import { Modal, Button, Row, Col, Empty, Spin } from "antd";
import { PlusOutlined, WarningOutlined, SaveOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { OUTPUTS_TYPE, NEW } from "../../../lib/utils/constants";
import DEFAULT_MUXED_OUTPUT from "../../../lib/utils/defaultValues/defaultMuxedOutput";

import MuxedSectionFormItem from "./MuxedSectionFormItem";
import MuxedOutputElement from "./MuxedOutputElement";
import MuxedOutputElementLabel from "./MuxedOutputElementLabel";

const MuxedSection = ({ detailView, handleFinish, id, muxedOutputs, form, isInput }) => {
  const { getFieldValue, setFieldsValue, resetFields, validateFields } = form;

  const [outputSectionVisible, setOutputSectionVisible] = useState(null);

  const numberOfMuxedOutputs = muxedOutputs ? muxedOutputs.length : 0;

  const handleAddNewMuxedOutput = () => {
    resetFields();
    const formMuxedOutputs = getFieldValue("muxedOutputs") || [];

    const numberOfOutputs = formMuxedOutputs.length + 1;
    const updatedMuxedOutputs = {
      muxedOutputs: [...formMuxedOutputs, { ...DEFAULT_MUXED_OUTPUT, name: `Muxed-output-${numberOfOutputs}` }],
    };

    setFieldsValue(updatedMuxedOutputs);
    setOutputSectionVisible(NEW);
  };

  const handleOKMuxedOutput = async () => {
    try {
      await validateFields();

      const updatedFormData = getFieldValue();

      handleFinish(updatedFormData);
      setOutputSectionVisible(null);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error - formValidation", error);
    }
  };

  const handleCancelMuxedOutput = () => {
    resetFields([OUTPUTS_TYPE.muxedOutputs]);
    setOutputSectionVisible(null);
  };

  const handleRemoveMuxedOutput = (name) => {
    const channelConfig = getFieldValue();
    const parsedFormMuxedOutputs = muxedOutputs.filter((muxedOutput) => muxedOutput.name !== name);
    const updatedFormData = { ...channelConfig, muxedOutputs: parsedFormMuxedOutputs };

    handleFinish(updatedFormData);
    resetFields();
    setFieldsValue({ muxedOutputs: parsedFormMuxedOutputs });
    setOutputSectionVisible(null);
  };

  const isMuxedOutputs = muxedOutputs && muxedOutputs.length > 0;

  if (!isMuxedOutputs && detailView) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Spin
      spinning={!isInput}
      indicator={<WarningOutlined />}
      tip={
        <FormattedMessage
          id="MuxedSection.selectInputType"
          defaultMessage="Please select input type before defining muxed outputs"
        />
      }
    >
      {muxedOutputs &&
        muxedOutputs.map((muxedOutput, index) => {
          const handleClick = () => {
            setOutputSectionVisible(index);
          };

          return (
            <Row gutter={24} key={muxedOutput.name}>
              <Col span={24}>
                <MuxedOutputElement
                  label={<MuxedOutputElementLabel type={muxedOutput?.type} />}
                  muxedOutput={muxedOutput}
                  handleClick={handleClick}
                  type={muxedOutput?.type}
                  incomplete={muxedOutput.incomplete}
                />
                <StyledModal
                  zIndex={100}
                  centered
                  title={
                    <>
                      <span>
                        <FormattedMessage id="MuxedSection.muxedOutputs" defaultMessage="Muxed Output" />
                      </span>
                      {` ${muxedOutput.name}`}
                    </>
                  }
                  open={outputSectionVisible === index}
                  onOk={handleOKMuxedOutput}
                  onCancel={handleCancelMuxedOutput}
                  footer={detailView ? null : undefined}
                >
                  <MuxedSectionFormItem
                    detailView={detailView}
                    getFieldValue={getFieldValue}
                    handleFinish={handleFinish}
                    handleRemove={handleRemoveMuxedOutput}
                    id={id}
                    prefix={[OUTPUTS_TYPE.muxedOutputs, index]}
                    setFieldsValue={setFieldsValue}
                    muxedOutput={muxedOutput}
                    resetFields={resetFields}
                    validateFields={validateFields}
                    isNewMuxedOutput={outputSectionVisible === NEW && setOutputSectionVisible}
                    form={form}
                  />
                </StyledModal>
              </Col>
            </Row>
          );
        })}
      {!detailView && (
        <StyledAddButton type="dashed" icon={<PlusOutlined />} onClick={handleAddNewMuxedOutput}>
          <span>
            <FormattedMessage id="MuxedSection.addMuxedOutput" defaultMessage="Add Muxed Output" />
          </span>
        </StyledAddButton>
      )}
      {outputSectionVisible === NEW && (
        <StyledModal
          zIndex={100}
          centered
          title={<FormattedMessage id="MuxedSection.newMuxedOutputs" defaultMessage="New Muxed Output" />}
          open={outputSectionVisible === NEW}
          onOk={handleOKMuxedOutput}
          onCancel={handleCancelMuxedOutput}
          okText={
            <span>
              <FormattedMessage id="general.save" defaultMessage="Save" />
            </span>
          }
          okButtonProps={{ icon: <SaveOutlined /> }}
        >
          <MuxedSectionFormItem
            getFieldValue={getFieldValue}
            handleFinish={handleFinish}
            handleRemove={handleRemoveMuxedOutput}
            id={id}
            prefix={[OUTPUTS_TYPE.muxedOutputs, numberOfMuxedOutputs]}
            setFieldsValue={setFieldsValue}
            resetFields={resetFields}
            validateFields={validateFields}
            footer={detailView ? null : undefined}
            isNewMuxedOutput={outputSectionVisible === NEW && setOutputSectionVisible}
            form={form}
          />
        </StyledModal>
      )}
    </Spin>
  );
};

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledAddButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

MuxedSection.propTypes = {
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  id: PropTypes.string,
  muxedOutputs: PropTypes.arrayOf(PropTypes.object),
  isInput: PropTypes.bool.isRequired,
};

MuxedSection.defaultProps = {
  detailView: null,
  id: null,
  muxedOutputs: null,
};

export default MuxedSection;
