import React, { useState, useContext } from "react";
import { Card, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import MonitoringSectionFormItem from "./MonitoringSectionFormItem";

import SectionSwitch from "../../../Fields/SectionSwitch";

import { FormV5Context } from "../../NodeChannelFormV5";

import useNodeChannelConfig from "../../../../hooks/useNodeChannelConfig";

import EnginePlayoutElement from "../../../EnginePlayoutElement";

export default function MonitoringSection({ disabled, handleFinish }) {
  const [open, setOpen] = useState(false);
  const form = useContext(FormV5Context);

  const { channelConfig, monitoring } = useNodeChannelConfig();

  const { resetFields, getFieldValue, validateFields } = form;

  const handleSaveMonitoring = async () => {
    await validateFields();
    const formData = getFieldValue("monitoring");

    handleFinish({ ...channelConfig, monitoring: formData });
    setOpen(false);
  };

  return (
    <Card
      title={
        <>
          <FormattedMessage id="MonitoringSection.monitoring" defaultMessage="Monitoring" />{" "}
          <SectionSwitch
            prefix={["monitoring", "enabled"]}
            disabled={disabled}
            handleFinish={handleFinish}
            handleOpen={() => setOpen(true)}
          />
        </>
      }
    >
      {monitoring?.enabled && (
        <EnginePlayoutElement
          label={<FormattedMessage id="MonitoringSection.monitoring" defaultMessage="Monitoring" />}
          handleClick={() => setOpen(true)}
          data={monitoring}
        />
      )}
      <Modal
        title={<FormattedMessage id="MonitoringSection.monitoring" defaultMessage="Monitoring" />}
        centered
        destroyOnClose
        open={open}
        onCancel={() => {
          resetFields();
          setOpen(false);
        }}
        onOk={handleSaveMonitoring}
        width={1280}
        footer={disabled ? null : undefined}
        okText={
          <span>
            <FormattedMessage id="general.save" defaultMessage="Save" />
          </span>
        }
        okButtonProps={{ icon: <SaveOutlined /> }}
      >
        <MonitoringSectionFormItem prefix={["monitoring"]} disabled={disabled} />
      </Modal>
    </Card>
  );
}

MonitoringSection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleFinish: PropTypes.func.isRequired,
};
