export default {
  caspar: {
    mode: "caspar",
    stopTime: 200,
    addClearToAllItems: false,
    casparIp: "127.0.0.1",
    casparChannel: 1,
    casparPort: 5250,
  },
  simpleLogo: {
    mode: "simpleLogo",
    stopTime: 200,
    addClearToAllItems: false,
  },
  off: {
    mode: "off",
  },
};
