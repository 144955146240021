import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

const BusinessDescription = () => {
  return (
    <>
      <StyledLabel>
        <FormattedMessage id="BusinessDescription.business" defaultMessage="Business" />
      </StyledLabel>
      <Tooltip
        title={
          <FormattedMessage
            id="BusinessDescription.description"
            // eslint-disable-next-line max-len
            defaultMessage={`If you are business responsible for channel - the following
            details will be visible to your business partners. Exchanging business contact details help to
            better manage your services.`}
          />
        }
      >
        <QuestionCircleFilled />
      </Tooltip>
    </>
  );
};

const StyledLabel = styled.span`
  margin-right: 5px;
`;

export default BusinessDescription;
