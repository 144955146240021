import React from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { Form, Row, Col, Divider, Button } from "antd";
import PropTypes from "prop-types";

import { FORM_VERSION_V5, LICENSES_TYPES, ENGINE_TYPES } from "../../lib/utils/constants";

import ChannelName from "../NodeChannelFormV5/GeneralSection/ChannelName";
import InputTextField from "../Fields/InputTextField";
import NewNodeChannelType from "../NewNodeChannelType";

const translations = defineMessages({
  channelName: {
    id: "BasicModeQSGeneralSettings.channelName",
    defaultMessage: "Channel name",
  },
});

export default function BasicModeQSGeneralSettings({
  handlePrevStep,
  handleNextStep,
  channelName,
  setGeneralSettings,
  generalSettings,
}) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const handleNextStepForm = async (formData) => {
    setGeneralSettings(formData);
    handleNextStep();
  };

  const initialFormValues = {
    name: channelName,
    licenseId: LICENSES_TYPES.CLOUD,
    version: FORM_VERSION_V5,
    type: ENGINE_TYPES.DIRECT,
    ...generalSettings,
  };

  return (
    <Form onFinish={handleNextStepForm} form={form} initialValues={initialFormValues}>
      <InputTextField name={["licenseId"]} hidden />
      <Row gutter={24} justify="center">
        <Col span={12}>
          <ChannelName name="name" label={formatMessage(translations.channelName)} />
        </Col>
        <NewNodeChannelType hidden />
        <InputTextField name="version" hidden />
      </Row>
      <Divider />
      <Row justify="end">
        <Button style={{ margin: "0 8px" }} onClick={handlePrevStep}>
          <FormattedMessage id="general.previous" defaultMessage="Previous" />
        </Button>
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="general.next" defaultMessage="Next" />
        </Button>
      </Row>
    </Form>
  );
}

BasicModeQSGeneralSettings.propTypes = {
  channelName: PropTypes.string.isRequired,
  handlePrevStep: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  generalSettings: PropTypes.object.isRequired,
};
