import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, Divider } from "antd";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";

import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

import EditPasswordForm from "../../components/EditPasswordForm";
// import AccountEditForm from "../../components/AccountEditForm";
import DeleteAccount from "../../components/DeleteAccount";
import GlobalErrorBoundaries from "../../components/ErrorBoundaries/GlobalErrorBoundaries";
import Auth2fa from "./Auth2fa";
import EmailOptIn from "./EmailOptIn";

const translations = defineMessages({
  profileData: {
    id: "account.profileData",
    defaultMessage: "Profile data",
  },
  editPassword: {
    id: "account.editPassword",
    defaultMessage: "Edit password",
  },
  deleteAccount: {
    id: "account.deleteAccount",
    defaultMessage: "Delete account",
  },
});

const Account = () => {
  const { formatMessage } = useIntl();
  const username = useSelector(ACCOUNT_SELECTORS.getUser);
  const email = useSelector(ACCOUNT_SELECTORS.getUserEmail);

  return (
    <GlobalErrorBoundaries>
      <Row gutter={16}>
        <StyledCol md={24} lg={12}>
          <Card title={formatMessage(translations.profileData)} bordered>
            <Row>
              <Col span={24}>
                <StyledBold>
                  <FormattedMessage id="account.username" defaultMessage="Username" />:
                </StyledBold>{" "}
                {username}
              </Col>
              <Divider />
              <Col span={24}>
                <StyledBold>
                  <FormattedMessage id="account.emial" defaultMessage="Email" />:
                </StyledBold>{" "}
                {email}
              </Col>
              <Divider />
              <EmailOptIn />
              <Divider />
              <Auth2fa />
            </Row>
          </Card>
        </StyledCol>
        <StyledCol md={24} lg={12}>
          <Card title={formatMessage(translations.editPassword)} bordered>
            <EditPasswordForm />
          </Card>
        </StyledCol>
        <StyledCol md={24} lg={12}>
          <Card title={formatMessage(translations.deleteAccount)} bordered>
            <DeleteAccount />
          </Card>
        </StyledCol>
      </Row>
    </GlobalErrorBoundaries>
  );
};

const StyledBold = styled.b`
  min-width: 100px;
  display: inline-block;
`;

const StyledCol = styled(Col)`
  margin: 0px 0px 10px 0px;
`;

export default Account;
