import React from "react";
import { Col, Form, Select } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { required } from "../../../../../../../lib/utils/formValidation";
import { MPEG2PROFILE } from "../../../../../../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

function Mpeg2VideoCodec({ disabled, fieldPrefix }) {
  return (
    <Col span={8}>
      <Item
        name={[...fieldPrefix, "mpeg2Profile"]}
        label={<FormattedMessage id="GeneralVideoCodec.mpeg2Profile" defaultMessage="Encoding profile" />}
        rules={[required]}
      >
        <Select disabled={disabled}>
          {MPEG2PROFILE.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Item>
    </Col>
  );
}

Mpeg2VideoCodec.propTypes = {
  disabled: PropTypes.bool,
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

Mpeg2VideoCodec.defaultProps = {
  disabled: null,
};

export default Mpeg2VideoCodec;
