import { MPEG2VIDEO_PRESETS } from "../constants";

const NDIVideoDefaultValues = (presetId) => {
  switch (presetId) {
    case 1:
      return {
        width: MPEG2VIDEO_PRESETS.preset4K.width,
        height: MPEG2VIDEO_PRESETS.preset4K.height,
        customSar: MPEG2VIDEO_PRESETS.preset4K.customSar,
        presetId,
      };
    case 2:
      return {
        width: MPEG2VIDEO_PRESETS.presetFullHD.width,
        height: MPEG2VIDEO_PRESETS.presetFullHD.height,
        customSar: MPEG2VIDEO_PRESETS.presetFullHD.customSar,
        presetId,
      };
    case 3:
      return {
        width: MPEG2VIDEO_PRESETS.presetHD.width,
        height: MPEG2VIDEO_PRESETS.presetHD.height,
        customSar: MPEG2VIDEO_PRESETS.presetHD.customSar,
        presetId,
      };
    case 4:
      return {
        width: MPEG2VIDEO_PRESETS.presetPALSD169.width,
        height: MPEG2VIDEO_PRESETS.presetPALSD169.height,
        customSar: MPEG2VIDEO_PRESETS.presetPALSD169.customSar,
        sarNum: MPEG2VIDEO_PRESETS.presetPALSD169.sarNum,
        sarDen: MPEG2VIDEO_PRESETS.presetPALSD169.sarDen,
        presetId,
      };
    case 5:
      return {
        width: MPEG2VIDEO_PRESETS.presetPALSD43.width,
        height: MPEG2VIDEO_PRESETS.presetPALSD43.height,
        customSar: MPEG2VIDEO_PRESETS.presetPALSD43.customSar,
        sarNum: MPEG2VIDEO_PRESETS.presetPALSD43.sarNum,
        sarDen: MPEG2VIDEO_PRESETS.presetPALSD43.sarDen,
        presetId,
      };
    case 6:
      return {
        width: MPEG2VIDEO_PRESETS.presetNTSCSD169.width,
        height: MPEG2VIDEO_PRESETS.presetNTSCSD169.height,
        customSar: MPEG2VIDEO_PRESETS.presetNTSCSD169.customSar,
        sarNum: MPEG2VIDEO_PRESETS.presetNTSCSD169.sarNum,
        sarDen: MPEG2VIDEO_PRESETS.presetNTSCSD169.sarDen,
        presetId,
      };
    case 7:
      return {
        width: MPEG2VIDEO_PRESETS.presetNTSCSD43.width,
        height: MPEG2VIDEO_PRESETS.presetNTSCSD43.height,
        customSar: MPEG2VIDEO_PRESETS.presetNTSCSD43.customSar,
        sarNum: MPEG2VIDEO_PRESETS.presetNTSCSD43.sarNum,
        sarDen: MPEG2VIDEO_PRESETS.presetNTSCSD43.sarDen,
        presetId,
      };
    default:
      return { presetId };
  }
};

export default NDIVideoDefaultValues;
