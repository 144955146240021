import React from "react";
import styled from "styled-components";
import { Col, Divider } from "antd";
import PropTypes from "prop-types";

import SelectStream from "./SelectStream";
import StreamCodec from "./StreamCodec";

import { INPUT_URL_TYPE } from "../../../lib/utils/constants";

const StreamsSectionFormItem = ({ detailView, inputType, fieldPrefix, form, prefix }) => {
  const isInputURLType = inputType === INPUT_URL_TYPE.value;

  return (
    <>
      {isInputURLType ? (
        <>
          <Divider />
          <SelectStream
            disabled={detailView}
            fieldPrefix={[...fieldPrefix, "selectStream"]}
            form={form}
            prefix={[...prefix, "selectStream"]}
          />
        </>
      ) : (
        <StyledHiddenCol>
          <SelectStream
            disabled={detailView}
            fieldPrefix={[...fieldPrefix, "selectStream"]}
            form={form}
            prefix={[...prefix, "selectStream"]}
          />
        </StyledHiddenCol>
      )}
      <Divider />

      <StreamCodec
        disabled={!!detailView}
        fieldPrefix={[...fieldPrefix, "codec"]}
        prefix={[...prefix, "codec"]}
        prePrefix={prefix}
        inputType={inputType}
        form={form}
      />
    </>
  );
};

const StyledHiddenCol = styled(Col)`
  visibility: hidden;
  display: none;
`;

StreamsSectionFormItem.propTypes = {
  detailView: PropTypes.bool,
  inputType: PropTypes.string,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  form: PropTypes.object.isRequired,
};

StreamsSectionFormItem.defaultProps = {
  detailView: null,
  inputType: null,
  prefix: null,
  fieldPrefix: null,
};

export default StreamsSectionFormItem;
