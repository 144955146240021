import React, { useState, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Row, Col, Form, Switch } from "antd";

import InputStreamUdpSection from "./InputStreamUdpSection";
import InputStreamSrtSection from "./InputStreamSrtSection";
import InputStreamQSSection from "../../../InputStreamQSSection";
import InputStreamSectionBackup from "../InputStreamSectionBackup";
import InputNumberField from "../../../Fields/InputNumberField";
import InputTextField from "../../../Fields/InputTextField";

import { CONNECTION_METHOD_INPUT, QUICKSTREAM_METHOD_V2 } from "../../../../lib/utils/constants";
import InputConnectionMethod from "../../../Fields/InputConnectionMethod";
import CloudChannelListV5 from "../../../Fields/CloudChannelListV5";

import globalTranslations from "../../../../lib/translations";
import inputURLDefaultValues, {
  DEFAULT_INPUT_BACKUP_VALUES,
  DEFAULT_INPUT_BACKUP_TRIGGERS,
} from "../../../../lib/utils/defaultValues/inputURLDefaultValues";

import {
  MAIN_CONNECTION_TYPE,
  INPUT_BACKUP_TRIGGERS_TYPE,
  INPUT_BACKUP_TYPE,
} from "../../../../lib/utils/types/inputURLTypes";

import { FormV5Context } from "../../NodeChannelFormV5";
import AdvancedSettings from "../../../AdvancedSettings";

const { Item } = Form;

const translations = defineMessages({
  cloudIdLabel: {
    id: "InputStreamURL.cloudIdLabel",
    defaultMessage: "Cloud ID",
  },
  backupInput: {
    id: "InputStreamURL.backupInput",
    defaultMessage: "Backup Input",
  },
});

const SRT_METHODS = [CONNECTION_METHOD_INPUT.inSrtListener.value, CONNECTION_METHOD_INPUT.inSrtCaller.value];
const UDP_METHODS = [CONNECTION_METHOD_INPUT.inUdpUnicast.value, CONNECTION_METHOD_INPUT.inUdpMulticast.value];

function InputStreamURL({ disabled, initialValue, stundAddress, sharedChannelList, form, basicMode }) {
  const parsedFrom = form || useContext(FormV5Context);
  const { getFieldValue, setFieldsValue } = parsedFrom;

  const [connectionMethod, setConnectionMethod] = useState(
    initialValue?.mainConnection?.type || CONNECTION_METHOD_INPUT.inUdpMulticast.value
  );
  const [switchInputBackupState, setSwitchInputBackupState] = useState(initialValue?.switchInputBackup);

  const isUDPMethod = UDP_METHODS.includes(connectionMethod);
  const isSRTMethod = SRT_METHODS.includes(connectionMethod);
  const isQuickstreamMethod = connectionMethod === QUICKSTREAM_METHOD_V2.inQSDirect.value;

  const { formatMessage } = useIntl();

  const handleChangeBackup = (value) => {
    setSwitchInputBackupState(value);
    if (value) {
      const inputFormData = getFieldValue(["input"]);
      const input = inputURLDefaultValues({
        input: inputFormData,
        stundAddress,
        sharedChannelList,
      });
      setFieldsValue({
        input: {
          ...input,
          switchInputBackup: value,
          backupConnection: DEFAULT_INPUT_BACKUP_VALUES,
          backupTriggers: DEFAULT_INPUT_BACKUP_TRIGGERS,
        },
      });
    } else {
      const inputFormData = getFieldValue(["input"]);
      delete inputFormData.backupConnection;
      delete inputFormData.backupTriggers;
      setFieldsValue({ input: inputFormData });
    }
  };

  return (
    <>
      <Row type="flex" justify="start" gutter={24}>
        <InputNumberField name={["input", "mainConnection", "inputId"]} disabled={disabled} hidden />
        <InputTextField name={["input", "mainConnection", "inputName"]} disabled={disabled} hidden />
        <Col span={24}>
          <InputConnectionMethod
            name={["input", "mainConnection", "type"]}
            disabled={disabled}
            setConnectionMethod={setConnectionMethod}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
          />
        </Col>

        {isQuickstreamMethod && (
          <>
            <Col span={12}>
              <CloudChannelListV5
                channelList={sharedChannelList}
                disabled={disabled}
                getFieldValue={getFieldValue}
                initialValue={
                  initialValue?.mainConnection?.cloudId && initialValue?.mainConnection?.cloudName
                    ? {
                        cloudId: initialValue?.mainConnection?.cloudId,
                        channelName: initialValue?.mainConnection?.cloudName,
                        permissionId: initialValue?.mainConnection?.permissionId,
                        description: initialValue?.mainConnection?.description,
                      }
                    : null
                }
                name={["input", "mainConnection"]}
                label={formatMessage(translations.cloudIdLabel)}
                sameAsValidation="output"
                setFieldsValue={setFieldsValue}
                isInput
              />
            </Col>
            <InputTextField name={["input", "mainConnection", "cloudName"]} disabled={disabled} hidden />
            <InputTextField name={["input", "mainConnection", "permissionId"]} disabled={disabled} hidden />
            <InputTextField name={["input", "mainConnection", "cloudId"]} disabled={disabled} hidden />
            <InputTextField name={["input", "mainConnection", "description"]} disabled={disabled} hidden />
          </>
        )}
        {isUDPMethod && (
          <InputStreamUdpSection
            connectionMethod={connectionMethod}
            disabled={disabled}
            prefix={["input", "mainConnection"]}
            basicMode={basicMode}
          />
        )}
        {isSRTMethod && (
          <InputStreamSrtSection
            connectionMethod={connectionMethod}
            disabled={disabled}
            initialValue={initialValue}
            prefix={["input", "mainConnection"]}
            form={parsedFrom}
            basicMode={basicMode}
          />
        )}
        {isQuickstreamMethod && (
          <Col span={24} hidden={basicMode}>
            <AdvancedSettings>
              <InputStreamQSSection
                disabled={disabled}
                getFieldValue={getFieldValue}
                isInput
                name={["input", "mainConnection"]}
                prefix={["input", "mainConnection"]}
                setFieldsValue={setFieldsValue}
                form={form}
              />
            </AdvancedSettings>
          </Col>
        )}
      </Row>
      {!isQuickstreamMethod && !basicMode && (
        <>
          <Item
            name={["input", "switchInputBackup"]}
            valuePropName="checked"
            label={formatMessage(translations.backupInput)}
          >
            <Switch
              disabled={disabled}
              defaultChecked={switchInputBackupState}
              checkedChildren={formatMessage(globalTranslations.enabled)}
              unCheckedChildren={formatMessage(globalTranslations.disabled)}
              onChange={handleChangeBackup}
            />
          </Item>
          {switchInputBackupState && <InputStreamSectionBackup disabled={disabled} initialValue={initialValue} />}
        </>
      )}
    </>
  );
}

InputStreamURL.propTypes = {
  disabled: PropTypes.bool,

  initialValue: PropTypes.shape({
    ...MAIN_CONNECTION_TYPE,
    ...INPUT_BACKUP_TYPE,
    ...INPUT_BACKUP_TRIGGERS_TYPE,
  }),
  stundAddress: PropTypes.string,
  sharedChannelList: PropTypes.array,
  form: PropTypes.object,
  basicMode: PropTypes.bool,
};

InputStreamURL.defaultProps = {
  disabled: null,
  initialValue: null,
  stundAddress: null,
  sharedChannelList: [],
  form: null,
  basicMode: false,
};

export default InputStreamURL;
