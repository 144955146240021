import PropTypes from "prop-types";

export const CAPABILITIES_TYPE = {
  audioCodecs: PropTypes.arrayOf(PropTypes.string),
  audioFilters: PropTypes.arrayOf(PropTypes.string),
  audioStreamTypes: PropTypes.arrayOf(PropTypes.string),
  inputTypes: PropTypes.arrayOf(PropTypes.string),
  otherStreamTypes: PropTypes.arrayOf(PropTypes.string),
  videoCodecs: PropTypes.arrayOf(PropTypes.string),
  videoFilters: PropTypes.arrayOf(PropTypes.string),
  videoStreamTypes: PropTypes.arrayOf(PropTypes.string),
};

export default CAPABILITIES_TYPE;
