import React from "react";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { ReloadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import PropTypes from "prop-types";

import { SEO_STATUS } from "../../lib/utils/constants";
import themeColors from "../../lib/style/theme";

const OrganizationValidation = ({ organizationData }) => {
  const { public: isPublic, status } = organizationData;

  if (status === SEO_STATUS.VERIFICATION) {
    return (
      <Tooltip
        title={
          <FormattedMessage
            id="general.catalogValidation"
            defaultMessage={`Please note that Quickstream verifies all data that are visible
              in the public catalog therefore it may take a while to publish all changes in the database.`}
          />
        }
        color={themeColors.gray}
      >
        <StyledReloadOutlined spin />
        <StyledSpan>
          <FormattedMessage id="organizationValidation.inValidation" defaultMessage="In validation process" />
        </StyledSpan>
      </Tooltip>
    );
  }

  if (!isPublic) {
    return (
      <span>
        <FormattedMessage id="organizationValidation.private" defaultMessage="Private" />
      </span>
    );
  }

  if (status === SEO_STATUS.REJECTED) {
    return (
      <span>
        <FormattedMessage id="organizationValidation.rejected" defaultMessage="Rejected" />
      </span>
    );
  }

  if (status === SEO_STATUS.ACCEPTED) {
    return (
      <span>
        <FormattedMessage id="organizationValidation.public" defaultMessage="Public" />
      </span>
    );
  }

  return (
    <>
      <Tooltip
        title={
          <FormattedMessage
            id="general.catalogValidation"
            defaultMessage={`Please note that Quickstream verifies all data that are visible
                  in the public catalog therefore it may take a while to publish all changes in the database.`}
          />
        }
        color={themeColors.gray}
      >
        <StyledReloadOutlined spin />
        <StyledSpan>
          <FormattedMessage id="organizationValidation.inValidation" defaultMessage="In validation process" />
        </StyledSpan>
      </Tooltip>
    </>
  );
};

const StyledReloadOutlined = styled(ReloadOutlined)`
  font-size: 12px;
`;

const StyledSpan = styled.span`
  margin-left: 5px;
`;

OrganizationValidation.propTypes = {
  organizationData: PropTypes.shape({
    address: PropTypes.string,
    country: PropTypes.string,
    creator: PropTypes.number,
    id: PropTypes.number,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    profiles: PropTypes.arrayOf(PropTypes.string),
    public: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    taxId: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default OrganizationValidation;
