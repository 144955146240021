import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import styled from "styled-components";

import InputWebcamMic from "./InputWebcamMic";
import InputWebcamVideo from "./InputWebcamVideo";
import InputNumberField from "../../../../Fields/InputNumberField";
import InputTextField from "../../../../Fields/InputTextField";

function InputWebcam({ audioInputs, disabled, getFieldValue, setFieldsValue, videoInputs, validateFields, prefix }) {
  const isWebcamAudioInputs = audioInputs.length > 0;
  const isWebcamVideoInputs = videoInputs.length > 0;

  return (
    <Row gutter={24}>
      <InputNumberField name={[...prefix, "inputId"]} disabled={disabled} hidden />
      <InputTextField name={[...prefix, "inputName"]} disabled={disabled} hidden />
      {isWebcamVideoInputs && (
        <Col span={24}>
          <InputWebcamVideo
            videoInputs={videoInputs}
            disabled={disabled}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            validateFields={validateFields}
            prefix={prefix}
          />
        </Col>
      )}
      {isWebcamAudioInputs && (
        <StyledCol span={24}>
          <InputWebcamMic
            audioInputs={audioInputs}
            disabled={disabled}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            prefix={prefix}
          />
        </StyledCol>
      )}
    </Row>
  );
}

const StyledCol = styled(Col)`
  margin-top: 15px;
`;

InputWebcam.propTypes = {
  disabled: PropTypes.bool,
  getFieldValue: PropTypes.func.isRequired,
  audioInputs: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.string,
    })
  ).isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  videoInputs: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.string,
    })
  ).isRequired,
  validateFields: PropTypes.func.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

InputWebcam.defaultProps = {
  disabled: null,
};

export default InputWebcam;
