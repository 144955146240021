import React from "react";
import { Form, Input, Col, Card, Row } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// import Muxer from "../NodeChannelFormV4/MuxedSection/MuxedSectionFormItem/Muxer";
import InputTextField from "../Fields/InputTextField";
import InputNumberField from "../Fields/InputNumberField";

import globalTranslations from "../../lib/translations";
import { max32characters, az09space, required } from "../../lib/utils/formValidation";
import { selectors as CHANNEL_SELECTORS } from "../../ducks/nodeChannels";

// eslint-disable-next-line max-len
import AudioStreams from "../NodeChannelFormV5/EngineTranscoding/EngineTranscodingSection/EngineTranscodingMuxer/AudioStreams";
// eslint-disable-next-line max-len
import VideoStream from "../NodeChannelFormV5/EngineTranscoding/EngineTranscodingSection/EngineTranscodingMuxer/VideoStream";

const { Item } = Form;

const translations = defineMessages({
  formats: {
    id: "OutputTranscodingRTMPFormItem.formats",
    defaultMessage: "rtmp:// or rtmpt:// or rtmpte:// or rtmps:// or rtmpte:// or rtmpts://",
  },
  wrongFormat: {
    id: "OutputTranscodingRTMPFormItem.wrongFormat",
    defaultMessage: "Supported formats: rtmp:// or rtmpt:// or rtmpte:// or rtmps:// or rtmpte:// or rtmpts://",
  },
  streamNameOrKey: {
    id: "InputRTMP.streamNameOrKey",
    defaultMessage: "Stream name or Key",
  },
});

function OutputTranscodingRTMPFormItem({ disabled, prefix, channelId, muxedOutput }) {
  const { formatMessage } = useIntl();
  const nodeChannels = useSelector(CHANNEL_SELECTORS.getChannels);

  const uniqueValidator = (rule, value) => {
    const outputNames = nodeChannels.getIn([`${channelId}`, "outputs", "outputsNames"]);
    const parsedOutputNames = outputNames && outputNames.filter((name) => name !== muxedOutput?.name);

    const duplicatedMuxedOutputName = parsedOutputNames && parsedOutputNames.includes(value);

    if (duplicatedMuxedOutputName) {
      return Promise.reject(formatMessage(translations.nameAlreadyExist));
    }

    return Promise.resolve();
  };

  return (
    <>
      <InputNumberField name={[...prefix, "outputId"]} disabled={disabled} hidden />
      <Col span={12}>
        <InputTextField
          disabled={disabled}
          name={[...prefix, "name"]}
          label={formatMessage(globalTranslations.name)}
          rules={[{ validator: uniqueValidator }, max32characters, az09space, required]}
        />
      </Col>
      <Col span={24}>
        <Item
          name={[...prefix, "url"]}
          label={<FormattedMessage id="OutputTranscodingRTMPFormItem.url" defaultMessage="Url" />}
          rules={[
            required,
            {
              pattern: new RegExp("^(rtmp://|rtmpt://|rtmpte://|rtmps://|rtmpte://|rtmpts://)"),
              message: formatMessage(translations.wrongFormat),
            },
          ]}
        >
          <Input placeholder={formatMessage(translations.formats)} disabled={disabled} />
        </Item>
      </Col>
      <Col span={24}>
        <InputTextField
          name={[...prefix, "key"]}
          disabled={disabled}
          label={formatMessage(translations.streamNameOrKey)}
          rules={[required]}
        />
      </Col>
      <Col span={24}>
        <Card title={<FormattedMessage id="OutputTranscodingRTMPFormItem.video" defaultMessage="Video" />}>
          <Row gutter={24}>
            <VideoStream disabled={disabled} prefix={prefix} />
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card title={<FormattedMessage id="OutputTranscodingRTMPFormItem.audio" defaultMessage="Audio" />}>
          <Row gutter={24}>
            <AudioStreams disabled={disabled} prefix={prefix} />
          </Row>
        </Card>
      </Col>
    </>
  );
}

OutputTranscodingRTMPFormItem.propTypes = {
  channelId: PropTypes.string,
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  muxedOutput: PropTypes.object,
};

OutputTranscodingRTMPFormItem.defaultProps = {
  disabled: false,
  channelId: null,
  muxedOutput: null,
};
export default OutputTranscodingRTMPFormItem;
