import React from "react";
import { Row, Col, Form, Switch, Select, Alert } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import InputNumberField from "../../../Fields/InputNumberField";

import { required } from "../../../../lib/utils/formValidation";
import globalTranslations from "../../../../lib/translations";

const { Item } = Form;

export default function SCTESectionFormItem({ prefix, disabled }) {
  const { formatMessage } = useIntl();

  return (
    <Row gutter={[24, 12]}>
      <Col span={24}>
        <Alert
          message={<FormattedMessage id="SubtitlesSectionFormItem.scteInfoTitle" defaultMessage="Using SCTE" />}
          description={
            <FormattedMessage
              id="SubtitlesSectionFormItem.scteInfo"
              defaultMessage="For use SCTE, it is necessary to select the appropriate option when creating an output"
            />
          }
          type="info"
          showIcon
        />
      </Col>
      <Col sm={24} lg={12}>
        <Item
          name={[...prefix, "apiMode"]}
          label={<FormattedMessage id="SubtitlesSectionFormItem.apiMode" defaultMessage="API mode" />}
          rules={[required]}
        >
          <Select
            disabled={disabled}
            options={[
              {
                value: "Placeholder",
                label: <FormattedMessage id="SubtitlesSectionFormItem.placeholder" defaultMessage="Placeholder" />,
              },
              {
                value: "ApiV3StartStop",
                label: (
                  <FormattedMessage id="SubtitlesSectionFormItem.apiV3StartStop" defaultMessage="Api V3 Start Stop" />
                ),
              },
            ]}
          />
        </Item>
      </Col>
      <Col sm={24} lg={12}>
        <Item
          name={[...prefix, "commandMode"]}
          label={<FormattedMessage id="SubtitlesSectionFormItem.commandMode" defaultMessage="Command mode" />}
          rules={[required]}
        >
          <Select
            disabled={disabled}
            options={[
              {
                value: "SpliceInsert",
                label: <FormattedMessage id="SubtitlesSectionFormItem.spliceInsert" defaultMessage="Splice insert" />,
              },
              {
                value: "Timesignal",
                label: <FormattedMessage id="SubtitlesSectionFormItem.timesignal" defaultMessage="Timesignal" />,
              },
            ]}
          />
        </Item>
      </Col>
      <Col span={4}>
        <Item
          name={[...prefix, "omitCueIn"]}
          valuePropName="checked"
          label={<FormattedMessage id="SubtitlesSectionFormItem.omitCueIn" defaultMessage="Omit cue in" />}
          rules={[required]}
        >
          <Switch
            disabled={disabled}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
          />
        </Item>
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "repeat"]}
          label={<FormattedMessage id="SubtitlesSectionFormItem.repeat" defaultMessage="Repeat" />}
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
    </Row>
  );
}
