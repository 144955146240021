import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

const DirectOutputTitle = () => {
  return (
    <>
      <FormattedMessage id="DirectOutputTitle.directOutputs" defaultMessage="Direct Outputs" />
      <Tooltip
        placement="top"
        title={
          <span>
            <FormattedMessage
              id="DirectOutputTitle.usedTooltip"
              // eslint-disable-next-line max-len
              defaultMessage="Direct outputs are the fastest way to connect an input to outputs without any modifications to the payload.  Quickstream is not transcoding nor multiplexing the streams but just copying input to output directly"
            />
            .
          </span>
        }
      >
        <StyledIcon>
          <QuestionCircleFilled />
        </StyledIcon>
      </Tooltip>
    </>
  );
};

const StyledIcon = styled.span`
  margin-left: 5px;
`;

export default DirectOutputTitle;
