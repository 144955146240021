import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

const NodeLicenseFingerprint = ({ fingerprint }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleCopyButton = (event) => {
    const { target } = event;
    target.blur();
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  return (
    <>
      <StyledTooltip title={fingerprint}>
        <InfoCircleOutlined />
      </StyledTooltip>
      <CopyToClipboard text={fingerprint}>
        <Tooltip
          title={<FormattedMessage id="NodeLicenseFingerprint.copied" defaultMessage="Copied" />}
          placement="right"
          open={showTooltip}
        >
          <Button color="orange" disabled={!fingerprint} onClick={(event) => handleCopyButton(event)}>
            <FormattedMessage id="NodeLicenseFingerprint.copy" defaultMessage="Copy to clipboard" />
          </Button>
        </Tooltip>
      </CopyToClipboard>
    </>
  );
};

const StyledTooltip = styled(Tooltip)`
  margin: 15px;
`;

NodeLicenseFingerprint.propTypes = {
  fingerprint: PropTypes.string,
};

NodeLicenseFingerprint.defaultProps = {
  fingerprint: null,
};

export default NodeLicenseFingerprint;
