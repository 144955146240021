/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Card, Row, Col, Form, Select, InputNumber, Spin } from "antd";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import ImmutablePropTypes from "react-immutable-proptypes";

import store from "../../../../../store";
import MQTTService from "../../../../../services/mqtt";

import { SET_LOADING_FORMATS, selectors as FORMAT_SELECTORS } from "../../../../../ducks/nodeFormats";
import { selectors as CONNECTION_SELECTORS } from "../../../../../ducks/node";

import { required } from "../../../../../lib/utils/formValidation";
import { NODE_DEVICES_TYPE, SELECT_CUSTOM, MESSAGE_TYPE } from "../../../../../lib/utils/constants";
import globalTranslations from "../../../../../lib/translations";

const { Item } = Form;
const { Option, OptGroup } = Select;

const InputWebcamVideo = ({ disabled, formats, getFieldValue, setFieldsValue, videoInputs, validateFields }) => {
  const { formatMessage } = useIntl();
  const cwid = useSelector(CONNECTION_SELECTORS.getNodeCwid);

  const [selectedVideoDevice, setSelectedVideoDevice] = useState(getFieldValue(["input", "webcamDevice"]));
  const [selectedVideoFormat, setSelectedVideoFormat] = useState(getFieldValue(["input", "webcamDeviceFormat"]));

  const initialWebcamDeviceFormat =
    getFieldValue(["input", "webcamDeviceFormat"]) && getFieldValue(["input", "webcamDeviceFormat"]).split("-");
  const [disabledCustomValues, setDisabledCustomValues] = useState(
    initialWebcamDeviceFormat && initialWebcamDeviceFormat[2] !== SELECT_CUSTOM.value
  );

  useEffect(() => {
    const isFormatData = !!formats.get(selectedVideoDevice);
    if (!isFormatData && selectedVideoDevice) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_FORMATS,
          device: selectedVideoDevice,
          type: NODE_DEVICES_TYPE.webcamVideoInputs,
        },
      });

      store.dispatch(SET_LOADING_FORMATS(true));
    }
  }, [selectedVideoDevice, formats]);

  const handleChangeVideoDevice = (selectedVideoDeviceValue) => {
    setSelectedVideoDevice(selectedVideoDeviceValue);
    setDisabledCustomValues(true);

    setFieldsValue({
      input: {
        webcamDevice: selectedVideoDeviceValue,
        webcamDeviceFormat: null,
        compressed: null,
        codecName: null,
        pixelFormat: null,
        width: null,
        height: null,
        fps: null,
      },
    });
  };

  const setSuffix = (value) => {
    switch (value) {
      case "width":
        return "W";
      case "height":
        return "H";
      default:
        return "R";
    }
  };

  const customDataValidation = async () => {
    const fields = ["width", "height", "fps"];

    let filteredFormats;
    const deviceFormats = formats.get(selectedVideoDevice);
    const selectedVideoFormatData = selectedVideoFormat && selectedVideoFormat.split("-");
    const formatType = selectedVideoFormatData && !+selectedVideoFormatData[0] ? "raw" : "encoded";

    const deviceFormatsType =
      deviceFormats && deviceFormats[formatType].find((format) => format.name === selectedVideoFormatData[1]);

    const resultPromises = fields.map((fieldName) => {
      const suffix = setSuffix(fieldName);
      const fieldValue = +getFieldValue(["input", fieldName]);

      if (fieldValue) {
        if (filteredFormats) {
          const selectedFormat = filteredFormats.filter((format) => {
            return +fieldValue <= +format[`max${suffix}`] && +fieldValue >= +format[`min${suffix}`];
          });

          if (!selectedFormat || selectedFormat.length === 0) {
            return Promise.reject(formatMessage(globalTranslations.valueOutOfRange));
          }
        }

        filteredFormats =
          deviceFormatsType &&
          deviceFormatsType.formats.filter((format) => {
            return +fieldValue <= +format[`max${suffix}`] && +fieldValue >= +format[`min${suffix}`];
          });

        if (!filteredFormats || filteredFormats.length === 0) {
          return Promise.reject(formatMessage(globalTranslations.valueOutOfRange));
        }
      }

      return null;
    });

    await Promise.all(resultPromises);

    return Promise.resolve();
  };

  const handleChangeVideoFormat = (selectedVideoFormatValue) => {
    const inputForm = getFieldValue("input");

    const selectedData = selectedVideoFormatValue && selectedVideoFormatValue.split("-");

    setSelectedVideoFormat(selectedVideoFormatValue);

    if (selectedData[2] === SELECT_CUSTOM.value) {
      delete inputForm.pixelFormat;
      delete inputForm.codecName;

      setFieldsValue({
        input: {
          ...inputForm,
          compressed: !!+selectedData[0],
          ...(+selectedData[0] ? { codecName: selectedData[1] } : { pixelFormat: selectedData[1] }),
          width: null,
          height: null,
          fps: null,
        },
      });
      setDisabledCustomValues(false);
    } else {
      delete inputForm.pixelFormat;
      delete inputForm.codecName;

      setFieldsValue({
        input: {
          ...inputForm,
          compressed: !!+selectedData[0],
          ...(+selectedData[0] ? { codecName: selectedData[1] } : { pixelFormat: selectedData[1] }),
          width: selectedData[2],
          height: selectedData[3],
          fps: selectedData[4],
        },
      });
      setDisabledCustomValues(true);
    }
  };

  const VIDEO_INPUTS_SELECT = videoInputs.map((input) => {
    return {
      label: input.device,
      value: input.device,
    };
  });

  const selectedDeviceFormats = formats.get(selectedVideoDevice);
  const isFormats = !!selectedDeviceFormats;

  const ENCODED_VIDEO_FORMATS =
    isFormats &&
    selectedDeviceFormats?.encoded.map((encodedOption) => {
      return { name: encodedOption.name, formats: [...encodedOption.formats, SELECT_CUSTOM] };
    });

  const RAW_VIDEO_FORMATS =
    !!selectedDeviceFormats &&
    selectedDeviceFormats?.raw.map((rawOption) => {
      return { name: rawOption.name, formats: [...rawOption.formats, SELECT_CUSTOM] };
    });

  return (
    <Card title={<FormattedMessage id="InputWebcamVideo.video" defaultMessage="Video" />}>
      <Row gutter={24}>
        <Col span={24}>
          <Item
            name={["input", "webcamDevice"]}
            label={<FormattedMessage id="InputWebcamVideo.device" defaultMessage="Device" />}
            rules={[...(selectedVideoDevice ? [required] : [])]}
          >
            <Select disabled={disabled} onChange={handleChangeVideoDevice} allowClear>
              {VIDEO_INPUTS_SELECT.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Spin
        spinning={!isFormats}
        tip={
          <FormattedMessage
            id="InputWebcamMic.waitingFormNodeFormats"
            defaultMessage="Waiting for node formats or device to be selected..."
          />
        }
      >
        <Row gutter={24}>
          <Col span={24}>
            <Item
              name={["input", "webcamDeviceFormat"]}
              label={<FormattedMessage id="InputWebcamVideo.format" defaultMessage="Format" />}
              rules={[...(selectedVideoDevice ? [required] : [])]}
            >
              <Select disabled={disabled} onChange={handleChangeVideoFormat}>
                {ENCODED_VIDEO_FORMATS &&
                  ENCODED_VIDEO_FORMATS.map((encodedOption) => {
                    return (
                      <OptGroup key={encodedOption.name} label={encodedOption.name}>
                        {encodedOption.formats.map((optionFormat) => {
                          if (optionFormat.value === SELECT_CUSTOM.value) {
                            return (
                              <Option
                                key={`1-${encodedOption.name}-${optionFormat.value}`}
                                value={`1-${encodedOption.name}-${optionFormat.value}`}
                              >
                                {`${optionFormat.label} (${encodedOption.name})`}
                              </Option>
                            );
                          }

                          const optionKey = `1-${encodedOption.name}-${optionFormat.maxH}-${optionFormat.maxR}-${optionFormat.maxW}-${optionFormat.minH}-${optionFormat.minR}-${optionFormat.minW}`;
                          const optionLabel = `${optionFormat.maxW}x${optionFormat.maxH}, fps: ${optionFormat.maxR}`;
                          const optionValue = `1-${encodedOption.name}-${optionFormat.maxW}-${optionFormat.maxH}-${optionFormat.maxR}`;

                          return (
                            <Option key={optionKey} value={optionValue}>
                              {optionLabel}
                            </Option>
                          );
                        })}
                      </OptGroup>
                    );
                  })}
                {RAW_VIDEO_FORMATS &&
                  RAW_VIDEO_FORMATS.map((rawOption) => {
                    return (
                      <OptGroup key={rawOption.name} label={rawOption.name}>
                        {rawOption.formats.map((optionFormat) => {
                          if (optionFormat.value === SELECT_CUSTOM.value) {
                            return (
                              <Option
                                key={`0-${rawOption.name}-${optionFormat.value}`}
                                value={`0-${rawOption.name}-${optionFormat.value}`}
                              >
                                {`${optionFormat.label} (${rawOption.name})`}
                              </Option>
                            );
                          }

                          const optionKey = `0-${rawOption.name}-${optionFormat.maxH}-${optionFormat.maxR}-${optionFormat.maxW}-${optionFormat.minH}-${optionFormat.minR}-${optionFormat.minW}`;
                          const optionLabel = `${optionFormat.maxW}x${optionFormat.maxH}, fps: ${optionFormat.maxR}`;
                          const optionValue = `0-${rawOption.name}-${optionFormat.maxW}-${optionFormat.maxH}-${optionFormat.maxR}`;

                          return (
                            <Option key={optionKey} value={optionValue}>
                              {optionLabel}
                            </Option>
                          );
                        })}
                      </OptGroup>
                    );
                  })}
              </Select>
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name={["input", "width"]}
              label={<FormattedMessage id="InputWebcamMic.width" defaultMessage="Width" />}
              rules={[
                ...(selectedVideoDevice
                  ? [required, { validator: customDataValidation }]
                  : [{ validator: customDataValidation }]),
              ]}
            >
              <InputNumber type="number" disabled={disabled || disabledCustomValues} />
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name={["input", "height"]}
              label={<FormattedMessage id="InputWebcamMic.height" defaultMessage="Height" />}
              rules={[
                ...(selectedVideoDevice
                  ? [required, { validator: customDataValidation }]
                  : [{ validator: customDataValidation }]),
              ]}
            >
              <InputNumber type="number" disabled={disabled || disabledCustomValues} />
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name={["input", "fps"]}
              label={<FormattedMessage id="InputWebcamMic.fps" defaultMessage="fps" />}
              rules={[
                ...(selectedVideoDevice
                  ? [required, { validator: customDataValidation }]
                  : [{ validator: customDataValidation }]),
              ]}
            >
              <InputNumber
                type="number"
                disabled={disabled || disabledCustomValues}
                onChange={(value) => {
                  if (value) {
                    setFieldsValue({ input: { fps: value.toString() } });
                  }
                  validateFields([["input", "fps"]]);
                }}
              />
            </Item>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

InputWebcamVideo.propTypes = {
  disabled: PropTypes.bool,
  formats: ImmutablePropTypes.map.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  videoInputs: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.string,
    })
  ).isRequired,
  validateFields: PropTypes.func.isRequired,
};

InputWebcamVideo.defaultProps = {
  disabled: null,
};

const mapStateToProps = (state) => ({
  formats: FORMAT_SELECTORS.getFormats(state),
});

export default connect(mapStateToProps, null)(InputWebcamVideo);
