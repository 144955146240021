import React from "react";
import PropTypes from "prop-types";
import { MutedOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import MQTTService from "../../services/mqtt";

import { MESSAGE_TYPE } from "../../lib/utils/constants";

export default function NodeAlertsHeader({ channelName, cwid, cnn, channelId }) {
  const handleMute = () => {
    MQTTService.sendMessageV5({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.NODE_MUTE_ALERTS,
        channelId,
        mute: true,
      },
    });
  };

  return (
    <div>
      <Link key={`/node/${cwid}/${cnn}/channel/${channelId}`} to={`/node/${cwid}/${cnn}/channel/${channelId}`}>
        {channelName}
      </Link>{" "}
      <Popconfirm
        placement="top"
        title={<FormattedMessage id="NodeAlertsHeader.muteChannelAlerts" defaultMessage="Mute this channel alerts?" />}
        okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
        cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
        onConfirm={handleMute}
      >
        <Button size="small" shape="circle" icon={<MutedOutlined style={{ fontSize: "11px" }} />} />
      </Popconfirm>
    </div>
  );
}

NodeAlertsHeader.propTypes = {
  channelName: PropTypes.string.isRequired,
  cwid: PropTypes.string.isRequired,
  cnn: PropTypes.string.isRequired,
};
