import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import store from "../store";

import MQTTService from "../services/mqtt";
import { selectors as CONNECTION_SELECTORS, getNodeNdisSelector } from "../ducks/node";
import { actions as LOADING_ACTIONS, selectors as LOADING_SELECTORS } from "../ducks/loadingData";
import { selectors as MQTT_SELECTORS } from "../ducks/mqtt";

import { MESSAGE_TYPE, MQTT_STATUS } from "../lib/utils/constants";

function useNodeNDISChannels() {
  const { cwid } = useParams();
  const MQTTStatus = useSelector(MQTT_SELECTORS.getStatus);
  const MQTTConnection = useSelector(MQTT_SELECTORS.getMqttConnection);
  const isConnection = MQTTConnection && MQTTStatus === MQTT_STATUS.CONNECTED;
  const nodeNDISChannels = useSelector(CONNECTION_SELECTORS.getNodeNdis);
  const noNodeNDISChannels = nodeNDISChannels && nodeNDISChannels.length === 0;
  const nodeNDISSelector = useSelector(getNodeNdisSelector) || [];
  const loadingNdis = useSelector(LOADING_SELECTORS.getLoadingNodeNdis);

  useEffect(() => {
    const getNodeNDISChannels = () => {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.LIST_NDI_CHANNELS,
        },
      });
    };

    if (isConnection && noNodeNDISChannels) {
      store.dispatch(LOADING_ACTIONS.SET_LOADING_NODE_NDIS(true));
      getNodeNDISChannels();
    }

    return () => {
      store.dispatch(LOADING_ACTIONS.SET_LOADING_NODE_NDIS(false));
    };
  }, [isConnection, cwid, noNodeNDISChannels]);

  return { nodeNDISSelector, loadingNdis };
}

export default useNodeNDISChannels;
