import React, { useState } from "react";
import { Row, Card, Tabs, Spin, Col, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  selectors as STATISTIC_NODE_SELECTORS,
  getNodeCPUStatsSelector,
  getNodeRAMStatsSelector,
} from "../../../../ducks/nodeSystemStatistics";

import NodeHeaderSystemStats from "../../../../components/NodeHeaderSystemStats";

import NodeStatisticsGraph from "./NodeStatisticsGraph";
import NodeSystemStatsGraph from "./NodeSystemStatsGraph";

import { NODE_STATS_TYPE } from "../../../../lib/utils/constants";

const NodeLiveStatistics = () => {
  const { cwid } = useParams();
  const nodeNetworkInterfaces = useSelector(STATISTIC_NODE_SELECTORS.getStatisticNodeInterfaces);
  const cupStats = useSelector((state) => getNodeCPUStatsSelector(state, cwid)) || [];
  const ramStats = useSelector((state) => getNodeRAMStatsSelector(state, cwid)) || [];

  const filteredIncomingStats = nodeNetworkInterfaces && nodeNetworkInterfaces[cwid]?.incoming;
  const filteredOutgoingStats = nodeNetworkInterfaces && nodeNetworkInterfaces[cwid]?.outgoing;

  const [activeTab, setActiveTab] = useState(NODE_STATS_TYPE.incoming);
  const dataKeysArray = (filteredIncomingStats && Object.keys(filteredIncomingStats)) || [""];
  const [selectedStat, setSelectedStat] = useState(dataKeysArray[0]);

  const handleChangeTab = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  if (!filteredIncomingStats && !filteredOutgoingStats) {
    return (
      <StyledCard title={<FormattedMessage id="NodeLiveStatistics.title" defaultMessage="Node Statistics" />}>
        <Row type="flex" justify="center">
          <Col span={24}>
            <Spin tip={<FormattedMessage id="NodeLiveStatistics.waitingForData" defaultMessage="Waiting for data" />}>
              <Skeleton active />
            </Spin>
          </Col>
        </Row>
      </StyledCard>
    );
  }

  const items = [
    {
      label: <FormattedMessage id="NodeLiveStatistics.incomingTraffic" defaultMessage="Incoming network traffic" />,
      key: NODE_STATS_TYPE.incoming,
      children: (
        <Row>
          <Col sm={24} md={24} xxl={12}>
            <Card>
              <NodeStatisticsGraph
                data={filteredIncomingStats}
                selectedStat={selectedStat}
                setSelectedStat={setSelectedStat}
              />
            </Card>
          </Col>
        </Row>
      ),
    },
    {
      label: <FormattedMessage id="NodeLiveStatistics.outgoingTraffic" defaultMessage="Outgoing network traffic" />,
      key: NODE_STATS_TYPE.outgoing,
      children: (
        <Row>
          <Col sm={24} md={24} xxl={12}>
            <Card>
              <NodeStatisticsGraph
                data={filteredOutgoingStats}
                selectedStat={selectedStat}
                setSelectedStat={setSelectedStat}
              />
            </Card>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <StyledCard
      title={<FormattedMessage id="NodeLiveStatistics.title" defaultMessage="Node Statistics" />}
      extra={<NodeHeaderSystemStats />}
    >
      <StyledCardMargin title={<FormattedMessage id="NodeLiveStatistics.system" defaultMessage="System" />}>
        <Row justify="center" gutter={24}>
          <Col span={12}>
            <Card>
              <NodeSystemStatsGraph data={cupStats} label="CPU" />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <NodeSystemStatsGraph data={ramStats} label="RAM" />
            </Card>
          </Col>
        </Row>
      </StyledCardMargin>
      <Card title={<FormattedMessage id="NodeLiveStatistics.network" defaultMessage="Network" />}>
        <Tabs defaultActiveKey={activeTab} onChange={handleChangeTab} items={items} />
      </Card>
    </StyledCard>
  );
};

const StyledCardMargin = styled(Card)`
  margin-bottom: 15px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 10px;
  min-height: 625px;
`;

export default NodeLiveStatistics;
