import React from "react";
import styled from "styled-components";
import { Button, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import {
  DEFAULT_VIDEO_STREAM,
  DEFAULT_AUDIO_STREAM,
  RTMP_AUDIO_STREAM,
} from "../../../../../../../lib/utils/defaultValues/defaultStreamTypes";
import { OUTPUTS_TYPES } from "../../../../../../../lib/utils/constants";

const ProgramStreamsAddButtons = ({ add, prefix, form }) => {
  const outputType = Form.useWatch([prefix[0], prefix[1], "type"], form);
  const isRtmpOutput = outputType === OUTPUTS_TYPES.outputRtmp.value;

  return (
    <>
      <StyledButton>
        <Button
          type="dashed"
          style={{ width: "48%" }}
          onClick={() => {
            add(DEFAULT_VIDEO_STREAM);
          }}
        >
          <PlusOutlined />{" "}
          <FormattedMessage id="ProgramStreamsAddButtons.addVideoStream" defaultMessage="Add video stream" />
        </Button>
        <Button
          type="dashed"
          style={{ width: "48%" }}
          onClick={() => {
            add(isRtmpOutput ? RTMP_AUDIO_STREAM : DEFAULT_AUDIO_STREAM);
          }}
        >
          <PlusOutlined />{" "}
          <FormattedMessage id="ProgramStreamsAddButtons.addAudioStream" defaultMessage="Add audio stream" />
        </Button>
      </StyledButton>
    </>
  );
};

const StyledButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

ProgramStreamsAddButtons.propTypes = {
  add: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

export default ProgramStreamsAddButtons;
