import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Input, Form, Tooltip } from "antd";
import PropTypes from "prop-types";
import { QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

const { Item } = Form;

const messages = defineMessages({
  hostRequired: {
    id: "HostField.hostRequired",
    defaultMessage: "Please input host address",
  },
  validation: {
    id: "HostField.validation",
    defaultMessage: "Host has an invalid format",
  },
  host: {
    id: "HostField.host",
    defaultMessage: "Host",
  },
  tooltip: {
    id: "HostField.tooltip",
    defaultMessage: "IP address or domain url",
  },
});

const HostLabel = () => {
  const { formatMessage } = useIntl();

  return (
    <StyledLabel>
      <Tooltip placement="top" title={formatMessage(messages.tooltip)}>
        <span>{formatMessage(messages.host)}</span>
        <QuestionCircleFilled />
      </Tooltip>
    </StyledLabel>
  );
};

export const HostField = ({ disabled, name }) => {
  const { formatMessage } = useIntl();

  return (
    <Item
      name={name}
      label={<HostLabel />}
      rules={[
        { required: true, message: formatMessage(messages.hostRequired) },
        {
          pattern: new RegExp("^[a-z0-9]{1}([a-z0-9-]{0,61}[a-z0-9]{1})*(.[a-z0-9]{1}([a-z0-9-]{0,61}[a-z0-9]{1})*)*$"),
          message: formatMessage(messages.validation),
        },
      ]}
    >
      <Input type="text" disabled={disabled} />
    </Item>
  );
};

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

HostField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

HostField.defaultProps = {
  disabled: false,
};

export default HostField;
