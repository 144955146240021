import React, { useState } from "react";
import { Form, Select, Space, Divider, Input, Button } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";

import { VIDEO_CODECS } from "../../../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

const translations = defineMessages({
  codecName: {
    id: "VirtualChannelCodecs.codecName",
    defaultMessage: "Enter codec name",
  },
});

const VirtualChannelCodecs = () => {
  const { formatMessage } = useIntl();
  const [videoCodecs, setVideoCodes] = useState(VIDEO_CODECS);
  const [newCodecName, setNewCodecName] = useState("");

  const onChangeNewCodecName = (event) => {
    setNewCodecName(event.target.value);
  };

  const addCodec = (event) => {
    event.preventDefault();
    setVideoCodes([...videoCodecs, newCodecName]);
    setNewCodecName("");
  };

  return (
    <Item
      label={<FormattedMessage id="VirtualChannelCodecs.videoCodecs" defaultMessage="Video codecs" />}
      name="videoCodec"
    >
      <Select
        mode="multiple"
        allowClear
        showSearch
        placeholder={<FormattedMessage id="general.pleaseSelect" defaultMessage="Please select" />}
        optionFilterProp="children"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Space
              align="center"
              style={{
                padding: "0 8px 4px",
              }}
            >
              <Input
                placeholder={formatMessage(translations.codecName)}
                value={newCodecName}
                onChange={onChangeNewCodecName}
              />
              <Button
                disabled={newCodecName.length === 0}
                type="primary"
                shape="round"
                onClick={addCodec}
                icon={<PlusOutlined />}
              >
                <span>
                  <FormattedMessage id="VirtualChannelCodecs.addCodec" defaultMessage="Add codec" />
                </span>
              </Button>
            </Space>
          </>
        )}
      >
        {videoCodecs.map((codec) => (
          <Option value={codec} key={codec}>
            {codec}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default VirtualChannelCodecs;
