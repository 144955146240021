import React from "react";
import { Col, Form, Select } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { required, AACAudioChannels, MPEGTSAudioChannels } from "../../../../../lib/utils/formValidation";
import {
  GLOBAL_CODEC_AUDIO_SAMPLE_FORMATS,
  GLOBAL_CODEC_AUDIO_SAMPLE_RATE,
  SELECT_STREAM_CODEC_AUDIO,
  ACC_AUDIO_SAMPLE_RATE_FORMATS,
} from "../../../../../lib/utils/constants";
import languageCodes from "../../../../../lib/utils/languageCodes";

import InputNumberField from "../../../../Fields/InputNumberField";

const { Item } = Form;
const { Option } = Select;

const getMaxAudioChannels = (audioCodedType) => {
  switch (audioCodedType) {
    case SELECT_STREAM_CODEC_AUDIO[2].value:
      return 8;
    case SELECT_STREAM_CODEC_AUDIO[1].value:
      return 2;
    default:
      return 16;
  }
};

function GlobalAudioCodecFormItem({ disabled, prefix, isAudioFormat, audioCodecType, isEnginePlayout }) {
  const isAACCodeType = audioCodecType === SELECT_STREAM_CODEC_AUDIO[2].value;
  const isMpeg2audioType = audioCodecType === SELECT_STREAM_CODEC_AUDIO[1].value;

  const PARSED_CODEC_AUDIO_SAMPLE_FORMATS = isAACCodeType
    ? ACC_AUDIO_SAMPLE_RATE_FORMATS
    : GLOBAL_CODEC_AUDIO_SAMPLE_FORMATS;

  return (
    <>
      <Col span={6}>
        <InputNumberField
          min={1}
          max={getMaxAudioChannels(audioCodecType)}
          name={[...prefix, "channels"]}
          label={<FormattedMessage id="GlobalAudioCodecFormItem.channels" defaultMessage="Channels" />}
          rules={[
            required,
            ...(isAACCodeType ? [AACAudioChannels] : []),
            ...(isMpeg2audioType ? [MPEGTSAudioChannels] : []),
          ]}
          disabled={disabled}
        />
      </Col>
      {isEnginePlayout && (
        <Col span={6}>
          <Item
            name={[...prefix, "langCode"]}
            label={<FormattedMessage id="GlobalAudioCodecFormItem.language" defaultMessage="Language" />}
            rules={[required]}
          >
            <Select disabled={disabled} options={languageCodes} showSearch />
          </Item>
        </Col>
      )}
      <Item
        name={[...prefix, "sampleFormat"]}
        label={<FormattedMessage id="GlobalAudioCodecFormItem.sampleFormat" defaultMessage="Sample format" />}
        rules={[required]}
        hidden
      >
        <Select disabled={disabled}>
          {PARSED_CODEC_AUDIO_SAMPLE_FORMATS.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Item>
      {!isAudioFormat && (
        <Col span={8}>
          <Item
            name={[...prefix, "sampleRate"]}
            label={<FormattedMessage id="GlobalAudioCodecFormItem.sampleRate" defaultMessage="Sample rate" />}
            rules={[required]}
          >
            <Select disabled={disabled}>
              {GLOBAL_CODEC_AUDIO_SAMPLE_RATE.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      )}
    </>
  );
}

GlobalAudioCodecFormItem.propTypes = {
  isAudioFormat: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  audioCodecType: PropTypes.string,
  isEnginePlayout: PropTypes.bool,
};

GlobalAudioCodecFormItem.defaultProps = {
  isAudioFormat: false,
  audioCodecType: null,
  isEnginePlayout: null,
};

export default GlobalAudioCodecFormItem;
