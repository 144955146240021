import React from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col, Image } from "antd";
import styled from "styled-components";

import LANGUAGES from "../../lib/utils/languages";

function HubChannelDetailView({ hubChannelData }) {
  if (!hubChannelData) {
    return <FormattedMessage id="HubChannelDetailView.noChannelData" defaultMessage="No channel data" />;
  }

  const {
    bgUrl,
    channelName,
    logoUrl,
    languages,
    channelType,
    resolutions,
    subtitles,
    videoCodec,
    description,
    screenShotUrls,
  } = hubChannelData;

  return (
    <StyledContainer>
      <StyledRow>
        {bgUrl && (
          <Col span={24}>
            <ShadowBox>
              <StyledBgImg src={bgUrl} alt={`${channelName} - header`} />
            </ShadowBox>
          </Col>
        )}
      </StyledRow>
      <Row justify="center" gutter={24}>
        {logoUrl && (
          <Col span={4}>
            <StyledLogo src={logoUrl} alt="logo" />
          </Col>
        )}
        <Col span={16}>
          <Row gutter={16}>
            <Col span={24}>
              <StyledChannelName>{channelName}</StyledChannelName>
            </Col>
            <Col span={24}>
              {languages && languages.length > 0 && (
                <div>
                  <StyledLabel>
                    <FormattedMessage id="HubChannelDetailView.languages" defaultMessage="Languages" />
                  </StyledLabel>
                  {languages.map((lng) => {
                    const selectedLng = LANGUAGES.find((language) => language.code === lng);

                    return <StyledTag>{selectedLng?.name}</StyledTag>;
                  })}
                </div>
              )}

              {channelType && (
                <div>
                  <StyledLabel>
                    <FormattedMessage id="HubChannelDetailView.channelType" defaultMessage="Channel Type" />
                  </StyledLabel>
                  <StyledTag>{channelType}</StyledTag>
                </div>
              )}

              {resolutions && resolutions.length > 0 && (
                <div>
                  <StyledLabel>
                    <FormattedMessage id="HubChannelDetailView.resolutions" defaultMessage="Resolutions" />
                  </StyledLabel>
                  {resolutions.map((resolution) => (
                    <StyledTag>{resolution}</StyledTag>
                  ))}
                </div>
              )}

              {subtitles && subtitles.length > 0 && (
                <div>
                  <StyledLabel>
                    <FormattedMessage id="HubChannelDetailView.subtitles" defaultMessage="Subtitles" />
                  </StyledLabel>
                  {subtitles.map((subtile) => {
                    const selectedLng = LANGUAGES.find((language) => language.code === subtile);

                    return <StyledTag>{selectedLng?.name}</StyledTag>;
                  })}
                </div>
              )}

              {videoCodec && videoCodec.length > 0 && (
                <div>
                  <StyledLabel>
                    <FormattedMessage id="HubChannelDetailView.videoCodec" defaultMessage="Video Codec" />
                  </StyledLabel>
                  {videoCodec.map((videoCode) => (
                    <StyledTag>{videoCode}</StyledTag>
                  ))}
                </div>
              )}

              {description && (
                <StyledDescription>
                  <p>{description}</p>
                </StyledDescription>
              )}

              {screenShotUrls && screenShotUrls.length > 0 && (
                <>
                  {screenShotUrls.map((src) => (
                    <StyledScreenImg src={src} />
                  ))}
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledContainer>
  );
}

const StyledScreenImg = styled(Image)`
  max-width: 200px;
  margin: 8px;
`;

const StyledDescription = styled.div`
  margin-top: 8px;
  color: white;
`;

const StyledTag = styled.span`
  color: #dfe1e5;
  letter-spacing: 0.05rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.375rem 0.75rem;
  background-color: rgb(213 229 255 / 0.15);
  border-radius: 9999px;
  align-items: center;
  display: inline-flex;
  margin: 0.25rem;
`;

const StyledLabel = styled.span`
  font-size: 1rem;
  color: #7c8698;
  text-transform: uppercase;
  margin-right: 5px;
  min-width: 120px;
  display: inline-block;
`;

const StyledContainer = styled.div`
  background-color: #090712;
`;

const StyledChannelName = styled.div`
  font-size: 1.5rem;
  color: white;
  margin-top: 25px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

const StyledLogo = styled.img`
  width: 100%;
`;

const StyledBgImg = styled.img`
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: transparent;
`;

const ShadowBox = styled.div`
  &:after {
    box-shadow: inset 0px -40px 60px 0px #090712;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export default HubChannelDetailView;
