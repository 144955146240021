export default [
  "Sports",
  "Entertainment",
  "Lifestyle",
  "General",
  "News",
  "Kids",
  "Culture|Science|World",
  "Document",
  "Film Entertainment",
  "Music",
  "Other",
];
