import PropTypes from "prop-types";

import { OUTPUTS_TYPES } from "../../../lib/utils/constants";

const MuxedOutputElementLabel = ({ type }) => {
  const isNdiType = type === OUTPUTS_TYPES.outputNdi.value;
  const isRtmpTypes = type === OUTPUTS_TYPES.outputRtmp.value;

  if (isNdiType) {
    return OUTPUTS_TYPES.outputNdi.label;
  }

  if (isRtmpTypes) {
    return OUTPUTS_TYPES.outputRtmp.label;
  }

  return type && type.toUpperCase();
};

MuxedOutputElementLabel.propTypes = {
  type: PropTypes.string.isRequired,
};

export default MuxedOutputElementLabel;
