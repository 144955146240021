import React from "react";
import PropsTypes from "prop-types";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

const ChannelDetailViewStatus = ({ status }) => {
  const pendingStatus = status && status.includes("@");

  return (
    <>
      {pendingStatus && (
        <Tooltip
          placement="top"
          title={
            <FormattedMessage id="ChannelDetailViewStatus.waitingFor" defaultMessage="Waiting for user registration" />
          }
        >
          <StyledMarginRight>
            <FormattedMessage id="ChannelDetailViewStatus.pendingStatus" defaultMessage="Pending" /> ({status})
          </StyledMarginRight>
          <QuestionCircleFilled />
        </Tooltip>
      )}
      {!pendingStatus && (
        <span>
          <FormattedMessage id="ChannelDetailViewStatus.activeStatus" defaultMessage="Active" />
        </span>
      )}
    </>
  );
};

const StyledMarginRight = styled.span`
  margin-right: 5px;
`;

ChannelDetailViewStatus.propTypes = {
  status: PropsTypes.string.isRequired,
};

export default ChannelDetailViewStatus;
