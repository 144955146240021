import { PLAYOUT_OUTPUT_TYPES } from "../constants";

export const PLAYOUT_DATA_SOURCE_SELECTOR = {
  main: {
    value: "main",
    label: "Main",
  },
  switcher: {
    value: "switcher",
    label: "Switcher",
  },
  live: {
    value: "live",
    label: "Live",
  },
};

const DEFAULT_PROVIDER = "Quickstream";

export const DEFAULT_PLAYOUT_OUTPUT = {
  type: PLAYOUT_OUTPUT_TYPES.playoutUrl.value,
  dataSource: PLAYOUT_DATA_SOURCE_SELECTOR.main.value,
  mpegtsServiceProvider: DEFAULT_PROVIDER,
  mpegtsServiceName: DEFAULT_PROVIDER,
  mpegtsCBRMode: false,
  mpegtsServiceId: 3,
  mpegtsMuxrate: 5000,
  mpegtsPmtStartPid: 1023,
  mpegtsAudioLangCode: "pol",
  mpegtsVideoPid: 1024,
  mpegtsAudioPid: 1025,
  scteEnabled: false,
  mpegtsScte35Pid: 1026,
  mpegtsTransportStreamId: 1,
  mpegtsOriginalNetworkId: 1,
  maxDelayTweak: 0,
  mpegtsPcrPeriod: 20,
  mpegtsPatPeriod: 100,
  mpegtsSdtPeriod: 500,
  mpegtsNitPeriod: 10000,
  mpegtsTdtPeriod: 30000,
  mpegtsEitPeriod: 2000,
  urlDestinations: [],
  audioStreams: [],
  videoFormat: {
    resize: "pad",
    presetId: 1,
    width: 320,
    height: 180,
    pixFormat: "YUV420P",
    fps: "25",
    interlaced: 0,
    customSar: false,
    sarDen: 37,
    sarNum: 64,
    changeGOP: false,
    bf: 4,
    g: 15,
    type: "Mpeg2video",
    mpeg2Profile: "high",
  },
};

export const DEFAULT_PLAYOUT_DECKLINK_OUTPUT = {
  type: PLAYOUT_OUTPUT_TYPES.playoutDecklink.value,
  format: "Hp50",
  dataSource: PLAYOUT_DATA_SOURCE_SELECTOR.main.value,
  resize: "pad",
  audioChannels: 2,
};
