import React, { useCallback } from "react";
import { Select } from "antd";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { actions as LANG_ACTIONS, selectors as LANG_SELECTORS } from "../../ducks/lang";
import store from "../../store";

const { Option } = Select;

const SelectLangComponent = ({ lang }) => {
  const handleChange = useCallback((value) => {
    store.dispatch(LANG_ACTIONS.CHANGE_LANG_DATA({ lang: value }));
    window.localStorage.setItem("lang", value);
  }, []);

  return (
    <StyledSelect value={lang} onChange={handleChange}>
      <Option value="pl">PL</Option>
      <Option value="en">EN</Option>
    </StyledSelect>
  );
};

SelectLangComponent.propTypes = {
  lang: PropTypes.string,
};

SelectLangComponent.defaultProps = {
  lang: null,
};

const StyledSelect = styled(Select)`
  display: none;
  align-self: center;
  margin: 0 10px !important;
`;

const mapStateToProps = (state) => ({
  lang: LANG_SELECTORS.getLang(state),
});

export default connect(mapStateToProps, null)(SelectLangComponent);
