import { Card, Col, Divider, Row, Steps } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { errorNotification } from "../../lib/utils/notification";
import OrganizationsServices from "../../services/organizations";

import { selectors as ORGANIZATION_SELECTORS } from "../../ducks/organizations";

import OrganizationStep0 from "../OrganizationStep0";
import OrganizationStep1 from "../OrganizationStep1";
import OrganizationStep2 from "../OrganizationStep2";

// import OrganizationStep3 from "../../components/OrganizationStep3";

const { Step } = Steps;

const OrganizationAssign = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { formatMessage } = useIntl();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    setLoadingData(true);
    OrganizationsServices.getOrganizations({ errorNotification: errorNotification(formatMessage), setLoadingData });
  }, [formatMessage]);

  const handleChangeStep = useCallback((selectedStep) => {
    setCurrentStep(selectedStep);
  }, []);

  const organizationId = useSelector(ORGANIZATION_SELECTORS.getOrganizationId);

  return (
    <Row gutter={[24, 12]}>
      <Col span={24}>
        <Card>
          <Steps current={currentStep} onChange={handleChangeStep}>
            <Step
              title={<FormattedMessage id="OrganizationPage.organization" defaultMessage="Organization" />}
              description={
                <FormattedMessage id="OrganizationPage.chooseOrganization" defaultMessage="Choose organization" />
              }
            />
            <Step
              disabled={!organizationId}
              title={<FormattedMessage id="OrganizationPage.role" defaultMessage="Role" />}
              description={<FormattedMessage id="OrganizationPage.selectRole" defaultMessage="Organization role" />}
            />
            <Step
              disabled={!organizationId}
              title={<FormattedMessage id="OrganizationPage.publicHub" defaultMessage="Quickstream Hub" />}
              description={<FormattedMessage id="OrganizationPage.publicCatalog" defaultMessage="Public catalog" />}
            />
            {/* <Step
              disabled={!organizationId}
              title={<FormattedMessage id="OrganizationPage.preview" defaultMessage="Preview" />}
              description="Business card"
            /> */}
          </Steps>
          <Divider />

          {currentStep === 0 && <OrganizationStep0 setCurrentStep={setCurrentStep} loadingData={loadingData} />}
          {currentStep === 1 && <OrganizationStep1 setCurrentStep={setCurrentStep} />}
          {currentStep === 2 && <OrganizationStep2 setCurrentStep={setCurrentStep} />}
          {/* {currentStep === 3 && <OrganizationStep3 setCurrentStep={setCurrentStep} />} */}
        </Card>
      </Col>
    </Row>
  );
};

export default OrganizationAssign;
