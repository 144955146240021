import { PlusCircleOutlined, ProfileOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Modal, Row, Select, Space, Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import themeColor from "../../lib/style/theme";
import globalTranslation from "../../lib/translations";
import { required } from "../../lib/utils/formValidation";
import { errorNotification } from "../../lib/utils/notification";

import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";
import { selectors as ORGANIZATION_SELECTORS } from "../../ducks/organizations";
import OrganizationsServices from "../../services/organizations";
import useRole from "../../hooks/useRole";

import OrganizationForm from "../OrganizationForm";
import OrganizationLoading from "../OrganizationLoading";
import OrganizationDetail from "./OrganizationDetail";
import OrganizationStep0Nav from "./OrganizationStep0Nav";

const { Item: FromItem } = Form;
const { Option } = Select;

const OrganizationStep0 = ({ setCurrentStep, loadingData }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { isAdmin, isAdminOrSupport } = useRole();
  const { setFieldsValue } = form;

  const organizationsPublicList = useSelector(ORGANIZATION_SELECTORS.getOrganizationsPublicList);
  const organizationsList = useSelector(ORGANIZATION_SELECTORS.getOrganizationsList);
  const organizationRoleId = useSelector(ORGANIZATION_SELECTORS.getOrganizationRoleId);
  const canCreateNewOrganization = organizationsList && organizationsList.length === 0;

  const organizationId = useSelector(ORGANIZATION_SELECTORS.getOrganizationId);
  const accountId = useSelector(ACCOUNT_SELECTORS.getUserId);

  const selectedOrganizationId = Form.useWatch("organization", form);

  const selectedOrganizationData = organizationsPublicList.find(
    (organization) => organization.id === selectedOrganizationId
  );

  const { creator: organizationOwnerId } = selectedOrganizationData || {};
  const isOrganizationOwner = accountId === organizationOwnerId;

  const [isVisibleNewOrgModal, setIsVisibleNewOrgModal] = useState(false);
  const [isVisibleEditOrgModal, setIsVisibleEditOrgModal] = useState(false);
  const [isVisibleOrgDetailModal, setIsVisibleOrgDetailModal] = useState(false);

  const organizationData = useSelector(ORGANIZATION_SELECTORS.getOrganizationData);

  const handleShowModal = useCallback(() => {
    setIsVisibleNewOrgModal(true);
  }, [setIsVisibleNewOrgModal]);

  const handleOkModal = useCallback(() => {
    setIsVisibleNewOrgModal(false);
    setIsVisibleEditOrgModal(false);
  }, [setIsVisibleNewOrgModal]);

  const handleCancelModal = useCallback(() => {
    setIsVisibleNewOrgModal(false);
    setIsVisibleEditOrgModal(false);
  }, [setIsVisibleNewOrgModal]);

  const handleEditOrganization = useCallback(() => {
    setIsVisibleEditOrgModal(true);
  }, [setIsVisibleNewOrgModal]);

  const onFinish = async ({ organization }) => {
    await OrganizationsServices.updateOrganization(
      { organizationId: organization, id: organizationRoleId },
      {
        errorNotification: errorNotification(formatMessage),
        afterUpdate: () => {
          setCurrentStep(1);
        },
      }
    );
  };

  if (loadingData) {
    return <OrganizationLoading />;
  }

  return (
    <Row gutter={[24, 12]}>
      <Col span={24}>
        <StyledTitle>
          <FormattedMessage
            id="OrganizationStep0.description"
            defaultMessage={`Quickstream connects organizations to deliver live feeds and
            linear channels anywhere in world in a quick, secure and a reliable way`}
          />
          .
        </StyledTitle>
      </Col>
      <Col span={24}>
        <Form
          initialValues={{ organization: organizationId }}
          name="organizationStep0"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Row justify="center" gutter={24}>
            <Col sm={24} md={12}>
              <Tooltip
                placement="top"
                title={
                  organizationData ? (
                    <FormattedMessage
                      id="OrganizationStep0.disabledChange"
                      // eslint-disable-next-line max-len
                      defaultMessage="In order to change the organization you need to leave the current one"
                    />
                  ) : null
                }
              >
                <FromItem
                  label={<FormattedMessage id="OrganizationStep0.organization" defaultMessage="Organization" />}
                  name="organization"
                  rules={[required]}
                >
                  <Select
                    disabled={organizationData}
                    showSearch
                    optionFilterProp="children"
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        {canCreateNewOrganization && !isAdminOrSupport && (
                          <>
                            <Divider style={{ margin: "8px 0" }} />
                            <Space align="center" style={{ padding: "0 8px 4px" }}>
                              <StyledLink onClick={handleShowModal} style={{ whiteSpace: "nowrap" }}>
                                <PlusCircleOutlined />{" "}
                                <FormattedMessage
                                  id="OrganizationStep0.addOrganization"
                                  defaultMessage="Add new organization"
                                />
                              </StyledLink>
                              <FormattedMessage
                                id="OrganizationStep0.addOrganizationInfo"
                                defaultMessage="(cannot find your organization?)"
                              />
                            </Space>
                          </>
                        )}
                      </>
                    )}
                  >
                    {organizationsPublicList.map((organization) => {
                      const isOwner = accountId === organization.creator;

                      return (
                        <Option value={organization.id} key={organization.id}>
                          {organization.name}
                          {isOwner && (
                            <b>
                              {" "}
                              (
                              <FormattedMessage id="OrganizationStep0.owner" defaultMessage="Owner" />)
                            </b>
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                </FromItem>
              </Tooltip>
            </Col>
            <Row sm={24} md={2}>
              {selectedOrganizationData && (
                <Button
                  onClick={() => setIsVisibleOrgDetailModal(true)}
                  style={{ marginRight: 5 }}
                  icon={<ProfileOutlined />}
                >
                  <span>
                    <FormattedMessage id="OrganizationInformation.showDetail" defaultMessage="Show detail" />
                  </span>
                </Button>
              )}
              {(isOrganizationOwner || isAdmin) && (
                <Button type="primary" onClick={handleEditOrganization}>
                  {formatMessage(globalTranslation.edit)}
                </Button>
              )}
              {canCreateNewOrganization && !isAdminOrSupport && (
                <Button onClick={handleShowModal} style={{ marginLeft: 5 }} icon={<PlusCircleOutlined />}>
                  <span>
                    <FormattedMessage id="OrganizationInformation.addNew" defaultMessage="Add new" />
                  </span>
                </Button>
              )}
            </Row>
          </Row>

          <OrganizationStep0Nav isSelectedOrganizationData={!!selectedOrganizationData} />
        </Form>
      </Col>
      <Modal
        open={isVisibleOrgDetailModal}
        footer={null}
        onCancel={() => setIsVisibleOrgDetailModal(false)}
        width={1200}
      >
        <OrganizationDetail selectedOrganizationData={selectedOrganizationData} />
      </Modal>

      <Modal
        title={<FormattedMessage id="OrganizationStep1.newOrganizationModalTitle" defaultMessage="New organization" />}
        open={isVisibleNewOrgModal}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        footer={null}
        width={1000}
      >
        <OrganizationForm setModalVisible={setIsVisibleNewOrgModal} setOrganization={setFieldsValue} />
      </Modal>

      {isVisibleEditOrgModal && (
        <Modal
          closable={false}
          maskClosable={false}
          title={
            <FormattedMessage id="OrganizationStep1.editOrganizationModalTitle" defaultMessage="Edit organization" />
          }
          open={isVisibleEditOrgModal}
          onOk={handleOkModal}
          onCancel={handleCancelModal}
          footer={null}
          width={1000}
        >
          <OrganizationForm
            setModalVisible={setIsVisibleEditOrgModal}
            setFieldsValue={setFieldsValue}
            selectedOrganizationData={selectedOrganizationData}
          />
        </Modal>
      )}
    </Row>
  );
};

const StyledTitle = styled.h3`
  text-align: center;
`;

const StyledLink = styled.a`
  color: ${themeColor.yellowLink};

  &:hover {
    color: ${themeColor.yellowLinkHover};
  }
`;

OrganizationStep0.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
};

export default OrganizationStep0;
