import React from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

const translations = defineMessages({
  labelTooltip: {
    id: "OverheadBandwidthLabel.labelTooltip",
    defaultMessage:
      // eslint-disable-next-line max-len
      "The maximum bandwidth SRT protocol will use in case of retransmission. If for example, your stream is 8Mbit (total with audio/video) and bandwidth overhead is 25% (default value) your maximum stream bandwidth will equal about  8Mbit + 25% ~= 10 Mbit.",
  },
  label: {
    id: "OverheadBandwidthLabel.label",
    defaultMessage: "Overhead bandwidth (%)",
  },
});

const OverheadBandwidthLabel = () => {
  const { formatMessage } = useIntl();

  return (
    <StyledLabel>
      <span>{formatMessage(translations.label)}</span>
      <Tooltip placement="top" title={formatMessage(translations.labelTooltip)}>
        <QuestionCircleFilled />
      </Tooltip>
    </StyledLabel>
  );
};

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

export default OverheadBandwidthLabel;
