import React, { useContext } from "react";
import { Form, Select, Col } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import InputNumberField from "../../../../Fields/InputNumberField";

import { SELECT_STREAM_TYPE } from "../../../../../lib/utils/constants";
import { required } from "../../../../../lib/utils/formValidation";

import { FormV5Context } from "../../..";

const { Item } = Form;
const { Option } = Select;

function SelectStream({ disabled, fieldPrefix, prefix, label }) {
  const form = useContext(FormV5Context);

  const selectedStream = Form.useWatch([...prefix, "streamId"], form);

  const isPIDSelected = selectedStream === SELECT_STREAM_TYPE[0].value;
  const isAutoSelected = selectedStream === SELECT_STREAM_TYPE[1].value;

  return (
    <>
      <Col span={12}>
        <Item name={[...fieldPrefix, "streamId"]} label={label} rules={[required]}>
          <Select disabled={disabled}>
            {SELECT_STREAM_TYPE.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      {!isAutoSelected && (
        <Col span={12}>
          {isPIDSelected && (
            <InputNumberField
              disabled={disabled}
              name={[...fieldPrefix, "pid"]}
              label={<FormattedMessage id="SelectStream.pid" defaultMessage="PID" />}
              rules={[required]}
              min={32}
              max={8186}
            />
          )}
        </Col>
      )}
    </>
  );
}

SelectStream.propTypes = {
  label: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

SelectStream.defaultProps = {
  disabled: null,
  prefix: null,
  fieldPrefix: null,
};

export default SelectStream;
