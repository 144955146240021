import React from "react";
import { Tag } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import themeColor from "../../lib/style/theme";

const LogsV5Label = ({ logValue }) => {
  switch (+logValue) {
    case 0:
      return (
        <StyledTag color={themeColor.logCritical}>
          <FormattedMessage id="Logs.critical" defaultMessage="Critical" />{" "}
        </StyledTag>
      );
    case 1:
      return (
        <StyledTag color={themeColor.logError}>
          <FormattedMessage id="Logs.error" defaultMessage="Error" />{" "}
        </StyledTag>
      );
    case 2:
      return (
        <StyledTag color={themeColor.logWarning}>
          <FormattedMessage id="Logs.warning" defaultMessage="Warning" />{" "}
        </StyledTag>
      );
    case 3:
      return (
        <StyledTag color={themeColor.logInfo}>
          <FormattedMessage id="Logs.info" defaultMessage="Info" />{" "}
        </StyledTag>
      );
    case 4:
      return (
        <StyledTag color={themeColor.logDebug}>
          <FormattedMessage id="Logs.debug" defaultMessage="Debug" />{" "}
        </StyledTag>
      );
    default:
      return (
        <StyledTag color={themeColor.maritime}>
          <FormattedMessage id="Logs.verbose" defaultMessage="Verbose" />{" "}
        </StyledTag>
      );
  }
};

const StyledTag = styled(Tag)`
  color: #000 !important;
`;

export default LogsV5Label;
