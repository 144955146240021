import React, { useCallback } from "react";
import { Tooltip, Button, Popconfirm } from "antd";
import { DeleteOutlined, QuestionCircleOutlined, ShareAltOutlined, RetweetOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import CloudChannelsServices from "../../services/cloudChannels";

import { errorNotification, successNotification } from "../../lib/utils/notification";

import EditSharedChannelModal from "./ChannelModal/EditSharedChannelModal";
import HubChannelUseOnNodeAction from "./HubChannelUseOnNodeAction";

export default function HubChannelListActions({
  channelName,
  cwid,
  cloudId,
  channelId,
  setTransferVCModalOpen,
  setSelectedTransferCloudId,
  transferVCModalOpen,
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const handleShowHideModal = useCallback(() => {
    setTransferVCModalOpen(!transferVCModalOpen);
    setSelectedTransferCloudId(cloudId);
  }, [transferVCModalOpen, setTransferVCModalOpen, setSelectedTransferCloudId]);

  const removeChannel = () => {
    CloudChannelsServices.removeChannel(cloudId, {
      errorNotification: errorNotification(formatMessage),
      successNotification: successNotification(formatMessage),
    });
  };

  return (
    <>
      <HubChannelUseOnNodeAction cwid={cwid} channelName={channelName} cloudId={cloudId} />
      <EditSharedChannelModal cloudId={cloudId} />
      <StyledButton icon={<ShareAltOutlined />} onClick={() => navigate(`/virtual-channel/own/${channelId}`)}>
        <span>
          <FormattedMessage id="ChannelDetailView.share" defaultMessage="Share" />
        </span>
      </StyledButton>
      <Tooltip
        placement="top"
        title={
          cwid ? (
            <FormattedMessage
              id="ChannelDetailView.transferHubDisabled"
              defaultMessage="Remove hub channel from node to transfer"
            />
          ) : null
        }
      >
        <StyledButton icon={<RetweetOutlined />} onClick={handleShowHideModal} disabled={cwid}>
          <span>
            <FormattedMessage id="ChannelDetailView.transfer" defaultMessage="Transfer" />
          </span>
        </StyledButton>
      </Tooltip>
      <Popconfirm
        title={
          <FormattedMessage
            id="ActionButtons.confirmRemove"
            defaultMessage="Are you sure, you want to remove this channel?"
          />
        }
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        onConfirm={removeChannel}
        okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
        cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
      >
        <StyledButton type="primary" danger icon={<DeleteOutlined />}>
          <span>
            <FormattedMessage id="general.remove" defaultMessage="Remove" />
          </span>
        </StyledButton>
      </Popconfirm>
    </>
  );
}

const StyledButton = styled(Button)`
  margin: 3px 5px;
`;

HubChannelListActions.propTypes = {
  channelName: PropTypes.string.isRequired,
  cwid: PropTypes.string,
  cloudId: PropTypes.number.isRequired,
  channelId: PropTypes.number.isRequired,
  setTransferVCModalOpen: PropTypes.func.isRequired,
  setSelectedTransferCloudId: PropTypes.func.isRequired,
  transferVCModalOpen: PropTypes.bool.isRequired,
};

HubChannelListActions.defaultProps = {
  cwid: null,
};
