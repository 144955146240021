import React, { useCallback, useEffect } from "react";
import { LockOutlined } from "@ant-design/icons";
import { Input, Button, Form, Card } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import useMainTheme from "../../hooks/useMainTheme";

import AccountService from "../../services/account";
import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

import { errorNotification, successNotification } from "../../lib/utils/notification";
import { max32characters, password } from "../../lib/utils/formValidation";
import logoUrl from "../../lib/images/logo.png";
import logoLightUrl from "../../lib/images/logo-light.png";
import parseJwt from "../../lib/utils/parseJwt";

import TurnstileValidation from "../TurnstileValidation";
import ReturnSignInFooter from "../ReturnSignInFooter";

const { Item } = Form;
const { Password } = Input;

const translations = defineMessages({
  confirmPassword: {
    id: "ChangePasswordForm.confirmPassword",
    defaultMessage: "Confirm new password",
  },
  confirmPasswordNoInput: {
    id: "ChangePasswordForm.validationConfirmPassword",
    defaultMessage: "Please input confirmation password",
  },
  confirmPasswordNotIdentical: {
    id: "ChangePasswordForm.validationNotIdentical",
    defaultMessage: "Confirmation password need to be the same as password",
  },
  password: {
    id: "ChangePasswordForm.password",
    defaultMessage: "New password",
  },
  passwordNoInput: {
    id: "ChangePasswordForm.validationNoPassword",
    defaultMessage: "Please input password",
  },
  passwordTooShort: {
    id: "ChangePasswordForm.validationMinLength",
    defaultMessage: "Password must be at least 8 characters long",
  },
  returnSignInLinkTitle: {
    id: "ChangePasswordForm.returnSignInLinkTitle",
    defaultMessage: "Back to",
  },
  tokenExpired: {
    id: "ChangePasswordForm.tokenExpired",
    defaultMessage:
      "Yours link has expired (20 min expiration time), please generate new one to change account password",
  },
});

const ChangePasswordForm = () => {
  const { resetLink } = useParams();
  const [form] = Form.useForm();
  const { getFieldValue } = form;
  const { formatMessage } = useIntl();
  const { isDarkTheme } = useMainTheme();
  const navigate = useNavigate();
  const turnstileToken = useSelector(ACCOUNT_SELECTORS.getTurnstileToken);

  useEffect(() => {
    const jwtToken = parseJwt(resetLink);
    const utcTime = moment.utc().unix();
    if (utcTime > jwtToken.exp) {
      errorNotification(formatMessage)(translations.tokenExpired);
      navigate("/login");
    }
  }, [resetLink, formatMessage]);

  const handleFinish = useCallback(
    async ({ newPassword }) => {
      const data = {
        newPassword,
        resetLink,
        turnstileToken,
      };
      AccountService.resetPassword(data, {
        errorNotification: errorNotification(formatMessage),
        successNotification: successNotification(formatMessage),
      });
    },
    [formatMessage, resetLink, turnstileToken]
  );

  const validatePassword = useCallback(
    (rule, value) => {
      const passwordValue = getFieldValue("newPassword");
      if (!value) {
        return Promise.reject(formatMessage(translations.confirmPasswordNoInput));
      }
      if (value !== passwordValue) {
        return Promise.reject(formatMessage(translations.confirmPasswordNotIdentical));
      }

      return Promise.resolve();
    },
    [formatMessage, getFieldValue]
  );

  return (
    <StyledFormContainer>
      <Card
        title={<FormattedMessage id="ChangePasswordForm.changePassword" defaultMessage="Change password" />}
        extra={<img src={isDarkTheme ? logoUrl : logoLightUrl} alt="quickstream logo" width="200" />}
      >
        <StyledForm onFinish={handleFinish} form={form}>
          <Item
            name="newPassword"
            rules={[
              { required: true, message: formatMessage(translations.passwordNoInput) },
              { min: 8, message: formatMessage(translations.passwordTooShort) },
              password,
              max32characters,
            ]}
          >
            <Password
              prefix={<LockOutlined />}
              type="password"
              autoComplete="new-password"
              placeholder={formatMessage(translations.password)}
            />
          </Item>
          <Item name="confirmPassword" rules={[{ validator: validatePassword }, password]}>
            <Password
              prefix={<LockOutlined />}
              type="password"
              autoComplete="new-password"
              placeholder={formatMessage(translations.confirmPassword)}
            />
          </Item>
          <StyledButton type="primary" htmlType="submit" loading={!turnstileToken}>
            <FormattedMessage id="ChangePasswordForm.submit" defaultMessage="Change password" />
          </StyledButton>
        </StyledForm>
        <TurnstileValidation />
      </Card>
      <ReturnSignInFooter linkTitle={formatMessage(translations.returnSignInLinkTitle)} />
    </StyledFormContainer>
  );
};

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
`;

const StyledForm = styled(Form)`
  min-width: 450px;
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ChangePasswordFormContainer = () => {
  return <ChangePasswordForm />;
};

export default ChangePasswordFormContainer;
