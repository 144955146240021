import React from "react";

import useNodeVersion from "../../../hooks/useNodeVersion";
import useSetNodeConnection from "../../../hooks/useSetNodeConnection";
import useNodeChannels from "../../../hooks/useNodeChannels";
import useRequestNodeLiveStats from "../../../hooks/useRequestNodeLiveStats";

import Logs from "../../../components/Logs";
import LogsV5 from "../../../components/LogsV5";

export default function NodeLogs() {
  const { isV5Node, isV4Node } = useNodeVersion();
  useSetNodeConnection();
  useNodeChannels();
  useRequestNodeLiveStats();

  return (
    <>
      {isV5Node && <LogsV5 />}
      {isV4Node && <Logs />}
    </>
  );
}
