import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import { thunk } from "redux-thunk";
import rootReducer from "./ducks";

export const history = createBrowserHistory();

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(rootReducer, enhancer);

export default store;
