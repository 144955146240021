import React from "react";
import { Select, Form } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { selectors as NODE_SELECTORS } from "../../ducks/node";

import globalTranslations from "../../lib/translations";

const { Item } = Form;

function NewNodeChannelType({ disabled, hidden }) {
  const { formatMessage } = useIntl();
  const isPlayout = useSelector(NODE_SELECTORS.getPlayoutCapabilities);

  return (
    <Item
      label={<FormattedMessage id="NewNodeChannelType.type" defaultMessage="Type" />}
      name="type"
      rules={[{ required: true, message: formatMessage(globalTranslations.required) }]}
      hidden={hidden}
    >
      <Select
        disabled={disabled}
        options={[
          {
            value: "direct",
            label: <FormattedMessage id="NewNodeChannelType.direct" defaultMessage="Direct" />,
          },
          {
            value: "transcoding",
            label: <FormattedMessage id="NewNodeChannelType.transcoding" defaultMessage="Transcoding" />,
          },
          ...(isPlayout
            ? [
                {
                  value: "playout",
                  label: <FormattedMessage id="NewNodeChannelType.playout" defaultMessage="Playout" />,
                },
              ]
            : []),
        ]}
      />
    </Item>
  );
}

NewNodeChannelType.propTypes = {
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

NewNodeChannelType.defaultProps = {
  disabled: false,
  hidden: false,
};

export default NewNodeChannelType;
