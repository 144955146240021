import React from "react";
import { Col, Card, Row, Form } from "antd";
import { useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import ImmutablePropTypes from "react-immutable-proptypes";

import SelectOutputType from "../../../SelectOutputType";

import { OUTPUTS_TYPES } from "../../../../lib/utils/constants";

import { selectors as NODE_CHANNEL_SELECTORS } from "../../../../ducks/nodeChannels";

import RemoveButton from "../../../RemoveButton";
import OutputNDIFormItem from "../../../OutputNDIFormItem";
import OutputURLFormItem from "../../../OutputURLFormItem";
import OutputRTMPFormItem from "../../../OutputRTMPFormItem";

const translations = defineMessages({
  confirmRemoveText: {
    id: "MuxedSectionFormItem.confirmRemove",
    defaultMessage: "Are you sure, you want to remove this output?",
  },
});

const MuxedSectionFormItem = ({
  detailView,
  handleFinish,
  handleRemove,
  id,
  isNewMuxedOutput,
  nodeChannels,
  muxedOutput,
  prefix,
  form,
}) => {
  const { formatMessage } = useIntl();
  const selectedOutputType = Form.useWatch([...prefix, "type"], form);
  const isOutputNIDType = selectedOutputType === OUTPUTS_TYPES.outputNdi.value;
  const isOutputUrlType = selectedOutputType === OUTPUTS_TYPES.outputUrl.value;
  const isOutputRTMPType = selectedOutputType === OUTPUTS_TYPES.outputRtmp.value;

  return (
    <StyledCard
      title={muxedOutput?.name}
      extra={
        muxedOutput?.name &&
        !detailView && (
          <RemoveButton
            handleRemove={handleRemove}
            fieldName={muxedOutput.name}
            confirmRemoveText={formatMessage(translations.confirmRemoveText)}
          />
        )
      }
    >
      <Row type="flex" justify="start" gutter={[24, 24]}>
        <Col span={12}>
          <SelectOutputType prefix={prefix} form={form} disabled={detailView} />
        </Col>
        {isOutputRTMPType && (
          <OutputRTMPFormItem
            disabled={detailView}
            prefix={prefix}
            form={form}
            channelId={id}
            muxedOutput={muxedOutput}
          />
        )}
        {isOutputNIDType && (
          <OutputNDIFormItem
            detailView={detailView}
            prefix={prefix}
            form={form}
            channelId={id}
            initialOutputName={muxedOutput?.name}
          />
        )}
        {isOutputUrlType && (
          <OutputURLFormItem
            form={form}
            prefix={prefix}
            muxedOutput={muxedOutput}
            isNewMuxedOutput={isNewMuxedOutput}
            handleFinish={handleFinish}
            channelId={id}
            nodeChannels={nodeChannels}
            detailView={detailView}
          />
        )}
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-top: 10px;
`;

MuxedSectionFormItem.propTypes = {
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  id: PropTypes.string,
  isNewMuxedOutput: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  muxedOutput: PropTypes.object,
  nodeChannels: ImmutablePropTypes.map.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

MuxedSectionFormItem.defaultProps = {
  detailView: null,
  id: null,
  muxedOutput: null,
};

const mapStateToProps = (state) => ({
  nodeChannels: NODE_CHANNEL_SELECTORS.getChannels(state),
});

export default connect(mapStateToProps, null)(MuxedSectionFormItem);
