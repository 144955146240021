import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Form, Radio } from "antd";
import PropTypes from "prop-types";

import themeColor from "../../lib/style/theme";
import { required } from "../../lib/utils/formValidation";

const { Item } = Form;
const { Group } = Radio;

const EmailOptIn = ({ setEmailOptIn }) => {
  return (
    <>
      <StyledTitle>
        <FormattedMessage id="RegistrationForm.emailOptIn" defaultMessage="Email opt-in" />
      </StyledTitle>
      <StyledSendInfo>
        <FormattedMessage
          id="EmailOptIn.sendEmails"
          // eslint-disable-next-line max-len
          defaultMessage="We will send you only important information related to Quickstream. We promise to respect your inbox and send"
        />
        <StyledText>
          <FormattedMessage id="EmailOptIn.max1Email" defaultMessage="maximum 1 email per month" />
        </StyledText>
        .
      </StyledSendInfo>
      <StyledItem name="emailOptIn" rules={[required]}>
        <Group onChange={() => setEmailOptIn(true)}>
          <Radio value>
            <FormattedMessage id="EmailOptIn.emailOptInYes" defaultMessage="Yes, I want to stay in touch" />.
          </Radio>
          <Radio value={false}>
            <FormattedMessage id="EmailOptIn.emailOptInNo" defaultMessage="No, I don't want to stay in touch" />.
          </Radio>
        </Group>
      </StyledItem>
    </>
  );
};

const StyledSendInfo = styled.span`
  font-size: 13px;
`;

const StyledItem = styled(Item)`
  margin-top: 10px;
`;

const StyledTitle = styled.h3`
  color: ${themeColor.greyTitle};
`;

const StyledText = styled.span`
  margin: 0 0 0 5px;
  color: ${themeColor.orange};
`;

EmailOptIn.propTypes = {
  setEmailOptIn: PropTypes.func.isRequired,
};

export default EmailOptIn;
