import { createAction, handleActions } from "redux-actions";
import { Map, List } from "immutable";
import { createSelector } from "reselect";

import { MESSAGE_TYPE } from "../lib/utils/constants";

export const actions = {
  SET_NEW_NODE_NOTIFICATION: createAction("NODE/SET_NEW_NODE_NOTIFICATION"),
  SET_NOTIFICATION_VIEW_ACTIVE: createAction("NODE/SET_NOTIFICATION_VIEW_ACTIVE"),
  SET_NOTIFICATION_VIEW_DEACTIVATED: createAction("NODE/SET_NOTIFICATION_VIEW_DEACTIVATED"),
  SET_NOTIFICATION_ACTIVE_STATE: createAction("NODE/SET_NOTIFICATION_ACTIVE_STATE"),
  SET_ACTIVE_NOTIFICATION: createAction("NODE/SET_ACTIVE_NOTIFICATION"),
  REMOVE_ACTIVE_NOTIFICATION: createAction("NODE/REMOVE_ACTIVE_NOTIFICATION"),
  CLEAR_NODE_UNREAD_NOTIFICATIONS: createAction("NODE/CLEAR_NODE_UNREAD_NOTIFICATIONS"),
  CLEAR_NODE_NOTIFICATIONS: createAction("NODE/CLEAR_NODE_NOTIFICATIONS"),
};

const initialState = new Map({
  showPopup: window.localStorage.getItem("showPopupNotification")
    ? window.localStorage.getItem("showPopupNotification") === "true"
    : true,
  isNotificationViewActive: false,
  error: new Map({}),
  warning: new Map({}),
  unreadwarningCounter: 0,
  unreaderrorCounter: 0,
  activeNotifications: new List([]),
});

const reducer = handleActions(
  {
    [actions.SET_NEW_NODE_NOTIFICATION]: (state, { payload }) => {
      const stateUnreadwarningCounter = state.get("unreadwarningCounter");
      const stateUnreaderrorCounter = state.get("unreaderrorCounter");
      const stateInNotificationViewActive = state.get("isNotificationViewActive");
      const parsedChannelId = payload.channelId >= 1 ? payload.channelId : 0;
      const parsedChannelName = payload?.path ? payload.path[0] : payload.channelId;

      const isError = payload.msgType === MESSAGE_TYPE.ERROR;
      const parsedCode = isError ? payload?.errorCode : payload?.warningCode;

      const parsedState = state.updateIn(
        [`${payload.msgType}`, `${payload?.cnn}`, `${parsedChannelName}`, `${parsedCode}`],
        (oldData) => {
          const parsedErrorData = {
            channelId: parsedChannelId,
            channelName: parsedChannelName,
            cnn: payload?.cnn,
            cwid: payload?.from,
            ...(isError ? { errorCode: parsedCode } : { warningCode: parsedCode }),
            msgType: payload?.msgType,
            path: payload?.path,
            time: payload?.time,
          };

          return oldData ? { ...parsedErrorData, counter: oldData.counter + 1 } : { ...parsedErrorData, counter: 1 };
        }
      );

      const activeTabType = stateInNotificationViewActive && stateInNotificationViewActive.split("&")[0];
      const activeTab = stateInNotificationViewActive && stateInNotificationViewActive.split("&")[1];

      const updateCounterState = parsedState.updateIn(
        [`${payload.msgType}`, `${payload?.cnn}`, "unreadCounter"],
        (oldData) => {
          if (activeTab === payload?.cnn && activeTabType === payload?.msgType) {
            return 0;
          }

          return oldData ? oldData + 1 : 1;
        }
      );

      if (activeTab === payload?.cnn && activeTabType === payload?.msgType) {
        return updateCounterState;
      }

      if (payload.msgType === MESSAGE_TYPE.WARNING) {
        return updateCounterState.merge({
          unreadwarningCounter: stateUnreadwarningCounter + 1,
        });
      }

      if (payload.msgType === MESSAGE_TYPE.ERROR) {
        return updateCounterState.merge({
          unreaderrorCounter: stateUnreaderrorCounter + 1,
        });
      }

      return updateCounterState;
    },
    [actions.SET_ACTIVE_NOTIFICATION]: (state, { payload }) => {
      const notificationIsActive = state.get("activeNotifications").includes(payload);
      if (notificationIsActive) {
        return state;
      }

      return state.updateIn(["activeNotifications"], (array) => array.push(payload));
    },
    [actions.REMOVE_ACTIVE_NOTIFICATION]: (state, { payload }) => {
      const notificationIsActive = state.get("activeNotifications").includes(payload);
      if (notificationIsActive) {
        return state.updateIn(["activeNotifications"], (array) =>
          array.filter((notification) => notification !== payload)
        );
      }

      return state;
    },
    [actions.CLEAR_NODE_UNREAD_NOTIFICATIONS]: (state, { payload }) => {
      const stateTypeCounter = state.get(`unread${payload?.activeTabType}Counter`);
      const stateNodeCounter = state.getIn([`${payload?.activeTabType}`, `${payload?.activeTab}`, "unreadCounter"]);

      const updatedErrorTypeCounter = stateTypeCounter
        ? state.set(`unread${payload?.activeTabType}Counter`, stateTypeCounter - stateNodeCounter)
        : state;

      // Update unread count state for node
      const parsedState = stateNodeCounter
        ? updatedErrorTypeCounter.setIn([`${payload?.activeTabType}`, `${payload?.activeTab}`, "unreadCounter"], 0)
        : updatedErrorTypeCounter;

      return parsedState.set("unreadCounter", 0);
    },
    [actions.SET_NOTIFICATION_VIEW_ACTIVE]: (state, { payload }) => {
      return state.set("isNotificationViewActive", payload);
    },
    [actions.SET_NOTIFICATION_VIEW_DEACTIVATED]: (state) => state.set("isNotificationViewActive", false),
    [actions.SET_NOTIFICATION_ACTIVE_STATE]: (state, { payload }) => {
      window.localStorage.setItem("showPopupNotification", payload);

      return state.set("showPopup", payload);
    },
    [actions.CLEAR_NODE_NOTIFICATIONS]: () => {
      const showPopup = window.localStorage.getItem("showPopupNotification")
        ? window.localStorage.getItem("showPopupNotification") === "true"
        : true;

      return initialState.set("showPopup", showPopup);
    },
  },
  initialState
);

export const selectors = {
  getErrors: (state) => state.getIn(["nodeNotifications", "error"]),
  getWarnings: (state) => state.getIn(["nodeNotifications", "warning"]),
  getWarningsCounter: (state) => state.getIn(["nodeNotifications", "unreadwarningCounter"]),
  getErrorCounter: (state) => state.getIn(["nodeNotifications", "unreaderrorCounter"]),
  getCounter: (state) => {
    const unreadwarningCounter = state.getIn(["nodeNotifications", "unreadwarningCounter"]);
    const unreaderrorCounter = state.getIn(["nodeNotifications", "unreaderrorCounter"]);

    return unreadwarningCounter + unreaderrorCounter;
  },
  getShowPopup: (state) => state.getIn(["nodeNotifications", "showPopup"]),
};

export const getErrorsTabsSelector = createSelector([selectors.getErrors], (errorsMap) => {
  return Object.keys(errorsMap.toJS());
});

export const getWarningsTabsSelector = createSelector([selectors.getWarnings], (warningsMap) => {
  return Object.keys(warningsMap.toJS());
});

export const getAllErrors = createSelector([selectors.getErrors], (errorsMap) => {
  const allErrors = Object.values(errorsMap.toJS())
    .flatMap((value) => value)
    .reverse();

  return allErrors;
});

export const getAllWarnings = createSelector([selectors.getWarnings], (warningsMap) => {
  const allWarnings = Object.values(warningsMap.toJS())
    .flatMap((value) => value)
    .reverse();

  return allWarnings;
});

export default reducer;
