import React, { useEffect } from "react";
import { Drawer, Button, Divider, Card, Spin, Empty } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import store from "../../store";
import MQTTService from "../../services/mqtt";
import { selectors as PROBE_SELECTORS, SET_PROBE_LOADING, CLEAR_PROBE } from "../../ducks/probe";

import { INPUT_STREAM_TYPE, MESSAGE_TYPE, ENGINE_TYPES } from "../../lib/utils/constants";

import ProbeDrawerElement from "./ProbeDrawerElement";

function ProbeDrawerV5({
  getFieldValue,
  isBackup,
  loadingProbe,
  probeBackupData,
  probeMainData,
  setShowDrawer,
  showDrawer,
  validateFields,
  type,
}) {
  useEffect(() => {
    let probeTimeout;

    if (loadingProbe) {
      probeTimeout = setTimeout(() => {
        store.dispatch(SET_PROBE_LOADING(false));
      }, 20000);
    }

    return () => {
      clearTimeout(probeTimeout);
    };
  }, [loadingProbe]);

  const handleCloseDrawer = () => {
    store.dispatch(CLEAR_PROBE());

    setShowDrawer(false);
  };

  const { cwid } = useParams();

  const probeRequest = async (probeType) => {
    try {
      await validateFields(["input"]);

      // Old engine type <= 5.X
      const isOldNodeEngine = !type;
      const isDirectEngine = type === ENGINE_TYPES.DIRECT;
      const isTranscoding = type === ENGINE_TYPES.TRANSCODING;
      const isMainProbe = probeType === INPUT_STREAM_TYPE.main;
      const isBackupProbe = probeType === INPUT_STREAM_TYPE.backup;

      if (probeType === INPUT_STREAM_TYPE.main && isOldNodeEngine) {
        const { backup, ...inputConfig } = getFieldValue("input");

        MQTTService.sendMessageV5({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.PROBE_STREAM,
            input: inputConfig,
            type: probeType,
          },
        });

        store.dispatch(SET_PROBE_LOADING(true));
      }

      if (probeType === INPUT_STREAM_TYPE.backup && isOldNodeEngine) {
        const backupConfig = getFieldValue(["input", "backup", "backupConnection"]);

        MQTTService.sendMessageV5({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.PROBE_STREAM,
            input: backupConfig,
            type: probeType,
          },
        });

        store.dispatch(SET_PROBE_LOADING(true));
      }

      if (isTranscoding && isMainProbe) {
        const mainInput = getFieldValue("mainInput");

        MQTTService.sendMessageV5({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.PROBE_STREAM,
            input: mainInput,
            type: probeType,
          },
        });

        store.dispatch(SET_PROBE_LOADING(true));
      }

      if (isTranscoding && isBackupProbe) {
        const backupInput = getFieldValue("backupInput");

        MQTTService.sendMessageV5({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.PROBE_STREAM,
            input: backupInput,
            type: probeType,
          },
        });

        store.dispatch(SET_PROBE_LOADING(true));
      }

      if (isDirectEngine && isMainProbe) {
        const { mainConnection, type: inputType } = getFieldValue("input");

        MQTTService.sendMessageV5({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.PROBE_STREAM,
            input: {
              inputStreamCfg: mainConnection,
              type: inputType,
            },
            type: probeType,
          },
        });

        store.dispatch(SET_PROBE_LOADING(true));
      }

      if (isDirectEngine && isBackupProbe) {
        const { backupConnection, type: backupType } = getFieldValue("input");

        MQTTService.sendMessageV5({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.PROBE_STREAM,
            input: {
              inputStreamCfg: backupConnection,
              type: backupType,
            },
            type: probeType,
          },
        });

        store.dispatch(SET_PROBE_LOADING(true));
      }

      setShowDrawer(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Validation Error", error);
    }
  };

  return (
    <Drawer
      title={<FormattedMessage id="ProbeDrawerV5.probeStream" defaultMessage="Probe stream" />}
      placement="right"
      onClose={handleCloseDrawer}
      open={showDrawer}
      width="60%"
      closable
    >
      <Spin tip={<FormattedMessage id="ProbeDrawerV5.probing" defaultMessage="Probing..." />} spinning={loadingProbe}>
        <Card
          title={<FormattedMessage id="ProbeDrawerV5.mainStream" defaultMessage="Main Stream" />}
          extra={
            <Button
              type="primary"
              onClick={() => probeRequest(INPUT_STREAM_TYPE.main)}
              icon={probeMainData ? <ReloadOutlined /> : <SearchOutlined />}
            >
              <span>
                {!probeMainData && <FormattedMessage id="ProbeDrawerV5.probe" defaultMessage="Probe" />}
                {probeMainData && <FormattedMessage id="ProbeDrawerV5.reloadProbe" defaultMessage="Reload probe" />}
              </span>
            </Button>
          }
        >
          {!probeMainData && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          {probeMainData && <ProbeDrawerElement data={probeMainData} />}
        </Card>
        {isBackup && (
          <>
            <Divider />
            <Card
              title={<FormattedMessage id="ProbeDrawerV5.backupStream" defaultMessage="Backup Stream" />}
              extra={
                <Button
                  type="primary"
                  onClick={() => probeRequest(INPUT_STREAM_TYPE.backup)}
                  icon={probeBackupData ? <ReloadOutlined /> : <SearchOutlined />}
                >
                  <span>
                    {!probeBackupData && <FormattedMessage id="ProbeDrawerV5.probe" defaultMessage="Probe" />}
                    {probeBackupData && (
                      <FormattedMessage id="ProbeDrawerV5.reloadProbe" defaultMessage="Reload probe" />
                    )}
                  </span>
                </Button>
              }
            >
              {!probeBackupData && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              {probeBackupData && <ProbeDrawerElement data={probeBackupData} />}
            </Card>
          </>
        )}
      </Spin>
    </Drawer>
  );
}

ProbeDrawerV5.propTypes = {
  type: PropTypes.string.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  isBackup: PropTypes.bool.isRequired,
  loadingProbe: PropTypes.bool.isRequired,
  probeBackupData: PropTypes.object,
  probeMainData: PropTypes.object,
  setShowDrawer: PropTypes.func.isRequired,
  showDrawer: PropTypes.bool.isRequired,
  validateFields: PropTypes.func.isRequired,
};

ProbeDrawerV5.defaultProps = {
  probeBackupData: null,
  probeMainData: null,
};

const mapStateToProps = (state) => ({
  loadingProbe: PROBE_SELECTORS.getProbeLoading(state),
  probeMainData: PROBE_SELECTORS.getMainProbe(state),
  probeBackupData: PROBE_SELECTORS.getBackupProbe(state),
});

export default connect(mapStateToProps, null)(ProbeDrawerV5);
