import React, { useCallback } from "react";
import { Card, Col, Tooltip, Form, Input, Button, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

import CloudService from "../../../services/cloud";

import { required, max32characters, max1024characters } from "../../../lib/utils/formValidation";
import { errorNotification } from "../../../lib/utils/notification";

const { Item } = Form;

const NodesListClaim = () => {
  const { formatMessage } = useIntl();

  const onFinish = useCallback((formData) => {
    CloudService.claimNode(formData, { errorNotification: errorNotification(formatMessage) });
  }, []);

  return (
    <Col span={24}>
      <Card
        title={
          <Tooltip
            placement="top"
            title={
              <span>
                <FormattedMessage
                  id="NodesListClaim.claimDescription"
                  // eslint-disable-next-line max-len
                  defaultMessage="Would you like to manage your node from cloud? Copy your unique hardware id from node and claim it."
                />
              </span>
            }
          >
            <FormattedMessage id="NodesListClaim.claimNode" defaultMessage="Claim node" />
            <StyledQuestionCircleFilled />
          </Tooltip>
        }
      >
        <Form name="claimNode" onFinish={onFinish} autoComplete="off">
          <Row gutter={12} justify="end">
            <Col sm={24} lg={12}>
              <Item
                label={<FormattedMessage id="NodesListClaim.hardwareId" defaultMessage="Hardware ID" />}
                name="fingerprint"
                rules={[required, max1024characters]}
              >
                <Input />
              </Item>
            </Col>
            <Col sm={24} lg={12}>
              <Item
                label={<FormattedMessage id="NodesListClaim.nodeName" defaultMessage="Node name" />}
                name="cnn"
                rules={[required, max32characters]}
              >
                <Input />
              </Item>
            </Col>
          </Row>
          <Row justify="end">
            <Item>
              <Button type="primary" htmlType="submit">
                <FormattedMessage id="NodesListClaim.submit" defaultMessage="Submit" />
              </Button>
            </Item>
          </Row>
        </Form>
      </Card>
    </Col>
  );
};

const StyledQuestionCircleFilled = styled(QuestionCircleFilled)`
  margin-left: 5px;
`;

export default NodesListClaim;
