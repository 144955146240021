import React from "react";
import { Form, Radio } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const { Item } = Form;

export default function InterlacedField({ prefix, disabled }) {
  const options = [
    {
      label: <FormattedMessage id="GeneralVideoCodec.progressive" defaultMessage="Progressive" />,
      value: 0,
    },
    {
      label: <FormattedMessage id="GeneralVideoCodec.topFieldFirst" defaultMessage="Top field first" />,
      value: 1,
    },
    {
      label: <FormattedMessage id="GeneralVideoCodec.bottomFieldFirst" defaultMessage="Bottom field first" />,
      value: 2,
    },
  ];

  return (
    <Item
      name={[...prefix, "interlaced"]}
      label={<FormattedMessage id="GeneralVideoCodec.interlacedNew" defaultMessage="Interlaced" />}
    >
      <Radio.Group disabled={disabled} options={options} optionType="button" buttonStyle="solid" />
    </Item>
  );
}

InterlacedField.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  disabled: PropTypes.bool.isRequired,
};
