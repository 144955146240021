import React from "react";
import { Form, Select } from "antd";
import { FormattedMessage } from "react-intl";

import LANGUAGES_LIST from "../../../../lib/utils/languages";

const { Item } = Form;
const { Option } = Select;

const VirtualChannelSubtitles = () => {
  return (
    <Item
      label={<FormattedMessage id="VirtualChannelSubtitles.subtitles" defaultMessage="Subtitles" />}
      name="subtitles"
    >
      <Select
        mode="multiple"
        allowClear
        showSearch
        placeholder={<FormattedMessage id="general.pleaseSelect" defaultMessage="Please select" />}
        optionFilterProp="children"
      >
        {LANGUAGES_LIST.map((language) => (
          <Option value={language.code} key={language.code}>
            {language.name} ({language.code.toUpperCase()})
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default VirtualChannelSubtitles;
