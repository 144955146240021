import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Popconfirm, Row, Skeleton, Spin, Table, Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";
import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";
import CloudServices from "../../services/cloud";

import useNodeFingerprint from "../../hooks/useNodeFingerprint";

import themeColor from "../../lib/style/theme";
import { MQTT_STATUS } from "../../lib/utils/constants";
import { errorNotification } from "../../lib/utils/notification";

import GlobalErrorBoundaries from "../../components/ErrorBoundaries/GlobalErrorBoundaries";
import ShareNodeFormModal from "./ShareNodeFormModal";

const ShareNodeForm = ({ userId, MQTTStatus }) => {
  const { cwid, cnn } = useParams();
  const { formatMessage } = useIntl();
  const [nodeShares, setNodesShares] = useState([]);
  const fingerprint = useNodeFingerprint();

  const fetchNodeShares = useCallback(async () => {
    const nodeSharesData = await CloudServices.getNodeShares(cwid, {
      errorNotification: errorNotification(formatMessage),
    });

    const filteredShares = nodeSharesData.filter((share) => share.id !== userId);

    setNodesShares(filteredShares);
  }, [cwid, formatMessage, userId]);

  useEffect(() => {
    const isConnection = cwid && MQTTStatus === MQTT_STATUS.CONNECTED;
    if (isConnection) {
      fetchNodeShares();
    }
  }, [cwid, fetchNodeShares, MQTTStatus]);

  const onConfirmRemove = useCallback(
    async (username) => {
      const filteredNodeShares = nodeShares.filter((nodeUser) => nodeUser.username !== username);
      const response = CloudServices.removeNodeShareForUser(
        { cwid, username },
        { errorNotification: errorNotification(formatMessage), setNodesShares, filteredNodeShares }
      );
      if (response) {
        setNodesShares(filteredNodeShares);
      }
    },
    [formatMessage, cwid, nodeShares]
  );

  const columns = [
    {
      title: <FormattedMessage id="general.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
    },
    {
      key: "actions",
      width: 100,
      title: <FormattedMessage id="general.action" defaultMessage="Action" />,
      render: (text, { username: userData }) => (
        <Popconfirm
          title={
            <>
              <FormattedMessage
                id="ShareNodeForm.confirmRemoveAccess"
                defaultMessage="Are you sure, you want to remove access to this node for user"
              />{" "}
              {userData}?
            </>
          }
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onConfirm={() => onConfirmRemove(userData)}
          okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
          cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
        >
          <Button type="primary" danger icon={<DeleteOutlined />}>
            <span>
              <FormattedMessage id="general.remove" defaultMessage="Remove" />
            </span>
          </Button>
        </Popconfirm>
      ),
    },
  ];

  if (!fingerprint) {
    return (
      <Spin tip={<FormattedMessage id="ShareNodeForm.loadingFingerprint" defaultMessage="Loading fingerprint..." />}>
        <Skeleton active />
      </Spin>
    );
  }

  return (
    <GlobalErrorBoundaries>
      <Card
        title={
          <>
            <FormattedMessage id="ShareNodeForm.manageAccess" defaultMessage="Manage access to node" />
            {`: `}
            <Tooltip
              placement="top"
              title={
                <FormattedMessage
                  id="ShareNodeForm.cnnTooltip"
                  defaultMessage="Node name - you can change node name in the system tab"
                />
              }
            >
              <StyledNodeHostname>{cnn}</StyledNodeHostname>
            </Tooltip>
          </>
        }
      >
        <Row type="flex" justify="center" gutter={12}>
          <Col sm={24} lg={10}>
            <Card
              title={<FormattedMessage id="ShareNodeForm.usersAccess" defaultMessage="Users with access to node" />}
            >
              <Table
                columns={columns}
                dataSource={nodeShares}
                pagination={false}
                bordered
                role="table"
                rowKey={(record) => record.username}
              />
            </Card>
          </Col>
          <Col sm={24} lg={14}>
            <Card
              title={<FormattedMessage id="ShareNodeForm.addNewShareTitle" defaultMessage="Add access for new user" />}
            >
              <ShareNodeFormModal
                cnn={cnn}
                cwid={cwid}
                fetchNodeShares={fetchNodeShares}
                fingerprint={fingerprint}
                nodeShares={nodeShares}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </GlobalErrorBoundaries>
  );
};

const StyledNodeHostname = styled.span`
  color: ${themeColor.orange};
`;

ShareNodeForm.propTypes = {
  MQTTStatus: PropTypes.string,
  userId: PropTypes.number.isRequired,
};

ShareNodeForm.defaultProps = {
  MQTTStatus: null,
};

const mapStateToProps = (state) => ({
  MQTTStatus: MQTT_SELECTORS.getStatus(state),
  userId: ACCOUNT_SELECTORS.getUserId(state),
});

export default connect(mapStateToProps, null)(ShareNodeForm);
