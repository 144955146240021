import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Select, Form } from "antd";

import ConnectionMethodLabel from "../../NodeChannelFormV4/Labels/ConnectionMethodLabel";

import { CONNECTION_METHOD_OUTPUT, QUICKSTREAM_METHOD_V2, LICENSES_TYPES } from "../../../lib/utils/constants";
import defaultNonMuxedOutput from "../../../lib/utils/defaultValues/defaultNonMuxedOutput";
import { required } from "../../../lib/utils/formValidation";

const { Item } = Form;
const { Option } = Select;

const OutputConnectionMethod = ({
  connectionMethod,
  disabled,
  getFieldValue,
  isMuxedOutput,
  isNonMuxedOutput,
  name,
  prefix,
  setConnectionMethod,
  setFieldsValue,
  stundAddress,
}) => {
  const fieldListName = name.length <= 2 ? name[0] : name[1];
  const licenseId = getFieldValue(["licenseId"]);
  const isCloudLicense = licenseId === LICENSES_TYPES.CLOUD;

  const CONNECTION_METHOD_OUTPUT_ARRAY = Object.values({
    ...CONNECTION_METHOD_OUTPUT,
    outQSDirect: QUICKSTREAM_METHOD_V2.outQSDirect,
  });

  const handleChangeConnectionMethod = (value) => {
    if (isNonMuxedOutput) {
      // Clear output - after change connectionMethod
      const defaultOutputValues = {
        ...defaultNonMuxedOutput(value),
        type: value,
        cloudIp: stundAddress,
        autoIp: !!stundAddress,
      };

      const nonMuxedOutputs = getFieldValue(["nonMuxedOutputs"]);
      nonMuxedOutputs[fieldListName] = {
        ...defaultOutputValues,
        outputName: nonMuxedOutputs[fieldListName].outputName,
      };

      setFieldsValue({
        nonMuxedOutputs,
      });
      // End - Clear output
      setConnectionMethod({ ...connectionMethod, [fieldListName]: value });

      return null;
    }

    if (isMuxedOutput) {
      // Clear output - after change connectionMethod
      const defaultOutputValues = {
        ...defaultNonMuxedOutput(value),
        cloudIp: stundAddress,
        autoIp: !!stundAddress,
      };
      const muxedOutputs = getFieldValue(["muxedOutputs"]);
      muxedOutputs[prefix[1]].outputs[prefix[3]] = {
        ...defaultOutputValues,
        outputName: muxedOutputs[prefix[1]].outputs[prefix[3]].outputName,
      };
      setFieldsValue({
        muxedOutputs,
      });
      // // End - Clear output
      setConnectionMethod({ muxedOutputs });

      return null;
    }

    setConnectionMethod(value);

    return null;
  };

  const isOutputSection = isNonMuxedOutput || isMuxedOutput;

  return (
    <Item name={name} label={<ConnectionMethodLabel />} rules={[required]}>
      <Select disabled={disabled} onChange={handleChangeConnectionMethod}>
        {isOutputSection &&
          CONNECTION_METHOD_OUTPUT_ARRAY.map((option) => (
            <Option
              key={option.value}
              value={option.value}
              disabled={!isCloudLicense && option.value === QUICKSTREAM_METHOD_V2.outQSDirect.value}
            >
              {!isCloudLicense && option.value === QUICKSTREAM_METHOD_V2.outQSDirect.value ? (
                <>
                  {option.label} (
                  <FormattedMessage
                    id="general.availableCloudLicense"
                    defaultMessage="Available only for cloud license"
                  />
                  )
                </>
              ) : (
                option.label
              )}
            </Option>
          ))}
      </Select>
    </Item>
  );
};

OutputConnectionMethod.propTypes = {
  connectionMethod: PropTypes.string,
  disabled: PropTypes.bool,
  getFieldValue: PropTypes.func,
  isNonMuxedOutput: PropTypes.bool,
  isMuxedOutput: PropTypes.bool,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setConnectionMethod: PropTypes.func,
  setFieldsValue: PropTypes.func,
};

OutputConnectionMethod.defaultProps = {
  connectionMethod: null,
  disabled: false,
  getFieldValue: null,
  isMuxedOutput: false,
  isNonMuxedOutput: false,
  prefix: null,
  setFieldsValue: null,
  setConnectionMethod: null,
};

export default OutputConnectionMethod;
