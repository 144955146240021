import React, { useContext } from "react";
import { Divider, Col, Form, Select, Switch, Row } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";

import {
  MPEG2VIDEO_PRESETS,
  SELECT_MPEG2VIDEO_PRESETS,
  SELECT_GLOBAL_STREAM_CODEC_FPS,
  GLOBAL_VIDEO_RESIZE,
  PROCESSING_VIDEO_FORMATS,
  SELECT_TRANSCODING_VIDEO_CODEC,
  TRANSCODING_X264_PIX_FORMAT,
  X264_PROFILE,
} from "../../../../../lib/utils/constants";
import { required } from "../../../../../lib/utils/formValidation";
import globalTranslations from "../../../../../lib/translations";
import mpeg2VideDefaultValues from "../../../../../lib/utils/defaultValues/mpeg2VideoDefaultValues";

import InputNumberField from "../../../../Fields/InputNumberField";
import PixFormatLabel from "./PixFormatLabel";

import { FormV5Context } from "../../../NodeChannelFormV5";

const { Item } = Form;
const { Option } = Select;

const translations = defineMessages({
  bitrate: {
    id: "GlobalVideoCodecFormItem.bitrate",
    defaultMessage: "Bitrate [kbps]",
  },
  sarNum: {
    id: "GlobalVideoCodecFormItem.sarNum",
    defaultMessage: "Video Sample Ratio (sarNum)",
  },
  sarDen: {
    id: "GlobalVideoCodecFormItem.sarDen",
    defaultMessage: "Video Sample Ratio (sarDen)",
  },
  gopStructure: {
    id: "GlobalVideoCodecFormItem.gopStructure",
    defaultMessage: "B-Frames [frames]",
  },
});

const HiQualityPresets = [
  SELECT_MPEG2VIDEO_PRESETS[0].value,
  SELECT_MPEG2VIDEO_PRESETS[1].value,
  SELECT_MPEG2VIDEO_PRESETS[2].value,
];

function GlobalVideoCodecFormItem({ disabled, prefix, isVideoFormat, pixelFormat, isEnginePlayout }) {
  const form = useContext(FormV5Context);

  const { getFieldValue, setFieldValue } = form;
  const { formatMessage } = useIntl();

  const selectedCustomSar = !!Form.useWatch([...prefix, "customSar"], form);

  const selectedCodecPresetId = Form.useWatch([...prefix, "presetId"], form);

  const x264ProfileValue = Form.useWatch(
    isEnginePlayout
      ? [prefix[0], prefix[1], prefix[2], "x264Profile"]
      : [prefix[0], prefix[1], prefix[2], "videoCodec", "x264Profile"],
    form
  );

  const isSelectedCodecCustomPresetId = selectedCodecPresetId === SELECT_MPEG2VIDEO_PRESETS[7].value;
  const isSelectedCodecHIPresetId = HiQualityPresets.includes(selectedCodecPresetId);

  const selectedCodecType = Form.useWatch([...prefix, "type"], form);
  const isNDICodecType = selectedCodecType === SELECT_TRANSCODING_VIDEO_CODEC[2].value;

  const handleChangeCodecResize = (codecResize) => {
    const codec = getFieldValue(prefix);
    delete codec.width;
    delete codec.height;
    delete codec.sarNum;
    delete codec.sarDen;
    delete codec.presetId;
    delete codec.customSar;

    const codecUpdate = {
      resize: codecResize,
      presetId: 2,
      width: MPEG2VIDEO_PRESETS.presetFullHD.width,
      height: MPEG2VIDEO_PRESETS.presetFullHD.height,
      customSar: MPEG2VIDEO_PRESETS.presetFullHD.customSar,
    };
    setFieldValue(prefix, { ...codec, ...codecUpdate });
  };

  const handleChangePresetId = (presetId) => {
    mpeg2VideDefaultValues({ prefix, presetId, getFieldValue, setFieldValue });
  };

  const handleChangeCustomSar = (value) => {
    const codec = getFieldValue(prefix);
    delete codec.sarDen;
    delete codec.sarNum;

    setFieldValue(prefix, { ...codec, customSar: value });
  };

  return (
    <Row gutter={24}>
      <Col span={8}>
        <Item
          name={[...prefix, "resize"]}
          label={<FormattedMessage id="GlobalVideoCodecFormItem.resize" defaultMessage="Scale video" />}
          rules={[required]}
        >
          <Select onChange={handleChangeCodecResize} disabled={disabled}>
            {GLOBAL_VIDEO_RESIZE.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      {!isVideoFormat && (
        <Col span={8}>
          <Item
            name={[...prefix, "fps"]}
            label={<FormattedMessage id="GlobalVideoCodecFormItem.fps" defaultMessage="Frame rate" />}
            rules={[required]}
          >
            <Select disabled={disabled}>
              {SELECT_GLOBAL_STREAM_CODEC_FPS.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      )}
      <Col span={8}>
        <Item
          name={[...prefix, "pixFormat"]}
          label={<PixFormatLabel isNDICodecType={isNDICodecType} />}
          rules={[required]}
        >
          <Select disabled={disabled}>
            {!isVideoFormat &&
              PROCESSING_VIDEO_FORMATS.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            {isVideoFormat &&
              pixelFormat.map((option) => (
                <Option
                  key={option.value}
                  value={option.value}
                  disabled={
                    // disable for high422
                    (x264ProfileValue === X264_PROFILE[1].value &&
                      ![TRANSCODING_X264_PIX_FORMAT[1].value].includes(option.value)) ||
                    // disable for high444
                    (x264ProfileValue === X264_PROFILE[2].value &&
                      ![TRANSCODING_X264_PIX_FORMAT[2].value].includes(option.value)) ||
                    ([X264_PROFILE[0].value, X264_PROFILE[3].value, X264_PROFILE[4].value].includes(x264ProfileValue) &&
                      ![TRANSCODING_X264_PIX_FORMAT[0].value].includes(option.value))
                  }
                >
                  {option.label}
                </Option>
              ))}
          </Select>
        </Item>
      </Col>
      <>
        <Divider />
        <Col span={24}>
          <Item
            name={[...prefix, "presetId"]}
            label={<FormattedMessage id="GlobalVideoCodecFormItem.presetId" defaultMessage="Encoding Preset" />}
            rules={[required]}
          >
            <Select onChange={handleChangePresetId} disabled={disabled}>
              {SELECT_MPEG2VIDEO_PRESETS.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <InputNumberField
            name={[...prefix, "width"]}
            label={formatMessage(globalTranslations.width)}
            rules={[required]}
            disabled={!isSelectedCodecCustomPresetId || disabled}
          />
        </Col>
        <Col span={8}>
          <InputNumberField
            name={[...prefix, "height"]}
            label={formatMessage(globalTranslations.height)}
            rules={[required]}
            disabled={!isSelectedCodecCustomPresetId || disabled}
          />
        </Col>
        <Col span={8}>
          <Item
            name={[...prefix, "customSar"]}
            valuePropName="checked"
            label={
              <FormattedMessage
                id="GlobalVideoCodecFormItem.customSar"
                defaultMessage="Custom video sample aspect ratio"
              />
            }
          >
            <Switch
              disabled={!isSelectedCodecCustomPresetId || disabled}
              defaultChecked={selectedCustomSar}
              checkedChildren={formatMessage(globalTranslations.on)}
              unCheckedChildren={formatMessage(globalTranslations.off)}
              onChange={handleChangeCustomSar}
            />
          </Item>
        </Col>
        {!isSelectedCodecHIPresetId && selectedCustomSar && (
          <>
            <Col span={8}>
              <InputNumberField
                name={[...prefix, "sarNum"]}
                label={formatMessage(translations.sarNum)}
                rules={[required]}
                disabled={!isSelectedCodecCustomPresetId || disabled}
              />
            </Col>
            <Col span={8}>
              <InputNumberField
                name={[...prefix, "sarDen"]}
                label={formatMessage(translations.sarDen)}
                rules={[required]}
                disabled={!isSelectedCodecCustomPresetId || disabled}
              />
            </Col>
          </>
        )}
      </>
    </Row>
  );
}

GlobalVideoCodecFormItem.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  isVideoFormat: PropTypes.bool,
  pixelFormat: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

GlobalVideoCodecFormItem.defaultProps = {
  disabled: null,
  isVideoFormat: false,
  pixelFormat: null,
};

export default GlobalVideoCodecFormItem;
