import React, { useCallback } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import styled from "styled-components";

import themeColor from "../../lib/style/theme";
import { history } from "../../store";

const NotificationTitle = ({ cwid, cnn, channelId, paths, source }) => {
  const linkURL = useCallback(() => {
    switch (source) {
      case 0:
        return "/channels";
      case 1:
        return `/licenses`;
      default:
        if (+channelId <= 0) {
          return "/channels";
        }

        return `/channel/${channelId}`;
    }
  }, [channelId, source]);

  return (
    <StyledTitle onClick={() => history.push(`/node/${cwid}/${cnn}${linkURL()}`)}>
      <b>{cnn} </b>
      {paths && <CaretRightOutlined style={{ color: themeColor.orange }} alt="/" />}
      {paths &&
        paths.map((path, index) => {
          return (
            <span key={path}>
              {index !== 0 && <CaretRightOutlined style={{ color: themeColor.orange }} alt="/" />}
              <b>{path} </b>
            </span>
          );
        })}
    </StyledTitle>
  );
};

const StyledTitle = styled.span`
  color: ${themeColor.orange};
  transition: color 0.4s ease;

  &:hover {
    color: ${themeColor.orangeHover};
    cursor: pointer;
    transition: color 0.4s ease;
  }
`;

NotificationTitle.propTypes = {
  cwid: PropTypes.string,
  cnn: PropTypes.string,
  channelId: PropTypes.number,
  paths: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.number,
};

NotificationTitle.defaultProps = {
  cwid: null,
  cnn: null,
  channelId: null,
  paths: null,
  source: null,
};

export default NotificationTitle;
