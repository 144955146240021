/* eslint-disable max-len */
import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

export default function EmergencyReturnMsecsLabel() {
  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage
          id="EmergencyReturnMsecsLabel.emergencyReturnMsecsDesc"
          defaultMessage="If backup source starts producing frames, then return to it after this amount of milliseconds"
        />
      }
    >
      <FormattedMessage id="EmergencyReturnMsecsLabel.emergencyReturnMsecs" defaultMessage="Emergency return [ms]" />{" "}
      <QuestionCircleFilled />
    </Tooltip>
  );
}
