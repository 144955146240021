import React, { useEffect } from "react";
import { Card, Form, Row, Col, Spin, Drawer } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { connect, useSelector } from "react-redux";
import { CaretDownOutlined, InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import store from "../../store";

import themeColor from "../../lib/style/theme";
import {
  FORM_VERSION,
  AUTO_IP,
  INPUT_URL_TYPE,
  MESSAGE_TYPE,
  QUICKSTREAM_METHOD_V2,
  CONNECTION_TYPE,
} from "../../lib/utils/constants";
import { errorNotification, successNotification } from "../../lib/utils/notification";
import setDifference from "../../lib/utils/setDifference";

import { selectors as NODE_CHANNEL_SELECTORS } from "../../ducks/nodeChannels";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";
import { actions as LOADING_ACTIONS, selectors as LOADING_SELECTOR } from "../../ducks/loadingData";
import { actions as NODE_ACTIONS, selectors as NODE_SELECTORS } from "../../ducks/node";
import { CLEAR_PROBE } from "../../ducks/probe";

import CloudChannelsServices from "../../services/cloudChannels";
import MQTTService from "../../services/mqtt";
import NodeService from "../../services/node";

import InputSection from "./InputSection";
import GeneralSection from "./GeneralSection";
import NonmuxedSection from "./NonmuxedSection";
import MuxedSection from "./MuxedSection";
import DetailSection from "./DetailSection";
import BackButton from "../BackButton";
import DirectOutputTitle from "./DirectOutputTitle";
import NodePorts from "../../pages/Node/NodePorts";

import SeparationTwoColumns from "../SeparationTwoColumns";
import GlobalErrorBoundaries from "../ErrorBoundaries/GlobalErrorBoundaries";

import useNodeDevices from "../../hooks/useNodeDevices";

const NewChannelForm = ({ channelConfig, detailView, editMode, id, nodeChannels, sharedChannelList, stundAddress }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const openDrawer = useSelector(NODE_SELECTORS.getNodeOpenPortsDrawer);

  useNodeDevices();
  const { getFieldValue, setFieldsValue, validateFields, resetFields } = form;

  const isInputURLType = channelConfig?.input?.type === INPUT_URL_TYPE.value;
  const isInput = !!channelConfig?.input;

  const {
    status: { usesBackup, requestedStatusText },
  } = nodeChannels.find((channel) => channel.channelId === id) || { status: {} };
  const { cnn, cwid } = useParams();

  useEffect(() => {
    if (editMode) {
      store.dispatch(CLEAR_PROBE());
    }
  }, [editMode]);

  useEffect(() => {
    if (!detailView) {
      CloudChannelsServices.getOwnChannels({ errorNotification: errorNotification(formatMessage) });
      CloudChannelsServices.getSharedChannels({ errorNotification: errorNotification(formatMessage) });
    }
  }, [detailView, formatMessage]);

  const handleFinish = async (formData) => {
    const oldPermissionId = channelConfig?.input?.mainConnection?.permissionId;

    const parsedFormData = () => {
      const parsedMuxedOutputs = formData?.muxedOutputs?.map((muxedOutput) => {
        return {
          ...muxedOutput,
          outputs: muxedOutput.outputs?.map((output) => {
            if (output.autoIp) {
              return {
                ...output,
                cloudIp: AUTO_IP,
              };
            }

            return output;
          }),
        };
      });

      return {
        ...formData,
        nonMuxedOutputs: formData?.nonMuxedOutputs,
        muxedOutputs: formData?.muxedOutputs && parsedMuxedOutputs,
      };
    };

    const newConfig = parsedFormData();
    const newInputPermissionId = newConfig?.input?.mainConnection?.permissionId;

    const inputConnectionMethod = formData?.input?.mainConnection?.type;
    const isInputQuickstreamMethod = inputConnectionMethod === QUICKSTREAM_METHOD_V2.inQSDirect.value;
    const newCloudIdsToConnect = new Set();

    if (formData?.nonMuxedOutputs || formData?.muxedOutputs) {
      const oldCloudIds = new Set();
      const newCloudIds = new Set();

      if (channelConfig.nonMuxedOutputs) {
        channelConfig.nonMuxedOutputs.forEach((nonMuxedOutput) => {
          if (nonMuxedOutput.type === QUICKSTREAM_METHOD_V2.outQSDirect.value) {
            oldCloudIds.add(nonMuxedOutput.cloudId);
          }
        });
      }

      if (channelConfig.muxedOutputs) {
        channelConfig.muxedOutputs.forEach((muxedOutput) => {
          if (muxedOutput.outputs) {
            muxedOutput.outputs.forEach((muxedOutputOutput) => {
              const oldCloudId = muxedOutputOutput?.cloudId;
              if (oldCloudId) {
                oldCloudIds.add(muxedOutputOutput?.cloudId);
              }
            });
          }
        });
      }

      if (newConfig.nonMuxedOutputs) {
        newConfig.nonMuxedOutputs.forEach((nonMuxedOutput) => {
          if (nonMuxedOutput.type === QUICKSTREAM_METHOD_V2.outQSDirect.value) {
            newCloudIds.add(nonMuxedOutput.cloudId);
          }
        });
      }

      if (newConfig.muxedOutputs) {
        newConfig.muxedOutputs.forEach((muxedOutput) => {
          if (muxedOutput.outputs) {
            muxedOutput.outputs.forEach((muxedOutputOutput) => {
              const newCloudId = muxedOutputOutput?.cloudId;
              if (newCloudId) {
                newCloudIds.add(muxedOutputOutput?.cloudId);
              }
            });
          }
        });
      }

      // check Set() - if there is any new cloudIds to connect - if so - connect to cloud
      const cloudIdsToConnect = setDifference(newCloudIds, oldCloudIds);

      cloudIdsToConnect.forEach((cloudId) => newCloudIdsToConnect.add(cloudId));

      if (newCloudIdsToConnect.size > 0) {
        const cloudData = {
          cnn,
          cwid,
          cloudIds: Array.from(newCloudIdsToConnect),
          type: CONNECTION_TYPE.NODE_DIRECT,
        };

        await NodeService.connectFingerprintToChannel(cloudData, {
          errorNotification: errorNotification(formatMessage),
        });
      }
    }

    const newPermissionIdToConnect =
      isInputQuickstreamMethod &&
      oldPermissionId !== newInputPermissionId &&
      formData?.input?.mainConnection?.permissionId;

    if (isInputQuickstreamMethod && oldPermissionId !== newInputPermissionId) {
      const channelData = {
        cnn,
        cwid,
        permissionId: formData?.input?.mainConnection?.permissionId,
        type: CONNECTION_TYPE.NODE_DIRECT,
      };

      await NodeService.connectFingerprintToPermission(channelData, {
        errorNotification: errorNotification(formatMessage),
      });
    }

    if (editMode) {
      store.dispatch(LOADING_ACTIONS.SET_LOADING("updateNodeChannel"));

      const oldCloudIdsToRemove = new Set();
      const oldPermissionIdToRemove = oldPermissionId !== newInputPermissionId && oldPermissionId;

      if (oldPermissionId !== newInputPermissionId) {
        await NodeService.updateChannelData(
          { oldPermissionId },
          {
            errorNotification: errorNotification(formatMessage),
            successNotification: successNotification(formatMessage),
          }
        );
      }

      if (channelConfig.nonMuxedOutputs) {
        const oldCloudIds = new Set();
        const newCloudIds = new Set();

        channelConfig.nonMuxedOutputs.forEach((nonMuxedOutput) => {
          const oldCloudId = nonMuxedOutput?.cloudId;
          if (oldCloudId) {
            oldCloudIds.add(nonMuxedOutput?.cloudId);
          }
        });

        newConfig.nonMuxedOutputs.forEach((nonMuxedOutput) => {
          const newCloudId = nonMuxedOutput?.cloudId;
          if (newCloudId) {
            newCloudIds.add(nonMuxedOutput?.cloudId);
          }
        });

        // check Set() - if there is any oldCloudId to remove - if so - remove it
        const cloudIdsToRemove = setDifference(oldCloudIds, newCloudIds);
        cloudIdsToRemove.forEach((cloudId) => oldCloudIdsToRemove.add(cloudId));

        if (oldCloudIdsToRemove.size > 0) {
          await NodeService.updateChannelData(
            { oldCloudIds: Array.from(oldCloudIdsToRemove) },
            {
              errorNotification: errorNotification(formatMessage),
            }
          );
        }
      }

      if (channelConfig.muxedOutputs) {
        const oldCloudIds = new Set();
        const newCloudIds = new Set();

        channelConfig.muxedOutputs.forEach((muxedOutput) => {
          if (muxedOutput.outputs) {
            muxedOutput.outputs.forEach((muxedOutputOutput) => {
              const oldCloudId = muxedOutputOutput?.cloudId;
              if (oldCloudId) {
                oldCloudIds.add(muxedOutputOutput?.cloudId);
              }
            });
          }
        });

        newConfig.muxedOutputs.forEach((muxedOutput) => {
          if (muxedOutput.outputs) {
            muxedOutput.outputs.forEach((muxedOutputOutput) => {
              const newCloudId = muxedOutputOutput?.cloudId;
              if (newCloudId) {
                newCloudIds.add(muxedOutputOutput?.cloudId);
              }
            });
          }
        });

        // check Set() - if there is any oldCloudId to remove - if so - remove it
        const cloudIdsToRemove = setDifference(oldCloudIds, newCloudIds);

        cloudIdsToRemove.forEach((cloudId) => oldCloudIdsToRemove.add(cloudId));

        if (oldCloudIdsToRemove.size > 0) {
          await NodeService.updateChannelData(
            { oldCloudIds: Array.from(oldCloudIdsToRemove) },
            {
              errorNotification: errorNotification(formatMessage),
            }
          );
        }
      }

      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.UPDATE_CHANNEL,
          channelId: id,
          channelConfig: JSON.stringify({
            ...newConfig,
          }),
          oldCloudIdsToRemove: Array.from(oldCloudIdsToRemove),
          oldPermissionIdToRemove,
          newPermissionIdToConnect,
          newCloudIdsToConnect: Array.from(newCloudIdsToConnect),
        },
      });
    } else {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.ADD_NODE_CHANNEL,
          channelConfig: JSON.stringify({ ...newConfig }),
          newPermissionIdToConnect,
          newCloudIdsToConnect: Array.from(newCloudIdsToConnect),
        },
      });

      store.dispatch(LOADING_ACTIONS.SET_LOADING(MESSAGE_TYPE.ADD_NODE_CHANNEL));
    }
  };

  const setInitialAutoIp = (cloudIp) => {
    if (!cloudIp) {
      return stundAddress;
    }

    return cloudIp;
  };

  const initialFormValues = {
    ...channelConfig,
    ...(channelConfig?.nonMuxedOutputs
      ? {
          nonMuxedOutputs: channelConfig.nonMuxedOutputs.map((nonMuxedOutput) => {
            return {
              ...nonMuxedOutput,
              cloudIp: setInitialAutoIp(nonMuxedOutput?.cloudIp),
            };
          }),
        }
      : {}),
    ...(channelConfig?.muxedOutputs
      ? {
          muxedOutputs: channelConfig.muxedOutputs.map((muxedOutput) => {
            return {
              ...muxedOutput,
              outputs: muxedOutput?.outputs?.map((output) => {
                return {
                  ...output,
                  cloudIp: setInitialAutoIp(output?.cloudIp),
                };
              }),
            };
          }),
        }
      : {}),
    version: FORM_VERSION,
  };

  return (
    <StyledCard>
      <Form onFinish={handleFinish} form={form} initialValues={initialFormValues}>
        <Row gutter={48}>
          {detailView && (
            <Col span={24}>
              <GlobalErrorBoundaries>
                <DetailSection channelId={id} />
              </GlobalErrorBoundaries>
            </Col>
          )}
          <Col span={24}>
            <GlobalErrorBoundaries>
              <GeneralSection
                channelConfig={channelConfig}
                detailView={detailView}
                editMode={editMode}
                getFieldValue={getFieldValue}
                handleFinish={handleFinish}
                id={id}
                resetFields={resetFields}
                validateFields={validateFields}
              />
            </GlobalErrorBoundaries>
          </Col>
        </Row>
        <Spin
          indicator={<InfoCircleOutlined />}
          tip={<FormattedMessage id="NewChannelForm.disabled" defaultMessage="Please fill general section before" />}
          spinning={!editMode}
        >
          <Row gutter={48}>
            <Col span={24}>
              <Row justify="space-around" align="middle">
                <StyledColCenter span={24}>
                  <CaretDownOutlined />
                </StyledColCenter>
              </Row>
            </Col>
            <Col span={24}>
              <GlobalErrorBoundaries>
                <InputSection
                  channelConfig={channelConfig}
                  detailView={detailView}
                  getFieldValue={getFieldValue}
                  handleFinish={handleFinish}
                  id={id}
                  requestedStatusText={requestedStatusText}
                  resetFields={resetFields}
                  setFieldsValue={setFieldsValue}
                  sharedChannelList={sharedChannelList}
                  stundAddress={stundAddress}
                  usesBackup={usesBackup}
                  validateFields={validateFields}
                />
              </GlobalErrorBoundaries>
            </Col>
            <SeparationTwoColumns />
            <Col span={12}>
              <GlobalErrorBoundaries>
                <Card
                  title={
                    <FormattedMessage id="NewChannelForm.muxedOutputsTitle" defaultMessage="Muxed Outputs (Beta)" />
                  }
                >
                  <MuxedSection
                    detailView={detailView}
                    handleFinish={handleFinish}
                    id={id}
                    muxedOutputs={channelConfig?.muxedOutputs}
                    form={form}
                    isInput={!!channelConfig?.input}
                  />
                </Card>
              </GlobalErrorBoundaries>
            </Col>
            <Col span={12}>
              <GlobalErrorBoundaries>
                <Card title={<DirectOutputTitle incomplete={channelConfig?.nonMuxedOutputs?.incomplete} />}>
                  <Spin
                    spinning={!isInputURLType || !isInput}
                    tip={
                      !isInput ? (
                        <FormattedMessage
                          id="NewChannelForm.selectInputType"
                          defaultMessage="Please select input type before defining muxed outputs"
                        />
                      ) : (
                        <FormattedMessage
                          id="NewChannelForm.disabledNonMuxedOutputs"
                          defaultMessage="Disabled for non-URL input type"
                        />
                      )
                    }
                    indicator={<WarningOutlined />}
                  >
                    <NonmuxedSection
                      channelId={id}
                      detailView={detailView}
                      getFieldValue={getFieldValue}
                      handleFinish={handleFinish}
                      nonMuxedOutputs={channelConfig?.nonMuxedOutputs || []}
                      resetFields={resetFields}
                      setFieldsValue={setFieldsValue}
                      stundAddress={stundAddress}
                      validateFields={validateFields}
                      form={form}
                    />
                  </Spin>
                </Card>
              </GlobalErrorBoundaries>
            </Col>
          </Row>
        </Spin>
        <BackButton channelId={id} detailView={detailView} />
      </Form>
      <Drawer
        title={<FormattedMessage id="NodeDashboard.usedPorts" defaultMessage="Used ports" />}
        placement="right"
        onClose={() => store.dispatch(NODE_ACTIONS.SET_NODE_DRAWER(false))}
        open={openDrawer}
        width={750}
        forceRender
        zIndex={1001}
      >
        <NodePorts activeLink={false} />
      </Drawer>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  .ant-card {
    margin-bottom: 10px;
  }
`;

const StyledColCenter = styled(Col)`
  text-align: center;
  color: ${themeColor.orange};
  font-size: 40px;
`;

const mapStateToProps = (state) => ({
  connection: NODE_SELECTORS.getCnn(state),
  loading: LOADING_SELECTOR.getLoading(state),
  nodeChannels: NODE_CHANNEL_SELECTORS.getChannels(state),
  sharedChannelList: CLOUD_CHANNELS_SELECTORS.getSharedChannels(state),
  stundAddress: NODE_SELECTORS.getStundAddress(state),
});

NewChannelForm.propTypes = {
  channelConfig: PropTypes.object,
  detailView: PropTypes.bool,
  editMode: PropTypes.bool,
  id: PropTypes.string,
  nodeChannels: PropTypes.object,
  sharedChannelList: PropTypes.array,
  stundAddress: PropTypes.string,
};

NewChannelForm.defaultProps = {
  channelConfig: null,
  detailView: null,
  editMode: null,
  nodeChannels: null,
  id: null,
  sharedChannelList: null,
  stundAddress: null,
};

export default connect(mapStateToProps, null)(NewChannelForm);
