import React, { useCallback } from "react";
import { Col, Alert, Card, Form, Switch, Row, Divider, Input, Button, Tooltip, Checkbox } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { MailOutlined, DeliveredProcedureOutlined, PhoneOutlined, SaveOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import styled from "styled-components";

import BusinessDescription from "./BusinessDescription";
import TechnicalDescription from "./TechnicalDescription";
import OrganizationRole from "./OrganizationRole";

import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";
import { selectors as ORGANIZATION_SELECTORS } from "../../ducks/organizations";
import OrganizationsServices from "../../services/organizations";

import { BUSINESS_ROLES, TECHNICAL_ROLES } from "../../lib/utils/constants";
import { required, trim, min2characters, max79characters } from "../../lib/utils/formValidation";
import { errorNotification } from "../../lib/utils/notification";

const { Item: FormItem } = Form;

const OrganizationStep1 = ({ setCurrentStep, initialData, setIsVisibleRolesDetail }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const userEmail = useSelector(ACCOUNT_SELECTORS.getUserEmail);
  let organizationData = null;
  const organizationDataSelector = useSelector(ORGANIZATION_SELECTORS.getOrganizationData);

  if (initialData) {
    organizationData = initialData;
  } else {
    organizationData = organizationDataSelector;
  }

  const isBusiness = Form.useWatch("business", form);
  const isTechnical = Form.useWatch("technical", form);

  const onFinish = useCallback(
    (formData) => {
      OrganizationsServices.updateOrganization(
        {
          ...formData,
          organizationId: organizationData.organizationId,
          ...(initialData ? { username: initialData?.username } : {}),
        },
        {
          errorNotification: errorNotification(formatMessage),
          afterUpdate: () => setCurrentStep(2),
        }
      );
      if (setIsVisibleRolesDetail) {
        setIsVisibleRolesDetail(false);
      }
    },
    [organizationData, initialData]
  );

  const handleBackButton = useCallback(() => {
    setCurrentStep(0);
  }, []);

  return (
    <Col span={24}>
      <Alert
        message={
          <span>
            <FormattedMessage
              id="OrganizationStep1.description"
              // eslint-disable-next-line max-len
              defaultMessage="We take your data very seriously. We never share your direct contact details information. For more information please refer to our Privacy Policy"
            />
            .
          </span>
        }
        type="info"
        showIcon
      />
      <Divider />
      <Card>
        <Form
          name="organizationStep1"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            id: organizationData?.id,
            business: organizationData?.business || false,
            businessRole: organizationData?.businessRole,
            businessName: organizationData?.businessName,
            businessEmail: organizationData?.businessEmail || userEmail,
            technical: organizationData?.technical || false,
            technicalRole: organizationData?.technicalRole,
            technicalName: organizationData?.technicalName,
            technicalEmail: organizationData?.technicalEmail || userEmail,
            businessPhoneNumber: organizationData?.businessPhoneNumber,
            technicalPhoneNumber: organizationData?.technicalPhoneNumber,
            public: "public" in organizationData ? organizationData.public : true,
          }}
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Row gutter={[24, 24]}>
            <StyledCol span={12}>
              <FormItem name="id" hidden>
                <Input />
              </FormItem>
              <FormItem label={<BusinessDescription />} name="business" valuePropName="checked">
                <Switch />
              </FormItem>
              {isBusiness && (
                <>
                  <OrganizationRole name="businessRole" selector={BUSINESS_ROLES} />
                  <FormItem
                    label={<FormattedMessage id="OrganizationStep1.name" defaultMessage="Name" />}
                    name="businessName"
                    rules={[trim]}
                  >
                    <Input />
                  </FormItem>

                  <FormItem
                    label={<FormattedMessage id="OrganizationForm.phone" defaultMessage="Phone" />}
                    name="businessPhoneNumber"
                    rules={[min2characters, max79characters, trim]}
                  >
                    <Input addonBefore={<PhoneOutlined />} />
                  </FormItem>

                  <FormItem
                    label={<FormattedMessage id="OrganizationStep1.email" defaultMessage="Email" />}
                    name="businessEmail"
                    rules={[
                      required,
                      {
                        type: "email",
                        message: (
                          <FormattedMessage id="OrganizationStep1.emailValidation" defaultMessage="Email not valid" />
                        ),
                      },
                    ]}
                  >
                    <Input addonBefore={<MailOutlined />} />
                  </FormItem>
                </>
              )}
            </StyledCol>

            <StyledCol span={12}>
              <FormItem label={<TechnicalDescription />} name="technical" valuePropName="checked">
                <Switch />
              </FormItem>
              {isTechnical && (
                <>
                  <OrganizationRole name="technicalRole" selector={TECHNICAL_ROLES} />
                  <FormItem
                    label={<FormattedMessage id="OrganizationStep1.name" defaultMessage="Name" />}
                    name="technicalName"
                    rules={[trim]}
                  >
                    <Input />
                  </FormItem>

                  <FormItem
                    label={<FormattedMessage id="OrganizationForm.phone" defaultMessage="Phone" />}
                    name="technicalPhoneNumber"
                    rules={[min2characters, max79characters, trim]}
                  >
                    <Input addonBefore={<PhoneOutlined />} />
                  </FormItem>

                  <FormItem
                    label={<FormattedMessage id="OrganizationStep1.email" defaultMessage="Email" />}
                    name="technicalEmail"
                    rules={[
                      required,
                      {
                        type: "email",
                        message: (
                          <FormattedMessage id="OrganizationStep1.emailValidation" defaultMessage="Email not valid" />
                        ),
                      },
                    ]}
                  >
                    <Input addonBefore={<MailOutlined />} />
                  </FormItem>
                </>
              )}
            </StyledCol>
          </Row>
          {!initialData && (
            <StyledNavigation>
              <Button type="primary" onClick={handleBackButton}>
                <FormattedMessage id="general.back" defaultMessage="Back" />
              </Button>
              <Tooltip
                placement="top"
                title={
                  <FormattedMessage id="general.nextAndSave" defaultMessage="Go to next step (data will be saved)" />
                }
              >
                <Button htmlType="submit" type="primary" icon={<DeliveredProcedureOutlined />}>
                  <span>
                    <FormattedMessage id="general.next" defaultMessage="Next" />
                  </span>
                </Button>
              </Tooltip>
            </StyledNavigation>
          )}
          {initialData && (
            <StyledNavigation>
              <FormItem
                name="public"
                valuePropName="checked"
                label={
                  <>
                    <StyledLabel>
                      <FormattedMessage id="OrganizationStep2.public" defaultMessage="Public" />
                    </StyledLabel>
                  </>
                }
              >
                <Checkbox />
              </FormItem>
              <Tooltip placement="top">
                <Button htmlType="submit" type="primary" icon={<SaveOutlined />}>
                  <span>
                    <FormattedMessage id="general.next" defaultMessage="Next" />
                  </span>
                </Button>
              </Tooltip>
            </StyledNavigation>
          )}
        </Form>
      </Card>
    </Col>
  );
};

const StyledCol = styled(Col)`
  min-height: 250px;
`;

const StyledNavigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .ant-form-item {
    width: 200px;
  }
`;

const StyledLabel = styled.span`
  margin-right: 5px;
`;

export default OrganizationStep1;
