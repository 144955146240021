import React from "react";
import { Col, Form, Select } from "antd";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import {
  X264_PROFILE,
  OUTPUT_RTMP_PROFILE,
  TRANSCODING_X264_PIX_FORMAT,
} from "../../../../../../../lib/utils/constants";
import { required } from "../../../../../../../lib/utils/formValidation";

const { Item } = Form;
const { Option } = Select;

function X264VideoCodec({ prefix, fieldPrefix, disabled, form, isOutputRtmp }) {
  const { setFieldValue } = form;

  const handleChangeProfile = (value) => {
    // for high422
    if (value === X264_PROFILE[1].value) {
      setFieldValue(
        [prefix[0], prefix[1], prefix[2], "videoFormat", "pixFormat"],
        TRANSCODING_X264_PIX_FORMAT[1].value
      );

      return;
    }
    // for high444
    if (value === X264_PROFILE[2].value) {
      setFieldValue(
        [prefix[0], prefix[1], prefix[2], "videoFormat", "pixFormat"],
        TRANSCODING_X264_PIX_FORMAT[2].value
      );

      return;
    }

    setFieldValue([prefix[0], prefix[1], prefix[2], "videoFormat", "pixFormat"], TRANSCODING_X264_PIX_FORMAT[0].value);
  };

  const PARSED_PROFILES = isOutputRtmp ? OUTPUT_RTMP_PROFILE : X264_PROFILE;

  return (
    <Col span={6}>
      <Item
        name={[...fieldPrefix, "x264Profile"]}
        label={<FormattedMessage id="X264VideoCodec.x264Profile" defaultMessage="Profile" />}
        rules={[required]}
      >
        <Select disabled={disabled} onChange={handleChangeProfile}>
          {PARSED_PROFILES.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Item>
    </Col>
  );
}

X264VideoCodec.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  disabled: PropTypes.bool,
  isOutputRtmp: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

X264VideoCodec.defaultProps = {
  disabled: null,
};

export default X264VideoCodec;
