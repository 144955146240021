import React, { useState, useContext } from "react";
import { Card, Modal, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { SaveOutlined, QuestionCircleFilled } from "@ant-design/icons";
import PropTypes from "prop-types";

import SCTESectionFormItem from "./SCTESectionFormItem";

import { FormV5Context } from "../../NodeChannelFormV5";

import useNodeChannelConfig from "../../../../hooks/useNodeChannelConfig";

import EnginePlayoutElement from "../../../EnginePlayoutElement";

export default function SCTESection({ disabled, handleFinish }) {
  const [open, setOpen] = useState(false);
  const form = useContext(FormV5Context);

  const { channelConfig, scte } = useNodeChannelConfig();

  const { resetFields, getFieldValue, validateFields } = form;

  const handleSaveSCTE = async () => {
    await validateFields();
    const formData = getFieldValue("scte");
    handleFinish({ ...channelConfig, scte: formData });
    setOpen(false);
  };

  return (
    <Card
      title={
        <>
          <FormattedMessage id="SCTESection.scte" defaultMessage="SCTE" />{" "}
          <Tooltip
            placement="top"
            title={
              <FormattedMessage
                id="SCTESection.scteInfo"
                defaultMessage="For use SCTE, it is necessary to select the appropriate option when creating an output"
              />
            }
          >
            <QuestionCircleFilled />
          </Tooltip>
        </>
      }
    >
      <EnginePlayoutElement
        label={<FormattedMessage id="SCTESection.scte" defaultMessage="SCTE" />}
        handleClick={() => setOpen(true)}
        data={scte}
      />
      <Modal
        title={<FormattedMessage id="SCTESection.scte" defaultMessage="SCTE" />}
        centered
        destroyOnClose
        open={open}
        onCancel={() => {
          resetFields();
          setOpen(false);
        }}
        onOk={handleSaveSCTE}
        width={1280}
        footer={disabled ? null : undefined}
        okText={
          <span>
            <FormattedMessage id="general.save" defaultMessage="Save" />
          </span>
        }
        okButtonProps={{ icon: <SaveOutlined /> }}
      >
        <SCTESectionFormItem prefix={["scte"]} disabled={disabled} />
      </Modal>
    </Card>
  );
}

SCTESection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleFinish: PropTypes.func.isRequired,
};
