import React from "react";
import { useSelector } from "react-redux";
import { LinuxOutlined, WindowsFilled } from "@ant-design/icons";
import styled from "styled-components";
import { Spin } from "antd";

import { selectors as CONNECTION_SELECTORS } from "../../ducks/node";
import { NODE_OS } from "../../lib/utils/constants";
import themeColor from "../../lib/style/theme";

export default function NodeOsIcon() {
  const nodeOS = useSelector(CONNECTION_SELECTORS.getNodeOS);
  const isWindows = nodeOS === NODE_OS.WINDOWS;
  const isLinux = nodeOS === NODE_OS.LINUX;

  return (
    <>
      {!nodeOS && (
        <span>
          <StyledSpin size="small" />
        </span>
      )}
      {isWindows && <WindowsFilled />}
      {isLinux && <LinuxOutlined />}
    </>
  );
}

const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: ${themeColor.maritime};
  }
`;
