import React, { useState, useCallback } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Modal, Form } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import NewChannelFormModal from "./NewChannelFormModal";

const EditSharedChannelModal = ({ cloudId }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentCloudId, setCurrentCloudId] = useState(cloudId);
  const [form] = Form.useForm();

  const handleShowModal = useCallback(() => {
    setShowModal(!showModal);
    setCurrentStep(0);
  }, [showModal]);

  return (
    <>
      <StyledButton icon={<EditOutlined />} onClick={handleShowModal} type="dashed">
        <span>
          <FormattedMessage id="EditSharedChannelModal.editShare" defaultMessage="Edit" />
        </span>
      </StyledButton>
      <Modal
        destroyOnClose
        width={800}
        title={<FormattedMessage id="EditSharedChannelModal.editHubChannel" defaultMessage="Edit hub channel" />}
        open={showModal}
        footer={null}
        onCancel={handleShowModal}
      >
        <NewChannelFormModal
          editMode
          handleShowModal={handleShowModal}
          form={form}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          currentCloudId={currentCloudId}
          setCurrentCloudId={setCurrentCloudId}
        />
      </Modal>
    </>
  );
};

const StyledButton = styled(Button)`
  margin: 3px 5px;
`;

EditSharedChannelModal.propTypes = {
  cloudId: PropTypes.number,
};

EditSharedChannelModal.defaultProps = {
  cloudId: null,
};

export default EditSharedChannelModal;
