import React, { useMemo } from "react";
import { Card, Row, Col, Button, Tooltip } from "antd";
import styled from "styled-components";
import { CaretLeftOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import themeColor from "../../lib/style/theme";

import NodeHeaderSystemStats from "../NodeHeaderSystemStats";
import InstallNewNode from "../InstallNewNode";
import Breadcrumbs from "../Breadcrumbs";
import DetailSectionTitleV5 from "../NodeChannelFormV5/DetailSection/DetailSectionTitle";
import DetailSectionTitleV4 from "../NodeChannelFormV4/DetailSection/DetailSectionTitle";

import { selectors as CHANNEL_SELECTORS } from "../../ducks/nodeChannels";
import useNodeVersion from "../../hooks/useNodeVersion";

const PageHeaderContainer = () => {
  const { isV5Node, isV4Node } = useNodeVersion();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const { pathname } = useLocation();

  const pathUrlArray = pathname.substring(1).split("/");

  const isNodeUrl = useMemo(
    () => pathUrlArray[0] === "node" && !pathUrlArray.includes("manage-access"),
    [pathUrlArray]
  );
  const isNodeManagementUrl = useMemo(() => pathUrlArray[0] === "nodes-management", [pathUrlArray]);

  const channels = useSelector(CHANNEL_SELECTORS.getChannels);
  const isEditMode = pathUrlArray.includes("edit");
  const channelId = isNodeUrl && isEditMode ? pathUrlArray[5] : pathUrlArray[4];
  const { config, status } = (channelId && channels.find((channel) => channel.channelId === channelId)) || {};
  const isChannelDetailView = config && channelId && status && !isEditMode;

  return (
    <StyledAPageHeaderContainer>
      <Card size="small">
        <Row justify="start" align="middle" gutter={12}>
          <Col xs={4} sm={3} md={2} lg={1} align="middle">
            <Tooltip title={<FormattedMessage id="PageHeaderContainer.back" defaultMessage="Back" />}>
              <StyledButton
                type="dashed"
                shape="circle"
                icon={<CaretLeftOutlined style={{ fontSize: "12px" }} />}
                onClick={handleGoBack}
              />
            </Tooltip>
          </Col>
          <Col xs={20} sm={21} md={22} lg={23}>
            <StyledRow align="middle" justify={isNodeManagementUrl || isChannelDetailView ? "space-between" : "start"}>
              <Col span={isNodeManagementUrl || isChannelDetailView ? 12 : 24}>
                <Breadcrumbs />
                {isNodeUrl && (
                  <Col span={24}>
                    <NodeHeaderSystemStats size="small" />
                  </Col>
                )}
              </Col>
              {isChannelDetailView && (
                <Col span={12}>
                  {isV5Node && (
                    <DetailSectionTitleV5 channelId={channelId} channelConfig={config} channelStatus={status} />
                  )}
                  {isV4Node && (
                    <DetailSectionTitleV4 channelId={channelId} channelConfig={config} channelStatus={status} />
                  )}
                </Col>
              )}
              {isNodeManagementUrl && (
                <Col span={12}>
                  <InstallNewNode />
                </Col>
              )}
            </StyledRow>
          </Col>
        </Row>
      </Card>
    </StyledAPageHeaderContainer>
  );
};

const StyledRow = styled(Row)`
  margin-left: 10px;
`;

const StyledButton = styled(Button)`
  color: ${themeColor.orange};
  border-color: ${themeColor.orange};

  &:hover {
    color: #fff;
    background-color: ${themeColor.orangeHover};
    border-color: ${themeColor.orangeHover};
  }
`;

const StyledAPageHeaderContainer = styled.div`
  position: sticky;
  top: -12px;
  z-index: 100;

  .ant-card {
    margin-bottom: 10px;
  }
`;

export default PageHeaderContainer;
