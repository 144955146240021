import React, { useContext } from "react";
import { Col, Form, Switch } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";

import InputNumberField from "../../../Fields/InputNumberField";
import InputTextField from "../../../Fields/InputTextField";

import { required } from "../../../../lib/utils/formValidation";
import globalTranslations from "../../../../lib/translations";

import { FormV5Context } from "../../NodeChannelFormV5";

const { Item } = Form;

const translations = defineMessages({
  stopTime: {
    id: "SimpleLogoFormItem.stopTime",
    defaultMessage: "Stop time [ms]",
  },
  casparIp: {
    id: "SimpleLogoFormItem.casparIp",
    defaultMessage: "Caspar Ip",
  },
  addClearToAllItems: {
    id: "SimpleLogoFormItem.addClearToAllItems",
    defaultMessage: "Add clear to all items",
  },
});

export default function SimpleLogoFormItem({ prefix, disabled }) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);
  const addClearToAllItems = Form.useWatch([...prefix, "addClearToAllItems"], form);

  return (
    <>
      <Col span={12}>
        <InputTextField
          name={[...prefix, "customPath"]}
          disabled={disabled}
          label={<FormattedMessage id="SimpleLogoFormItem.customPathFiles" defaultMessage="Custom path files" />}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "stopTime"]}
          label={formatMessage(translations.stopTime)}
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={6}>
        <Item
          name={[...prefix, "addClearToAllItems"]}
          valuePropName="checked"
          label={formatMessage(translations.addClearToAllItems)}
          rules={[required]}
        >
          <Switch
            disabled={disabled}
            defaultChecked={addClearToAllItems}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
          />
        </Item>
      </Col>
    </>
  );
}

SimpleLogoFormItem.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
};
