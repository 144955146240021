import React from "react";
import { Card, Row, Empty, Tooltip, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";

import { getConnectedNodeV5Selector } from "../../ducks/mqtt";

import NodeCard from "../../components/NodeCard";

export default function Dashboard() {
  const [array1, array2] = useSelector(getConnectedNodeV5Selector);
  const isNoConnectedNodes = [...array1, ...array2].length === 0;

  if (isNoConnectedNodes) {
    return (
      <StyledCard title={<FormattedMessage id="Dashboard.nodesConnected" defaultMessage="Nodes connected" />}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<FormattedMessage id="Dashboard.nodeNodesConnected" defaultMessage="No nodes connected" />}
        />
      </StyledCard>
    );
  }

  return (
    <StyledCard
      title={
        <>
          <FormattedMessage id="Dashboard.nodesConnected" defaultMessage="Nodes connected" />{" "}
          <Tooltip
            placement="top"
            title={
              <FormattedMessage
                id="Dashboard.nodesConnectedInfo"
                defaultMessage="Nodes connected to cloud - includes only nodes in version greater than 5.x.x "
              />
            }
          >
            <QuestionCircleFilled />
          </Tooltip>
        </>
      }
    >
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Row gutter={[24, 24]}>
            {array1.map((nodeData) => (
              <NodeCard nodeData={nodeData} key={nodeData?.cwid} />
            ))}
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={[24, 24]}>
            {array2.map((nodeData) => (
              <NodeCard nodeData={nodeData} key={nodeData?.cwid} />
            ))}
          </Row>
        </Col>
      </Row>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  .ant-card-actions li span {
    &:hover {
      cursor: auto;
    }
  }
`;
