export default function flattenObject(obj) {
  return Object.values(obj).flatMap((value) => {
    if (typeof value === "object" && !Array.isArray(value)) {
      return flattenObject(value);
    }

    if (Array.isArray(value)) {
      return value.flatMap((item) => flattenObject(item));
    }

    switch (value) {
      case "inUdpMulticast":
        return "udp multicast";
      case "inUdpUnicast":
        return "udp unicast";
      case "inSrtListener":
        return "srt listener";
      case "inSrtCaller":
        return "srt caller";
      case "outUdp":
        return "udp";
      case "outSrtListener":
        return "srt listener";
      case "outSrtCaller":
        return "srt caller";
      case "udpMulticast":
        return "udp multicast";
      case "inQSDirect":
      case "outQSDirect":
        return "quickstream";
      default:
        return String(value).toLowerCase();
    }
  });
}
