import React from "react";
import { Modal, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import StripePricingTable from "../StripePricingTable";

export default function BillingModal({ showBillingModal, handleCloseBillingModal }) {
  const items = [
    {
      label: "EURO",
      key: "en",
      children: <StripePricingTable lng="en" />,
    },
    { label: "PLN", key: "pl", children: <StripePricingTable lng="pl" /> },
  ];

  return (
    <Modal
      title={<FormattedMessage id="BillingModal.title" defaultMessage="Quickstream Billing" />}
      open={showBillingModal}
      onCancel={handleCloseBillingModal}
      footer={null}
    >
      <Tabs items={items} />
    </Modal>
  );
}

BillingModal.propTypes = {
  showBillingModal: PropTypes.bool.isRequired,
  handleCloseBillingModal: PropTypes.func.isRequired,
};
