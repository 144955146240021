import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Tooltip, Spin } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

import CloudServices from "../../../services/cloud";

import { selectors as MQTT_SELECTORS } from "../../../ducks/mqtt";
import themeColor from "../../../lib/style/theme";
import { NODES_TRAFFIC_TYPE } from "../../../lib/utils/constants";
import { errorNotification } from "../../../lib/utils/notification";

import NodeNetworkOutput from "../../NodeNetworkOutput";
import NodeNetworkTrafficDate from "../../NodeNetworkTrafficDate";

export default function LicensesListCloudNetworkOutput() {
  const totalNodesNetworkOutput = useSelector(MQTT_SELECTORS.getTotalNetworkOutput);
  const cloudNodes = useSelector(MQTT_SELECTORS.getCloudNodes);
  const cloudNodesTotal = cloudNodes && cloudNodes.length;
  const selectedNetworkTrafficDate = useSelector(MQTT_SELECTORS.getNetworkOutputTrafficDate);

  useEffect(() => {
    const data = {
      startDate: selectedNetworkTrafficDate[0].format("YYYY-MM-DD"),
      endDate: selectedNetworkTrafficDate[1].format("YYYY-MM-DD"),
      type: NODES_TRAFFIC_TYPE.ALL,
    };

    const intervalId = setInterval(() => {
      CloudServices.getCloudNodesNetworkUsage(data, { errorNotification });
    }, 60000);

    CloudServices.getCloudNodesNetworkUsage(data, { errorNotification });

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedNetworkTrafficDate]);

  return (
    <div>
      <Tooltip
        placement="topLeft"
        title={
          <FormattedMessage id="LicensesListCloud.numberOfCloudNodesTooltip" defaultMessage="Number of cloud nodes" />
        }
      >
        <FormattedMessage id="LicensesListCloudNetworkOutput.cloudNodes" defaultMessage="Cloud Nodes" />{" "}
        <QuestionCircleFilled />
        {" : "}
      </Tooltip>

      <StyledTotalCloudNodes>{cloudNodesTotal}</StyledTotalCloudNodes>
      <StyledTraffic>
        (
        <StyledNodeNetworkTrafficDate>
          <NodeNetworkTrafficDate />
        </StyledNodeNetworkTrafficDate>
        <StyledNetworkInfo>
          <FormattedMessage
            id="LicensesListCloudNetworkOutput.totalNetworkOutputTraffic"
            defaultMessage="Total network output traffic"
          />
          <StyledTooltip
            placement="top"
            title={
              <FormattedMessage
                id="LicensesListCloudNetworkOutput.info2"
                defaultMessage="Your cloud nodes total network output traffic between selected date"
              />
            }
          >
            <QuestionCircleFilled />
          </StyledTooltip>
          {": "}
        </StyledNetworkInfo>
        {totalNodesNetworkOutput == null && <StyledSpin spinning />}
        {totalNodesNetworkOutput && <StyledOutput totalNodesNetworkOutput={totalNodesNetworkOutput} isCloudNode />})
      </StyledTraffic>
    </div>
  );
}

const StyledNodeNetworkTrafficDate = styled.span`
  margin: 0 5px;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 4px;
`;

const StyledNetworkInfo = styled.span`
  font-size: 14px;
`;

const StyledTraffic = styled.span`
  margin-left: 10px;
`;

const StyledTotalCloudNodes = styled.span`
  color: ${themeColor.maritime};
`;

const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: ${themeColor.maritime};
  }
`;

const StyledOutput = styled(NodeNetworkOutput)`
  color: ${themeColor.maritime};
`;
