/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useState, useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { Button, Slider, Modal, InputNumber } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import "react-image-crop/dist/ReactCrop.css";

import OrganizationsServices from "../../services/organizations";
import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "../../hooks/useDebounceEffect";
import { errorNotification } from "../../lib/utils/notification";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 200,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const marks = {
  1: 1,
  5: 5,
  10: 10,
};

const CropImage = ({ setFileList, organizationName, filename, moreImages, aspect, setLoading }) => {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [openModalImg, setOpenModalImg] = useState(false);
  const hiddenFileInput = useRef(null);
  const [uploading, setUploading] = useState(false);

  const token = useSelector(ACCOUNT_SELECTORS.getAuthToken);
  const rotate = 0;

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setOpenModalImg(true);

      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }

    const canvas = previewCanvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.reset();
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const parsedLoading = (value) => {
    setUploading(value);
    if (setLoading) {
      setLoading(value);
    }
  };

  const handleUpload = (file) => {
    if (organizationName) {
      const formData = new FormData();
      const parsedFile = new File([file], file.name, { type: file.type });
      formData.append("file", parsedFile);
      formData.append("organizationName", organizationName);

      OrganizationsServices.uploadLogo(formData, {
        errorNotification,
        token,
        setFileList,
        setUploading: parsedLoading,
        setOpenModalImg,
      });
    } else {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setFileList([
          ...(moreImages || []),
          { name: file.name, type: file.type, originFileObj: file, thumbUrl: reader?.result },
        ]);
      });
      reader.readAsDataURL(file);
    }
  };

  const handleUploadImg = () => {
    const imageHash = Date.now();

    const canvas = document.getElementById("canvas");
    canvas.toBlob((blob) => {
      const file = new File([blob], `${filename}-${imageHash}.jpg`, { type: "image/jpeg" });
      handleUpload(file);
      setOpenModalImg(false);
      hiddenFileInput.current.value = null;
    }, "image/jpeg");
  };

  const handleCancel = () => {
    setOpenModalImg(false);
    setFileList([...(moreImages || [])]);
    hiddenFileInput.current.value = null;
    setImgSrc("");
  };

  const handleUploadClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div>
      <HiddenInput ref={hiddenFileInput} type="file" accept="image/*" onChange={onSelectFile} />
      <Button className="ant-upload ant-upload-select ant-upload-select-picture-card" onClick={handleUploadClick}>
        <span className="ant-upload">
          + <FormattedMessage id="CropImage.upload" defaultMessage="Upload" />
        </span>
      </Button>

      <Modal
        maskClosable={false}
        open={openModalImg}
        title={<FormattedMessage id="CropImage.editImg" defaultMessage="Edit image" />}
        onOk={handleUploadImg}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} loading={uploading} disabled={uploading}>
            <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
          </Button>,
          <Button key="submit" type="primary" onClick={handleUploadImg} loading={uploading} disabled={uploading}>
            <FormattedMessage id="general.submit" defaultMessage="Submit" />
          </Button>,
        ]}
      >
        <>
          <div>
            <label htmlFor="scale-input">
              <FormattedMessage id="CropImage.scale" defaultMessage="Scale" />:{" "}
            </label>
            <InputNumber
              min={0.1}
              max={10}
              step={0.1}
              value={scale}
              onChange={(selectedScale) => {
                setScale(selectedScale);
              }}
            />
            <Slider
              marks={marks}
              value={scale}
              disabled={!imgSrc}
              onChange={(selectedScale) => {
                setScale(selectedScale);
              }}
              min={0.1}
              max={10}
              step={0.1}
            />
          </div>
          {!!imgSrc && (
            <StyledReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(competedCrop) => setCompletedCrop(competedCrop)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </StyledReactCrop>
          )}
          {!!completedCrop && (
            <StyledCenterDiv>
              <canvas
                id="canvas"
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </StyledCenterDiv>
          )}
        </>
      </Modal>
    </div>
  );
};

const StyledCenterDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  background-color: #d9d9d9;
`;

const StyledReactCrop = styled(ReactCrop)`
  background-color: #d9d9d9;
`;

const HiddenInput = styled.input`
  display: none !important;
`;

export default CropImage;
