import React from "react";
import { Modal, Divider, Row, Col, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import iconUrl from "../../lib/images/icon192x192.png";

const { Title, Text } = Typography;
const year = moment().year();

function About({ handleShowModal, showModal }) {
  return (
    <Modal
      title={<FormattedMessage id="About.title" defaultMessage="About Quickstream" />}
      open={showModal}
      footer={null}
      onCancel={handleShowModal}
    >
      <Row type="flex" justify="center" gutter={12}>
        <Col span={6}>
          <StyledIcon />
        </Col>
        <Col span={18}>
          <Title level={2}>
            <FormattedMessage id="About.quickstream" defaultMessage="Quickstream" />
          </Title>
          <StyledDiv>
            <Text>
              <FormattedMessage id="About.pleaseCheckOur" defaultMessage="Please check our" />{" "}
            </Text>
            <a
              href="https://docs.cloud.quickstream.tech"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="knowledge base link"
            >
              <FormattedMessage id="About.knowledgeBase" defaultMessage="knowledge base " />
            </a>
          </StyledDiv>
          <div>
            <div>
              <Text>
                <FormattedMessage id="About.directContact" defaultMessage="For direct contact:" />
              </Text>
            </div>
            <div>
              <Text>
                <FormattedMessage id="general.www" defaultMessage="WWW:" />{" "}
              </Text>
              <a href="https://quickstream.tech/" target="_blank" rel="noopener noreferrer">
                www.quickstream.tech
              </a>
            </div>
            <div>
              <Text>
                <FormattedMessage id="general.email" defaultMessage="email:" />{" "}
              </Text>
              <a href="mailto:hello@quickstream.tech">hello@quickstream.tech</a>
            </div>
          </div>
        </Col>
      </Row>
      <Divider />
      <div>
        <span>{`© 2018–${year} tvhive sp. z o.o.`}</span>{" "}
        <FormattedMessage id="About.footer" defaultMessage="All rights reserved." />
      </div>
    </Modal>
  );
}

const StyledDiv = styled.div`
  margin-bottom: 10px;
`;

const StyledIcon = styled.div`
  width: 100px;
  height: 100px;
  background: url(${iconUrl});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

About.propTypes = {
  handleShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default About;
