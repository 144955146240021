import { FundProjectionScreenOutlined, MailOutlined, UserOutlined, PhoneOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Spin } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectors as ORGANIZATION_SELECTORS } from "../../ducks/organizations";

const MyOrganizationRoles = () => {
  const myOrganizationData = useSelector(ORGANIZATION_SELECTORS.getOrganizationData);

  if (!myOrganizationData) {
    return (
      <Spin
        size="large"
        spinning
        message={
          <FormattedMessage
            id="MyOrganizationRoles.loadingOrganizationData"
            defaultMessage="Loading organization data..."
          />
        }
      />
    );
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Form.Item label={<FormattedMessage id="MyOrganizationRoles.businessRole" defaultMessage="Business Role" />}>
          <Input addonBefore={<FundProjectionScreenOutlined />} disabled value={myOrganizationData.businessRole} />
        </Form.Item>

        <Form.Item label={<FormattedMessage id="MyOrganizationRoles.businessName" defaultMessage="Name" />}>
          <Input addonBefore={<UserOutlined />} disabled value={myOrganizationData.businessName} />
        </Form.Item>

        <Form.Item label={<FormattedMessage id="MyOrganizationRoles.businessPhone" defaultMessage="Phone" />}>
          <Input addonBefore={<PhoneOutlined />} disabled value={myOrganizationData.businessPhoneNumber} />
        </Form.Item>

        <Form.Item label={<FormattedMessage id="MyOrganizationRoles.businessEmail" defaultMessage="Email" />}>
          <Input addonBefore={<MailOutlined />} disabled value={myOrganizationData.businessEmail} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item label={<FormattedMessage id="MyOrganizationRoles.technicalRole" defaultMessage="Technical Role" />}>
          <Input addonBefore={<FundProjectionScreenOutlined />} disabled value={myOrganizationData.technicalRole} />
        </Form.Item>

        <Form.Item label={<FormattedMessage id="MyOrganizationRoles.technicalName" defaultMessage="Name" />}>
          <Input addonBefore={<UserOutlined />} disabled value={myOrganizationData.businessName} />
        </Form.Item>

        <Form.Item label={<FormattedMessage id="MyOrganizationRoles.technicalPhone" defaultMessage="Phone" />}>
          <Input addonBefore={<PhoneOutlined />} disabled value={myOrganizationData.technicalPhoneNumber} />
        </Form.Item>

        <Form.Item
          label={<FormattedMessage id="MyOrganizationRoles.technicalEmail" defaultMessage="Technical Email" />}
        >
          <Input addonBefore={<MailOutlined />} disabled value={myOrganizationData.technicalEmail} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default MyOrganizationRoles;
