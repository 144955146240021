import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import MQTTService from "../services/mqtt";
import { selectors as NODE_DEVICES_SELECTORS, SET_LOADING_DEVICES_LIST } from "../ducks/nodeDevices";

import { MESSAGE_TYPE } from "../lib/utils/constants";

import store from "../store";

function useNodeDevices() {
  const nodeDevicesLoaded = useSelector(NODE_DEVICES_SELECTORS.getLoadingDevices);
  const { cwid } = useParams();

  useEffect(() => {
    if (nodeDevicesLoaded === null) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_DEVICES,
        },
      });

      store.dispatch(SET_LOADING_DEVICES_LIST(true));
    }

    const getDeviceTimeout = setTimeout(() => {
      store.dispatch(SET_LOADING_DEVICES_LIST(false));
    }, 20000);

    return () => {
      clearTimeout(getDeviceTimeout);
    };
  }, [nodeDevicesLoaded, cwid]);
}

export default useNodeDevices;
