import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const actions = {
  SET_LOADING: createAction("LOADING/SET_LOADING"),
  SET_LOADING_NODE_CHANNELS: createAction("LOADING/SET_LOADING_NODE_CHANNELS"),
  SET_LOADING_LOG_LVL: createAction("LOADING/SET_LOADING_LOG_LVL"),
  SET_LOADING_LICENSES: createAction("LOADING/SET_LOADING_LICENSES"),
  SET_LOADING_HIST_STATS: createAction("LOADING/SET_LOADING_HIST_STATS"),
  SET_LOADING_NODE_NDIS: createAction("LOADING/SET_LOADING_NODE_NDIS"),
  CLEAR_LOADING: createAction("LOADING/CLEAR_LOADING"),
};

const initialState = new Map({
  dataType: "",
  loading: false,
  loadingCloudNodes: false,
  loadingNodeChannels: false,
  loadingNodeLogLvl: false,
  loadingLicenses: false,
  loadingHistStats: false,
  loadingNodeNDIS: false,
});

const reducer = handleActions(
  {
    [actions.SET_LOADING]: (state, { payload }) => state.merge({ loading: true, dataType: payload }),
    [actions.SET_LOADING_NODE_CHANNELS]: (state, { payload }) => state.merge({ loadingNodeChannels: payload }),
    [actions.SET_LOADING_LOG_LVL]: (state, { payload }) => state.merge({ loadingNodeLogLvl: payload }),
    [actions.SET_LOADING_LICENSES]: (state, { payload }) => state.merge({ loadingLicenses: payload }),
    [actions.SET_LOADING_HIST_STATS]: (state, { payload }) => state.merge({ loadingHistStats: payload }),
    [actions.SET_LOADING_NODE_NDIS]: (state, { payload }) => state.merge({ loadingNodeNDIS: payload }),
    [actions.CLEAR_LOADING]: () => initialState,
  },
  initialState
);

export const selectors = {
  getLoading: (state) => state.getIn(["loadingData", "loading"]),
  getLoadingDataType: (state) => state.getIn(["loadingData", "dataType"]),
  getLoadingCloudNodes: (state) => state.getIn(["loadingData", "loadingCloudNodes"]),
  getLoadingNodeChannels: (state) => state.getIn(["loadingData", "loadingNodeChannels"]),
  getLoadingLogsLvl: (state) => state.getIn(["loadingData", "loadingNodeLogLvl"]),
  getLoadingHistStats: (state) => state.getIn(["loadingData", "loadingHistStats"]),
  getLoadingLicenses: (state) => state.getIn(["loadingData", "loadingLicenses"]),
  getLoadingNodeNdis: (state) => state.getIn(["loadingData", "loadingNodeNDIS"]),
};

export default reducer;
