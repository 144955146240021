import React from "react";
import { useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import PropTypes from "prop-types";

const translations = defineMessages({
  localPort: {
    id: "LocalPortLabel.localPort",
    defaultMessage: "Local port",
  },
  tooltip: {
    id: "LocalPortLabel.tooltip",
    defaultMessage: `Enter numeric value or 0 for "automatic"`,
  },
});

const LocalPortLabel = ({ noTooltip }) => {
  const { formatMessage } = useIntl();

  if (noTooltip) {
    return formatMessage(translations.localPort);
  }

  return (
    <StyledLabel>
      <Tooltip placement="top" title={formatMessage(translations.tooltip)}>
        <span>{formatMessage(translations.localPort)}</span>
        <QuestionCircleFilled />
      </Tooltip>
    </StyledLabel>
  );
};

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

LocalPortLabel.propTypes = {
  noTooltip: PropTypes.bool,
};

LocalPortLabel.defaultProps = {
  noTooltip: false,
};

export default LocalPortLabel;
