import React, { useCallback, useState, useLayoutEffect, useRef } from "react";
import { InputNumber, Form } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import { required } from "../../lib/utils/formValidation";
import themeColor from "../../lib/style/theme";

const { Item } = Form;

const VideoCodecBitrateField = ({ name, label, disabled }) => {
  const inputEl = useRef(null);

  useLayoutEffect(() => {
    function handleMouseScroll() {
      inputEl.current.blur();
    }
    window.addEventListener("mousewheel", handleMouseScroll);

    return () => window.removeEventListener("mousewheel", handleMouseScroll);
  }, []);

  const [showWarning, setShowWarning] = useState(null);

  const handleChangeBitrate = useCallback((value) => {
    if (value >= 30000) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, []);

  return (
    <Item
      name={name}
      label={label}
      rules={[required]}
      extra={
        showWarning ? (
          <StyledWarning>
            <FormattedMessage
              id="VideoCodecBitrateField.warning"
              defaultMessage="Be aware that high bitrate can cause network issues or hang your machine."
            />
          </StyledWarning>
        ) : null
      }
    >
      <InputNumber type="number" disabled={disabled} onChange={handleChangeBitrate} min={0} ref={inputEl} />
    </Item>
  );
};

const StyledWarning = styled.span`
  color: ${themeColor.orange};
`;

VideoCodecBitrateField.defaultProps = {
  disabled: PropTypes.bool,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  label: PropTypes.string.isRequired,
};

VideoCodecBitrateField.defaultProps = { disabled: null };

export default VideoCodecBitrateField;
