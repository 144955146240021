import React, { useState, useEffect, useRef } from "react";
import { Select, Button, Row, Col, Card } from "antd";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";

import translations from "../../../lib/translations/nodeTranslations";
import { selectors as NODE_LIVE_STATS_SELECTORS } from "../../../ducks/nodeLiveStats";
import { GRAPH_OPTIONS, GRAPH_DATASET_OPTIONS } from "../../../lib/style/theme";

import StatisticsGraphTitle from "./StatisticsGraphTitle";

const { Option } = Select;

const translationsLocal = defineMessages({
  channelStopped: {
    id: "StatisticsGraph.stopped",
    defaultMessage: "(Stopped)",
  },
});

const StatisticsGraph = ({ data, channelId, type, codecId, streamId, isChannelStarted }) => {
  const statRef = useRef(null);
  const { formatMessage } = useIntl();
  useEffect(() => {
    Chart.register([zoomPlugin]);
  }, []);

  const handleResetZoom = () => {
    statRef.current.resetZoom();
  };

  const channelStatusText = !isChannelStarted ? formatMessage(translationsLocal.channelStopped) : "";

  const parseStatisticName = (statisticName) => {
    const translationExist = statisticName in translations;

    return translationExist
      ? `${formatMessage(translations[statisticName])} ${channelStatusText}`
      : `${statisticName} ${channelStatusText}`;
  };

  const selectorData = useSelector((state) =>
    NODE_LIVE_STATS_SELECTORS.getSelectedStatsTypes({ state, channelId, type })
  );

  const [selectedStat, setSelectedStat] = useState(selectorData[0]);
  const { data: filteredData, labels: filteredLabels } = useSelector(() =>
    NODE_LIVE_STATS_SELECTORS.getSelectedStatistics({ selectedStat, type, data, codecId, streamId })
  );

  const handleChange = (selectedValue) => {
    setSelectedStat(selectedValue);
    handleResetZoom();
  };

  const defaultStatDataSet = {
    labels: filteredLabels,
    datasets: [
      {
        label: parseStatisticName(selectedStat),
        backgroundColor: isChannelStarted ? "rgba(75,192,192,1)" : "rgba(240, 246, 246, 0.2)",
        borderColor: isChannelStarted ? "rgba(75,192,192,1)" : "rgba(240, 246, 246, 0.2)",
        pointBorderColor: isChannelStarted ? "rgba(75,192,192,1)" : "rgba(240, 246, 246, 0.2)",
        pointHoverBackgroundColor: isChannelStarted ? "rgba(75,192,192,1)" : "rgba(240, 246, 246, 0.2)",
        data: filteredData,
        ...GRAPH_DATASET_OPTIONS,
      },
    ],
  };

  const channelSourceDataSets = {
    labels: filteredLabels,
    datasets: [
      {
        label: isChannelStarted ? "Main (%)" : "Main (%) (Stopped)",
        backgroundColor: isChannelStarted ? "rgba(75,192,192,1)" : "rgba(240, 246, 246, 0.2)",
        borderColor: isChannelStarted ? "rgba(75,192,192,1)" : "rgba(240, 246, 246, 0.2)",
        pointBorderColor: isChannelStarted ? "rgba(75,192,192,1)" : "rgba(240, 246, 246, 0.2)",
        pointHoverBackgroundColor: isChannelStarted ? "rgba(75,192,192,1)" : "rgba(240, 246, 246, 0.2)",
        data: filteredData.mainData,
        ...GRAPH_DATASET_OPTIONS,
      },
      {
        label: isChannelStarted ? "Backup (%)" : "Backup (%) (Stopped)",
        backgroundColor: isChannelStarted ? "rgb(255, 241, 118)" : "rgba(255, 241, 118, 0.2)",
        borderColor: isChannelStarted ? "rgb(255, 241, 118)" : "rgba(255, 241, 118, 0.2)",
        pointBorderColor: isChannelStarted ? "rgb(255, 241, 118)" : "rgba(255, 241, 118, 0.2)",
        pointHoverBackgroundColor: isChannelStarted ? "rgb(255, 241, 118)" : "rgba(255, 241, 118, 0.2)",
        data: filteredData.backupData,
        ...GRAPH_DATASET_OPTIONS,
      },
      {
        label: isChannelStarted ? "Emergency (%)" : "Emergency (%) (Stopped)",
        backgroundColor: isChannelStarted ? "rgb(229, 115, 115)" : "rgba(229, 115, 115, 0.2)",
        borderColor: isChannelStarted ? "rgb(229, 115, 115)" : "rgba(229, 115, 115, 0.2)",
        pointBorderColor: isChannelStarted ? "rgb(229, 115, 115)" : "rgba(229, 115, 115, 0.2)",
        pointHoverBackgroundColor: isChannelStarted ? "rgb(229, 115, 115)" : "rgba(229, 115, 115, 0.2)",
        data: filteredData.emergencyData,
        ...GRAPH_DATASET_OPTIONS,
      },
    ],
  };

  const isChannelDefaultStats = selectedStat !== "processing";

  return (
    <Col md={24} xl={14} xxl={14}>
      <StyledCard title={<StatisticsGraphTitle />}>
        <Row type="flex" justify="space-between">
          <Col>
            <FormattedMessage id="StatisticsGraph.selectStatistic" defaultMessage="Select statistic:" />{" "}
            <Select onChange={handleChange} style={{ width: 250 }} defaultValue={selectedStat}>
              {selectorData.map((statisticName) => (
                <Option key={statisticName}>{parseStatisticName(statisticName)}</Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Button type="primary" onClick={handleResetZoom}>
              <FormattedMessage id="StatisticsGraph.resetZoom" defaultMessage="Reset zoom" />
            </Button>
          </Col>
        </Row>
        <Line
          data={isChannelDefaultStats ? defaultStatDataSet : channelSourceDataSets}
          options={GRAPH_OPTIONS}
          ref={statRef}
        />
      </StyledCard>
    </Col>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 10px;
`;

export default StatisticsGraph;
