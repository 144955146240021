import React, { useState } from "react";
import { Tabs, Card } from "antd";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import LicenseList from "../Admin/License/LicenseList";
import LicensesListCloud from "../../components/LicensesListCloud";
import PlayoutLicensesList from "../../components/PlayoutLicensesList";

import useRole from "../../hooks/useRole";

const TABS = {
  CLOUD: "cloudLicenses",
  LOCAL: "localLicenses",
  PLAYOUT: "playoutLicenses",
};

export default function LicenseContainer() {
  const { isAdmin } = useRole();
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") || TABS.CLOUD;
  const [activeKey, setActiveKey] = useState(tab);

  const handleChangeTab = (selectedTab) => {
    setActiveKey(selectedTab);

    setSearchParams({ tab: selectedTab });
  };

  const items = [
    {
      label: <FormattedMessage id="LicenseContainer.cloudLicenses" defaultMessage="Cloud licenses" />,
      key: TABS.CLOUD,
      children: <LicensesListCloud />,
    },
    {
      label: <FormattedMessage id="LicenseContainer.localLicenses" defaultMessage="Local licenses" />,
      key: TABS.LOCAL,
      children: <LicenseList />,
    },
    ...(isAdmin
      ? [
          {
            label: <FormattedMessage id="LicenseContainer.playoutLicenses" defaultMessage="Playout licenses" />,
            key: TABS.PLAYOUT,
            children: <PlayoutLicensesList />,
          },
        ]
      : []),
  ];

  return (
    <Card>
      <Tabs items={items} activeKey={activeKey} onChange={handleChangeTab} />
    </Card>
  );
}
