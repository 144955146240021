import React from "react";
import { Form, Input } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ImmutablePropTypes from "react-immutable-proptypes";
import { defineMessages, useIntl } from "react-intl";

import { selectors as CHANNEL_SELECTORS } from "../../../../ducks/nodeChannels";

import { max130characters, required } from "../../../../lib/utils/formValidation";

const { Item } = Form;

const translations = defineMessages({
  channelNameAlreadyExist: {
    id: "ChannelName.channelNameAlreadyExist",
    defaultMessage: "Channel name already exists",
  },
});

const ChannelName = ({ name, label, nodeChannels, channelId }) => {
  const { formatMessage } = useIntl();
  const uniqueValidator = (rule, value) => {
    const duplicatedChannelName = channelId
      ? nodeChannels.find((channelData) => channelData.config.name === value && channelData.channelId !== channelId)
      : nodeChannels.find((channelData) => channelData.config.name === value);

    if (duplicatedChannelName) {
      return Promise.reject(formatMessage(translations.channelNameAlreadyExist));
    }

    return Promise.resolve();
  };

  return (
    <Item name={name} label={label} rules={[{ validator: uniqueValidator }, max130characters, required]}>
      <Input type="text" autoComplete="off" />
    </Item>
  );
};

ChannelName.propTypes = {
  channelId: PropTypes.string,
  nodeChannels: ImmutablePropTypes.map.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  label: PropTypes.string.isRequired,
};

ChannelName.defaultProps = {
  channelId: null,
};

const mapStateToProps = (state) => ({
  nodeChannels: CHANNEL_SELECTORS.getChannels(state),
});

export default connect(mapStateToProps, null)(ChannelName);
