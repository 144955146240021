import React, { useContext } from "react";
import { Col, Row, Form, Switch, Divider } from "antd";
import { defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";

import InputNumberField from "../../../../Fields/InputNumberField";
import InputTextField from "../../../../Fields/InputTextField";

import PlayoutVideoStream from "./PlayoutVideoStream";
import PlayoutAudioStreams from "./PlayoutAudioStreams";
import PacketSizeLabel from "./PacketSizeLabel";
import AdvancedSettings from "../../../../AdvancedSettings";

import { max32characters, az09space, required, ASCIICharacters } from "../../../../../lib/utils/formValidation";
import generalTranslations from "../../../../../lib/translations";

import { FormV5Context } from "../../../NodeChannelFormV5";

const translations = defineMessages({
  transportStreamId: {
    id: "EnginePlayoutMuxer.transportStreamId",
    defaultMessage: "Transport Stream ID",
  },
  originalNetworkId: {
    id: "EnginePlayoutMuxer.originalNetworkId",
    defaultMessage: "Original Network ID",
  },
  pmtStartPid: {
    id: "EnginePlayoutMuxer.pmtStartPID",
    defaultMessage: "PMT Start PID",
  },
  startPid: {
    id: "EnginePlayoutMuxer.startPid",
    defaultMessage: "Start PID",
  },
  muxrate: {
    id: "EnginePlayoutMuxer.muxrate[kbps]",
    defaultMessage: "Muxrate [kbps]",
  },
  CBRModeOn: {
    id: "EnginePlayoutMuxer.CBRModeOn",
    defaultMessage: "CBR Mode ON",
  },
  CBRModeOff: {
    id: "EnginePlayoutMuxer.CBRModeOff",
    defaultMessage: "CBR Mode OFF",
  },
  SCTE: {
    id: "EnginePlayoutMuxer.SCTE",
    defaultMessage: "SCTE",
  },
  mpegtsServiceProvider: {
    id: "EnginePlayoutMuxer.mpegtsServiceProvider",
    defaultMessage: "Service provider",
  },
  mpegtsServiceName: {
    id: "EnginePlayoutMuxer.mpegtsServiceName",
    defaultMessage: "Service name",
  },
  mpegtsServiceId: {
    id: "EnginePlayoutMuxer.mpegtsServiceId",
    defaultMessage: "Service ID",
  },
  mpegtsVideoPid: {
    id: "EnginePlayoutMuxer.mpegtsVideoPid",
    defaultMessage: "Video PID",
  },
  mpegtsScte35Pid: {
    id: "EnginePlayoutMuxer.mpegtsScte35Pid",
    defaultMessage: "SCTE35 Pid",
  },
  mpegtsTransportStreamId: {
    id: "EnginePlayoutMuxer.mpegtsTransportStreamId",
    defaultMessage: "Transport stream ID",
  },
  mpegtsOriginalNetworkId: {
    id: "EnginePlayoutMuxer.mpegtsOriginalNetworkId",
    defaultMessage: "Original network ID",
  },
  mpegtsPcrPeriod: {
    id: "EnginePlayoutMuxer.mpegtsPcrPeriod",
    defaultMessage: "PCR period [ms]",
  },
  mpegtsPatPeriod: {
    id: "EnginePlayoutMuxer.mpegtsPatPeriod",
    defaultMessage: "Pat period [ms]",
  },
  mpegtsSdtPeriod: {
    id: "EnginePlayoutMuxer.mpegtsSdtPeriod",
    defaultMessage: "SDT period [ms]",
  },
  mpegtsNitPeriod: {
    id: "EnginePlayoutMuxer.mpegtsNitPeriod",
    defaultMessage: "NIT period [ms]",
  },
  mpegtsTdtPeriod: {
    id: "EnginePlayoutMuxer.mpegtsTdtPeriod",
    defaultMessage: "TDT period [ms]",
  },
  mpegtsEitPeriod: {
    id: "EnginePlayoutMuxer.mpegtsEitPeriod",
    defaultMessage: "EIT period [ms]",
  },
  maxDelayTweak: {
    id: "EnginePlayoutMuxer.maxDelayTweak",
    defaultMessage: "Max delay tweak [ms]",
  },
});

const { Item } = Form;

function EnginePlayoutMuxer({ disabled, prefix }) {
  const { formatMessage } = useIntl();

  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldsValue } = form;
  const useCBRModeSwitch = Form.useWatch([...prefix, "mpegtsCBRMode"], form);
  const scteEnabled = Form.useWatch([...prefix, "scteEnabled"], form);

  const handleChangeMuxrateSwitch = (muxrateSwitch) => {
    const outputs = getFieldValue(["outputs"]);

    outputs[[prefix[1]]].mpegtsCBRMode = muxrateSwitch;
    if (muxrateSwitch) {
      delete outputs[[prefix[1]]].mpegtsCBRMuxrate;
      outputs[[prefix[1]]].mpegtsCBRForcePacketSize = false;
    }
    setFieldsValue({ outputs });
  };

  return (
    <Row justify="start" gutter={[24, 24]}>
      <Col span={24}>
        <Row>
          <Col span={6}>
            <Item name={[...prefix, "mpegtsCBRMode"]} valuePropName="checked">
              <Switch
                disabled={disabled}
                defaultChecked={useCBRModeSwitch}
                checkedChildren={formatMessage(translations.CBRModeOn)}
                unCheckedChildren={formatMessage(translations.CBRModeOff)}
                onChange={handleChangeMuxrateSwitch}
              />
            </Item>
          </Col>

          {useCBRModeSwitch && (
            <>
              <Col span={6}>
                <InputNumberField
                  disabled={disabled}
                  name={[...prefix, "mpegtsCBRMuxrate"]}
                  label={formatMessage(translations.muxrate)}
                  rules={[required]}
                  min={1}
                />
              </Col>
              <Col span={6}>
                <Item
                  name={[...prefix, "mpegtsCBRForcePacketSize"]}
                  valuePropName="checked"
                  label={<PacketSizeLabel />}
                  rules={[required]}
                >
                  <Switch
                    disabled={disabled}
                    defaultChecked={false}
                    checkedChildren={formatMessage(generalTranslations.on)}
                    unCheckedChildren={formatMessage(generalTranslations.off)}
                  />
                </Item>
              </Col>
            </>
          )}
        </Row>
        <AdvancedSettings>
          <Row justify="start" gutter={24}>
            <Col span={12}>
              <InputTextField
                label={formatMessage(translations.mpegtsServiceName)}
                name={[...prefix, "mpegtsServiceName"]}
                disabled={disabled}
                rules={[max32characters, az09space, required]}
              />
            </Col>
            <Col span={12}>
              <InputTextField
                label={formatMessage(translations.mpegtsServiceProvider)}
                name={[...prefix, "mpegtsServiceProvider"]}
                disabled={disabled}
                rules={[max32characters, ASCIICharacters, required]}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsServiceId"]}
                label={formatMessage(translations.mpegtsServiceId)}
                rules={[required]}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsVideoPid"]}
                label={formatMessage(translations.mpegtsVideoPid)}
                rules={[required]}
                min={1}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsPmtStartPid"]}
                label={formatMessage(translations.startPid)}
                rules={[required]}
                min={32}
                max={8186}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsTransportStreamId"]}
                label={formatMessage(translations.mpegtsTransportStreamId)}
                rules={[required]}
                min={1}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsOriginalNetworkId"]}
                label={formatMessage(translations.mpegtsOriginalNetworkId)}
                min={-1000}
                max={5000}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "maxDelayTweak"]}
                label={formatMessage(translations.maxDelayTweak)}
                rules={[required]}
                min={0}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsPcrPeriod"]}
                label={formatMessage(translations.mpegtsPcrPeriod)}
                rules={[required]}
                min={1}
                max={2000}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsPatPeriod"]}
                label={formatMessage(translations.mpegtsPatPeriod)}
                rules={[required]}
                min={1}
                max={2000}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsSdtPeriod"]}
                label={formatMessage(translations.mpegtsSdtPeriod)}
                rules={[required]}
                min={1}
                max={2000}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsNitPeriod"]}
                label={formatMessage(translations.mpegtsNitPeriod)}
                rules={[required]}
                min={0}
                max={60000}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsTdtPeriod"]}
                label={formatMessage(translations.mpegtsTdtPeriod)}
                rules={[required]}
                min={0}
                max={60000}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "mpegtsEitPeriod"]}
                label={formatMessage(translations.mpegtsEitPeriod)}
                rules={[required]}
                min={0}
                max={60000}
              />
            </Col>

            <Col span={6}>
              <Item name={[...prefix, "scteEnabled"]} valuePropName="checked" label={formatMessage(translations.SCTE)}>
                <Switch
                  disabled={disabled}
                  defaultChecked={useCBRModeSwitch}
                  checkedChildren={formatMessage(generalTranslations.enabled)}
                  unCheckedChildren={formatMessage(generalTranslations.disabled)}
                />
              </Item>
            </Col>
            {scteEnabled && (
              <Col span={6}>
                <InputNumberField
                  disabled={disabled}
                  name={[...prefix, "mpegtsScte35Pid"]}
                  label={formatMessage(translations.mpegtsScte35Pid)}
                  rules={[required]}
                  min={1}
                />
              </Col>
            )}
          </Row>
        </AdvancedSettings>
      </Col>
      <PlayoutVideoStream disabled={disabled} prefix={prefix} />
      <Divider />
      <PlayoutAudioStreams disabled={disabled} prefix={prefix} />
    </Row>
  );
}

EnginePlayoutMuxer.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

export default EnginePlayoutMuxer;
