import React from "react";
import { Form, Select } from "antd";
import { FormattedMessage } from "react-intl";

import COUNTRIES_LIST from "../../../../lib/utils/countries";

const { Item } = Form;
const { Option } = Select;

const VirtualChannelRegion = () => {
  return (
    <Item label={<FormattedMessage id="VirtualChannelRegion.regions" defaultMessage="Regions" />} name="regions">
      <Select
        mode="multiple"
        allowClear
        showSearch
        placeholder={<FormattedMessage id="general.pleaseSelect" defaultMessage="Please select" />}
        optionFilterProp="children"
      >
        {COUNTRIES_LIST.map((country) => (
          <Option value={country.code} key={country.code}>
            {country.name} ({country.code})
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default VirtualChannelRegion;
