export default (cloudId) => {
  const parsedCloudId = cloudId
    .toString(10)
    .split("")
    .map((id, index) => {
      const dashesIndexArray = [2, 5];
      if (dashesIndexArray.includes(index)) {
        return `${id}-`;
      }

      return id;
    })
    .join("");

  return parsedCloudId;
};
