import React, { useState, useCallback } from "react";
import { Modal, Button, Form, Input } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import debounce from "lodash.debounce";

import CloudChannelsService from "../../services/cloudChannels";

import { PlatformAPI } from "../../lib/api";
import { errorNotification } from "../../lib/utils/notification";

const { Item } = Form;

const translations = defineMessages({
  validUsername: {
    id: "TransferVirtualChannels.validUsername",
    defaultMessage: "Please enter valid cloud username",
  },
  validUsernameRequired: {
    id: "TransferVirtualChannels.validUsernameRequired",
    defaultMessage: "Please input valid username",
  },
});

const TransferVirtualChannels = ({ transferVCModalOpen, setTransferVCModalOpen, selectedTransferCloudId }) => {
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { resetFields } = form;

  const handleFinish = async ({ username }) => {
    if (!username || !isValidUsername) {
      return;
    }

    await CloudChannelsService.editCloudChannel(
      { username, cloudId: selectedTransferCloudId },
      {
        errorNotification: errorNotification(formatMessage),
        setLoading,
      }
    );

    setTransferVCModalOpen(false);
    resetFields();
  };

  const handleSearch = useCallback(
    debounce(async (event) => {
      const inputUsernameValue = event?.target?.value;

      if (!inputUsernameValue) return;
      if (inputUsernameValue === "") return;

      const result = await PlatformAPI.checkUser(
        { username: inputUsernameValue },
        { errorNotification: errorNotification(formatMessage) }
      );

      setIsValidUsername(result);
    }, 350),
    [setIsValidUsername]
  );

  return (
    <Modal
      title={<FormattedMessage id="TransferVirtualChannels.transferHub" defaultMessage="Transfer hub channel" />}
      open={transferVCModalOpen}
      onCancel={() => {
        setTransferVCModalOpen(false);
        resetFields();
        setIsValidUsername(true);
      }}
      footer={null}
    >
      <Form onFinish={handleFinish} form={form}>
        <Item
          name="username"
          label={<FormattedMessage id="general.username" defaultMessage="Username" />}
          validateStatus={isValidUsername ? null : "error"}
          hasFeedback
          help={isValidUsername ? null : formatMessage(translations.validUsernameRequired)}
        >
          <Input
            type="text"
            style={{ width: "100%", marginRight: 8 }}
            placeholder={formatMessage(translations.validUsername)}
            onChange={handleSearch}
          />
        </Item>
        <StyledButton type="primary" htmlType="submit" disabled={loading} loading={loading}>
          <FormattedMessage id="general.submit" defaultMessage="Submit" />
        </StyledButton>
      </Form>
    </Modal>
  );
};

const StyledButton = styled(Button)`
  margin-top: 15px;
  display: block;
  width: 100%;
  justify-content: center;
`;

TransferVirtualChannels.propTypes = {
  selectedTransferCloudId: PropTypes.number,
  transferVCModalOpen: PropTypes.bool.isRequired,
  setTransferVCModalOpen: PropTypes.func.isRequired,
};

TransferVirtualChannels.defaultProps = {
  selectedTransferCloudId: null,
};

export default TransferVirtualChannels;
