import React, { useContext } from "react";
import { Form, Select } from "antd";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { selectors as NODE_SELECTORS } from "../../../../../ducks/node";

import globalTranslations from "../../../../../lib/translations";
import { PLAYOUT_OUTPUT_TYPES } from "../../../../../lib/utils/constants";
import { required } from "../../../../../lib/utils/formValidation";

import {
  DEFAULT_PLAYOUT_OUTPUT,
  DEFAULT_PLAYOUT_DECKLINK_OUTPUT,
} from "../../../../../lib/utils/defaultValues/defaultPlayoutOutput";

import { FormV5Context } from "../../../NodeChannelFormV5";

const { Option } = Select;
const { Item } = Form;

function SelectPlayoutOutputType({ prefix, disabled }) {
  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldsValue } = form;

  const { formatMessage } = useIntl();
  const outputCapabilities = useSelector(NODE_SELECTORS.getNodeOutputCapabilities) || [];

  const OUTPUTS_TYPES_ARRAY = Object.values(PLAYOUT_OUTPUT_TYPES);

  const checkIfOptionDisabled = (optionValue) => {
    if (outputCapabilities && !outputCapabilities.includes(optionValue)) {
      return true;
    }

    return false;
  };

  const handleChangeOutputType = (selectedType) => {
    const formOutputs = getFieldValue("outputs") || [];
    const formOutputData = getFieldValue(prefix);

    const selectedOutputUrlType = selectedType === PLAYOUT_OUTPUT_TYPES.playoutUrl.value;
    const selectedOutputSDIType = selectedType === PLAYOUT_OUTPUT_TYPES.playoutDecklink.value;

    if (selectedOutputUrlType) {
      const parsedFormOutputs = formOutputs.map((outputData) => {
        if (outputData?.name === formOutputData?.name) {
          const numberOfOutputs = formOutputs.length + 1;

          return {
            ...DEFAULT_PLAYOUT_OUTPUT,
            outputId: outputData?.outputId,
            name: outputData?.name ? outputData.name : `Output-${numberOfOutputs}`,
          };
        }

        return outputData;
      });

      setFieldsValue({ outputs: parsedFormOutputs });
    }

    if (selectedOutputSDIType) {
      const parsedFormOutputs = formOutputs.map((outputData) => {
        if (outputData?.name === formOutputData?.name) {
          const numberOfOutputs = formOutputs.length + 1;

          return {
            ...DEFAULT_PLAYOUT_DECKLINK_OUTPUT,
            outputId: outputData?.outputId,
            name: outputData?.name ? outputData.name : `Output-${numberOfOutputs}`,
          };
        }

        return outputData;
      });

      setFieldsValue({ outputs: parsedFormOutputs });
    }
  };

  return (
    <Item name={[...prefix, "type"]} label={formatMessage(globalTranslations.type)} rules={[required]}>
      <Select onChange={handleChangeOutputType} disabled={disabled}>
        {OUTPUTS_TYPES_ARRAY.map((option) => (
          <Option key={option.value} value={option.value} disabled={checkIfOptionDisabled(option.value)}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Item>
  );
}

SelectPlayoutOutputType.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  disabled: PropTypes.bool,
};

SelectPlayoutOutputType.defaultProps = {
  disabled: null,
};

export default SelectPlayoutOutputType;
