import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Form, Select, Divider, Space, Input, Button, Spin } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import store from "../../../../../store";

import MQTTService from "../../../../../services/mqtt";

import { selectors as NODE_SELECTORS } from "../../../../../ducks/node";
import { actions as LOADING_ACTIONS, selectors as LOADING_SELECTORS } from "../../../../../ducks/loadingData";

import InputNumberField from "../../../../Fields/InputNumberField";
import InputTextField from "../../../../Fields/InputTextField";

import { MESSAGE_TYPE } from "../../../../../lib/utils/constants";
import { required } from "../../../../../lib/utils/formValidation";

import { FormV5Context } from "../../../NodeChannelFormV5";

const { Item } = Form;
const { Option } = Select;

const translations = defineMessages({
  streamSource: {
    id: "InputNDI.streamSource",
    defaultMessage: "Source name",
  },
  streamAddr: {
    id: "InputNDI.streamAddr",
    defaultMessage: "Source address",
  },
  ipValidation: {
    id: "InputNDI.ipValidation",
    defaultMessage: "IPv4 address has an invalid format!",
  },
});

function InputNDI({ initialValue, disabled, prefix }) {
  const { formatMessage } = useIntl();
  const [newNDISource, setNewNDISource] = useState("");
  const [newNDIAddress, setNewNDIAddress] = useState("");
  const form = useContext(FormV5Context);
  const { setFieldValue } = form;

  const cwid = useSelector(NODE_SELECTORS.getShortFingerprint);
  const nodeNDIS = useSelector(NODE_SELECTORS.getNodeNdis) || [];
  const loadingNdis = useSelector(LOADING_SELECTORS.getLoadingNodeNdis);

  const [nodeNewNDISources, setNewNodeNDISources] = useState([]);
  const allNodeNDIValues = nodeNDIS && nodeNDIS.map((ndi) => ndi.streamSource);
  const allNodeNDIS = [
    ...nodeNDIS,
    ...nodeNewNDISources,
    ...(initialValue?.streamSource && !allNodeNDIValues.includes(initialValue?.streamSource) ? [initialValue] : []),
  ];

  const onChangeNewNDISource = (event) => {
    if ((event.target.value && event.target.value.length <= 32) || event.target.value.length === 0) {
      setNewNDISource(event.target.value);
    }
  };

  const onChangeNewNDIAddress = (event) => {
    if ((event.target.value && event.target.value.length <= 32) || event.target.value.length === 0) {
      setNewNDIAddress(event.target.value);
    }
  };

  const addNewNDI = (event) => {
    event.preventDefault();

    setNewNodeNDISources([
      ...nodeNewNDISources,
      {
        streamSource: newNDISource,
        streamAddr: newNDIAddress,
      },
    ]);
    setNewNDISource("");
    setNewNDIAddress("");
  };

  useEffect(() => {
    MQTTService.sendMessageV5({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.LIST_NDI_CHANNELS,
      },
    });

    store.dispatch(LOADING_ACTIONS.SET_LOADING_NODE_NDIS(true));
  }, [cwid]);

  const handleChangeNDISource = (selectedValue) => {
    const parsedValue = (selectedValue && JSON.parse(selectedValue)) || {};
    setFieldValue([...prefix, "streamAddr"], parsedValue?.streamAddr);
    setFieldValue([...prefix, "streamSource"], parsedValue?.streamSource);
  };

  return (
    <Spin
      spinning={loadingNdis}
      tip={<FormattedMessage id="InputNDI.loadingSourceList" defaultMessage="Loading source list" />}
    >
      <InputNumberField name={[...prefix, "inputId"]} disabled={disabled} hidden />
      <InputTextField name={[...prefix, "inputName"]} disabled={disabled} hidden />

      <Item
        label={<FormattedMessage id="InputNDI.sourceName" defaultMessage="Source name" />}
        name={[...prefix, "streamSource"]}
        rules={[required]}
      >
        <Select
          allowClear
          onChange={handleChangeNDISource}
          showSearch
          placeholder={
            <FormattedMessage id="InputNDI.selectOrAddNew" defaultMessage="Please select or add new source" />
          }
          disabled={disabled}
          optionFilterProp="children"
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                align="center"
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Space.Compact>
                  <Input
                    allowClear
                    value={newNDISource}
                    onChange={onChangeNewNDISource}
                    style={{ width: "50%" }}
                    placeholder={formatMessage(translations.streamSource)}
                  />
                  <Input
                    allowClear
                    value={newNDIAddress}
                    onChange={onChangeNewNDIAddress}
                    style={{ width: "50%" }}
                    placeholder={formatMessage(translations.streamAddr)}
                  />
                </Space.Compact>
                <Button
                  disabled={
                    disabled ||
                    newNDIAddress.length === 0 ||
                    newNDISource.length === 0 ||
                    allNodeNDIValues.includes(newNDISource)
                  }
                  type="primary"
                  shape="round"
                  onClick={addNewNDI}
                  icon={<PlusOutlined />}
                >
                  <span>
                    <FormattedMessage id="InputNDI.addNewSource" defaultMessage="Add new source" />
                  </span>
                </Button>
              </Space>
            </>
          )}
        >
          {allNodeNDIS.map((ndi) => (
            <Option value={JSON.stringify(ndi)} key={ndi.streamSource}>
              {ndi?.streamSource}
            </Option>
          ))}
        </Select>
      </Item>
      <InputTextField
        name={[...prefix, "streamAddr"]}
        label={<FormattedMessage id="InputNDI.sourceAddress" defaultMessage="Source address" />}
        rules={[required]}
        disabled
      />
    </Spin>
  );
}

InputNDI.propTypes = {
  initialValue: PropTypes.shape({
    streamSource: PropTypes.string,
    streamAddr: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

InputNDI.defaultProps = {
  initialValue: null,
  disabled: null,
};

export default InputNDI;
