import React, { useEffect, useCallback, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Table, Divider, Tag, Affix, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import LicenseServices from "../../../../services/license";
import { selectors as LICENSE_SELECTORS } from "../../../../ducks/license";
import { selectors as ACCOUNT_SELECTORS } from "../../../../ducks/account";
import { selectors as MQTT_CONNECTION_SELECTORS } from "../../../../ducks/mqtt";

import { errorNotification } from "../../../../lib/utils/notification";
import parseJwt from "../../../../lib/utils/parseJwt";
import themeColor from "../../../../lib/style/theme";
import { PERMANENT_LICENSE, ROLE } from "../../../../lib/utils/constants";
import setLicenseTagColor from "../../../../lib/utils/setLicenseTagColor";

import ExtendLicense from "../ExtendLicense";
import EditLicense from "../EditLicense";
import LicenseListAdminColumn from "./LicenseListAdminColumn";
import GlobalErrorBoundaries from "../../../../components/ErrorBoundaries/GlobalErrorBoundaries";
import LicenseListMissingNodeData from "./LicenseListMissingNodeData";
import LicenseListLicenseToken from "./LicenseListLicenseToken";
import LicenseListLicenseExpire from "./LicenseListLicenseExpire";

const LicenseList = ({ licenseList, role }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [showEditLicenseModal, setShowEditLicenseModal] = useState(false);
  const [selectedModalLicense, setSelectedModalLicense] = useState(null);
  const nodes = useSelector(MQTT_CONNECTION_SELECTORS.getNodes);

  const handleCloseModal = useCallback((setCallback) => {
    setCallback(false);
  }, []);

  const handleShowModal = useCallback((licenseId, setCallback) => {
    setSelectedModalLicense(licenseId);
    setCallback(true);
  }, []);

  useEffect(() => {
    LicenseServices.getLicenseList({ errorNotification: errorNotification(formatMessage) });
  }, [formatMessage]);

  const isAdmin = role === ROLE.ADMIN;

  const columns = [
    {
      title: <FormattedMessage id="LicenseList.licenseId" defaultMessage="License ID" />,
      dataIndex: "licenseId",
      key: "licenseId",
      sorter: (currentValue, nextValue) => currentValue.licenseId.localeCompare(nextValue.licenseId),
    },
    {
      title: <FormattedMessage id="LicenseList.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
      sorter: (currentValue, nextValue) => currentValue.username.localeCompare(nextValue.username),
    },
    {
      title: <FormattedMessage id="LicenseList.licenseType" defaultMessage="License type" />,
      dataIndex: "licenseType",
      key: "licenseType",
      sorter: (currentValue, nextValue) => currentValue.licenseType.localeCompare(nextValue.licenseType),
      render: (licenseType, record) => (
        <Tag color={setLicenseTagColor(licenseType)} key={record.id}>
          {licenseType && licenseType.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: <FormattedMessage id="LicenseList.engines" defaultMessage="Channels" />,
      dataIndex: "engines",
      key: "engines",
      sorter: (currentValue, nextValue) => currentValue.engines - nextValue.engines,
    },
    {
      title: <FormattedMessage id="LicenseList.cnn" defaultMessage="Node" />,
      dataIndex: "cnn",
      key: "cnn",
      align: "center",
    },
    {
      title: <FormattedMessage id="LicenseList.expiration" defaultMessage="Expiration" />,
      dataIndex: "licenseExpire",
      key: "licenseExpire",
      sorter: (currentValue, nextValue) => currentValue.licenseExpire.localeCompare(nextValue.licenseExpire),
      render: (licenseExpire, record) => (
        <LicenseListLicenseExpire
          record={record}
          isAdmin={isAdmin}
          handleShowModal={handleShowModal}
          setShowModal={setShowModal}
        />
      ),
    },
    {
      title: <FormattedMessage id="LicenseList.licenseToken" defaultMessage="License" />,
      dataIndex: "encryptedLicenseToken",
      key: "encryptedLicenseToken",
      render: (encryptedLicenseToken, record) => {
        return (
          <LicenseListLicenseToken
            record={record}
            isAdmin={isAdmin}
            handleShowModal={handleShowModal}
            setShowEditLicenseModal={setShowEditLicenseModal}
          />
        );
      },
    },
  ];

  const parsedColumns = isAdmin ? [...columns, ...LicenseListAdminColumn] : columns;

  const parsedLicenseList = licenseList
    .map((license) => {
      const nodeData = nodes.find((node) => node.cwid === license.cwid);

      return {
        ...parseJwt(license.licenseToken),
        id: license.id,
        licenseToken: license.licenseToken,
        encryptedLicenseToken: license.encryptedLicenseToken,
        fingerprint: license.fingerprint,
        updatedAt: license.updatedAt,
        cnn: nodeData?.cnn || <LicenseListMissingNodeData cwid={license.cwid} />,
      };
    })
    .reverse();

  const setClassName = (record) => {
    if (record.licenseExpire === PERMANENT_LICENSE) {
      return null;
    }

    if (moment().isAfter(record.licenseExpire)) {
      return "licenseExpired";
    }

    return "";
  };

  return (
    <GlobalErrorBoundaries>
      <StyledTable
        columns={parsedColumns}
        rowClassName={setClassName}
        dataSource={parsedLicenseList}
        pagination={false}
        bordered
        role="table"
        rowKey={(record) => record.licenseId}
        scroll={{ x: "calc(100vh - 250px)" }}
      />
      <Modal
        title={
          <span>
            <FormattedMessage id="LicenseList.editModalTitle" defaultMessage="Edit license hardware ID" />
            {": "}
            {selectedModalLicense}
          </span>
        }
        open={showEditLicenseModal}
        footer={null}
        onCancel={() => handleCloseModal(setShowEditLicenseModal)}
      >
        <EditLicense
          selectedModalLicense={selectedModalLicense}
          handleCloseModal={() => handleCloseModal(setShowEditLicenseModal)}
        />
      </Modal>
      {isAdmin && (
        <>
          <Divider />
          <Affix offsetBottom={15}>
            <FullSizeButton type="primary" onClick={() => navigate("/admin/license/new")}>
              <FormattedMessage id="LicenseList.addNewLicense" defaultMessage="Add new license" />
            </FullSizeButton>
          </Affix>
          <Modal
            title={
              <span>
                <FormattedMessage id="LicenseList.extendModalTitle" defaultMessage="Extend license" />
                {": "}
                {selectedModalLicense}
              </span>
            }
            open={showModal}
            footer={null}
            onCancel={() => handleCloseModal(setShowModal)}
          >
            <ExtendLicense
              selectedModalLicense={selectedModalLicense}
              handleCloseModal={() => handleCloseModal(setShowModal)}
            />
          </Modal>
        </>
      )}
    </GlobalErrorBoundaries>
  );
};

LicenseList.propTypes = {
  licenseList: PropTypes.arrayOf(
    PropTypes.shape({
      encryptedLicenseToken: PropTypes.string.isRequired,
      fingerprint: PropTypes.string,
      id: PropTypes.number.isRequired,
      licenseToken: PropTypes.string.isRequired,
      updatedAt: PropTypes.string,
    })
  ).isRequired,
  role: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  licenseList: LICENSE_SELECTORS.getLicense(state),
  role: ACCOUNT_SELECTORS.getRole(state),
});

const FullSizeButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
`;

const StyledTable = styled(Table)`
  .licenseExpired {
    background-color: ${themeColor.brightRed};
  }
`;

export default connect(mapStateToProps, null)(LicenseList);
