import React from "react";
import { Form, Input } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";

import { required } from "../../../../../lib/utils/formValidation";

import InputNumberField from "../../../../Fields/InputNumberField";
import InputTextField from "../../../../Fields/InputTextField";

const { Item } = Form;

const translations = defineMessages({
  formats: {
    id: "InputRTMP.formats",
    defaultMessage: "rtmp:// or rtmpt:// or rtmpte:// or rtmps:// or rtmpte:// or rtmpts://",
  },
  wrongFormat: {
    id: "InputRTMP.wrongFormat",
    defaultMessage: "Supported formats: rtmp:// or rtmpt:// or rtmpte:// or rtmps:// or rtmpte:// or rtmpts://",
  },
  streamNameOrKey: {
    id: "InputRTMP.streamNameOrKey",
    defaultMessage: "Stream name or Key",
  },
});

function InputRTMP({ prefix, disabled }) {
  const { formatMessage } = useIntl();

  return (
    <>
      <InputNumberField name={[...prefix, "inputId"]} disabled={disabled} hidden />
      <InputTextField name={[...prefix, "inputName"]} disabled={disabled} hidden />
      <Item
        name={[...prefix, "url"]}
        label={<FormattedMessage id="InputRTMP.url" defaultMessage="Url" />}
        rules={[
          required,
          {
            pattern: new RegExp(
              // eslint-disable-next-line max-len
              "^(rtmp://|rtmpt://|rtmpte://|rtmps://|rtmpte://|rtmpts://)"
            ),
            message: formatMessage(translations.wrongFormat),
          },
        ]}
      >
        <Input placeholder={formatMessage(translations.formats)} disabled={disabled} />
      </Item>
      <InputTextField
        name={[...prefix, "key"]}
        disabled={disabled}
        label={formatMessage(translations.streamNameOrKey)}
        rules={[required]}
      />
    </>
  );
}

InputRTMP.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
};

InputRTMP.defaultProps = {
  disabled: null,
};
export default InputRTMP;
