import { defineMessages } from "react-intl";

export default defineMessages({
  // cloud connection/send message errors 0-100
  0: {
    id: "cloudError.0",
    defaultMessage: "Missing translations for this cloud error",
  },
  1: {
    id: "cloudError.1",
    defaultMessage: "Cloud connection error",
  },
  2: {
    id: "cloudError.2",
    defaultMessage: "Invalid authentication",
  },
  3: {
    id: "cloudError.3",
    defaultMessage: "Invalid websocket command sent",
  },
  4: {
    id: "cloudError.4",
    defaultMessage: "Receiver is not connected to cloud",
  },
  5: {
    id: "cloudError.5",
    defaultMessage: "Cloud connection offline",
  },
  6: {
    id: "cloudError.6",
    defaultMessage: "Cloud connection reconnecting...",
  },

  // cloud fetch data errors 101-200
  100: {
    id: "cloudError.100",
    defaultMessage: "There is no node connected to cloud for this user",
  },

  // cloud events
  cloudDisconnection: {
    id: "cloudEvent.cloudDisconnection",
    defaultMessage: "Cloud disconnected",
  },
  cloudConnected: {
    id: "cloudEvent.cloudConnected",
    defaultMessage: "Cloud connected",
  },
  nodeHostnameUpdated: {
    id: "cloudEvent.nodeNameUpdated",
    defaultMessage: "Node name was updated",
  },
});
