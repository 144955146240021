import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Select, Divider, Space, Input, Button, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import store from "../../../../store";
import MQTTService from "../../../../services/mqtt";

import { selectors as CONNECTION_SELECTORS } from "../../../../ducks/node";
import { actions as LOADING_ACTIONS, selectors as LOADING_SELECTORS } from "../../../../ducks/loadingData";

import { MESSAGE_TYPE } from "../../../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

const InputNDI = ({ initialValue, disabled }) => {
  const [newNDI, setNewNDI] = useState("");

  const cwid = useSelector(CONNECTION_SELECTORS.getNodeCwid);
  const nodeNDIS = useSelector(CONNECTION_SELECTORS.getNodeNdis) || [];
  const loadingNdis = useSelector(LOADING_SELECTORS.getLoadingNodeNdis);

  const [nodeNewNDISources, setNewNodeNDISources] = useState([]);
  const allNodeNDIS = [
    ...nodeNDIS,
    ...nodeNewNDISources,
    ...(initialValue && !nodeNDIS.includes(initialValue) ? [initialValue] : []),
  ];

  const onChangeNewNDI = (event) => {
    if (event.target.value && event.target.value.length <= 32) {
      setNewNDI(event.target.value);
    }
  };

  const addNewNDI = (event) => {
    event.preventDefault();

    setNewNodeNDISources([...nodeNewNDISources, newNDI]);
    setNewNDI("");
  };

  useEffect(() => {
    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.LIST_NDI_CHANNELS,
      },
    });
    store.dispatch(LOADING_ACTIONS.SET_LOADING_NODE_NDIS(true));
  }, [cwid]);

  return (
    <Spin
      spinning={loadingNdis}
      tip={<FormattedMessage id="InputNDI.loadingSourceList" defaultMessage="Loading source list" />}
    >
      <Item label={<FormattedMessage id="InputNDI.source" defaultMessage="Source" />} name={["input", "streamSource"]}>
        <Select
          allowClear
          showSearch
          placeholder={
            <FormattedMessage id="InputNDI.selectOrAddNew" defaultMessage="Please select or add new source" />
          }
          disabled={disabled}
          optionFilterProp="children"
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                align="center"
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input value={newNDI} onChange={onChangeNewNDI} />
                <Button
                  disabled={disabled || newNDI.length === 0 || nodeNDIS.includes(newNDI)}
                  type="primary"
                  shape="round"
                  onClick={addNewNDI}
                  icon={<PlusOutlined />}
                >
                  <span>
                    <FormattedMessage id="InputNDI.addNewSource" defaultMessage="Add new source" />
                  </span>
                </Button>
              </Space>
            </>
          )}
        >
          {allNodeNDIS.map((ndi) => (
            <Option value={ndi} key={ndi}>
              {ndi}
            </Option>
          ))}
        </Select>
      </Item>
    </Spin>
  );
};

InputNDI.propTypes = {
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
};

InputNDI.defaultProps = {
  initialValue: null,
  disabled: null,
};

export default InputNDI;
