import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import store from "../store";

import MQTTService from "../services/mqtt";
import { selectors as NODE_SELECTORS } from "../ducks/node";
import { actions as LOADING_ACTIONS } from "../ducks/loadingData";
import { selectors as MQTT_SELECTORS } from "../ducks/mqtt";

import { MESSAGE_TYPE, MQTT_STATUS } from "../lib/utils/constants";

function useNodeFingerprint() {
  const { cwid } = useParams();
  const MQTTStatus = useSelector(MQTT_SELECTORS.getStatus);
  const MQTTConnection = useSelector(MQTT_SELECTORS.getMqttConnection);
  const isConnection = MQTTConnection && MQTTStatus === MQTT_STATUS.CONNECTED;
  const nodesData = useSelector(MQTT_SELECTORS.getNodes);
  const selectedNodeData = nodesData.find((node) => node.cwid === cwid);

  const fingerprint = useSelector(NODE_SELECTORS.getFingerprint) || selectedNodeData?.encryptedFingerprint;

  useEffect(() => {
    const requestFingerprint = () => {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.FINGERPRINT,
        },
      });
      store.dispatch(LOADING_ACTIONS.SET_LOADING(MESSAGE_TYPE.FINGERPRINT));
    };

    if (isConnection && !fingerprint) {
      requestFingerprint();
    }
  }, [isConnection, fingerprint]);

  return fingerprint;
}

export default useNodeFingerprint;
