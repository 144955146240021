import React, { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import { Col, Select, Button, Row } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";

import { GRAPH_OPTIONS, GRAPH_DATASET_OPTIONS } from "../../../../lib/style/theme";

const { Option } = Select;

const NodeStatisticsGraph = ({ data, selectedStat, setSelectedStat }) => {
  const statRef = useRef(null);
  useEffect(() => {
    Chart.register(zoomPlugin);
  }, []);

  const handleResetZoom = () => {
    statRef.current.resetZoom();
  };

  const dataKeysArray = Object.keys(data);
  const selectedData = data[selectedStat] || [];

  const handleChange = (selectedValue) => {
    setSelectedStat(selectedValue);
    handleResetZoom();
  };

  const parsedData = {
    labels: selectedData.map((statistic) => statistic.time),
    datasets: [
      {
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        data: selectedData.map((statistic) => statistic.value),
        label: `${selectedStat} [Mbps]`,
        pointBorderColor: "rgba(75,192,192,1)",
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        ...GRAPH_DATASET_OPTIONS,
      },
    ],
  };

  return (
    <>
      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="NodeStatisticsGraph.selectInterface" defaultMessage="Select network interface:" />{" "}
          <Select onChange={handleChange} style={{ width: 250 }} value={selectedStat}>
            {dataKeysArray.map((networkInterfaces) => (
              <Option key={networkInterfaces}>{networkInterfaces}</Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Button type="primary" onClick={handleResetZoom}>
            <FormattedMessage id="NodeStatisticsGraph.resetZoom" defaultMessage="Reset zoom" />
          </Button>
        </Col>
      </Row>
      <Line data={parsedData} options={GRAPH_OPTIONS} ref={statRef} />
    </>
  );
};

NodeStatisticsGraph.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape(
      PropTypes.arrayOf(
        PropTypes.shape({
          time: PropTypes.string,
          value: PropTypes.number,
        })
      )
    ),
    PropTypes.object,
  ]).isRequired,
  selectedStat: PropTypes.string,
  setSelectedStat: PropTypes.func.isRequired,
};

NodeStatisticsGraph.defaultProps = {
  selectedStat: null,
};

export default NodeStatisticsGraph;
