import dayjs from "dayjs";

export default function getTimeRangeData(selectedValue) {
  const now = dayjs();
  const startOfHour = now.startOf("hour");
  const lastFiveMinute = startOfHour.add(Math.floor(now.minute() / 5) * 5, "minute");

  switch (selectedValue) {
    case 1:
      return {
        start: lastFiveMinute.subtract(60, "minute").unix(),
        end: dayjs().unix(),
        value: selectedValue,
      };

    case 6:
      return {
        start: lastFiveMinute.subtract(6, "hour").unix(),
        end: dayjs().unix(),
        value: selectedValue,
      };

    case 24:
      return {
        start: lastFiveMinute.subtract(1, "day").unix(),
        end: dayjs().unix(),
        value: selectedValue,
      };

    case 48:
      return {
        start: lastFiveMinute.subtract(2, "day").unix(),
        end: dayjs().unix(),
        value: selectedValue,
      };

    case 72:
      return {
        start: lastFiveMinute.subtract(3, "day").unix(),
        end: dayjs().unix(),
        value: selectedValue,
      };

    default:
      return {
        start: lastFiveMinute.subtract(5, "minute").unix(),
        end: dayjs().unix(),
        value: selectedValue,
      };
  }
}
