import React from "react";
import { Row } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import SelectStream from "../../EngineDirect/InputStreamSection/SelectStream";

function InputStreamVideoSelector({ disabled, prefix }) {
  return (
    <Row gutter={24}>
      <SelectStream
        disabled={disabled}
        fieldPrefix={prefix}
        prefix={prefix}
        label={<FormattedMessage id="InputStreamVideoSelector.label" defaultMessage="Video stream" />}
      />
    </Row>
  );
}

InputStreamVideoSelector.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default InputStreamVideoSelector;
