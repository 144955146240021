import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { LogoutOutlined, ReloadOutlined, SaveOutlined } from "@ant-design/icons";
import { Badge, Affix, Button, Card, Col, Popconfirm, Row, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Navigate, useNavigate } from "react-router-dom";

import { selectors as ORGANIZATION_SELECTORS } from "../../ducks/organizations";
import themeColor from "../../lib/style/theme";
import globalTranslation from "../../lib/translations";
import { SEO_STATUS } from "../../lib/utils/constants";
import { errorNotification } from "../../lib/utils/notification";
import { VALIDATION_COLORS } from "../../lib/utils/organizationColors";
import OrganizationsServices from "../../services/organizations";
import OrganizationDetail from "../OrganizationStep0/OrganizationDetail";
import MissingOrganizationData from "./MissingOrganizationData";
import MyOrganizationRoles from "./MyOrganizationRoles";

const OrganizationInformation = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { Ribbon } = Badge;

  const organizationList = useSelector(ORGANIZATION_SELECTORS.getOrganizationsList);
  const organizationsPublicList = useSelector(ORGANIZATION_SELECTORS.getOrganizationsPublicList);
  const organizationId = useSelector(ORGANIZATION_SELECTORS.getOrganizationId);

  const myOrganizationData =
    organizationList.find((organization) => organization.id === organizationId) ||
    organizationsPublicList.find((organization) => organization.id === organizationId);
  const organizationData = useSelector(ORGANIZATION_SELECTORS.getOrganizationData);

  if (!organizationId) {
    return <Navigate to="/organization/edit" replace />;
  }

  const handleLeaveOrganization = useCallback(() => {
    OrganizationsServices.leaveOrganizationRole({
      errorNotification: errorNotification(formatMessage),
    });
  }, []);

  const GetRoleRibbonColor = (status) => {
    if (status === SEO_STATUS.VERIFICATION) return VALIDATION_COLORS.DEFAULT;
    if (status === SEO_STATUS.REJECTED) return VALIDATION_COLORS.REJECTED;
    if (status === SEO_STATUS.PRIVATE || !organizationData.public) return VALIDATION_COLORS.PRIVATE;
    if (status === SEO_STATUS.ACCEPTED) return VALIDATION_COLORS.ACCEPTED;

    return "";
  };

  const GetRoleRibbonText = (status) => {
    if (status === SEO_STATUS.VERIFICATION)
      return (
        <Tooltip
          title={
            <FormattedMessage
              id="general.catalogValidation"
              defaultMessage={`Please note that Quickstream verifies all data that are visible
          in the public catalog therefore it may take a while to publish all changes in the database.`}
            />
          }
          color={themeColor.gray}
        >
          <StyledReloadOutlined spin />
          <StyledSpan>
            <FormattedMessage id="organizationValidation.inValidation" defaultMessage="In validation process" />
          </StyledSpan>
        </Tooltip>
      );
    if (!organizationData.public)
      return (
        <span>
          <FormattedMessage id="organizationValidation.private" defaultMessage="Private" />
        </span>
      );
    if (status === SEO_STATUS.REJECTED)
      return (
        <span>
          <FormattedMessage id="organizationValidation.rejected" defaultMessage="Rejected" />
        </span>
      );
    if (status === SEO_STATUS.ACCEPTED || organizationData.public)
      return (
        <span>
          <FormattedMessage id="organizationValidation.public" defaultMessage="Public" />
        </span>
      );

    return "";
  };

  return (
    <>
      {!myOrganizationData && <MissingOrganizationData />}
      {myOrganizationData && (
        <Row gutter={[24, 24]}>
          <OrganizationDetail selectedOrganizationData={myOrganizationData} />
          <br />
          <Col span={24}>
            <Ribbon
              text={GetRoleRibbonText(organizationData.status)}
              color={GetRoleRibbonColor(organizationData.status)}
            >
              <StyledCard title="My Roles" bordered>
                <MyOrganizationRoles />
              </StyledCard>
            </Ribbon>
          </Col>

          <StyledManageSection>
            <Affix offsetBottom={15}>
              <Col>
                <Button
                  style={{ marginRight: 10 }}
                  icon={<SaveOutlined />}
                  type="dashed"
                  onClick={() => navigate("/organization/edit")}
                >
                  <span>
                    <FormattedMessage id="OrganizationInformation.editData" defaultMessage="Edit" />
                  </span>
                </Button>
                <Popconfirm
                  placement="top"
                  title={
                    <span>
                      <FormattedMessage
                        id="OrganizationInformation.leaveOrganizationInfo"
                        // eslint-disable-next-line max-len
                        defaultMessage={`Are you sure, you want to delete all your data from organization: `}
                      />
                      {myOrganizationData?.name}?
                    </span>
                  }
                  onConfirm={handleLeaveOrganization}
                  okText={formatMessage(globalTranslation.yes)}
                  cancelText={formatMessage(globalTranslation.no)}
                >
                  <Button type="primary" danger icon={<LogoutOutlined />}>
                    <span>
                      <FormattedMessage
                        id="OrganizationInformation.leaveOrganization"
                        defaultMessage="Leave organization"
                      />
                    </span>
                  </Button>
                </Popconfirm>
              </Col>
            </Affix>
          </StyledManageSection>
        </Row>
      )}
    </>
  );
};

const StyledReloadOutlined = styled(ReloadOutlined)`
  font-size: 12px;
`;

const StyledManageSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 1rem;
`;

const StyledSpan = styled.span`
  margin-left: 5px;
`;

const StyledCard = styled(Card)`
  width: 100%;

  .ant-card-head {
    background-color: ${themeColor.orange};
  }

  .ant-card-head-title {
    color: #ffffff;
  }
`;

export default OrganizationInformation;
