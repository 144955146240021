/* eslint-disable consistent-return */
import { bindActionCreators } from "redux";
import { defineMessages } from "react-intl";

import store, { history } from "../store";
import { actions as ACCOUNT_ACTIONS } from "../ducks/account";
import { actions as NAVIGATION_ACTIONS } from "../ducks/navigation";
import { actions as LICENSE_ACTIONS } from "../ducks/license";
import { CLEAR_ORGANIZATIONS_DATA } from "../ducks/organizations";

import { AuthAPI, PlatformAPI } from "../lib/api/api";
import getMessageFromErrorCode from "../lib/utils/getMessageFromErrorCode";
import getUserFromJWToken from "../lib/utils/getUserFromJWToken";
import { MESSAGE_TYPE } from "../lib/utils/constants";

import MQTTService from "./mqtt";

const translations = defineMessages({
  deleteSuccess: {
    id: "account.deleteSuccess",
    defaultMessage: "Your account was successfully removed",
  },
  sendEmailSuccessfully: {
    id: "account.sendEmailSuccessfully",
    defaultMessage: "Activation email has been sent - please activate account with link in this email",
  },
  updatePasswordSuccess: {
    id: "account.updatePasswordSuccess",
    defaultMessage: "Your password was successfully updated",
  },
  updateUsernameSuccess: {
    id: "account.updateUsernameSuccess",
    defaultMessage: "Your username was successfully updated",
  },
  resetPasswordSuccess: {
    id: "account.resetPasswordSuccess",
    defaultMessage: "Your password was successfully updated",
  },
  emailSend: {
    id: "account.emailSend",
    defaultMessage: "Email with reset password link has been sent to provided email address",
  },
  accountActivationSuccess: {
    id: "account.accountActivationSuccessfully",
    defaultMessage: "Account registered",
  },
  emailOptInChangeSuccess: {
    id: "account.emailOptInChangeSuccess",
    defaultMessage: "Email Opt-In status has been changed",
  },
  auth2FASuccess: {
    id: "account.auth2FASuccess",
    defaultMessage: "Tour two factor authentication has been changed",
  },
  invitationSendSuccess: {
    id: "account.invitationSendSuccess",
    defaultMessage: "Invitations have been sent",
  },
  twoFactorAuthSendSendSuccess: {
    id: "account.twoFactorAuthSendSendSuccess",
    defaultMessage: "Two factor authentication email has been send",
  },
});

const actions = bindActionCreators(
  {
    loginUser: (data, { errorNotification, turnstile, resetFields }) => {
      const { username, password, remember, turnstileToken } = data;

      return async () => {
        try {
          const response = await AuthAPI.login(
            { header: { username, password, turnstileToken }, data: { turnstileToken } },
            { errorNotification, turnstile, resetFields }
          );

          const { token } = response;
          if (!token) return;
          if (remember) {
            window.localStorage.setItem("authToken", token);
          }
          store.dispatch(ACCOUNT_ACTIONS.SAVE_ACCOUNT_DATA({ ...getUserFromJWToken(token), token }));

          MQTTService.initialize();

          history.push("/");
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
    setTurnstileToken: (token) => {
      return () => {
        store.dispatch(ACCOUNT_ACTIONS.TURNSTILE_TOKEN(token));
      };
    },
    forgotPassword: (data, { errorNotification, successNotification }) => {
      return async () => {
        try {
          const response = await AuthAPI.forgotPassword(data, { errorNotification });
          if (response?.status !== MESSAGE_TYPE.ERROR) {
            successNotification(translations.emailSend);
            history.push("/login");
          }
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
    auth2faLogin: (data, { errorNotification }) => {
      return async () => {
        try {
          const response = await AuthAPI.auth2faLogin(data, { errorNotification });
          if (response?.status !== MESSAGE_TYPE.ERROR) {
            const { token } = response;
            if (!token) return;
            window.localStorage.setItem("authToken", token);
            MQTTService.initialize();
            store.dispatch(ACCOUNT_ACTIONS.SAVE_ACCOUNT_DATA({ ...getUserFromJWToken(token), token }));

            history.push("/");
          }
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          history.push("/login");
        }
      };
    },
    auth2fa: (data, { errorNotification, successNotification }) => {
      return async () => {
        try {
          await AuthAPI.auth2fa(data, { errorNotification });
          successNotification(translations.twoFactorAuthSendSendSuccess);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          history.push("/login");
        }
      };
    },
    accountActivation: (data, { errorNotification, successNotification }) => {
      return async () => {
        try {
          const response = await AuthAPI.accountActivation(data, { errorNotification });
          if (response?.status !== MESSAGE_TYPE.ERROR) {
            successNotification(translations.accountActivationSuccess);
            history.push("/login");
          }
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          history.push("/login");
        }
      };
    },
    createUser: (data, { errorNotification, successNotification, adminMode, afterSubmitCallback }) => {
      const { username, password, role = "user", email, termsOfUse, privacyPolicy, emailOptIn, turnstileToken } = data;

      return async () => {
        try {
          await AuthAPI.createUser(
            {
              username,
              password,
              role,
              email,
              emailOptIn,
              termsOfUse,
              privacyPolicy,
              turnstileToken,
            },
            errorNotification
          );
          successNotification(translations.sendEmailSuccessfully);
          if (adminMode) {
            afterSubmitCallback();
          }

          return true;
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
    invitationsAccount:
      (data, { errorNotification, successNotification, setLoadingInvitations }) =>
      async () => {
        try {
          await AuthAPI.invitationsAccount(data, { errorNotification });
          successNotification(translations.invitationSendSuccess);
          setLoadingInvitations(false);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    createInvitationUser: (data, { errorNotification, successNotification, setLoading }) => {
      const { token } = data;

      return async () => {
        try {
          const response = await AuthAPI.createUserInvitation(data, errorNotification);
          await PlatformAPI.activateChannelPermission({ userId: response.userId, token }, { errorNotification });
          successNotification(translations.accountActivationSuccess);
          history.push("/login");
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setLoading(false);
        }
      };
    },
    editPassword: (formData, { errorNotification, successNotification }) => {
      const { newPassword, oldPassword } = formData;

      return async () => {
        try {
          await AuthAPI.editPassword(
            {
              newPassword,
              oldPassword,
            },
            errorNotification
          );
          successNotification(translations.updatePasswordSuccess);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
    getUserData:
      ({ errorNotification, setLoadingData, username, setUserData }) =>
      async () => {
        try {
          setLoadingData(true);
          const userData = await AuthAPI.getUserData({ errorNotification, username });
          setUserData(userData);
          setLoadingData(false);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setLoadingData(false);
        }
      },
    editAuth2FA: (auth2fa, { errorNotification, successNotification }) => {
      return async () => {
        try {
          await AuthAPI.editAuth2fa(auth2fa, errorNotification);
          successNotification(translations.auth2FASuccess);

          return true;
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
    editEmailOptIn: (emailOptInState, { errorNotification, successNotification }) => {
      return async () => {
        try {
          await AuthAPI.editEmailOptIn(emailOptInState, errorNotification);
          successNotification(translations.emailOptInChangeSuccess);

          return true;
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
    resetPassword: (formData, { errorNotification, successNotification }) => {
      return async () => {
        try {
          await AuthAPI.resetPassword(formData, errorNotification);
          successNotification(translations.resetPasswordSuccess);
          history.push("/login");
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
    editUsername: (formData, { errorNotification, successNotification }) => {
      const { newUsername } = formData;

      return async () => {
        try {
          await AuthAPI.editUsername(
            {
              newUsername,
            },
            errorNotification
          );
          successNotification(translations.updateUsernameSuccess);
          window.localStorage.removeItem("authToken");
          store.dispatch(ACCOUNT_ACTIONS.CLEAR_ACCOUNT_DATA());
          store.dispatch(NAVIGATION_ACTIONS.CLEAR_ACTIVE_KEY());
          store.dispatch(CLEAR_ORGANIZATIONS_DATA());
          store.dispatch(LICENSE_ACTIONS.CLEAR_LICENSE());
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
    deleteAccount: ({ errorNotification, successNotification }) => {
      return async () => {
        try {
          await AuthAPI.deleteCurrentAccount({ errorNotification });
          successNotification(translations.deleteSuccess);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
  },

  store.dispatch
);

export default actions;
