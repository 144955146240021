import React, { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Row, Col, Divider, Form, Switch } from "antd";
import styled from "styled-components";

import InputStreamUdpSection from "./InputStreamUdpSection";
import InputStreamSrtSection from "./InputStreamSrtSection";
import InputStreamQSSection from "../../InputStreamQSSection";
import InputStreamSectionBackup from "../InputStreamSectionBackup";

import { CONNECTION_METHOD_INPUT, QUICKSTREAM_METHOD_V2 } from "../../../lib/utils/constants";
import InputConnectionMethod from "../../Fields/InputConnectionMethod";
import CloudChannelList from "../../Fields/CloudChannelList";
import InputTextFiled from "../../Fields/InputTextField";
import AdvancedSettings from "../../AdvancedSettings";

import globalTranslations from "../../../lib/translations";
import inputURLDefaultValues, {
  DEFAULT_INPUT_BACKUP_VALUES,
  DEFAULT_INPUT_BACKUP_TRIGGERS,
} from "../../../lib/utils/defaultValues/inputURLDefaultValues";

import {
  MAIN_CONNECTION_TYPE,
  INPUT_BACKUP_TRIGGERS_TYPE,
  INPUT_BACKUP_TYPE,
} from "../../../lib/utils/types/inputURLTypes";

const { Item } = Form;

const translations = defineMessages({
  cloudIdLabel: {
    id: "InputStreamURL.cloudChannel",
    defaultMessage: "Cloud channel",
  },
  backupInput: {
    id: "InputStreamURL.backupInput",
    defaultMessage: "Backup Input",
  },
});

const SRT_METHODS = [CONNECTION_METHOD_INPUT.inSrtListener.value, CONNECTION_METHOD_INPUT.inSrtCaller.value];
const UDP_METHODS = [CONNECTION_METHOD_INPUT.inUdpUnicast.value, CONNECTION_METHOD_INPUT.inUdpMulticast.value];

const InputStreamURL = ({
  channelList,
  disabled,
  getFieldValue,
  initialValue,
  setFieldsValue,
  stundAddress,
  sharedChannelList,
  form,
}) => {
  const [connectionMethod, setConnectionMethod] = useState(
    initialValue?.mainConnection?.type || CONNECTION_METHOD_INPUT.inUdpMulticast.value
  );
  const [switchInputBackupState, setSwitchInputBackupState] = useState(initialValue?.switchInputBackup);

  const isUDPMethod = UDP_METHODS.includes(connectionMethod);
  const isSRTMethod = SRT_METHODS.includes(connectionMethod);

  const isQuickstreamMethod = connectionMethod === QUICKSTREAM_METHOD_V2.inQSDirect.value;
  const { formatMessage } = useIntl();

  const handleChangeBackup = (value) => {
    setSwitchInputBackupState(value);
    if (value) {
      const inputFormData = getFieldValue(["input"]);
      const input = inputURLDefaultValues({ input: inputFormData, stundAddress, sharedChannelList });
      setFieldsValue({
        input: {
          ...input,
          switchInputBackup: value,
          backupConnection: DEFAULT_INPUT_BACKUP_VALUES,
          backupTriggers: DEFAULT_INPUT_BACKUP_TRIGGERS,
        },
      });
    } else {
      const inputFormData = getFieldValue(["input"]);
      delete inputFormData.backupConnection;
      delete inputFormData.backupTriggers;
      setFieldsValue({ input: inputFormData });
    }
  };

  return (
    <>
      <Row type="flex" justify="start" gutter={24}>
        <Col span={16}>
          <InputConnectionMethod
            name={["input", "mainConnection", "type"]}
            disabled={disabled}
            setConnectionMethod={setConnectionMethod}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
          />
        </Col>
        <Divider />
        {isQuickstreamMethod && (
          <>
            <Col span={24}>
              <CloudChannelList
                channelList={channelList}
                disabled={disabled}
                getFieldValue={getFieldValue}
                initialValue={
                  initialValue?.cloudId && initialValue?.cloudName
                    ? {
                        cloudId: initialValue?.cloudId,
                        channelName: initialValue?.cloudName,
                        permissionId: initialValue?.permissionId,
                        description: initialValue?.description,
                      }
                    : null
                }
                name={["input", "mainConnection"]}
                label={formatMessage(translations.cloudIdLabel)}
                sameAsValidation="output"
                setFieldsValue={setFieldsValue}
                isInput
              />
            </Col>
            <StyledHiddenCol span={4}>
              <InputTextFiled name={["input", "mainConnection", "cloudName"]} disabled={disabled} />
            </StyledHiddenCol>
            <StyledHiddenCol span={4}>
              <InputTextFiled name={["input", "mainConnection", "permissionId"]} disabled={disabled} />
            </StyledHiddenCol>
            <StyledHiddenCol span={4}>
              <InputTextFiled name={["input", "mainConnection", "description"]} disabled={disabled} />
            </StyledHiddenCol>
          </>
        )}
        {isUDPMethod && (
          <InputStreamUdpSection
            connectionMethod={connectionMethod}
            disabled={disabled}
            prefix={["input", "mainConnection"]}
          />
        )}
        {isSRTMethod && (
          <InputStreamSrtSection
            connectionMethod={connectionMethod}
            disabled={disabled}
            initialValue={initialValue}
            prefix={["input", "mainConnection"]}
            form={form}
          />
        )}
        {isQuickstreamMethod && (
          <AdvancedSettings>
            <InputStreamQSSection
              disabled={disabled}
              getFieldValue={getFieldValue}
              isInput
              name={["input", "mainConnection"]}
              prefix={["input", "mainConnection"]}
              setFieldsValue={setFieldsValue}
              form={form}
            />
          </AdvancedSettings>
        )}
      </Row>
      {!isQuickstreamMethod && (
        <>
          <Divider />
          <Item
            name={["input", "switchInputBackup"]}
            valuePropName="checked"
            label={formatMessage(translations.backupInput)}
          >
            <Switch
              disabled={disabled}
              defaultChecked={switchInputBackupState}
              checkedChildren={formatMessage(globalTranslations.enabled)}
              unCheckedChildren={formatMessage(globalTranslations.disabled)}
              onChange={handleChangeBackup}
            />
          </Item>
          {switchInputBackupState && (
            <InputStreamSectionBackup
              disabled={disabled}
              initialValue={initialValue}
              getFieldValue={getFieldValue}
              setFieldsValue={setFieldsValue}
              form={form}
            />
          )}
        </>
      )}
    </>
  );
};

const StyledHiddenCol = styled(Col)`
  visibility: hidden;
  display: none;
`;

InputStreamURL.propTypes = {
  disabled: PropTypes.bool,
  // channelList: PropTypes.oneOfType([
  //   PropTypes.arrayOf(
  //     PropTypes.shape({
  //       cloudId: PropTypes.number.isRequired,
  //       channelName: PropTypes.string.isRequired,
  //       id: PropTypes.number.isRequired,
  //       isEncrypted: PropTypes.bool.isRequired,
  //       isPublic: PropTypes.bool.isRequired,
  //       userId: PropTypes.string.isRequired,
  //     })
  //   ),
  //   PropTypes.array,
  // ]).isRequired,
  initialValue: PropTypes.shape({
    ...MAIN_CONNECTION_TYPE,
    ...INPUT_BACKUP_TYPE,
    ...INPUT_BACKUP_TRIGGERS_TYPE,
  }),
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  stundAddress: PropTypes.string,
  sharedChannelList: PropTypes.array,
};

InputStreamURL.defaultProps = {
  disabled: null,
  initialValue: null,
  stundAddress: null,
  sharedChannelList: null,
};

export default InputStreamURL;
