import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import CloudServices from "../../../services/cloud";

import { PlatformAPI } from "../../../lib/api";
import { errorNotification } from "../../../lib/utils/notification";

const { Item } = Form;
const translations = defineMessages({
  validUsername: {
    id: "ShareNodeForm.validUsername",
    defaultMessage: "Please enter valid cloud username",
  },
  usernameValidationMessage: {
    id: "ShareNodeForm.usernameRequired",
    defaultMessage: "Please input valid username",
  },
  usernameExists: {
    id: "ShareNodeForm.usernameIsAlreadyAdded",
    defaultMessage: "Username already have access to node",
  },
  shareNodeUsername: {
    id: "ShareNodeForm.shareNodeUsername",
    defaultMessage: "Input username to share this node",
  },
});

const ShareNodeFormModal = ({ fingerprint, cnn, fetchNodeShares, nodeShares }) => {
  const [form] = Form.useForm();
  const { resetFields, validateFields } = form;
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [isValidUsername, setIsValidUsername] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    resetFields();
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const validate = async () => {
      await validateFields();
    };

    validate();
  }, [isValidUsername]);

  const handleFinish = async ({ username }) => {
    await CloudServices.connectNodeToCloud(
      { fingerprint, cnn, username },
      {
        errorNotification: errorNotification(formatMessage),
      }
    );
    fetchNodeShares();
    handleCloseModal();
  };

  const handleSearch = debounce(async (event) => {
    const inputUsernameValue = event?.target?.value;

    if (!inputUsernameValue) return;
    if (inputUsernameValue === "") return;

    const result = await PlatformAPI.checkUser(
      { username: inputUsernameValue },
      { errorNotification: errorNotification(formatMessage) }
    );

    setIsValidUsername(result);
  }, 350);

  const userAPIValidator = async (rule, value) => {
    const existingUsers = nodeShares.map((share) => share.username);

    if (existingUsers.includes(value)) return Promise.reject(formatMessage(translations.usernameExists));
    if (!isValidUsername) return Promise.reject(formatMessage(translations.usernameValidationMessage));

    return Promise.resolve();
  };

  return (
    <>
      <StyledShareButton type="dashed" onClick={handleShowModal}>
        <PlusOutlined /> <FormattedMessage id="ShareNodeForm.addNewAccess" defaultMessage="Add new access" />
      </StyledShareButton>
      <Modal
        title={<FormattedMessage id="ShareNodeForm.newNodeAccess" defaultMessage="New node access" />}
        open={showModal}
        footer={null}
        onCancel={handleCloseModal}
      >
        <Form onFinish={handleFinish} form={form}>
          <Item
            name="username"
            label={<FormattedMessage id="general.username" defaultMessage="Username" />}
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                validator: userAPIValidator,
              },
            ]}
          >
            <Input
              type="text"
              style={{ width: "100%", marginRight: 8 }}
              placeholder={formatMessage(translations.shareNodeUsername)}
              onChange={handleSearch}
            />
          </Item>
          <StyledButton type="primary" htmlType="submit">
            <FormattedMessage id="general.submit" defaultMessage="Submit" />
          </StyledButton>
        </Form>
      </Modal>
    </>
  );
};

const StyledButton = styled(Button)`
  margin-top: 15px;
  display: block;
  width: 100%;
  justify-content: center;
`;

const StyledShareButton = styled(Button)`
  width: 100%;
`;

ShareNodeFormModal.propTypes = {
  fingerprint: PropTypes.string.isRequired,
  cnn: PropTypes.string.isRequired,
  fetchNodeShares: PropTypes.func.isRequired,
  nodeShares: PropTypes.arrayOf(PropTypes.shape({ username: PropTypes.string })).isRequired,
};

export default ShareNodeFormModal;
