import React from "react";
import styled from "styled-components";

import ChannelsValidation from "../../ChannelsValidation";

export default function UniqHubChannelName({ channels }) {
  return (
    <>
      {channels.map((channel) => (
        <StyledDiv>
          {`${channel.channelName} (${channel.username})`}{" "}
          <ChannelsValidation channelData={{ status: channel.status, public: true }} />
        </StyledDiv>
      ))}
    </>
  );
}

const StyledDiv = styled.div`
  padding: 5px 0;
`;
