import React, { useEffect, useState, useCallback } from "react";
import { Card, Table, Popconfirm, Radio, Modal, Form, Button } from "antd";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import CloudChannelsServices from "../../services/cloudChannels";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";
import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

import { errorNotification } from "../../lib/utils/notification";
import parseCloudId from "../../lib/utils/parseCloudId";
import { SEO_STATUS } from "../../lib/utils/constants";
import globalTranslation from "../../lib/translations";

import AllChannelsActionButtons from "./AllChannelsActionButtons";
import NewChannelFormModal from "./ChannelModal/NewChannelFormModal";
import SearchFilter from "../../components/Filters/SearchFilter";
import HubChannelDetailView from "../../components/HubChannelDetailView";

const translations = defineMessages({
  channelName: {
    id: "AllChannelsList.name",
    defaultMessage: "Name",
  },
  id: {
    id: "AllChannelsList.id",
    defaultMessage: "ID",
  },
  owner: {
    id: "AllChannelsList.owner",
    defaultMessage: "Owner",
  },
  preview: {
    id: "OwnChannelList.preview",
    defaultMessage: "Preview",
  },
});

const AllChannelsList = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [confirmVisible, setConfirmVisible] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [loadingDeleteVChannel, setLoadingDeleteVChannel] = useState(false);
  const [selectedChannelData, setSelectedChannelData] = useState({});
  const [isVisibleEditChannelModal, setIsVisibleEditChannelModal] = useState(false);
  const [currentCloudId, setCurrentCloudId] = useState(null);
  const allChannelsList = useSelector(CLOUD_CHANNELS_SELECTORS.getAllChannels);
  const username = useSelector(ACCOUNT_SELECTORS.getUser);
  const [openModal, setOpenModal] = useState(false);
  const [hubChannelData, setHubChannelData] = useState({});

  const handleOpenModal = useCallback((selectedData) => {
    setOpenModal(true);
    setHubChannelData(selectedData);
  }, []);

  function fetchChannels() {
    CloudChannelsServices.getAllChannels({ errorNotification: errorNotification(formatMessage) });
  }

  const handleChangeStatus = useCallback(() => {
    CloudChannelsServices.changeVirtualChannelStatus(confirmVisible, {
      errorNotification: errorNotification(formatMessage),
    });
    setConfirmVisible(null);
  }, [confirmVisible]);

  const hideConfirm = useCallback(() => {
    setConfirmVisible(null);
  }, [setConfirmVisible]);

  const showConfirm = useCallback(
    (data) => {
      setConfirmVisible(data);
    },
    [setConfirmVisible]
  );

  useEffect(() => {
    fetchChannels();
  }, []);

  const columns = [
    {
      key: "channelName",
      dataIndex: "channelName",
      title: formatMessage(translations.channelName),
      sorter: (currentValue, nextValue) => currentValue.channelName.localeCompare(nextValue.channelName),
      ...SearchFilter({
        dataIndex: "virtualChannelName",
        placeholder: "name",
      }),
      render: (channelName, record) => (
        <Button type="link" onClick={() => handleOpenModal(record)}>
          {channelName}
        </Button>
      ),
    },
    {
      key: "cloudId",
      dataIndex: "cloudId",
      width: 130,
      title: formatMessage(translations.id),
      sorter: (currentValue, nextValue) => currentValue.cloudId - nextValue.cloudId,
      render: (cloudId) => {
        return parseCloudId(cloudId);
      },
    },
    {
      key: "preview",
      dataIndex: "preview",
      width: 100,
      align: "center",
      title: formatMessage(translations.preview),
      render: (_text, record) => {
        return (
          <Button
            icon={<SearchOutlined />}
            type="dashed"
            shape="circle"
            href={`${process.env.REACT_APP_HUB_URL}/preview/channels/${record.cloudId}-${record.channelName}`}
            target="_blank"
            rel="noopener noreferrer"
          />
        );
      },
    },
    {
      key: "username",
      dataIndex: "username",
      title: formatMessage(translations.owner),
      sorter: (currentValue, nextValue) => currentValue.username.localeCompare(nextValue.username),
      ...SearchFilter({
        dataIndex: "username",
        placeholder: "username",
      }),
      defaultFilteredValue: [username],
      render: (tableUsername) => <Link to={`/users/${tableUsername}`}>{tableUsername}</Link>,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 240,
      filters: [
        {
          text: SEO_STATUS.VERIFICATION.toUpperCase(),
          value: SEO_STATUS.VERIFICATION,
        },
        {
          text: SEO_STATUS.ACCEPTED.toUpperCase(),
          value: SEO_STATUS.ACCEPTED,
        },
        {
          text: SEO_STATUS.REJECTED.toUpperCase(),
          value: SEO_STATUS.REJECTED,
        },
      ],
      defaultFilteredValue: [SEO_STATUS.VERIFICATION],
      onFilter: (value, record) => value && record?.status?.indexOf(value) === 0,
      render: (status, { cloudId }) => {
        return (
          <Popconfirm
            key={cloudId}
            open={confirmVisible?.cloudId === cloudId}
            placement="top"
            title={
              <>
                <FormattedMessage
                  id="AllChannelsList.change"
                  defaultMessage="Are you sure you want to change role to"
                />{" "}
                <b>{confirmVisible?.status.toUpperCase()}</b>
              </>
            }
            onConfirm={() => handleChangeStatus()}
            onCancel={hideConfirm}
            okText={formatMessage(globalTranslation.yes)}
            cancelText={formatMessage(globalTranslation.no)}
          >
            <Radio.Group
              value={status}
              onChange={(event) => showConfirm({ status: event.target.value, cloudId })}
              disabled={loadingDeleteVChannel}
              loading={loadingDeleteVChannel}
            >
              <Radio.Button value={SEO_STATUS.ACCEPTED}>{SEO_STATUS.ACCEPTED.toUpperCase()}</Radio.Button>
              <Radio.Button value={SEO_STATUS.REJECTED}>{SEO_STATUS.REJECTED.toUpperCase()}</Radio.Button>
            </Radio.Group>
          </Popconfirm>
        );
      },
    },
    {
      title: <FormattedMessage id="OrganizationsList.actions" defaultMessage="Actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (_data, record) => (
        <AllChannelsActionButtons
          setCurrentCloudId={setCurrentCloudId}
          setSelectedChannelData={setSelectedChannelData}
          setIsVisibleEditChannelModal={setIsVisibleEditChannelModal}
          record={record}
          loadingDeleteVChannel={loadingDeleteVChannel}
          setLoadingDeleteVChannel={setLoadingDeleteVChannel}
        />
      ),
    },
  ];

  return (
    <Card>
      <Table columns={columns} dataSource={allChannelsList} rowKey={(record) => record.id} />
      <Modal
        width="60%"
        title={hubChannelData?.channelName}
        open={openModal}
        footer={false}
        onCancel={() => setOpenModal(false)}
      >
        <HubChannelDetailView hubChannelData={hubChannelData} />
      </Modal>
      {isVisibleEditChannelModal && (
        <Modal
          destroyOnClose
          width={800}
          title={<FormattedMessage id="EditSharedChannelModal.editHubChannel" defaultMessage="Edit hub channel" />}
          open={isVisibleEditChannelModal}
          footer={null}
          onCancel={() => {
            setIsVisibleEditChannelModal(false);
            setCurrentStep(0);
          }}
        >
          <NewChannelFormModal
            allChannels
            editMode
            handleShowModal={setIsVisibleEditChannelModal}
            form={form}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            currentCloudId={currentCloudId || selectedChannelData?.cloudId}
            setCurrentCloudId={setCurrentCloudId}
          />
        </Modal>
      )}
    </Card>
  );
};

export default AllChannelsList;
