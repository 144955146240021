import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Tag } from "antd";
import { useSearchParams } from "react-router-dom";

import SearchFilter from "../../../components/Filters/SearchFilter";
import MultiSelectFilter from "../../../components/Filters/MultiSelectFilter";
import NodeV5ChannelListActionButtons from "./NodeV5ChannelListActionButtons";
import NodeChannelsListBitrate from "./NodeChannelsListBitrate";
import SourceSwitch from "../../../components/SourceSwitch";
import ClientsConnected from "../../../components/NodeChannelFormV5/DetailSection/ClientsConnected";

import getActiveInputType from "../../../lib/utils/getActiveInputType";

const translations = defineMessages({
  name: { id: "NodeChannelsListColumns.name", defaultMessage: "Name" },
  type: { id: "NodeChannelsListColumns.type", defaultMessage: "Type" },
  logs: {
    id: "NodeChannelsListColumns.logs",
    defaultMessage: "Logs",
  },
  stats: {
    id: "NodeChannelsListColumns.stats",
    defaultMessage: "Stats",
  },
  mainInput: {
    id: "NodeChannelsListColumns.mainInput",
    defaultMessage: "Main",
  },
  backupInput: {
    id: "NodeChannelsListColumns.backupInput",
    defaultMessage: "Backup",
  },
  inputSource: {
    id: "NodeChannelsListColumns.inputSource",
    defaultMessage: "Input source",
  },
  channelStatus: {
    id: "NodeChannelsListColumns.channelStatus",
    defaultMessage: "Channel status",
  },
  licenseStatus: {
    id: "NodeChannelsListColumns.licenseStatus",
    defaultMessage: "License status",
  },
  actions: {
    id: "NodeChannelsListColumns.actions",
    defaultMessage: "Actions",
  },
  inputBitrate: {
    id: "NodeChannelsListColumns.inputBitrate",
    defaultMessage: "Input bitrate",
  },
  connected: {
    id: "NodeChannelsListColumns.connected",
    defaultMessage: "Connected",
  },
  waitingForStart: {
    id: "NodeChannelsListColumns.waitingForStart",
    defaultMessage: "Waiting for start",
  },
});

const NodeChannelsListColumns = ({ cwid, cnn }) => {
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const inputStatus = searchParams.get("inputStatus");
  const search = searchParams.get("search");

  const parsedInputStatus = inputStatus && inputStatus.includes(",") ? inputStatus.split(",") : [inputStatus];

  const columns = [
    {
      key: "name",
      title: formatMessage(translations.name),
      ...SearchFilter({ cwid, cnn, dataIndex: "searchAll", placeholder: "all", hidden: true }),
      ...(search ? { filteredValue: [search] } : { filteredValue: null }),
      className: "searchAllFilter",
    },
    {
      key: "config",
      title: formatMessage(translations.type),
      align: "center",
      render: ({ config: { type } }) => {
        return <Tag color="default">{type && type.toUpperCase()}</Tag>;
      },
    },
    {
      key: "status",
      align: "center",
      title: formatMessage(translations.channelStatus),
      dataIndex: "status",
      ...MultiSelectFilter(),
      ...(inputStatus ? { filteredValue: [...parsedInputStatus] } : { filteredValue: null }),
    },
    {
      key: "InputSource",
      align: "center",
      title: formatMessage(translations.inputSource),
      dataIndex: "status",
      width: 250,
      render: (status, { channelId, config: { type } }) => {
        const { playingSource, requestedStatusText, inputs: inputsStatus } = status;

        if (inputsStatus && inputsStatus.length === 0) {
          return <span>N/D</span>;
        }

        return (
          <SourceSwitch
            channelId={channelId}
            playingSource={playingSource}
            requestedStatusText={requestedStatusText}
            inputsStatus={inputsStatus}
            type={type}
          />
        );
      },
    },
    {
      key: "status",
      align: "center",
      title: formatMessage(translations.inputBitrate),
      dataIndex: "status",
      render: (status, record) => {
        const inputType = getActiveInputType({
          playingSource: record?.status?.playingSource,
          config: record?.config,
        });

        return (
          <NodeChannelsListBitrate
            channelId={status?.channelId}
            channelStatus={status?.channelStatusText}
            inputType={inputType}
            playingSource={status?.playingSource}
          />
        );
      },
    },
    {
      key: "status",
      align: "center",
      title: formatMessage(translations.connected),
      dataIndex: "status",
      render: ({ connected, outputs }) => {
        return <ClientsConnected connected={connected} outputs={outputs} />;
      },
    },
    {
      key: "actions",
      fixed: "right",
      width: 400,
      title: formatMessage(translations.actions),
      render: (text, { status, channelId, config }) => {
        return <NodeV5ChannelListActionButtons channelConfig={config} channelId={channelId} status={status} />;
      },
    },
  ];

  return columns;
};

export default NodeChannelsListColumns;
