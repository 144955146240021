import React from "react";
import { Card, Col, Row, Divider, Select, Form, Switch } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import InputNumberField from "../../../../Fields/InputNumberField";

import globalTranslations from "../../../../../lib/translations";
import { required } from "../../../../../lib/utils/formValidation";
import { MUXER_TYPES } from "../../../../../lib/utils/constants";

import MuxerPrograms from "./MuxerPrograms";

const translations = defineMessages({
  transportStreamId: {
    id: "Muxer.transportStreamId",
    defaultMessage: "Transport Stream ID",
  },
  originalNetworkId: {
    id: "Muxer.originalNetworkId",
    defaultMessage: "Original Network ID",
  },
  pmtStartPid: {
    id: "Muxer.pmtStartPID",
    defaultMessage: "PMT Start PID",
  },
  startPid: {
    id: "Muxer.startPID",
    defaultMessage: "Start PID",
  },
  muxrate: {
    id: "Muxer.muxrate[kbps]",
    defaultMessage: "Muxrate [kbps]",
  },
  CBRModeOn: {
    id: "Muxer.CBRModeOn",
    defaultMessage: "CBR Mode ON",
  },
  CBRModeOff: {
    id: "Muxer.CBRModeOff",
    defaultMessage: "CBR Mode OFF",
  },
});

const { Option } = Select;
const { Item } = Form;

const Muxer = ({ disabled, handleFinish, id, isNewMuxedOutput, name, prefix, setMuxerData, form }) => {
  const { formatMessage } = useIntl();
  const { getFieldValue, setFieldsValue } = form;
  const useCBRModeSwitch = getFieldValue([...prefix, "useCBRMode"]);

  const handleChangeMuxrateSwitch = (muxrateSwitch) => {
    const muxedOutputs = getFieldValue(["muxedOutputs"]);
    muxedOutputs[[prefix[1]]].muxer.useCBRMode = muxrateSwitch;
    if (muxrateSwitch) {
      delete muxedOutputs[[prefix[1]]].muxer.muxrate;
    }
    setFieldsValue({ muxedOutputs });
    setMuxerData(muxedOutputs);
  };

  return (
    <StyledCard title={<FormattedMessage id="Muxer.muxer" defaultMessage="Muxer" />}>
      <Row justify="start" gutter={24}>
        <HiddenCol span={24}>
          <Item name={[...name, "type"]} label={formatMessage(globalTranslations.type)}>
            <Select disabled={disabled}>
              {MUXER_TYPES.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </HiddenCol>
        <Col span={8}>
          <InputNumberField
            disabled={disabled}
            name={[...name, "transportStreamId"]}
            label={formatMessage(translations.transportStreamId)}
            rules={[required]}
          />
        </Col>
        <Col span={8}>
          <InputNumberField
            disabled={disabled}
            name={[...name, "originalNetworkId"]}
            label={formatMessage(translations.originalNetworkId)}
            rules={[required]}
          />
        </Col>
        <Col span={8}>
          <InputNumberField
            disabled={disabled}
            name={[...name, "pmtStartPid"]}
            label={formatMessage(translations.pmtStartPid)}
            rules={[required]}
          />
        </Col>
        <Col span={8}>
          <InputNumberField
            disabled={disabled}
            name={[...name, "startPid"]}
            label={formatMessage(translations.startPid)}
            rules={[required]}
          />
        </Col>
        <Col span={8}>
          <Item name={[...name, "useCBRMode"]} valuePropName="checked">
            <Switch
              disabled={disabled}
              defaultChecked={useCBRModeSwitch}
              checkedChildren={formatMessage(translations.CBRModeOn)}
              unCheckedChildren={formatMessage(translations.CBRModeOff)}
              onChange={handleChangeMuxrateSwitch}
            />
          </Item>
        </Col>
        {useCBRModeSwitch && (
          <Col span={8}>
            <InputNumberField
              disabled={disabled}
              name={[...name, "muxrate"]}
              label={formatMessage(translations.muxrate)}
              rules={[required]}
              min={1}
            />
          </Col>
        )}
      </Row>
      <Divider />
      <MuxerPrograms
        disabled={disabled}
        handleFinish={handleFinish}
        id={id}
        isNewMuxedOutput={isNewMuxedOutput}
        name={[...name]}
        prefix={[...prefix, "programs"]}
        form={form}
      />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-top: 10px;
`;

const HiddenCol = styled(Col)`
  display: none;
  visibility: hidden;
`;

Muxer.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleFinish: PropTypes.func.isRequired,
  id: PropTypes.string,
  isNewMuxedOutput: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  setMuxerData: PropTypes.func.isRequired,
};

Muxer.defaultProps = {
  id: null,
};

export default Muxer;
