import React from "react";
import PropTypes from "prop-types";

import InputTranscodingStreamSectionBackup from "../InputTranscodingStreamSectionBackup";

import {
  MAIN_CONNECTION_TYPE,
  INPUT_BACKUP_TRIGGERS_TYPE,
  INPUT_BACKUP_TYPE,
} from "../../../../lib/utils/types/inputURLTypes";

function InputBackupTranscodingStreamURLForm({ disabled, initialValue }) {
  return <InputTranscodingStreamSectionBackup disabled={disabled} initialValue={initialValue} />;
}

InputBackupTranscodingStreamURLForm.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.shape({
    ...MAIN_CONNECTION_TYPE,
    ...INPUT_BACKUP_TYPE,
    ...INPUT_BACKUP_TRIGGERS_TYPE,
  }),
};

InputBackupTranscodingStreamURLForm.defaultProps = {
  disabled: null,
  initialValue: null,
};

export default InputBackupTranscodingStreamURLForm;
