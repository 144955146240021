import React, { useContext, useState } from "react";
import { Form, Switch, Popconfirm } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { FormV5Context } from "../../NodeChannelFormV5";

import defaultSubtitles from "../../../lib/utils/defaultValues/defaultSubtitles";
import defaultMonitoringSection from "../../../lib/utils/defaultValues/defaultMonitoringSection";
import defaultTimeCodeSection from "../../../lib/utils/defaultValues/defaultTimeCodeSection";

import useNodeChannelConfig from "../../../hooks/useNodeChannelConfig";

const { Item } = Form;

export default function SectionSwitch({ prefix, disabled, handleFinish, handleOpen }) {
  const form = useContext(FormV5Context);
  const { setFieldValue, getFieldValue } = form;
  const switchValue = Form.useWatch(prefix, form);
  const [open, setOpen] = useState(false);
  const { channelConfig } = useNodeChannelConfig();

  const handleSwitch = (value) => {
    if (!value) {
      setOpen(true);
      setFieldValue(prefix, true);
    }

    const isSubtitlesSection = prefix[0] === "subtitles";
    const isMonitoringSection = prefix[0] === "monitoring";
    const isTimeCodeSection = prefix[0] === "timecode";
    if (value && isSubtitlesSection) {
      handleOpen();
      setFieldValue([prefix[0]], defaultSubtitles);
    }

    if (value && isMonitoringSection) {
      handleOpen();
      setFieldValue([prefix[0]], defaultMonitoringSection);
    }

    if (value && isTimeCodeSection) {
      handleOpen();
      setFieldValue([prefix[0]], defaultTimeCodeSection);
    }
  };

  const handleConfirm = async () => {
    setFieldValue(prefix, false);
    const formData = getFieldValue(prefix[0]);

    handleFinish({ ...channelConfig, [prefix[0]]: formData });
    setOpen(false);
  };

  return (
    <Popconfirm
      title={<FormattedMessage id="SectionSwitch.removeConfirm" defaultMessage="Remove confirmation" />}
      description={
        <FormattedMessage
          id="SectionSwitch.confirmDesc"
          defaultMessage="Disabling will remove all section data, are you sure?"
        />
      }
      open={open}
      onConfirm={handleConfirm}
      onCancel={() => setOpen(false)}
      okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
      cancelText={<FormattedMessage id="general.cancel" defaultMessage="Cancel" />}
    >
      <Item name={prefix} disabled={disabled} noStyle>
        <Switch
          checkedChildren={<FormattedMessage id="general.enabled" defaultMessage="Enabled" />}
          unCheckedChildren={<FormattedMessage id="general.disabled" defaultMessage="Disabled" />}
          checked={switchValue}
          onClick={handleSwitch}
        />
      </Item>
    </Popconfirm>
  );
}

SectionSwitch.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  disabled: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  handleOpen: PropTypes.func,
};

SectionSwitch.defaultProps = {
  prefix: null,
  disabled: null,
  handleOpen: null,
};
