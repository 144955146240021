import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Card } from "antd";

import themeColor from "../../../lib/style/theme";
import { STREAMS_TYPE } from "../../../lib/utils/constants";

import ProbeDrawerElementStreamVideo from "./ProbeDrawerElementStreamVideo";
import ProbeDrawerElementStreamAudio from "./ProbeDrawerElementStreamAudio";
import ProbeDrawerElementStreamUN from "./ProbeDrawerElementStreamUN";
import BoxData from "../../BoxData";

const ProbeDrawerElementStreamContainer = ({ programStreams, streams }) => {
  const VIDEO_TYPE = STREAMS_TYPE[0].value;
  const AUDIO_TYPE = STREAMS_TYPE[1].value;
  const UNKNOWN_TYPE = "Unknown";

  const selectDataFormat = ({ type, data }) => {
    switch (type) {
      case VIDEO_TYPE:
        return <ProbeDrawerElementStreamVideo data={data} />;
      case AUDIO_TYPE:
        return <ProbeDrawerElementStreamAudio data={data} />;
      default:
        return <ProbeDrawerElementStreamUN data={data} />;
    }
  };

  return (
    <Card title={<FormattedMessage id="ProbeDrawerElementStreamContainer.streams" defaultMessage="Streams" />}>
      {programStreams &&
        programStreams.map((streamIndex) => {
          const selectedStream = streams.find((stream) => +stream.id === +streamIndex);

          return (
            <div key={streamIndex}>
              <BoxData
                title={
                  <StyledStream>
                    <FormattedMessage id="ProbeDrawerElementStreamContainer.streamTitle" defaultMessage="Stream" /> #
                    {streamIndex}
                  </StyledStream>
                }
                label={(selectedStream && selectedStream.type) || UNKNOWN_TYPE}
                data={
                  selectedStream &&
                  selectDataFormat({
                    type: selectedStream.type,
                    data: selectedStream,
                  })
                }
              />
            </div>
          );
        })}
      {!programStreams &&
        streams.map((stream) => {
          return (
            <div key={stream.id}>
              <BoxData
                title={
                  <StyledStream>
                    <FormattedMessage id="ProbeDrawerElementStreamContainer.streamTitle" defaultMessage="Stream" /> #
                    {stream.id}
                  </StyledStream>
                }
                label={stream?.type || UNKNOWN_TYPE}
                data={selectDataFormat({ type: stream?.type, data: stream })}
              />
            </div>
          );
        })}
    </Card>
  );
};

const StyledStream = styled.h4`
  color: ${themeColor.orange};
`;

ProbeDrawerElementStreamContainer.propTypes = {
  programStreams: PropTypes.arrayOf(PropTypes.number),
  streams: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ProbeDrawerElementStreamContainer.defaultProps = {
  programStreams: null,
};

export default ProbeDrawerElementStreamContainer;
