import React, { useEffect } from "react";
import { Navigate, useLocation, Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import store from "../../store";
import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";
import { actions as NODE_CHANNELS_ACTIONS } from "../../ducks/nodeChannels";
import { actions as NAVIGATION_ACTIONS } from "../../ducks/navigation";
import { actions as NODE_ACTIONS } from "../../ducks/node";
import { selectors as MQTT_CONNECTION_SELECTORS } from "../../ducks/mqtt";

import { NODES_URL, MQTT_STATUS } from "../../lib/utils/constants";

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isUserLoggedIn = useSelector(ACCOUNT_SELECTORS.getUser);
  const nodes = useSelector(MQTT_CONNECTION_SELECTORS.getNodes);

  useEffect(() => {
    if (pathname.includes("/cloud/licenses")) {
      store.dispatch(NAVIGATION_ACTIONS.SET_ACTIVE_KEY({ activeKey: ["/cloud/licenses"] }));
    } else {
      store.dispatch(NAVIGATION_ACTIONS.SET_ACTIVE_KEY({ activeKey: [decodeURI(pathname)] }));
    }

    if (nodes && nodes.length > 0) {
      const nodesSplittedURL = pathname.split("/");

      const selectedNodeData = nodes.find((node) => node.cwid === nodesSplittedURL[2]);

      if (selectedNodeData && selectedNodeData.status === MQTT_STATUS.DISCONNECTED) {
        store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
        store.dispatch(NODE_CHANNELS_ACTIONS.CLEAR_NODES_CHANNELS());
        navigate("/");
      }

      if (nodesSplittedURL[1] === NODES_URL) {
        store.dispatch(
          NAVIGATION_ACTIONS.SET_ACTIVE_SUBMENU({
            activeSubmenu: ["/node", `/node/${nodesSplittedURL[2]}`],
          })
        );
      } else {
        store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
        store.dispatch(NODE_CHANNELS_ACTIONS.CLEAR_NODES_CHANNELS());
      }
    }
  }, [pathname, nodes]);

  return isUserLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
