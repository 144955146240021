import React from "react";
import PropTypes from "prop-types";
import { Tag } from "antd";
import { FormattedMessage } from "react-intl";

const NodePortsTypes = ({ type }) => {
  switch (type) {
    case "im":
      return (
        <Tag color="volcano">
          <FormattedMessage id="NodePortsTypes.inputMain" defaultMessage="Input" />
        </Tag>
      );
    case "ib":
      return (
        <Tag color="gold">
          <FormattedMessage id="NodePortsTypes.inputBackup" defaultMessage="Backup" />
        </Tag>
      );
    case "do":
      return (
        <Tag color="purple">
          <FormattedMessage id="NodePortsTypes.directOutput" defaultMessage="Direct output" />
        </Tag>
      );
    case "mo":
    case "moo":
      return (
        <Tag color="blue">
          <FormattedMessage id="NodePortsTypes.muxedOutput" defaultMessage="Muxed output" />
        </Tag>
      );
    default:
      return (
        <span>
          <span>{type}</span>
        </span>
      );
  }
};

NodePortsTypes.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NodePortsTypes;
