import React, { useState } from "react";
import { Form, Select, Divider, Card, Spin, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { WarningOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { required } from "../../../lib/utils/formValidation";
import inputURLDefaultValues from "../../../lib/utils/defaultValues/inputURLDefaultValues";
import themeColor from "../../../lib/style/theme";
import { MAIN_CONNECTION_TYPE } from "../../../lib/utils/types/inputURLTypes";
import { CAPABILITIES_TYPE } from "../../../lib/utils/types/capabilitiesTypes";

import { selectors as NODE_DEVICES_SELECTORS } from "../../../ducks/nodeDevices";
import { selectors as NODE_SELECTORS } from "../../../ducks/node";

import {
  INPUT_DECKLINK_TYPE,
  INPUT_URL_TYPE,
  INPUT_WEBCAM_TYPE,
  NODE_DEVICES_TYPE,
  SELECT_DEFAULT_AUTO,
  INPUT_TYPES,
} from "../../../lib/utils/constants";
import InputStreamURL from "./InputStreamURL";
import InputSDI from "./InputSDI";
import InputWebcam from "./InputWebcam";
import InputRTMP from "./InputRTMP";
import InputRTP from "./InputRTP";
import UpdateNodeDevices from "../../UpdateNodeDevices";
import InputNDI from "./InputNDI";

const { Item } = Form;
const { Option } = Select;

const InputStreamSectionContainer = ({
  capabilities,
  decklinkInputs,
  disabled,
  getFieldValue,
  initialValue,
  isInput,
  nodeDevicesLoaded,
  setFieldsValue,
  setSelectedInputType,
  sharedChannelList,
  stundAddress,
  validateFields,
  webcamAudioInputs,
  webcamVideoInputs,
  form,
}) => {
  const [inputType, setInputType] = useState(getFieldValue(["input", "type"]));
  const [showWarning, setShowWarning] = useState(false);
  const isNonmuxedOutputs = getFieldValue(["nonMuxedOutputs"]);

  const handleChangeInputType = (value) => {
    setSelectedInputType(value);
    const formData = getFieldValue();
    delete formData.input;

    const isInitialValueInputUrlType = initialValue?.type === INPUT_URL_TYPE.value;

    if (value === INPUT_DECKLINK_TYPE.value) {
      setFieldsValue({ input: { type: value, format: SELECT_DEFAULT_AUTO.value } });
      if (isInitialValueInputUrlType || isNonmuxedOutputs) {
        setShowWarning(true);
      }
    }

    if (value === INPUT_URL_TYPE.value) {
      const initialInputValues = inputURLDefaultValues({ input: initialValue, stundAddress, sharedChannelList });
      setFieldsValue({ input: initialInputValues });
      setShowWarning(false);
    }

    if (value === INPUT_WEBCAM_TYPE.value) {
      setFieldsValue({ input: { type: value } });
      if (isInitialValueInputUrlType || isNonmuxedOutputs) {
        setShowWarning(true);
      }
    }

    setFieldsValue({ input: { type: value } });
    setInputType(value);
  };

  const isInputURLTypeSelected = inputType === INPUT_URL_TYPE.value;
  const isInputSDITypeSelected = inputType === INPUT_DECKLINK_TYPE.value;
  const isInputWEBCAMTypeSelected = inputType === INPUT_WEBCAM_TYPE.value;
  const isInputRTMPTypeSelected = inputType === INPUT_TYPES.inputRtmp.value;
  const isInputRTPTypeSelected = inputType === INPUT_TYPES.inputMpegtsOverRtp.value;
  const isInputNDITypeSelected = inputType === INPUT_TYPES.inputNdi.value;

  const isDecklinkInputs = decklinkInputs.length > 0;
  const isWebcamAudioInputs = webcamAudioInputs.length > 0;
  const isWebcamVideoInputs = webcamVideoInputs.length > 0;

  const INPUT_TYPES_ARRAY = Object.values(INPUT_TYPES);

  const INPUT_STREAM_DEVICE_TYPE = INPUT_TYPES_ARRAY;

  const checkIfOptionDisabled = (optionValue) => {
    if (capabilities?.inputTypes && !capabilities?.inputTypes.includes(optionValue)) {
      return true;
    }

    if (optionValue === INPUT_DECKLINK_TYPE.value && !isDecklinkInputs) {
      return true;
    }

    if (optionValue === INPUT_WEBCAM_TYPE.value && (!isWebcamAudioInputs || !isWebcamVideoInputs)) {
      return true;
    }

    return false;
  };

  return (
    <Card extra={<UpdateNodeDevices />}>
      <Item
        name={["input", "type"]}
        label={<FormattedMessage id="InputStreamSectionContainer.type" defaultMessage="Type" />}
        rules={[required]}
      >
        <Select disabled={disabled || isInput} onChange={handleChangeInputType}>
          {INPUT_STREAM_DEVICE_TYPE.map((option) => (
            <Option key={option.value} value={option.value} disabled={checkIfOptionDisabled(option.value)}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Item>
      {showWarning && (
        <StyledWarning>
          <WarningOutlined />{" "}
          <FormattedMessage
            id="InputStreamSectionContainer.warning"
            // eslint-disable-next-line max-len
            defaultMessage="Be aware that this accepting this change you will overwrite the all old input configuration (including input backup), and all direct outputs"
          />
        </StyledWarning>
      )}
      <Divider />
      {nodeDevicesLoaded && (
        <>
          <StyledSpin
            tip={
              <FormattedMessage
                id="InputStreamSectionContainer.loadingNodeDevices"
                defaultMessage="Loading node devices list..."
              />
            }
          >
            <Skeleton active />
          </StyledSpin>
          <Divider />
        </>
      )}
      {isInputURLTypeSelected && (
        <InputStreamURL
          channelList={sharedChannelList}
          disabled={disabled}
          getFieldValue={getFieldValue}
          initialValue={initialValue}
          setFieldsValue={setFieldsValue}
          sharedChannelList={sharedChannelList}
          stundAddress={stundAddress}
          form={form}
        />
      )}
      {isInputSDITypeSelected && (
        <InputSDI
          getFieldValue={getFieldValue}
          inputs={decklinkInputs}
          disabled={disabled}
          type={NODE_DEVICES_TYPE.decklinkInputs}
          setFieldsValue={setFieldsValue}
        />
      )}
      {isInputWEBCAMTypeSelected && (
        <InputWebcam
          audioInputs={webcamAudioInputs}
          disabled={disabled}
          getFieldValue={getFieldValue}
          setFieldsValue={setFieldsValue}
          videoInputs={webcamVideoInputs}
          validateFields={validateFields}
        />
      )}
      {isInputRTMPTypeSelected && <InputRTMP disabled={disabled} />}
      {isInputRTPTypeSelected && <InputRTP disabled={disabled} prefix={["input"]} />}
      {isInputNDITypeSelected && <InputNDI disabled={disabled} initialValue={initialValue?.streamSource} />}
    </Card>
  );
};

const StyledWarning = styled.span`
  color: ${themeColor.orange};
`;

const StyledSpin = styled(Spin)`
  width: 100%;
`;

InputStreamSectionContainer.propTypes = {
  capabilities: PropTypes.shape(CAPABILITIES_TYPE),
  disabled: PropTypes.bool,
  decklinkInputs: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string,
      device: PropTypes.string,
    })
  ).isRequired,
  initialValue: PropTypes.shape({
    type: PropTypes.string.isRequired,
    switchInputBackup: PropTypes.bool,
    ...MAIN_CONNECTION_TYPE,
  }),
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  stundAddress: PropTypes.string,
  sharedChannelList: PropTypes.array,
  webcamAudioInputs: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.string,
    })
  ).isRequired,
  webcamVideoInputs: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.string,
    })
  ).isRequired,
  validateFields: PropTypes.func.isRequired,
  nodeDevicesLoaded: PropTypes.bool,
  setSelectedInputType: PropTypes.func.isRequired,
  isInput: PropTypes.bool.isRequired,
};

InputStreamSectionContainer.defaultProps = {
  capabilities: null,
  disabled: null,
  initialValue: null,
  nodeDevicesLoaded: null,
  sharedChannelList: null,
  stundAddress: null,
};

const mapStateToProps = (state) => ({
  capabilities: NODE_SELECTORS.getNodeCapabilities(state),
  decklinkInputs: NODE_DEVICES_SELECTORS.getDecklinkInputs(state),
  decklinkOutputs: NODE_DEVICES_SELECTORS.getDecklinkOutputs(state),
  webcamAudioInputs: NODE_DEVICES_SELECTORS.getWebcamAudioInputs(state),
  webcamVideoInputs: NODE_DEVICES_SELECTORS.getWebcamVideoInputs(state),
  nodeDevicesLoaded: NODE_DEVICES_SELECTORS.getLoadingDevices(state),
});

export default connect(mapStateToProps, null)(InputStreamSectionContainer);
