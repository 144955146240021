import React, { Upload, Spin, Modal, message } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import OrganizationsServices from "../../services/organizations";

import { errorNotification } from "../../lib/utils/notification";
import { VALID_IMAGE_FORMATS } from "../../lib/utils/constants";

import CropImage from "../CropImage";

const translations = defineMessages({
  max7MBValidation: {
    id: "UploadLogo.max7MBValidation",
    defaultMessage: "Image must smaller than 7MB!",
  },
  imageValidation: {
    id: "UploadLogo.imageValidation",
    defaultMessage: "is not image format!",
  },
});

const UploadLogo = ({ setFileList, fileList, organizationName, setLoading }) => {
  const { formatMessage } = useIntl();
  const [processingDeleteLogo, setProcessDeleteLogo] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handleCancelPreview = () => setPreviewVisible(false);

  const onChange = ({ fileList: newFileList }) => {
    setLoading(true);

    if (newFileList[0] && !newFileList[0].status) {
      return;
    }

    if (!newFileList[0]) {
      setLoading(false);
    }

    if (newFileList[0] && newFileList[0].status === "done") {
      setLoading(false);
    }

    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    const imageHash = Date.now();

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;

    setPreviewImage(`${image.src}?${imageHash}` || `${file.url}?${imageHash}`);
    setPreviewVisible(true);
  };

  const handleRemoveLogo = (file) => {
    setProcessDeleteLogo(true);
    setLoading(true);
    setFileList([]);

    const encodedKey = encodeURIComponent(file.response ? file.response?.data?.key : file?.key);

    return OrganizationsServices.deleteOrganizationLogo(
      { key: encodedKey },
      {
        errorNotification: errorNotification(formatMessage),
        setProcessDeleteLogo,
        setLoading,
      }
    );
  };

  const beforeUpload = (file) => {
    const isValidSizeImage = file.size / 1024 / 1024 < 7;

    const isImage = VALID_IMAGE_FORMATS.includes(file.type);
    if (!isImage) {
      message.error(`${file.name} ${formatMessage(translations.imageValidation)}`);
    }

    if (!isValidSizeImage) {
      setLoading(false);
      message.error(formatMessage(translations.max7MBValidation));
    }

    return (isImage && isValidSizeImage) || Upload.LIST_IGNORE;
  };

  return (
    <>
      <StyledUpload>
        <StyledLabel>
          <FormattedMessage id="UploadLogo.logo" defaultMessage="Logo" />:
        </StyledLabel>
        {fileList.length < 1 && (
          <CropImage
            setFileList={setFileList}
            organizationName={organizationName}
            filename="orgLogo"
            aspect={1}
            setLoading={setLoading}
          />
        )}
        {fileList.length > 0 && (
          <Upload
            beforeUpload={beforeUpload}
            disabled={processingDeleteLogo}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            onRemove={handleRemoveLogo}
          >
            {fileList.length < 1 && (
              <span>
                {processingDeleteLogo && (
                  <Spin spinning>
                    <FormattedMessage id="UploadLogo.deleting" defaultMessage="Deleting" />
                  </Spin>
                )}
                {!processingDeleteLogo && (
                  <span>
                    {"+ "}
                    <FormattedMessage id="UploadLogo.upload" defaultMessage="Upload" />
                  </span>
                )}
              </span>
            )}
          </Upload>
        )}
      </StyledUpload>
      <Modal
        onCancel={handleCancelPreview}
        open={previewVisible}
        title={<FormattedMessage id="UploadLogo.preview" defaultMessage="Preview" />}
        footer={null}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

const StyledUpload = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledLabel = styled.span`
  margin-right: 5px;
`;

UploadLogo.propTypes = {
  setFileList: PropTypes.func.isRequired,
  fileList: PropTypes.array.isRequired,
};

export default UploadLogo;
