import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import React from "react";

import store from "../../store";

import { actions as NODE_NOTIFICATION_ACTIONS } from "../../ducks/nodeNotifications";

// eslint-disable-next-line import/no-cycle
import { MESSAGE_TYPE } from "./constants";
import NodeTranslations from "../translations/nodeTranslations";
import notificationHandler from "./notificationHandler";

import NotificationTitle from "../../components/NotificationTitle";

const setDefaultTranslation = (type) => {
  switch (type) {
    case MESSAGE_TYPE.ERROR:
      return NodeTranslations[7000];
    default:
      return NodeTranslations["warning-0"];
  }
};

const nodeNotificationErrorHandler = ({ error }) => {
  const cloudNodes = store.getState().getIn(["mqtt", "nodes"]);
  const activeNotifications = store.getState().getIn(["nodeNotifications", "activeNotifications"]);
  const nodeData = cloudNodes.find((node) => node.cwid === error.from.split("/")[1]);
  const isAlreadyActiveNotification =
    nodeData && activeNotifications.includes(`${nodeData.cnn}-${error.channelId}-${error.msgType}-${error.errorCode}`);

  if (nodeData?.cnn) {
    store.dispatch(
      NODE_NOTIFICATION_ACTIONS.SET_ACTIVE_NOTIFICATION(
        `${nodeData.cnn}-${error.channelId}-${error.msgType}-${error.errorCode}`
      )
    );
    store.dispatch(
      NODE_NOTIFICATION_ACTIONS.SET_NEW_NODE_NOTIFICATION({
        ...error,
        cnn: nodeData.cnn,
        id: `${nodeData.cnn}-${uuidv4()}`,
        time: `${moment().format("HH:mm:ss DD-MM-YYYY")}`,
      })
    );
  }

  let showPopup = store.getState().getIn(["nodeNotifications", "showPopup"]);
  const isWindowActive = !document.hidden;

  if (error.unmutable) showPopup = true;

  if (showPopup && isWindowActive && !isAlreadyActiveNotification) {
    const getErrorCode = () => {
      switch (error.msgType) {
        case MESSAGE_TYPE.ERROR:
          return error.errorCode;
        default:
          return `warning-${error.warningCode}`;
      }
    };

    const errorMessage = NodeTranslations[getErrorCode()] || setDefaultTranslation(error.msgType);

    notificationHandler[error.msgType]({
      message: (
        <NotificationTitle
          cwid={nodeData?.cwid}
          cnn={nodeData?.cnn}
          channelId={error?.channelId}
          paths={error?.path}
          source={error?.source}
        />
      ),
      description: `${errorMessage.defaultMessage}`,
      onClose: () =>
        store.dispatch(
          NODE_NOTIFICATION_ACTIONS.REMOVE_ACTIVE_NOTIFICATION(
            `${nodeData?.cnn}-${error.channelId}-${error.msgType}-${error.errorCode}`
          )
        ),
    });
  }
};

export default nodeNotificationErrorHandler;
