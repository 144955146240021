import { OUTPUTS_TYPES, CONNECTION_METHOD_OUTPUT, DEFAULT_VALUES, SELECT_DEFAULT_AUTO } from "../constants";

import { DEFAULT_VIDEO_STREAM, DEFAULT_AUDIO_STREAM } from "./defaultTranscodedTypes";

export const DEFAULT_TRANSCODED_DESTINATION_URL = {
  type: CONNECTION_METHOD_OUTPUT.outUdp.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  localPort: DEFAULT_VALUES.LOCALPORT,
  destinationAddress: DEFAULT_VALUES.IP,
  destinationPort: DEFAULT_VALUES.PORT,
  udpTTL: DEFAULT_VALUES.TTL,
};

export const DEFAULT_OUTPUT_RTMP = {
  type: OUTPUTS_TYPES.outputRtmp.value,
  videoStream: DEFAULT_VIDEO_STREAM,
  audioStreams: [DEFAULT_AUDIO_STREAM],
};

const DEFAULT_PROVIDER = "Quickstream";

export default {
  type: OUTPUTS_TYPES.outputUrl.value,
  mpegtsServiceProvider: DEFAULT_PROVIDER,
  mpegtsServiceName: DEFAULT_PROVIDER,
  mpegtsServiceId: 1,
  mpegtsPmtStartPid: 1023,
  mpegtsStartPid: 1024,
  mpegtsTransportStreamId: 1,
  mpegtsOriginalNetworkId: 1,
  mpegtsCBRMode: false,

  urlDestinations: [],
  videoStream: DEFAULT_VIDEO_STREAM,
  audioStreams: [DEFAULT_AUDIO_STREAM],
};

export const DEFAULT_OUTPUT_SDI = {
  type: OUTPUTS_TYPES.outputDecklink.value,
  resize: "pad",
  audioChannels: 2,
};
