import { useSelector } from "react-redux";
import semver from "semver";

import { selectors as NODE_SELECTORS } from "../ducks/node";

function useNodeVersion() {
  const nodeVersion = useSelector(NODE_SELECTORS.getNodeVersion);
  const isV5Node = nodeVersion && semver.satisfies(nodeVersion, "5.x.x");
  const isV4Node = nodeVersion && semver.satisfies(nodeVersion, "4.x.x");
  const isMinV5 = nodeVersion && semver.satisfies(nodeVersion, ">=5.0.0");

  return { isV5Node, isV4Node, isMinV5 };
}

export default useNodeVersion;
