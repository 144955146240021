import {
  OUTPUTS_TYPES,
  MUXER_TYPES,
  CONNECTION_METHOD_OUTPUT,
  DEFAULT_VALUES,
  SELECT_DEFAULT_AUTO,
  SELECT_STREAM_CODEC,
  SELECT_STREAM_CODEC_AUDIO,
  MPEG2AUDIO_BITRATE,
  MPEG2AUDIO_SAMPLE_FORMAT,
  MPEG2AUDIO_SAMPLE_RATE,
  AAC_PROFILE,
  SELECT_STREAM_CODEC_RESIZE,
  SELECT_STREAM_CODEC_FPS,
  OUTPUT_RTMP_PIX_FORMAT,
  STREAMS_TYPE,
} from "../constants";

export const DEFAULT_MUXED_OUTPUT_OUTPUT = {
  type: CONNECTION_METHOD_OUTPUT.outUdp.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  localPort: DEFAULT_VALUES.LOCALPORT,
  destinationAddress: DEFAULT_VALUES.IP,
  destinationPort: DEFAULT_VALUES.PORT,
  udpTTL: DEFAULT_VALUES.TTL,
};

export const DEFAULT_OUTPUT_RTMP = {
  type: OUTPUTS_TYPES.outputRtmp.value,
  videoStream: {
    selectStream: {
      streamId: SELECT_DEFAULT_AUTO.value,
    },
    codec: {
      type: SELECT_STREAM_CODEC[2].value,
      resize: SELECT_STREAM_CODEC_RESIZE[0].value,
      fps: SELECT_STREAM_CODEC_FPS[0].value,
      pixFormat: OUTPUT_RTMP_PIX_FORMAT[0].value,
      changeGOP: false,
      interlaced: false,
    },
    filters: [],
    type: STREAMS_TYPE[0].value,
  },
  audioStream: {
    selectStream: {
      streamId: SELECT_DEFAULT_AUTO.value,
    },
    codec: {
      type: SELECT_STREAM_CODEC_AUDIO[2].value,
      b: MPEG2AUDIO_BITRATE[0].value,
      sampleFormat: MPEG2AUDIO_SAMPLE_FORMAT[0].value,
      sampleRate: MPEG2AUDIO_SAMPLE_RATE[0].value,
      aacProfile: AAC_PROFILE[0].value,
    },
    filters: [],
    type: STREAMS_TYPE[1].value,
  },
};

export default {
  type: OUTPUTS_TYPES.outputUrl.value,
  outputs: [],
  muxer: {
    type: MUXER_TYPES[0].value,
    useCBRMode: false,
    transportStreamId: 1,
    originalNetworkId: 1,
    pmtStartPid: 1023,
    startPid: 1024,
  },
};
