import React, { useCallback } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { ReloadOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import store from "../../store";

import { selectors as NODE_DEVICES_SELECTORS, SET_LOADING_DEVICES_LIST } from "../../ducks/nodeDevices";
import { selectors as NODE_SELECTORS } from "../../ducks/node";
import MQTTService from "../../services/mqtt";

import { MESSAGE_TYPE } from "../../lib/utils/constants";

const UpdateNodeDevices = ({ cwid, nodeDevicesLoaded }) => {
  const fetchNodeDevices = useCallback(() => {
    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.GET_DEVICES,
      },
    });

    store.dispatch(SET_LOADING_DEVICES_LIST(true));

    const getDeviceTimeout = setTimeout(() => {
      store.dispatch(SET_LOADING_DEVICES_LIST(false));
    }, 20000);

    return () => {
      clearTimeout(getDeviceTimeout);
    };
  }, [cwid]);

  return (
    <StyledButton disabled={nodeDevicesLoaded} type="primary" onClick={fetchNodeDevices} icon={<ReloadOutlined />}>
      <span>
        <FormattedMessage id="general.updateDevicesList" defaultMessage="Update devices" />
      </span>
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  margin-right: 5px;
`;

UpdateNodeDevices.propTypes = {
  cwid: PropTypes.string.isRequired,
  nodeDevicesLoaded: PropTypes.bool,
};

UpdateNodeDevices.defaultProps = {
  nodeDevicesLoaded: null,
};

const mapStateToProps = (state) => ({
  cwid: NODE_SELECTORS.getNodeCwid(state),
  nodeDevicesLoaded: NODE_DEVICES_SELECTORS.getLoadingDevices(state),
});

export default connect(mapStateToProps, null)(UpdateNodeDevices);
