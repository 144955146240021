import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

export default function BackupReturnMsecsLabel() {
  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage
          id="BackupReturnMsecsLabel.backupReturnMsecsDesc"
          // eslint-disable-next-line max-len
          defaultMessage="If main source starts producing frames, then return to it after this amount of milliseconds"
        />
      }
    >
      <FormattedMessage id="BackupReturnMsecsLabel.backupReturnMsecs" defaultMessage="Return time [ms]" />{" "}
      <QuestionCircleFilled />
    </Tooltip>
  );
}
