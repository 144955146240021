/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { Form, Select, Card, Row, Col, InputNumber, Spin } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

import store from "../../../../../store";
import MQTTService from "../../../../../services/mqtt";
import { SET_LOADING_FORMATS, selectors as FORMAT_SELECTORS } from "../../../../../ducks/nodeFormats";
import { selectors as CONNECTION_SELECTORS } from "../../../../../ducks/node";

import {
  NODE_DEVICES_TYPE,
  SELECT_CUSTOM,
  SAMPLE_RATES_SELECT,
  MESSAGE_TYPE,
} from "../../../../../lib/utils/constants";
import { required } from "../../../../../lib/utils/formValidation";
import globalTranslations from "../../../../../lib/translations";

const { Item } = Form;
const { Option } = Select;

const InputWebcamMic = ({ audioInputs, disabled, getFieldValue, formats, setFieldsValue }) => {
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(getFieldValue(["input", "micDevice"]));
  const initialMicDeviceFormat = getFieldValue(["input", "micDeviceFormat"]);

  const [disabledCustomValues, setDisabledCustomValues] = useState(initialMicDeviceFormat !== SELECT_CUSTOM.value);
  const { formatMessage } = useIntl();
  const cwid = useSelector(CONNECTION_SELECTORS.getNodeCwid);

  useEffect(() => {
    const isFormatData = !!formats.get(selectedAudioDevice);
    if (!isFormatData && selectedAudioDevice) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_FORMATS,
          device: selectedAudioDevice,
          type: NODE_DEVICES_TYPE.webcamAudioInputs,
        },
      });

      store.dispatch(SET_LOADING_FORMATS(true));
    }
  }, [selectedAudioDevice, formats]);

  const SELECTED_AUDIO_FORMATS =
    (!!formats.get(selectedAudioDevice) && [...formats.get(selectedAudioDevice), SELECT_CUSTOM]) || [];
  const isFormats = SELECTED_AUDIO_FORMATS.length > 0;

  const AUDIO_INPUTS_SELECT = audioInputs.map((input) => {
    return {
      label: input.device,
      value: input.device,
    };
  });

  const handleChangeAudioDevice = (selectedAudioDeviceValue) => {
    setSelectedAudioDevice(selectedAudioDeviceValue);
    setDisabledCustomValues(true);

    setFieldsValue({
      input: {
        micDevice: selectedAudioDeviceValue,
        micDeviceFormat: null,
        bitsPerSample: null,
        sampleRate: null,
        channels: null,
      },
    });
  };

  const handleChangeAudioFormat = (selectedAudioFormat) => {
    const inputForm = getFieldValue("input");

    if (selectedAudioFormat === SELECT_CUSTOM.value) {
      setFieldsValue({
        input: {
          ...inputForm,
          bitsPerSample: null,
          sampleRate: null,
          channels: null,
        },
      });
      setDisabledCustomValues(false);
    } else {
      const selectedData = selectedAudioFormat.split("-");
      setFieldsValue({
        input: {
          ...inputForm,
          bitsPerSample: +selectedData[0],
          sampleRate: selectedData[1],
          channels: +selectedData[2],
        },
      });
      setDisabledCustomValues(true);
    }
  };

  const setSuffix = (value) => {
    switch (value) {
      case "bitsPerSample":
        return "B";
      case "sampleRate":
        return "R";
      default:
        return "C";
    }
  };

  const customDataValidation = async () => {
    const fields = ["bitsPerSample", "sampleRate", "channels"];

    let filteredFormats;
    const deviceFormats = formats.get(selectedAudioDevice);

    const resultPromises = fields.map((fieldName) => {
      const suffix = setSuffix(fieldName);
      const fieldValue = +getFieldValue(["input", fieldName]);

      if (fieldValue) {
        if (filteredFormats) {
          const selectedFormat = filteredFormats.filter((format) => {
            return +fieldValue <= +format[`max${suffix}`] && +fieldValue >= +format[`min${suffix}`];
          });

          if (!selectedFormat || selectedFormat.length === 0) {
            return Promise.reject(formatMessage(globalTranslations.valueOutOfRange));
          }
        }

        filteredFormats = deviceFormats.filter((format) => {
          return fieldValue <= format[`max${suffix}`] && fieldValue >= format[`min${suffix}`];
        });

        if (!filteredFormats || filteredFormats.length === 0) {
          return Promise.reject(formatMessage(globalTranslations.valueOutOfRange));
        }
      }

      return null;
    });

    await Promise.all(resultPromises);

    return Promise.resolve();
  };

  return (
    <Card title={<FormattedMessage id="InputWebcamMic.audio" defaultMessage="Audio" />}>
      <Row gutter={24}>
        <Col span={24}>
          <Item
            name={["input", "micDevice"]}
            label={<FormattedMessage id="InputWebcamMic.device" defaultMessage="Device" />}
            rules={[...(selectedAudioDevice ? [required] : [])]}
          >
            <Select disabled={disabled} onChange={handleChangeAudioDevice} allowClear>
              {AUDIO_INPUTS_SELECT.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Spin
        spinning={!isFormats}
        tip={
          <FormattedMessage
            id="InputWebcamMic.waitingFormNodeFormats"
            defaultMessage="Waiting for node formats or device to be selected..."
          />
        }
      >
        <Row gutter={24}>
          <Col span={24}>
            <Item
              name={["input", "micDeviceFormat"]}
              label={<FormattedMessage id="InputWebcamMic.format" defaultMessage="Format" />}
              rules={[...(selectedAudioDevice ? [required] : [])]}
            >
              <Select disabled={disabled || !isFormats} onChange={handleChangeAudioFormat}>
                {SELECTED_AUDIO_FORMATS.map((option) => {
                  if (option.value === SELECT_CUSTOM.value) {
                    return (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    );
                  }

                  const optionKey = `${option.maxB}-${option.maxC}-${option.maxR}-${option.minB}-${option.minC}-${option.minR}`;
                  const optionLabel = `Bits per sample: ${option.maxB}, Sample rate: ${option.maxR}, Channels: ${option.maxC}`;
                  const optionValue = `${option.maxB}-${option.maxR}-${option.maxC}`;

                  return (
                    <Option key={optionKey} value={optionValue}>
                      {optionLabel}
                    </Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name={["input", "bitsPerSample"]}
              label={<FormattedMessage id="InputWebcamMic.bitratePerSample" defaultMessage="Bitrate per sample" />}
              rules={[
                ...(selectedAudioDevice
                  ? [required, { validator: customDataValidation }]
                  : [{ validator: customDataValidation }]),
              ]}
            >
              <InputNumber type="number" disabled={disabled || disabledCustomValues || !isFormats} />
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name={["input", "sampleRate"]}
              label={<FormattedMessage id="InputWebcamMic.sampleRate" defaultMessage="Sample rate" />}
              rules={[
                ...(selectedAudioDevice
                  ? [required, { validator: customDataValidation }]
                  : [{ validator: customDataValidation }]),
              ]}
            >
              <Select disabled={disabled || disabledCustomValues || !isFormats}>
                {SAMPLE_RATES_SELECT.map((sampleRate) => {
                  return (
                    <Option key={sampleRate.value} value={sampleRate.value}>
                      {sampleRate.value}
                    </Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name={["input", "channels"]}
              label={<FormattedMessage id="InputWebcamMic.channels" defaultMessage="Channels" />}
              rules={[
                ...(selectedAudioDevice
                  ? [required, { validator: customDataValidation }]
                  : [{ validator: customDataValidation }]),
              ]}
            >
              <InputNumber type="number" disabled={disabled || disabledCustomValues || !isFormats} />
            </Item>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

InputWebcamMic.propTypes = {
  disabled: PropTypes.bool,
  getFieldValue: PropTypes.func.isRequired,
  audioInputs: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.string,
    })
  ).isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  formats: ImmutablePropTypes.map.isRequired,
};

InputWebcamMic.defaultProps = {
  disabled: null,
};

const mapStateToProps = (state) => ({
  formats: FORMAT_SELECTORS.getFormats(state),
});

export default connect(mapStateToProps, null)(InputWebcamMic);
