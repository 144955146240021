import React, { useCallback, useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Input, Button, Checkbox, Card, Form, Divider, Row, Spin } from "antd";
import styled from "styled-components";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTurnstile } from "react-turnstile";

import AccountService from "../../services/account";
import { errorNotification } from "../../lib/utils/notification";
import themeColor from "../../lib/style/theme";

import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

import useMainTheme from "../../hooks/useMainTheme";

import CookieConsent from "../CookieConsent";
import TurnstileValidation from "../TurnstileValidation";

import logoUrl from "../../lib/images/logo.png";
import logoLightUrl from "../../lib/images/logo-light.png";

const { Item } = Form;
const { Password } = Input;
const cookies = new Cookies();

const translations = defineMessages({
  username: {
    id: "login.username",
    defaultMessage: "Username or email address",
  },
  usernameNoInput: {
    id: "login.validationNoInput",
    defaultMessage: "Please input username",
  },
  password: {
    id: "login.password",
    defaultMessage: "Password",
  },
  passwordNoInput: {
    id: "login.validationNotIdentical",
    defaultMessage: "Please input password",
  },
  cookieApprovalNeeded: {
    id: "login.cookieApprovalNeeded",
    defaultMessage: "Please accept cookies, to be able to login to Quickstream Cloud",
  },
});

const LoginForm = ({ loggedUsername }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [cookieAccepted, setCookieAccepted] = useState(cookies.get("QuickstreamCloudCookieApproval"));
  const { isDarkTheme } = useMainTheme();
  const turnstileToken = useSelector(ACCOUNT_SELECTORS.getTurnstileToken);
  const turnstile = useTurnstile();
  const { resetFields } = form;

  const handleFinish = useCallback(
    async (formData) => {
      const cookieApproved = cookies.get("QuickstreamCloudCookieApproval");
      if (!cookieApproved) {
        errorNotification(formatMessage)(translations.cookieApprovalNeeded);

        return;
      }

      await AccountService.loginUser(
        { ...formData, turnstileToken },
        {
          errorNotification: errorNotification(formatMessage),
          turnstile,
          resetFields,
        }
      );
    },
    [formatMessage, turnstileToken]
  );

  if (loggedUsername) {
    return <Navigate to="/" replace />;
  }

  return (
    <StyledFormContainer>
      <CookieConsent setCookieAccepted={setCookieAccepted} />
      <Spin
        spinning={!cookieAccepted}
        tip={
          <FormattedMessage
            id="LoginForm.pleaseAcceptCookies"
            defaultMessage="Please accept cookies privacy policy below"
          />
        }
      >
        <StyledCard
          extra={<StyledLogo src={isDarkTheme ? logoUrl : logoLightUrl} alt="quickstream logo" width="200" />}
        >
          <StyledForm onFinish={handleFinish} className="login-form" form={form} initialValues={{ remember: true }}>
            <Item name="username" rules={[{ required: true, message: formatMessage(translations.usernameNoInput) }]}>
              <Input prefix={<UserOutlined />} placeholder={formatMessage(translations.username)} />
            </Item>
            <Item name="password" rules={[{ required: true, message: formatMessage(translations.passwordNoInput) }]}>
              <Password prefix={<LockOutlined />} type="password" placeholder={formatMessage(translations.password)} />
            </Item>
            <Item name="remember" valuePropName="checked">
              <Checkbox>
                <FormattedMessage id="login.rememberMe" defaultMessage="Remember me" />
              </Checkbox>
            </Item>
            <>
              <StyledButton type="primary" htmlType="submit" className="login-form-button" loading={!turnstileToken}>
                <FormattedMessage id="login.loginButton" defaultMessage="Log in" />
              </StyledButton>
              <Row type="flex" justify="center" align="middle">
                <StyledLink to="/password/forgot">
                  <StyledOrangeURL>
                    <FormattedMessage id="login.forgotPassword" defaultMessage="Forgot password?" />
                  </StyledOrangeURL>
                </StyledLink>
              </Row>
              <Divider />
              <Row type="flex" justify="center" align="middle">
                <h4>
                  <FormattedMessage id="login.dontHaveAccount" defaultMessage="Don't have an account?" />
                </h4>
              </Row>
              <Row type="flex" justify="center" align="middle">
                <Link to="/register">
                  <StyledOrangeURL>
                    <FormattedMessage id="login.registerNow" defaultMessage="Register now!" />
                  </StyledOrangeURL>
                </Link>
              </Row>
            </>
          </StyledForm>
        </StyledCard>
      </Spin>
      <TurnstileValidation />
    </StyledFormContainer>
  );
};

const StyledCard = styled(Card)`
  .ant-card-extra {
    margin: 0 auto;
    padding: 0;
  }
`;

const StyledLogo = styled.img`
  margin-top: 10px;
`;

const StyledLink = styled(Link)`
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
  margin: 20px 0 0 0;
`;

const StyledForm = styled(Form)`
  min-width: 400px;
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledOrangeURL = styled.span`
  color: ${themeColor.orange};
  transition: color 0.2s ease;

  &:hover {
    color: ${themeColor.orangeHover};
  }
`;

LoginForm.propTypes = {
  loggedUsername: PropTypes.string,
};

LoginForm.defaultProps = {
  loggedUsername: null,
};

const mapStateToProps = (state) => ({
  loggedUsername: ACCOUNT_SELECTORS.getUser(state),
});

const LoginFormContainer = () => {
  return <LoginForm />;
};

export default connect(mapStateToProps, null)(LoginFormContainer);
