import React from "react";
import { Col, Form, Switch } from "antd";
import { useIntl, defineMessages } from "react-intl";
import styled from "styled-components";

import { required } from "../../lib/utils/formValidation";
import globalTranslations from "../../lib/translations";

import InputNumberField from "../Fields/InputNumberField";

const translations = defineMessages({
  ipTosSwitch: {
    id: "IPTOSFields.ipTosSwitch",
    defaultMessage: "IP TOS",
  },
  ipTosValue: {
    id: "IPTOSFields.ipTosValue",
    defaultMessage: "IP TOS Value",
  },
});

const { Item } = Form;

const IPTOSFields = ({ prefix, form, disabled }) => {
  const { formatMessage } = useIntl();

  const setIPTOSValue = Form.useWatch([...prefix, "setIPTOS"], form) || false;

  return (
    <StyledDiv>
      <Col md={24} lg={6}>
        <Item name={[...prefix, "setIPTOS"]} label={formatMessage(translations.ipTosSwitch)} valuePropName="checked">
          <Switch
            defaultChecked={setIPTOSValue}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
            disabled={disabled}
          />
        </Item>
      </Col>
      {setIPTOSValue && (
        <Col md={24} lg={8}>
          <InputNumberField
            name={[...prefix, "valueIPTOS"]}
            label={formatMessage(translations.ipTosValue)}
            disabled={disabled}
            rules={[required]}
            min={0}
            max={255}
          />
        </Col>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
`;

export default IPTOSFields;
