import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

import { INPUT_STREAM_TYPE } from "../lib/utils/constants";

export const SET_PROBE = createAction("PROBE/SET_PROBE");
export const SET_PROBE_LOADING = createAction("PROBE/SET_PROBE_LOADING");
export const CLEAR_PROBE = createAction("PROBE/CLEAR_PROBE");

const defaultState = new Map({
  main: null,
  backup: null,
  loading: false,
});

const reducer = handleActions(
  {
    [SET_PROBE]: (state, { payload }) => {
      const probeType = payload?.type;

      if (probeType === INPUT_STREAM_TYPE.main) {
        return state.merge({ main: payload, loading: false });
      }

      if (probeType === INPUT_STREAM_TYPE.backup) {
        return state.merge({ backup: payload, loading: false });
      }

      return state.merge({ loading: false });
    },
    [SET_PROBE_LOADING]: (state, { payload }) => {
      return state.merge({ loading: payload });
    },
    [CLEAR_PROBE]: () => {
      return defaultState;
    },
  },
  defaultState
);

export const selectors = {
  getMainProbe: (state) => state.getIn(["probe", "main"]),
  getBackupProbe: (state) => state.getIn(["probe", "backup"]),
  getProbeLoading: (state) => state.getIn(["probe", "loading"]),
};

export default reducer;
