export default ({ nodeVersion }) => {
  const currentUrl = window.location.href;
  const isClientVersionInUrl = currentUrl.split("://")[1].split("/")[0].split(".")[0].includes("v");

  const currentClientVersion = isClientVersionInUrl && currentUrl.split("://")[1].split(".")[0].replace("v", "");

  if (+currentClientVersion === +nodeVersion || (+nodeVersion >= 4 && !currentClientVersion)) {
    return false;
  }

  return true;
};
