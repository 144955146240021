import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { defineMessages, useIntl } from "react-intl";
import { Table } from "antd";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";

import CloudChannelsServices from "../../../services/cloudChannels";

import { selectors as MQTT_CONNECTION_SELECTORS } from "../../../ducks/mqtt";
import { selectors as NODE_CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";

import { QUICKSTREAM_METHOD_V2 } from "../../../lib/utils/constants";
import themeColor from "../../../lib/style/theme";

import AddNewChannelButton from "../../../components/AddNewChannelButton";
import NewNodeChannelModal from "../NewNodeChannelModal";

import NodeChannelsListColumns from "./NodeChannelsListColumns";

import { errorNotification } from "../../../lib/utils/notification";

const translations = defineMessages({
  wrongCloudNodeName: {
    id: "general.wrongCloudNodeName",
    defaultMessage: "Node does not exist in Quickstream Cloud",
  },
});

const NodeChannelsList = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [visibleNewNodeChannelForm, setVisibleNewNodeChannelForm] = useState(false);
  const {
    location: { search },
  } = window;

  const { cwid, cnn } = useParams();
  const channels = useSelector(NODE_CHANNEL_SELECTORS.getChannels);
  const numberOfChannels = useSelector(NODE_CHANNEL_SELECTORS.numberOfChannels);
  const nodes = useSelector(MQTT_CONNECTION_SELECTORS.getNodes);

  const parsedQueryString = queryString.parse(search);

  const nodeData = nodes && nodes.find((node) => node.cwid === cwid);

  const [dataFromCloudFlag, setDataFromCloudFlag] = useState(false);
  const isLoadingChannelList = useSelector(NODE_CHANNEL_SELECTORS.getLoadingChannels);

  const channelsJs = channels && channels.toJS();

  useEffect(() => {
    const pagination = window.localStorage.getItem(`${cwid}-paginationNodeChannelList`);

    if (!search && pagination) {
      navigate(`/node/${cwid}/${cnn}/channels${pagination}`, { replace: true });
    }
  }, [search, numberOfChannels, parsedQueryString, cwid, cnn]);

  useEffect(() => {
    if (channelsJs.length < 0 || dataFromCloudFlag) {
      return;
    }

    async function fetchPermissionDescription({ cloudIds, channelId }) {
      await CloudChannelsServices.getChannelPermissionsDescription(
        { cloudIds, channelId, cwid },
        {
          errorNotification: errorNotification(formatMessage),
        }
      );
    }

    const qsOutputs = {};
    Object.values(channelsJs).forEach((channel) => {
      const hasNonmuxedOutputs = channel.config?.nonMuxedOutputs && channel.config?.nonMuxedOutputs.length > 0;
      const hasMuxedOutputs = channel.config?.muxedOutputs && channel.config?.muxedOutputs.length > 0;

      if (hasNonmuxedOutputs) {
        // eslint-disable-next-line no-unused-expressions
        channel.config?.nonMuxedOutputs.forEach((nonMuxedOutput) => {
          if (nonMuxedOutput.type === QUICKSTREAM_METHOD_V2.outQSDirect.value) {
            if (qsOutputs[channel.channelId]) {
              qsOutputs[channel.channelId] = [...qsOutputs[channel.channelId], nonMuxedOutput.cloudId];
            } else {
              qsOutputs[channel.channelId] = [nonMuxedOutput.cloudId];
            }
          }
        });
      }

      if (hasMuxedOutputs) {
        // eslint-disable-next-line no-unused-expressions
        channel.config?.muxedOutputs.forEach((muxedOutput) => {
          // eslint-disable-next-line no-unused-expressions
          muxedOutput?.outputs?.forEach((muxedOutputOutput) => {
            if (muxedOutputOutput.type === QUICKSTREAM_METHOD_V2.outQSDirect.value) {
              if (qsOutputs[channel.channelId]) {
                qsOutputs[channel.channelId] = [...qsOutputs[channel.channelId], muxedOutputOutput.cloudId];
              } else {
                qsOutputs[channel.channelId] = [muxedOutputOutput.cloudId];
              }
            }
          });
        });
      }
    });

    Object.entries(qsOutputs).forEach((output) => {
      const [channelId, cloudIds] = output;

      const stringCloudIds = JSON.stringify(cloudIds);

      fetchPermissionDescription({ channelId, cloudIds: stringCloudIds, cwid });
      setDataFromCloudFlag(true);
    });
  }, [channels, channelsJs, cwid, dataFromCloudFlag, formatMessage]);

  const data = channelsJs && Object.entries(channelsJs).map(([, item]) => item);

  if (nodes && nodes.length > 0 && !nodeData) {
    errorNotification(formatMessage)(translations.wrongCloudNodeName);
    navigate("/");
  }

  const handleChange = () => {
    window.localStorage.setItem(`${cwid}-paginationNodeChannelList`, `?tab=${parsedQueryString.tab}`);
  };

  return (
    <>
      <StyledTable
        loading={isLoadingChannelList}
        dataSource={data}
        columns={NodeChannelsListColumns({ cwid, cnn })}
        rowClassName={(record) => {
          const isConfigWrongVersionRunning = record?.status?.configWrongVersionRunning;

          return isConfigWrongVersionRunning ? "wrongVersionRunning" : null;
        }}
        rowKey={(record) => record.channelId}
        scroll={{ x: 1080 }}
        onChange={handleChange}
        pagination={false}
      />
      {nodeData && <AddNewChannelButton setVisibleNewNodeChannelForm={setVisibleNewNodeChannelForm} />}
      {nodeData && (
        <NewNodeChannelModal
          cwid={cwid}
          setVisibleNewNodeChannelForm={setVisibleNewNodeChannelForm}
          visibleNewNodeChannelForm={visibleNewNodeChannelForm}
        />
      )}
    </>
  );
};

const StyledTable = styled(Table)`
  .wrongVersionRunning {
    background-color: ${themeColor.lightRed};
  }
`;

export default NodeChannelsList;
