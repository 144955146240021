import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl, defineMessages } from "react-intl";
import { Popconfirm, Button } from "antd";

import LicenseServices from "../../../../services/license";

import { errorNotification, successNotification } from "../../../../lib/utils/notification";
import globalTranslation from "../../../../lib/translations";

const translations = defineMessages({
  deleteLicenseQuestion: {
    id: "LicenseActionButtons.deleteLicenseQuestion",
    defaultMessage: "Are you sure you want to delete this license?",
  },
});

const LicenseActionButtons = ({ licenseId, playoutLicenses }) => {
  const { formatMessage } = useIntl();

  const onConfirmRemove = useCallback(async () => {
    if (playoutLicenses) {
      await LicenseServices.playoutDeleteLicense(licenseId, {
        errorNotification: errorNotification(formatMessage),
        successNotification: successNotification(formatMessage),
      });
    } else {
      await LicenseServices.deleteLicense(licenseId, {
        errorNotification: errorNotification(formatMessage),
        successNotification: successNotification(formatMessage),
      });
      await LicenseServices.getLicenseList({ errorNotification: errorNotification(formatMessage) });
    }
  }, [licenseId, formatMessage]);

  return (
    <Popconfirm
      placement="top"
      title={formatMessage(translations.deleteLicenseQuestion)}
      onConfirm={onConfirmRemove}
      okText={formatMessage(globalTranslation.yes)}
      cancelText={formatMessage(globalTranslation.no)}
    >
      <Button type="primary" danger onClick={() => null}>
        {formatMessage(globalTranslation.delete)}
      </Button>
    </Popconfirm>
  );
};

LicenseActionButtons.propTypes = {
  licenseId: PropTypes.number.isRequired,
  playoutLicenses: PropTypes.bool,
};

LicenseActionButtons.defaultProps = {
  playoutLicenses: false,
};

export default LicenseActionButtons;
