import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const actions = {
  SAVE_ACCOUNTS_LIST: createAction("ADMIN/SAVE_ACCOUNT_LIST"),
  CLEAR_ACCOUNTS_LIST: createAction("ADMIN/CLEAR_ACCOUNTS_LIST"),
};

const initialState = new Map({
  accountsList: [],
});

const reducer = handleActions(
  {
    [actions.SAVE_ACCOUNTS_LIST]: (state, { payload }) => state.merge({ accountsList: payload }),
    [actions.CLEAR_ACCOUNTS_LIST]: () => initialState,
  },
  initialState
);

export const selectors = {
  getAccountList: (state) => state.getIn(["admin", "accountsList"]),
};

export default reducer;
