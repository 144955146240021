import React from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import store from "../../store";

import { actions as NODE_STATS_V5_ACTIONS, selectors as NODE_STATS_V5_SELECTOR } from "../../ducks/nodeStatsV5";

export default function StatsScaleSelector({ selectedRangeValue }) {
  const statScale = useSelector(NODE_STATS_V5_SELECTOR.getStatsScale);
  const handleChangeScale = (selectedScale) => {
    store.dispatch(NODE_STATS_V5_ACTIONS.SET_STAT_SCALE_V5(selectedScale));
  };

  const scaleOptions = [
    { value: 1, label: <FormattedMessage id="StatsScaleSelector.30s" defaultMessage="30 seconds" /> },
    { value: 2, label: <FormattedMessage id="StatsScaleSelector.1minute" defaultMessage="1 minute" /> },
    { value: 10, label: <FormattedMessage id="StatsScaleSelector.5minutes" defaultMessage="5 minutes" /> },
    { value: 30, label: <FormattedMessage id="StatsScaleSelector.15minutes" defaultMessage="15 minutes" /> },
    { value: 60, label: <FormattedMessage id="StatsScaleSelector.30minutes" defaultMessage="30 minutes" /> },
    { value: 120, label: <FormattedMessage id="StatsScaleSelector.1hour" defaultMessage="1 hour" /> },
  ];

  return (
    <>
      <FormattedMessage id="StatsScaleSelector.scale" defaultMessage="Scale" />
      {": "}
      <Select
        style={{ width: 100, marginRight: "5px" }}
        value={statScale}
        options={scaleOptions}
        onChange={handleChangeScale}
        disabled={selectedRangeValue === 0.5}
      />
    </>
  );
}

StatsScaleSelector.propTypes = {
  selectedRangeValue: PropTypes.number.isRequired,
};
