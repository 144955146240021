import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Table, Card } from "antd";
import { Link } from "react-router-dom";

import LicenseServices from "../../../../services/license";
import { selectors as LICENSES_SELECTORS } from "../../../../ducks/license";

import { errorNotification } from "../../../../lib/utils/notification";
import CloudLicensesListElement from "../../../../components/CloudLicensesListElement";

const columns = [
  {
    title: <FormattedMessage id="CloudLicensesList.username" defaultMessage="Username" />,
    dataIndex: "username",
    key: "username",
    sorter: (currentValue, nextValue) => currentValue.username.localeCompare(nextValue.username),
    width: 130,
    fixed: "left",
    render: (username) => {
      return <Link to={`/cloud/licenses/${username}`}>{username}</Link>;
    },
  },
  {
    title: <FormattedMessage id="CloudLicensesList.softQuota" defaultMessage="Soft quota" />,
    dataIndex: "softQuota",
    key: "softQuota",
    align: "center",
  },
  {
    title: <FormattedMessage id="CloudLicensesList.aac" defaultMessage="AAC" />,
    dataIndex: "codecAAC",
    key: "codecAAC",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.codecAAC - nextValue.codecAAC,
    render: (codecAAC, record) => <CloudLicensesListElement usage={codecAAC} usageLimit={record?.licenseCodecAAC} />,
  },
  {
    title: <FormattedMessage id="CloudLicensesList.x264" defaultMessage="X264" />,
    dataIndex: "codecX264",
    key: "codecX264",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.codecX264 - nextValue.codecX264,
    render: (codecX264, record) => <CloudLicensesListElement usage={codecX264} usageLimit={record?.licenseCodecX264} />,
  },
  {
    title: <FormattedMessage id="CloudLicensesList.x265" defaultMessage="X265" />,
    dataIndex: "codecX265",
    key: "codecX265",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.codecX265 - nextValue.codecX265,
    render: (codecX265, record) => <CloudLicensesListElement usage={codecX265} usageLimit={record?.licenseCodecX265} />,
  },
  {
    title: <FormattedMessage id="CloudLicensesList.inputsCount" defaultMessage="Inputs Count" />,
    dataIndex: "inputsCount",
    key: "inputsCount",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.inputsCount - nextValue.inputsCount,
    render: (inputsCount, record) => (
      <CloudLicensesListElement usage={inputsCount} usageLimit={record?.licenseInputsCount} />
    ),
  },
  {
    title: <FormattedMessage id="CloudLicensesList.inputsNotEncoded" defaultMessage="Inputs not Encoded" />,
    dataIndex: "inputsNotEncoded",
    key: "inputsNotEncoded",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.inputsNotEncoded - nextValue.inputsNotEncoded,
  },
  {
    title: <FormattedMessage id="CloudLicensesList.inputsOther" defaultMessage="Inputs Other" />,
    dataIndex: "inputsOther",
    key: "inputsOther",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.inputsOther - nextValue.inputsOther,
  },
  {
    title: <FormattedMessage id="CloudLicensesList.inputsDirect" defaultMessage="Inputs Quickstream" />,
    dataIndex: "inputsQuickstream",
    key: "inputsQuickstream",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.inputsQuickstream - nextValue.inputsQuickstream,
  },
  {
    title: <FormattedMessage id="CloudLicensesList.outputsCount" defaultMessage="Outputs Count" />,
    dataIndex: "outputsCount",
    key: "outputsCount",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.outputsCount - nextValue.outputsCount,
    render: (outputsCount, record) => (
      <CloudLicensesListElement usage={outputsCount} usageLimit={record?.licenseOutputsCount} />
    ),
  },
  {
    title: <FormattedMessage id="CloudLicensesList.outputsDirect" defaultMessage="Outputs Direct" />,
    dataIndex: "outputsDirect",
    key: "outputsDirect",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.outputsDirect - nextValue.outputsDirect,
  },
  {
    title: <FormattedMessage id="CloudLicensesList.outputsEncoded" defaultMessage="Outputs Encoded" />,
    dataIndex: "outputsEncoded",
    key: "outputsEncoded",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.outputsEncoded - nextValue.outputsEncoded,
  },
  {
    title: <FormattedMessage id="CloudLicensesList.outputsNotEncoded" defaultMessage="Outputs not Encoded" />,
    dataIndex: "outputsNotEncoded",
    key: "outputsNotEncoded",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.outputsNotEncoded - nextValue.outputsNotEncoded,
  },
  {
    title: <FormattedMessage id="CloudLicensesList.outputsQuickstream" defaultMessage="Outputs Quickstream" />,
    dataIndex: "outputsQuickstream",
    key: "outputsQuickstream",
    align: "center",
    sorter: (currentValue, nextValue) => currentValue.outputsQuickstream - nextValue.outputsQuickstream,
    render: (outputsQuickstream, record) => (
      <CloudLicensesListElement usage={outputsQuickstream} usageLimit={record?.licenseOutputsQuickstream} />
    ),
  },
];

const CloudLicensesList = () => {
  const { formatMessage } = useIntl();
  const licenses = useSelector(LICENSES_SELECTORS.getCloudLicensesList);

  useEffect(() => {
    LicenseServices.getCloudLicensesList({ errorNotification: errorNotification(formatMessage) });
  }, [formatMessage]);

  return (
    <Card title={<FormattedMessage id="CloudLicensesList.cloudLicenses" defaultMessage="Cloud licenses" />}>
      <Table
        columns={columns}
        dataSource={licenses}
        bordered
        role="table"
        rowKey={(record) => record.username}
        scroll={{ x: 1500 }}
      />
    </Card>
  );
};

export default CloudLicensesList;
