import React from "react";
import { Form, Row } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import SelectStream from "../../EngineDirect/InputStreamSection/SelectStream";

const { List } = Form;

function InputStreamAudioSelector({ disabled, prefix }) {
  return (
    <Row gutter={24}>
      <List name={[...prefix]}>
        {(fields) => (
          <>
            {fields.map((field, index) => (
              <SelectStream
                disabled={disabled}
                fieldPrefix={[index]}
                prefix={[...prefix, index]}
                key={`${[...prefix, index]}`}
                label={<FormattedMessage id="InputStreamAudioSelector.label" defaultMessage="Audio stream" />}
              />
            ))}
          </>
        )}
      </List>
    </Row>
  );
}

InputStreamAudioSelector.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default InputStreamAudioSelector;
