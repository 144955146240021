import React from "react";
import { FormattedMessage } from "react-intl";
import { Switch, Button, Popconfirm } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";

import { actions as NODE_ACTIONS } from "../../ducks/nodeNotifications";
import store from "../../store";

import themeColor from "../../lib/style/theme";

const NotificationsPageTitle = ({ showPopup, handleSwitchNotification }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClearNotifications = () => {
    store.dispatch(NODE_ACTIONS.CLEAR_NODE_NOTIFICATIONS());
    searchParams.delete("tab");
    searchParams.delete("page");
    setSearchParams(searchParams);
    window.localStorage.removeItem(`paginationNotification`);
  };

  return (
    <>
      <FormattedMessage
        id="NotificationsPageTitle.showNodeNotification"
        defaultMessage="Show node popup notification"
      />
      :{" "}
      <Switch
        checkedChildren={<FormattedMessage id="NotificationsPage.enabled" defaultMessage="Enabled" />}
        unCheckedChildren={<FormattedMessage id="NotificationsPage.disabled" defaultMessage="Disabled" />}
        defaultChecked={showPopup}
        onChange={handleSwitchNotification}
      />
      <Popconfirm
        title={
          <FormattedMessage
            id="NotificationsPageTitle.confirmClear"
            defaultMessage="Are you sure, you want to clear all notifications?"
          />
        }
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        onConfirm={handleClearNotifications}
        okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
        cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
      >
        <StyledButton icon={<DeleteOutlined />} type="primary" danger>
          <span>
            <FormattedMessage id="NotificationsPageTitle.clearNotifications" defaultMessage="Clear notifications" />
          </span>
        </StyledButton>
      </Popconfirm>
    </>
  );
};

const StyledButton = styled(Button)`
  margin-left: 10px;
  background-color: ${themeColor.darkRed};
`;

NotificationsPageTitle.propTypes = {
  showPopup: PropTypes.bool.isRequired,
  handleSwitchNotification: PropTypes.func.isRequired,
};

export default NotificationsPageTitle;
