import React from "react";
import { Popconfirm, Button } from "antd";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { actions as ACCOUNT_ACTIONS } from "../../ducks/account";
import globalTranslation from "../../lib/translations";

import AccountService from "../../services/account";
import store from "../../store";
import { errorNotification, successNotification } from "../../lib/utils/notification";

const translations = defineMessages({
  deleteAccountQuestionTitle: {
    id: "account.deleteAccountQuestionTitle",
    defaultMessage: `Are you sure you want to delete your account?`,
  },
  deleteAccountQuestionInfo: {
    id: "account.deleteAccountQuestionInfo",
    defaultMessage: `Deleting an account results in irreversible loss of all data related to your account`,
  },
  deleteAccountQuestionInfo2: {
    id: "account.deleteAccountQuestionInfo2",
    defaultMessage: `(e.g. licenses, shared channels and nodes in quickstream cloud)`,
  },
});

const DeleteAccount = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const deleteAccount = () => {
    AccountService.deleteAccount({
      errorNotification: errorNotification(formatMessage),
      successNotification: successNotification(formatMessage),
    });
    store.dispatch(ACCOUNT_ACTIONS.CLEAR_ACCOUNT_DATA());
    window.localStorage.removeItem("authToken");
    navigate("/login");
  };

  return (
    <Popconfirm
      placement="top"
      title={
        <>
          <div>
            <b>{formatMessage(translations.deleteAccountQuestionTitle)}</b>
          </div>
          <div>{formatMessage(translations.deleteAccountQuestionInfo)}</div>
          <div>{formatMessage(translations.deleteAccountQuestionInfo2)}</div>
        </>
      }
      onConfirm={deleteAccount}
      okText={formatMessage(globalTranslation.yes)}
      cancelText={formatMessage(globalTranslation.no)}
    >
      <Button type="primary" danger>
        <FormattedMessage id="account.remove" defaultMessage="Delete" />
      </Button>
    </Popconfirm>
  );
};

export default DeleteAccount;
