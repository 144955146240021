import React from "react";
import { FormattedMessage } from "react-intl";
import { Badge } from "antd";
import PropTypes from "prop-types";

// import { CONNECTION_METHOD_OUTPUT, CONNECTION_METHOD_INPUT } from "../../../lib/utils/constants";
import themeColor from "../../../lib/style/theme";
import { NODE_CHANNEL_STATE } from "../../../lib/utils/constants";

const ClientsConnected = ({ connected, outputs }) => {
  // const UDP_METHODS = [
  //   CONNECTION_METHOD_OUTPUT.outUdp.value,
  //   CONNECTION_METHOD_INPUT.inUdpMulticast.value,
  //   CONNECTION_METHOD_INPUT.inUdpUnicast.value,
  // ];

  // if (UDP_METHODS.includes(connectionType)) {
  //   return <span>N/A</span>;
  // }

  const outputsText = () => {
    const connectedOutputs =
      (outputs && outputs.filter((output) => output?.threadStatusText === NODE_CHANNEL_STATE.CONNECTED)) || [];

    const parsedOutputs = connectedOutputs.map((output) => {
      return output?.connectionInfo;
    });

    return outputs ? parsedOutputs.join("\r\n") : "";
  };

  if (connected || connected === 0) {
    return (
      <Badge
        title={outputsText()}
        count={connected}
        showZero
        style={{ backgroundColor: `${themeColor.green}`, color: "#FFF" }}
      />
    );
  }

  return <FormattedMessage id="NodeChannelDetailView.noData" defaultMessage="No data" />;
};

ClientsConnected.propTypes = {
  // connectionType: PropTypes.string,
  connected: PropTypes.number,
};

ClientsConnected.defaultProps = {
  // connectionType: null,
  connected: null,
};

export default ClientsConnected;
