import React, { useState, useCallback } from "react";
import { Tooltip, Button, Modal, Steps, Row, Col, Drawer } from "antd";
import { FormattedMessage } from "react-intl";
import { ApiOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import BasicModeQSSelectNodeStep from "../../components/BasicModeQS/BasicModeQSSelectNodeStep";
import BasicModeQSGeneralSettings from "../../components/BasicModeQS/BasicModeQSGeneralSettings";
import BasicModeQSInput from "../../components/BasicModeQS/BasicModeQSInput";
import BasicModeQSOutput from "../../components/BasicModeQS/BasicModeQSOutput";
import NodePorts from "../Node/NodePorts";

import store from "../../store";

import { selectors as NODE_SELECTORS, actions as NODE_ACTIONS } from "../../ducks/node";

export default function HubChannelUseOnNodeAction({ cwid, channelName, cloudId }) {
  const [openModal, setOpenModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedNode, setSelectedNode] = useState(null);
  const [generalSettings, setGeneralSettings] = useState({});
  const [inputSettings, setInputSettings] = useState({});
  const openDrawer = useSelector(NODE_SELECTORS.getNodeOpenPortsDrawer);

  const handleUseInNode = () => {
    setOpenModal(!openModal);
  };

  const handlePrevStep = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [setCurrentStep, currentStep]);

  const handleNextStep = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [setCurrentStep, currentStep]);

  const handleClose = useCallback(() => {
    setOpenModal(false);
    setCurrentStep(0);
    store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
  }, [setOpenModal, setCurrentStep]);

  const items = [
    {
      title: <FormattedMessage id="HubChannelUseOnNodeAction.node" defaultMessage="Node" />,
      description: <FormattedMessage id="HubChannelUseOnNodeAction.selectNode" defaultMessage="Select Node" />,
      content: (
        <BasicModeQSSelectNodeStep
          setSelectedNode={setSelectedNode}
          selectedNodeCwid={selectedNode?.cwid}
          handleNextStep={handleNextStep}
        />
      ),
    },
    {
      title: <FormattedMessage id="HubChannelUseOnNodeAction.baseInfo" defaultMessage="General" />,
      description: <FormattedMessage id="HubChannelUseOnNodeAction.generalSetting" defaultMessage="General settings" />,
      content: (
        <BasicModeQSGeneralSettings
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          channelName={channelName}
          setGeneralSettings={setGeneralSettings}
          generalSettings={generalSettings}
        />
      ),
    },
    {
      title: <FormattedMessage id="HubChannelUseOnNodeAction.input" defaultMessage="Input" />,
      description: <FormattedMessage id="HubChannelUseOnNodeAction.inputSettings" defaultMessage="Input settings" />,
      content: (
        <BasicModeQSInput
          generalSettings={generalSettings}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          setInputSettings={setInputSettings}
          inputSettings={inputSettings}
        />
      ),
    },
    {
      title: <FormattedMessage id="HubChannelUseOnNodeAction.output" defaultMessage="Output" />,
      description: <FormattedMessage id="HubChannelUseOnNodeAction.outputSettings" defaultMessage="Output settings" />,
      content: (
        <BasicModeQSOutput
          generalSettings={generalSettings}
          handlePrevStep={handlePrevStep}
          selectedNode={selectedNode}
          setOpenModal={setOpenModal}
          setCurrentStep={setCurrentStep}
          cloudId={cloudId}
          channelName={channelName}
          inputSettings={inputSettings}
        />
      ),
    },
  ];

  return (
    <>
      <Tooltip
        title={
          cwid ? (
            <FormattedMessage id="HubChannelUseOnNodeAction.usedOnNode" defaultMessage="Already used in Node" />
          ) : null
        }
      >
        <Button onClick={handleUseInNode} type="primary" icon={<ApiOutlined />} disabled={cwid}>
          <span>
            <FormattedMessage id="HubChannelUseOnNodeAction.useInNode" defaultMessage="Use in Node" />
          </span>
        </Button>
      </Tooltip>
      <Modal
        destroyOnClose
        open={openModal}
        title={
          <FormattedMessage id="HubChannelUseOnNodeAction.newChannelOnNode" defaultMessage="New channel on Node" />
        }
        footer={null}
        onCancel={handleClose}
        width={1000}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Steps items={items} current={currentStep} />
          </Col>
          <Col span={24}>{items[currentStep].content}</Col>
        </Row>
      </Modal>
      <Drawer
        destroyOnClose
        title={<FormattedMessage id="HubChannelUseOnNodeAction.usedPorts" defaultMessage="Used ports" />}
        placement="right"
        onClose={() => store.dispatch(NODE_ACTIONS.SET_NODE_DRAWER(false))}
        open={openDrawer}
        width={750}
        forceRender
        zIndex={1001}
      >
        <NodePorts activeLink={false} selectedCnn={selectedNode?.cnn} selectedCwid={selectedNode?.cwid} />
      </Drawer>
    </>
  );
}

HubChannelUseOnNodeAction.propTypes = {
  cloudId: PropTypes.number.isRequired,
  channelName: PropTypes.string.isRequired,
  cwid: PropTypes.string,
};

HubChannelUseOnNodeAction.defaultProps = {
  cwid: null,
};
