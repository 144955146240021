import React from "react";
import { FormattedMessage } from "react-intl";
import { Badge, Tooltip } from "antd";
import PropTypes from "prop-types";

import themeColor from "../../../lib/style/theme";
import badgeStatus from "../../../lib/utils/badgeStatus";
import { INPUT_CHANNEL_STATE } from "../../../lib/utils/constants";

function ClientsConnected({ connected, outputs }) {
  const filteredOutputs = outputs?.filter((output) => output.threadStatusText !== INPUT_CHANNEL_STATE.CLOSED);

  const outputsText = () => {
    const parsedOutputs =
      filteredOutputs?.map((output) => {
        const sourceId = `${output?.id}-${output?.p1}-${output?.p2}-${output?.p3}-${output?.s}`;

        return (
          <div key={sourceId}>
            <Badge status={badgeStatus(output.threadStatusText)} />{" "}
            {output.connectionInfo ? output.connectionInfo : output.threadStatusText}
          </div>
        );
      }) || [];

    return filteredOutputs?.length > 0 ? parsedOutputs : null;
  };

  if (connected || connected === 0) {
    if (filteredOutputs?.length === connected) {
      const badgeColor = connected > 0 ? themeColor.green : themeColor.grayBorder;

      return (
        <Tooltip placement="top" title={outputsText()}>
          <Badge count={connected} showZero style={{ backgroundColor: badgeColor, color: "#FFF" }} />
        </Tooltip>
      );
    }

    return (
      <Tooltip placement="top" title={outputsText()}>
        <Badge count={connected} showZero style={{ backgroundColor: themeColor.orangeHover, color: "#FFF" }} />
      </Tooltip>
    );
  }

  return <FormattedMessage id="NodeChannelDetailView.noData" defaultMessage="No data" />;
}

ClientsConnected.propTypes = {
  connected: PropTypes.number,
  outputs: PropTypes.arrayOf(
    PropTypes.shape({
      connectionInfo: PropTypes.string.isRequired,
      errorCode: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      permissionId: PropTypes.number.isRequired,
      threadStatus: PropTypes.number.isRequired,
      threadStatusText: PropTypes.string.isRequired,
    })
  ),
};

ClientsConnected.defaultProps = {
  connected: null,
  outputs: null,
};

export default ClientsConnected;
