import React from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

const translations = defineMessages({
  label: {
    id: "PixFormatLabel.pixelFormat",
    defaultMessage: "Pixel Format",
  },
  labelTooltip: {
    id: "PixFormatLabel.labelTooltip",
    // eslint-disable-next-line max-len
    defaultMessage: "Available/disable values depend on the selected profile",
  },
});

const PixFormatLabel = ({ isNDICodecType }) => {
  const { formatMessage } = useIntl();

  if (isNDICodecType) {
    return (
      <StyledLabel>
        <span>{formatMessage(translations.label)}</span>
        <Tooltip placement="top" title={formatMessage(translations.labelTooltip)}>
          <QuestionCircleFilled />
        </Tooltip>
      </StyledLabel>
    );
  }

  return formatMessage(translations.label);
};

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

export default PixFormatLabel;
