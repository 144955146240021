import React from "react";
import { Typography, Badge } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import themeColor from "../../../../lib/style/theme";

import IncompleteConfig from "../../../IncompleteConfig";

import useNodeChannelConfig from "../../../../hooks/useNodeChannelConfig";

const { Paragraph } = Typography;
const { Ribbon } = Badge;

export default function PlaylistSourceElement({ incomplete, handleClick, title, label }) {
  const noIncomplete = !incomplete || (incomplete && incomplete.length === 0);

  const { channelConfig } = useNodeChannelConfig();

  return (
    <Ribbon
      text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
      color={noIncomplete ? themeColor.green : themeColor.orange}
    >
      <StyledContainer onClick={handleClick}>
        <StyledAsideType>{label}</StyledAsideType>
        <StyledBoldText>{title}</StyledBoldText>
        <div>
          <>
            <StyledText>
              <FormattedMessage id="PlaylistSourceElement.url" defaultMessage="URL" />:
            </StyledText>
            {channelConfig?.playlistSource?.url && (
              <StyledBoldText>
                <StyledParagraph
                  onClick={(event) => event.stopPropagation()}
                  copyable={{
                    tooltips: [
                      <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                      <FormattedMessage id="general.copied" defaultMessage="Copied" />,
                    ],
                  }}
                >
                  {channelConfig?.playlistSource?.url}
                </StyledParagraph>
              </StyledBoldText>
            )}
          </>
        </div>
      </StyledContainer>
    </Ribbon>
  );
}

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledParagraph = styled(Paragraph)`
  display: inherit;

  & .anticon-copy {
    color: ${themeColor.orange};
  }
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 105px;
  margin: 0 0 15px 30px;
  padding: 10px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  color: #fff;
  padding: 5px;
  line-height: 24px;
  position: absolute;
  top: 104px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 105px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;
