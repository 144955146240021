import React from "react";
import { Spin, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";

export default function LoadingNodeData() {
  return (
    <Spin tip={<FormattedMessage id="LoadingNodeData.loadingNodeData" defaultMessage="Loading node data" />}>
      <Skeleton active />
    </Spin>
  );
}
