const LogsLabel = ({ logValue }) => {
  switch (+logValue) {
    case 0:
      return "Critical";
    case 1:
      return "Error";
    case 2:
      return "Warning";
    case 3:
      return "Info";
    case 4:
      return "Debug";
    default:
      return "Verbose";
  }
};

export default LogsLabel;
