import { useSelector } from "react-redux";

import { selectors as ACTION_SELECTORS } from "../ducks/account";

import { MAIN_THEME } from "../lib/utils/constants";

function useMainTheme() {
  const mainTheme = useSelector(ACTION_SELECTORS.getMainTheme);
  const isLightTheme = mainTheme === MAIN_THEME.LIGHT;
  const isDarkTheme = mainTheme === MAIN_THEME.DARK;

  return { isLightTheme, isDarkTheme, mainTheme };
}

export default useMainTheme;
