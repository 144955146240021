export default (value) => {
  if (!value) {
    return null;
  }

  if (+value >= 1024) {
    return `${(+value / 1024).toFixed(2)} [Gbps]`;
  }

  if (+value < 1) {
    return `${(+value * 1024).toFixed(2)} [Kbps]`;
  }

  return `${(+value).toFixed(2)} [Mbps]`;
};
