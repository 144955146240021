import React from "react";
import { Card } from "antd";
import styled from "styled-components";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import useMainTheme from "../../hooks/useMainTheme";

import logoUrl from "../../lib/images/logo.png";
import logoLightUrl from "../../lib/images/logo-light.png";

import TurnstileValidation from "../../components/TurnstileValidation";
import RegistrationForm from "../../components/RegistrationForm";
import ReturnSignInFooter from "../../components/ReturnSignInFooter";

const translations = defineMessages({
  registrationLinkTitle: {
    id: "PasswordForgotForm.registrationLinkTitle",
    defaultMessage: "Already have an account?",
  },
});

const Registration = () => {
  const { formatMessage } = useIntl();
  const { isDarkTheme } = useMainTheme();

  return (
    <StyledFormContainer>
      <Card
        title={<FormattedMessage id="Registration.title" defaultMessage="Registration Form" />}
        extra={<img src={isDarkTheme ? logoUrl : logoLightUrl} alt="quickstream logo" width="200" />}
      >
        <RegistrationForm />
        <TurnstileValidation />
      </Card>
      <ReturnSignInFooter linkTitle={formatMessage(translations.registrationLinkTitle)} />
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default Registration;
