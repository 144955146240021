import React from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

const translations = defineMessages({
  label: {
    id: "NetworkFieldLabel.label",
    defaultMessage: "Network Interface",
  },
  labelInputTooltip: {
    id: "NetworkFieldLabel.labelInputTooltip",
    // eslint-disable-next-line max-len
    defaultMessage: `Select interface you to use to receive TS UDP traffic. If you select Automatic the routing table will be used to select Interface.`,
  },
  labelOutputTooltip: {
    id: "NetworkFieldLabel.labelOutputTooltip",
    // eslint-disable-next-line max-len
    defaultMessage: `Select interface you to use to send TS UDP traffic. If you select Automatic routing table will be used to select Interface. Find out more in knowledgebase: `,
  },
});

const NetworkFieldLabel = (source) => {
  const { formatMessage } = useIntl();

  const LabelOutputTooltip = (
    <>
      <span>{formatMessage(translations.labelOutputTooltip)}</span>
      <StyledLink
        href="https://help.quickstream.tech/portal/kb/articles/establishing-connections"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://help.quickstream.tech/portal/kb/articles/establishing-connections
      </StyledLink>
    </>
  );

  return (
    <StyledLabel>
      <span>{formatMessage(translations.label)}</span>
      <Tooltip
        placement="top"
        title={source === "input" ? formatMessage(translations.labelInputTooltip) : LabelOutputTooltip}
      >
        <QuestionCircleFilled />
      </Tooltip>
    </StyledLabel>
  );
};

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

const StyledLink = styled.a`
  margin-left: 4px;
`;

export default NetworkFieldLabel;
