import React from "react";
import { FormattedMessage } from "react-intl";
import { InputNumber, Form } from "antd";
import PropTypes from "prop-types";

import { required } from "../../lib/utils/formValidation";

const { Item } = Form;

const VideoCodecGOPLengthField = ({ name, disabled }) => {
  return (
    <Item
      name={name}
      label={<FormattedMessage id="VideoCodecGOPLengthField.gopLength" defaultMessage="GoP Length [frames]" />}
      rules={[required]}
    >
      <InputNumber type="number" disabled={disabled} min={0} max={250} />
    </Item>
  );
};

VideoCodecGOPLengthField.propTypes = {
  disabled: PropTypes.bool,
};

VideoCodecGOPLengthField.defaultProps = {
  disabled: null,
};

export default VideoCodecGOPLengthField;
