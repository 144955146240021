import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Spin, Skeleton, Card } from "antd";
import { FormattedMessage } from "react-intl";
import { ProfileOutlined, CodeOutlined, LineChartOutlined } from "@ant-design/icons";
import { useParams, useSearchParams, Navigate } from "react-router-dom";

import store from "../../../store";

import { selectors as MQTT_SELECTORS } from "../../../ducks/mqtt";
import { actions as NODE_ACTIONS, selectors as NODE_SELECTORS } from "../../../ducks/node";

import NodeChannelFormV4 from "../../../components/NodeChannelFormV4";
import NodeChannelFormV5 from "../../../components/NodeChannelFormV5";
import Logs from "../../../components/Logs";
import LogsV5 from "../../../components/LogsV5";
import NodeStatisticsContainer from "../NodeStatistics";
import GlobalErrorBoundaries from "../../../components/ErrorBoundaries/GlobalErrorBoundaries";

import { MQTT_STATUS, NODE_CHANEL_DETAIL_VIEW_TABS } from "../../../lib/utils/constants";
import useNodeChannels from "../../../hooks/useNodeChannels";
import useNodeCapabilities from "../../../hooks/useNodeCapabilities";
import useNodeStunAddress from "../../../hooks/useNodeStunAddress";
import useNodeVersion from "../../../hooks/useNodeVersion";

const NodeChannelDetailViewContainer = ({ nodes, MQTTConnection, MQTTStatus }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryTab = searchParams.get("tab");

  const { id, cwid, cnn } = useParams();
  const nodeChannels = useNodeChannels();
  const channelConfig = nodeChannels.getIn([`${id}`, "config"]);
  const nodeChannelsLoaded = nodeChannels && nodeChannels.size > 0;

  const isStundAddress = useSelector(NODE_SELECTORS.getIsStundAddress);
  const nodeData = nodes.find((node) => node.cwid === cwid);
  useNodeCapabilities();
  useNodeStunAddress();
  const { isV5Node, isV4Node } = useNodeVersion();

  const isConnection = MQTTConnection && MQTTStatus === MQTT_STATUS.CONNECTED;

  useEffect(() => {
    if (isConnection) {
      store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    }
  }, [cwid, nodeData, isConnection]);

  if (nodeChannelsLoaded && !channelConfig) {
    return <Navigate to={`/node/${cwid}/${cnn}/channels?tab=channelList`} replace />;
  }

  if (!channelConfig || !isStundAddress) {
    return (
      <Spin
        tip={
          <FormattedMessage
            id="NodeChannelDetailViewContainer.loadingChannelData"
            defaultMessage="Loading channel data..."
          />
        }
      >
        <Skeleton active />
      </Spin>
    );
  }

  const openTabs = (key) => {
    searchParams.set("c", id);
    searchParams.set("tab", key);
    setSearchParams(searchParams);
  };

  const tabList = [
    {
      key: NODE_CHANEL_DETAIL_VIEW_TABS.configurations,
      icon: <ProfileOutlined />,
      label: (
        <span>
          <FormattedMessage id="NodeChannelDetailViewContainer.configuration" defaultMessage="Configuration" />
        </span>
      ),
    },
    {
      key: NODE_CHANEL_DETAIL_VIEW_TABS.logs,
      icon: <CodeOutlined />,
      label: (
        <span>
          <FormattedMessage id="NodeChannelDetailViewContainer.logs" defaultMessage="Logs" />
        </span>
      ),
    },
    {
      key: NODE_CHANEL_DETAIL_VIEW_TABS.statistics,
      icon: <LineChartOutlined />,
      label: (
        <span>
          <FormattedMessage id="NodeChannelDetailViewContainer.statistics" defaultMessage="Statistics" />
        </span>
      ),
    },
  ];

  const isDetailView = queryTab === NODE_CHANEL_DETAIL_VIEW_TABS.configurations || !queryTab;

  return (
    <GlobalErrorBoundaries>
      <Card
        tabProps={{ size: "small" }}
        activeTabKey={queryTab || NODE_CHANEL_DETAIL_VIEW_TABS.configurations}
        tabList={tabList}
        onTabChange={openTabs}
      >
        {isV4Node && isDetailView && <NodeChannelFormV4 channelConfig={channelConfig} id={id} detailView editMode />}
        {isV5Node && isDetailView && <NodeChannelFormV5 channelConfig={channelConfig} id={id} detailView editMode />}
        {queryTab === NODE_CHANEL_DETAIL_VIEW_TABS.logs && (
          <>
            {isV5Node && <LogsV5 channelId={id} channelDetailView />}
            {isV4Node && <Logs channelId={id} channelDetailView />}
          </>
        )}
        {queryTab === NODE_CHANEL_DETAIL_VIEW_TABS.statistics && <NodeStatisticsContainer channelDetailView />}
      </Card>
    </GlobalErrorBoundaries>
  );
};

NodeChannelDetailViewContainer.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      cnn: PropTypes.string.isRequired,
      cwid: PropTypes.string.isRequired,
      online: PropTypes.string,
    })
  ).isRequired,
  MQTTConnection: PropTypes.bool,
  MQTTStatus: PropTypes.string,
};

NodeChannelDetailViewContainer.defaultProps = {
  MQTTConnection: null,
  MQTTStatus: null,
};

const mapStateToProps = (state) => ({
  nodes: MQTT_SELECTORS.getNodes(state),
  MQTTConnection: MQTT_SELECTORS.getMqttConnection(state),
  MQTTStatus: MQTT_SELECTORS.getStatus(state),
});

export default connect(mapStateToProps, null)(NodeChannelDetailViewContainer);
