import React from "react";
import { Input, Form } from "antd";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";

const { TextArea } = Input;
const { Item } = Form;

const translations = defineMessages({
  placeholder: {
    id: "VirtualChannelDescription.placeholder",
    defaultMessage: "Please input description",
  },
});

const VirtualChannelDescription = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <StyledLabel>
        <FormattedMessage id="VirtualChannelDescription.description" defaultMessage="Description" />
      </StyledLabel>
      :
      <StyledItem name="description">
        <TextArea rows={19} placeholder={formatMessage(translations.placeholder)} showCount maxLength={500} />
      </StyledItem>
    </>
  );
};

const StyledItem = styled(Item)`
  .ant-col-sm-18 {
    max-width: 100%;
  }
`;

const StyledLabel = styled.span`
  margin-right: 2px;
`;

export default VirtualChannelDescription;
