import React from "react";
import { Col, Badge, Card } from "antd";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { getOutputAudioIncomplete } from "../../../../../../ducks/nodeChannels";

import themeColor from "../../../../../../lib/style/theme";
import IncompleteConfig from "../../../../../IncompleteConfig";

import AudioCodec from "./AudioCodec";

const { Ribbon } = Badge;

export default function AudioStreamsElement({ disabled, prefix, index }) {
  const { id: channelId } = useParams();
  const incomplete = useSelector((state) =>
    getOutputAudioIncomplete(state, {
      channelId,
      outputIndex: prefix[1],
      audioStreamIndex: prefix[3],
    })
  );

  const isIncomplete = incomplete && incomplete.length > 0;

  return (
    <Col span={24}>
      <Ribbon
        text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
        color={isIncomplete ? themeColor.orange : themeColor.green}
      >
        <Card
          title={<FormattedMessage id="AudioStreamsElement.audioStream" defaultMessage="Audio stream" />}
          // extra={
          //   !disabled && (
          //     <RemoveButton
          //       handleRemove={(fieldName) => {
          //         remove(fieldName);
          //         setIsAudioStreams(false);
          //       }}
          //       fieldName={field.name}
          //       confirmRemoveText={
          //         <FormattedMessage
          //           id="AudioStreams.removeConfirmation"
          //           defaultMessage="Are you sure to remove this audio stream?"
          //         />
          //       }
          //     />
          //   )
          // }
        >
          <AudioCodec disabled={disabled} prefix={prefix} fieldPrefix={[index]} />
        </Card>
      </Ribbon>
    </Col>
  );
}

AudioStreamsElement.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
