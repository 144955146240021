import { bindActionCreators } from "redux";
import { defineMessages } from "react-intl";

import store, { history } from "../store";
import { actions as LICENSE_ACTIONS } from "../ducks/license";

import { LicenseAPI } from "../lib/api";
import getMessageFromErrorCode from "../lib/utils/getMessageFromErrorCode";

const translations = defineMessages({
  createSuccess: {
    id: "license.createSuccess",
    defaultMessage: "License added",
  },
  deleteSuccess: {
    id: "license.deleteSuccess",
    defaultMessage: "License successfully removed",
  },
  extendLicenseSuccess: {
    id: "license.extendLicenseSuccess",
    defaultMessage: "License succesfully extended",
  },
  editLicenseSuccess: {
    id: "license.editLicenseSuccess",
    defaultMessage: "License succesfully updated",
  },
});

const actions = bindActionCreators(
  {
    createNewLicense:
      (data, { successNotification, errorNotification }) =>
      async () => {
        try {
          await LicenseAPI.createLicense({ ...data }, { errorNotification });
          successNotification(translations.createSuccess);
          history.go(-1);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    addNewPlayoutLicense:
      (data, { successNotification, errorNotification }) =>
      async () => {
        try {
          store.dispatch(LICENSE_ACTIONS.SET_LOADING_LICENSES(true));
          const response = await LicenseAPI.addNewPlayoutLicense(data, { errorNotification });
          store.dispatch(LICENSE_ACTIONS.SAVE_PLAYOUT_LICENSE(response?.list));
          successNotification(translations.createSuccess);
        } catch (error) {
          store.dispatch(LICENSE_ACTIONS.SET_LOADING_LICENSES(false));
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    extendLicense:
      (data, { successNotification, errorNotification }) =>
      async () => {
        try {
          await LicenseAPI.extendLicense({ ...data }, { errorNotification });
          successNotification(translations.extendLicenseSuccess);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },

    editLicense:
      (data, { successNotification, errorNotification }) =>
      async () => {
        try {
          await LicenseAPI.editLicense(data, { errorNotification });
          successNotification(translations.editLicenseSuccess);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },

    getLicenseList:
      ({ errorNotification }) =>
      async () => {
        try {
          const licenseList = await LicenseAPI.getLicense({ errorNotification });
          store.dispatch(LICENSE_ACTIONS.SAVE_LICENSE(licenseList));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    getPlayoutLicenseList:
      ({ errorNotification }) =>
      async () => {
        try {
          store.dispatch(LICENSE_ACTIONS.SET_LOADING_LICENSES(true));
          const playoutLicenses = await LicenseAPI.getPlayoutLicense({ errorNotification });
          store.dispatch(LICENSE_ACTIONS.SAVE_PLAYOUT_LICENSE(playoutLicenses?.list));
        } catch (error) {
          store.dispatch(LICENSE_ACTIONS.SET_LOADING_LICENSES(false));
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    getUserCloudLicenseUsage:
      ({ errorNotification }) =>
      async () => {
        try {
          const cloudLicenseUsage = await LicenseAPI.getUserCloudLicenseUsage({ errorNotification });

          return cloudLicenseUsage;
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);

          return null;
        }
      },
    getCloudLicensesList:
      ({ errorNotification }) =>
      async () => {
        try {
          const cloudLicensesList = await LicenseAPI.getCloudLicenses({ errorNotification });

          store.dispatch(LICENSE_ACTIONS.SAVE_CLOUD_LICENSES(cloudLicensesList));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },

    getCloudUserLicensesList:
      ({ errorNotification, username }) =>
      async () => {
        try {
          const cloudUserLicensesList = await LicenseAPI.getCloudUserLicenses({ errorNotification, username });

          store.dispatch(LICENSE_ACTIONS.SAVE_CLOUD_USER_LICENSES(cloudUserLicensesList));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    uniqLicense:
      (licenseId, { errorNotification }) =>
      async () => {
        try {
          return await LicenseAPI.uniqLicense(licenseId, { errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);

          return { uniqLicense: false };
        }
      },
    playoutUniqLicense:
      (licenseId, { errorNotification }) =>
      async () => {
        try {
          return await LicenseAPI.playoutUniqLicense(licenseId, { errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);

          return { uniqLicense: false };
        }
      },
    deleteLicense: (licenseId, { errorNotification, successNotification }) => {
      return async () => {
        try {
          await LicenseAPI.deleteLicense(licenseId, { errorNotification });
          successNotification(translations.deleteSuccess);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
    playoutDeleteLicense: (licenseId, { errorNotification, successNotification }) => {
      return async () => {
        try {
          const response = await LicenseAPI.playoutDeleteLicense(licenseId, { errorNotification });
          store.dispatch(LICENSE_ACTIONS.SAVE_PLAYOUT_LICENSE(response?.list));
          successNotification(translations.deleteSuccess);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      };
    },
  },

  store.dispatch
);

export default actions;
