import React from "react";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";
import { Input, Switch, Row, Select, Form, Col } from "antd";

import { required, min10characters, max79characters } from "../../../lib/utils/formValidation";
import globalTranslations from "../../../lib/translations";
import { OUTPUTS_TYPE, VIRTUAL_CHANNEL_PERMISSION } from "../../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;
const { Password } = Input;

const translation = defineMessages({
  passphrase: {
    id: "PassphraseField.passphrase",
    defaultMessage: "Channel encryption",
  },
  keyLengthLabel: {
    id: "PassphraseField.keyLengthLabel",
    defaultMessage: "Key length",
  },
  keyLabel: {
    id: "PassphraseField.keyLabel",
    defaultMessage: "Key",
  },
});

const PassphraseField = ({ disabled, prefix, type, hidden, form }) => {
  const { formatMessage } = useIntl();
  const isEncrypted = Form.useWatch([...prefix, "encryptionEnabled"], form);
  const isPermission = type === VIRTUAL_CHANNEL_PERMISSION;

  const { setFieldsValue, getFieldValue } = form;

  const handleChangeEncryptedSwitch = (value) => {
    const formData = isPermission ? getFieldValue("users") : getFieldValue([prefix[0]]);

    switch (type) {
      case VIRTUAL_CHANNEL_PERMISSION: {
        if (value) {
          formData[prefix[0]].encryptionEnabled = value;
          formData[prefix[0]].encryptionKeyLen = 16;
        } else {
          formData[prefix[0]].encryptionEnabled = value;
          delete formData[prefix[0]].encryptionKeyLen;
          delete formData[prefix[0]].encryptionPassword;
        }

        setFieldsValue({ users: formData });
        break;
      }
      case OUTPUTS_TYPE.nonMuxedOutputs: {
        if (value) {
          formData[prefix[1]].encryptionEnabled = value;
          formData[prefix[1]].encryptionKeyLen = 16;
        } else {
          formData[prefix[1]].encryptionEnabled = value;
          delete formData[prefix[1]].encryptionKeyLen;
          delete formData[prefix[1]].encryptionPassword;
        }
        break;
      }
      case OUTPUTS_TYPE.muxedOutputs: {
        if (value) {
          formData[prefix[1]].outputs[prefix[3]].encryptionEnabled = value;
          formData[prefix[1]].outputs[prefix[3]].encryptionKeyLen = 16;
        } else {
          formData[prefix[1]].outputs[prefix[3]].encryptionEnabled = value;
          delete formData[prefix[1]].outputs[prefix[3]].encryptionKeyLen;
          delete formData[prefix[1]].outputs[prefix[3]].encryptionPassword;
        }
        break;
      }
      case "outputs": {
        if (value) {
          formData[prefix[1]].urlDestinations[prefix[3]].encryptionEnabled = value;
          formData[prefix[1]].urlDestinations[prefix[3]].encryptionKeyLen = 16;
        } else {
          formData[prefix[1]].urlDestinations[prefix[3]].encryptionEnabled = value;
          delete formData[prefix[1]].urlDestinations[prefix[3]].encryptionKeyLen;
          delete formData[prefix[1]].urlDestinations[prefix[3]].encryptionPassword;
        }
        break;
      }
      default:
        if (value) {
          formData[prefix[1]].encryptionEnabled = value;
          formData[prefix[1]].encryptionKeyLen = 16;
        } else {
          formData[prefix[1]].encryptionEnabled = value;
          delete formData[prefix[1]].encryptionKeyLen;
          delete formData[prefix[1]].encryptionPassword;
        }
        break;
    }

    setFieldsValue({ [prefix[0]]: formData });
  };

  return (
    <Row justify="space-between" gutter={24}>
      <Col md={24} lg={8}>
        <Item
          hidden={hidden}
          name={[...prefix, "encryptionEnabled"]}
          label={formatMessage(translation.passphrase)}
          valuePropName="checked"
        >
          <Switch
            onChange={handleChangeEncryptedSwitch}
            defaultChecked={isEncrypted}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
            disabled={disabled}
          />
        </Item>
      </Col>
      {isEncrypted && (
        <>
          <Col md={isPermission ? 24 : 8} lg={isPermission ? 24 : 8}>
            <Item
              name={[...prefix, "encryptionPassword"]}
              label={formatMessage(translation.keyLabel)}
              rules={[required, min10characters, max79characters]}
            >
              <Password type="password" disabled={disabled} />
            </Item>
          </Col>
          <Col md={isPermission ? 24 : 8} lg={isPermission ? 24 : 8}>
            <Item name={[...prefix, "encryptionKeyLen"]} label={formatMessage(translation.keyLengthLabel)}>
              <Select disabled={disabled}>
                <Option key="16" value={16}>
                  AES-128
                </Option>
                <Option key="24" value={24}>
                  AES-192
                </Option>
                <Option key="32" value={32}>
                  AES-256
                </Option>
              </Select>
            </Item>
          </Col>
        </>
      )}
    </Row>
  );
};

PassphraseField.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

PassphraseField.defaultProps = {
  disabled: null,
  type: null,
  hidden: false,
};

export default PassphraseField;
