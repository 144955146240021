import React, { useContext } from "react";
import { Col, Card, Row, Form } from "antd";
import { useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import { PLAYOUT_OUTPUT_TYPES } from "../../../../../lib/utils/constants";
import RemoveButton from "../../../../RemoveButton";
import SelectPlayoutOutputType from "../SelectPlayoutOutputType";
import PlayoutOutputURLFormItem from "../../../../PlayoutOutputURLFormItem";
import PlayoutOutputSDIFormItem from "../../../../PlayoutOutputSDIFormItem";

import { FormV5Context } from "../../..";

const translations = defineMessages({
  confirmRemoveText: {
    id: "EnginePlayoutFormItem.confirmRemove",
    defaultMessage: "Are you sure, you want to remove this output?",
  },
});

export default function EnginePlayoutFormItem({
  prefix,
  detailView,
  handleFinish,
  handleRemove,
  id,
  isNewOutput,
  outputData,
}) {
  const form = useContext(FormV5Context);
  const { formatMessage } = useIntl();
  const selectedOutputType = Form.useWatch([...prefix, "type"], form);
  const isOutputUrlType = selectedOutputType === PLAYOUT_OUTPUT_TYPES.playoutUrl.value;
  const isOutputSDIType = selectedOutputType === PLAYOUT_OUTPUT_TYPES.playoutDecklink.value;

  return (
    <StyledCard
      title={outputData?.name}
      extra={
        outputData?.name &&
        !detailView && (
          <RemoveButton
            handleRemove={handleRemove}
            fieldName={outputData.name}
            confirmRemoveText={formatMessage(translations.confirmRemoveText)}
          />
        )
      }
    >
      <Row type="flex" justify="start" gutter={[24, 24]}>
        <Col span={8}>
          <SelectPlayoutOutputType prefix={prefix} disabled={detailView} />
        </Col>
        {isOutputSDIType && (
          <PlayoutOutputSDIFormItem
            detailView={detailView}
            prefix={prefix}
            form={form}
            channelId={id}
            initialOutputName={outputData?.name}
            outputData={outputData}
          />
        )}
        {isOutputUrlType && (
          <PlayoutOutputURLFormItem
            form={form}
            prefix={prefix}
            outputData={outputData}
            isNewOutput={isNewOutput}
            handleFinish={handleFinish}
            channelId={id}
            detailView={detailView}
          />
        )}
      </Row>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  margin-top: 10px;
`;

EnginePlayoutFormItem.propTypes = {
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  id: PropTypes.string,
  isNewOutput: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

EnginePlayoutFormItem.defaultProps = {
  detailView: null,
  id: null,
};
