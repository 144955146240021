import React from "react";
import { Affix, Button } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { CaretLeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";

import { selectors as CHANNEL_SELECTORS } from "../../ducks/nodeChannels";

const BackButton = ({ channelId, detailView }) => {
  const isUpdatingChannel = useSelector(CHANNEL_SELECTORS.getLoadingChannels);
  const { cwid, cnn } = useParams();
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (detailView) {
      navigate(`/node/${cwid}/${cnn}/channels`);
    }

    if (!detailView) {
      navigate(`/node/${cwid}/${cnn}/channel/${channelId}`);
    }
  };

  return (
    <Affix offsetBottom={0}>
      <StyledButton
        loading={isUpdatingChannel}
        disabled={isUpdatingChannel}
        icon={<CaretLeftOutlined />}
        type="primary"
        onClick={handleBackButton}
      >
        <span>
          {detailView && <FormattedMessage id="BackButton.channelsList" defaultMessage="Channels list" />}
          {!detailView && <FormattedMessage id="BackButton.channelDetail" defaultMessage="Channel detail" />}
        </span>
      </StyledButton>
    </Affix>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

BackButton.propTypes = {
  channelId: PropTypes.string.isRequired,
  detailView: PropTypes.bool,
};

BackButton.defaultProps = {
  detailView: null,
};

export default BackButton;
