import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";
import { createSelector } from "reselect";

import { MQTT_STATUS } from "../lib/utils/constants";

export const actions = {
  CLEAR_NODE_CONNECTION: createAction("NODE/CLEAR_NODE_CONNECTION"),
  SET_NETWORKINTERFACES: createAction("NODE/NETWORKINTERFACES"),
  SET_NODE_CAPABILITIES: createAction("NODE/SET_NODE_CAPABILITIES"),
  SET_NODE_CNN: createAction("NODE/SET_NODE_CNN"),
  SET_NODE_CONNECTION: createAction("NODE/SET_NODE_CONNECTION"),
  SET_NODE_FINGERPRINT: createAction("NODE/SET_NODE_FINGERPRINT"),
  SET_NODE_LICENSE_LIST: createAction("NODE/SET_NODE_LICENSE_LIST"),
  SET_NODE_START: createAction("NODE/SET_NODE_START"),
  SET_NODE_VERSION: createAction("NODE/SET_NODE_VERSION"),
  SET_SHORT_FINGERPRINT: createAction("NODE/SET_SHORT_FINGERPRINT"),
  SET_STUND_ADDRESS: createAction("NODE/SET_STUND_ADDRESS"),
  SET_NODE_NDIS: createAction("NODE/SET_NODE_NDIS"),
  SET_NODE_PORTS: createAction("NODE/SET_NODE_PORTS"),
  SET_NODE_DRAWER: createAction("NODE/SET_NODE_DRAWER"),
  SET_NODE_EMERGENCY_FILE_LIST: createAction("NODE/SET_NODE_EMERGENCY_FILE_LIST"),
  SET_NODE_CONFIG: createAction("NODE/SET_NODE_CONFIG"),
};

const defaultState = new Map({
  capabilities: null,
  cnn: null,
  cwid: null,
  fingerprint: "",
  licenseList: {},
  meta: null,
  networkInterfaces: [],
  networkInterfacesFrom: null,
  nodeVersion: "",
  status: MQTT_STATUS.DISCONNECTED,
  stundAddress: null,
  ndis: [],
  ports: { list: [], used: [] },
  openPortsDrawer: false,
  emergencyFilesList: [],
  config: {},
});

const reducer = handleActions(
  {
    [actions.SET_NODE_CONNECTION]: (state, { payload }) =>
      state.merge({ ...defaultState, ...payload, status: MQTT_STATUS.CONNECTED }),
    [actions.SET_NODE_START]: (state) => state.merge({ status: MQTT_STATUS.CONNECTED }),
    [actions.SET_NODE_FINGERPRINT]: (state, { payload }) => state.merge({ fingerprint: payload }),
    [actions.SET_NODE_CONFIG]: (state, { payload }) => state.merge({ config: payload }),
    [actions.SET_SHORT_FINGERPRINT]: (state, { payload }) => state.merge({ shortFingerprint: payload }),
    [actions.SET_NODE_CNN]: (state, { payload }) => state.merge({ cnn: payload }),
    [actions.SET_NETWORKINTERFACES]: (state, { payload }) =>
      state.merge({ networkInterfaces: payload?.interfaces, networkInterfacesFrom: payload?.from }),
    [actions.CLEAR_NODE_CONNECTION]: () => defaultState,
    [actions.SET_STUND_ADDRESS]: (state, { payload }) => state.merge({ stundAddress: payload }),
    [actions.SET_NODE_VERSION]: (state, { payload }) => state.merge({ nodeVersion: payload }),
    [actions.SET_NODE_CAPABILITIES]: (state, { payload }) => state.merge({ capabilities: payload }),
    [actions.SET_NODE_NDIS]: (state, { payload }) => state.merge({ ndis: payload }),
    [actions.SET_NODE_DRAWER]: (state, { payload }) => state.merge({ openPortsDrawer: payload }),
    [actions.SET_NODE_PORTS]: (state, { payload }) => {
      const parsedPorts = payload
        ? payload.map((port) => ({ ...port, id: `${port.port}-${port.path.join("-")}` }))
        : [];
      const usedPorts = payload ? [...payload.map((nodePort) => nodePort.port)] : [];

      return state.merge({ ports: { list: parsedPorts, used: usedPorts } });
    },
    [actions.SET_NODE_EMERGENCY_FILE_LIST]: (state, { payload }) => state.merge({ emergencyFilesList: payload }),
    [actions.SET_NODE_LICENSE_LIST]: (state, { payload }) => {
      const cwid = payload.from.split("/")[1];
      const oldNodeLicensesList = state.get("licenseList");

      return state.merge({ licenseList: { ...oldNodeLicensesList, [cwid]: payload.list } });
    },
  },
  defaultState
);

export const selectors = {
  getNodeCwid: (state) => state.getIn(["node", "cwid"]),
  getStatus: (state) => state.getIn(["node", "status"]),
  getFingerprint: (state) => state.getIn(["node", "fingerprint"]),
  getShortFingerprint: (state) => state.getIn(["node", "shortFingerprint"]),
  getCnn: (state) => state.getIn(["node", "cnn"]),
  getNetworkInterfaces: (state) => state.getIn(["node", "networkInterfaces"]),
  getNetworkInterfacesFrom: (state) => state.getIn(["node", "networkInterfacesFrom"]),
  getStundAddress: (state) => state.getIn(["node", "stundAddress"]),
  getIsStundAddress: (state) => typeof state.getIn(["node", "stundAddress"]) === "string",
  getNodeVersion: (state) => state.getIn(["node", "nodeVersion"]),
  getNodeOS: (state) => state.getIn(["node", "nodeOS"]),
  getWrapperPublicKey: (state) => state.getIn(["node", "wrapperPublicKey"]),
  getNodeLicenses: (state) => state.getIn(["node", "licenseList"]),
  getPlayoutCapabilities: (state) => state.getIn(["node", "capabilities", "playoutApis"]),
  getNodeCapabilities: (state) => state.getIn(["node", "capabilities"]),
  getNodeOutputCapabilities: (state) => state.getIn(["node", "capabilities", "outputTypes"]),
  getNodeAdditionalUrlOutputs: (state) => state.getIn(["node", "capabilities", "additionalUrlOutputs"]),
  getNodeNdis: (state) => state.getIn(["node", "ndis"]),
  getNodePortsList: (state) => state.getIn(["node", "ports", "list"]),
  getNodeUsedPorts: (state) => state.getIn(["node", "ports", "used"]),
  getNodeOpenPortsDrawer: (state) => state.getIn(["node", "openPortsDrawer"]),
  getEmergencyFilesList: (state) => state.getIn(["node", "emergencyFilesList"]),
  getNodeInputSwitchTooltip: (state) => state.getIn(["node", "config", "changeInputSwitchConfirmation"]),
  getNodeInputSwitchTooltipValue: (state) =>
    state.getIn(["node", "config", "changeInputSwitchConfirmation"]) === "true",
  getIsNodeInputSwitchTooltipDisabled: (state) =>
    state.getIn(["node", "config", "changeInputSwitchConfirmation"]) === "false",
};

export const getNodeNdisSelector = createSelector([selectors.getNodeNdis], (ndis) => {
  const parsedNdis = ndis.map((ndi) => ({
    label: ndi?.streamSource,
    value: ndi?.streamSource,
    streamAddr: ndi?.streamAddr,
  }));

  return parsedNdis;
});

export default reducer;
