import React from "react";
import { Card, Divider, Tooltip, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectors as STATISTIC_NODE_SELECTORS } from "ducks/nodeSystemStatistics";

import themeColors from "lib/style/theme";

export default function NodeCloudLicenseUsage() {
  const { cwid } = useParams();
  const { outputsCount, codecX264 } = useSelector((state) =>
    STATISTIC_NODE_SELECTORS.getCurrentNodeLicenseUsage(state, cwid)
  );

  return (
    <Card
      title={
        <FormattedMessage
          id="NodeCloudLicenseUsage.currentNodeLicensesUsage"
          defaultMessage="Current node licenses usage"
        />
      }
    >
      <StyledUsageTitle>
        <Tooltip
          placement="topLeft"
          title={
            <FormattedMessage
              id="NodeCloudLicenseUsage.outputsTooltip"
              defaultMessage="Number of outputs used in running channels on your nodes"
            />
          }
        >
          <FormattedMessage id="LicensesListCloud.outputs" defaultMessage="Outputs" /> <QuestionCircleFilled />
        </Tooltip>
        {" : "}
        <StyledUsage>{!Number.isNaN(Number(outputsCount)) ? outputsCount : <Spin size="small" />}</StyledUsage>
      </StyledUsageTitle>
      <Divider type="vertical" />
      <StyledUsageTitle>
        <Tooltip
          placement="topLeft"
          title={
            <FormattedMessage
              id="NodeCloudLicenseUsage.codecH264Tooltip"
              defaultMessage="Number of encoded H264 outputs used in running channels on your nodes"
            />
          }
        >
          <FormattedMessage id="LicensesListCloud.codecH264" defaultMessage="H264 Codec" /> <QuestionCircleFilled />
        </Tooltip>
        {" : "}
        <StyledUsage>{!Number.isNaN(Number(codecX264)) ? codecX264 : <Spin size="small" />}</StyledUsage>
      </StyledUsageTitle>
    </Card>
  );
}

const StyledUsageTitle = styled.span`
  font-size: 15px;

  .anticon {
    font-size: 14px;
  }
`;

const StyledUsage = styled.span`
  color: ${themeColors.maritime};

  .ant-spin-dot-item {
    background-color: ${themeColors.maritime};
  }
`;
