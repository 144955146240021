import React from "react";
import { Form, Select } from "antd";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { selectors as NODE_SELECTORS } from "../../ducks/node";

import globalTranslations from "../../lib/translations";
import {
  OUTPUTS_TYPES,
  SELECT_DEFAULT_AUTO,
  SELECT_STREAM_CODEC_FPS,
  OUTPUT_NDI_PIXEL_FORMATS,
  SELECT_STREAM_CODEC_RESIZE,
} from "../../lib/utils/constants";
import { required } from "../../lib/utils/formValidation";
import DEFAULT_MUXED_OUTPUT, { DEFAULT_OUTPUT_RTMP } from "../../lib/utils/defaultValues/defaultMuxedOutput";

const { Item } = Form;
const { Option } = Select;

const SelectOutputType = ({ prefix, form, disabled }) => {
  const { formatMessage } = useIntl();
  const outputCapabilities = useSelector(NODE_SELECTORS.getNodeOutputCapabilities) || [];

  const OUTPUTS_TYPES_ARRAY = Object.values(OUTPUTS_TYPES);

  const checkIfOptionDisabled = (optionValue) => {
    if (outputCapabilities && !outputCapabilities.includes(optionValue)) {
      return true;
    }

    return false;
  };

  const handleChangeOutputType = (selectedType) => {
    const { getFieldValue, setFieldsValue } = form;
    const formMuxedOutputs = getFieldValue("muxedOutputs") || [];
    const formMuxedOutput = getFieldValue(prefix);

    const selectedOutputUrlType = selectedType === OUTPUTS_TYPES.outputUrl.value;
    const selectedOutputNdiType = selectedType === OUTPUTS_TYPES.outputNdi.value;
    const selectedOutputRtmpType = selectedType === OUTPUTS_TYPES.outputRtmp.value;

    if (selectedOutputRtmpType) {
      const parsedFormMuxedOutputs = formMuxedOutputs.map((muxedOutput) => {
        if (muxedOutput?.name === formMuxedOutput?.name) {
          const numberOfOutputs = formMuxedOutputs.length + 1;

          return {
            ...DEFAULT_OUTPUT_RTMP,
            name: muxedOutput?.name ? muxedOutput.name : `Muxed-output-${numberOfOutputs}`,
          };
        }

        return muxedOutput;
      });

      setFieldsValue({ muxedOutputs: parsedFormMuxedOutputs });
    }

    if (selectedOutputUrlType) {
      const parsedFormMuxedOutputs = formMuxedOutputs.map((muxedOutput) => {
        if (muxedOutput?.name === formMuxedOutput?.name) {
          const numberOfOutputs = formMuxedOutputs.length + 1;

          return {
            ...DEFAULT_MUXED_OUTPUT,
            type: selectedType,
            name: muxedOutput?.name ? muxedOutput.name : `Muxed-output-${numberOfOutputs}`,
          };
        }

        return muxedOutput;
      });

      setFieldsValue({ muxedOutputs: parsedFormMuxedOutputs });
    }

    if (selectedOutputNdiType) {
      const parsedFormMuxedOutputs = formMuxedOutputs.map((muxedOutput) => {
        if (muxedOutput?.name === formMuxedOutput?.name) {
          const numberOfOutputs = formMuxedOutputs.length + 1;

          return {
            type: OUTPUTS_TYPES.outputNdi.value,
            name: muxedOutput?.name ? muxedOutput.name : `Muxed-output-${numberOfOutputs}`,
            streamName: `Stream-${numberOfOutputs}`,
            videoStream: {
              streamId: SELECT_DEFAULT_AUTO.value,
            },
            audioStream: {
              streamId: SELECT_DEFAULT_AUTO.value,
            },
            videoFormat: {
              fps: SELECT_STREAM_CODEC_FPS[0].value,
              interlaced: false,
              pixFormat: OUTPUT_NDI_PIXEL_FORMATS[0],
              resize: SELECT_STREAM_CODEC_RESIZE[0].value,
            },
            audioFormat: {
              sampleFormat: SELECT_DEFAULT_AUTO.value,
              sampleRate: SELECT_DEFAULT_AUTO.value,
            },
          };
        }

        return muxedOutput;
      });

      setFieldsValue({ muxedOutputs: parsedFormMuxedOutputs });
    }
  };

  return (
    <Item name={[...prefix, "type"]} label={formatMessage(globalTranslations.type)} rules={[required]}>
      <Select onChange={handleChangeOutputType} disabled={disabled}>
        {OUTPUTS_TYPES_ARRAY.map((option) => (
          <Option key={option.value} value={option.value} disabled={checkIfOptionDisabled(option.value)}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

SelectOutputType.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

SelectOutputType.defaultProps = {
  disabled: null,
};

export default SelectOutputType;
