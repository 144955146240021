import React from "react";
import { Button as ButtonAntd } from "antd";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { switchProp } from "styled-tools";

import themeColor from "../../lib/style/theme";

const Button = ({ children, onClick, ...rest }) => (
  <ButtonAntd onClick={onClick} {...rest}>
    {children}
  </ButtonAntd>
);

const StyledButton = styled(Button)`
  ${switchProp("button-color", {
    green: css`
      background-color: ${themeColor.greenHover};
      border-color: ${themeColor.greenHover};
    `,
  })}

  &:hover {
    ${switchProp("button-color", {
      green: css`
        background-color: ${themeColor.greenHover};
        border-color: ${themeColor.greenHover};
      `,
    })}
  }
`;

Button.propTypes = {
  children: PropTypes.element.isRequired,
  "button-color": PropTypes.string,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

Button.defaultProps = {
  "button-color": null,
  type: "primary",
};

export default StyledButton;
