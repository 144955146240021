/* eslint-disable consistent-return */
import { bindActionCreators } from "redux";
import { defineMessages } from "react-intl";
import store from "../store";

import MQTTService from "./mqtt";

import { PlatformAPI } from "../lib/api";
import getMessageFromErrorCode from "../lib/utils/getMessageFromErrorCode";
import { MESSAGE_TYPE } from "../lib/utils/constants";

import { actions as MQTT_ACTIONS } from "../ducks/mqtt";

const translations = defineMessages({
  editNodeHostnameSuccess: {
    id: "cloudService.editNodeHostnameSuccess",
    defaultMessage: "Hostname for node was successfully updated",
  },
});

const actions = bindActionCreators(
  {
    editNodeHostname:
      (data, { errorNotification, successNotification }) =>
      async () => {
        try {
          MQTTService.sendMessage({
            topic: `node/${data?.cwid}/in`,
            message: {
              msgType: MESSAGE_TYPE.SET_HOSTNAME,
              hostname: data?.cnn,
            },
          });

          successNotification(translations.editNodeHostnameSuccess);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    changeCloudNodeStatus:
      (data, { errorNotification, setLoading }) =>
      async () => {
        try {
          setLoading(true);
          await PlatformAPI.changeCloudNodeStatus(data, { errorNotification });

          return true;
        } catch (error) {
          setLoading(false);
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    removeNodeShareForUser:
      (data, { errorNotification }) =>
      async () => {
        try {
          await PlatformAPI.removeNodeShareForUser(data, { errorNotification });

          return true;
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    claimNode:
      (data, { errorNotification }) =>
      async () => {
        try {
          await PlatformAPI.claimNode(data, { errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    newCloudNode:
      (data, { errorNotification }) =>
      async () => {
        try {
          await PlatformAPI.newCloudNode(data, { errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    getNodeShares:
      (cwid, { errorNotification }) =>
      async () => {
        try {
          return await PlatformAPI.getNodeShares(cwid, { errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    getUsersCloudNodes:
      ({ errorNotification }) =>
      async () => {
        try {
          return await PlatformAPI.getUsersCloudNodes({ errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    getCloudNodesNetworkUsage:
      (data, { errorNotification }) =>
      async () => {
        try {
          const response = await PlatformAPI.getCloudNodesNetworkUsage(data, { errorNotification });
          const { nodesTotalNetworkOutput, data: responseData } = response;

          store.dispatch(MQTT_ACTIONS.NODE_UPDATE_TOTAL_NETWORK_OUTPUT(nodesTotalNetworkOutput));
          store.dispatch(MQTT_ACTIONS.NODE_UPDATE_NETWORK_OUTPUT(responseData));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    getUserCloudNodes:
      (data, { errorNotification }) =>
      async () => {
        try {
          return await PlatformAPI.getUserCloudNodes(data, { errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    removeNodeShare:
      (data, { errorNotification }) =>
      async () => {
        try {
          await PlatformAPI.removeNodeShare(data, { errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    connectNodeToCloud:
      (data, { errorNotification }) =>
      async () => {
        try {
          await PlatformAPI.addNewCWID(data, { errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
  },
  store.dispatch
);

export default actions;
