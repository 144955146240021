import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";
import { createSelector } from "reselect";

export const SET_SYSTEM_STATS = createAction("STATISTICS/NODE/SET_SYSTEM_STATS");
export const CLEAR_ALL_STATISTIC_NODE = createAction("STATISTICS/NODE/CLEAR_ALL_STATISTIC_NODE");

const ONE_DAY = 8640;

const defaultState = new Map({
  data: {},
  interfaces: {},
});
const reducer = handleActions(
  {
    [SET_SYSTEM_STATS]: (
      state,
      { payload: { from, cpuLoad, ramAvailable, ramTotal, time, totalIncoming, totalOutgoing, networkLoads, licenses } }
    ) => {
      const parsedFrom = from.split("/")[1];
      const oldStateData = state.get("data");
      const oldStateDataFrom = oldStateData[parsedFrom] || [];
      const oldStateInterfaces = state.get("interfaces");

      let incoming;
      let outgoing;

      networkLoads.forEach((networkInterface) => {
        const oldIncomingData =
          state.getIn(["interfaces", [parsedFrom], "incoming", [networkInterface.interface]]) || [];
        const oldOutgoingData =
          state.getIn(["interfaces", [parsedFrom], "outgoing", [networkInterface.interface]]) || [];

        if (oldIncomingData.length > ONE_DAY) {
          oldIncomingData.shift();
        }
        if (oldOutgoingData.length > ONE_DAY) {
          oldOutgoingData.shift();
        }
        incoming = {
          ...incoming,
          [networkInterface.interface]: [...oldIncomingData, { value: networkInterface.incoming, time }],
        };
        outgoing = {
          ...outgoing,
          [networkInterface.interface]: [...oldOutgoingData, { value: networkInterface.outgoing, time }],
        };
      });

      return state.merge({
        data: {
          ...oldStateData,
          [parsedFrom]: [
            ...oldStateDataFrom,
            { cpuLoad, ramAvailable, ramTotal, time, totalIncoming, totalOutgoing, networkLoads, licenses },
          ],
        },
        interfaces: { ...oldStateInterfaces, [parsedFrom]: { incoming, outgoing } },
      });
    },
    [CLEAR_ALL_STATISTIC_NODE]: () => {
      return defaultState;
    },
  },
  defaultState
);

export const selectors = {
  getStatisticNode: (state) => state.getIn(["nodeSystemStatistics", "data"]),
  getNodeSystemStats: (state) => {
    const nodeSystemStats = state.getIn(["nodeSystemStatistics", "data"]) || {};

    return nodeSystemStats;
  },
  getStatisticNodeInterfaces: (state) => state.getIn(["nodeSystemStatistics", "interfaces"]),
  getCurrentNodeLicenseUsage: (state, cwid) => {
    const currentNodeLicenseUsage = state.getIn(["nodeSystemStatistics", "data", cwid]) || [];
    if (currentNodeLicenseUsage.length > 0) {
      return (
        currentNodeLicenseUsage[currentNodeLicenseUsage.length - 1] &&
        currentNodeLicenseUsage[currentNodeLicenseUsage.length - 1].licenses
      );
    }

    return {};
  },
  getParams: (state, params) => params,
};

export const getNodeCPUStatsSelector = createSelector(
  [selectors.getNodeSystemStats, selectors.getParams],
  (systemStats, cwid) => {
    const nodeSystemStats = systemStats[cwid];

    const parsedNodeSystemStats =
      nodeSystemStats &&
      nodeSystemStats.map((stat) => ({
        value: stat?.cpuLoad,
        time: stat?.time,
      }));

    return parsedNodeSystemStats;
  }
);

export const getNodeRAMStatsSelector = createSelector(
  [selectors.getNodeSystemStats, selectors.getParams],
  (systemStats, cwid) => {
    const nodeSystemStats = systemStats[cwid];

    const parsedNodeSystemStats =
      nodeSystemStats &&
      nodeSystemStats.map((stat) => ({
        value: Math.round(100 - (stat?.ramAvailable * 100) / stat?.ramTotal),
        time: stat?.time,
      }));

    return parsedNodeSystemStats;
  }
);

export default reducer;
