import React from "react";
import { Form, Select } from "antd";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { selectors as NODE_SELECTORS } from "../../ducks/node";

import globalTranslations from "../../lib/translations";
import {
  OUTPUTS_TYPES,
  SELECT_DEFAULT_AUTO,
  NDI_SELECT_FPS,
  OUTPUT_NDI_PIXEL_FORMATS,
  SELECT_STREAM_CODEC_RESIZE,
  MPEG2VIDEO_PRESETS,
  NDI_AUDIO_SAMPLE_FORMAT,
  GLOBAL_CODEC_AUDIO_SAMPLE_RATE,
} from "../../lib/utils/constants";
import { required } from "../../lib/utils/formValidation";
import DEFAULT_TRANSCODING_OUTPUT, {
  DEFAULT_OUTPUT_RTMP,
  DEFAULT_OUTPUT_SDI,
} from "../../lib/utils/defaultValues/defaultTranscodedOutput";
import useNodeVersion from "../../hooks/useNodeVersion";

const { Item } = Form;
const { Option } = Select;

function SelectTranscodingOutputType({ prefix, form, disabled }) {
  const { getFieldValue, setFieldsValue } = form;
  const { isV4Node, isV5Node } = useNodeVersion();

  const { formatMessage } = useIntl();
  const outputCapabilities = useSelector(NODE_SELECTORS.getNodeOutputCapabilities) || [];

  const OUTPUTS_TYPES_ARRAY = Object.values(OUTPUTS_TYPES);

  const checkIfOptionDisabled = (optionValue) => {
    if (outputCapabilities && !outputCapabilities.includes(optionValue)) {
      return true;
    }

    return false;
  };

  const handleChangeOutputType = (selectedType) => {
    const formOutputs = getFieldValue("outputs") || [];
    const formOutputData = getFieldValue(prefix);

    const selectedOutputUrlType = selectedType === OUTPUTS_TYPES.outputUrl.value;
    const selectedOutputSDIType = selectedType === OUTPUTS_TYPES.outputDecklink.value;
    const selectedOutputNdiType = selectedType === OUTPUTS_TYPES.outputNdi.value;
    const selectedOutputRtmpType = selectedType === OUTPUTS_TYPES.outputRtmp.value;

    if (selectedOutputRtmpType) {
      const parsedFormOutputs = formOutputs.map((output) => {
        if (output?.name === formOutputData?.name) {
          const numberOfOutputs = formOutputs.length + 1;

          return {
            ...DEFAULT_OUTPUT_RTMP,
            outputId: output?.outputId,
            name: output?.name ? output.name : `Output-${numberOfOutputs}`,
          };
        }

        return output;
      });

      setFieldsValue({ outputs: parsedFormOutputs });
    }

    if (selectedOutputUrlType) {
      const parsedFormOutputs = formOutputs.map((outputData) => {
        if (outputData?.name === formOutputData?.name) {
          const numberOfOutputs = formOutputs.length + 1;

          return {
            ...DEFAULT_TRANSCODING_OUTPUT,
            outputId: outputData?.outputId,
            type: selectedType,
            name: outputData?.name ? outputData.name : `Output-${numberOfOutputs}`,
          };
        }

        return outputData;
      });

      setFieldsValue({ outputs: parsedFormOutputs });
    }

    if (selectedOutputSDIType) {
      const parsedFormOutputs = formOutputs.map((outputData) => {
        if (outputData?.name === formOutputData?.name) {
          const numberOfOutputs = formOutputs.length + 1;

          return {
            ...DEFAULT_OUTPUT_SDI,
            outputId: outputData?.outputId,
            name: outputData?.name ? outputData.name : `Output-${numberOfOutputs}`,
          };
        }

        return outputData;
      });

      setFieldsValue({ outputs: parsedFormOutputs });
    }

    if (selectedOutputNdiType) {
      const parsedFormOutputs = formOutputs.map((outputData) => {
        if (outputData?.name === formOutputData?.name) {
          const numberOfOutputs = formOutputs.length + 1;

          return {
            type: OUTPUTS_TYPES.outputNdi.value,
            outputId: outputData?.outputId,
            name: outputData?.name ? outputData.name : `Output-${numberOfOutputs}`,
            streamName: `Stream-${numberOfOutputs}`,
            ...(isV4Node
              ? [
                  {
                    videoStream: {
                      streamId: SELECT_DEFAULT_AUTO.value,
                    },
                    audioStream: {
                      streamId: SELECT_DEFAULT_AUTO.value,
                    },
                  },
                ]
              : []),
            videoFormat: {
              fps: NDI_SELECT_FPS[2].value,
              interlaced: 0,
              pixFormat: OUTPUT_NDI_PIXEL_FORMATS[0],
              resize: isV5Node ? SELECT_STREAM_CODEC_RESIZE[1].value : SELECT_STREAM_CODEC_RESIZE[0].value,
              ...(isV5Node && {
                presetId: 1,
                width: MPEG2VIDEO_PRESETS.preset4K.width,
                height: MPEG2VIDEO_PRESETS.preset4K.height,
                customSar: MPEG2VIDEO_PRESETS.preset4K.customSar,
              }),
            },
            audioFormat: {
              sampleFormat: NDI_AUDIO_SAMPLE_FORMAT.value,
              sampleRate: GLOBAL_CODEC_AUDIO_SAMPLE_RATE[0].value,
              ...(isV5Node && { channels: 2 }),
            },
          };
        }

        return outputData;
      });

      setFieldsValue({ outputs: parsedFormOutputs });
    }
  };

  return (
    <Item name={[...prefix, "type"]} label={formatMessage(globalTranslations.type)} rules={[required]}>
      <Select onChange={handleChangeOutputType} disabled={disabled}>
        {OUTPUTS_TYPES_ARRAY.map((option) => (
          <Option key={option.value} value={option.value} disabled={checkIfOptionDisabled(option.value)}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Item>
  );
}

SelectTranscodingOutputType.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

SelectTranscodingOutputType.defaultProps = {
  disabled: null,
};

export default SelectTranscodingOutputType;
