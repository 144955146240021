import React from "react";
import { Form, Select, Col } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import InputNumberField from "../Fields/InputNumberField";

import { SELECT_STREAM_TYPE } from "../../lib/utils/constants";
import { required } from "../../lib/utils/formValidation";

const { Item } = Form;
const { Option } = Select;

const NDISelectStream = ({ disabled, prefix, form, label }) => {
  const selectedStream = Form.useWatch([...prefix, "streamId"], form);
  const isPIDSelected = selectedStream === SELECT_STREAM_TYPE[0].value;
  const isAutoSelected = selectedStream === SELECT_STREAM_TYPE[1].value;

  return (
    <>
      <Col span={6}>
        <Item name={[...prefix, "streamId"]} label={label} rules={[required]}>
          <Select disabled={disabled}>
            {SELECT_STREAM_TYPE.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={6}>
        {!isAutoSelected && isPIDSelected && (
          <InputNumberField
            disabled={disabled}
            name={[...prefix, "pid"]}
            label={<FormattedMessage id="NDISelectStream.pid" defaultMessage="PID" />}
            rules={[required]}
            min={24}
            max={8121}
          />
        )}
      </Col>
    </>
  );
};

NDISelectStream.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  form: PropTypes.object.isRequired,
};

NDISelectStream.defaultProps = {
  disabled: null,
};

export default NDISelectStream;
