import { PlusOutlined, QuestionCircleFilled, SaveOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, List, Row, Tooltip, Divider } from "antd";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";
import { selectors as ORGANIZATION_SELECTORS } from "../../ducks/organizations";
import OrganizationsServices from "../../services/organizations";

import themeColor from "../../lib/style/theme";
import { errorNotification } from "../../lib/utils/notification";

import useRole from "../../hooks/useRole";

const { Item: FormItem } = Form;

const OrganizationStep2 = ({ setCurrentStep }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { isAdmin } = useRole();
  // eslint-disable-next-line no-use-before-define, no-unused-vars
  const [modalVisible, setModalVisible] = useState(false);

  const organizationId = useSelector(ORGANIZATION_SELECTORS.getOrganizationId);

  const organizationRolePublic = useSelector(ORGANIZATION_SELECTORS.getOrganizationRolePublic);
  const organizationList = useSelector(ORGANIZATION_SELECTORS.getOrganizationsList);
  const organizationPublicList = useSelector(ORGANIZATION_SELECTORS.getOrganizationsPublicList);
  const organizationRoleId = useSelector(ORGANIZATION_SELECTORS.getOrganizationRoleId);
  const accountId = useSelector(ACCOUNT_SELECTORS.getUserId);

  const myOrganization = organizationList.find((organization) => organization.id === organizationId);
  const selectedOrganization = [...organizationList, ...organizationPublicList].find(
    (organization) => organization.id === organizationId
  );

  const handlePreview = useCallback(() => {
    const formData = form.getFieldsValue();

    const { rolePublic, organizationPublic } = formData || {};

    if (typeof organizationPublic !== "undefined" && !!myOrganization?.public !== !!organizationPublic) {
      OrganizationsServices.editOrganization(
        {
          public: organizationPublic,
          organizationId,
        },
        {
          errorNotification: errorNotification(formatMessage),
          setModalVisible,
        }
      );
    }

    OrganizationsServices.updateOrganization(
      { public: rolePublic, organizationId },
      {
        errorNotification: errorNotification(formatMessage),
        afterUpdate: () =>
          window.open(`${process.env.REACT_APP_HUB_URL}/preview/organizations/${selectedOrganization?.name}`),
      }
    );
  }, [errorNotification, formatMessage, selectedOrganization]);

  const onFinish = useCallback(
    ({ rolePublic, organizationPublic }) => {
      OrganizationsServices.updateOrganization(
        { public: rolePublic, organizationId, id: organizationRoleId },
        {
          errorNotification: errorNotification(formatMessage),
          afterUpdate: () => navigate("/qs-hub/organization"),
        }
      );

      if (typeof organizationPublic !== "undefined" && !!myOrganization?.public !== !!organizationPublic) {
        OrganizationsServices.editOrganization(
          {
            public: organizationPublic,
            organizationId,
          },
          {
            errorNotification: errorNotification(formatMessage),
            setModalVisible,
          }
        );
      }
    },
    [errorNotification, formatMessage, organizationRoleId]
  );

  const dataList = [
    <FormattedMessage
      id="OrganizationStep2.benefit1"
      defaultMessage={`Quickstream Hub helps partners to exchange live
      and linear content in a secure and a reliable way`}
    />,
    <FormattedMessage
      id="OrganizationStep2.benefit2"
      defaultMessage="Your contact details are never directly visible in public database"
    />,
    <FormattedMessage
      id="OrganizationStep2.benefit3"
      defaultMessage="New customers and partners can express interest in your organization and directly reach you"
    />,
    <FormattedMessage
      id="OrganizationStep2.benefit4"
      defaultMessage="You will get notifications if someone is interested"
    />,
    <FormattedMessage
      id="OrganizationStep2.benefit5"
      defaultMessage="You will increase revenue and grow your business"
    />,
  ];

  const handleBackButton = useCallback(() => {
    setCurrentStep(1);
  }, []);

  return (
    <Col span={24}>
      <Card title={<FormattedMessage id="OrganizationStep2.quickstreamHub" defaultMessage="Quickstream Hub" />}>
        <Form
          name="organizationStep2"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            rolePublic:
              typeof organizationRolePublic === "undefined" || organizationRolePublic === null
                ? true
                : organizationRolePublic,
            organizationPublic: myOrganization?.public,
          }}
          form={form}
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Col>
                <Card
                  title={
                    <FormattedMessage
                      id="OrganizationStep2.yourRoleOrganization"
                      defaultMessage="Your role in organization"
                    />
                  }
                >
                  <FormItem
                    name="rolePublic"
                    valuePropName="checked"
                    label={
                      <>
                        <StyledLabel>
                          <FormattedMessage id="OrganizationStep2.public" defaultMessage="Public" />
                        </StyledLabel>

                        <Tooltip
                          title={
                            <FormattedMessage
                              id="OrganizationStep2.visibleRoleQSHub"
                              defaultMessage="Do you want your role to be visible in Quickstream hub?"
                            />
                          }
                        >
                          <QuestionCircleFilled />
                        </Tooltip>
                      </>
                    }
                  >
                    <Checkbox />
                  </FormItem>
                </Card>
                <br />
                {(accountId === myOrganization?.creator || isAdmin) && (
                  <Card
                    title={
                      <FormattedMessage id="OrganizationStep2.yourOrganization" defaultMessage="Your Organization" />
                    }
                  >
                    <FormItem
                      name="organizationPublic"
                      valuePropName="checked"
                      label={
                        <>
                          <StyledLabel>
                            <FormattedMessage id="OrganizationStep2.public" defaultMessage="Public" />
                          </StyledLabel>

                          <Tooltip
                            title={
                              <FormattedMessage
                                id="OrganizationFormPublic.visibleOrgQSHub"
                                defaultMessage="Do you want your organization to be visible in Quickstream hub?"
                              />
                            }
                          >
                            <QuestionCircleFilled />
                          </Tooltip>
                        </>
                      }
                    >
                      <Checkbox />
                    </FormItem>
                  </Card>
                )}
              </Col>
            </Col>
            <Col span={12}>
              <Card
                title={
                  <FormattedMessage
                    id="OrganizationStep2.benefitsTitle"
                    defaultMessage="Quickstream Live Exchange benefits"
                  />
                }
              >
                <List
                  dataSource={dataList}
                  renderItem={(item) => (
                    <List.Item>
                      <StyledMeta avatar={<StyledPlusOutlined />} title={item} />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
          <StyledNavigation>
            <Button type="primary" onClick={handleBackButton}>
              <FormattedMessage id="general.back" defaultMessage="Back" />
            </Button>
            <span>
              <Tooltip
                placement="top"
                title={
                  <FormattedMessage
                    id="OrganizationStep2.showPreview"
                    defaultMessage="Show preview (data will be saved)"
                  />
                }
              >
                <Button icon={<SaveOutlined />} onClick={handlePreview}>
                  <span>
                    <FormattedMessage id="general.preview" defaultMessage="Preview" />
                  </span>
                </Button>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip
                placement="top"
                title={<FormattedMessage id="general.finishAdnSave" defaultMessage="Finish (data will be saved)" />}
              >
                <Button htmlType="submit" type="primary" icon={<SaveOutlined />}>
                  <span>
                    <FormattedMessage id="general.finish" defaultMessage="Finish" />
                  </span>
                </Button>
              </Tooltip>
            </span>
          </StyledNavigation>
        </Form>
      </Card>
    </Col>
  );
};

const StyledLabel = styled.span`
  margin-right: 5px;
`;

const StyledMeta = styled(List.Item.Meta)`
  align-items: center;
`;

const StyledPlusOutlined = styled(PlusOutlined)`
  color: ${themeColor.yellowLink};
`;

const StyledNavigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export default OrganizationStep2;
