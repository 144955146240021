import React, { useContext } from "react";
import { Col, Form, Switch, Row, Tooltip } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

import { FormV5Context } from "../../../../../NodeChannelFormV5";
import { required, pidValidation } from "../../../../../../../lib/utils/formValidation";
import globalTranslations from "../../../../../../../lib/translations";

import InputNumberField from "../../../../../../Fields/InputNumberField";
import VideoCodecBitrateField from "../../../../../../VideoCodecBitrateField";
import VideoCodecGOPLengthField from "../../../../../../VideoCodecGOPLengthField";
// import RemoveButton from "../../../../../RemoveButton";

import GlobalVideoCodecFormItem from "../../../../GlobalCodecSection/GlobalCodecVideo/GlobalVideoCodecFormItem";
import AdvancedSettings from "../../../../../../AdvancedSettings";
import InterlacedField from "../../../../../../InterlacedField";

const { Item } = Form;

const translations = defineMessages({
  bitrate: {
    id: "GeneralVideoCodec.bitrate",
    defaultMessage: "Bitrate [kbps]",
  },
  sarNum: {
    id: "GeneralVideoCodec.sarNum",
    defaultMessage: "Video Sample Ratio (sarNum)",
  },
  sarDen: {
    id: "GeneralVideoCodec.sarDen",
    defaultMessage: "Video Sample Ratio (sarDen)",
  },
  gopStructure: {
    id: "GeneralVideoCodec.gopStructure",
    defaultMessage: "B-Frames [frames]",
  },
  automaticAs0Tooltip: {
    id: "GeneralVideoCodec.automaticAs0Tooltip",
    defaultMessage: "Select 0 for automatic",
  },
});

function GeneralVideoCodec({
  disabled,
  disableInterlaced,
  prefix,
  additionalBasicFields,
  additionalAdvancedFields,
  pixelFormat,
}) {
  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldValue } = form;
  const { formatMessage } = useIntl();

  const prefixVideoCodec = [...prefix, "videoCodec"];

  const initialChangeGOP = Form.useWatch([...prefixVideoCodec, "changeGOP"], form) || false;

  const isSwitchChangeGOP = Form.useWatch([...prefixVideoCodec, "changeGOP"], form);
  const isSwitchConvert = Form.useWatch([...prefix, "convert"], form);
  const gValue = Form.useWatch([...prefixVideoCodec, "g"], form);

  const handleSwitchConvert = (value) => {
    const videoStream = getFieldValue(prefix);

    if (value) {
      const defaultVideoFormat = {
        resize: "pad",
        presetId: 1,
        width: 1920,
        height: 1080,
        pixFormat: "YUV420P",
        customSar: false,
        sarDen: 37,
        sarNum: 64,
      };

      setFieldValue([...prefix, "videoFormat"], defaultVideoFormat);
    } else {
      delete videoStream.videoFormat;
      setFieldValue(prefix, videoStream);
    }
  };

  const handleSwitchChangeGOP = (value) => {
    const codec = getFieldValue(prefixVideoCodec);

    if (value) {
      const codecUpdate = {
        changeGOP: value,
        g: 25,
        bf: 0,
      };

      setFieldValue(prefixVideoCodec, { ...codec, ...codecUpdate });
    } else {
      delete codec.g;
      delete codec.bf;
      setFieldValue(prefixVideoCodec, { ...codec, changeGOP: value });
    }
  };

  return (
    <>
      <Col span={12}>
        <VideoCodecBitrateField
          disabled={disabled}
          name={[...prefixVideoCodec, "b"]}
          label={formatMessage(translations.bitrate)}
        />
      </Col>

      <Col span={24}>
        <AdvancedSettings>
          <Row gutter={24}>
            {additionalBasicFields && additionalBasicFields}
            <InputNumberField name={[...prefix, "outputId"]} label="OutputId" disabled={disabled} hidden />
            <Col span={6}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "pid"]}
                label={
                  <>
                    <FormattedMessage id="GeneralVideoCodec.pid" defaultMessage="PID" />
                    <StyledTooltip placement="top" title={formatMessage(translations.automaticAs0Tooltip)}>
                      <QuestionCircleFilled />
                    </StyledTooltip>
                  </>
                }
                rules={[pidValidation]}
                min={0}
                max={8186}
              />
            </Col>

            <Col span={12} style={disableInterlaced ? { visibility: "hidden", display: "none" } : null}>
              <InterlacedField prefix={prefixVideoCodec} disabled={disabled || disableInterlaced} />
            </Col>

            {additionalAdvancedFields && additionalAdvancedFields}
            <Col span={6}>
              <Item
                name={[...prefixVideoCodec, "changeGOP"]}
                valuePropName="checked"
                label={<FormattedMessage id="GeneralVideoCodec.changeGOP" defaultMessage="Custom GoP" />}
              >
                <Switch
                  disabled={disabled}
                  onChange={handleSwitchChangeGOP}
                  defaultChecked={initialChangeGOP}
                  checkedChildren={formatMessage(globalTranslations.on)}
                  unCheckedChildren={formatMessage(globalTranslations.off)}
                />
              </Item>
            </Col>
            {isSwitchChangeGOP && (
              <>
                <Col span={6}>
                  <VideoCodecGOPLengthField
                    disabled={disabled}
                    prefix={prefixVideoCodec}
                    name={[...prefixVideoCodec, "g"]}
                    form={form}
                  />
                </Col>
                <Col span={6}>
                  <InputNumberField
                    disabled={disabled}
                    name={[...prefixVideoCodec, "bf"]}
                    label={formatMessage(translations.gopStructure)}
                    rules={[required]}
                    min={0}
                    max={gValue}
                  />
                </Col>
              </>
            )}

            <Col span={6}>
              <Item
                name={[...prefix, "convert"]}
                valuePropName="checked"
                label={<FormattedMessage id="GeneralVideoCodec.convert" defaultMessage="Convert" />}
              >
                <Switch
                  disabled={disabled}
                  onChange={handleSwitchConvert}
                  defaultChecked={isSwitchConvert}
                  checkedChildren={formatMessage(globalTranslations.on)}
                  unCheckedChildren={formatMessage(globalTranslations.off)}
                />
              </Item>
            </Col>
            {isSwitchConvert && (
              <Col span={24}>
                <GlobalVideoCodecFormItem
                  disabled={disabled}
                  prefix={[...prefix, "videoFormat"]}
                  isVideoFormat
                  pixelFormat={pixelFormat}
                />
              </Col>
            )}
          </Row>
        </AdvancedSettings>
      </Col>
    </>
  );
}

const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;
`;

GeneralVideoCodec.propTypes = {
  additionalBasicFields: PropTypes.object,
  additionalAdvancedFields: PropTypes.object,
  disabled: PropTypes.bool,
  disableInterlaced: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  pixelFormat: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

GeneralVideoCodec.defaultProps = {
  additionalBasicFields: null,
  additionalAdvancedFields: null,
  disabled: null,
  disableInterlaced: null,
};

export default GeneralVideoCodec;
