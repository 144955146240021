export default function cloudNodeInstanceType(instanceType) {
  switch (instanceType) {
    case "trial":
      return "TRIAL";
    case "t3.medium":
      return "MEDIUM";
    case "t3.large":
      return "LARGE";
    case "t3.xlarge":
      return "XLARGE";
    case "t3.2xlarge":
      return "2XLARGE";
    default:
      return "";
  }
}
