import React from "react";
import { Col, Badge, Collapse } from "antd";
import { FormattedMessage } from "react-intl";
import { AlertOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { getNodeAlertsCountSelector, getNodeAlertsSelector } from "../../ducks/nodeAlerts";

import themeColor from "../../lib/style/theme";
import NodesListIcon from "../NodesList/NodesListIcon";
import NodeCardAlertsElement from "./NodeCardAlertsElement";

const { Ribbon } = Badge;

export default function NodeCard({ nodeData }) {
  const numberOfAlerts = useSelector((state) => getNodeAlertsCountSelector(state, { cwid: nodeData?.cwid }));
  const nodeAlerts = useSelector((state) => getNodeAlertsSelector(state, { cwid: nodeData?.cwid }));
  const isAnyNodeAlerts = nodeAlerts && nodeAlerts.length > 0;

  return (
    <Col span={24}>
      <Ribbon
        text={
          <>
            {numberOfAlerts > 0 ? (
              <>
                <AlertOutlined /> {numberOfAlerts}
              </>
            ) : (
              <FormattedMessage id="general.ok" defaultMessage="OK" />
            )}
          </>
        }
        color={numberOfAlerts === 0 ? themeColor.green : themeColor.red}
      >
        <Collapse
          defaultActiveKey={[...(isAnyNodeAlerts ? [nodeData.cwid] : [])]}
          items={[
            {
              key: nodeData.cwid,
              label: (
                <>
                  <NodesListIcon online={nodeData?.online} type={nodeData?.type} /> {nodeData?.cnn}
                </>
              ),
              children: <NodeCardAlertsElement nodeData={nodeData} />,
            },
          ]}
        />
      </Ribbon>
    </Col>
  );
}
