import React, { useContext } from "react";
import { Form, Select, Col, Row, Tooltip } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";

import { FormV5Context } from "../../../../../NodeChannelFormV5";

import {
  SELECT_STREAM_CODEC_AUDIO,
  SELECT_TRANSCODING_CODEC_AUDIO,
  MPEG2AUDIO_BITRATE,
  AAC_PROFILE,
  OUTPUTS_TYPES,
  SELECT_TRANSCODING_RTMP_CODEC_AUDIO,
} from "../../../../../../../lib/utils/constants";
import { required, pidValidation } from "../../../../../../../lib/utils/formValidation";

import AACCodec from "./AACCodec";
import Mpeg2AudioCodec from "./Mpeg2AudioCodec";
// eslint-disable-next-line max-len
import GlobalAudioCodecFormItem from "../../../../../EngineTranscoding/GlobalCodecSection/GlobalCodecAudio/GlobalAudioCodecFormItem";
import InputNumberField from "../../../../../../Fields/InputNumberField";
import AdvancedSettings from "../../../../../../AdvancedSettings";

const { Item } = Form;
const { Option } = Select;

const translations = defineMessages({
  automaticAs0Tooltip: {
    id: "AudioCodec.automaticAs0Tooltip",
    defaultMessage: "Select 0 for automatic",
  },
});

export default function AudioCodec({ disabled, prefix, fieldPrefix }) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);
  const { setFieldValue } = form;

  const audioCodecType = Form.useWatch([...prefix, "type"], form);
  const outputType = Form.useWatch([prefix[0], prefix[1], "type"], form);
  const isOutputRtmp = outputType === OUTPUTS_TYPES.outputRtmp.value;

  const isMpeg2audioType = audioCodecType === SELECT_STREAM_CODEC_AUDIO[1].value;
  const isAACType = audioCodecType === SELECT_STREAM_CODEC_AUDIO[2].value;

  const handleChangeCodecType = (codecType) => {
    if (codecType === SELECT_STREAM_CODEC_AUDIO[1].value) {
      setFieldValue(prefix, {
        pid: 0,
        audioStreamSelector: 0,
        b: MPEG2AUDIO_BITRATE[9].value,
        channels: 2,
        sampleFormat: "S16",
        sampleRate: "48000",
        langCode: "eng",
        type: codecType,
      });

      return;
    }

    if (codecType === SELECT_STREAM_CODEC_AUDIO[2].value) {
      setFieldValue(prefix, {
        pid: 0,
        audioStreamSelector: 0,
        b: MPEG2AUDIO_BITRATE[9].value,
        aacProfile: AAC_PROFILE[0].value,
        channels: 2,
        sampleFormat: "S16",
        sampleRate: "48000",
        langCode: "eng",
        type: codecType,
      });

      return;
    }

    setFieldValue(prefix, { type: codecType });
  };

  return (
    <>
      <Item
        name={[...fieldPrefix, "type"]}
        label={<FormattedMessage id="AudioCodec.codec" defaultMessage="Codec" />}
        rules={[required]}
      >
        <Select onChange={handleChangeCodecType} disabled={disabled}>
          {isOutputRtmp &&
            SELECT_TRANSCODING_RTMP_CODEC_AUDIO.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          {!isOutputRtmp &&
            SELECT_TRANSCODING_CODEC_AUDIO.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Item>
      <AdvancedSettings>
        <Row gutter={[24, 24]}>
          <InputNumberField name={[...fieldPrefix, "outputId"]} label="OutputId" disabled={disabled} hidden />
          <Col span={6}>
            <InputNumberField
              disabled={disabled}
              name={[...fieldPrefix, "pid"]}
              label={
                <>
                  <FormattedMessage id="AudioCodec.pid" defaultMessage="PID" />
                  <StyledTooltip placement="top" title={formatMessage(translations.automaticAs0Tooltip)}>
                    <QuestionCircleFilled />
                  </StyledTooltip>
                </>
              }
              rules={[pidValidation]}
              min={0}
              max={8186}
            />
          </Col>
          {isMpeg2audioType && <Mpeg2AudioCodec disabled={disabled} fieldPrefix={fieldPrefix} />}
          {isAACType && <AACCodec disabled={disabled} fieldPrefix={fieldPrefix} />}
          <GlobalAudioCodecFormItem
            disabled={disabled}
            prefix={fieldPrefix}
            isAudioFormat
            audioCodecType={audioCodecType}
            isEnginePlayout
          />
        </Row>
      </AdvancedSettings>
    </>
  );
}

const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;
`;

AudioCodec.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

AudioCodec.defaultProps = {
  disabled: false,
};
