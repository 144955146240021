export default function splitArrayToHalf(array) {
  if (!array || (array && array.length === 0)) {
    return [[], []];
  }

  const middleIndex = Math.ceil(array.length / 2);

  const array1 = array.slice(0, middleIndex);
  const array2 = array.slice(middleIndex);

  return [array1, array2];
}
