import React from "react";
import { Button, Popconfirm } from "antd";
import styled from "styled-components";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import useRole from "../../hooks/useRole";
import OrganizationsServices from "../../services/organizations";

import globalTranslation from "../../lib/translations";
import { errorNotification } from "../../lib/utils/notification";

import OrganizationListRoles from "./OrganizationListRoles";

const OrganizationsListActions = ({
  setSelectedOrganizationData,
  setIsVisibleEditOrgModal,
  record,
  loadingDeleteOrg,
  setLoadingDeleteOrg,
}) => {
  const { formatMessage } = useIntl();
  const { isAdminOrSupport } = useRole();

  const handleEditOrganization = (selectedOrganization) => {
    setSelectedOrganizationData(selectedOrganization);
    setIsVisibleEditOrgModal(true);
  };

  const handleDeleteOrganization = (organizationId) => {
    setLoadingDeleteOrg(true);
    OrganizationsServices.deleteOrganization({
      organizationId,
      errorNotification: errorNotification(formatMessage),
      setLoadingDeleteOrg,
    });
  };

  return (
    <>
      <StyledButton
        icon={<EditOutlined />}
        type="dashed"
        onClick={() => handleEditOrganization(record)}
        disabled={loadingDeleteOrg}
        loading={loadingDeleteOrg}
      >
        {formatMessage(globalTranslation.edit)}
      </StyledButton>
      {isAdminOrSupport && (
        <>
          <OrganizationListRoles organization={{ name: record?.name, id: record?.id }} disabled={loadingDeleteOrg} />
          <Popconfirm
            onConfirm={() => handleDeleteOrganization(record.id)}
            title={
              <FormattedMessage
                id="OrganizationsListActions.confirmRemove"
                defaultMessage="Are you sure, you want to remove this organization?"
              />
            }
          >
            <StyledButton
              icon={<DeleteOutlined />}
              type="primary"
              danger
              disabled={loadingDeleteOrg}
              loading={loadingDeleteOrg}
            >
              {formatMessage(globalTranslation.delete)}
            </StyledButton>
          </Popconfirm>
        </>
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  margin: 3px 5px;
`;

OrganizationsListActions.propTypes = {
  setSelectedOrganizationData: PropTypes.func.isRequired,
  setIsVisibleEditOrgModal: PropTypes.func.isRequired,
  loadingDeleteOrg: PropTypes.bool.isRequired,
  setLoadingDeleteOrg: PropTypes.func.isRequired,
  record: PropTypes.shape({
    address: PropTypes.string,
    country: PropTypes.string,
    creator: PropTypes.number,
    id: PropTypes.number,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    profiles: PropTypes.arrayOf(PropTypes.string),
    public: PropTypes.bool,
    status: PropTypes.string,
    taxId: PropTypes.string,
  }).isRequired,
};

export default OrganizationsListActions;
