import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import LicenseActionButtons from "../LicenseActionButtons";

const LicenseListAdminColumn = [
  {
    title: <FormattedMessage id="LicenseList.fingerprint" defaultMessage="Fingerprint" />,
    dataIndex: "fingerprint",
    key: "fingerprint",
    render: (fingerprint) => (
      <StyledLicenseToken>
        <Tooltip title={fingerprint}>
          <StyledInfoCircleOutlined />
        </Tooltip>
      </StyledLicenseToken>
    ),
  },
  {
    title: <FormattedMessage id="LicenseList.adminAction" defaultMessage="Admin action" />,
    key: "action",
    render: (action, record) => <LicenseActionButtons licenseId={record.id} />,
  },
];

const StyledLicenseToken = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-left: 5px;
  margin-right: 5px;
`;

export default LicenseListAdminColumn;
