import React from "react";
import { Row, Popover } from "antd";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import themeColor from "../../lib/style/theme";
import NodeTranslations from "../../lib/translations/nodeTranslations";

const IncompleteTranslations = ({ incomplete }) => {
  const { formatMessage } = useIntl();

  return incomplete.map((incompleteCode) => {
    const isTranslationMissing = !(`incomplete${incompleteCode}` in NodeTranslations);

    if (isTranslationMissing) {
      return (
        <div key={incompleteCode}>
          <FormattedMessage
            id="IncompleteConfig.missingCodeTranslation"
            defaultMessage="Missing incomplete code translation"
          />
          {": "}
          {incompleteCode}
        </div>
      );
    }

    return <div key={incompleteCode}>{formatMessage(NodeTranslations[`incomplete${incompleteCode}`])}</div>;
  });
};

function IncompleteConfig({ children, incomplete, color }) {
  const incompleteNumber = incomplete && incomplete.length;

  return (
    <Row justify="center" align="middle">
      {children}
      {incompleteNumber > 0 && (
        <Popover
          placement="topRight"
          title={
            <FormattedMessage id="IncompleteConfig.incompleteConfiguration" defaultMessage="Incomplete configuration" />
          }
          content={<IncompleteTranslations incomplete={incomplete} />}
          trigger="hover"
        >
          <StyledWarningOutlined>
            <WarningOutlined style={{ color }} />
          </StyledWarningOutlined>
        </Popover>
      )}

      {(!incompleteNumber || incompleteNumber === 0) && (
        <StyledWarningOutlined>
          <CheckCircleOutlined style={{ color }} />
        </StyledWarningOutlined>
      )}
    </Row>
  );
}

const StyledWarningOutlined = styled.span`
  margin: 0 5px;
`;

IncompleteConfig.propTypes = {
  color: PropTypes.string,
};

IncompleteConfig.defaultProps = {
  color: `${themeColor.orange}`,
};

export default IncompleteConfig;
