import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card, Spin, Table, Tag, Row, Col, Avatar, Tooltip, Skeleton } from "antd";
import { connect, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  CloudDownloadOutlined,
  CloudOutlined,
  BorderVerticleOutlined,
  UserOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import styled from "styled-components";
import { Link } from "react-router-dom";

import store from "../../store";
import themeColor from "../../lib/style/theme";
import cloudNodeInstanceType from "../../lib/utils/cloudNodeInstanceType";
import { errorNotification } from "../../lib/utils/notification";

import CloudServices from "../../services/cloud";
import { actions as MQTT_ACTIONS, selectors as MQTT_SELECTORS } from "../../ducks/mqtt";

import MQTTService from "../../services/mqtt";

import { MQTT_STATUS, MESSAGE_TYPE, NODE_TYPES, NODES_TRAFFIC_TYPE } from "../../lib/utils/constants";

import NodesListActionButtons from "./NodesListActionButtons";
import NodesListClaim from "./NodesListClaim";
import GlobalErrorBoundaries from "../../components/ErrorBoundaries/GlobalErrorBoundaries";
import NodesListCloudNodeTooltip from "./NodesListCloudNodeTooltip";
import NodeNetworkOutput from "../../components/NodeNetworkOutput";
import NodeNetworkTrafficDate from "../../components/NodeNetworkTrafficDate";

const type = "DraggableBodyRow";

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

DraggableBodyRow.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string.isRequired,
  moveRow: PropTypes.func,
  style: PropTypes.object.isRequired,
};

DraggableBodyRow.defaultProps = {
  index: null,
  moveRow: null,
};

const NodesListView = ({ nodes, MQTTConnection, MQTTStatus }) => {
  const isConnection = MQTTConnection && MQTTStatus === MQTT_STATUS.CONNECTED;
  const [sortedNodes, setSortedNodes] = useState(nodes);
  const cloudNodeLicenses = useSelector(MQTT_SELECTORS.getCloudNodeLicenses);
  const selectedNetworkTrafficDate = useSelector(MQTT_SELECTORS.getNetworkOutputTrafficDate);
  const nodesList = useSelector(MQTT_SELECTORS.getNodes);

  useEffect(() => {
    const data = {
      startDate: selectedNetworkTrafficDate[0].format("YYYY-MM-DD"),
      endDate: selectedNetworkTrafficDate[1].format("YYYY-MM-DD"),
      type: NODES_TRAFFIC_TYPE.ACTIVE,
    };

    const intervalId = setInterval(() => {
      CloudServices.getCloudNodesNetworkUsage(data, { errorNotification });
    }, 60000);

    CloudServices.getCloudNodesNetworkUsage(data, { errorNotification });

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedNetworkTrafficDate]);

  useEffect(() => {
    if (isConnection) {
      MQTTService.sendMessage({
        topic: `platform/in`,
        message: {
          msgType: MESSAGE_TYPE.CLOUD_NODE_LICENSES,
        },
      });
    }
  }, [isConnection]);

  useEffect(() => {
    setSortedNodes(nodes);
  }, [nodes]);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = sortedNodes[dragIndex];

      const sortedData = update(sortedNodes, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      setSortedNodes(sortedData);
      const sortedCWID = sortedData.map((node) => node.cwid);
      const sortedCWIDString = JSON.stringify(sortedCWID);

      MQTTService.sendMessage({
        topic: `platform/in`,
        message: { msgType: MESSAGE_TYPE.SET_NODES_ORDER, order: sortedCWIDString },
      });

      store.dispatch(MQTT_ACTIONS.SET_NODES(sortedData));
    },
    [sortedNodes]
  );

  const columns = [
    {
      title: <FormattedMessage id="NodesListView.sort" defaultMessage="Sort" />,
      dataIndex: "sort",
      width: 30,
      align: "center",
      className: "drag-visible",
      render: () => <StyledIcon />,
    },
    {
      title: <FormattedMessage id="NodesListView.hostname" defaultMessage="Node Name" />,
      dataIndex: "cnn",
      key: "cnn",
      render: (cnn, { cwid }) => {
        const { online } = nodesList.find((node) => node.cwid === cwid) ?? {};
        const isOnline = online === MQTT_STATUS.CONNECTED;

        if (isOnline) {
          return (
            <>
              <Link to={`/node/${cwid}/${cnn}/channels`}>{cnn}</Link>
              <StyledCwid>
                <Tooltip title={cwid}>
                  <InfoCircleOutlined />
                </Tooltip>
              </StyledCwid>
            </>
          );
        }

        return (
          <>
            <span>{cnn}</span>
            <StyledCwid>
              <Tooltip title={cwid}>
                <InfoCircleOutlined />
              </Tooltip>
            </StyledCwid>
          </>
        );
      },
    },
    {
      title: <FormattedMessage id="NodesListView.owner" defaultMessage="Owner" />,
      dataIndex: "owner",
      key: "owner",
      align: "center",
      render: (username) => <Link to={`/users/${username}`}>{username}</Link>,
    },
    {
      title: <FormattedMessage id="NodesListView.status" defaultMessage="Status" />,
      dataIndex: "online",
      key: "online",
      align: "center",
      render: (status, record) => (
        <>
          {status === MQTT_STATUS.CONNECTED && (
            <Tag color="success" icon={<CloudOutlined />}>
              <FormattedMessage id="general.online" defaultMessage="Online" />
            </Tag>
          )}
          {status === MQTT_STATUS.DISCONNECTED && (
            <Tag color="error" icon={<CloudDownloadOutlined />}>
              <FormattedMessage id="general.offline" defaultMessage="Offline" />
            </Tag>
          )}
          {!status && record.type === NODE_TYPES.CLOUD && (
            <Tag color="default" icon={<LoadingOutlined />}>
              <FormattedMessage id="NodesListView.waiting" defaultMessage="Waiting" />
            </Tag>
          )}
        </>
      ),
    },
    {
      title: <FormattedMessage id="NodesListView.version" defaultMessage="Version" />,
      dataIndex: "nodeVersion",
      key: "nodeVersion",
      align: "center",
      render: (nodeVersion) => (
        <>
          {nodeVersion && <span>{nodeVersion}</span>}
          {!nodeVersion && (
            <Tag color="default" icon={<LoadingOutlined />}>
              <FormattedMessage id="NodesListView.waiting" defaultMessage="Waiting" />
            </Tag>
          )}
        </>
      ),
    },
    {
      title: <FormattedMessage id="NodesListView.type" defaultMessage="Type" />,
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (recordType, { instanceType, instanceRegion }) => (
        <>
          {recordType === NODE_TYPES.CLOUD && (
            <Tooltip placement="top" title={<NodesListCloudNodeTooltip region={instanceRegion} />}>
              <StyledCloudAvatar icon={<CloudOutlined />} />
              <StyledInterfaceType>({cloudNodeInstanceType(instanceType)})</StyledInterfaceType>
              <StyledInterfaceType>
                {instanceType === "trial" && (
                  <>
                    <FormattedMessage id="NodesListView.daysLeft" defaultMessage="days left" />
                    {": "}
                    <StyledOrangeText>{cloudNodeLicenses?.trialDaysLeft}</StyledOrangeText>
                  </>
                )}
              </StyledInterfaceType>
            </Tooltip>
          )}
          {recordType === NODE_TYPES.USER && (
            <Tooltip
              placement="top"
              title={<FormattedMessage id="NodesListView.userNode" defaultMessage="User node" />}
            >
              <StyledUserAvatar icon={<UserOutlined />} />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: <NodeNetworkTrafficDate showTitle />,
      dataIndex: "networkOutput",
      key: "networkOutput",
      align: "center",
      render: (_data, record) => {
        return <NodeNetworkOutput instanceId={record?.instanceId} isCloudNode={record.type === NODE_TYPES.CLOUD} />;
      },
    },
    {
      title: <FormattedMessage id="NodesListView.actions" defaultMessage="Actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (_data, record) => {
        return (
          <NodesListActionButtons
            cwid={record.cwid}
            owner={record.creator}
            cnn={record.cnn}
            fingerprint={record.encryptedFingerprint}
          />
        );
      },
    },
  ];

  if (!isConnection) {
    return (
      <Row justify="center">
        <Spin
          tip={
            <FormattedMessage id="NodesListView.waitingForWCloud" defaultMessage="Waiting for cloud connection..." />
          }
        >
          <Skeleton active />
        </Spin>
      </Row>
    );
  }

  return (
    <GlobalErrorBoundaries>
      <Row gutter={[24, 24]}>
        <NodesListClaim />
        <Col span={24}>
          <Card title={<FormattedMessage id="NodesListView.nodesList" defaultMessage="Nodes list" />}>
            <DndProvider backend={HTML5Backend}>
              <StyledTable
                components={components}
                columns={columns}
                dataSource={sortedNodes}
                pagination={false}
                bordered
                role="table"
                rowKey={(record) => record.cwid}
                onRow={(record, index) => ({
                  index,
                  moveRow,
                })}
                scroll={{ x: "calc(100vh - 250px)" }}
              />
            </DndProvider>
          </Card>
        </Col>
      </Row>
    </GlobalErrorBoundaries>
  );
};

const StyledIcon = styled(BorderVerticleOutlined)`
  color: ${themeColor.orange};
`;

const StyledTable = styled(Table)`
  tr.drop-over-downward td {
    border-bottom: 2px dashed ${themeColor.orange};
  }
  tr.drop-over-upward td {
    border-top: 2px dashed ${themeColor.orange};
  }
`;

const StyledCloudAvatar = styled(Avatar)`
  background: ${themeColor.cloudBlue};
  margin-right: 5px;
`;

const StyledCwid = styled.span`
  span {
    margin-left: 5px;
  }
`;

const StyledUserAvatar = styled(Avatar)`
  background: ${themeColor.orangeHover};
  margin: 0 5px;
`;

const StyledInterfaceType = styled.div`
  font-size: 11px;
`;

const StyledOrangeText = styled.span`
  color: ${themeColor.maritime};
`;

NodesListView.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      cnn: PropTypes.string.isRequired,
      cwid: PropTypes.string.isRequired,
      online: PropTypes.string,
    })
  ).isRequired,
  MQTTConnection: PropTypes.bool,
  MQTTStatus: PropTypes.string,
};

NodesListView.defaultProps = {
  MQTTStatus: null,
  MQTTConnection: null,
};

const mapStateToProps = (state) => ({
  nodes: MQTT_SELECTORS.getNodes(state),
  MQTTStatus: MQTT_SELECTORS.getStatus(state),
  MQTTConnection: MQTT_SELECTORS.getMqttConnection(state),
});

export default connect(mapStateToProps, null)(NodesListView);
