import React from "react";
import { Row, Col, Empty } from "antd";

import StreamSourceElement from "../StreamSourceElement";

import parseConnectionMethodName from "../../lib/utils/parseConnectionMethodName";
import { DESTINATIONS_TYPES } from "../../lib/utils/types/destinationsTypes";

export default function DestinationsUrlTooltip({ destinations }) {
  if (destinations && destinations.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Row gutter={24}>
      {destinations.map((destination) => {
        return (
          <Col span={12} key={destination?.outputName}>
            <StreamSourceElement
              connectionType={destination?.type}
              destinationAddress={destination?.destinationAddress}
              destinationPort={destination?.destinationPort}
              localPort={destination?.localPort}
              handleClick={() => {}}
              interfaceIp={destination?.interfaceIp}
              label={parseConnectionMethodName(destination?.type)}
              title={
                destination?.destinationName
                  ? destination?.destinationName
                  : parseConnectionMethodName(destination?.type)
              }
              cloudId={destination?.cloudId}
            />
          </Col>
        );
      })}
    </Row>
  );
}

DestinationsUrlTooltip.propTypes = {
  ...DESTINATIONS_TYPES,
};

DestinationsUrlTooltip.defaultProps = {
  destinations: [],
};
