import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Popconfirm, Row } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { FORM_VERSION, MESSAGE_TYPE, NODE_CHANNEL_STATE } from "../../lib/utils/constants";
import { errorNotification } from "../../lib/utils/notification";

import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";

import MQTTService from "../../services/mqtt";
import NODE_CHANNELS_SERVICES from "../../services/node";

import StyledButton from "../StyledButton";
import NodeChannelsActionStartButton from "./StartButton";

const translations = defineMessages({
  wrongConfigurationVersion: {
    id: "ChannelListActionButtons.wrongConfigurationVersion",
    defaultMessage: "Channel configuration version not supported",
  },
});

const NodeChannelsActionButtons = ({
  channelId,
  status: { requestedStatusText, channelStatusText },
  channelConfig,
  detailView,
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [waitingButton, setWaitingButton] = useState(null);
  const timerRef = useRef(null);
  const {
    location: { search },
  } = window;

  const nodeData = useSelector(MQTT_SELECTORS.getActiveNode);

  useEffect(() => {
    setWaitingButton(null);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [channelStatusText]);

  const oldCloudIds = [];
  const oldPermissionId = +channelConfig?.input?.mainConnection?.permissionId || false;
  if (channelConfig?.nonMuxedOutputs && channelConfig.nonMuxedOutputs.length > 0) {
    channelConfig.nonMuxedOutputs.forEach((nonMuxedOutput) => {
      const oldCloudId = nonMuxedOutput?.cloudId;
      if (oldCloudId) {
        oldCloudIds.push(nonMuxedOutput?.cloudId);
      }
    });
  }
  if (channelConfig?.muxedOutputs && channelConfig.muxedOutputs.length > 0) {
    channelConfig.muxedOutputs.forEach((muxedOutput) => {
      // eslint-disable-next-line no-unused-expressions
      muxedOutput?.outputs?.forEach((muxedOutputOutput) => {
        const oldCloudId = muxedOutputOutput?.cloudId;
        if (oldCloudId) {
          oldCloudIds.push(muxedOutputOutput?.cloudId);
        }
      });
    });
  }

  const cloudData = { oldPermissionId, oldCloudIds };

  const onActionClick = (actionName) => {
    setWaitingButton(actionName);
    timerRef.current = setTimeout(() => {
      setWaitingButton(null);
    }, 3000);
  };

  onActionClick.propTypes = {
    actionName: PropTypes.oneOf(["start", "stop", "delete"]),
  };

  const onConfirmRemove = useCallback(() => {
    NODE_CHANNELS_SERVICES.updateChannelData(
      { ...cloudData, cwid: nodeData.cwid },
      { errorNotification: errorNotification(formatMessage) }
    );
    NODE_CHANNELS_SERVICES.clearStatistic(channelId);
    onActionClick("delete");
    MQTTService.sendMessage({
      topic: `node/${nodeData.cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.DELETE_CHANNEL,
        channelId,
      },
    });

    if (detailView) {
      navigate(-1);
    }
  }, [channelId, cloudData, nodeData, formatMessage]);

  const handleEditChannel = useCallback(() => {
    if (+channelConfig?.version > +FORM_VERSION) {
      errorNotification(formatMessage)(translations.wrongConfigurationVersion);
    } else {
      navigate(`/node/${nodeData.cwid}/${nodeData.cnn}/channel/edit/${channelId}${search}`);
    }
  }, [channelId, nodeData, formatMessage, channelConfig, search]);

  const handleStartChannel = useCallback(() => {
    MQTTService.sendMessage({
      topic: `node/${nodeData.cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.STREAM_PLAY,
        channelId,
      },
    });

    NODE_CHANNELS_SERVICES.clearStatistic({ channelId, from: nodeData.cwid });
    onActionClick("start");
  }, [channelId, nodeData]);

  return (
    <Row type="flex" justify="center" align="middle">
      <NodeChannelsActionStartButton
        handleStartChannel={handleStartChannel}
        waitingButton={waitingButton}
        channelStatusText={channelStatusText}
      />

      <Divider type="vertical" />
      <StyledButton
        onClick={useCallback(() => {
          MQTTService.sendMessage({
            topic: `node/${nodeData.cwid}/in`,
            message: {
              msgType: MESSAGE_TYPE.STREAM_STOP,
              channelId,
            },
          });
          onActionClick("stop");
        }, [channelId, nodeData])}
        loading={waitingButton === "stop"}
        disabled={
          ![NODE_CHANNEL_STATE.CONNECTED].includes(requestedStatusText) ||
          channelStatusText === NODE_CHANNEL_STATE.PROCESS_DISCONNECTED
        }
      >
        {waitingButton !== "stop" ? <FormattedMessage id="ChannelList.actionStop" defaultMessage="STOP" /> : <span />}
      </StyledButton>
      <Divider type="vertical" />
      <Button
        onClick={handleEditChannel}
        type="dashed"
        disabled={
          ![NODE_CHANNEL_STATE.STOPPED, NODE_CHANNEL_STATE.UNCONFIGURED, NODE_CHANNEL_STATE.CONFIG_INCOMPLETE].includes(
            channelStatusText
          )
        }
      >
        <FormattedMessage id="ChannelList.action.edit" defaultMessage="EDIT" />
      </Button>
      <Divider type="vertical" />
      <Popconfirm
        disabled={
          waitingButton === "start" ||
          [NODE_CHANNEL_STATE.CONNECTED, NODE_CHANNEL_STATE.CONNECTING, NODE_CHANNEL_STATE.RECONNECTING].includes(
            channelStatusText
          )
        }
        title={
          <FormattedMessage
            id="NodeChannelsActionButtons.confirmRemove"
            defaultMessage="Are you sure, you want to remove this channel?"
          />
        }
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        onConfirm={onConfirmRemove}
        okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
        cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
      >
        <Button
          type="primary"
          danger
          disabled={
            waitingButton === "start" ||
            [NODE_CHANNEL_STATE.CONNECTED, NODE_CHANNEL_STATE.CONNECTING, NODE_CHANNEL_STATE.RECONNECTING].includes(
              channelStatusText
            )
          }
          loading={waitingButton === "delete"}
          onClick={() => null}
        >
          {waitingButton !== "delete" ? (
            <FormattedMessage id="ChannelList.action.delete" defaultMessage="DELETE" />
          ) : (
            <span />
          )}
        </Button>
      </Popconfirm>
    </Row>
  );
};

const StyledActionButton = styled(StyledButton)`
  &.ant-btn-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

// eslint-disable-next-line no-underscore-dangle
StyledActionButton.__ANT_BUTTON = true;

NodeChannelsActionButtons.propTypes = {
  detailView: PropTypes.bool,
  channelId: PropTypes.string.isRequired,
  status: PropTypes.shape({
    channelStatusText: PropTypes.string.isRequired,
    requestedStatusText: PropTypes.string.isRequired,
  }).isRequired,
};

NodeChannelsActionButtons.defaultProps = {
  detailView: false,
};

export default NodeChannelsActionButtons;
