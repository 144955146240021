import * as React from "react";
import { useIntl, defineMessages } from "react-intl";
import { Col } from "antd";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { selectors as CONNECTION_SELECTORS } from "../../../ducks/node";

import { IPField, PortField } from "../../Fields/Fields";
import NetworkField from "../../Fields/NetworkField";

import NetworkFieldLabel from "../Labels/NetworkFieldLabel";
import LocalPortLabel from "../../LocalPortLabel";

import { CONNECTION_METHOD_INPUT, NODE_OS } from "../../../lib/utils/constants";

const translations = defineMessages({
  ipAddressLabel: {
    id: "IPField.label",
    defaultMessage: "IP Address",
  },
});

const InputStreamUdpSection = ({ prefix, connectionMethod, disabled }) => {
  const { formatMessage } = useIntl();
  const isMulticastType = connectionMethod === CONNECTION_METHOD_INPUT.inUdpMulticast.value;
  const isUnicastType = connectionMethod === CONNECTION_METHOD_INPUT.inUdpUnicast.value;
  const nodeOS = useSelector(CONNECTION_SELECTORS.getNodeOS);
  const isWindows = nodeOS === NODE_OS.WINDOWS;

  return (
    <>
      {isMulticastType && (
        <>
          <Col span={12}>
            <IPField
              name={[...prefix, "destinationAddress"]}
              label={formatMessage(translations.ipAddressLabel)}
              disabled={disabled}
            />
          </Col>
          <Col span={12}>
            <PortField name={[...prefix, "destinationPort"]} disabled={disabled} />
          </Col>
        </>
      )}

      <Col span={12}>
        <NetworkField
          name={[...prefix, "interfaceIp"]}
          label={NetworkFieldLabel("input")}
          disabled={disabled}
          disableAutomatic={isWindows && isMulticastType}
        />
      </Col>
      {isUnicastType && (
        <Col span={12}>
          <PortField
            name={[...prefix, "localPort"]}
            disabled={disabled}
            label={<LocalPortLabel noTooltip={isUnicastType} />}
            min={isUnicastType ? 1 : 0}
          />
        </Col>
      )}
    </>
  );
};

InputStreamUdpSection.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  connectionMethod: PropTypes.string.isRequired,
};

InputStreamUdpSection.defaultProps = {
  disabled: null,
};

export default InputStreamUdpSection;
