import React from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "antd";
import PropTypes from "prop-types";

import ProbeDrawerElementText from "../ProbeDrawerElementText";

import { SELECT_DEFAULT_AUTO } from "../../../lib/utils/constants";
import ParseCodecName from "../../../lib/utils/parseCodecName";

const ProbeDrawerElementStreamVideo = ({ data }) => {
  const displayAspectRatio =
    data && data?.displayAspectRatio?.num && `${data?.displayAspectRatio?.num}/${data?.displayAspectRatio?.den}`;
  const sampleAspectRatio =
    data && data?.sampleAspectRatio?.num && `${data?.sampleAspectRatio?.num}/${data?.sampleAspectRatio?.den}`;
  const fps =
    data?.fps === SELECT_DEFAULT_AUTO.value ? !!data?.fpsRaw?.num && data?.fpsRaw?.num / data?.fpsRaw?.den : data.fps;

  return (
    <Row justify="center">
      <ProbeDrawerElementText
        data={data?.pid}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.pid" defaultMessage="PID" />}
        divided
      />
      <ProbeDrawerElementText
        data={ParseCodecName(data?.codec)}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.codec" defaultMessage="Codec" />}
        divided
      />
      <ProbeDrawerElementText
        data={data?.bitrate}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.bitrate" defaultMessage="Bitrate [kbps]" />}
        divided
      />
      <ProbeDrawerElementText
        data={data?.width}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.width" defaultMessage="Width" />}
        divided
      />
      <ProbeDrawerElementText
        data={data?.height}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.height" defaultMessage="Height" />}
        divided
      />
      <ProbeDrawerElementText
        data={displayAspectRatio}
        text={
          <FormattedMessage
            id="ProbeDrawerElementStreamVideo.displayAspectRatio"
            defaultMessage="Display Aspect Ratio"
          />
        }
        divided
      />
      <ProbeDrawerElementText
        data={fps}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.fps" defaultMessage="FPS" />}
        divided
      />
      <ProbeDrawerElementText
        data={sampleAspectRatio}
        text={
          <FormattedMessage id="ProbeDrawerElementStreamVideo.sampleAspectRatio" defaultMessage="Sample Aspect Ratio" />
        }
        divided
      />
      <ProbeDrawerElementText
        data={data?.pixelFormat}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.pixelFormat" defaultMessage="Pixel Format" />}
      />
    </Row>
  );
};

ProbeDrawerElementStreamVideo.propTypes = {
  data: PropTypes.shape({
    bitrate: PropTypes.number.isRequired,
    codec: PropTypes.string.isRequired,
    displayAspectRatio: PropTypes.shape({
      den: PropTypes.number.isRequired,
      num: PropTypes.number.isRequired,
    }).isRequired,
    fps: PropTypes.string.isRequired,
    fpsRaw: PropTypes.shape({
      den: PropTypes.number.isRequired,
      num: PropTypes.number.isRequired,
    }),
    height: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    pid: PropTypes.number,
    pixelFormat: PropTypes.string.isRequired,
    sampleAspectRatio: PropTypes.shape({
      den: PropTypes.number.isRequired,
      num: PropTypes.number.isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
};

export default ProbeDrawerElementStreamVideo;
