import React from "react";
import { Row } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import ProbeDrawerElementText from "../ProbeDrawerElementText";

import ParseCodecName from "../../../../../lib/utils/parseCodecName";

const ProbeDrawerElementStreamAudio = ({ data }) => {
  return (
    <Row justify="center">
      <ProbeDrawerElementText
        data={data?.pid}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.pid" defaultMessage="PID" />}
        divided
      />
      <ProbeDrawerElementText
        data={data?.channels}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.channels" defaultMessage="Channels" />}
        divided
      />
      <ProbeDrawerElementText
        data={ParseCodecName(data?.codec)}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.codec" defaultMessage="Codec" />}
        divided
      />
      <ProbeDrawerElementText
        data={data?.bitrate}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.bitrate" defaultMessage="Bitrate [kbps]" />}
        divided
      />
      <ProbeDrawerElementText
        data={data?.sampleFormat}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.sampleFormat" defaultMessage="Sample Format" />}
        divided
      />
      <ProbeDrawerElementText
        data={data?.sampleRate}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.sampleRate" defaultMessage="Sample Rate" />}
        divided
      />
    </Row>
  );
};

ProbeDrawerElementStreamAudio.propTypes = {
  data: PropTypes.shape({
    bitrate: PropTypes.number.isRequired,
    channels: PropTypes.number.isRequired,
    codec: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    pid: PropTypes.number,
    sampleFormat: PropTypes.string.isRequired,
    sampleRate: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProbeDrawerElementStreamAudio;
