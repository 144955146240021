import React from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";

const translations = defineMessages({
  label: {
    id: "ConnectionMethodLabel.label",
    defaultMessage: "Connection method",
  },
});

function ConnectionMethodLabel() {
  const { formatMessage } = useIntl();

  return (
    <StyledLabel>
      <span>{formatMessage(translations.label)}</span>
    </StyledLabel>
  );
}

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

export default ConnectionMethodLabel;
