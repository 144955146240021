import mqtt from "mqtt";

import store, { history } from "../store";

import { actions as MQTT_ACTIONS } from "../ducks/mqtt";
import { actions as NODE_ACTIONS } from "../ducks/node";

import { MESSAGE_TYPE, PROTOCOL_VERSION, PROTOCOL_VERSION_V5 } from "../lib/utils/constants";
import getUserFromJWToken from "../lib/utils/getUserFromJWToken";
// eslint-disable-next-line import/no-cycle
import MQTTMessageHandler from "../lib/utils/mqttMessageHandler";
import cloudNotificationHandler from "../lib/utils/cloudNotificationHandler";

class MQTTHandler {
  constructor() {
    this.clientId = null;
    this.timeout = null;
  }

  initialize = async () => {
    const token = window.localStorage.getItem("authToken");
    const MQTT_HOST = process.env.REACT_APP_MQTT_HOST || "mqtt-test.d.quickstream.tech:443";
    const { username } = getUserFromJWToken(token);
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const clientId = `clientweb/${username}/${timestamp}`;

    this.clientId = clientId;

    // Create WebSocket connection.
    const options = {
      clientId,
      username: clientId,
      password: token,
      clean: true,
      protocolVersion: 5,
      connectTimeout: 5000,
      reconnectPeriod: 0,
      keepalive: 30,
      rh: true,
      will: {
        topic: `${clientId}/status`,
        payload: JSON.stringify({ online: false, from: clientId, msgType: MESSAGE_TYPE.CLIENT_UPDATE_STATUS }),
        qos: 1,
        retain: true,
        properties: {
          messageExpiryInterval: 60,
        },
      },
    };

    this.client = mqtt.connect(`wss://${MQTT_HOST}/mqtt`, options);

    this.client.on("connect", async () => {
      clearTimeout(this.timeout);

      try {
        await this.client.subscribeAsync(`${clientId}/in`);
        await this.client.subscribeAsync(`clientweb/${username}/in`);

        this.client.publish(
          `${clientId}/status`,
          JSON.stringify({
            online: true,
            from: clientId,
            msgType: MESSAGE_TYPE.CLIENT_UPDATE_STATUS,
            version: PROTOCOL_VERSION,
          }),
          {
            qos: 1,
            retain: true,
            properties: {
              messageExpiryInterval: 60,
            },
          }
        );

        this.client.publish(
          "platform/in",
          JSON.stringify({ msgType: MESSAGE_TYPE.GET_NODES, from: clientId, version: PROTOCOL_VERSION })
        );

        store.dispatch(MQTT_ACTIONS.SET_CONNECTION_AUTH(clientId));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("MQTT Initialization error:", error);
      }
    });

    this.client.on("message", (topic, message) => {
      try {
        MQTTMessageHandler({ message: JSON.parse(message.toString()), client: this.client, clientId });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`Wrong message format, message: ${message}`);
      }
    });

    this.client.on("error", () => {
      store.dispatch(MQTT_ACTIONS.DISCONNECT());
      cloudNotificationHandler({ msgType: MESSAGE_TYPE.ERROR, errorCode: 1 });
      window.localStorage.removeItem("authToken");
      history.push("/login");
      clearTimeout(this.timeout);
    });

    this.client.on("close", () => {
      store.dispatch(MQTT_ACTIONS.CLEAR_CONNECTION());
      store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
      this.timeout = setTimeout(async () => {
        await this.client.end();
        this.client = null;
        store.dispatch(MQTT_ACTIONS.DISCONNECT());
        const tokenToRefresh = window.localStorage.getItem("authToken");

        if (tokenToRefresh) {
          this.reInitialize();
        }
      }, 3000);
    });

    this.client.on("offline", () => {
      cloudNotificationHandler({ msgType: MESSAGE_TYPE.ERROR, errorCode: 5 });
      store.dispatch(MQTT_ACTIONS.DISCONNECT());
    });
  };

  sendMessage = ({ message, topic }) => {
    const signedMessage = { ...message, from: this.clientId, version: PROTOCOL_VERSION };

    this.client.publish(topic, JSON.stringify(signedMessage));
  };

  sendMessageV5 = ({ message, topic }) => {
    const signedMessage = { ...message, from: this.clientId, version: PROTOCOL_VERSION_V5 };

    this.client.publish(topic, JSON.stringify(signedMessage));
  };

  disconnect = () => {
    if (this.client) {
      this.client.end();
    }
  };

  reInitialize = () => {
    this.initialize();
  };
}

export default new MQTTHandler();
