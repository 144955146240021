const setLicenseTagColor = (licenseType) => {
  switch (licenseType) {
    case "professional":
      return "geekblue";
    case "enterprise":
      return "cyan";
    case "playout":
      return "purple";
    default:
      return "gold";
  }
};

export default setLicenseTagColor;
