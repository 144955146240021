import React, { useEffect } from "react";
import { QuestionCircleFilled, WarningOutlined } from "@ant-design/icons";
import { Radio, Card, Tooltip, Spin, Skeleton, Alert } from "antd";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import store from "../../../../store";

import { selectors as LOG_SELECTORS } from "../../../../ducks/logs";
import { selectors as LOADING_SELECTORS, actions as LOADING_ACTIONS } from "../../../../ducks/loadingData";
import { selectors as CONNECTION_SELECTORS } from "../../../../ducks/node";
import { selectors as MQTT_SELECTORS } from "../../../../ducks/mqtt";

import MQTTService from "../../../../services/mqtt";
import themeColor from "../../../../lib/style/theme";
import { MQTT_STATUS, MESSAGE_TYPE } from "../../../../lib/utils/constants";

const { Button: RadioButton, Group } = Radio;

const LogLevel = ({ MQTTStatus, logLevel, cwid, MQTTConnection }) => {
  const isVerboseLogLvl = logLevel === 5;
  const isDebugLogLvl = logLevel === 4;

  useEffect(() => {
    const isConnection = MQTTConnection && MQTTStatus === MQTT_STATUS.CONNECTED;

    if (isConnection) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_LOG_LEVEL,
        },
      });

      store.dispatch(LOADING_ACTIONS.SET_LOADING_LOG_LVL(true));
    }
  }, [cwid, MQTTStatus, MQTTConnection]);

  const onChange = (event) => {
    const selectedLogLevel = event.target.value;

    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.SET_LOG_LEVEL,
        level: selectedLogLevel,
      },
    });
    store.dispatch(LOADING_ACTIONS.SET_LOADING_LOG_LVL(true));
  };

  const isLoadingLogLevel = useSelector(LOADING_SELECTORS.getLoadingLogsLvl);

  if (isLoadingLogLevel) {
    return (
      <Spin tip={<FormattedMessage id="LogLevel.loadingLogLevel" defaultMessage="Loading log level" />}>
        <Skeleton active />
      </Spin>
    );
  }

  const LogLevelLabelTooltip = (
    <>
      <div>
        <FormattedMessage
          id="LogLevelLabel.label"
          // eslint-disable-next-line max-len
          defaultMessage="Select the log level. If you select for example “WARNING” only WARNING. ERROR, CRITICAL logs will be recorded. Find out more about logging here:"
        />
      </div>{" "}
      <a href="https://help.quickstream.tech/portal/kb/articles/logging" target="_blank" rel="noopener noreferrer">
        https://help.quickstream.tech/portal/kb/articles/logging
      </a>
    </>
  );

  const LogLevelLabel = (
    <StyledLabel>
      <span>
        <FormattedMessage id="LogLevelLabel.logLevel" defaultMessage="Log level" />
      </span>
      <Tooltip placement="top" title={LogLevelLabelTooltip}>
        <QuestionCircleFilled />
      </Tooltip>
    </StyledLabel>
  );

  return (
    <Card title={LogLevelLabel}>
      <Group onChange={onChange} value={logLevel} optionType="button" buttonStyle="solid">
        <RadioButton value={0}>
          <FormattedMessage id="LogLevel.critical" defaultMessage="CRITICAL" />
        </RadioButton>
        <RadioButton value={1}>
          <FormattedMessage id="LogLevel.error" defaultMessage="ERROR" />
        </RadioButton>
        <RadioButton value={2}>
          <FormattedMessage id="LogLevel.warning" defaultMessage="WARNING" />
        </RadioButton>
        <RadioButton value={3}>
          <FormattedMessage id="LogLevel.info" defaultMessage="INFO" />
        </RadioButton>
        <RadioButton value={4}>
          <FormattedMessage id="LogLevel.debug" defaultMessage="DEBUG" />
        </RadioButton>
        <RadioButton value={5}>
          <FormattedMessage id="LogLevel.verbose" defaultMessage="VERBOSE" />
        </RadioButton>
      </Group>
      {isVerboseLogLvl && (
        <StyledAlert
          type="error"
          message={
            <>
              <StyledIcon>
                <WarningOutlined />
              </StyledIcon>
              <FormattedMessage
                id="LogLevel.verboseWarning"
                // eslint-disable-next-line max-len
                defaultMessage="Warning! Debug mode can generate HUGE log files and therefore may interfere with your system. Debug mode should be used with caution only for troubleshooting. It should not be used at all in a production environment"
              />
              .
            </>
          }
        />
      )}
      {isDebugLogLvl && (
        <StyledAlert
          type="warning"
          message={
            <>
              <StyledIcon>
                <WarningOutlined />
              </StyledIcon>
              <FormattedMessage
                id="LogLevel.debugWarning"
                // eslint-disable-next-line max-len
                defaultMessage="Warning! Debug mode can generate very large log files and therefore may interfere with your system. Debug mode should be used with caution only for troubleshooting. It should not be used for longer periods of time in a production environment"
              />
              .
            </>
          }
        />
      )}
    </Card>
  );
};

const StyledAlert = styled(Alert)`
  margin-top: 15px;
  .ant-alert-message {
    color: ${themeColor.orange};
  }
`;

const StyledIcon = styled.span`
  margin: 0 5px;
`;

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

const mapStateToProps = (state) => ({
  cwid: CONNECTION_SELECTORS.getNodeCwid(state),
  logLevel: LOG_SELECTORS.getLogLevel(state),
  MQTTStatus: MQTT_SELECTORS.getStatus(state),
  MQTTConnection: MQTT_SELECTORS.getMqttConnection(state),
});

LogLevel.propTypes = {
  cwid: PropTypes.string,
  MQTTStatus: PropTypes.string,
  logLevel: PropTypes.number,
  MQTTConnection: PropTypes.bool,
};

LogLevel.defaultProps = {
  cwid: null,
  MQTTStatus: null,
  logLevel: null,
  MQTTConnection: null,
};

export default connect(mapStateToProps, null)(LogLevel);
