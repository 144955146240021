export default (newestChannelInStats) => {
  const parsedNewestChannelInputStat = {};

  if (newestChannelInStats) {
    newestChannelInStats.forEach((stat) => {
      const { n: statName, v: statValue } = stat;
      parsedNewestChannelInputStat[statName] = statValue;
    });
  }

  return parsedNewestChannelInputStat;
};
