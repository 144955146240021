import React from "react";
import styled from "styled-components";
import { Button, Affix } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const AddNewChannelButton = ({ setVisibleNewNodeChannelForm }) => {
  return (
    <Affix offsetBottom={15}>
      <StyledAddButton type="primary" onClick={() => setVisibleNewNodeChannelForm(true)}>
        <FormattedMessage id="AddButton.create" defaultMessage="Create new channel" />
      </StyledAddButton>
    </Affix>
  );
};

const StyledAddButton = styled(Button)`
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: center;
`;

AddNewChannelButton.propTypes = {
  setVisibleNewNodeChannelForm: PropTypes.func.isRequired,
};

export default AddNewChannelButton;
