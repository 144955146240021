import * as React from "react";
import PropTypes from "prop-types";
import { Col } from "antd";
import { useIntl } from "react-intl";

import { IPFieldV5, PortField, TTLField } from "../../Fields/Fields";
import NetworkField from "../../Fields/NetworkField";
import AdvancedSettings from "../../AdvancedSettings";

import globalTranslations from "../../../lib/translations";

import NetworkFieldLabel from "../Labels/NetworkFieldLabel";
import UDPTTLLabel from "../Labels/UDPTTLLabel";
import LocalPortLabel from "../../LocalPortLabel";

export function OutputStreamUdpSection({ disabled, prefix, basicMode }) {
  const { formatMessage } = useIntl();

  return (
    <>
      <Col sm={12} md={12} lg={12}>
        <IPFieldV5
          label={formatMessage(globalTranslations.addressIp)}
          name={[...prefix, "destinationAddress"]}
          disabled={disabled}
        />
      </Col>
      <Col sm={12} md={12} lg={6}>
        <PortField name={[...prefix, "destinationPort"]} disabled={disabled} />
      </Col>
      <Col sm={12} md={12} lg={6}>
        <TTLField name={[...prefix, "udpTTL"]} disabled={disabled} label={<UDPTTLLabel />} />
      </Col>
      {!basicMode && (
        <>
          <Col sm={12} md={12} lg={12}>
            <NetworkField name={[...prefix, "interfaceIp"]} label={NetworkFieldLabel("output")} disabled={disabled} />
          </Col>
          <Col span={24}>
            <AdvancedSettings>
              <PortField name={[...prefix, "localPort"]} disabled={disabled} label={<LocalPortLabel />} min={0} />
            </AdvancedSettings>
          </Col>
        </>
      )}
    </>
  );
}

OutputStreamUdpSection.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  basicMode: PropTypes.bool,
};

OutputStreamUdpSection.defaultProps = {
  disabled: null,
  basicMode: false,
};

export default OutputStreamUdpSection;
