import React from "react";
import { Form, Input, Col, Card, Row } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// import Muxer from "../NodeChannelFormV4/MuxedSection/MuxedSectionFormItem/Muxer";
import InputTextFiled from "../Fields/InputTextField";

import globalTranslations from "../../lib/translations";
import { max32characters, az09space, required } from "../../lib/utils/formValidation";
import { selectors as CHANNEL_SELECTORS } from "../../ducks/nodeChannels";

import StreamCodec from "../NodeChannelFormV4/StreamsSection/StreamCodec";
import SelectStream from "../NodeChannelFormV4/StreamsSection/SelectStream";

const { Item } = Form;

const translations = defineMessages({
  formats: {
    id: "OutputRTMPFormItem.formats",
    defaultMessage: "rtmp:// or rtmpt:// or rtmpte:// or rtmps:// or rtmpte:// or rtmpts://",
  },
  wrongFormat: {
    id: "OutputRTMPFormItem.wrongFormat",
    defaultMessage: "Supported formats: rtmp:// or rtmpt:// or rtmpte:// or rtmps:// or rtmpte:// or rtmpts://",
  },
});

const OutputRTMPFormItem = ({ disabled, prefix, form, channelId, muxedOutput }) => {
  const { getFieldValue } = form;
  const { formatMessage } = useIntl();
  const nodeChannels = useSelector(CHANNEL_SELECTORS.getChannels);
  const channelInputType = getFieldValue(["input", "type"]);

  const uniqueValidator = (rule, value) => {
    const outputNames = nodeChannels.getIn([`${channelId}`, "outputs", "outputsNames"]);
    const parsedOutputNames = outputNames && outputNames.filter((name) => name !== muxedOutput?.name);

    const duplicatedMuxedOutputName = parsedOutputNames && parsedOutputNames.includes(value);

    if (duplicatedMuxedOutputName) {
      return Promise.reject(formatMessage(translations.nameAlreadyExist));
    }

    return Promise.resolve();
  };

  return (
    <>
      <Col span={12}>
        <InputTextFiled
          disabled={disabled}
          name={[...prefix, "name"]}
          label={formatMessage(globalTranslations.name)}
          rules={[{ validator: uniqueValidator }, max32characters, az09space, required]}
        />
      </Col>
      <Col span={24}>
        <Item
          name={[...prefix, "url"]}
          label={<FormattedMessage id="OutputRTMPFormItem.url" defaultMessage="Url" />}
          rules={[
            required,
            {
              pattern: new RegExp("^(rtmp://|rtmpt://|rtmpte://|rtmps://|rtmpte://|rtmpts://)"),
              message: formatMessage(translations.wrongFormat),
            },
          ]}
        >
          <Input placeholder={formatMessage(translations.formats)} disabled={disabled} />
        </Item>
      </Col>
      <Col span={24}>
        <Card title={<FormattedMessage id="OutputRTMPFormItem.video" defaultMessage="Video" />}>
          <Row gutter={[24, 24]}>
            <SelectStream
              disabled={disabled}
              fieldPrefix={[...prefix, "videoStream", "selectStream"]}
              form={form}
              prefix={[...prefix, "videoStream", "selectStream"]}
            />
            <StreamCodec
              disabled={!!disabled}
              fieldPrefix={[...prefix, "videoStream", "codec"]}
              prefix={[...prefix, "videoStream", "codec"]}
              prePrefix={prefix}
              inputType={channelInputType}
              form={form}
            />
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card title={<FormattedMessage id="OutputRTMPFormItem.audio" defaultMessage="Audio" />}>
          <Row gutter={[24, 24]}>
            <SelectStream
              disabled={disabled}
              fieldPrefix={[...prefix, "audioStream", "selectStream"]}
              form={form}
              prefix={[...prefix, "audioStream", "selectStream"]}
            />
            <StreamCodec
              disabled={!!disabled}
              fieldPrefix={[...prefix, "audioStream", "codec"]}
              prefix={[...prefix, "audioStream", "codec"]}
              prePrefix={prefix}
              inputType={channelInputType}
              form={form}
            />
          </Row>
        </Card>
      </Col>
    </>
  );
};

OutputRTMPFormItem.propTypes = {
  channelId: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  muxedOutput: PropTypes.object,
};

OutputRTMPFormItem.defaultProps = {
  disabled: null,
  channelId: null,
  muxedOutput: null,
};
export default OutputRTMPFormItem;
