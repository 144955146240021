import React, { useContext } from "react";
import { Col, Card, Row, Form } from "antd";
import { useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import ImmutablePropTypes from "react-immutable-proptypes";

import { FormV5Context } from "../../..";
import SelectTranscodingOutputType from "../../../../SelectTranscodingOutputType";

import { OUTPUTS_TYPES } from "../../../../../lib/utils/constants";

import { selectors as CHANNEL_SELECTORS } from "../../../../../ducks/nodeChannels";

import RemoveButton from "../../../../RemoveButton";
// import OutputNDIFormItem from "../../../../OutputNDIFormItem";
import TranscodingOutputURLFormItem from "../../../../TranscodingOutputURLFormItem";
import OutputTranscodingRTMPFormItem from "../../../../OutputTranscodingRTMPFormItem";
import TranscodingOutputSDIFormItem from "../../../../TranscodingOutputSDIFormItem";
import TranscodingOutputNDIFormItemV5 from "../../../../TranscodingOutputNDIFormItemV5";

const translations = defineMessages({
  confirmRemoveText: {
    id: "EngineTranscodingFormItem.confirmRemove",
    defaultMessage: "Are you sure, you want to remove this output?",
  },
});

function EngineTranscodingFormItem({
  detailView,
  handleFinish,
  handleRemove,
  id,
  isNewOutput,
  nodeChannels,
  muxedOutput,
  prefix,
}) {
  const form = useContext(FormV5Context);
  const { formatMessage } = useIntl();
  const selectedOutputType = Form.useWatch([...prefix, "type"], form);
  const isOutputNIDType = selectedOutputType === OUTPUTS_TYPES.outputNdi.value;
  const isOutputUrlType = selectedOutputType === OUTPUTS_TYPES.outputUrl.value;
  const isOutputRTMPType = selectedOutputType === OUTPUTS_TYPES.outputRtmp.value;
  const isOutputSDIType = selectedOutputType === OUTPUTS_TYPES.outputDecklink.value;

  return (
    <StyledCard
      title={muxedOutput?.name}
      extra={
        muxedOutput?.name &&
        !detailView && (
          <RemoveButton
            handleRemove={handleRemove}
            fieldName={muxedOutput.name}
            confirmRemoveText={formatMessage(translations.confirmRemoveText)}
          />
        )
      }
    >
      <Row type="flex" justify="start" gutter={[24, 24]}>
        <Col span={12}>
          <SelectTranscodingOutputType prefix={prefix} form={form} disabled={detailView} />
        </Col>
        {isOutputRTMPType && (
          <OutputTranscodingRTMPFormItem
            disabled={detailView}
            prefix={prefix}
            form={form}
            channelId={id}
            muxedOutput={muxedOutput}
          />
        )}
        {isOutputSDIType && (
          <TranscodingOutputSDIFormItem
            detailView={detailView}
            prefix={prefix}
            form={form}
            channelId={id}
            initialOutputName={muxedOutput?.name}
            nodeChannels={nodeChannels}
            muxedOutput={muxedOutput}
          />
        )}
        {isOutputNIDType && (
          <TranscodingOutputNDIFormItemV5
            detailView={detailView}
            prefix={prefix}
            channelId={id}
            initialOutputName={muxedOutput?.name}
          />
        )}
        {isOutputUrlType && (
          <TranscodingOutputURLFormItem
            form={form}
            prefix={prefix}
            muxedOutput={muxedOutput}
            isNewOutput={isNewOutput}
            handleFinish={handleFinish}
            channelId={id}
            nodeChannels={nodeChannels}
            detailView={detailView}
          />
        )}
      </Row>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  margin-top: 10px;
`;

EngineTranscodingFormItem.propTypes = {
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  id: PropTypes.string,
  isNewOutput: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  muxedOutput: PropTypes.object,
  nodeChannels: ImmutablePropTypes.map.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

EngineTranscodingFormItem.defaultProps = {
  detailView: null,
  id: null,
  muxedOutput: null,
};

const mapStateToProps = (state) => ({
  nodeChannels: CHANNEL_SELECTORS.getChannels(state),
});

export default connect(mapStateToProps, null)(EngineTranscodingFormItem);
