import React, { useEffect } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Col, DatePicker, Row, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { QuestionCircleFilled } from "@ant-design/icons";

import store from "../../store";

import MQTTService from "../../services/mqtt";
import { DELETE_LOGS_DATA, selectors as LOGS_SELECTORS } from "../../ducks/logs";

import { MESSAGE_TYPE } from "../../lib/utils/constants";

import SelectEngine from "../SelectEngine";

const translations = defineMessages({
  selectDate: {
    id: "LogsV5.selectDate",
    defaultMessage: "Select date",
  },
});

export default function LogsV5Filters({
  channelDetailView,
  channelId,
  handleSelectChangeChannel,
  sourceParams,
  timestamp,
  setLastChannelLog,
}) {
  const { formatMessage } = useIntl();
  const { cwid } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (channelId) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_LOG_PARAM_NAMES,
          channelId,
          end: dayjs().unix(),
          start: timestamp || 0,
        },
      });
    }
  }, [channelId, timestamp]);

  const sourceOptions = useSelector(LOGS_SELECTORS.getLogsSourceParams);

  const handleChangeDate = (selectedDate) => {
    if (selectedDate) {
      setLastChannelLog(0);
      searchParams.set("jumpTo", selectedDate.unix());
      setSearchParams(searchParams);
    } else {
      setLastChannelLog(0);
      searchParams.delete("jumpTo");
      setSearchParams(searchParams);
    }
    store.dispatch(DELETE_LOGS_DATA());
  };

  const handleChangeSource = (selectedSource) => {
    if (selectedSource) {
      setLastChannelLog(0);
      searchParams.set("source", selectedSource);
      setSearchParams(searchParams);
    } else {
      setLastChannelLog(0);
      searchParams.delete("source");
      setSearchParams(searchParams);
    }
    store.dispatch(DELETE_LOGS_DATA());
  };

  return (
    <Row justify="start" gutter={24}>
      {!channelDetailView && (
        <Col>
          <FormattedMessage id="LogsV5.channel" defaultMessage="Channel" />
          {" : "}
          <SelectEngine channelId={channelId} handleSelectChange={handleSelectChangeChannel} logList />
        </Col>
      )}
      <Col>
        <Tooltip
          placement="top"
          title={<FormattedMessage id="LogsV5.jumpToTooltip" defaultMessage="Set start date filter for the logs" />}
        >
          <FormattedMessage id="LogsV5.jumpTo" defaultMessage="Jump to" /> <QuestionCircleFilled />
        </Tooltip>

        {": "}
        <DatePicker
          value={timestamp ? dayjs.unix(timestamp) : null}
          onChange={handleChangeDate}
          placeholder={formatMessage(translations.selectDate)}
          allowClear
          format="D MMM, HH:mm:ss"
          showTime={{
            defaultValue: dayjs("00:00:00", "HH:mm:ss"),
          }}
        />
      </Col>
      <Col>
        <FormattedMessage id="LogsV5.source" defaultMessage="Source" />
        {": "}
        <Select
          value={sourceParams && JSON.stringify(sourceParams)}
          options={sourceOptions}
          style={{ width: 250 }}
          popupMatchSelectWidth={false}
          allowClear
          onChange={handleChangeSource}
          showSearch
          optionFilterProp="label"
          disabled={channelId === "-1"}
        />
      </Col>
    </Row>
  );
}
