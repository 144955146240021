/* eslint-disable max-len */
import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

export default function EmergencySwitchMsecsLabel() {
  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage
          id="EmergencySwitchMsecsLabel.emergencySwitchMsecsDesc"
          defaultMessage="If both sources are not producing frames for this amount of milliseconds, then switch to emergency source"
        />
      }
    >
      <FormattedMessage id="EmergencySwitchMsecsLabel.emergencySwitchMsecs" defaultMessage="Emergency switch [ms]" />{" "}
      <QuestionCircleFilled />
    </Tooltip>
  );
}
