import React from "react";
import { Tooltip, Tag } from "antd";
import { FormattedMessage } from "react-intl";
import { ReloadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import PropTypes from "prop-types";

import { SEO_STATUS } from "../../lib/utils/constants";
import themeColors from "../../lib/style/theme";

const ChannelsValidation = ({ channelData }) => {
  const { public: isPublic, status } = channelData;

  if (!isPublic) {
    return (
      <Tag color={themeColors.lightRed}>
        <FormattedMessage id="ChannelsValidation.private" defaultMessage="Private" />
      </Tag>
    );
  }

  if (status === SEO_STATUS.REJECTED) {
    return (
      <Tag color={themeColors.red}>
        <FormattedMessage id="ChannelsValidation.rejected" defaultMessage="Rejected" />
      </Tag>
    );
  }

  if (status === SEO_STATUS.ACCEPTED) {
    return (
      <Tag color={themeColors.green}>
        <FormattedMessage id="ChannelsValidation.public" defaultMessage="Public" />
      </Tag>
    );
  }

  return (
    <Tag color={themeColors.gray}>
      <Tooltip
        title={
          <FormattedMessage
            id="general.catalogValidation"
            defaultMessage={`Please note that Quickstream verifies all data that are visible
                  in the public catalog therefore it may take a while to publish all changes in the database.`}
          />
        }
        color={themeColors.gray}
      >
        <StyledReloadOutlined spin />
        <StyledSpan>
          <FormattedMessage id="ChannelsValidation.inValidation" defaultMessage="In validation process" />
        </StyledSpan>
      </Tooltip>
    </Tag>
  );
};

const StyledReloadOutlined = styled(ReloadOutlined)`
  font-size: 12px;
`;

const StyledSpan = styled.span`
  margin-left: 5px;
`;

ChannelsValidation.propTypes = {
  channelData: PropTypes.shape({
    bgUrl: PropTypes.string,
    bitrate: PropTypes.number,
    channelName: PropTypes.string,
    channelType: PropTypes.string,
    cloudId: PropTypes.number,
    description: PropTypes.string,
    framerate: PropTypes.string,
    id: PropTypes.number.isRequired,
    public: PropTypes.bool.isRequired,
    languages: PropTypes.arrayOf(PropTypes.string),
    logoUrl: PropTypes.string,
    organizationId: PropTypes.number,
    resolutions: PropTypes.arrayOf(PropTypes.string),
    screenShotUrls: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
    subtitles: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    username: PropTypes.string,
    videoCodec: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default ChannelsValidation;
