import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Divider, Typography, Badge } from "antd";

import themeColor from "../../lib/style/theme";
import { SELECT_DEFAULT_AUTO, QUICKSTREAM_METHOD_V2 } from "../../lib/utils/constants";
import parseCloudId from "../../lib/utils/parseCloudId";

import IncompleteConfig from "../IncompleteConfig";

const { Paragraph } = Typography;
const { Ribbon } = Badge;

const InputStreamSourceElement = ({
  cloudId,
  connectionType,
  destinationAddress,
  port,
  handleClick,
  ip,
  interfaceIp,
  label,
  title,
  url,
  urlKey,
  incomplete,
}) => {
  const isQSMethod = connectionType === QUICKSTREAM_METHOD_V2.inQSDirect.value;

  const parsedInterfaceIp =
    interfaceIp && interfaceIp.toLowerCase() === SELECT_DEFAULT_AUTO.value ? SELECT_DEFAULT_AUTO.label : interfaceIp;
  const noIncomplete = !incomplete || (incomplete && incomplete.length === 0);
  const isNetworkInterfaceAutomatic = parsedInterfaceIp === SELECT_DEFAULT_AUTO.label;

  return (
    <Ribbon
      text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
      color={noIncomplete ? themeColor.green : themeColor.orange}
    >
      <StyledContainer onClick={handleClick}>
        <StyledAsideType>{label}</StyledAsideType>
        <StyledBoldText>{title}</StyledBoldText>
        <div>
          {(destinationAddress || ip) && (
            <>
              <StyledText>
                <FormattedMessage id="general.ip" defaultMessage="IP" />:
              </StyledText>
              <StyledBoldText>
                <StyledParagraph
                  onClick={(event) => event.stopPropagation()}
                  copyable={{
                    tooltips: [
                      <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                      <FormattedMessage id="general.copied" defaultMessage="Copied" />,
                    ],
                  }}
                >
                  {destinationAddress || ip}
                </StyledParagraph>
              </StyledBoldText>
              <Divider type="vertical" />
            </>
          )}
          {!isQSMethod && port && (
            <>
              <StyledText>
                <FormattedMessage id="general.port" defaultMessage="Port" />:
              </StyledText>
              {port && (
                <StyledBoldText>
                  <StyledParagraph
                    onClick={(event) => event.stopPropagation()}
                    copyable={{
                      tooltips: [
                        <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                        <FormattedMessage id="general.copied" defaultMessage="Copied" />,
                      ],
                    }}
                  >
                    {port}
                  </StyledParagraph>
                </StyledBoldText>
              )}
            </>
          )}
        </div>
        {isQSMethod && (
          <div>
            <StyledText>
              <FormattedMessage id="InputStreamSourceElement.cloudId" defaultMessage="Cloud ID" />:
            </StyledText>
            {cloudId && <StyledBoldText>{parseCloudId(cloudId)}</StyledBoldText>}
          </div>
        )}
        {parsedInterfaceIp && (
          <div>
            <StyledText>
              <FormattedMessage id="InputStreamSourceElement.networkInterface" defaultMessage="Network Interface" />:
            </StyledText>
            <StyledBoldText>
              {isNetworkInterfaceAutomatic && parsedInterfaceIp}
              {!isNetworkInterfaceAutomatic && (
                <StyledParagraph
                  onClick={(event) => event.stopPropagation()}
                  copyable={{
                    tooltips: [
                      <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                      <FormattedMessage id="general.copied" defaultMessage="Copied" />,
                    ],
                  }}
                >
                  {parsedInterfaceIp}
                </StyledParagraph>
              )}
            </StyledBoldText>
          </div>
        )}
        {url && (
          <div>
            <StyledText>
              <FormattedMessage id="general.url" defaultMessage="Url" />:
            </StyledText>
            <StyledBoldText>{url}</StyledBoldText>
          </div>
        )}
        {urlKey && (
          <div>
            <StyledText>
              <FormattedMessage id="InputStreamSourceElement.streamNameOrKey" defaultMessage="Stream name or Key" />:
            </StyledText>
            <StyledBoldText>{urlKey}</StyledBoldText>
          </div>
        )}
      </StyledContainer>
    </Ribbon>
  );
};

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledParagraph = styled(Paragraph)`
  display: inherit;

  & .anticon-copy {
    color: ${themeColor.orange};
  }
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 105px;
  margin: 0 0 15px 30px;
  padding: 10px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  color: #fff;
  padding: 5px;
  line-height: 24px;
  position: absolute;
  top: 104px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 105px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;

InputStreamSourceElement.propTypes = {
  connectionType: PropTypes.string,
  destinationAddress: PropTypes.string,
  // destinationPort: PropTypes.number,
  handleClick: PropTypes.func.isRequired,
  interfaceIp: PropTypes.string,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  urlKey: PropTypes.string,
  ip: PropTypes.string,
  incomplete: PropTypes.arrayOf(PropTypes.number),
};

InputStreamSourceElement.defaultProps = {
  connectionType: null,
  destinationAddress: null,
  // destinationPort: null,
  interfaceIp: null,
  url: null,
  urlKey: null,
  ip: null,
  incomplete: null,
};

export default InputStreamSourceElement;
