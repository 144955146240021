import React from "react";
import { useSelector } from "react-redux";
import { Row, Divider, Button, Form } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { actions as NODE_ACTIONS } from "ducks/node";
import { selectors as MQTT_SELECTORS } from "ducks/mqtt";

import NodeService from "services/node";
import MQTTService from "services/mqtt";

import store from "../../store";

import defaultDirectOutput from "../../lib/utils/defaultValues/defaultDirectOutput";
import {
  CONNECTION_METHOD_OUTPUT,
  OUTPUTS_TYPE,
  DIRECT_ENGINE_OUTPUTS_PREFIX,
  CONNECTION_TYPE,
  MESSAGE_TYPE,
  QUICKSTREAM_METHOD_V2,
} from "../../lib/utils/constants";
import { errorNotification } from "../../lib/utils/notification";
import useNodeStunAddress from "../../hooks/useNodeStunAddress";

import OutputFormItem from "../NodeChannelFormV5/OutputFormItem";

export default function BasicModeOutput({
  handlePrevStep,
  generalSettings,
  inputSettings,
  selectedNode,
  setOpenModal,
  setCurrentStep,
}) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const nodesList = useSelector(MQTT_SELECTORS.getNodes);
  const stundAddress = useNodeStunAddress();

  const handleFinish = async (formData) => {
    const parsedChannelConfig = {
      ...generalSettings,
      ...inputSettings,
      ...formData,
    };

    const newPermissionIdToConnect = inputSettings?.input?.mainConnection?.permissionId;

    const channelData = {
      cnn: selectedNode?.cnn,
      cwid: selectedNode.cwid,
      permissionId: inputSettings?.input?.mainConnection?.permissionId,
      type: CONNECTION_TYPE.NODE_DIRECT,
    };

    await NodeService.connectFingerprintToPermission(channelData, {
      errorNotification: errorNotification(formatMessage),
    });

    const newCloudIds = new Set();

    if (formData.outputs) {
      formData.outputs.forEach((output) => {
        if (output.type === QUICKSTREAM_METHOD_V2.outQSDirect.value) {
          newCloudIds.add(output.cloudId);
        }

        if (output?.urlDestinations) {
          output?.urlDestinations.forEach((outputDestination) => {
            const newCloudId = outputDestination?.cloudId;
            if (newCloudId) {
              newCloudIds.add(outputDestination?.cloudId);
            }
          });
        }
      });
    }

    if (newCloudIds.size > 0) {
      const outputCloudData = {
        cnn: selectedNode?.cnn,
        cwid: selectedNode.cwid,
        cloudIds: Array.from(newCloudIds),
        type: CONNECTION_TYPE.NODE_CLOUD,
      };

      await NodeService.connectFingerprintToChannel(outputCloudData, {
        errorNotification: errorNotification(formatMessage),
      });
    }

    MQTTService.sendMessageV5({
      topic: `node/${selectedNode.cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.ADD_NODE_CHANNEL,
        channelConfig: JSON.stringify(parsedChannelConfig),
        newCloudIdsToConnect: Array.from(newCloudIds),
        newPermissionIdToConnect,
      },
    });

    const nodeData = nodesList.find((node) => node.cwid === selectedNode.cwid);
    store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    setOpenModal(false);
    setCurrentStep(0);
  };

  const initialFormValues = {
    outputs: [
      {
        ...defaultDirectOutput(CONNECTION_METHOD_OUTPUT.outUdp.value),
        outputName: `Output-QS-${inputSettings?.input?.mainConnection?.permissionId}`,
      },
    ],
  };

  return (
    <Form onFinish={handleFinish} form={form} initialValues={initialFormValues}>
      <OutputFormItem
        index={0}
        outputType={OUTPUTS_TYPE.DIRECT}
        prefix={[DIRECT_ENGINE_OUTPUTS_PREFIX]}
        setDisableCondition={() => null}
        form={form}
        stundAddress={stundAddress}
        basicMode
      />
      <Divider />
      <Row justify="end">
        <Button style={{ margin: "0 8px" }} onClick={handlePrevStep}>
          <FormattedMessage id="general.previous" defaultMessage="Previous" />
        </Button>
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="general.done" defaultMessage="Done" />
        </Button>
      </Row>
    </Form>
  );
}

BasicModeOutput.propTypes = {
  handlePrevStep: PropTypes.func.isRequired,
  generalSettings: PropTypes.object.isRequired,
  inputSettings: PropTypes.object.isRequired,
  selectedNode: PropTypes.object,
  setOpenModal: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

BasicModeOutput.defaultProps = {
  selectedNode: null,
};
