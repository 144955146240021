import React from "react";
import { Row, Col } from "antd";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import InputNumberField from "../../../../Fields/InputNumberField";
import InputTextField from "../../../../Fields/InputTextField";

import { IPFieldV5, PortField } from "../../../../Fields/Fields";

import globalTranslations from "../../../../../lib/translations";

function InputRTP({ disabled, prefix }) {
  const { formatMessage } = useIntl();

  return (
    <Row gutter={24}>
      <InputNumberField name={[...prefix, "inputId"]} disabled={disabled} hidden />
      <InputTextField name={[...prefix, "inputName"]} disabled={disabled} hidden />
      <Col md={24} lg={12}>
        <IPFieldV5 disabled={disabled} label={formatMessage(globalTranslations.addressIp)} name={[...prefix, "ip"]} />
      </Col>
      <Col md={24} lg={12}>
        <PortField name={[...prefix, "port"]} disabled={disabled} />
      </Col>
    </Row>
  );
}

InputRTP.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

InputRTP.defaultProps = {
  disabled: null,
};

export default InputRTP;
