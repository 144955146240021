import React, { useEffect } from "react";
import { Card, Spin, Skeleton } from "antd";
import { connect, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import store from "../../store";

import { actions as NODE_ACTIONS, selectors as NODE_SELECTORS } from "../../ducks/node";
import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";

import NodeChannelFormV4 from "../../components/NodeChannelFormV4";
import NodeChannelFormV5 from "../../components/NodeChannelFormV5";
import GlobalErrorBoundaries from "../../components/ErrorBoundaries/GlobalErrorBoundaries";

import { MQTT_STATUS } from "../../lib/utils/constants";
import useNodeStunAddress from "../../hooks/useNodeStunAddress";
import useNodeChannels from "../../hooks/useNodeChannels";
import useNodeCapabilities from "../../hooks/useNodeCapabilities";
import useNodeFingerprint from "../../hooks/useNodeFingerprint";
import useNodeVersion from "../../hooks/useNodeVersion";

const ChannelEdit = ({ detailView, nodes, MQTTConnection, MQTTStatus }) => {
  const { id, cwid } = useParams();

  const nodeChannels = useNodeChannels();
  const channelConfig = nodeChannels.getIn([`${id}`, "config"]);
  const nodeData = nodes.find((node) => node.cwid === cwid);
  const isStundAddress = useSelector(NODE_SELECTORS.getIsStundAddress);
  useNodeStunAddress();
  useNodeCapabilities();
  const fingerprint = useNodeFingerprint();
  const { isV5Node, isV4Node } = useNodeVersion();

  const isConnection = MQTTConnection && MQTTStatus === MQTT_STATUS.CONNECTED;

  useEffect(() => {
    if (!detailView && isConnection) {
      store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    }
  }, [detailView, cwid, isConnection, nodeData]);

  if (!channelConfig || !isStundAddress) {
    return (
      <Spin tip={<FormattedMessage id="ChannelEdit.loadingChannelData" defaultMessage="Loading channel data..." />}>
        <Skeleton active />
      </Spin>
    );
  }

  if (!fingerprint) {
    return (
      <Spin
        tip={
          <FormattedMessage id="ChannelEdit.waitingForFingerprint" defaultMessage="Waiting for node fingerprint..." />
        }
      >
        <Skeleton active />
      </Spin>
    );
  }

  return (
    <GlobalErrorBoundaries>
      <Card>
        {isV4Node && <NodeChannelFormV4 channelConfig={channelConfig} id={id} editMode />}
        {isV5Node && <NodeChannelFormV5 channelConfig={channelConfig} id={id} editMode />}
      </Card>
    </GlobalErrorBoundaries>
  );
};

const mapStateToProps = (state) => ({
  nodes: MQTT_SELECTORS.getNodes(state),
  MQTTConnection: MQTT_SELECTORS.getMqttConnection(state),
  MQTTStatus: MQTT_SELECTORS.getStatus(state),
});

export default connect(mapStateToProps, null)(ChannelEdit);
