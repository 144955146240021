import React from "react";
import { Row } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import ProbeDrawerElementText from "../ProbeDrawerElementText";

const ProbeDrawerElementStreamUN = ({ data }) => {
  return (
    <Row justify="center">
      <ProbeDrawerElementText
        data={data?.pid}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.pid" defaultMessage="PID" />}
        divided
      />
      <ProbeDrawerElementText
        data={data?.id}
        text={<FormattedMessage id="ProbeDrawerElementStreamVideo.id" defaultMessage="ID" />}
        divided
      />
    </Row>
  );
};

ProbeDrawerElementStreamUN.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pid: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProbeDrawerElementStreamUN;
