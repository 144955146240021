import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

export default function BackupSwitchMsecsLabel() {
  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage
          id="BackupSwitchMsecsLabel.backupSwitchTimeDesc"
          // eslint-disable-next-line max-len
          defaultMessage="If main source is not producing frames for this amount of milliseconds, then switch to backup source"
        />
      }
    >
      <FormattedMessage id="BackupSwitchMsecsLabel.backupSwitchTime" defaultMessage="Switch time [ms]" />{" "}
      <QuestionCircleFilled />
    </Tooltip>
  );
}
