import {
  MPEG2PROFILE,
  SELECT_STREAM_CODEC,
  X264_PRESET,
  X264_TUNE,
  X264_PROFILE,
  X264_LEVEL,
  OUTPUT_RTMP_PIX_FORMAT,
} from "./constants";

const defaultVideoFormat = {
  resize: "pad",
  presetId: 1,
  width: 1920,
  height: 1080,
  pixFormat: "YUV420P",
  customSar: false,
  sarDen: 37,
  sarNum: 64,
};

export default ({ codecType, setFieldValue, prefix, isOutputRtmp }) => {
  const isMpeg2videoType = codecType === SELECT_STREAM_CODEC[1].value;
  const isOpenH264Type = codecType === SELECT_STREAM_CODEC[2].value;
  const isH264Type = codecType === SELECT_STREAM_CODEC[3].value;

  if (isMpeg2videoType) {
    const codec = {
      pid: 0,
      mpeg2Profile: MPEG2PROFILE[0].value,
      interlaced: 0,
      changeGOP: false,
      type: codecType,
      ...defaultVideoFormat,
      pixFormat: "YUV420P",
    };
    setFieldValue(prefix, codec);
  }

  if (isOpenH264Type) {
    const codec = {
      pid: 0,
      changeGOP: false,
      type: codecType,
      ...defaultVideoFormat,
      pixFormat: isOutputRtmp ? OUTPUT_RTMP_PIX_FORMAT[0].value : "YUV420P",
    };
    setFieldValue(prefix, codec);
  }

  if (isH264Type) {
    const codec = {
      pid: 0,
      mpeg2Profile: MPEG2PROFILE[0].value,
      interlaced: 0,
      changeGOP: false,
      x264Preset: X264_PRESET[2].value,
      x264Tune: X264_TUNE[0].value,
      x264Profile: X264_PROFILE[3].value,
      x264Level: X264_LEVEL[0].value,
      x264VbvBufsize: 4,
      type: codecType,
      ...defaultVideoFormat,
      pixFormat: isOutputRtmp ? OUTPUT_RTMP_PIX_FORMAT[0].value : "YUV420P",
    };
    setFieldValue(prefix, codec);
  }
};
