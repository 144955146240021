import React, { useContext } from "react";
import { Col, Form, Select, Card, Row, Switch, Divider } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import { required } from "../../lib/utils/formValidation";
import NDIVideoDefaultValues from "../../lib/utils/defaultValues/NDIVideoDefaultValues";
import {
  NDI_SELECT_FPS,
  OUTPUT_NDI_PIXEL_FORMATS,
  SELECT_STREAM_CODEC_RESIZE,
  SELECT_DEFAULT_AUTO,
  SELECT_MPEG2VIDEO_PRESETS,
  MPEG2VIDEO_PRESETS,
} from "../../lib/utils/constants";
import globalTranslations from "../../lib/translations";

import InputNumberField from "../Fields/InputNumberField";
import useNodeVersion from "../../hooks/useNodeVersion";

import { FormV5Context } from "../NodeChannelFormV5";
import InterlacedField from "../InterlacedField";

const { Item } = Form;
const { Option } = Select;

const HiQualityPresets = [
  SELECT_MPEG2VIDEO_PRESETS[0].value,
  SELECT_MPEG2VIDEO_PRESETS[1].value,
  SELECT_MPEG2VIDEO_PRESETS[2].value,
];

const translations = defineMessages({
  sarNum: {
    id: "NDIVideoFormat.sarNum",
    defaultMessage: "Video Sample Ratio (sarNum)",
  },
  sarDen: {
    id: "NDIVideoFormat.sarDen",
    defaultMessage: "Video Sample Ratio (sarDen)",
  },
});

const NDIVideoFormat = ({ prefix, disabled }) => {
  const { formatMessage } = useIntl();
  const { isV4Node } = useNodeVersion();
  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldValue } = form;

  const selectedCodecResize = Form.useWatch([...prefix, "resize"], form);
  const isSelectedCodecResizeAuto = selectedCodecResize === SELECT_DEFAULT_AUTO.value;

  const selectedCodecPresetId = Form.useWatch([...prefix, "presetId"], form);
  const isSelectedCodecCustomPresetId = selectedCodecPresetId === SELECT_MPEG2VIDEO_PRESETS[7].value;

  const customSarValue = !!Form.useWatch([...prefix, "customSar"], form);
  const isSelectedCodecHIPresetId = HiQualityPresets.includes(selectedCodecPresetId);

  const handleChangeCodecResize = () => {
    const videoFormat = getFieldValue(prefix);
    setFieldValue(prefix, {
      ...videoFormat,
      presetId: 1,
      width: MPEG2VIDEO_PRESETS.preset4K.width,
      height: MPEG2VIDEO_PRESETS.preset4K.height,
      customSar: MPEG2VIDEO_PRESETS.preset4K.customSar,
    });
  };

  const handleChangePresetId = (selectedPreset) => {
    const presetDefaultValues = NDIVideoDefaultValues(selectedPreset);

    const videoFormat = getFieldValue(prefix);
    setFieldValue(prefix, {
      ...videoFormat,
      ...presetDefaultValues,
    });
  };

  const handleChangeCustomSar = (selectedCustomSar) => {
    const videoFormat = getFieldValue(prefix);
    setFieldValue(prefix, {
      ...videoFormat,
      customSar: selectedCustomSar,
      sarNum: null,
      sarDen: null,
    });
  };

  const PARSED_SELECT_STREAM_CODEC_RESIZE = isV4Node
    ? SELECT_STREAM_CODEC_RESIZE
    : SELECT_STREAM_CODEC_RESIZE.filter((_, i) => i !== 0);

  return (
    <StyledCard title={<FormattedMessage id="NDIVideoFormat.videoFormat" defaultMessage="Video format" />}>
      <Row gutter={24}>
        <>
          <Col span={8}>
            <Item
              name={[...prefix, "fps"]}
              label={<FormattedMessage id="NDIVideoFormat.fps" defaultMessage="Frame rate" />}
              rules={[required]}
            >
              <Select disabled={disabled}>
                {NDI_SELECT_FPS.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={8}>
            <InterlacedField prefix={prefix} disabled={disabled} />
          </Col>
        </>

        <Col span={8}>
          <Item
            name={[...prefix, "pixFormat"]}
            label={<FormattedMessage id="NDIVideoFormat.pixFormat" defaultMessage="Pixel Format" />}
            rules={[required]}
          >
            <Select disabled={disabled}>
              {OUTPUT_NDI_PIXEL_FORMATS.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Divider />
        <Col span={8}>
          <Item
            name={[...prefix, "resize"]}
            label={<FormattedMessage id="NDIVideoFormat.resize" defaultMessage="Scale video" />}
            rules={[required]}
          >
            <Select onChange={handleChangeCodecResize} disabled={disabled}>
              {PARSED_SELECT_STREAM_CODEC_RESIZE.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        {!isSelectedCodecResizeAuto && (
          <>
            <Divider />
            <Col span={24}>
              <Item
                name={[...prefix, "presetId"]}
                label={<FormattedMessage id="NDIVideoFormat.presetId" defaultMessage="Encoding Preset" />}
                rules={[required]}
              >
                <Select onChange={handleChangePresetId} disabled={disabled}>
                  {SELECT_MPEG2VIDEO_PRESETS.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={8}>
              <InputNumberField
                name={[...prefix, "width"]}
                label={formatMessage(globalTranslations.width)}
                rules={[required]}
                disabled={!isSelectedCodecCustomPresetId || disabled}
              />
            </Col>
            <Col span={8}>
              <InputNumberField
                name={[...prefix, "height"]}
                label={formatMessage(globalTranslations.height)}
                rules={[required]}
                disabled={!isSelectedCodecCustomPresetId || disabled}
              />
            </Col>
            <Col span={8}>
              <Item
                name={[...prefix, "customSar"]}
                valuePropName="checked"
                label={
                  <FormattedMessage id="NDIVideoFormat.customSar" defaultMessage="Custom video sample aspect ratio" />
                }
              >
                <Switch
                  disabled={!isSelectedCodecCustomPresetId || disabled}
                  defaultChecked={customSarValue}
                  checkedChildren={formatMessage(globalTranslations.on)}
                  unCheckedChildren={formatMessage(globalTranslations.off)}
                  onChange={handleChangeCustomSar}
                />
              </Item>
            </Col>
            {!isSelectedCodecHIPresetId && customSarValue && (
              <>
                <Col span={8}>
                  <InputNumberField
                    name={[...prefix, "sarNum"]}
                    label={formatMessage(translations.sarNum)}
                    rules={[required]}
                    disabled={!isSelectedCodecCustomPresetId || disabled}
                  />
                </Col>
                <Col span={8}>
                  <InputNumberField
                    name={[...prefix, "sarDen"]}
                    label={formatMessage(translations.sarDen)}
                    rules={[required]}
                    disabled={!isSelectedCodecCustomPresetId || disabled}
                  />
                </Col>
              </>
            )}
          </>
        )}
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
`;

NDIVideoFormat.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  disabled: PropTypes.bool,
};

NDIVideoFormat.defaultProps = {
  disabled: null,
};

export default NDIVideoFormat;
