import { Dropdown, Button } from "antd";
import React, { useCallback, useState } from "react";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { actions as NAVIGATION_ACTIONS } from "../../ducks/navigation";
import useRole from "../../hooks/useRole";

import store from "../../store";
import { selectors as ACCOUNT_SELECTORS, actions as ACCOUNT_ACTIONS } from "../../ducks/account";
import { CLEAR_ORGANIZATIONS_DATA } from "../../ducks/organizations";
import { actions as ADMIN_ACTIONS } from "../../ducks/admin";

import MQTTService from "../../services/mqtt";

import TutorialsModal from "../TutorialsModal";
import BillingModal from "../BillingModal";
import themeColor from "../../lib/style/theme";
import { AuthAPI } from "../../lib/api";
import { errorNotification } from "../../lib/utils/notification";

const UserMenu = ({ username }) => {
  const navigate = useNavigate();
  const { isAdmin } = useRole();
  const [showTutorialsModal, setShowTutorialsModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const isStripeCustomer = useSelector(ACCOUNT_SELECTORS.getStripeCustomer);
  const { formatMessage } = useIntl();

  const onLogout = () => {
    window.localStorage.removeItem("authToken");
    window.localStorage.removeItem("paginationNotification");
    store.dispatch(ACCOUNT_ACTIONS.CLEAR_ACCOUNT_DATA());
    store.dispatch(CLEAR_ORGANIZATIONS_DATA());
    store.dispatch(NAVIGATION_ACTIONS.CLEAR_NAVIGATION());
    if (isAdmin) store.dispatch(ADMIN_ACTIONS.CLEAR_ACCOUNTS_LIST());
    MQTTService.disconnect();
    navigate("/login");
  };

  const onAccount = () => {
    store.dispatch(NAVIGATION_ACTIONS.CLEAR_ACTIVE_KEY());
    navigate("/account");
  };

  const handleShowTutorialsModal = useCallback(() => {
    setShowTutorialsModal(!showTutorialsModal);
  }, [showTutorialsModal]);

  const handleClickBilling = useCallback(() => {
    if (isStripeCustomer) {
      AuthAPI.stripeRotateToken({ errorNotification: errorNotification(formatMessage), setShowBillingModal });
    } else {
      setShowBillingModal(!showBillingModal);
    }
  }, [showBillingModal, isStripeCustomer]);

  const handleCloseBillingModal = () => {
    setShowBillingModal(!showBillingModal);
  };

  const items = [
    {
      key: "/account",
      label: (
        <Button type="link" onClick={onAccount}>
          <FormattedMessage id="userMenu.account" defaultMessage="Account" />
        </Button>
      ),
    },
    {
      key: "/tutorials",
      label: (
        <Button type="link" onClick={handleShowTutorialsModal}>
          <FormattedMessage id="userMenu.tutorials" defaultMessage="Tutorials" />
        </Button>
      ),
    },
    {
      key: "/billing",
      label: (
        <Button type="link" onClick={handleClickBilling}>
          <FormattedMessage id="userMenu.billing" defaultMessage="Billing" />
        </Button>
      ),
    },
    {
      key: "/logout",
      label: (
        <Button type="link" onClick={onLogout}>
          <FormattedMessage id="userMenu.logout" defaultMessage="Logout" />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <StyledUsername>
          {username} <StyledDownOutlined />
        </StyledUsername>
      </Dropdown>
      <TutorialsModal handleShowTutorialsModal={handleShowTutorialsModal} showTutorialsModal={showTutorialsModal} />
      <BillingModal showBillingModal={showBillingModal} handleCloseBillingModal={handleCloseBillingModal} />
    </>
  );
};

UserMenu.propTypes = {
  username: PropTypes.string.isRequired,
};

const StyledDownOutlined = styled(DownOutlined)`
  color: ${themeColor.orange};
`;

const StyledUsername = styled.span`
  min-width: 85px;
  padding: 0 15px;
  cursor: pointer;
  color: #fff;
`;

const mapStateToProps = (state) => ({
  username: ACCOUNT_SELECTORS.getUser(state),
});

export default connect(mapStateToProps, null)(UserMenu);
