import { EMERGENCY_MODE } from "./constants";

export default function parseEmergencyModeName(mode) {
  switch (mode) {
    case EMERGENCY_MODE.NONE:
      return "Black screen";
    case EMERGENCY_MODE.STATIC:
      return "Static screen";
    case EMERGENCY_MODE.LOOP:
      return "Looped video";
    default:
      return "Not supported mode";
  }
}
