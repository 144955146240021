import React, { useCallback } from "react";
import { Row, Col, Button } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { CloudSyncOutlined } from "@ant-design/icons";

import MQTTService from "../../../services/mqtt";
import { MESSAGE_TYPE } from "../../../lib/utils/constants";

import NodeLicenseFingerprint from "./NodeLicenseFingerprint";

const NodeLicenseListCardTitle = ({ fingerprint, cwid }) => {
  const getLicensesFromCloud = useCallback(() => {
    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.FORCE_DOWNLOAD_LICENSES,
      },
    });
  }, [cwid]);

  return (
    <Row type="flex" justify="space-between" align="middle">
      <Col>
        <FormattedMessage id="NodeLicenseListCardTitle.fingerprint" defaultMessage="Hardware ID" />
        <NodeLicenseFingerprint fingerprint={fingerprint} />
      </Col>
      <Col>
        <Button type="primary" onClick={getLicensesFromCloud} icon={<CloudSyncOutlined />}>
          <span>
            <FormattedMessage id="NodeLicenseListCardTitle.cloudLicenses" defaultMessage="Get licenses from Cloud" />
          </span>
        </Button>
      </Col>
    </Row>
  );
};

NodeLicenseListCardTitle.propTypes = {
  cwid: PropTypes.string.isRequired,
  fingerprint: PropTypes.string,
};

NodeLicenseListCardTitle.defaultProps = {
  fingerprint: null,
};

export default NodeLicenseListCardTitle;
