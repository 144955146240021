import React, { useCallback, useState } from "react";
import { Form, Card, Col, Button } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";

import CloudChannelsServices from "../../services/cloudChannels";

import { errorNotification, successNotification } from "../../lib/utils/notification";

import PermissionDynamicForm from "./PermissionDynamicForm";

export default function HubChannelShare({ fetchChannels, channelData }) {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { resetFields, setFieldsValue, getFieldValue, validateFields } = form;

  const userForm = Form.useWatch("users", form);

  const handleNewShare = useCallback(
    async ({ users }) => {
      if (users && users.length > 0) {
        setLoading(true);
        await CloudChannelsServices.addChannelPermission(
          { users, cloudId: channelData.id },
          {
            errorNotification: errorNotification(formatMessage),
            successNotification: successNotification(formatMessage),
            setLoading,
          }
        );
        resetFields();
        fetchChannels();
      }
    },
    [channelData, fetchChannels, formatMessage, resetFields]
  );

  return (
    <Col xs={24} sm={24} md={24} lg={12}>
      <Form onFinish={handleNewShare} form={form}>
        <StyledCard
          title={<FormattedMessage id="HubChannelShare.shareWith" defaultMessage="Share with existing user" />}
        >
          <PermissionDynamicForm
            channelName={channelData?.channelName}
            setFieldsValue={setFieldsValue}
            getFieldValue={getFieldValue}
            loading={loading}
            setLoading={setLoading}
            validateForm={validateFields}
            form={form}
          />
          <Button type="primary" htmlType="submit" disabled={loading || !userForm} block>
            <span>
              <FormattedMessage id="HubChannelShare.share" defaultMessage="Share" />
            </span>
          </Button>
        </StyledCard>
      </Form>
    </Col>
  );
}

const StyledCard = styled(Card)`
  width: 100%;
`;
