import { INPUT_TYPES, OUTPUTS_TYPES } from "./constants";

export default ({ inputType, outputType }) => {
  if (inputType === INPUT_TYPES.inputUrl.value && outputType === OUTPUTS_TYPES.outputUrl.value) {
    return false;
  }

  if (inputType === INPUT_TYPES.inputMpegtsOverRtp.value && outputType === OUTPUTS_TYPES.outputUrl.value) {
    return false;
  }

  return true;
};
