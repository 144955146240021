import React, { useState, useEffect } from "react";
import { Table, Divider, Affix, Button, Modal, Typography, Tag, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import dayjs from "dayjs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import LicenseServices from "../../services/license";
import { selectors as LICENSES_SELECTORS } from "../../ducks/license";

import { errorNotification } from "../../lib/utils/notification";
import themeColor from "../../lib/style/theme";

import NewPlayoutLicenseForm from "./NewPlayoutLicenseForm";
import LicenseActionButtons from "../../pages/Admin/License/LicenseActionButtons";

const { Paragraph } = Typography;

export default function PlayoutLicenses() {
  const [showNewLicenseModal, setShowNewLicenseModal] = useState(false);
  const playoutLicenses = useSelector(LICENSES_SELECTORS.getPlayoutLicense);
  const loadingLicenses = useSelector(LICENSES_SELECTORS.getLoadingLicense);

  useEffect(() => {
    LicenseServices.getPlayoutLicenseList({ errorNotification });
  }, []);

  const columns = [
    {
      title: <FormattedMessage id="PlayoutLicenses.licenseId" defaultMessage="License" />,
      dataIndex: "licenseId",
      key: "licenseId",
      sorter: (currentValue, nextValue) => currentValue.licenseId.localeCompare(nextValue.licenseId),
    },
    {
      title: <FormattedMessage id="PlayoutLicenses.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
      sorter: (currentValue, nextValue) => currentValue.username.localeCompare(nextValue.username),
      render: (username) => {
        return <Link to={`/users/${username}`}>{username}</Link>;
      },
    },
    {
      title: <FormattedMessage id="PlayoutLicenses.channelName" defaultMessage="Channel name" />,
      dataIndex: "channelName",
      key: "channelName",
      sorter: (currentValue, nextValue) => currentValue.channelName.localeCompare(nextValue.channelName),
    },
    {
      title: <FormattedMessage id="PlayoutLicenses.companyName" defaultMessage="Company name" />,
      dataIndex: "companyName",
      key: "companyName",
      sorter: (currentValue, nextValue) => currentValue.companyName.localeCompare(nextValue.companyName),
    },
    {
      title: <FormattedMessage id="PlayoutLicenses.expireDate" defaultMessage="Expire date" />,
      dataIndex: "expireDate",
      key: "expireDate",
      width: 110,
      render: (data) => {
        const today = dayjs().format("YYYY-MM-DD");
        const licenseExpired = dayjs(data).isBefore(today);

        return licenseExpired ? (
          <>
            {dayjs(data).format("YYYY-MM-DD")}{" "}
            <Tooltip title={<FormattedMessage id="PlayoutLicenses.licenseExpired" defaultMessage="License expired" />}>
              <ExclamationCircleOutlined style={{ color: `${themeColor.red}` }} />
            </Tooltip>
          </>
        ) : (
          dayjs(data).format("YYYY-MM-DD")
        );
      },
    },

    {
      title: <FormattedMessage id="PlayoutLicenses.encryptedLicense" defaultMessage="Encrypted license" />,
      dataIndex: "encryptedLicense",
      key: "encryptedLicense",
      width: 70,
      align: "center",
      render: (data) => {
        return (
          <span>
            <StyledParagraph
              onClick={(event) => event.stopPropagation()}
              copyable={{
                text: data,
                tooltips: [
                  <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                  <FormattedMessage id="general.copied" defaultMessage="Copied" />,
                ],
              }}
            />
          </span>
        );
      },
    },
    {
      title: <FormattedMessage id="PlayoutLicenses.terminated" defaultMessage="Terminated" />,
      dataIndex: "terminated",
      key: "terminated",
      align: "center",
      width: 90,
      render: (terminated) =>
        terminated ? (
          <Tag color={themeColor.red}>
            <FormattedMessage id="general.true" defaultMessage="True" />
          </Tag>
        ) : (
          <Tag color={themeColor.green}>
            <FormattedMessage id="general.false" defaultMessage="False" />
          </Tag>
        ),
    },
    {
      title: <FormattedMessage id="PlayoutLicenses.actions" defaultMessage="Actions" />,
      key: "id",
      align: "center",
      width: 90,
      render: ({ id }) => <LicenseActionButtons licenseId={id} playoutLicenses />,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={playoutLicenses}
        pagination={false}
        bordered
        role="table"
        rowKey={(record) => record.id}
        scroll={{ x: "calc(100vh - 250px)" }}
        loading={loadingLicenses}
      />
      <Divider />
      <Affix offsetBottom={15}>
        <Button block type="primary" onClick={() => setShowNewLicenseModal(true)} disabled={loadingLicenses}>
          <FormattedMessage id="PlayoutLicenses.addNewPlayoutLicense" defaultMessage="Add new playout license" />
        </Button>
      </Affix>
      <Modal
        title={<FormattedMessage id="PlayoutLicenses.newLicense" defaultMessage="New license" />}
        open={showNewLicenseModal}
        footer={null}
        onCancel={() => setShowNewLicenseModal(false)}
      >
        <NewPlayoutLicenseForm setShowNewLicenseModal={setShowNewLicenseModal} />
      </Modal>
    </>
  );
}

const StyledParagraph = styled(Paragraph)`
  display: inherit;

  & .anticon-copy {
    color: ${themeColor.orange};
  }
`;
