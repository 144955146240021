import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Modal, Button, Row, Col, Empty, Spin } from "antd";
import { PlusOutlined, WarningOutlined, SaveOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";

import { warningNotification } from "../../../../lib/utils/notification";
import { NEW } from "../../../../lib/utils/constants";
// import DEFAULT_MUXED_OUTPUT from "../../../lib/utils/defaultValues/defaultMuxedOutput";
import DEFAULT_TRANSCODED_OUTPUT from "../../../../lib/utils/defaultValues/defaultTranscodedOutput";

import EngineTranscodingFormItem from "./EngineTranscodingFormItem";
import EngineTranscodingOutputElement from "./EngineTranscodingOutputElement";
import EngineTranscodingOutputElementLabel from "./EngineTranscodingOutputElementLabel";

import { FormV5Context } from "../../NodeChannelFormV5";
import useNodeChannelConfig from "../../../../hooks/useNodeChannelConfig";

const translations = defineMessages({
  formValidationError: {
    id: "general.formValidationError",
    defaultMessage: "Form validation error: Please check all fields",
  },
});

function EngineTranscodingSection({ detailView, handleFinish, id, isInput }) {
  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldValue, setFieldsValue, resetFields, validateFields } = form;
  const { outputs: channelOutputs } = useNodeChannelConfig();

  const [outputSectionVisible, setOutputSectionVisible] = useState(null);
  const { formatMessage } = useIntl();

  const numberOfTransOutputs = channelOutputs ? channelOutputs.length : 0;

  const handleAddNewOutput = () => {
    const numberOfOutputs = channelOutputs.length + 1;
    const parsedOutputs = [...channelOutputs, { ...DEFAULT_TRANSCODED_OUTPUT, name: `Output-${numberOfOutputs}` }];

    setFieldsValue({ outputs: parsedOutputs });
    setOutputSectionVisible(NEW);
  };

  const handleOKOutput = async () => {
    try {
      await validateFields();

      const updatedFormData = getFieldValue();
      handleFinish(updatedFormData);
      setOutputSectionVisible(null);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error - formValidation", error);
      warningNotification(formatMessage)(translations.formValidationError);
    }
  };

  const handleCancelOutput = () => {
    resetFields();
    setOutputSectionVisible(null);
  };

  const handleRemoveOutput = (name) => {
    const channelConfig = getFieldValue();
    const parsedFormOutputs = channelOutputs.filter((transOutput) => transOutput.name !== name);
    const updatedFormData = {
      ...channelConfig,
      outputs: parsedFormOutputs,
    };

    handleFinish(updatedFormData);
    resetFields();
    setFieldValue("outputs", parsedFormOutputs);
    setOutputSectionVisible(null);
  };

  if (numberOfTransOutputs === 0 && detailView) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Spin
      spinning={!isInput}
      indicator={<WarningOutlined />}
      tip={
        <FormattedMessage
          id="EngineTranscodingSection.inputNeeded"
          defaultMessage="Please define input before add outputs"
        />
      }
    >
      {channelOutputs &&
        channelOutputs.map((muxedOutput, index) => {
          const handleClick = () => {
            resetFields();
            setOutputSectionVisible(index);
          };

          return (
            <Row gutter={24} key={muxedOutput.name}>
              <Col span={24}>
                <EngineTranscodingOutputElement
                  label={<EngineTranscodingOutputElementLabel type={muxedOutput?.type} />}
                  muxedOutput={muxedOutput}
                  handleClick={handleClick}
                  incomplete={muxedOutput.incomplete}
                  type={muxedOutput?.type}
                />
                <StyledModal
                  destroyOnClose
                  zIndex={100}
                  centered
                  title={
                    <>
                      <span>
                        <FormattedMessage id="EngineTranscodingSection.transcoded" defaultMessage="Transcoded" />
                      </span>
                      {` ${muxedOutput.name}`}
                    </>
                  }
                  open={outputSectionVisible === index}
                  onOk={handleOKOutput}
                  onCancel={handleCancelOutput}
                  footer={detailView ? null : undefined}
                  okText={
                    <span>
                      <FormattedMessage id="general.save" defaultMessage="Save" />
                    </span>
                  }
                  okButtonProps={{ icon: <SaveOutlined /> }}
                >
                  <EngineTranscodingFormItem
                    detailView={detailView}
                    handleFinish={handleFinish}
                    handleRemove={handleRemoveOutput}
                    id={id}
                    prefix={["outputs", index]}
                    muxedOutput={muxedOutput}
                    isNewOutput={outputSectionVisible === NEW && setOutputSectionVisible}
                  />
                </StyledModal>
              </Col>
            </Row>
          );
        })}
      {!detailView && (
        <StyledAddButton type="dashed" icon={<PlusOutlined />} onClick={handleAddNewOutput}>
          <span>
            <FormattedMessage id="EngineTranscodingSection.addOutput" defaultMessage="Add Output" />
          </span>
        </StyledAddButton>
      )}
      {outputSectionVisible === NEW && (
        <StyledModal
          zIndex={100}
          centered
          title={
            <FormattedMessage
              id="EngineTranscodingSection.newTranscodedOutputs"
              defaultMessage="New transcoded output"
            />
          }
          open={outputSectionVisible === NEW}
          onOk={handleOKOutput}
          onCancel={handleCancelOutput}
          destroyOnClose
        >
          <EngineTranscodingFormItem
            handleFinish={handleFinish}
            handleRemove={handleRemoveOutput}
            id={id}
            prefix={["outputs", numberOfTransOutputs]}
            footer={detailView ? null : undefined}
            isNewOutput={outputSectionVisible === NEW && setOutputSectionVisible}
          />
        </StyledModal>
      )}
    </Spin>
  );
}

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledAddButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

EngineTranscodingSection.propTypes = {
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  id: PropTypes.string,
  isInput: PropTypes.bool.isRequired,
};

EngineTranscodingSection.defaultProps = {
  detailView: null,
  id: null,
};

export default EngineTranscodingSection;
