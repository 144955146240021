import React, { useState } from "react";
import { DeploymentUnitOutlined, ShareAltOutlined, ScheduleOutlined } from "@ant-design/icons";
import { Card, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import HubChannelList from "./HubChannelList";
import SharedChannelList from "./SharedChannelList";
import CreateChannelModal from "./ChannelModal/CreateChannelModal";
import AllChannelsList from "./AllChannelsList";
import GlobalErrorBoundaries from "../../components/ErrorBoundaries/GlobalErrorBoundaries";

import useRole from "../../hooks/useRole";

const TABS = {
  OWN: "own",
  SHARED: "shared",
  VERIFICATION: "verification",
};

const ChannelsListContainer = () => {
  const { isAdminOrSupport } = useRole();
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") || TABS.OWN;
  const [activeKey, setActiveKey] = useState(tab);

  const handleChangeTab = (selectedTab) => {
    setActiveKey(selectedTab);

    setSearchParams({ tab: selectedTab });
  };

  const items = [
    {
      icon: <DeploymentUnitOutlined />,
      label: (
        <span>
          <FormattedMessage id="ChannelsListContainer.myHubChannels" defaultMessage="My hub channels" />
        </span>
      ),
      key: TABS.OWN,
      children: <HubChannelList />,
    },
    {
      icon: <ShareAltOutlined />,
      label: (
        <span>
          <FormattedMessage id="ChannelsListContainer.shared" defaultMessage="Shared with me" />
        </span>
      ),
      key: TABS.SHARED,
      children: <SharedChannelList />,
    },
    ...(isAdminOrSupport
      ? [
          {
            icon: <ScheduleOutlined />,
            label: (
              <span>
                <FormattedMessage
                  id="ChannelsListContainer.channelsHubVerification"
                  defaultMessage="Hub channels verification"
                />
              </span>
            ),
            key: TABS.VERIFICATION,
            children: <AllChannelsList />,
          },
        ]
      : []),
  ];

  return (
    <GlobalErrorBoundaries>
      <Card extra={<CreateChannelModal />}>
        <Tabs activeKey={activeKey} items={items} onChange={handleChangeTab} />
      </Card>
    </GlobalErrorBoundaries>
  );
};

export default ChannelsListContainer;
