import React from "react";
import { Table, Card, Divider, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import themeColors from "../../../../lib/style/theme";

import useNodeInterfacesHook from "../../../../hooks/useNodeInterfaces";
import useNodeStunAddressHook from "../../../../hooks/useNodeStunAddress";

const { Paragraph } = Typography;

const NodeInterfaces = () => {
  const nodeInterfaces = useNodeInterfacesHook();
  const stunAddress = useNodeStunAddressHook();

  const columns = [
    {
      title: <FormattedMessage id="NodeInterfaces.name" defaultMessage="Name" />,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <FormattedMessage id="NodeInterfaces.ipAddress" defaultMessage="IP address" />,
      dataIndex: "ip",
      key: "ip",
      render: (ip) => {
        return (
          <StyledParagraph
            copyable={{
              tooltips: [
                <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                <FormattedMessage id="general.copied" defaultMessage="Copied" />,
              ],
            }}
          >
            {ip}
          </StyledParagraph>
        );
      },
    },
    {
      title: <FormattedMessage id="NodeInterfaces.macAddress" defaultMessage="MAC address" />,
      dataIndex: "mac",
      key: "mac",
    },
  ];

  return (
    <>
      <Card>
        <StyledStun>
          <FormattedMessage id="NodeInterfaces.externalIpAddress" defaultMessage="External IP address" />
        </StyledStun>
        {": "}

        <StyledStunIp
          copyable={{
            tooltips: [
              <FormattedMessage id="general.copy" defaultMessage="Copy" />,
              <FormattedMessage id="general.copied" defaultMessage="Copied" />,
            ],
          }}
        >
          {stunAddress}
        </StyledStunIp>
      </Card>
      <Divider />
      <Card
        title={
          <span>
            <FormattedMessage id="NodeInterfaces.interfaces" defaultMessage="Interfaces" />
          </span>
        }
      >
        <Table
          dataSource={nodeInterfaces}
          columns={columns}
          rowKey={(record) => record.ip}
          scroll={{ x: "calc(100vh - 250px)" }}
        />
      </Card>
    </>
  );
};

const StyledStun = styled.span`
  font-weight: 500;
  font-size: 16px;
`;

const StyledParagraph = styled(Paragraph)`
  display: inline;

  & .anticon-copy {
    color: ${themeColors.orange};
  }
`;

const StyledStunIp = styled(StyledParagraph)`
  color: ${themeColors.orange};
`;

export default NodeInterfaces;
