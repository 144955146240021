import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Card, Row, Col, Alert } from "antd";
import styled from "styled-components";

import { errorNotification } from "../../lib/utils/notification";
import AccountService from "../../services/account";

const UserDetailView = () => {
  const { username } = useParams();
  const { formatMessage } = useIntl();
  const [loadingData, setLoadingData] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    AccountService.getUserData({
      errorNotification: errorNotification(formatMessage),
      setLoadingData,
      username,
      setUserData,
    });
  }, [formatMessage, username, setLoadingData]);

  const isOrganization = !!userData?.organization;
  const isBusiness = !!userData?.organization?.business;
  const isTechnical = !!userData?.organization?.technical;
  const hasAccess = !!userData?.access;

  if (!hasAccess && !loadingData) {
    return (
      <Alert
        message={<FormattedMessage id="UserDetailView.noData" defaultMessage="No data" />}
        type="warning"
        showIcon
      />
    );
  }

  return (
    <Card loading={loadingData}>
      <Row gutter={16}>
        <Col sm={24} lg={8}>
          <Card title={<FormattedMessage id="UserDetailView.userData" defaultMessage="User data" />}>
            <StyledData>
              <FormattedMessage id="UserDetailView.username" defaultMessage="Username" />:{" "}
              <b>{userData?.user?.username}</b>
            </StyledData>
            <StyledData>
              <FormattedMessage id="UserDetailView.email" defaultMessage="Email" />: <b>{userData?.user?.email}</b>
            </StyledData>
          </Card>
        </Col>
        {isOrganization && (
          <Col sm={24} lg={16}>
            <Card
              title={
                <span>
                  <FormattedMessage id="UserDetailView.organization" defaultMessage="Organization" />:{" "}
                  {userData?.organization?.name}
                </span>
              }
            >
              <Row gutter={[16, 16]}>
                {isBusiness && (
                  <Col sm={24} lg={isTechnical ? 12 : 24}>
                    <Card title={<FormattedMessage id="UserDetailView.business" defaultMessage="Business" />}>
                      {userData?.organization?.businessName && (
                        <StyledData>
                          <FormattedMessage id="UserDetailView.name" defaultMessage="Name" />:{" "}
                          <b>{userData?.organization?.businessName}</b>
                        </StyledData>
                      )}

                      {userData?.organization?.businessRole && (
                        <StyledData>
                          <FormattedMessage id="UserDetailView.role" defaultMessage="Role" />:{" "}
                          <b>{userData?.organization?.businessRole}</b>
                        </StyledData>
                      )}

                      {userData?.organization?.businessEmail && (
                        <StyledData>
                          <FormattedMessage id="UserDetailView.phone" defaultMessage="Phone" />:{" "}
                          <b>{userData?.organization?.businessEmail}</b>
                        </StyledData>
                      )}

                      {userData?.organization?.businessEmail && (
                        <StyledData>
                          <FormattedMessage id="UserDetailView.email" defaultMessage="Email" />:{" "}
                          <b>{userData?.organization?.businessEmail}</b>
                        </StyledData>
                      )}
                    </Card>
                  </Col>
                )}
                {isTechnical && (
                  <Col sm={24} lg={isBusiness ? 12 : 24}>
                    <Card title={<FormattedMessage id="UserDetailView.technical" defaultMessage="Technical" />}>
                      {userData?.organization?.technicalName && (
                        <StyledData>
                          <FormattedMessage id="UserDetailView.name" defaultMessage="Name" />:{" "}
                          <b>{userData?.organization?.technicalName}</b>
                        </StyledData>
                      )}

                      {userData?.organization?.technicalRole && (
                        <StyledData>
                          <FormattedMessage id="UserDetailView.role" defaultMessage="Role" />:{" "}
                          <b>{userData?.organization?.technicalRole}</b>
                        </StyledData>
                      )}

                      {userData?.organization?.technicalEmail && (
                        <StyledData>
                          <FormattedMessage id="UserDetailView.phone" defaultMessage="Phone" />:{" "}
                          <b>{userData?.organization?.technicalEmail}</b>
                        </StyledData>
                      )}

                      {userData?.organization?.technicalEmail && (
                        <StyledData>
                          <FormattedMessage id="UserDetailView.email" defaultMessage="Email" />:{" "}
                          <b>{userData?.organization?.technicalEmail}</b>
                        </StyledData>
                      )}
                    </Card>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        )}
      </Row>
    </Card>
  );
};

const StyledData = styled.div`
  margin-bottom: 10px;
`;

export default UserDetailView;
