import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import {
  CONNECTION_METHOD_INPUT,
  CONNECTION_METHOD_OUTPUT,
  QUICKSTREAM_METHOD_V2,
  INVALID_CONFIG,
  OUTPUTS_TYPES,
  OUTPUT_TO_FILE_TYPE,
} from "./constants";

const parseConnectionMethodName = (type, invalidCfg) => {
  switch (type) {
    case QUICKSTREAM_METHOD_V2.inQSDirect.value:
      return QUICKSTREAM_METHOD_V2.inQSDirect.label;
    case QUICKSTREAM_METHOD_V2.outQSDirect.value:
      return QUICKSTREAM_METHOD_V2.outQSDirect.label;
    case CONNECTION_METHOD_INPUT.inUdpMulticast.value:
      return CONNECTION_METHOD_INPUT.inUdpMulticast.label;
    case CONNECTION_METHOD_INPUT.inUdpUnicast.value:
      return CONNECTION_METHOD_INPUT.inUdpUnicast.label;
    case CONNECTION_METHOD_INPUT.inSrtListener.value:
      return CONNECTION_METHOD_INPUT.inSrtListener.label;
    case CONNECTION_METHOD_INPUT.inSrtCaller.value:
      return CONNECTION_METHOD_INPUT.inSrtCaller.label;

    case CONNECTION_METHOD_OUTPUT.outUdp.value:
      return CONNECTION_METHOD_OUTPUT.outUdp.label;
    case CONNECTION_METHOD_OUTPUT.outSrtListener.value:
      return CONNECTION_METHOD_OUTPUT.outSrtListener.label;
    case CONNECTION_METHOD_OUTPUT.outSrtCaller.value:
      return CONNECTION_METHOD_OUTPUT.outSrtCaller.label;
    case OUTPUTS_TYPES.outputUrl.value:
      return OUTPUTS_TYPES.outputUrl.label;
    case OUTPUTS_TYPES.outputNdi.value:
      return OUTPUTS_TYPES.outputNdi.label;
    case OUTPUTS_TYPES.outputRtmp.value:
      return OUTPUTS_TYPES.outputRtmp.label;
    case OUTPUT_TO_FILE_TYPE:
      return "File";
    case INVALID_CONFIG:
      return (
        <Row align="middle" justify="center">
          <StyledCol span={24}>{type && type.toUpperCase()}</StyledCol>
          <StyledCol span={24}>{invalidCfg}</StyledCol>
        </Row>
      );
    default:
      return type;
  }
};

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

export default parseConnectionMethodName;
