import { useEffect } from "react";
import { useSelector } from "react-redux";

import store from "../store";

import MQTTService from "../services/mqtt";
import { selectors as NODE_SELECTORS } from "../ducks/node";
import { actions as LOADING_ACTIONS } from "../ducks/loadingData";

import { MESSAGE_TYPE } from "../lib/utils/constants";

function useNodeInterfaces() {
  const cwid = useSelector(NODE_SELECTORS.getNodeCwid);
  const interfacesCwid = useSelector(NODE_SELECTORS.getNetworkInterfacesFrom);
  const networkInterfaces = useSelector(NODE_SELECTORS.getNetworkInterfaces);
  const noNetWorkInterface = networkInterfaces.length === 0;

  useEffect(() => {
    const getInterfaces = () => {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.NETWORK_INTERFACES,
        },
      });
      store.dispatch(LOADING_ACTIONS.SET_LOADING(MESSAGE_TYPE.NETWORK_INTERFACES));
    };

    if (noNetWorkInterface || interfacesCwid !== cwid) {
      getInterfaces(cwid);
    }
  }, [cwid, noNetWorkInterface]);

  return networkInterfaces;
}

export default useNodeInterfaces;
