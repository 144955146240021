import React from "react";
import { Button, Popconfirm } from "antd";
import styled from "styled-components";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";

import CloudChannelsServices from "../../services/cloudChannels";

import globalTranslation from "../../lib/translations";
import { errorNotification, successNotification } from "../../lib/utils/notification";

const AllChannelsActionButtons = ({
  setSelectedChannelData,
  setCurrentCloudId,
  setIsVisibleEditChannelModal,
  record,
  loadingDeleteVChannel,
  setLoadingDeleteVChannel,
}) => {
  const { formatMessage } = useIntl();

  const handleEditVirtualChannel = (selectedChannel) => {
    setSelectedChannelData(selectedChannel);
    setCurrentCloudId(selectedChannel.cloudId);
    setIsVisibleEditChannelModal(true);
  };

  const handleDeleteVirtualChanel = (cloudId) => {
    setLoadingDeleteVChannel(true);
    CloudChannelsServices.removeChannel(cloudId, {
      errorNotification: errorNotification(formatMessage),
      successNotification: successNotification(formatMessage),
      setLoadingDeleteVChannel,
    });
  };

  return (
    <>
      <StyledButton
        icon={<EditOutlined />}
        type="dashed"
        onClick={() => handleEditVirtualChannel(record)}
        disabled={loadingDeleteVChannel}
        loading={loadingDeleteVChannel}
      >
        {formatMessage(globalTranslation.edit)}
      </StyledButton>
      <Popconfirm
        onConfirm={() => handleDeleteVirtualChanel(record.cloudId)}
        title={
          <FormattedMessage
            id="AllChannelsActionButtons.confirmRemove"
            defaultMessage="Are you sure, you want to remove this channel?"
          />
        }
      >
        <StyledButton
          icon={<DeleteOutlined />}
          type="primary"
          danger
          disabled={loadingDeleteVChannel}
          loading={loadingDeleteVChannel}
        >
          {formatMessage(globalTranslation.delete)}
        </StyledButton>
      </Popconfirm>
    </>
  );
};

const StyledButton = styled(Button)`
  margin: 3px 5px;
`;

AllChannelsActionButtons.propTypes = {
  setCurrentCloudId: PropTypes.func.isRequired,
  setSelectedChannelData: PropTypes.func.isRequired,
  setIsVisibleEditChannelModal: PropTypes.func.isRequired,
  loadingDeleteVChannel: PropTypes.bool.isRequired,
  setLoadingDeleteVChannel: PropTypes.func.isRequired,
  record: PropTypes.shape({
    bgUrl: PropTypes.string,
    bitrate: PropTypes.number,
    channelName: PropTypes.string,
    channelType: PropTypes.string,
    cloudId: PropTypes.number,
    description: PropTypes.string,
    framerate: PropTypes.string,
    id: PropTypes.number.isRequired,
    public: PropTypes.bool.isRequired,
    languages: PropTypes.arrayOf(PropTypes.string),
    logoUrl: PropTypes.string,
    organizationId: PropTypes.number,
    resolutions: PropTypes.arrayOf(PropTypes.string),
    screenShotUrls: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string.isRequired,
    subtitles: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    username: PropTypes.string,
    videoCodec: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default AllChannelsActionButtons;
