import React from "react";
import { Col, Form, Select, InputNumber } from "antd";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { X264_PRESET, X264_TUNE, X264_LEVEL } from "../../../../../../../lib/utils/constants";
import { required } from "../../../../../../../lib/utils/formValidation";

const { Item } = Form;
const { Option } = Select;

function X264VideoCodecAdvanced({ fieldPrefix, disabled }) {
  return (
    <>
      <Col span={6}>
        <Item
          name={[...fieldPrefix, "x264Preset"]}
          label={<FormattedMessage id="X264VideoCodecAdvanced.pixFormat" defaultMessage="Preset" />}
          rules={[required]}
        >
          <Select disabled={disabled}>
            {X264_PRESET.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={8}>
        <Item
          name={[...fieldPrefix, "x264Tune"]}
          label={<FormattedMessage id="X264VideoCodecAdvanced.x264Tune" defaultMessage="Tune" />}
          rules={[required]}
        >
          <Select disabled={disabled}>
            {X264_TUNE.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={8}>
        <Item
          name={[...fieldPrefix, "x264Level"]}
          label={<FormattedMessage id="X264VideoCodecAdvanced.x264Level" defaultMessage="Level" />}
          rules={[required]}
        >
          <Select disabled={disabled}>
            {X264_LEVEL.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={8}>
        <Item
          name={[...fieldPrefix, "x264VbvBufsize"]}
          label={<FormattedMessage id="X264VideoCodecAdvanced.x264VbvBufsize" defaultMessage="VBV Size [frames]" />}
          rules={[required]}
        >
          <InputNumber type="number" disabled={disabled} min={1} max={100} />
        </Item>
      </Col>
    </>
  );
}

X264VideoCodecAdvanced.propTypes = {
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  disabled: PropTypes.bool,
};

X264VideoCodecAdvanced.defaultProps = {
  disabled: null,
};

export default X264VideoCodecAdvanced;
