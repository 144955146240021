import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const LicenseListTooltip = ({ isAdmin, editAvailable, isPermanentLicense, isFreeLicense, isPlayoutLicense }) => {
  if (!isAdmin && editAvailable) {
    return (
      <FormattedMessage
        id="LicenseListTooltip.disabledOncePerDay"
        defaultMessage="License can be edited only once per day"
      />
    );
  }

  if (isPermanentLicense || isFreeLicense) {
    return (
      <FormattedMessage
        id="LicenseListTooltip.disabledPermanentLicense"
        defaultMessage="Unable to edit permanent license"
      />
    );
  }

  if (isPlayoutLicense) {
    return (
      <FormattedMessage
        id="LicenseListTooltip.disabledPlayoutLicense"
        defaultMessage="Unable to edit playout license"
      />
    );
  }

  return null;
};

LicenseListTooltip.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  editAvailable: PropTypes.bool,
  isPermanentLicense: PropTypes.bool.isRequired,
  isFreeLicense: PropTypes.bool.isRequired,
  isPlayoutLicense: PropTypes.bool.isRequired,
};

LicenseListTooltip.defaultProps = {
  editAvailable: null,
};

export default LicenseListTooltip;
