import React from "react";
import { Col, Form, Select } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { required } from "../../../../../../../../lib/utils/formValidation";
import { MPEG2AUDIO_BITRATE } from "../../../../../../../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

function Mpeg2AudioCodec({ disabled, fieldPrefix }) {
  const TRANSCODED_MPEG2AUDIO_BITRATE = MPEG2AUDIO_BITRATE.slice(1);

  return (
    <Col span={6}>
      <Item
        name={[...fieldPrefix, "b"]}
        label={<FormattedMessage id="Mpeg2AudioCodec.bitrate" defaultMessage="Bitrate" />}
        rules={[required]}
      >
        <Select disabled={disabled}>
          {TRANSCODED_MPEG2AUDIO_BITRATE.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Item>
    </Col>
  );
}

Mpeg2AudioCodec.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

export default Mpeg2AudioCodec;
