import React, { useEffect } from "react";
import { Col, Form, Select, Spin } from "antd";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import MQTTService from "services/mqtt";

import { selectors as NODE_DEVICE_SELECTORS } from "ducks/nodeDevices";
import { SET_LOADING_FORMATS, selectors as FORMAT_SELECTORS } from "ducks/nodeFormats";
import { selectors as NODE_CHANNEL_SELECTORS } from "ducks/nodeChannels";

import { PLAYOUT_DATA_SOURCE_SELECTOR } from "../../lib/utils/defaultValues/defaultPlayoutOutput";

import store from "../../store";

import {
  MESSAGE_TYPE,
  GLOBAL_VIDEO_RESIZE,
  SELECTOR_SDI_AUDIO_CHANNELS,
  NODE_DEVICES_TYPE,
} from "../../lib/utils/constants";
import globalTranslations from "../../lib/translations";
import { max32characters, az09space, required } from "../../lib/utils/formValidation";
import formatLabels from "../../lib/utils/formatLabels";

import InputTextFiled from "../Fields/InputTextField";
import InputNumberField from "../Fields/InputNumberField";

const translations = defineMessages({
  nameAlreadyExist: {
    id: "PlayoutOutputSDIFormItem.nameAlreadyExist",
    defaultMessage: "This output name already exist",
  },
  sdiDeviceAlreadyUsed: {
    id: "PlayoutOutputSDIFormItem.sdiDeviceAlreadyUsed",
    defaultMessage: "This SDI device is already used",
  },
});

const { Item } = Form;
const { Option } = Select;

export default function PlayoutOutputSDIFormItem({ detailView, prefix, channelId, outputData, form }) {
  const { formatMessage } = useIntl();
  const nodeChannels = useSelector(NODE_CHANNEL_SELECTORS.getChannels);
  const decklinkOutputs = useSelector(NODE_DEVICE_SELECTORS.getDecklinkOutputs);
  const sdiOutputs = useSelector((state) => NODE_CHANNEL_SELECTORS.getChannelSDIOutputs(state, channelId));
  const formats = useSelector(FORMAT_SELECTORS.getFormats);
  const loadingFormats = !!useSelector(FORMAT_SELECTORS.getLoadingFormat);
  const { cwid } = useParams();
  const { setFieldValue, getFieldValue } = form;

  const selectedDevice = Form.useWatch([...prefix, "device"], form);

  useEffect(() => {
    const isFormatData = !!formats.get(selectedDevice);

    if (!isFormatData && selectedDevice) {
      MQTTService.sendMessageV5({
        topic: `node/${cwid}/in`,
        message: { msgType: MESSAGE_TYPE.GET_FORMATS, device: selectedDevice, type: NODE_DEVICES_TYPE.decklinkOutputs },
      });

      store.dispatch(SET_LOADING_FORMATS(true));
    }
  }, [selectedDevice, formats]);

  const uniqueValidator = (rule, value) => {
    const outputNames = nodeChannels.getIn([`${channelId}`, "outputs", "outputsNames"]);
    const parsedOutputNames = outputNames && outputNames.filter((name) => name !== outputData?.name);

    const duplicatedMuxedOutputName = parsedOutputNames && parsedOutputNames.includes(value);

    if (duplicatedMuxedOutputName) {
      return Promise.reject(formatMessage(translations.nameAlreadyExist));
    }

    return Promise.resolve();
  };

  const OUTPUTS_SDI_SELECT = decklinkOutputs.map((output) => {
    return {
      label: output.desc,
      value: output.device,
    };
  });

  const handleChangeDevice = () => {
    setFieldValue([...prefix, "resize"], GLOBAL_VIDEO_RESIZE[0].value);
    setFieldValue([...prefix, "format"], null);
  };

  const SELECTED_FORMATS =
    (formats.get(selectedDevice) &&
      formats.get(selectedDevice).map((format) => {
        return { label: format, value: format };
      })) ||
    [];

  const isSelectedFormats = SELECTED_FORMATS && SELECTED_FORMATS.length > 0;
  const PARSED_SELECTED_FORMATS =
    SELECTED_FORMATS &&
    SELECTED_FORMATS.map((format) => {
      const parsedLabel = format.label in formatLabels ? formatLabels[format.label] : format.label;

      return { ...format, label: parsedLabel };
    });

  const sameDeviceValidation = async () => {
    const inputSDIDevice = getFieldValue(["liveSource", "mainInput", "device"]);
    const backupInputSDIDevice = getFieldValue(["liveSource", "backupInput", "device"]);
    const outputId = getFieldValue([...prefix, "outputId"]);
    const sdiDevices = [
      ...sdiOutputs,
      ...(inputSDIDevice ? [{ source: "mainInput", device: inputSDIDevice }] : []),
      ...(backupInputSDIDevice ? [{ source: "backupInput", device: backupInputSDIDevice }] : []),
    ];

    const sdiAlreadyUsed = sdiDevices && sdiDevices.find((sdi) => sdi.device === selectedDevice);
    if (sdiAlreadyUsed && sdiAlreadyUsed?.source !== outputId) {
      return Promise.reject(formatMessage(translations.sdiDeviceAlreadyUsed));
    }

    return Promise.resolve();
  };

  return (
    <>
      <InputNumberField name={[...prefix, "outputId"]} label="OutputId" disabled={detailView} hidden />
      <Col sm={24} md={8}>
        <InputTextFiled
          disabled={detailView}
          name={[...prefix, "name"]}
          label={formatMessage(globalTranslations.name)}
          rules={[{ validator: uniqueValidator }, max32characters, az09space, required]}
        />
      </Col>
      <Col sm={24} md={8}>
        <InputTextFiled
          disabled={detailView}
          name={[...prefix, "outputName"]}
          label={formatMessage(globalTranslations.outputName)}
          rules={[{ validator: uniqueValidator }, max32characters, az09space, required]}
        />
      </Col>

      <Col sm={24} md={8}>
        <Item
          name={[...prefix, "device"]}
          label={<FormattedMessage id="InputSDI.device" defaultMessage="Device" />}
          rules={[required, { validator: sameDeviceValidation }]}
        >
          <Select disabled={detailView} onChange={handleChangeDevice}>
            {OUTPUTS_SDI_SELECT.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col sm={24} md={8}>
        <Spin
          spinning={!isSelectedFormats}
          tip={
            <FormattedMessage
              id="InputSDI.waitingFormNodeFormats"
              defaultMessage="Waiting for node formats or device to be selected..."
            />
          }
        >
          <Item
            name={[...prefix, "format"]}
            label={<FormattedMessage id="InputSDI.format" defaultMessage="Format" />}
            rules={[required]}
          >
            <Select
              disabled={detailView || !isSelectedFormats}
              notFoundContent={loadingFormats ? <Spin size="small" /> : null}
            >
              {PARSED_SELECTED_FORMATS.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Spin>
      </Col>
      <Col sm={24} md={8}>
        <Item
          name={[...prefix, "dataSource"]}
          label={<FormattedMessage id="InputSDI.dataSource" defaultMessage="Data source" />}
          rules={[required]}
        >
          <Select
            options={[
              PLAYOUT_DATA_SOURCE_SELECTOR.main,
              PLAYOUT_DATA_SOURCE_SELECTOR.live,
              PLAYOUT_DATA_SOURCE_SELECTOR.switcher,
            ]}
          />
        </Item>
      </Col>
      <Col xs={24} md={8}>
        <Item
          name={[...prefix, "resize"]}
          label={<FormattedMessage id="NDIVideoFormat.scaleVideo" defaultMessage="Scale video" />}
          rules={[required]}
        >
          <Select disabled={detailView}>
            {GLOBAL_VIDEO_RESIZE.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col xs={24} md={8}>
        <Item
          name={[...prefix, "audioChannels"]}
          label={<FormattedMessage id="InputSDI.audioChannels" defaultMessage="Audio channels" />}
          rules={[required]}
        >
          <Select disabled={detailView}>
            {SELECTOR_SDI_AUDIO_CHANNELS.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
    </>
  );
}

PlayoutOutputSDIFormItem.propTypes = {
  detailView: PropTypes.bool,
  channelId: PropTypes.string,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  outputData: PropTypes.object,
  form: PropTypes.object.isRequired,
};

PlayoutOutputSDIFormItem.defaultProps = {
  detailView: null,
  channelId: null,
  outputData: null,
};
