import React, { useContext } from "react";
import { Form, Select, Divider, Card, Spin, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { required } from "../../../../lib/utils/formValidation";
import { MAIN_CONNECTION_TYPE } from "../../../../lib/utils/types/inputURLTypes";
import { CAPABILITIES_TYPE } from "../../../../lib/utils/types/capabilitiesTypes";

import { selectors as NODE_DEVICES_SELECTORS } from "../../../../ducks/nodeDevices";

import {
  INPUT_DECKLINK_TYPE,
  INPUT_URL_TYPE,
  INPUT_WEBCAM_TYPE,
  // SELECT_DEFAULT_AUTO,
  INPUT_TYPES,
  ENGINE_TYPES,
} from "../../../../lib/utils/constants";
import InputStreamURL from "./InputStreamURL";

import UpdateNodeDevices from "../../../UpdateNodeDevices";
import { selectors as NODE_SELECTORS } from "../../../../ducks/node";

import { FormV5Context } from "../../NodeChannelFormV5";

const { Item } = Form;
const { Option } = Select;

function InputStreamSectionContainer({
  basicMode,
  capabilities,
  decklinkInputs,
  disabled,
  form,
  initialValue,
  isInput,
  nodeDevicesLoaded,
  sharedChannelList,
  stundAddress,
  webcamAudioInputs,
  webcamVideoInputs,
}) {
  const parsedForm = form || useContext(FormV5Context);
  const { getFieldValue } = parsedForm;

  const inputType = getFieldValue(["input", "type"]);

  const engineType = getFieldValue(["type"]);
  const isDirectEngine = engineType === ENGINE_TYPES.DIRECT;

  const isInputURLTypeSelected = inputType === INPUT_URL_TYPE.value;

  const isDecklinkInputs = decklinkInputs.length > 0;
  const isWebcamAudioInputs = webcamAudioInputs.length > 0;
  const isWebcamVideoInputs = webcamVideoInputs.length > 0;

  const INPUT_TYPES_ARRAY = Object.values(INPUT_TYPES);
  const INPUT_STREAM_DEVICE_TYPE = INPUT_TYPES_ARRAY;

  const checkIfOptionDisabled = (optionValue) => {
    if (capabilities?.inputTypes && !capabilities?.inputTypes.includes(optionValue)) {
      return true;
    }
    if (optionValue === INPUT_DECKLINK_TYPE.value && !isDecklinkInputs) {
      return true;
    }

    if (optionValue === INPUT_WEBCAM_TYPE.value && (!isWebcamAudioInputs || !isWebcamVideoInputs)) {
      return true;
    }

    return false;
  };

  return (
    <Card extra={isDirectEngine ? null : <UpdateNodeDevices />}>
      <Item
        hidden={isDirectEngine}
        name={["input", "type"]}
        label={<FormattedMessage id="InputStreamSectionContainer.type" defaultMessage="Type" />}
        rules={[required]}
      >
        <Select disabled={disabled || isInput}>
          {INPUT_STREAM_DEVICE_TYPE.map((option) => (
            <Option key={option.value} value={option.value} disabled={checkIfOptionDisabled(option.value)}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Item>
      {!isDirectEngine && <Divider />}

      {nodeDevicesLoaded && (
        <>
          <StyledSpin
            tip={
              <FormattedMessage
                id="InputStreamSectionContainer.loadingNodeDevices"
                defaultMessage="Loading node devices list..."
              />
            }
          >
            <Skeleton active />
          </StyledSpin>
          <Divider />
        </>
      )}
      {isInputURLTypeSelected && (
        <InputStreamURL
          channelList={sharedChannelList}
          disabled={disabled}
          initialValue={initialValue}
          sharedChannelList={sharedChannelList}
          stundAddress={stundAddress}
          form={parsedForm}
          basicMode={basicMode}
        />
      )}
    </Card>
  );
}

const StyledSpin = styled(Spin)`
  width: 100%;
`;

InputStreamSectionContainer.propTypes = {
  capabilities: PropTypes.shape(CAPABILITIES_TYPE),
  disabled: PropTypes.bool,
  decklinkInputs: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string,
      device: PropTypes.string,
    })
  ).isRequired,
  initialValue: PropTypes.shape({
    type: PropTypes.string.isRequired,
    switchInputBackup: PropTypes.bool,
    ...MAIN_CONNECTION_TYPE,
  }),
  stundAddress: PropTypes.string,
  sharedChannelList: PropTypes.array,
  webcamAudioInputs: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.string,
    })
  ).isRequired,
  webcamVideoInputs: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.string,
    })
  ).isRequired,
  nodeDevicesLoaded: PropTypes.bool,
  isInput: PropTypes.bool.isRequired,
  form: PropTypes.object,
  basicMode: PropTypes.bool,
};

InputStreamSectionContainer.defaultProps = {
  capabilities: null,
  disabled: null,
  initialValue: null,
  nodeDevicesLoaded: null,
  sharedChannelList: null,
  stundAddress: null,
  form: null,
  basicMode: false,
};

const mapStateToProps = (state) => ({
  capabilities: NODE_SELECTORS.getNodeCapabilities(state),
  decklinkInputs: NODE_DEVICES_SELECTORS.getDecklinkInputs(state),
  decklinkOutputs: NODE_DEVICES_SELECTORS.getDecklinkOutputs(state),
  webcamAudioInputs: NODE_DEVICES_SELECTORS.getWebcamAudioInputs(state),
  webcamVideoInputs: NODE_DEVICES_SELECTORS.getWebcamVideoInputs(state),
  nodeDevicesLoaded: NODE_DEVICES_SELECTORS.getLoadingDevices(state),
});

export default connect(mapStateToProps, null)(InputStreamSectionContainer);
