import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const actions = {
  CHANGE_LANG_DATA: createAction("LANG/CHANGE"),
};

const initialState = new Map({
  lang: "en",
});

const reducer = handleActions(
  {
    [actions.CHANGE_LANG_DATA]: (state, { payload: { lang } }) => state.merge({ lang }),
  },
  initialState
);

export const selectors = {
  getLang: (state) => state.getIn(["lang", "lang"]),
};

export default reducer;
