import React from "react";
import { Col, Form, Switch } from "antd";
import { useIntl, defineMessages } from "react-intl";
import styled from "styled-components";

import { required } from "../../lib/utils/formValidation";
import globalTranslations from "../../lib/translations";

import InputNumberField from "../Fields/InputNumberField";

const translations = defineMessages({
  ipTtlSwitch: {
    id: "IPTTLFields.ipTtlSwitch",
    defaultMessage: "IP TTL",
  },
  ipTllValue: {
    id: "IPTTLFields.ipTllValue",
    defaultMessage: "IP TTL Value",
  },
});

const { Item } = Form;

const IPTTLFields = ({ prefix, form, disabled }) => {
  const { formatMessage } = useIntl();

  const setIPTTLValue = Form.useWatch([...prefix, "setIPTTL"], form) || false;

  return (
    <StyledDiv>
      <Col md={24} lg={6}>
        <Item name={[...prefix, "setIPTTL"]} label={formatMessage(translations.ipTtlSwitch)} valuePropName="checked">
          <Switch
            defaultChecked={setIPTTLValue}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
            disabled={disabled}
          />
        </Item>
      </Col>
      {setIPTTLValue && (
        <Col md={24} lg={8}>
          <InputNumberField
            name={[...prefix, "valueIPTTL"]}
            label={formatMessage(translations.ipTllValue)}
            rules={[required]}
            disabled={disabled}
            min={1}
            max={255}
          />
        </Col>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
`;

export default IPTTLFields;
