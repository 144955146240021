import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Card, Modal, Button, Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";

import GlobalVideoCodecElement from "./GlobalCodecVideo/GlobalVideoCodecElement";
import GlobalAudioCodecElement from "./GlobalCodecAudio/GlobalAudioCodecElement";

import GlobalAudioCodecList from "./GlobalCodecAudio/GlobalAudioCodecList";
import GlobalVideoCodecFormItem from "./GlobalCodecVideo/GlobalVideoCodecFormItem";

import { FormV5Context } from "../../NodeChannelFormV5";

import {
  DEFAULT_GLOBAL_VIDEO,
  GLOBAL_CODEC_AUDIO_SAMPLE_FORMATS,
  GLOBAL_CODEC_AUDIO_SAMPLE_RATE,
} from "../../../../lib/utils/constants";

function GlobalCodec({ disabled, isVideoFormat, isAudioFormat, handleFinish, form }) {
  const [showVideoCodec, setShowVideoCodec] = useState(false);
  const [showAudioCodec, setShowAudioCodec] = useState(false);
  const { getFieldValue, setFieldsValue } = useContext(FormV5Context);
  const { resetFields } = form;

  const handleOKVideoCodec = () => {
    const formData = getFieldValue();
    setShowVideoCodec(!showVideoCodec);

    handleFinish(formData);
  };

  const handleAddNewVideoCode = () => {
    const formData = getFieldValue();
    setFieldsValue({ ...formData, globalVideoFormat: DEFAULT_GLOBAL_VIDEO });

    setShowVideoCodec(true);
  };

  const handleOKAudioCodec = () => {
    const formData = getFieldValue();
    setShowAudioCodec(!showAudioCodec);

    handleFinish(formData);
  };

  const handleAddNewAudioCode = () => {
    const formData = getFieldValue();
    setFieldsValue({
      ...formData,
      globalAudioFormats: [
        {
          sampleFormat: GLOBAL_CODEC_AUDIO_SAMPLE_FORMATS[2].value,
          sampleRate: GLOBAL_CODEC_AUDIO_SAMPLE_RATE[0].value,
          channels: 2,
        },
      ],
    });

    setShowAudioCodec(true);
  };

  return (
    <>
      <Card title={<FormattedMessage id="GlobalCodec.processingAVFormats" defaultMessage="Processing A/V formats" />}>
        <Row gutter={24} justify="center">
          {isVideoFormat && (
            <Col span={12}>
              <GlobalVideoCodecElement
                type={<FormattedMessage id="GlobalCodec.video" defaultMessage="Video" />}
                handleClick={() => setShowVideoCodec(!showVideoCodec)}
              />
            </Col>
          )}
          {isAudioFormat && (
            <Col span={12}>
              <GlobalAudioCodecElement
                type={<FormattedMessage id="GlobalCodec.audio" defaultMessage="Audio" />}
                handleClick={() => setShowAudioCodec(!showVideoCodec)}
              />
            </Col>
          )}
        </Row>
        {!isVideoFormat && (
          <Button type="dashed" style={{ width: "100%", margin: "10px 0" }} onClick={handleAddNewVideoCode}>
            <PlusOutlined /> <FormattedMessage id="GlobalCodec.addViodeFormat" defaultMessage="Add video format" />
          </Button>
        )}
        {!isAudioFormat && (
          <Button type="dashed" style={{ width: "100%", margin: "10px 0" }} onClick={handleAddNewAudioCode}>
            <PlusOutlined /> <FormattedMessage id="GlobalCodec.addAudioFormat" defaultMessage="Add audio format" />
          </Button>
        )}
      </Card>
      <Modal
        title={<FormattedMessage id="GlobalCodec.processingAudioFormat" defaultMessage="Processing audio format" />}
        centered
        open={showAudioCodec}
        onCancel={() => {
          resetFields();
          setShowAudioCodec(false);
        }}
        onOk={handleOKAudioCodec}
        width={1024}
        footer={disabled ? null : undefined}
        okText={
          <span>
            <FormattedMessage id="general.save" defaultMessage="Save" />
          </span>
        }
        okButtonProps={{ icon: <SaveOutlined /> }}
      >
        <Row gutter={24}>
          <GlobalAudioCodecList prefix={["globalAudioFormats"]} disabled={disabled} />
        </Row>
      </Modal>
      <Modal
        title={<FormattedMessage id="GlobalCodec.processingVideoFormat" defaultMessage="Processing video format" />}
        centered
        open={showVideoCodec}
        footer={disabled ? null : undefined}
        onCancel={() => {
          resetFields();
          setShowVideoCodec(false);
        }}
        onOk={handleOKVideoCodec}
        width={1024}
        okText={
          <span>
            <FormattedMessage id="GlobalCodec.global" defaultMessage="Save" />
          </span>
        }
        okButtonProps={{ icon: <SaveOutlined /> }}
      >
        <GlobalVideoCodecFormItem prefix={["globalVideoFormat"]} disabled={disabled} />
      </Modal>
    </>
  );
}

GlobalCodec.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isVideoFormat: PropTypes.bool.isRequired,
  isAudioFormat: PropTypes.bool.isRequired,
  handleFinish: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default GlobalCodec;
