import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal, Button, Empty } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";

import parseConnectionMethodName from "../../../lib/utils/parseConnectionMethodName";
import { OUTPUTS_TYPE, NEW, CONNECTION_METHOD_OUTPUT } from "../../../lib/utils/constants";
import { OUTPUT_TYPE } from "../../../lib/utils/types/outputURLTypes";
import defaultNonMuxedOutput from "../../../lib/utils/defaultValues/defaultNonMuxedOutput";

import StreamSourceElement from "../../StreamSourceElement";
import OutputFormItem from "../OutputFormItem";

const NonmuxedSection = ({
  channelId,
  detailView,
  getFieldValue,
  nonMuxedOutputs,
  resetFields,
  setFieldsValue,
  validateFields,
  handleFinish,
  form,
}) => {
  const [outputSectionVisible, setOutputSectionVisible] = useState(null);
  const [disableCondition, setDisableCondition] = useState(false);
  const numberOfNonmuxedOutputs = nonMuxedOutputs ? nonMuxedOutputs.length : 0;

  const handleAddNewNonMuxedOutput = () => {
    resetFields();
    const formNonmuxedOutputs = getFieldValue("nonMuxedOutputs") || [];
    const outputsNumber = formNonmuxedOutputs.length + 1;

    setFieldsValue({
      nonMuxedOutputs: [
        ...nonMuxedOutputs,
        {
          ...defaultNonMuxedOutput(CONNECTION_METHOD_OUTPUT.outUdp.value),
          outputName: `Output-${outputsNumber}`,
        },
      ],
    });
    setOutputSectionVisible(NEW);
  };

  const handleOKNonmuxedOutput = async () => {
    if (!disableCondition) {
      try {
        await validateFields();
        const updatedFormData = getFieldValue();
        handleFinish(updatedFormData);
        setOutputSectionVisible(null);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("Error - formValidation", error);
      }
    }
  };

  const handleCancelNonmuxedOutput = () => {
    resetFields([OUTPUTS_TYPE.nonMuxedOutputs]);
    setOutputSectionVisible(null);
  };

  const handleRemoveNonMuxedOutput = (outputName) => {
    const channelConfig = getFieldValue();
    const parsedFormNonmuxedOutputs = nonMuxedOutputs.filter(
      (nonMuxedOutput) => nonMuxedOutput.outputName !== outputName
    );
    const updatedFormData = { ...channelConfig, nonMuxedOutputs: parsedFormNonmuxedOutputs };

    handleFinish(updatedFormData);
    resetFields();
    setFieldsValue({ nonMuxedOutputs: parsedFormNonmuxedOutputs });
    setOutputSectionVisible(null);
  };

  const isNonmuxedOutputs = nonMuxedOutputs && nonMuxedOutputs.length > 0;

  if (!isNonmuxedOutputs && detailView) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <>
      {nonMuxedOutputs &&
        nonMuxedOutputs.map((nonMuxedOutput, index) => {
          const handleClick = () => {
            setOutputSectionVisible(nonMuxedOutput.outputName);
          };

          return (
            <Row gutter={24} key={nonMuxedOutput.outputName}>
              <Col span={24}>
                <StreamSourceElement
                  connectionType={nonMuxedOutput?.type}
                  destinationAddress={nonMuxedOutput?.destinationAddress}
                  destinationPort={nonMuxedOutput?.destinationPort}
                  localPort={nonMuxedOutput?.localPort}
                  handleClick={handleClick}
                  interfaceIp={nonMuxedOutput?.interfaceIp}
                  label={parseConnectionMethodName(nonMuxedOutput?.type)}
                  title={
                    nonMuxedOutput?.outputName
                      ? nonMuxedOutput?.outputName
                      : parseConnectionMethodName(nonMuxedOutput?.type)
                  }
                  type={OUTPUTS_TYPE.nonMuxedOutputs}
                  cloudId={nonMuxedOutput?.cloudId}
                  incomplete={nonMuxedOutput?.incomplete}
                />
                <StyledModal
                  zIndex={100}
                  centered
                  title={<FormattedMessage id="NonmuxedSection.editDirectOutput" defaultMessage="Edit Direct Output" />}
                  open={outputSectionVisible === nonMuxedOutput.outputName}
                  onOk={handleOKNonmuxedOutput}
                  onCancel={handleCancelNonmuxedOutput}
                  footer={detailView ? null : undefined}
                  okText={
                    <span>
                      <FormattedMessage id="general.save" defaultMessage="Save" />
                    </span>
                  }
                  okButtonProps={{ icon: <SaveOutlined /> }}
                >
                  <OutputFormItem
                    channelId={channelId}
                    disabled={!!detailView}
                    getFieldValue={getFieldValue}
                    handleRemove={handleRemoveNonMuxedOutput}
                    index={index}
                    initialValue={nonMuxedOutput}
                    name={nonMuxedOutput.outputName}
                    outputType={OUTPUTS_TYPE.nonMuxedOutputs}
                    prefix={[OUTPUTS_TYPE.nonMuxedOutputs]}
                    setDisableCondition={setDisableCondition}
                    setFieldsValue={setFieldsValue}
                    isNonmuxedOutput
                    form={form}
                  />
                </StyledModal>
              </Col>
            </Row>
          );
        })}
      {!detailView && (
        <StyledAddButton type="dashed" icon={<PlusOutlined />} onClick={handleAddNewNonMuxedOutput}>
          <span>
            <FormattedMessage id="NonmuxedSection.addNewDirectOutput" defaultMessage="Add Direct Output" />
          </span>
        </StyledAddButton>
      )}
      {outputSectionVisible === NEW && (
        <StyledModal
          zIndex={100}
          centered
          title={<FormattedMessage id="NonmuxedSection.newDirectOutputs" defaultMessage="New Direct Output" />}
          open={outputSectionVisible === NEW}
          onOk={handleOKNonmuxedOutput}
          onCancel={handleCancelNonmuxedOutput}
          okText={
            <span>
              <FormattedMessage id="general.save" defaultMessage="Save" />
            </span>
          }
          okButtonProps={{ icon: <SaveOutlined /> }}
        >
          <OutputFormItem
            channelId={channelId}
            getFieldValue={getFieldValue}
            handleRemove={handleRemoveNonMuxedOutput}
            index={numberOfNonmuxedOutputs}
            outputType={OUTPUTS_TYPE.nonMuxedOutputs}
            prefix={[OUTPUTS_TYPE.nonMuxedOutputs]}
            setDisableCondition={setDisableCondition}
            setFieldsValue={setFieldsValue}
            isNonmuxedOutput
          />
        </StyledModal>
      )}
    </>
  );
};

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledAddButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

NonmuxedSection.propTypes = {
  channelId: PropTypes.string,
  detailView: PropTypes.bool,
  getFieldValue: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
  nonMuxedOutputs: PropTypes.arrayOf(PropTypes.shape(OUTPUT_TYPE)),
  resetFields: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
};

NonmuxedSection.defaultProps = {
  channelId: null,
  detailView: null,
  nonMuxedOutputs: null,
};

export default NonmuxedSection;
