import React from "react";
import styled from "styled-components";

import themeColor from "../../lib/style/theme";

const BoxData = ({ label, data, title }) => {
  return (
    <StyledContainer>
      <StyledAsideType>{label}</StyledAsideType>
      <StyledBoldText>{title}</StyledBoldText>
      {data}
    </StyledContainer>
  );
};

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 125px;
  margin: 0 0 15px 0;
  padding: 10px 10px 10px 45px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  color: #fff;
  padding: 5px;
  line-height: 24px;
  position: absolute;
  top: 146px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 135px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;

export default BoxData;
