import React from "react";
import Turnstile, { useTurnstile } from "react-turnstile";
import { Row, Col } from "antd";

import AccountService from "../../services/account";

import useMainTheme from "../../hooks/useMainTheme";

const turnsTileSiteKey = process.env.REACT_APP_TURNSTILE_SITE_KEY;

export default function TurnstileValidation() {
  const turnstile = useTurnstile();
  const { isDarkTheme } = useMainTheme();

  return (
    <Row justify="center">
      <Col style={{ marginTop: "15px" }}>
        <Turnstile
          appearance="interaction-only"
          autoResetOnExpire
          sitekey={turnsTileSiteKey}
          theme={isDarkTheme ? "dark" : "light"}
          onError={(error) => {
            AccountService.setTurnstileToken(`Turnstile error: ${error}`);
            // eslint-disable-next-line no-console
            console.log("Turnstile validation error,", error);

            setTimeout(() => {
              turnstile.reset();
            }, 6000);
          }}
          onVerify={(token) => {
            AccountService.setTurnstileToken(token);
          }}
        />
      </Col>
    </Row>
  );
}
