import React, { useState } from "react";
import { Form, Button, Input, Row, Radio, Col, Select } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import LicenseServices from "../../../../services/license";

import { required } from "../../../../lib/utils/formValidation";
import { errorNotification, successNotification } from "../../../../lib/utils/notification";
import { selectors as MQTT_CONNECTION_SELECTORS } from "../../../../ducks/mqtt";

const { Item } = Form;
const { Group } = Radio;
const { Option } = Select;

const translations = defineMessages({
  inputHardwareId: {
    id: "EditLicense.inputHardwareId",
    defaultMessage: "Input node hardware ID",
  },
});

const HARDWARE_ID_TYPES = {
  LIST: "listHardwareId",
  CUSTOM: "customHardwareId",
};

const EditLicense = ({ selectedModalLicense, handleCloseModal }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [hardwareIdType, setHardwareIdType] = useState(HARDWARE_ID_TYPES.LIST);
  const nodes = useSelector(MQTT_CONNECTION_SELECTORS.getNodes);

  const { resetFields } = form;

  const handleFinish = async ({ fingerprint }) => {
    await LicenseServices.editLicense(
      { fingerprint, licenseId: selectedModalLicense },
      {
        errorNotification: errorNotification(formatMessage),
        successNotification: successNotification(formatMessage),
      }
    );
    await LicenseServices.getLicenseList({ errorNotification: errorNotification(formatMessage) });
    resetFields();
    handleCloseModal();
  };

  const handleHardwareIdType = (value) => {
    setHardwareIdType(value);
  };

  return (
    <Form form={form} onFinish={handleFinish}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Group
            onChange={(event) => handleHardwareIdType(event.target.value)}
            value={hardwareIdType}
            optionType="button"
            buttonStyle="solid"
          >
            <Radio value={HARDWARE_ID_TYPES.LIST}>
              <FormattedMessage id="EditLicense.nodes" defaultMessage="Nodes" />
            </Radio>
            <Radio value={HARDWARE_ID_TYPES.CUSTOM}>
              <FormattedMessage id="EditLicense.customHardwareId" defaultMessage="Custom" />
            </Radio>
          </Group>
        </Col>
        <Col span={24}>
          {hardwareIdType === HARDWARE_ID_TYPES.LIST ? (
            <Item name="fingerprint" rules={[required]}>
              <Select
                showSearch
                placeholder={<FormattedMessage id="EditLicense.selectNode" defaultMessage="Select node" />}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {nodes.map((node) => (
                  <Option value={node?.encryptedFingerprint} key={node.cwid}>
                    {node.cnn}
                  </Option>
                ))}
              </Select>
            </Item>
          ) : (
            <Item name="fingerprint" rules={[required]}>
              <Input type="text" placeholder={formatMessage(translations.inputHardwareId)} />
            </Item>
          )}
        </Col>
      </Row>

      <Row justify="end">
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="EditLicense.submit" defaultMessage="Edit license" />
        </Button>
      </Row>
    </Form>
  );
};

EditLicense.propTypes = {
  selectedModalLicense: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
};

EditLicense.defaultProps = {
  selectedModalLicense: null,
};

export default EditLicense;
