export default {
  // SD Modes
  ntsc: "NTSC, 720x486 @ 29.97Hz, interlaced",
  nt23: "NTSC, 720x486 @ 23.98 Hz",
  ntsp: "NTSC, 720x486 @ 59.94 Hz",
  "pal ": "PAL, 720x576 @ 25Hz, interlaced",
  pal: "PAL, 720x576 @ 25Hz, interlaced",
  palp: "PAL, 720x576 @ 50Hz",

  // HD 1080 Modes
  "23ps": "FullHD 1080p, 1920x1080 @ 23.98Hz",
  "24ps": "FullHD 1080p, 1920x1080 @ 24Hz",
  Hp25: "FullHD 1080p, 1920x1080 @ 25Hz",
  Hp29: "FullHD 1080p, 1920x1080 @ 29.97Hz",
  Hp30: "FullHD 1080p, 1920x1080 @ 30Hz",
  Hp47: "FullHD 1080p, 1920x1080 @ 47.95Hz",
  Hp48: "FullHD 1080p, 1920x1080 @ 48Hz",
  Hp50: "FullHD 1080p, 1920x1080 @ 50Hz",
  Hp59: "FullHD 1080p, 1920x1080 @ 59.94Hz",
  Hp60: "FullHD 1080p, 1920x1080 @ 60Hz",
  Hp95: "FullHD 1080p, 1920x1080 @ 95.60Hz",
  Hp96: "FullHD 1080p, 1920x1080 @ 96Hz",
  Hp10: "FullHD 1080p, 1920x1080 @ 100Hz",
  Hp11: "FullHD 1080p, 1920x1080 @ 119.88Hz",
  Hp12: "FullHD 1080p, 1920x1080 @ 120Hz",
  Hi50: "FullHD 1080i, 1920x1080 @ 50Hz, interlaced",
  Hi59: "FullHD 1080i, 1920x1080 @ 59.94Hz, interlaced",
  Hi60: "FullHD 1080i, 1920x1080 @ 60Hz, interlaced",

  // HD 720 Modes
  hp50: "HD 720p, 1280x720 @ 50Hz",
  hp59: "HD 720p, 1280x720 @ 59.94Hz",
  hp60: "HD 720p, 1280x720 @ 60Hz",

  // 2K Modes
  "2k23": "2k, 2048x1556 @ 23.98Hz",
  "2k24": "2k, 2048x1556 @ 24Hz",
  "2k25": "2k, 2048x1556 @ 25Hz",

  // 2K DCI Modes
  "2d23": "2k DCI, 2048x1080 @ 23.98Hz",
  "2d24": "2k DCI, 2048x1080 @ 24Hz",
  "2d25": "2k DCI, 2048x1080 @ 25Hz",
  "2d29": "2k DCI, 2048x1080 @ 29.97Hz",
  "2d30": "2k DCI, 2048x1080 @ 30Hz",
  "2d47": "2k DCI, 2048x1080 @ 47.95Hz",
  "2d48": "2k DCI, 2048x1080 @ 48Hz",
  "2d50": "2k DCI, 2048x1080 @ 50Hz",
  "2d59": "2k DCI, 2048x1080 @ 59.94Hz",
  "2d60": "2k DCI, 2048x1080 @ 60Hz",
  "2d95": "2k DCI, 2048x1080 @ 95.90Hz",
  "2d96": "2k DCI, 2048x1080 @ 96Hz",
  "2d10": "2k DCI, 2048x1080 @ 100Hz",
  "2d11": "2k DCI, 2048x1080 @ 119.88Hz",
  "2d12": "2k DCI, 2048x1080 @ 120Hz",

  // 4K UHD Modes
  "4k23": "4k UHD, 3840x2160 @ 23.98Hz",
  "4k24": "4k UHD, 3840x2160 @ 24Hz",
  "4k25": "4k UHD, 3840x2160 @ 25Hz",
  "4k29": "4k UHD, 3840x2160 @ 29.97Hz",
  "4k30": "4k UHD, 3840x2160 @ 30Hz",
  "4k47": "4k UHD, 3840x2160 @ 47.95Hz",
  "4k48": "4k UHD, 3840x2160 @ 48Hz",
  "4k50": "4k UHD, 3840x2160 @ 50Hz",
  "4k59": "4k UHD, 3840x2160 @ 59.94Hz",
  "4k60": "4k UHD, 3840x2160 @ 60Hz",
  "4k95": "4k UHD, 3840x2160 @ 95.90Hz",
  "4k96": "4k UHD, 3840x2160 @ 96Hz",
  "4k10": "4k UHD, 3840x2160 @ 100Hz",
  "4k11": "4k UHD, 3840x2160 @ 119.88Hz",
  "4k12": "4k UHD, 3840x2160 @ 120Hz",

  // 4K DCI Modes
  "4d23": "4k DCI, 4096x2160 @ 23.98Hz",
  "4d24": "4k DCI, 4096x2160 @ 24Hz",
  "4d25": "4k DCI, 4096x2160 @ 25Hz",
  "4d29": "4k DCI, 4096x2160 @ 29.96Hz",
  "4d30": "4k DCI, 4096x2160 @ 30Hz",
  "4d47": "4k DCI, 4096x2160 @ 47.95Hz",
  "4d48": "4k DCI, 4096x2160 @ 48Hz",
  "4d50": "4k DCI, 4096x2160 @ 50Hz",
  "4d59": "4k DCI, 4096x2160 @ 59.94Hz",
  "4d60": "4k DCI, 4096x2160 @ 60Hz",
  "4d95": "4k DCI, 4096x2160 @ 95.90Hz",
  "4d96": "4k DCI, 4096x2160 @ 96Hz",
  "4d10": "4k DCI, 4096x2160 @ 100Hz",
  "4d11": "4k DCI, 4096x2160 @ 119.88Hz",
  "4d12": "4k DCI, 4096x2160 @ 120Hz",

  // 8K UHD Modes
  "8k23": "8k UHD, 7680x4320 @ 23.98Hz",
  "8k24": "8k UHD, 7680x4320 @ 24Hz",
  "8k25": "8k UHD, 7680x4320 @ 25Hz",
  "8k29": "8k UHD, 7680x4320 @ 29.97Hz",
  "8k30": "8k UHD, 7680x4320 @ 30Hz",
  "8k47": "8k UHD, 7680x4320 @ 47.95Hz",
  "8k48": "8k UHD, 7680x4320 @ 48Hz",
  "8k50": "8k UHD, 7680x4320 @ 50Hz",
  "8k59": "8k UHD, 7680x4320 @ 59.94Hz",
  "8k60": "8k UHD, 7680x4320 @ 60Hz",

  // 8K DCI Modes
  "8d23": "8k DCI, 8192x4320 @ 23.98Hz",
  "8d24": "8k DCI, 8192x4320 @ 24Hz",
  "8d25": "8k DCI, 8192x4320 @ 25Hz",
  "8d29": "8k DCI, 8192x4320 @ 29.97Hz",
  "8d30": "8k DCI, 8192x4320 @ 30Hz",
  "8d47": "8k DCI, 8192x4320 @ 47.95Hz",
  "8d48": "8k DCI, 8192x4320 @ 48Hz",
  "8d50": "8k DCI, 8192x4320 @ 50Hz",
  "8d59": "8k DCI, 8192x4320 @ 59.94Hz",
  "8d60": "8k DCI, 8192x4320 @ 60Hz",

  // PC Modes
  vga6: "VGA, 640x480 @ 60Hz",
  svg6: "SVGA, 800x600 @ 60Hz",
  wxg5: "WXGA, 1440x900 @ 50Hz",
  wxg6: "WXGA, 1440x900 @ 60Hz",
  sxg5: "SXGA+, 1440x1080 @ 50Hz",
  sxg6: "SXGA+, 1440x1080 @ 60Hz",
  uxg5: "UXGA, 1600x1200 @ 50Hz",
  uxg6: "UXGA, 1600x1200 @ 60Hz",
  wux5: "WUXGA, 1920x1200 @ 50Hz",
  wux6: "WUXGA, 1920x1200 @ 60Hz",
  1945: "1920x1440 @ 50Hz",
  1946: "1920x1440 @ 60Hz",
  wqh5: "WQHD, 2560x1440 @ 50Hz",
  wqh6: "WQHD, 2560x1440 @ 60Hz",
  wqx5: "WQXGA, 2560x1600 @ 50Hz",
  wqx6: "WQXGA, 2560x1600 @ 60Hz",
};
