import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Switch, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import InputTextField from "../../../Fields/InputTextField";

import { required } from "../../../../lib/utils/formValidation";
import globalTranslations from "../../../../lib/translations";

import { FormV5Context } from "../../NodeChannelFormV5";

const { Item } = Form;

export default function SubtitlesSectionFormItem({ disabled, prefix }) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);
  const forced = Form.useWatch([...prefix, "forced"], form);
  const cea608Enabled = Form.useWatch([...prefix, "cea608Enabled"], form);
  const burningEnabled = Form.useWatch([...prefix, "burningEnabled"], form);

  return (
    <Row gutter={24}>
      <Col xs={24} lg={4}>
        <Item
          name={[...prefix, "burningEnabled"]}
          valuePropName="checked"
          label={<FormattedMessage id="SubtitlesSectionFormItem.burningEnabled" defaultMessage="Burning" />}
          rules={[required]}
        >
          <Switch
            disabled={disabled}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
          />
        </Item>
      </Col>
      <Col sm={24} lg={20}>
        {burningEnabled && (
          <InputTextField
            name={[...prefix, "customFontPath"]}
            disabled={disabled}
            label={<FormattedMessage id="SubtitlesSectionFormItem.customFontPath" defaultMessage="Custom font path" />}
            style
          />
        )}
      </Col>

      <Col xs={24} lg={4}>
        <Item
          name={[...prefix, "cea608Enabled"]}
          valuePropName="checked"
          label={<FormattedMessage id="SubtitlesSectionFormItem.cea608Enabled" defaultMessage="CEA608" />}
          rules={[required]}
        >
          <Switch
            disabled={disabled}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
          />
        </Item>
      </Col>
      <Col sm={24} lg={20}>
        {cea608Enabled && (
          <Item
            name={[...prefix, "cea608Mode"]}
            label={<FormattedMessage id="SubtitlesSectionFormItem.cea608Mode" defaultMessage="CEA608 Mode" />}
            rules={[required]}
          >
            <Select
              disabled={disabled}
              options={[
                {
                  value: "singleCode",
                  label: <FormattedMessage id="SubtitlesSectionFormItem.singleCode" defaultMessage="Single code" />,
                },
                {
                  value: "multiCode",
                  label: <FormattedMessage id="SubtitlesSectionFormItem.multiCode" defaultMessage="Multi code" />,
                },
              ]}
            />
          </Item>
        )}
      </Col>

      <Col xs={24} lg={4}>
        <Item
          name={[...prefix, "forced"]}
          valuePropName="checked"
          label={<FormattedMessage id="SubtitlesSectionFormItem.forced" defaultMessage="Forced" />}
          rules={[required]}
        >
          <Switch
            disabled={disabled}
            defaultChecked={forced}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
          />
        </Item>
      </Col>
      <Col sm={24} lg={20}>
        {forced && (
          <InputTextField
            name={[...prefix, "forcedCustomPath"]}
            disabled={disabled}
            label={
              <FormattedMessage id="SubtitlesSectionFormItem.forcedCustomPath" defaultMessage="Forced custom path" />
            }
          />
        )}
      </Col>
      <Col span={12}>
        <Item
          name={[...prefix, "textEncoding"]}
          label={<FormattedMessage id="SubtitlesSectionFormItem.textEncoding" defaultMessage="Text encoding" />}
          rules={[required]}
        >
          <Select
            disabled={disabled}
            options={[
              {
                value: "auto",
                label: <FormattedMessage id="SubtitlesSectionFormItem.auto" defaultMessage="Automatic" />,
              },
              { value: "utf8", label: <FormattedMessage id="SubtitlesSectionFormItem.utf8" defaultMessage="UTF-8" /> },
              {
                value: "utf16",
                label: <FormattedMessage id="SubtitlesSectionFormItem.utf16" defaultMessage="UTF-16" />,
              },
            ]}
          />
        </Item>
      </Col>
    </Row>
  );
}

SubtitlesSectionFormItem.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
};
