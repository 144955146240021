import queryString from "query-string";
import { history } from "../../store";

const queryURL = ({ parameter, value, removeKey, removeKeys }) => {
  const {
    location: { pathname, search },
  } = window;

  const parsedQueryString = queryString.parse(search);

  if (removeKey) {
    delete parsedQueryString[removeKey];
  }

  if (removeKeys) {
    removeKeys.forEach((key) => {
      delete parsedQueryString[key];
    });
  }

  const updatedQueryString = queryString.stringify({ ...parsedQueryString, [parameter]: value });

  history.replace({
    pathname,
    search: `?${updatedQueryString}`,
  });
};

export default queryURL;
