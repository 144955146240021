/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import { FileSearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Tag } from "antd";
import { FormattedMessage } from "react-intl";

import COUNTRIES_LIST from "../../../lib/utils/countries";
import flattenObject from "../../../lib/utils/flattenObject";
import queryURL from "../../../lib/utils/queryURL";

import WrongVersionConfig from "../../WrongVersionConfig";

const SearchFilter = ({ cwid, cnn, dataIndex, placeholder, accountList, hidden }) => {
  let searchInput = null;
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    return () => {
      queryURL({ removeKey: "search" });
    };
  }, []);

  const handleSearch = (selectedKeys, confirm) => {
    searchParams.set("search", selectedKeys[0]);
    setSearchParams(searchParams);

    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    searchParams.delete("search");
    setSearchParams(searchParams);

    clearFilters();
    handleSearch([], confirm);
  };

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <StyledInput
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
        />
        <Space>
          <StyledButton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<FileSearchOutlined />}
            size="small"
          >
            <span>
              <FormattedMessage id="general.search" defaultMessage="Search" />
            </span>
          </StyledButton>
          <StyledButton onClick={() => handleReset(clearFilters, confirm)} size="small">
            <FormattedMessage id="general.reset" defaultMessage="Reset" />
          </StyledButton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => {
      if (hidden) {
        return null;
      }

      return (
        <Button type={filtered ? "primary" : "default"} shape="circle" size="small" icon={<FileSearchOutlined />} />
      );
    },
    onFilter: (value, record) => {
      switch (dataIndex) {
        case "searchAll": {
          const { configTimestamp, incomplete, version, ...restValues } = record.config;

          const results = [];
          const configValuesArray = flattenObject(restValues);
          configValuesArray.forEach((configValue) => {
            if (configValue.includes(value.toLowerCase())) {
              results.push(configValue);
            }
          });

          if (results.length > 0) {
            return true;
          }

          return false;
        }
        case "channelName":
          return record?.config?.name ? record.config.name.toString().toLowerCase().includes(value.toLowerCase()) : "";
        case "cnn":
          return record?.cnn ? record.cnn.toString().toLowerCase().includes(value.toLowerCase()) : "";
        case "organizationName":
          return record?.name ? record.name.toString().toLowerCase().includes(value.toLowerCase()) : "";
        case "username":
          return record?.username ? record.username.toString().toLowerCase().includes(value.toLowerCase()) : "";
        case "path":
          return record?.path ? record.path.join("-").toLowerCase().includes(value.toLowerCase()) : "";
        case "port":
          return record?.port ? record.port.toString().includes(value) : "";
        case "creator":
          const creatorData = accountList.find((account) => account.id === record?.creator);

          return creatorData?.username ? creatorData.username.toString().includes(value) : "";
        case "virtualChannelName":
          return record?.channelName ? record.channelName.toString().toLowerCase().includes(value.toLowerCase()) : "";
        case "country":
          const countryData = COUNTRIES_LIST.find((country) => country.code === record?.country);

          return countryData?.name ? countryData?.name.toString().toLowerCase().includes(value.toLowerCase()) : "";
        default:
          return "";
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text, record) => {
      switch (dataIndex) {
        case "searchAll":
        case "channelName":
          return (
            <Link key={record?.channelId} to={`/node/${cwid}/${cnn}/channel/${record?.channelId}`}>
              <span>{record?.config?.name}</span>
              {record?.status?.configWrongVersionRunning && <WrongVersionConfig />}
            </Link>
          );
        case "port":
          return <Tag color="orange">{text}</Tag>;
        case "virtualChannelName":
          return <span>{record.channelName}</span>;
        case "cnn":
        case "organizationName":
          return <span key={record?.id || text}>{text}</span>;
        case "creator":
          const creatorData = accountList && accountList.find((account) => account.id === text);

          if (!creatorData) {
            return "Missing data";
          }

          return creatorData?.username;
        case "country":
          const countryData = COUNTRIES_LIST.find((country) => country.code === text);

          return countryData?.name;
        case "username":
          return <span>{record.username}</span>;
        default:
          return <FormattedMessage id="general.notSupported" defaultMessage="Not supported column" />;
      }
    },
  };
};

const StyledButton = styled(Button)`
  width: 90px;
`;

const StyledInput = styled(Input)`
  display: block;
  width: 188px;
  margin-bottom: 8px;
`;

export default SearchFilter;
