import React, { useContext, useState } from "react";
import { Col, Button, Form } from "antd";
import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { FormV5Context } from "../../../../NodeChannelFormV5";

// import RemoveButton from "../../../../RemoveButton";
import { DEFAULT_PLAYOUT_AUDIO_STREAM } from "../../../../../../lib/utils/defaultValues/defaultTranscodedTypes";

import AudioStreamsElement from "./AudioStreamsElement";

const { List } = Form;

export default function PlayoutAudioStreams({ disabled, prefix }) {
  const form = useContext(FormV5Context);
  const { getFieldValue } = form;

  const audioStreams = getFieldValue([...prefix, "audioStreams"]);

  const [isAudioStreams, setIsAudioStreams] = useState(audioStreams && audioStreams.length > 0);

  return (
    <List name={[...prefix, "audioStreams"]}>
      {(fields, { add }) => (
        <>
          {!isAudioStreams && (
            <Col span={24}>
              <Button
                type="dashed"
                style={{ width: "100%" }}
                onClick={() => {
                  add(DEFAULT_PLAYOUT_AUDIO_STREAM);
                  setIsAudioStreams(true);
                }}
              >
                <PlusOutlined />{" "}
                <FormattedMessage id="PlayoutAudioStreams.addAudioStream" defaultMessage="Add audio stream" />
              </Button>
            </Col>
          )}
          {fields.map((field, index) => {
            return (
              <AudioStreamsElement
                key={`${field.name}`}
                prefix={[...prefix, "audioStreams", index]}
                disabled={disabled}
                index={index}
              />
            );
          })}
        </>
      )}
    </List>
  );
}

PlayoutAudioStreams.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};
