import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

import getUserFromJWToken from "../lib/utils/getUserFromJWToken";
import { MAIN_THEME } from "../lib/utils/constants";

export const actions = {
  INITIALIZE: createAction("ACCOUNT/INITIALIZE"),
  SAVE_ACCOUNT_DATA: createAction("ACCOUNT/SAVE_ACCOUNT_DATA"),
  CLEAR_ACCOUNT_DATA: createAction("ACCOUNT/DELETE"),
  UPDATE_STRIPE_CUSTOMER: createAction("ACCOUNT/UPDATE_STRIPE_CUSTOMER"),
  CHANGE_MAIN_THEME: createAction("ACCOUNT/CHANGE_MAIN_THEME"),
  TURNSTILE_TOKEN: createAction("ACCOUNT/TURNSTILE_TOKEN"),
  SET_AUTH2FA: createAction("ACCOUNT/SET_AUTH2FA"),
  SET_EMAIL_OPTIN: createAction("ACCOUNT/SET_EMAIL_OPTIN"),
};

const initialState = new Map({
  username: null,
  token: null,
  loginData: null,
  stripeCustomer: null,
  activeStripeSubscription: null,
  mainTheme: window.localStorage.getItem("mainTheme") || MAIN_THEME.DARK,
  turnstileToken: false,
  auth2fa: false,
  emailOptIn: false,
});

const reducer = handleActions(
  {
    [actions.SAVE_ACCOUNT_DATA]: (state, { payload }) => state.merge({ ...payload }),
    [actions.SET_AUTH2FA]: (state, { payload }) => state.merge({ auth2fa: payload }),
    [actions.SET_EMAIL_OPTIN]: (state, { payload }) => state.merge({ emailOptIn: payload }),
    [actions.TURNSTILE_TOKEN]: (state, { payload }) => state.merge({ turnstileToken: payload }),
    [actions.UPDATE_STRIPE_CUSTOMER]: (state, { payload }) => state.merge({ stripeCustomer: payload }),
    [actions.INITIALIZE]: (state, { payload: { token } }) => {
      return token ? state.merge({ ...getUserFromJWToken(token) }) : state;
    },
    [actions.CHANGE_MAIN_THEME]: (state, { payload }) => state.merge({ mainTheme: payload }),
    [actions.CLEAR_ACCOUNT_DATA]: () =>
      initialState.set("mainTheme", window.localStorage.getItem("mainTheme") || MAIN_THEME.DARK),
  },
  initialState
);

export const selectors = {
  getUserId: (state) => state.getIn(["account", "id"]),
  getUser: (state) => state.getIn(["account", "username"]),
  getRole: (state) => state.getIn(["account", "role"]),
  getAuth2FA: (state) => state.getIn(["account", "auth2fa"]),
  getUserEmail: (state) => state.getIn(["account", "email"]),
  getEmailOptIn: (state) => state.getIn(["account", "emailOptIn"]),
  getAuthToken: (state) => state.getIn(["account", "token"]),
  getStripeCustomer: (state) => state.getIn(["account", "stripeCustomer"]),
  getActiveStripeSubscription: (state) => state.getIn(["account", "activeStripeSubscription"]),
  getStripeSession: (state) => state.getIn(["account", "stripeSession"]),
  getMainTheme: (state) => state.getIn(["account", "mainTheme"]),
  getTurnstileToken: (state) => state.getIn(["account", "turnstileToken"]),
};

export default reducer;
