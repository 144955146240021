import React, { useState } from "react";
import { Form, Row, Col, Tooltip, Checkbox, List, Alert } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";
import PropType from "prop-types";

import CloudChannelsService from "../../../../services/cloudChannels";

import VirtualChannelItemLayout from "./VirtualChannelItemLayout";
import VirtualChannelNavButtons from "../../../../components/VirtualChannelNavButtons";

import { errorNotification, successNotification } from "../../../../lib/utils/notification";

const { Item } = Form;

const VirtualChannelStep2 = ({ initialValues, currentStep, handleChangeStep, cloudId, onFinish }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  const listData = [
    <FormattedMessage
      id="VirtualChannelStep2.hub"
      defaultMessage={`Quickstream Hub helps partners to exchange
      live and linear content in a secure and a reliable way`}
    />,
  ];

  const handleFinish = (formData) => {
    CloudChannelsService.editCloudChannel(
      { ...formData, cloudId },
      {
        errorNotification: errorNotification(formatMessage),
        successNotification: successNotification(formatMessage),
        setLoading,
        handleChangeStep: onFinish,
      }
    );
  };

  return (
    <Form {...VirtualChannelItemLayout} onFinish={handleFinish} initialValues={initialValues} form={form}>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <StyledAlert
            message="Public catalog"
            description={
              <FormattedMessage
                id="VirtualChannelStep2.publicInfo"
                defaultMessage={`Please note that all changes in the public catalog needs
              to be reviewed by Quickstream team so it may take a while to publish update data`}
              />
            }
            type="warning"
          />
          <Item
            valuePropName="checked"
            label={
              <>
                <StyledLabel>
                  <FormattedMessage id="general.public" defaultMessage="Public" />
                </StyledLabel>
                <Tooltip
                  title={
                    <FormattedMessage
                      id="VirtualChannelStep2.publicHubDescription"
                      defaultMessage="Do you want your hub channel to be visible in public catalog?"
                    />
                  }
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </>
            }
            name="public"
          >
            <Checkbox />
          </Item>
        </Col>
        <Col span={12}>
          <List
            size="small"
            bordered={false}
            header={
              <StyledHeader>
                <FormattedMessage id="VirtualChannelStep2.benefits" defaultMessage="Benefits" />
                {": "}
              </StyledHeader>
            }
            dataSource={listData}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Col>
        <VirtualChannelNavButtons currentStep={currentStep} handleChangeStep={handleChangeStep} loading={loading} />
      </Row>
    </Form>
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: 15px;
`;

const StyledHeader = styled.span`
  font-weight: bold;
`;

const StyledLabel = styled.span`
  margin-right: 5px;
`;

VirtualChannelStep2.propTypes = {
  initialValues: PropType.object,
  currentStep: PropType.number.isRequired,
  handleChangeStep: PropType.func.isRequired,
  cloudId: PropType.number,
  onFinish: PropType.func.isRequired,
};

VirtualChannelStep2.defaultProps = {
  initialValues: null,
  cloudId: null,
};

export default VirtualChannelStep2;
