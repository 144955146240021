import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Badge } from "antd";

import themeColor from "../../../../../../lib/style/theme";
import IncompleteConfig from "../../../../../IncompleteConfig";

const { Ribbon } = Badge;

const MuxerProgramsElement = ({ label, title, handleClick, streams, index, incomplete }) => {
  const numberOfStreams = streams ? streams.length : 0;
  const noIncomplete = !incomplete || (incomplete && incomplete.length === 0);

  return (
    <Ribbon
      text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
      color={noIncomplete ? themeColor.green : themeColor.orange}
    >
      <StyledContainer onClick={() => handleClick(index)}>
        <StyledAsideType>{label}</StyledAsideType>
        <StyledBoldText>{title}</StyledBoldText>
        <div>
          <StyledText>
            <FormattedMessage id="MuxerProgramsElement.streams" defaultMessage="Streams" />:
          </StyledText>
          <StyledBoldText>{numberOfStreams}</StyledBoldText>
        </div>
      </StyledContainer>
    </Ribbon>
  );
};

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 110px;
  margin: 0 0 15px 30px;
  padding: 10px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  color: #fff;
  border-bottom: 1px solid ${themeColor.grayBorder};
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  padding: 5px 0;
  line-height: 24px;
  position: absolute;
  top: 130px;
  left: 0px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 130px;
  text-align: center;
  border-radius: 2px;
`;

MuxerProgramsElement.propTypes = {
  incomplete: PropTypes.arrayOf(PropTypes.number),
};

MuxerProgramsElement.defaultProps = {
  incomplete: null,
};

export default MuxerProgramsElement;
