import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import themeColor from "../../lib/style/theme";

const ReturnSignInFooter = ({ linkTitle }) => {
  return (
    <StyledDiv>
      {linkTitle}
      <StyledLink to="/">
        <FormattedMessage id="Registration.signIn" defaultMessage="Sign In" />
      </StyledLink>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  margin-top: 15px;
`;

const StyledLink = styled(Link)`
  margin: 0 5px;
  color: ${themeColor.orange};

  &:hover {
    color: ${themeColor.orangeHover};
  }
`;

ReturnSignInFooter.propTypes = {
  linkTitle: PropTypes.string.isRequired,
};

export default ReturnSignInFooter;
