import React from "react";
import { useSelector } from "react-redux";
import { Popover, Divider } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons";

import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";

import themeColor from "../../lib/style/theme";

export default function InstallNewNodeLicenses({
  usedTrialNodes,
  usedMediumNodes,
  usedLargeNodes,
  usedXLargeNodes,
  used2XLargeNodes,
}) {
  const cloudNodeLicenses = useSelector(MQTT_SELECTORS.getCloudNodeLicenses);

  return (
    <>
      <Popover
        content={
          <>
            <FormattedMessage id="InstallNewNodeLicenses.trial" defaultMessage="Trial" />:{" "}
            <StyledLicense>{usedTrialNodes}</StyledLicense> (limit: {cloudNodeLicenses?.nodesTrial})
            <Divider type="vertical" />
            <FormattedMessage id="InstallNewNodeLicenses.medium" defaultMessage="Medium" />:{" "}
            <StyledLicense>{usedMediumNodes}</StyledLicense> (limit: {cloudNodeLicenses?.nodesMedium})
            <Divider type="vertical" />
            <FormattedMessage id="InstallNewNodeLicenses.large" defaultMessage="Large" />:{" "}
            <StyledLicense>{usedLargeNodes}</StyledLicense> (limit: {cloudNodeLicenses?.nodesLarge})
            <Divider type="vertical" />
            <FormattedMessage id="InstallNewNodeLicenses.xLarge" defaultMessage="X Large" />:{" "}
            <StyledLicense>{usedXLargeNodes}</StyledLicense> (limit: {cloudNodeLicenses?.nodesXLarge})
            <Divider type="vertical" />
            <FormattedMessage id="InstallNewNodeLicenses.2xLarge" defaultMessage="2X Large" />:{" "}
            <StyledLicense>{used2XLargeNodes}</StyledLicense> (limit: {cloudNodeLicenses?.nodes2XLarge})
            <StyledHelpdesk>
              <FormattedMessage
                id="InstallNewNodeLicenses.needMoreNodes"
                defaultMessage="Need more? Please contact us"
              />
              {": "}
              <a href="mailto:hello@quickstream.tech">hello@quickstream.tech</a>.
            </StyledHelpdesk>
          </>
        }
        title={<FormattedMessage id="InstallNewNodeLicenses.cloudNodeLicenses" defaultMessage="Cloud node licenses" />}
        trigger="hover"
      >
        <StyledIcon />
      </Popover>
    </>
  );
}

const StyledIcon = styled(InfoCircleOutlined)`
  margin-left: 4px;
  color: ${themeColor.defaultBlueColor};
  cursor: pointer;
`;

const StyledLicense = styled.span`
  color: ${themeColor.orange};
`;

const StyledHelpdesk = styled.div`
  display: block;
  margin-top: 10px;
`;
