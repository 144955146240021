import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const actions = {
  SAVE_NODE_LICENSE_LIST: createAction("LICENSE/SAVE_NODE_LICENSE_LIST"),
  CLEAR_NODE_LICENSE_LIST: createAction("LICENSE/CLEAR_NODE_LICENSE_LIST"),
};

const initialState = new Map({
  licenseList: [],
});

const reducer = handleActions(
  {
    [actions.SAVE_NODE_LICENSE_LIST]: (state, { payload }) => state.merge({ licenseList: payload }),
    [actions.CLEAR_NODE_LICENSE_LIST]: () => initialState,
  },
  initialState
);

export const selectors = {
  getLicense: (state) => state.getIn(["license", "licenseList"]),
};

export default reducer;
