import React from "react";
import ReactDOM from "react-dom";
import { connect, Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import * as PropTypes from "prop-types";
import { ConfigProvider, theme } from "antd";
import "@fontsource/roboto";
import "@fontsource/roboto/500.css";
import "@fontsource/inter";
import "@fontsource/inter/500.css";

import store from "./store";
import withClearCache from "./ClearCache";
import { actions as ACCOUNT_ACTIONS } from "./ducks/account";
import { actions as LANG_ACTIONS, selectors as LANG_SELECTORS } from "./ducks/lang";

import useMainTheme from "./hooks/useMainTheme";
import themeColor from "./lib/style/theme";

import MQTTService from "./services/mqtt";

import { messages } from "./config";

import Layout from "./components/Layout";
import NotificationHolder from "./components/NotificationHolder";

const token = window.localStorage.getItem("authToken");
if (token) {
  store.dispatch(ACCOUNT_ACTIONS.INITIALIZE({ token }));
  MQTTService.initialize();
}

const initialLang = window.localStorage.getItem("lang") || "en";
store.dispatch(LANG_ACTIONS.CHANGE_LANG_DATA({ lang: initialLang }));

const MainComponent = ({ lang }) => {
  const { isDarkTheme } = useMainTheme();

  return (
    <ConfigProvider
      theme={{
        algorithm: [isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm, theme.compactAlgorithm],
        token: {
          fontFamily: "Inter, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
          colorPrimary: `${themeColor.orange}`,
          colorBgLayout: isDarkTheme ? `${themeColor.mainBackground}` : `${themeColor.lightMainBackground}`,
          colorLink: `${themeColor.yellowLink}`,
          colorLinkHover: `${themeColor.yellowLinkHover}`,
          colorBgHeader: `${themeColor.defaultBackground}`,
        },
        components: {
          Layout: {
            headerBg: `${themeColor.defaultBackground}`,
            triggerBg: `${themeColor.defaultBackground}`,
            siderBg: `${themeColor.defaultBackground}`,
            bodyBg: isDarkTheme ? `${themeColor.defaultDarkBGColor}` : `${themeColor.lightCardBackground}`,
          },
          Menu: {
            colorBgHeader: `${themeColor.defaultBackground}`,
            darkItemBg: `${themeColor.defaultBackground}`,
            darkSubMenuItemBg: `${themeColor.defaultBackground}`,
            darkPopupBg: `${themeColor.defaultBackground}`,
          },
          Card: {
            colorBgContainer: isDarkTheme ? `${themeColor.cardBackground}` : `${themeColor.defaultLightBGColor}`,
            headerBg: isDarkTheme ? `${themeColor.defaultDarkBGColor}` : `${themeColor.lightCardBackground}`,
          },
          Collapse: {
            contentBg: isDarkTheme ? `${themeColor.cardBackground}` : `${themeColor.defaultLightBGColor}`,
          },
          Breadcrumb: {
            itemColor: `${themeColor.orange}`,
          },
        },
      }}
    >
      <IntlProvider locale={lang} messages={messages[lang]}>
        <NotificationHolder />
        <Layout />
      </IntlProvider>
    </ConfigProvider>
  );
};

MainComponent.propTypes = {
  lang: PropTypes.string,
};

MainComponent.defaultProps = {
  lang: null,
};

const mapStateToProps = (state) => ({
  lang: LANG_SELECTORS.getLang(state),
});

const MainComponentWithState = connect(mapStateToProps, null)(MainComponent);

const MainComponentWithClearCache = withClearCache(MainComponentWithState);

ReactDOM.render(
  <Provider store={store}>
    <MainComponentWithClearCache />
  </Provider>,
  document.getElementById("root")
);
