import React from "react";
import { Form, Select, Col, Card, Row } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { required } from "../../lib/utils/formValidation";
import {
  GLOBAL_CODEC_AUDIO_SAMPLE_RATE,
  NDI_AUDIO_SAMPLE_FORMAT,
  SELECTOR_NDI_AUDIO_CHANNELS,
} from "../../lib/utils/constants";
import useNodeVersion from "../../hooks/useNodeVersion";

const { Item } = Form;
const { Option } = Select;

const NDIAudioFormats = ({ prefix, disabled }) => {
  const { isV5Node } = useNodeVersion();

  return (
    <StyledCard title={<FormattedMessage id="NDIAudioFormats.audioFormat" defaultMessage="Audio format" />}>
      <Row gutter={24}>
        <Item
          name={[...prefix, "sampleFormat"]}
          label={<FormattedMessage id="NDIAudioFormats.sampleFormat" defaultMessage="Sample format" />}
          rules={[required]}
          hidden
        >
          <Select disabled={disabled}>
            <Option key={NDI_AUDIO_SAMPLE_FORMAT.value} value={NDI_AUDIO_SAMPLE_FORMAT.value}>
              {NDI_AUDIO_SAMPLE_FORMAT.label}
            </Option>
          </Select>
        </Item>

        {isV5Node && (
          <Col span={4}>
            <Item
              name={[...prefix, "channels"]}
              label={<FormattedMessage id="NDIAudioFormats.audioChannels" defaultMessage="Audio channels" />}
              rules={[required]}
            >
              <Select disabled={disabled}>
                {SELECTOR_NDI_AUDIO_CHANNELS.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        )}

        <Col span={8}>
          <Item
            name={[...prefix, "sampleRate"]}
            label={<FormattedMessage id="Mpeg2AudioCodec.sampleRate" defaultMessage="Sample rate" />}
            rules={[required]}
          >
            <Select disabled={disabled}>
              {GLOBAL_CODEC_AUDIO_SAMPLE_RATE.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
  margin-top: 10px;
`;

export default NDIAudioFormats;
