import React from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

const translations = defineMessages({
  label: {
    id: "LatencyLabel.label",
    defaultMessage: "Latency (ms)",
  },
  labelInputTooltip: {
    id: "LatencyLabel.labelInputTooltip",
    // eslint-disable-next-line max-len
    defaultMessage: `Sets latency for SRT connection. If the sender demanded latency is higher than receiver latency. SRT sets latency to the sender's value.`,
  },
  labelOutputTooltip: {
    id: "LatencyLabel.labelOutputTooltip",
    defaultMessage: `Sets minimal latency for SRT connection.`,
  },
});

const LatencyLabel = (source) => {
  const { formatMessage } = useIntl();

  return (
    <StyledLabel>
      <span>{formatMessage(translations.label)}</span>
      <Tooltip
        placement="top"
        title={
          source === "input"
            ? formatMessage(translations.labelInputTooltip)
            : formatMessage(translations.labelOutputTooltip)
        }
      >
        <QuestionCircleFilled />
      </Tooltip>
    </StyledLabel>
  );
};

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

export default LatencyLabel;
