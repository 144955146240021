import React from "react";
import { Descriptions, Badge, Tooltip, Button } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CodeOutlined, LineChartOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";

import badgeStatus from "../../../lib/utils/badgeStatus";

import TranslatedStatus from "../../TranslatedStatus";

const { Item } = Descriptions;

export default function DetailSectionInputStatus({ inputs, channelId }) {
  const { cwid, cnn } = useParams();
  const navigate = useNavigate();

  const handleGoToLogs = (input) => {
    const sourceId = {
      c: +channelId,
      e: input?.e,
      p1: input?.p1,
      p2: input?.p2,
      p3: input?.p3,
      s: input?.s,
    };
    const sourceUrlParams = encodeURIComponent(JSON.stringify({ ...sourceId }));

    navigate(`/node/${cwid}/${cnn}/channel/${channelId}?source=${sourceUrlParams}&tab=logs`);
  };

  const handleGoToStats = (input) => {
    const IsMainInput = input.id === 1;

    navigate(
      // eslint-disable-next-line max-len
      `/node/${cwid}/${cnn}/channel/${channelId}?tab=statistics&subTab=inputs&sourceTab=${IsMainInput ? "mainInput" : "backupInput"}`
    );
  };

  return (
    <Descriptions bordered column={1} size="small">
      {inputs.map((input) => (
        <Item
          key={input.id}
          label={
            <div>
              {input.id === 1 && <FormattedMessage id="DetailSection.main" defaultMessage="Main" />}
              {input.id === 2 && <FormattedMessage id="DetailSection.backup" defaultMessage="Backup" />}
              {input.id === -1 && <FormattedMessage id="DetailSection.emergency" defaultMessage="Emergency" />}{" "}
              {input?.connectionInfo && <span>({input?.connectionInfo})</span>}
              <Tooltip
                title={
                  <FormattedMessage
                    id="DetailSectionPermissionLabel.goToInputsLogsview"
                    defaultMessage="Go to inputs logs view"
                  />
                }
              >
                <Button
                  type="link"
                  size="small"
                  onClick={() => handleGoToLogs(input)}
                  icon={<CodeOutlined />}
                  shape="circle"
                />
              </Tooltip>
              <Tooltip
                title={
                  <FormattedMessage
                    id="DetailSectionPermissionLabel.goToInputStats"
                    defaultMessage="Go to input statistic view"
                  />
                }
              >
                <Button
                  type="link"
                  size="small"
                  onClick={() => handleGoToStats(input)}
                  icon={<LineChartOutlined />}
                  shape="circle"
                />
              </Tooltip>
            </div>
          }
        >
          <Badge
            status={badgeStatus(input?.threadStatusText)}
            text={
              <StyledTranslatedStatus>
                <TranslatedStatus channelStatus={input?.threadStatusText} />
              </StyledTranslatedStatus>
            }
          />
        </Item>
      ))}
    </Descriptions>
  );
}

const StyledTranslatedStatus = styled.span`
  font-weight: bold !important;
`;

DetailSectionInputStatus.propTypes = {
  channelId: PropTypes.string.isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      connectionInfo: PropTypes.string.isRequired,
      errorCode: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      permissionId: PropTypes.number.isRequired,
      threadStatus: PropTypes.number.isRequired,
      threadStatusText: PropTypes.string.isRequired,
    })
  ).isRequired,
};
