import React, { Upload, Modal, message, Form } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { errorNotification } from "../../../../lib/utils/notification";
import { VALID_IMAGE_FORMATS } from "../../../../lib/utils/constants";

import { selectors as ACCOUNT_SELECTORS } from "../../../../ducks/account";
import CloudChannelsService from "../../../../services/cloudChannels";

import VirtualChannelNavButtons from "../../../../components/VirtualChannelNavButtons";
import CropImage from "../../../../components/CropImage";

const translations = defineMessages({
  max7MBValidation: {
    id: "UploadLogo.max7MBValidation",
    defaultMessage: "Image must smaller than 7MB!",
  },
  imageValidation: {
    id: "UploadLogo.imageValidation",
    defaultMessage: "is not image format!",
  },
});

const VirtualChannelStep1 = ({ currentStep, handleChangeStep, cloudId, logoUrl, bgUrl, screenShotUrls }) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [form] = Form.useForm();

  const token = useSelector(ACCOUNT_SELECTORS.getAuthToken);

  const logoKey = !!logoUrl && logoUrl.split("/").slice(-3);
  const bgKey = !!bgUrl && bgUrl.split("/").slice(-3);

  const imageHash = Date.now();

  const initialLogo = logoUrl ? [{ url: `${logoUrl}`, key: logoKey && logoKey.join("/") }] : [];
  const initialBackground = bgUrl ? [{ url: `${bgUrl}`, key: bgKey && bgKey.join("/") }] : [];
  const initialScreenshots = screenShotUrls.map((screenshotUrl) => {
    const screenshotUrlKey = !!screenshotUrl && screenshotUrl.split("/").slice(-3);

    return {
      url: `${screenshotUrl}`,
      key: screenshotUrlKey && screenshotUrlKey.join("/"),
    };
  });

  const [logo, setLogo] = useState(initialLogo);
  const [background, setBackground] = useState(initialBackground);
  const [screenShots, setScreenShots] = useState(initialScreenshots);

  const handleCancelPreview = () => setPreviewVisible(false);

  const onChangeLogo = ({ fileList: newLogo }) => {
    const parsedLogo = newLogo.map((file) => ({ ...file, name: `logo-${imageHash}.jpg` }));

    setLogo(parsedLogo);
  };

  const onChangeBackground = ({ fileList: newBackground }) => {
    const parsedBackground = newBackground.map((file) => ({ ...file, name: `background-${imageHash}.jpg` }));

    setBackground(parsedBackground);
  };

  const onChangeScreenShots = ({ fileList: newScreenShots }) => {
    const parsedScreenShots = newScreenShots.map((file, index) => ({
      ...file,
      name: `screenShot-${index}-${imageHash}.jpg`,
    }));

    setScreenShots(parsedScreenShots);
  };

  const beforeUpload = (file) => {
    const isValidSizeImage = file.size / 1024 / 1024 < 7;

    const isImage = VALID_IMAGE_FORMATS.includes(file.type);
    if (!isImage) {
      message.error(`${file.name} ${formatMessage(translations.imageValidation)}`);
    }

    if (!isValidSizeImage) {
      setLoading(false);
      message.error(formatMessage(translations.max7MBValidation));
    }

    return false;
  };

  const uploadedScreenshots = screenShots && screenShots.filter((screenshot) => !!screenshot.url);
  const uploadedScreenshotsUrls =
    uploadedScreenshots.length > 0 ? [...uploadedScreenshots.map((screenshot) => screenshot.url)] : null;

  const handleFinish = () => {
    const formData = new FormData();
    const allImagesArray = [...logo, ...background, ...screenShots];

    const imagesToUpload = [];
    setLoading(true);

    allImagesArray.forEach((img) => {
      if (img.type) {
        imagesToUpload.push(img);
      }
    });

    if (imagesToUpload.length > 0) {
      imagesToUpload.forEach((file) => {
        const parsedFile = new File([file.originFileObj], file.name, { type: file.type });

        formData.append("files", parsedFile);
      });

      formData.append("cloudId", cloudId);

      CloudChannelsService.uploadChannelImages(formData, {
        errorNotification,
        token,
        cloudId,
        logoUrl: logo.length > 0 ? logo[0].url : null,
        bgUrl: background.length > 0 ? background[0].url : null,
        screenShotUrls: uploadedScreenshotsUrls,
        setLoading,
        handleChangeStep: () => handleChangeStep(currentStep + 1),
      });
    } else {
      CloudChannelsService.editCloudChannel(
        {
          logoUrl: logo.length > 0 ? logo[0].url : null,
          bgUrl: background.length > 0 ? background[0].url : null,
          screenShotUrls: uploadedScreenshotsUrls,
          cloudId,
        },
        {
          errorNotification: errorNotification(formatMessage),
          setLoading,
          handleChangeStep: () => handleChangeStep(currentStep + 1),
        }
      );
    }
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;

    setPreviewImage(`${image.src}` || `${file.url}?${imageHash}`);
    setPreviewVisible(true);
  };

  const handleRemoveImg = (file) => {
    const parsedScreenShotsUrl =
      (uploadedScreenshotsUrls && uploadedScreenshotsUrls.filter((screenshot) => screenshot !== file.url)) || [];
    const parsedLogoUrl = logo && logo.filter((imgElement) => imgElement.url !== file.url);
    const parsedBGUrl = background && background.filter((imgElement) => imgElement.url !== file.url);

    const encodedKey = file?.key && encodeURIComponent(file?.key);

    if (encodedKey) {
      CloudChannelsService.deleteVirtualChannelImg(
        { key: encodedKey },
        {
          errorNotification: errorNotification(formatMessage),
        }
      );
    }

    CloudChannelsService.editCloudChannel(
      {
        logoUrl: parsedLogoUrl.length > 0 ? parsedLogoUrl[0].url : null,
        bgUrl: parsedBGUrl.length > 0 ? parsedBGUrl[0].url : null,
        screenShotUrls: parsedScreenShotsUrl,
        cloudId,
      },
      {
        errorNotification: errorNotification(formatMessage),
        setLoading,
        handleChangeStep: () => null,
      }
    );
  };

  return (
    <Form onFinish={handleFinish} form={form}>
      <StyledUpload>
        <StyledLabel>
          <FormattedMessage id="UploadLogo.logo" defaultMessage="Logo" />:
        </StyledLabel>
        {logo.length < 1 && <CropImage setFileList={setLogo} filename="logo" aspect={1} />}
        {logo.length > 0 && (
          <Upload
            beforeUpload={beforeUpload}
            listType="picture-card"
            fileList={logo}
            onChange={onChangeLogo}
            onPreview={onPreview}
            onRemove={handleRemoveImg}
            disabled={loading}
          />
        )}
      </StyledUpload>
      <StyledUpload>
        <StyledLabel>
          <FormattedMessage id="UploadLogo.coverPhoto" defaultMessage="Cover photo" />:
        </StyledLabel>
        {background.length < 1 && <CropImage setFileList={setBackground} filename="background" aspect={4 / 1} />}
        {background.length > 0 && (
          <Upload
            beforeUpload={beforeUpload}
            listType="picture-card"
            fileList={background}
            onChange={onChangeBackground}
            onPreview={onPreview}
            onRemove={handleRemoveImg}
            disabled={loading}
          />
        )}
      </StyledUpload>
      <StyledUpload>
        <StyledLabel>
          <FormattedMessage id="UploadLogo.screenShots" defaultMessage="Screen shots" />:
        </StyledLabel>
        {screenShots.length > 0 && (
          <Upload
            beforeUpload={beforeUpload}
            listType="picture-card"
            fileList={screenShots}
            onChange={onChangeScreenShots}
            onPreview={onPreview}
            onRemove={handleRemoveImg}
            disabled={loading}
          />
        )}
        {screenShots.length < 5 && (
          <CropImage setFileList={setScreenShots} filename="screenShot" moreImages={screenShots} aspect={16 / 9} />
        )}
      </StyledUpload>
      <VirtualChannelNavButtons currentStep={currentStep} handleChangeStep={handleChangeStep} loading={loading} />
      <Modal
        onCancel={handleCancelPreview}
        open={previewVisible}
        title={<FormattedMessage id="UploadLogo.preview" defaultMessage="Preview" />}
        footer={null}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Form>
  );
};

const StyledUpload = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .ant-upload-picture-card-wrapper {
    width: auto;
  }
`;

const StyledLabel = styled.span`
  margin-right: 5px;
  min-width: 80px;
`;

export default VirtualChannelStep1;
