import React, { useContext } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Divider } from "antd";

import { SELECT_MPEG2VIDEO_PRESETS, GLOBAL_VIDEO_RESIZE } from "../../../../../lib/utils/constants";

import themeColor from "../../../../../lib/style/theme";

import { FormV5Context } from "../../../NodeChannelFormV5";

function GlobalVideoCodecElement({ type, handleClick }) {
  const { getFieldValue } = useContext(FormV5Context);
  const globalVideoFormat = getFieldValue("globalVideoFormat");
  const { label: scaleVideoLabel } = GLOBAL_VIDEO_RESIZE.find(
    (resizeOption) => globalVideoFormat?.resize === resizeOption.value
  );

  const { label: encodingPresetLabel } = SELECT_MPEG2VIDEO_PRESETS.find(
    (preset) => preset.value === globalVideoFormat?.presetId
  );

  return (
    <StyledContainer onClick={handleClick}>
      <StyledAsideType>{type}</StyledAsideType>
      <StyledBoldTitle>
        <FormattedMessage id="GlobalVideoCodecElement.processingVideoFormat" defaultMessage="Processing video format" />
      </StyledBoldTitle>
      <div>
        <StyledText>
          <FormattedMessage id="GlobalVideoCodecElement.scaleVideo" defaultMessage="Scale video" />
          {": "}
        </StyledText>

        <StyledBoldTitle>{scaleVideoLabel}</StyledBoldTitle>
        <Divider type="vertical" />
        <StyledText>
          <FormattedMessage id="GlobalVideoCodecElement.frameRate" defaultMessage="Frame rate  (fps)" />
          {": "}
        </StyledText>
        <StyledBoldTitle>{globalVideoFormat?.fps}</StyledBoldTitle>
        <Divider type="vertical" />
        <StyledText>
          <FormattedMessage id="GlobalVideoCodecElement.pixFormat" defaultMessage="Pixel format" />
          {": "}
        </StyledText>
        <StyledBoldTitle>{globalVideoFormat?.pixFormat}</StyledBoldTitle>
      </div>
      <div>
        <StyledText>
          <FormattedMessage id="GlobalVideoCodecElement.encoding" defaultMessage="Encoding" />
          {": "}
        </StyledText>
        <StyledBoldTitle>{encodingPresetLabel}</StyledBoldTitle>
        <Divider type="vertical" />
      </div>
    </StyledContainer>
  );
}

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledBoldTitle = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 90px;
  margin: 0 0 15px 30px;
  padding: 10px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  color: #fff;
  padding: 5px 10px;
  line-height: 24px;
  position: absolute;
  top: 89px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 90px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;

GlobalVideoCodecElement.propTypes = {
  type: PropTypes.element.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default GlobalVideoCodecElement;
