import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Select, Form, Spin, Row, Col } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { connect, useSelector } from "react-redux";
import ImmutablePropTypes from "react-immutable-proptypes";
import { useParams } from "react-router-dom";

import store from "../../../../../store";
import MQTTService from "../../../../../services/mqtt";

import { SET_LOADING_FORMATS, selectors as FORMAT_SELECTORS } from "../../../../../ducks/nodeFormats";
import { selectors as NODE_CHANNEL_SELECTORS } from "../../../../../ducks/nodeChannels";

import InputNumberField from "../../../../Fields/InputNumberField";
import InputTextField from "../../../../Fields/InputTextField";

import { required } from "../../../../../lib/utils/formValidation";
import {
  SELECT_DEFAULT_AUTO,
  MESSAGE_TYPE,
  SELECTOR_SDI_AUDIO_CHANNELS,
  SELECTOR_SDI_VIDEO_SOURCE,
  SELECTOR_SDI_AUDIO_SOURCE,
} from "../../../../../lib/utils/constants";
import formatLabels from "../../../../../lib/utils/formatLabels";

import AdvancedSettings from "../../../../AdvancedSettings";

const { Item } = Form;
const { Option } = Select;

const translations = defineMessages({
  sdiDeviceAlreadyUsed: {
    id: "InputSDI.sdiDeviceAlreadyUsed",
    defaultMessage: "This SDI device is already used",
  },
});

function InputSDI({ getFieldValue, inputs, disabled, loadingFormats, formats, setFieldsValue, type, prefix }) {
  const { formatMessage } = useIntl();
  const [selectedDevice, setSelectedDevice] = useState(getFieldValue([...prefix, "device"]));
  const { cwid, id: channelId } = useParams();
  const sdiOutputs = useSelector((state) => NODE_CHANNEL_SELECTORS.getChannelSDIOutputs(state, channelId)) || [];

  useEffect(() => {
    const isFormatData = !!formats.get(selectedDevice);

    if (!isFormatData && selectedDevice) {
      MQTTService.sendMessageV5({
        topic: `node/${cwid}/in`,
        message: { msgType: MESSAGE_TYPE.GET_FORMATS, device: selectedDevice, type },
      });

      store.dispatch(SET_LOADING_FORMATS(true));
    }
  }, [selectedDevice, type, formats]);

  const selectedFormats =
    (formats.get(selectedDevice) &&
      formats.get(selectedDevice).map((format) => {
        return { label: format, value: format };
      })) ||
    [];

  const FORMATS_ARRAY = [SELECT_DEFAULT_AUTO, ...selectedFormats];
  const PARSED_FORMATS_ARRAY =
    FORMATS_ARRAY &&
    FORMATS_ARRAY.map((format) => {
      const parsedLabel = format.label in formatLabels ? formatLabels[format.label] : format.label;

      return { ...format, label: parsedLabel };
    });
  const isSelectedFormats = selectedFormats && selectedFormats.length > 0;

  const INPUTS_SELECT = inputs.map((input) => {
    return {
      label: input.desc,
      value: input.device,
    };
  });

  const handleChangeDevice = (value) => {
    setSelectedDevice(value);
    const inputForm = getFieldValue(...prefix);
    setFieldsValue({
      input: {
        device: inputForm.device,
        format: SELECT_DEFAULT_AUTO.value,
        type: inputForm.type,
      },
    });
  };

  const sameDeviceValidation = async () => {
    const inputSDIDevice = getFieldValue(["mainInput", "device"]);
    const backupInputSDIDevice = getFieldValue(["backupInput", "device"]);
    const sdiDevices = [
      ...sdiOutputs,
      ...(inputSDIDevice ? [{ source: "mainInput", device: inputSDIDevice }] : []),
      ...(backupInputSDIDevice ? [{ source: "backupInput", device: backupInputSDIDevice }] : []),
    ];

    const sdiAlreadyUsed = sdiDevices && sdiDevices.find((sdi) => sdi.device === selectedDevice);
    if (sdiAlreadyUsed && sdiAlreadyUsed?.source !== prefix[0]) {
      return Promise.reject(formatMessage(translations.sdiDeviceAlreadyUsed));
    }

    return Promise.resolve();
  };

  return (
    <Row gutter={24}>
      <Col span={8}>
        <InputNumberField name={[...prefix, "inputId"]} disabled={disabled} hidden />
        <InputTextField name={[...prefix, "inputName"]} disabled={disabled} hidden />
        <Item
          name={[...prefix, "device"]}
          label={<FormattedMessage id="InputSDI.device" defaultMessage="Device" />}
          rules={[required, { validator: sameDeviceValidation }]}
        >
          <Select disabled={disabled} onChange={handleChangeDevice}>
            {INPUTS_SELECT.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={8}>
        <Spin
          spinning={!isSelectedFormats}
          tip={
            <FormattedMessage
              id="InputSDI.waitingFormNodeFormats"
              defaultMessage="Waiting for node formats or device to be selected..."
            />
          }
        >
          <Item
            name={[...prefix, "format"]}
            label={<FormattedMessage id="InputSDI.format" defaultMessage="Format" />}
            rules={[required]}
          >
            <Select
              disabled={disabled || !isSelectedFormats}
              notFoundContent={loadingFormats ? <Spin size="small" /> : null}
            >
              {PARSED_FORMATS_ARRAY.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Spin>
      </Col>
      <Col span={8}>
        <Item
          name={[...prefix, "audioChannels"]}
          label={<FormattedMessage id="InputSDI.audioChannels" defaultMessage="Audio channels" />}
          rules={[required]}
        >
          <Select disabled={disabled}>
            {SELECTOR_SDI_AUDIO_CHANNELS.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <AdvancedSettings>
        <Row gutter={24}>
          <Col span={12}>
            <Item
              name={[...prefix, "videoSource"]}
              label={<FormattedMessage id="InputSDI.videoSource" defaultMessage="Video source" />}
              rules={[required]}
            >
              <Select disabled={disabled}>
                {SELECTOR_SDI_VIDEO_SOURCE.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item
              name={[...prefix, "audioSource"]}
              label={<FormattedMessage id="InputSDI.audioSource" defaultMessage="Audio source" />}
              rules={[required]}
            >
              <Select disabled={disabled}>
                {SELECTOR_SDI_AUDIO_SOURCE.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
      </AdvancedSettings>
    </Row>
  );
}

InputSDI.propTypes = {
  disabled: PropTypes.bool,
  getFieldValue: PropTypes.func.isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string,
      device: PropTypes.string,
    })
  ).isRequired,
  loadingFormats: PropTypes.bool.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  formats: ImmutablePropTypes.map.isRequired,
  type: PropTypes.string.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

InputSDI.defaultProps = {
  disabled: null,
};

const mapStateToProps = (state) => ({
  formats: FORMAT_SELECTORS.getFormats(state),
  loadingFormats: !!FORMAT_SELECTORS.getLoadingFormat(state),
});

export default connect(mapStateToProps, null)(InputSDI);
