import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import parseCloudId from "../../../lib/utils/parseCloudId";
import themeColor from "../../../lib/style/theme";

const ChannelDetailViewCardTitle = ({ channelData }) => (
  <>
    <FormattedMessage id="ChannelDetailView.hubChannel" defaultMessage="Hub channel" />
    {" - "}
    <StyledCloudId>
      {channelData.channelName} ( <span>{parseCloudId(channelData.cloudId)}</span> )
    </StyledCloudId>
  </>
);

const StyledCloudId = styled.span`
  font-weight: 600;
  font-size: 16px;

  span {
    color: ${themeColor.orange};
  }
`;

ChannelDetailViewCardTitle.propTypes = {
  channelData: PropTypes.shape({
    channelName: PropTypes.string.isRequired,
    cloudId: PropTypes.number.isRequired,
  }).isRequired,
};

export default ChannelDetailViewCardTitle;
