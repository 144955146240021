import React, { useState, useCallback } from "react";
import { Button, Modal, Row, Col, Steps, Tooltip, Drawer } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import store from "../../store";
import { selectors as NODE_SELECTORS, actions as NODE_ACTIONS } from "../../ducks/node";

import BasicModeQSSelectNodeStep from "../../components/BasicModeQS/BasicModeQSSelectNodeStep";
import BasicModeQSGeneralSettings from "../../components/BasicModeQS/BasicModeQSGeneralSettings";
import BasicModeOutput from "../../components/BasicModeQS/BasicModeOutput";
import NodePorts from "../Node/NodePorts";

import { DEFAULT_IN_QS_DIRECT } from "../../lib/utils/defaultValues/inputURLDefaultValues";
import { INPUT_URL_TYPE } from "../../lib/utils/constants";

export default function HubShareUseOnNode({ shareName, permissionId, cwid, cloudId, channelName }) {
  const [openModal, setOpenModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedNode, setSelectedNode] = useState(null);
  const [generalSettings, setGeneralSettings] = useState({});
  const openDrawer = useSelector(NODE_SELECTORS.getNodeOpenPortsDrawer);

  const inputSettings = {
    input: {
      mainConnection: {
        ...DEFAULT_IN_QS_DIRECT,
        permissionId,
        cloudId,
        cloudName: channelName,
        description: shareName,
      },
      type: INPUT_URL_TYPE.value,
    },
  };

  const handleClose = useCallback(() => {
    setOpenModal(false);
    setCurrentStep(0);
    store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
  }, [setOpenModal, setCurrentStep]);

  const handlePrevStep = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [setCurrentStep, currentStep]);

  const handleNextStep = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [setCurrentStep, currentStep]);

  const items = [
    {
      title: <FormattedMessage id="HubShareUseOnNode.node" defaultMessage="Node" />,
      description: <FormattedMessage id="HubShareUseOnNode.selectNode" defaultMessage="Select Node" />,
      content: (
        <BasicModeQSSelectNodeStep
          setSelectedNode={setSelectedNode}
          selectedNodeCwid={selectedNode?.cwid}
          handleNextStep={handleNextStep}
        />
      ),
    },
    {
      title: <FormattedMessage id="HubShareUseOnNode.baseInfo" defaultMessage="General" />,
      description: <FormattedMessage id="HubShareUseOnNode.generalSetting" defaultMessage="General settings" />,
      content: (
        <BasicModeQSGeneralSettings
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          channelName={shareName}
          setGeneralSettings={setGeneralSettings}
          generalSettings={generalSettings}
        />
      ),
    },
    {
      title: <FormattedMessage id="HubShareUseOnNode.output" defaultMessage="Output" />,
      description: <FormattedMessage id="HubShareUseOnNode.outputSettings" defaultMessage="Output settings" />,
      content: (
        <BasicModeOutput
          generalSettings={generalSettings}
          handlePrevStep={handlePrevStep}
          selectedNode={selectedNode}
          setOpenModal={setOpenModal}
          setCurrentStep={setCurrentStep}
          inputSettings={inputSettings}
        />
      ),
    },
  ];

  return (
    <>
      <Tooltip
        title={
          cwid ? <FormattedMessage id="HubShareUseOnNode.usedOnNode" defaultMessage="Already used in Node" /> : null
        }
      >
        <Button type="primary" onClick={() => setOpenModal(true)} disabled={cwid}>
          <FormattedMessage id="HubShareUseOnNode.useInNode" defaultMessage="Use in Node" />
        </Button>
      </Tooltip>
      <Modal
        destroyOnClose
        open={openModal}
        title={<FormattedMessage id="HubShareUseOnNode.newChannelOnNode" defaultMessage="New channel on Node" />}
        footer={null}
        onCancel={handleClose}
        width={1000}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Steps items={items} current={currentStep} />
          </Col>
          <Col span={24}>{items[currentStep].content}</Col>
        </Row>
      </Modal>
      <Drawer
        title={<FormattedMessage id="HubChannelUseOnNodeAction.usedPorts" defaultMessage="Used ports" />}
        placement="right"
        onClose={() => store.dispatch(NODE_ACTIONS.SET_NODE_DRAWER(false))}
        open={openDrawer}
        width={750}
        forceRender
        zIndex={1001}
      >
        <NodePorts activeLink={false} selectedCnn={selectedNode?.cnn} selectedCwid={selectedNode?.cwid} />
      </Drawer>
    </>
  );
}

HubShareUseOnNode.propTypes = {
  shareName: PropTypes.string.isRequired,
  permissionId: PropTypes.number.isRequired,
  cloudId: PropTypes.number.isRequired,
  cwid: PropTypes.string,
};

HubShareUseOnNode.defaultProps = {
  cwid: null,
};
