import React from "react";
import { Col, Row } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import InputNumberField from "../../../Fields/InputNumberField";
import { required } from "../../../../lib/utils/formValidation";

export default function MonitoringSectionFormItem({ prefix, disabled }) {
  return (
    <Row gutter={24}>
      <Col span={8}>
        <InputNumberField
          name={[...prefix, "freezeInverval"]}
          label={
            <FormattedMessage id="MonitoringSectionFormItem.freezeInverval" defaultMessage="Freeze inverval [s]" />
          }
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={8}>
        <InputNumberField
          name={[...prefix, "freezeSensitivity"]}
          label={
            <FormattedMessage
              id="MonitoringSectionFormItem.freezeSensitivity"
              defaultMessage="Freeze sensitivity [fraction of maximum value]"
            />
          }
          rules={[required]}
          disabled={disabled}
          step={0.0001}
          min={0}
        />
      </Col>
      <Col span={8}>
        <InputNumberField
          name={[...prefix, "port"]}
          label={<FormattedMessage id="MonitoringSectionFormItem.port" defaultMessage="Port" />}
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
      <Col span={8}>
        <InputNumberField
          name={[...prefix, "silenceInterval"]}
          label={
            <FormattedMessage id="MonitoringSectionFormItem.silenceInterval" defaultMessage="Silence interval [s]" />
          }
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={8}>
        <InputNumberField
          name={[...prefix, "silenceSensitivity"]}
          label={
            <FormattedMessage
              id="MonitoringSectionFormItem.silenceSensitivity"
              defaultMessage="Silence sensitivity [fraction of maximum value]"
            />
          }
          rules={[required]}
          disabled={disabled}
          step={0.0001}
          min={0}
        />
      </Col>
    </Row>
  );
}

MonitoringSectionFormItem.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
};
