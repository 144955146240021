import PropTypes from "prop-types";

const OUTPUT_STREAM_TYPE = {
  autoPid: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

const OUTPUT_PROGRAMS_TYPE = {
  id: PropTypes.number,
  serviceName: PropTypes.string,
  serviceProvider: PropTypes.string,
  streams: PropTypes.arrayOf(OUTPUT_STREAM_TYPE),
};

const OUTPUT_MUXER_TYPE = {
  muxrate: PropTypes.string,
  originalNetworkId: PropTypes.number,
  pmtStartPid: PropTypes.number,
  programs: PropTypes.arrayOf(OUTPUT_PROGRAMS_TYPE),
  startPid: PropTypes.number,
  transportStreamId: PropTypes.number,
  type: PropTypes.string,
  useCBRMode: PropTypes.bool,
};

export const OUTPUT_TYPE = {
  type: PropTypes.string.isRequired,
  outputId: PropTypes.number,
  outputName: PropTypes.string,
  interfaceIp: PropTypes.string.isRequired,
  destinationAddress: PropTypes.string,
  destinationPort: PropTypes.number,
  localPort: PropTypes.number.isRequired,
  setIPTOS: PropTypes.bool,
  setIPTTL: PropTypes.bool,
  setStreamId: PropTypes.PropTypes.bool,
  srtLatency: PropTypes.number,
  srtOverhead: PropTypes.number,
  encryptionEnabled: PropTypes.bool,
  encryptionPassword: PropTypes.string,
  encryptionKeyLen: PropTypes.number,
  udpTTL: PropTypes.number,
};

export default {
  ...OUTPUT_MUXER_TYPE,
  name: PropTypes.string,
  outputId: PropTypes.number,
  outputs: PropTypes.arrayOf(OUTPUT_TYPE),
  type: PropTypes.string,
};
