import React from "react";
import { Form, Input } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";

import { required } from "../../../../lib/utils/formValidation";

const { Item } = Form;

const translations = defineMessages({
  formats: {
    id: "InputRTMP.formats",
    defaultMessage: "rtmp:// or rtmpt:// or rtmpte:// or rtmps:// or rtmpte:// or rtmpts://",
  },
  wrongFormat: {
    id: "InputRTMP.wrongFormat",
    defaultMessage: "Supported formats: rtmp:// or rtmpt:// or rtmpte:// or rtmps:// or rtmpte:// or rtmpts://",
  },
});

const InputRTMP = ({ disabled }) => {
  const { formatMessage } = useIntl();

  return (
    <Item
      name={["input", "url"]}
      label={<FormattedMessage id="InputRTMP.url" defaultMessage="Url" />}
      rules={[
        required,
        {
          pattern: new RegExp(
            // eslint-disable-next-line max-len
            "^(rtmp://|rtmpt://|rtmpte://|rtmps://|rtmpte://|rtmpts://)"
          ),
          message: formatMessage(translations.wrongFormat),
        },
      ]}
    >
      <Input placeholder={formatMessage(translations.formats)} disabled={disabled} />
    </Item>
  );
};

InputRTMP.propTypes = {
  disabled: PropTypes.bool,
};

InputRTMP.defaultProps = {
  disabled: null,
};
export default InputRTMP;
