import React from "react";
import { Card, Form, Row, Col, Switch, Empty } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import RemoveButton from "../../../../../../RemoveButton";
import InputNumberField from "../../../../../../Fields/InputNumberField";
import { required } from "../../../../../../../lib/utils/formValidation";
import themeColor from "../../../../../../../lib/style/theme";

import ProgramStreamsAddButtons from "./ProgramStreamsAddButtons";
import StreamsSectionFormItem from "../../../../../StreamsSection/StreamsSectionFormItem";

const { Item, List } = Form;

const translations = defineMessages({
  confirmRemoveText: {
    id: "ProgramStreams.confirmRemove",
    defaultMessage: "Are you sure, you want to remove this stream?",
  },
  pid: {
    id: "ProgramStreams.pid",
    defaultMessage: "PID",
  },
  autoPidOn: {
    id: "ProgramStreams.autoPidOn",
    defaultMessage: "Auto PID On",
  },
  autoPidOff: {
    id: "ProgramStreams.autoPidOff",
    defaultMessage: "Auto PID OFF",
  },
});

const ProgramStreams = ({ disabled, prefix, form }) => {
  const { formatMessage } = useIntl();
  const { getFieldValue, setFieldsValue } = form;
  const channelInputType = getFieldValue(["input", "type"]);

  return (
    <Card title={<FormattedMessage id="ProgramStreams.servicesStreams" defaultMessage="Service Streams" />}>
      <List name={[...prefix, "streams"]}>
        {(fields, { add, remove }) => {
          const handleRemove = async (fieldName) => {
            await remove(fieldName);
            const muxedOutputs = getFieldValue(["muxedOutputs"]);
            setFieldsValue({ muxedOutputs });
          };

          const isAnyStreams = fields && fields.length > 0;

          if (!isAnyStreams) {
            return (
              <Card title={<FormattedMessage id="ProgramStreams.service" defaultMessage="Service" />}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                {!disabled && <ProgramStreamsAddButtons add={add} form={form} prefix={prefix} />}
              </Card>
            );
          }

          return (
            <>
              {fields.map((field) => {
                const autoPidSwitch = getFieldValue([...prefix, "streams", field.name, "autoPid"]);
                const fieldPrefix = [field.name];
                const streamType = getFieldValue([...prefix, "streams", field.name, "stream", "type"]);

                const handleChangeAutoPIDSwitch = (autoPid) => {
                  const muxedOutputs = getFieldValue(["muxedOutputs"]);
                  muxedOutputs[prefix[1]].muxer.programs[prefix[4]].streams[field.name].autoPid = autoPid;

                  if (autoPid) {
                    delete muxedOutputs[prefix[1]].muxer.programs[prefix[4]].streams[field.name].pid;
                  }
                  setFieldsValue({ muxedOutputs });
                };

                return (
                  <StyledCard
                    key={field.key}
                    title={<StyledStreamTitle>{streamType && streamType.toUpperCase()}</StyledStreamTitle>}
                    extra={
                      !disabled && (
                        <RemoveButton
                          handleRemove={handleRemove}
                          fieldName={field.name}
                          confirmRemoveText={formatMessage(translations.confirmRemoveText)}
                        />
                      )
                    }
                  >
                    <Row justify="start" gutter={24}>
                      <Col span={6}>
                        <Item name={[field.name, "autoPid"]} valuePropName="checked">
                          <Switch
                            defaultChecked={autoPidSwitch}
                            checkedChildren={formatMessage(translations.autoPidOn)}
                            unCheckedChildren={formatMessage(translations.autoPidOff)}
                            onChange={handleChangeAutoPIDSwitch}
                            disabled={disabled}
                          />
                        </Item>
                      </Col>

                      {!autoPidSwitch && (
                        <Col span={6}>
                          <InputNumberField
                            disabled={disabled}
                            name={[field.name, "pid"]}
                            label={formatMessage(translations.pid)}
                            rules={[required]}
                            min={32}
                            max={8191}
                          />
                        </Col>
                      )}

                      <StreamsSectionFormItem
                        detailView={disabled}
                        fieldPrefix={[...fieldPrefix, "stream"]}
                        prefix={[...prefix, "streams", field.name, "stream"]}
                        inputType={channelInputType}
                        form={form}
                      />
                    </Row>
                  </StyledCard>
                );
              })}
              {!disabled && <ProgramStreamsAddButtons add={add} form={form} prefix={prefix} />}
            </>
          );
        }}
      </List>
    </Card>
  );
};

const StyledStreamTitle = styled.span`
  color: #fff;
`;

const StyledCard = styled(Card)`
  margin-bottom: 25px;
  border-color: ${themeColor.orangeHover};

  .ant-card-head {
    background: ${themeColor.orange};
  }
`;

ProgramStreams.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  form: PropTypes.object.isRequired,
};

export default ProgramStreams;
