import React, { useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Table, Tag, Button, Tooltip, Card, Popconfirm } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import { DeleteOutlined, QuestionCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import store from "../../../store";
import MQTTService from "../../../services/mqtt";
import { selectors as NODE_SELECTORS } from "../../../ducks/node";
import { actions as LOADING_ACTIONS, selectors as LOADING_SELECTORS } from "../../../ducks/loadingData";
import { selectors as MQTT_SELECTORS } from "../../../ducks/mqtt";

import useNodeFingerprint from "../../../hooks/useNodeFingerprint";

import parseJwt from "../../../lib/utils/parseJwt";
import themeColor from "../../../lib/style/theme";
import { PERMANENT_LICENSE, MQTT_STATUS, LICENSES_TYPES, MESSAGE_TYPE } from "../../../lib/utils/constants";
import NodeLicenseListCardTitle from "./NodeLicenseListCardTitle";
import GlobalErrorBoundaries from "../../../components/ErrorBoundaries/GlobalErrorBoundaries";

const setLicenseTagColor = (licenseType) => {
  switch (licenseType) {
    case "professional":
      return "geekblue";
    case "enterprise":
      return "cyan";
    default:
      return "gold";
  }
};

const NodeLicenseList = ({ connection, licenseList, MQTTConnection, MQTTStatus }) => {
  const { cwid } = useParams();
  const fingerprint = useNodeFingerprint();
  const isConnection = MQTTConnection && MQTTStatus === MQTT_STATUS.CONNECTED;
  const isLoading = useSelector(LOADING_SELECTORS.getLoadingLicenses);

  useEffect(() => {
    if (isConnection) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_LICENSES,
        },
      });

      store.dispatch(LOADING_ACTIONS.SET_LOADING_LICENSES(true));
    }
  }, [connection, isConnection, cwid]);

  const nodeLicensesList = licenseList[cwid] || [];

  const parsedNodeLicenseList = nodeLicensesList.map((license) => {
    return { ...parseJwt(license.licenseToken), id: license.id, channelsUsed: license.channelsUsed };
  });

  const removeLicense = useCallback(
    (licenseId) => {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.DELETE_LICENSE,
          licenseId,
        },
      });
    },
    [cwid]
  );

  const setClassName = (record) => {
    if (record.licenseExpire === PERMANENT_LICENSE) {
      return "null";
    }

    if (moment().isAfter(record.licenseExpire)) {
      return "licenseExpired";
    }

    return "";
  };

  const columns = [
    {
      title: <FormattedMessage id="LicenseList.licenseId" defaultMessage="License ID" />,
      dataIndex: "licenseId",
      key: "licenseId",
      sorter: (currentValue, nextValue) => currentValue.licenseId.localeCompare(nextValue.licenseId),
    },
    {
      title: <FormattedMessage id="LicenseList.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
      sorter: (currentValue, nextValue) => currentValue.username.localeCompare(nextValue.username),
    },
    {
      title: <FormattedMessage id="LicenseList.licenseType" defaultMessage="License type" />,
      dataIndex: "licenseType",
      key: "licenseType",
      sorter: (currentValue, nextValue) => currentValue.licenseType.localeCompare(nextValue.licenseType),
      render: (licenseType, record) => (
        <Tag color={setLicenseTagColor(licenseType)} key={record.id}>
          {licenseType && licenseType.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: <FormattedMessage id="LicenseList.channels" defaultMessage="Channels" />,
      dataIndex: "engines",
      key: "engines",
      sorter: (currentValue, nextValue) => currentValue.engines - nextValue.engines,
      render: (engines, record) => {
        return (
          <>
            <Tooltip title={<FormattedMessage id="AccountsList.channelsInUse" defaultMessage="Channels used" />}>
              {record.channelsUsed}
            </Tooltip>
            /
            <Tooltip title={<FormattedMessage id="AccountsList.allChannels" defaultMessage="All channels" />}>
              {engines}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: <FormattedMessage id="LicenseList.expiration" defaultMessage="Expiration" />,
      dataIndex: "licenseExpire",
      key: "licenseExpire",
      sorter: (currentValue, nextValue) => currentValue.licenseExpire.localeCompare(nextValue.licenseExpire),
      render: (licenseExpire, record) => {
        if (licenseExpire === PERMANENT_LICENSE) {
          return <FormattedMessage id="general.never" defaultMessage="Never" />;
        }

        if (record.licenseType === LICENSES_TYPES.FREE) {
          return PERMANENT_LICENSE;
        }

        if (moment().isAfter(licenseExpire)) {
          return (
            <span>
              {licenseExpire}{" "}
              <Tooltip title={<FormattedMessage id="AccountsList.licenseExpired" defaultMessage="License expired" />}>
                <ExclamationCircleOutlined style={{ color: `${themeColor.darkRed}` }} />
              </Tooltip>
            </span>
          );
        }

        return <span>{licenseExpire}</span>;
      },
    },
    {
      key: "actions",
      width: 100,
      title: <FormattedMessage id="general.action" defaultMessage="Action" />,
      render: (text, { licenseId }) => (
        <Popconfirm
          title={
            <FormattedMessage
              id="AccountsList.confirmRemove"
              defaultMessage="Are you sure, you want to remove this license?"
            />
          }
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onConfirm={() => removeLicense(licenseId)}
          okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
          cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
        >
          <Button type="primary" danger icon={<DeleteOutlined />}>
            <span>
              <FormattedMessage id="general.remove" defaultMessage="Remove" />
            </span>
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <GlobalErrorBoundaries>
      <Card title={<NodeLicenseListCardTitle fingerprint={fingerprint} cwid={cwid} />}>
        <StyledTable
          columns={columns}
          rowClassName={setClassName}
          dataSource={parsedNodeLicenseList}
          pagination={false}
          bordered
          role="table"
          loading={isLoading}
          rowKey={(record) => record.licenseId}
          scroll={{ x: "calc(100vh - 250px)" }}
        />
      </Card>
    </GlobalErrorBoundaries>
  );
};

NodeLicenseList.propTypes = {
  connection: PropTypes.string,
  licenseList: PropTypes.object.isRequired,
  MQTTConnection: PropTypes.bool,
  MQTTStatus: PropTypes.string,
};

NodeLicenseList.defaultProps = {
  connection: null,
  MQTTStatus: null,
  MQTTConnection: null,
};

const StyledTable = styled(Table)`
  .licenseExpired {
    background-color: ${themeColor.logCritical};
  }
`;

const mapStateToProps = (state) => ({
  licenseList: NODE_SELECTORS.getNodeLicenses(state),
  connection: NODE_SELECTORS.getNodeCwid(state),
  MQTTStatus: MQTT_SELECTORS.getStatus(state),
  MQTTConnection: MQTT_SELECTORS.getMqttConnection(state),
});

export default connect(mapStateToProps, null)(NodeLicenseList);
