import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip, Row, Col } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import PropTypes from "prop-types";

export default function StatisticsGraphTitle({ hideTitle }) {
  return (
    <>
      {!hideTitle && <FormattedMessage id="StatisticsGraph.title" defaultMessage="Statistics chart" />}{" "}
      <Tooltip
        placement="top"
        title={
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <FormattedMessage
                id="StatisticsGraph.leftMouseTooltip"
                defaultMessage="Press and hold left mouse button to zoom in on the map"
              />
            </Col>
            <Col span={24}>
              <FormattedMessage
                id="StatisticsGraph.ctrlTooltip"
                defaultMessage='Hold "ctrl" and left mouse button to move around the map'
              />
            </Col>
          </Row>
        }
      >
        <QuestionCircleFilled />
      </Tooltip>
    </>
  );
}

StatisticsGraphTitle.propTypes = {
  hideTitle: PropTypes.bool,
};

StatisticsGraphTitle.defaultProps = {
  hideTitle: false,
};
