import React, { useCallback, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Card, Table, Button, Modal } from "antd";
import { useIntl, defineMessages } from "react-intl";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import CloudChannelsServices from "../../services/cloudChannels";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";
import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";

import { errorNotification } from "../../lib/utils/notification";
import parseCloudId from "../../lib/utils/parseCloudId";
import { NODE_CHANEL_TABS } from "../../lib/utils/constants";

import ChannelsValidation from "./ChannelsValidation";
import TransferVirtualChannels from "./TransferVirtualChannels";
import SearchFilter from "../../components/Filters/SearchFilter";
import HubChannelDetailView from "../../components/HubChannelDetailView";
import HubChannelListActions from "./HubChannelListActions";

const translations = defineMessages({
  name: {
    id: "OwnChannelList.name",
    defaultMessage: "Name",
  },
  id: {
    id: "OwnChannelList.id",
    defaultMessage: "ID",
  },
  owner: {
    id: "OwnChannelList.owner",
    defaultMessage: "Owner",
  },
  actions: {
    id: "general.actions",
    defaultMessage: "Actions",
  },
  status: {
    id: "OwnChannelList.publicStatus",
    defaultMessage: "Public status",
  },
  node: {
    id: "OwnChannelList.node",
    defaultMessage: "Node",
  },
  preview: {
    id: "OwnChannelList.preview",
    defaultMessage: "Preview",
  },
});

const HubChannelList = ({ ownChannelsList }) => {
  const { formatMessage } = useIntl();
  const [transferVCModalOpen, setTransferVCModalOpen] = useState(false);
  const [selectedTransferCloudId, setSelectedTransferCloudId] = useState(null);
  const nodes = useSelector(MQTT_SELECTORS.getNodes);
  const [openModal, setOpenModal] = useState(false);
  const [hubChannelData, setHubChannelData] = useState({});

  const handleOpenModal = useCallback((selectedChannelData) => {
    setOpenModal(true);
    setHubChannelData(selectedChannelData);
  }, []);

  async function fetchChannels() {
    CloudChannelsServices.getOwnChannels({ errorNotification: errorNotification(formatMessage) });
  }

  useEffect(() => {
    fetchChannels();
  }, []);

  const columns = [
    {
      key: "channelName",
      dataIndex: "channelName",
      title: formatMessage(translations.name),
      ...SearchFilter({
        dataIndex: "virtualChannelName",
        placeholder: "name",
      }),
      sorter: (currentValue, nextValue) => currentValue.channelName.localeCompare(nextValue.channelName),
      render: (channelName, record) => (
        <Button type="link" onClick={() => handleOpenModal(record)}>
          {channelName}
        </Button>
      ),
    },
    {
      key: "cloudId",
      dataIndex: "cloudId",
      width: 130,
      title: formatMessage(translations.id),
      sorter: (currentValue, nextValue) => currentValue.cloudId - nextValue.cloudId,
      render: (cloudId) => {
        return parseCloudId(cloudId);
      },
    },
    {
      key: "preview",
      dataIndex: "preview",
      width: 90,
      align: "center",
      title: formatMessage(translations.preview),
      render: (_text, record) => {
        return (
          <Button
            icon={<SearchOutlined />}
            type="dashed"
            shape="circle"
            href={`${process.env.REACT_APP_HUB_URL}/preview/channels/${record.cloudId}-${record.channelName}`}
            target="_blank"
            rel="noopener noreferrer"
          />
        );
      },
    },
    {
      key: "username",
      dataIndex: "username",
      title: formatMessage(translations.owner),
      sorter: (currentValue, nextValue) => currentValue.username.localeCompare(nextValue.username),
      render: (username) => {
        return <Link to={`/users/${username}`}>{username}</Link>;
      },
    },
    {
      key: "status",
      dataIndex: "status",
      title: formatMessage(translations.status),
      render: (text, record) => <ChannelsValidation channelData={record} />,
    },
    {
      key: "cwid",
      dataIndex: "cwid",
      title: formatMessage(translations.node),
      render: (nodeCwid, record) => {
        const nodeData = nodes.find((node) => node.cwid === nodeCwid);
        if (nodeData && nodeData.cnn) {
          return (
            <Link
              // eslint-disable-next-line max-len
              to={`/node/${nodeData.cwid}/${nodeData.cnn}/channels?search=${record?.channelName}&tab=${NODE_CHANEL_TABS.channelList}`}
            >
              {nodeData.cnn}
            </Link>
          );
        }

        const parsedNodeData = record?.cnn ? record.cnn : nodeCwid;

        return <span>{parsedNodeData}</span>;
      },
    },
    {
      key: "actions",
      title: formatMessage(translations.actions),
      render: (_, { id: channelId, cloudId, cwid, channelName }) => (
        <HubChannelListActions
          channelName={channelName}
          cwid={cwid}
          cloudId={cloudId}
          channelId={channelId}
          setTransferVCModalOpen={setTransferVCModalOpen}
          setSelectedTransferCloudId={setSelectedTransferCloudId}
          transferVCModalOpen={transferVCModalOpen}
        />
      ),
    },
  ];

  return (
    <Card>
      <Table columns={columns} dataSource={ownChannelsList} rowKey={(record) => record.id} />
      <TransferVirtualChannels
        transferVCModalOpen={transferVCModalOpen}
        setTransferVCModalOpen={setTransferVCModalOpen}
        selectedTransferCloudId={selectedTransferCloudId}
      />
      <Modal
        destroyOnClose
        width="60%"
        title={hubChannelData?.channelName}
        open={openModal}
        footer={false}
        onCancel={() => setOpenModal(false)}
      >
        <HubChannelDetailView hubChannelData={hubChannelData} />
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  ownChannelsList: CLOUD_CHANNELS_SELECTORS.getOwnChannels(state),
});

HubChannelList.propTypes = {
  ownChannelsList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cloudId: PropTypes.number.isRequired,
        channelName: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isEncrypted: PropTypes.bool.isRequired,
        public: PropTypes.bool.isRequired,
        userId: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
};

export default connect(mapStateToProps, null)(HubChannelList);
