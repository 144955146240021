import React from "react";
import { Collapse } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import themeColor from "../../lib/style/theme";

const AdvancedSettings = ({ children }) => {
  const collapseItems = [
    {
      key: "advancedSettings",
      label: <FormattedMessage id="general.advancedSettings" defaultMessage="Advanced settings" />,
      forceRender: true,
      children,
    },
  ];

  return <StyledCollapse items={collapseItems} />;
};

const StyledCollapse = styled(Collapse)`
  background-color: ${themeColor.orange} !important;
  width: 100%;
  margin: 15px 0;

  .ant-collapse-header {
    color: #fff !important;
  }
`;

AdvancedSettings.propTypes = {
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object, PropTypes.array]).isRequired,
};

export default AdvancedSettings;
