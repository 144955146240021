import React, { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Row, Spin, Card, Skeleton } from "antd";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import AccountService from "../../services/account";
import { errorNotification, successNotification } from "../../lib/utils/notification";

const AccountActivation = () => {
  const { activationToken } = useParams();
  const { formatMessage } = useIntl();

  useEffect(() => {
    async function activateAccount() {
      await AccountService.accountActivation(
        { activationToken },
        {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
        }
      );
    }

    activateAccount();
  }, [activationToken, formatMessage]);

  return (
    <StyledRow justify="center" align="middle">
      <Card>
        <Spin
          tip={<FormattedMessage id="AccountActivation.accountActivation" defaultMessage="Account activation..." />}
        >
          <Skeleton active />
        </Spin>
      </Card>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  min-width: 100%;
`;

export default AccountActivation;
