import React from "react";
import { Form, Select } from "antd";
import { FormattedMessage } from "react-intl";

import { required } from "../../lib/utils/formValidation";

const { Item } = Form;
const { Option } = Select;

const RegistrationFormRoles = () => {
  return (
    <Item label="role" name="role" rules={[required]}>
      <Select>
        <Option value="admin">
          <FormattedMessage id="RegistrationForm.roleAdmin" defaultMessage="Admin" />
        </Option>
        <Option value="user">
          <FormattedMessage id="RegistrationForm.roleUser" defaultMessage="User" />
        </Option>
        <Option value="support">
          <FormattedMessage id="RegistrationForm.roleSupport" defaultMessage="Support" />
        </Option>
      </Select>
    </Item>
  );
};

export default RegistrationFormRoles;
