import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Button, Space, Modal, Row, Col } from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";

import useNodeChannelConfig from "../../../../hooks/useNodeChannelConfig";
import { FormV5Context } from "../../NodeChannelFormV5";

import { DEFAULT_PLAYOUT_OUTPUT } from "../../../../lib/utils/defaultValues/defaultPlayoutOutput";
import { NEW } from "../../../../lib/utils/constants";
import { warningNotification } from "../../../../lib/utils/notification";

import EnginePlayoutFormItem from "./EnginePlayoutFormItem";
import EnginePlayoutOutputElement from "./EnginePlayoutOutputElement";
import EnginePlayoutOutputElementLabel from "./EnginePlayoutOutputElementLabel";

const translations = defineMessages({
  formValidationError: {
    id: "general.formValidationError",
    defaultMessage: "Form validation error: Please check all fields",
  },
});

export default function EnginePlayoutOutputsSection({ detailView, handleFinish, id }) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldValue, setFieldsValue, resetFields, validateFields } = form;
  const { channelConfig, outputs } = useNodeChannelConfig();
  const numberOfOutputs = outputs ? outputs.length : 0;
  const [outputSectionVisible, setOutputSectionVisible] = useState(null);

  const isNewOutput = outputSectionVisible === NEW;

  const handleAddNewOutput = () => {
    const numberOfNewOutput = outputs.length + 1;
    const parsedOutputs = [...outputs, { ...DEFAULT_PLAYOUT_OUTPUT, name: `Output-${numberOfNewOutput}` }];

    setFieldsValue({ outputs: parsedOutputs });
    setOutputSectionVisible(NEW);
  };

  const handleOKOutput = async () => {
    try {
      await validateFields();

      const updatedFormData = getFieldValue();
      handleFinish(updatedFormData);
      setOutputSectionVisible(null);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error - formValidation", error);
      warningNotification(formatMessage)(translations.formValidationError);
    }
  };

  const handleCancelOutput = () => {
    resetFields();
    setOutputSectionVisible(null);
  };

  const handleRemoveOutput = (name) => {
    const parsedFormOutputs = outputs.filter((transOutput) => transOutput.name !== name);
    const updatedFormData = {
      ...channelConfig,
      outputs: parsedFormOutputs,
    };

    handleFinish(updatedFormData);
    resetFields();
    setFieldValue("outputs", parsedFormOutputs);
    setOutputSectionVisible(null);
  };

  return (
    <>
      {outputs &&
        outputs.map((output, index) => {
          const handleClick = () => {
            resetFields();
            setOutputSectionVisible(index);
          };

          return (
            <Row gutter={24} key={output.name}>
              <Col span={24}>
                <EnginePlayoutOutputElement
                  label={<EnginePlayoutOutputElementLabel type={output?.type} />}
                  outputData={output}
                  handleClick={handleClick}
                  incomplete={output.incomplete}
                  type={output?.type}
                />
                <StyledModal
                  destroyOnClose
                  zIndex={100}
                  centered
                  title={
                    <>
                      <span>
                        <FormattedMessage id="EngineTranscodingSection.playout" defaultMessage="Playout" />
                      </span>
                      {` ${output.name}`}
                    </>
                  }
                  open={outputSectionVisible === index}
                  onOk={handleOKOutput}
                  onCancel={handleCancelOutput}
                  footer={detailView ? null : undefined}
                  okText={
                    <span>
                      <FormattedMessage id="general.save" defaultMessage="Save" />
                    </span>
                  }
                  okButtonProps={{ icon: <SaveOutlined /> }}
                >
                  <EnginePlayoutFormItem
                    detailView={detailView}
                    handleFinish={handleFinish}
                    handleRemove={handleRemoveOutput}
                    id={id}
                    prefix={["outputs", index]}
                    outputData={output}
                    isNewOutput={isNewOutput && setOutputSectionVisible}
                  />
                </StyledModal>
              </Col>
            </Row>
          );
        })}
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {!detailView && (
          <Button block type="dashed" icon={<PlusOutlined />} onClick={handleAddNewOutput}>
            <span>
              <FormattedMessage id="EnginePlayoutOutputsSection.addOutput" defaultMessage="Add Output" />
            </span>
          </Button>
        )}
      </Space>
      <StyledModal
        zIndex={100}
        centered
        title={
          <FormattedMessage id="EnginePlayoutOutputsSection.newPlayoutOutputs" defaultMessage="New playout output" />
        }
        open={isNewOutput}
        onOk={handleOKOutput}
        onCancel={handleCancelOutput}
        destroyOnClose
      >
        <EnginePlayoutFormItem
          handleFinish={handleFinish}
          handleRemove={handleRemoveOutput}
          id={id}
          prefix={["outputs", numberOfOutputs]}
          footer={detailView ? null : undefined}
          isNewOutput={isNewOutput && setOutputSectionVisible}
        />
      </StyledModal>
    </>
  );
}

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

EnginePlayoutOutputsSection.propTypes = {
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  id: PropTypes.string,
};

EnginePlayoutOutputsSection.defaultProps = {
  detailView: null,
  id: null,
};
