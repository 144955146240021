import React, { useCallback, useState, useEffect } from "react";
import { LockOutlined, UserOutlined, MailOutlined } from "@ant-design/icons";
import { Input, Button, Form, Divider, Row, Col, Spin, Skeleton } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

import AccountService from "../../services/account";

import { errorNotification, successNotification } from "../../lib/utils/notification";
import { az09, max32characters, password, required } from "../../lib/utils/formValidation";
import getUserFromJWToken from "../../lib/utils/getUserFromJWToken";

import TermsOfUseAndPrivacyPolicy from "../TermsOfUseAndPrivacyPolicy";
import EmailOptIn from "../EmailOptIn";
import OptinTips from "../OptinTips";
import RegistrationFormRoles from "./RegistrationFormRoles";

const { Item } = Form;
const { Password } = Input;

const translations = defineMessages({
  confirmPassword: {
    id: "RegistrationForm.confirmPassword",
    defaultMessage: "Confirm password",
  },
  confirmPasswordNoInput: {
    id: "RegistrationForm.validationConfirmPassword",
    defaultMessage: "Field required",
  },
  confirmPasswordNotIdentical: {
    id: "RegistrationForm.validationNotIdentical",
    defaultMessage: "Confirm password need to be the same as password",
  },
  username: {
    id: "RegistrationForm.username",
    defaultMessage: "Username",
  },
  email: {
    id: "RegistrationForm.email",
    defaultMessage: "Business email",
  },
  usernameNoInput: {
    id: "RegistrationForm.validationNoUsername",
    defaultMessage: "Please input username",
  },
  password: {
    id: "RegistrationForm.password",
    defaultMessage: "Password",
  },
  passwordNoInput: {
    id: "RegistrationForm.validationNoPassword",
    defaultMessage: "Please input password",
  },
  passwordTooShort: {
    id: "RegistrationForm.validationMinLength",
    defaultMessage: "Password must be at least 8 characters long",
  },
  usernameToShort: {
    id: "RegistrationForm.usernameValidationMinLength",
    defaultMessage: "Username must be at least 5 characters long",
  },
  roleRequired: {
    id: "RegistrationForm.roleRequired",
    defaultMessage: "Role is required",
  },
  notValidEmail: {
    id: "general.notValidEmail",
    defaultMessage: "Invalid email format",
  },
});

const RegistrationForm = ({ adminMode, afterSubmitCallback }) => {
  const navigate = useNavigate();
  const [initialEmail, setInitialEmail] = useState();
  const { token } = useParams();
  const turnstileToken = useSelector(ACCOUNT_SELECTORS.getTurnstileToken);

  useEffect(() => {
    async function checkToken() {
      try {
        const { email } = getUserFromJWToken(token);

        setInitialEmail(email);
      } catch (error) {
        navigate("/");
      }
    }

    if (token) {
      checkToken();
    }
  }, [token]);

  const [form] = Form.useForm();
  const { getFieldValue, resetFields } = form;

  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [termOfUseAcceptance, setTermOfUseAcceptance] = useState(false);
  const [emailOptIn, setEmailOptIn] = useState(false);

  const handleFinish = useCallback(
    async (formData) => {
      const { username, email, password: fromPassword, emailOptIn: formEmailOptIn } = formData;

      setLoading(true);

      if (token) {
        await AccountService.createInvitationUser(
          { username, email, password: fromPassword, emailOptIn: formEmailOptIn, turnstileToken, token },
          {
            errorNotification: errorNotification(formatMessage),
            successNotification: successNotification(formatMessage),
            setLoading,
          }
        );
        setLoading(false);
        setTermOfUseAcceptance(false);
        setEmailOptIn(false);
        resetFields();

        return;
      }
      const response = await AccountService.createUser(
        { ...formData, turnstileToken },
        {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
          adminMode,
          afterSubmitCallback,
        }
      );
      setLoading(false);
      setTermOfUseAcceptance(false);
      setEmailOptIn(false);
      resetFields();
      if (response && !adminMode) {
        navigate("/login");
      }
    },
    [formatMessage, adminMode, afterSubmitCallback, setLoading, resetFields, token, turnstileToken]
  );

  const acceptanceReady = termOfUseAcceptance && emailOptIn;

  const validatePassword = useCallback(
    (rule, value) => {
      const passwordValue = getFieldValue("password");
      if (!value) {
        return Promise.reject(formatMessage(translations.confirmPasswordNoInput));
      }
      if (value !== passwordValue) {
        return Promise.reject(formatMessage(translations.confirmPasswordNotIdentical));
      }

      return Promise.resolve();
    },
    [formatMessage, getFieldValue]
  );

  if (token && !initialEmail) {
    return (
      <Spin tip={<FormattedMessage id="RegistrationForm.loading" defaultMessage="Loading initial email" />}>
        <Skeleton active />
      </Spin>
    );
  }

  return (
    <StyledWrapper>
      <StyledFormContainer>
        <StyledForm onFinish={handleFinish} form={form} initialValues={{ email: initialEmail }}>
          <Row gutter={24}>
            <Col span={12}>
              <Item
                name="username"
                rules={[
                  required,
                  { min: 5, message: formatMessage(translations.usernameToShort) },
                  az09,
                  max32characters,
                ]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder={formatMessage(translations.username)}
                  autoComplete="nickname"
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                name="email"
                rules={[required, { type: "email", message: formatMessage(translations.notValidEmail) }]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder={formatMessage(translations.email)}
                  autoComplete="nickname"
                  disabled={initialEmail}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                name="password"
                rules={[
                  required,
                  { min: 8, message: formatMessage(translations.passwordTooShort) },
                  password,
                  max32characters,
                ]}
              >
                <Password
                  prefix={<LockOutlined />}
                  type="password"
                  autoComplete="new-password"
                  placeholder={formatMessage(translations.password)}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item name="confirmPassword" rules={[{ validator: validatePassword }, password]}>
                <Password
                  prefix={<LockOutlined />}
                  type="password"
                  autoComplete="new-password"
                  placeholder={formatMessage(translations.confirmPassword)}
                />
              </Item>
            </Col>
          </Row>
          {adminMode && <RegistrationFormRoles />}
          {!adminMode && (
            <>
              <Divider />
              <TermsOfUseAndPrivacyPolicy
                termOfUseAcceptance={termOfUseAcceptance}
                setTermOfUseAcceptance={setTermOfUseAcceptance}
              />
            </>
          )}
          <Divider />
          <EmailOptIn setEmailOptIn={setEmailOptIn} />
          <OptinTips />
          <Divider />
          <StyledButton
            type="primary"
            htmlType="submit"
            disabled={!turnstileToken || (adminMode ? loading : loading || !acceptanceReady)}
            loading={loading || !turnstileToken}
          >
            <FormattedMessage id="RegistrationForm.submit" defaultMessage="Create new account" />
          </StyledButton>
        </StyledForm>
      </StyledFormContainer>
    </StyledWrapper>
  );
};

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
`;

const StyledForm = styled(Form)`
  min-width: 500px;
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 840px;
`;

export default RegistrationForm;
