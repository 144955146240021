import React from "react";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { DisconnectOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const LicenseListMissingNodeData = ({ cwid }) => {
  return (
    <Tooltip
      title={
        <span style={{ textAlign: "center" }}>
          <div>
            <FormattedMessage id="LicenseListMissingNodeData.missingNodeData" defaultMessage="Missing node data" />.
          </div>
          <div>
            <FormattedMessage
              id="LicenseListMissingNodeData.nodeNeedToBeConnected"
              defaultMessage="Node need to be connected to cloud for this user to show node name"
            />
            .
          </div>
          <div>{cwid}</div>
        </span>
      }
    >
      <DisconnectOutlined />
    </Tooltip>
  );
};

LicenseListMissingNodeData.propTypes = {
  cwid: PropTypes.string.isRequired,
};

export default LicenseListMissingNodeData;
