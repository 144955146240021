import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Switch } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import InputNumberField from "../../../Fields/InputNumberField";
import InputTextField from "../../../Fields/InputTextField";

import { required } from "../../../../lib/utils/formValidation";
import globalTranslations from "../../../../lib/translations";

import { FormV5Context } from "../../NodeChannelFormV5";

const { Item } = Form;

const translations = defineMessages({
  useBackup: {
    id: "FileSourceFormItem.useBackup",
    defaultMessage: "Use backup",
  },
  bufferFrames: {
    id: "FileSourceFormItem.bufferFrames",
    defaultMessage: "Buffer frames",
  },
  bufferFramesStart: {
    id: "FileSourceFormItem.bufferFramesStart",
    defaultMessage: "Buffer frames start",
  },
  fileCheckInterval: {
    id: "FileSourceFormItem.fileCheckInterval",
    defaultMessage: "File check interval [s]",
  },
  getFrameTimeout: {
    id: "FileSourceFormItem.getFrameTimeout",
    defaultMessage: "Get frame timeout [ms]",
  },
  maxInterstreamDistance: {
    id: "FileSourceFormItem.maxInterstreamDistance",
    defaultMessage: "Max interstream distance [ms]",
  },
  maxRestartTime: {
    id: "FileSourceFormItem.maxRestartTime",
    defaultMessage: "Max restart time [ms]",
  },
  tcOutRelaxedLimitFrames: {
    id: "FileSourceFormItem.tcOutRelaxedLimitFrames",
    defaultMessage: "Tc out relaxed limit frames",
  },
  openTimeout: {
    id: "FileSourceFormItem.openTimeout",
    defaultMessage: "Open timeout [ms]",
  },
  reinitDelay: {
    id: "FileSourceFormItem.reinitDelay",
    defaultMessage: "Reinitialization delay [ms]",
  },
});

export default function FileSourceFormItem({ disabled, prefix }) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);
  const useBackup = Form.useWatch([...prefix, "useBackup"], form);

  return (
    <Row gutter={24}>
      <Col span={10}>
        <InputTextField
          name={[...prefix, "customPathFiles"]}
          disabled={disabled}
          label={<FormattedMessage id="FileSourceFormItem.customPathFiles" defaultMessage="Custom path files" />}
        />
      </Col>
      <Col span={4}>
        <Item
          name={[...prefix, "useBackup"]}
          valuePropName="checked"
          label={formatMessage(translations.useBackup)}
          rules={[required]}
        >
          <Switch
            disabled={disabled}
            defaultChecked={useBackup}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
          />
        </Item>
      </Col>

      <Col span={10}>
        {useBackup && (
          <InputTextField
            name={[...prefix, "customPathBackup"]}
            disabled={disabled}
            label={<FormattedMessage id="FileSourceFormItem.customPathBackup" defaultMessage="Custom path backup" />}
          />
        )}
      </Col>
      <Col span={10}>
        <InputTextField
          name={[...prefix, "customAdBreakFile"]}
          disabled={disabled}
          label={<FormattedMessage id="FileSourceFormItem.customAdBreakFile" defaultMessage="Custom ad break file" />}
        />
      </Col>
      <Col span={14}>
        <InputTextField
          name={[...prefix, "audioMatrixForAdBreak"]}
          disabled={disabled}
          label={
            <FormattedMessage
              id="FileSourceFormItem.audioMatrixForAdBreak"
              defaultMessage="Audio matrix for ad break"
            />
          }
          rules={[required]}
        />
      </Col>
      <Col span={10}>
        <InputTextField
          name={[...prefix, "customEmergencyFile"]}
          disabled={disabled}
          label={
            <FormattedMessage id="FileSourceFormItem.customEmergencyFile" defaultMessage="Custom emergency  file" />
          }
          rules={[required]}
        />
      </Col>
      <Col span={14}>
        <InputTextField
          name={[...prefix, "audioMatrixForEmergency"]}
          disabled={disabled}
          label={
            <FormattedMessage
              id="FileSourceFormItem.audioMatrixForEmergency"
              defaultMessage="Audio matrix for emergency"
            />
          }
          rules={[required]}
        />
      </Col>
      <Col span={12}>
        <InputTextField
          name={[...prefix, "fileNameSuffix"]}
          disabled={disabled}
          label={<FormattedMessage id="FileSourceFormItem.fileNameSuffix" defaultMessage="File name suffix" />}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "bufferFrames"]}
          label={formatMessage(translations.bufferFrames)}
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "bufferFramesStart"]}
          label={formatMessage(translations.bufferFramesStart)}
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "fileCheckInterval"]}
          label={formatMessage(translations.fileCheckInterval)}
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "getFrameTimeout"]}
          label={formatMessage(translations.getFrameTimeout)}
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "maxInterstreamDistance"]}
          label={formatMessage(translations.maxInterstreamDistance)}
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "maxRestartTime"]}
          label={formatMessage(translations.maxRestartTime)}
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "tcOutRelaxedLimitFrames"]}
          label={formatMessage(translations.tcOutRelaxedLimitFrames)}
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "openTimeout"]}
          label={formatMessage(translations.openTimeout)}
          rules={[required]}
          disabled={disabled}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "reinitDelay"]}
          label={formatMessage(translations.reinitDelay)}
          rules={[required]}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
}

FileSourceFormItem.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
};
