import React, { useState, useContext } from "react";
import { Card, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { SaveOutlined } from "@ant-design/icons";

import { FormV5Context } from "../../NodeChannelFormV5";

import useNodeChannelConfig from "../../../../hooks/useNodeChannelConfig";

import CGSectionFormItem from "./CGSectionFormItem";
import EnginePlayoutElement from "../../../EnginePlayoutElement";

export default function CGSection({ disabled, handleFinish }) {
  const [open, setOpen] = useState(false);
  const form = useContext(FormV5Context);

  const { channelConfig, cg } = useNodeChannelConfig();

  const { resetFields, getFieldValue } = form;

  const handleSaveCG = () => {
    const formData = getFieldValue("cg");
    handleFinish({ ...channelConfig, cg: formData });
    setOpen(false);
  };

  return (
    <Card title={<FormattedMessage id="FileSourceSection.cg" defaultMessage="CG" />}>
      <EnginePlayoutElement
        label={<FormattedMessage id="FileSourceSection.cg" defaultMessage="CG" />}
        handleClick={() => setOpen(true)}
        data={cg}
      />
      <Modal
        title={<FormattedMessage id="FileSourceSection.cg" defaultMessage="CG" />}
        centered
        destroyOnClose
        open={open}
        onCancel={() => {
          resetFields();
          setOpen(false);
        }}
        onOk={handleSaveCG}
        width={1280}
        footer={disabled ? null : undefined}
        okText={
          <span>
            <FormattedMessage id="general.save" defaultMessage="Save" />
          </span>
        }
        okButtonProps={{ icon: <SaveOutlined /> }}
      >
        <CGSectionFormItem prefix={["cg"]} disabled={disabled} />{" "}
      </Modal>
    </Card>
  );
}

CGSection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleFinish: PropTypes.func.isRequired,
};
