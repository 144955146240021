import React from "react";
import { Col, Select, Form, Tooltip } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

import InputTextFiled from "../../Fields/InputTextField";
import InputNumberField from "../../Fields/InputNumberField";

import { FILE_TIME_SPAN_OPTIONS } from "../../../lib/utils/constants";
import { required } from "../../../lib/utils/formValidation";

const { Item } = Form;

const translations = defineMessages({
  fileDurationTooltip: {
    id: "OutputToFileSection.fileDurationTooltip",
    defaultMessage: "System records stream and divides recordings into files of a defined duration",
  },
  recordingPeriodTooltip: {
    id: "OutputToFileSection.recordingPeriodTooltip",
    defaultMessage:
      "This parameter defines how many days should be kept on a drive. Older files are deleted automatically",
  },
  storagePathTooltip: {
    id: "OutputToFileSection.storagePathTooltip",
    defaultMessage: `Defines where the system should store the files. If not specified it keeps
    file in /opt/quickstream5/ for Linux and c:/ProgramData/Quickstream/Quickstream Node….. for Windows`,
  },
});

export default function OutputToFileSection({ prefix, disabled }) {
  const { formatMessage } = useIntl();

  return (
    <>
      <Col span={6}>
        <Item
          name={[...prefix, "fileTimeSpan"]}
          label={
            <>
              <FormattedMessage id="OutputToFileSection.fileDuration" defaultMessage="File duration [min]" />
              <StyledTooltip placement="top" title={formatMessage(translations.fileDurationTooltip)}>
                <QuestionCircleFilled />
              </StyledTooltip>
            </>
          }
          rules={[required]}
        >
          <Select
            optionFilterProp="children"
            filterOption={(input, option) => (option?.value ?? "").toLowerCase().includes(input.toLowerCase())}
            options={FILE_TIME_SPAN_OPTIONS}
          />
        </Item>
      </Col>
      <Col span={6}>
        <InputNumberField
          disabled={disabled}
          name={[...prefix, "keepDays"]}
          label={
            <>
              <FormattedMessage id="OutputToFileSection.recordingPeriod" defaultMessage="Recording period [days]" />
              <StyledTooltip placement="top" title={formatMessage(translations.recordingPeriodTooltip)}>
                <QuestionCircleFilled />
              </StyledTooltip>
            </>
          }
          rules={[required]}
          min={1}
        />
      </Col>
      <Col md={24} lg={12}>
        <InputTextFiled
          label={
            <>
              <FormattedMessage id="OutputToFileSection.storagePath" defaultMessage="Storage path" />
              <StyledTooltip placement="top" title={formatMessage(translations.storagePathTooltip)}>
                <QuestionCircleFilled />
              </StyledTooltip>
            </>
          }
          name={[...prefix, "path"]}
          disabled={disabled}
        />
      </Col>
    </>
  );
}

const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;
`;
