import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Divider, Row, Button } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { selectors as CLOUD_CHANNELS_SELECTORS } from "ducks/cloudChannels";
import CloudChannelsServices from "services/cloudChannels";

import inputURLDefaultValues from "../../lib/utils/defaultValues/inputURLDefaultValues";
import { errorNotification } from "../../lib/utils/notification";

import useNodeStunAddress from "../../hooks/useNodeStunAddress";

import InputStreamSection from "../NodeChannelFormV5/EngineDirect/InputStreamSection";

export default function BasicModeQSInput({
  generalSettings,
  handlePrevStep,
  handleNextStep,
  setInputSettings,
  inputSettings,
}) {
  const stundAddress = useNodeStunAddress();
  const sharedChannelList = useSelector(CLOUD_CHANNELS_SELECTORS.getSharedChannels);
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  useEffect(() => {
    CloudChannelsServices.getSharedChannels({ errorNotification: errorNotification(formatMessage) });
  }, [formatMessage]);

  const handleNext = async (formData) => {
    setInputSettings(formData);
    handleNextStep();
  };

  const initialValues = { ...generalSettings, input: inputURLDefaultValues(inputSettings) };

  return (
    <Form onFinish={handleNext} form={form} initialValues={initialValues}>
      <InputStreamSection
        isInput
        sharedChannelList={sharedChannelList}
        stundAddress={stundAddress}
        form={form}
        initialValue={initialValues.input}
        basicMode
      />
      <Divider />
      <Row justify="end">
        <Button style={{ margin: "0 8px" }} onClick={handlePrevStep}>
          <FormattedMessage id="general.previous" defaultMessage="Previous" />
        </Button>
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="general.next" defaultMessage="Next" />
        </Button>
      </Row>
    </Form>
  );
}

BasicModeQSInput.propTypes = {
  generalSettings: PropTypes.object.isRequired,
  handlePrevStep: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  setInputSettings: PropTypes.func.isRequired,
  inputSettings: PropTypes.object.isRequired,
};
