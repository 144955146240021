import React from "react";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { WarningOutlined } from "@ant-design/icons";

import themeColor from "../../lib/style/theme";

const WrongVersionConfig = () => (
  <Tooltip
    placement="top"
    title={
      <FormattedMessage
        id="general.wrongVersionRunning"
        defaultMessage="Wrong version of configuration is running - please check channel
        configuration and restart channel"
      />
    }
  >
    <StyledWarningOutlined />
  </Tooltip>
);

const StyledWarningOutlined = styled(WarningOutlined)`
  color: ${themeColor.darkRed};
  margin-left: 5px;
`;

export default WrongVersionConfig;
