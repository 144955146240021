import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const SET_FORMAT = createAction("FORMAT/SET_FORMAT");
export const SET_LOADING_FORMATS = createAction("FORMAT/SET_LOADING_FORMAT");
export const CLEAR_FORMATS = createAction("FORMAT/CLEAR_FORMAT");

const defaultState = new Map({
  loading: null,
});

const reducer = handleActions(
  {
    [SET_FORMAT]: (state, { payload }) => {
      const { device, formats } = payload;

      return state.merge({ [device]: formats });
    },
    [SET_LOADING_FORMATS]: (state, { payload }) => {
      return state.merge({ loading: payload });
    },
    [CLEAR_FORMATS]: () => {
      return defaultState;
    },
  },
  defaultState
);

export const selectors = {
  getFormats: (state) => state.get("nodeFormats"),
  getLoadingFormat: (state) => state.getIn(["nodeFormats", "loading"]),
};

export default reducer;
