import React from "react";
import CookieConsent from "react-cookie-consent";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import themeColor from "../../lib/style/theme";
import useMainTheme from "../../hooks/useMainTheme";

const CookieConsentContainer = ({ setCookieAccepted }) => {
  const { isDarkTheme } = useMainTheme();

  return (
    <CookieConsent
      location="bottom"
      buttonText={<FormattedMessage id="general.Accept" defaultMessage="Accept" />}
      cookieName="QuickstreamCloudCookieApproval"
      style={{ background: isDarkTheme ? themeColor.defaultBackground : themeColor.defaultBackground }}
      buttonStyle={{ background: `${themeColor.orange}`, color: "#FFF", fontSize: "14px" }}
      onAccept={() => setCookieAccepted(true)}
    >
      <StyledCookieTitle>
        <FormattedMessage id="CookieConsent.cookies" defaultMessage="Cookies" />
      </StyledCookieTitle>
      <StyledParagraph $isdarkTheme={isDarkTheme}>
        <span>
          <FormattedMessage
            id="CookieConsent.Info1"
            // eslint-disable-next-line max-len
            defaultMessage="The website administrator (tvhive sp. z o.o. with its registered office at Krakow (30-504), ul. Kalwaryjska 69, NIP: 6793185462) uses cookies. Information on the types of cookies used, the purposes, methods and period of their use is specified in the"
          />{" "}
        </span>
        <a
          href="https://quickstream.tech/privacypolicy"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Privacy Policy"
        >
          <FormattedMessage id="CookieConsent.privacyPolicy" defaultMessage="Privacy Policy" />
        </a>
        .
      </StyledParagraph>
    </CookieConsent>
  );
};

const StyledParagraph = styled.div`
  color: ${(props) => (props.$isdarkTheme ? "#FFF" : themeColor.lightCardBackground)};

  margin-left: 10px;
`;

const StyledCookieTitle = styled.h3`
  color: ${themeColor.orange};
`;

CookieConsentContainer.propTypes = {
  setCookieAccepted: PropTypes.func.isRequired,
};

export default CookieConsentContainer;
