import React from "react";
import { Card, Row, Col, Form, Switch, Divider } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";

import TooltipLabel from "../../TooltipLabel";

import InputNumberField from "../../Fields/InputNumberField";
import { required } from "../../../lib/utils/formValidation";
import globalTranslations from "../../../lib/translations";

import {
  DEFAULT_TRIGGER_BITRATE,
  DEFAULT_TRIGGER_CONNECTION,
} from "../../../lib/utils/defaultValues/inputURLDefaultValues";

const { Item } = Form;

const translations = defineMessages({
  triggerBitrateLabel: {
    id: "InputStreamSectionBackupTriggers.triggerBitrateLabel",
    defaultMessage: "On Bitrate drop",
  },
  triggerConnectionTimeLabel: {
    id: "InputStreamSectionBackupTriggers.triggerConnectionTimeLabel",
    defaultMessage: "On connection failure",
  },
  triggerConnectionTimeLabelTooltip: {
    id: "InputStreamSectionBackupTriggers.triggerConnectionTimeLabelTooltip",
    // eslint-disable-next-line max-len
    defaultMessage: `Switch between primary and backup when the connection fails to establish in specified “Maximum connection time"`,
  },
  disconnectTriggerLabel: {
    id: "InputStreamSectionBackupTriggers.disconnectTriggerLabel",
    defaultMessage: "On disconnect",
  },
  disconnectTriggerLabelTooltip: {
    id: "InputStreamSectionBackupTriggers.disconnectTriggerLabelTooltip",
    defaultMessage: "Switch between primary and backup when SRT connection drops",
  },
  connectionTimeTriggerEnabled: {
    id: "InputStreamSectionBackupTriggers.connectionTimeTriggerEnabled",
    defaultMessage: "Connection Time Trigger Enabled",
  },
  connectionTimeTriggerDisabled: {
    id: "InputStreamSectionBackupTriggers.connectionTimeTriggerDisabled",
    defaultMessage: "Connection Time Trigger Disabled",
  },
  disconnectTriggerEnabled: {
    id: "InputStreamSectionBackupTriggers.disconnectTriggerEnabled",
    defaultMessage: "Disconnect Trigger Enabled",
  },
  disconnectTriggerDisabled: {
    id: "InputStreamSectionBackupTriggers.disconnectTriggerDisabled",
    defaultMessage: "Disconnect Trigger Disabled",
  },
  triggerBitrateLabelTooltip: {
    id: "InputStreamSectionBackupTriggers.triggerBitrateLabelTooltip",
    defaultMessage:
      // eslint-disable-next-line max-len
      "Switch between primary and backup when connection bitrate drops below “Bitrate Level” for at least “Bitrate drop duration” seconds",
  },
});

function InputStreamSectionBackupTriggers({ disabled, prefix, form }) {
  const { formatMessage } = useIntl();
  const { setFieldValue, getFieldValue } = form;

  const switchBitrateTriggerValue = Form.useWatch([...prefix, "triggerBitrateEnabled"], form);
  const switchConnectionTimeTriggerValue = Form.useWatch([...prefix, "triggerConnectionTimeEnabled"], form);

  const handleChangeTriggerBitrate = (value) => {
    if (value) {
      setFieldValue([...prefix, "triggerBitrateLevel"], DEFAULT_TRIGGER_BITRATE.triggerBitrateLevel);
      setFieldValue([...prefix, "triggerBitrateTimeSecs"], DEFAULT_TRIGGER_BITRATE.triggerBitrateTimeSecs);
    } else {
      const backupTriggers = getFieldValue(prefix);
      delete backupTriggers.triggerBitrateLevel;
      delete backupTriggers.triggerBitrateTimeSecs;

      setFieldValue(prefix, backupTriggers);
    }
  };

  const handleChangeTriggerConnection = (value) => {
    if (value) {
      setFieldValue([...prefix, "triggerConnectionTimeSecs"], DEFAULT_TRIGGER_CONNECTION.triggerConnectionTimeSecs);
    } else {
      const backupTriggers = getFieldValue(prefix);
      delete backupTriggers.triggerConnectionTimeSecs;

      setFieldValue(prefix, backupTriggers);
    }
  };

  return (
    <Card
      title={
        <FormattedMessage
          id="InputStreamSectionBackupTriggers.additionalBackupTriggers"
          defaultMessage="Additional backup triggers"
        />
      }
    >
      <Row align="middle" gutter={12}>
        <Col span={8}>
          <Item
            name={[...prefix, "triggerBitrateEnabled"]}
            label={
              <TooltipLabel
                label={formatMessage(translations.triggerBitrateLabel)}
                description={formatMessage(translations.triggerBitrateLabelTooltip)}
              />
            }
            valuePropName="checked"
          >
            <Switch
              disabled={disabled}
              defaultChecked={switchBitrateTriggerValue}
              checkedChildren={formatMessage(globalTranslations.enabled)}
              unCheckedChildren={formatMessage(globalTranslations.disabled)}
              onChange={handleChangeTriggerBitrate}
            />
          </Item>
        </Col>
        {switchBitrateTriggerValue && (
          <>
            <Col span={8}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "triggerBitrateLevel"]}
                label={
                  <span>
                    <FormattedMessage
                      id="InputStreamSectionBackupTriggers.bitrateKbps"
                      defaultMessage="Bitrate Level [kbps]"
                    />
                  </span>
                }
                rules={[required]}
              />
            </Col>
            <Col span={8}>
              <InputNumberField
                disabled={disabled}
                name={[...prefix, "triggerBitrateTimeSecs"]}
                label={
                  <span>
                    <FormattedMessage
                      id="InputStreamSectionBackupTriggers.BitrateDropDuration"
                      defaultMessage="Bitrate drop duration (s)"
                    />
                  </span>
                }
                rules={[required]}
              />
            </Col>
          </>
        )}
        <Divider />
        <Col span={8}>
          <Item
            name={[...prefix, "triggerConnectionTimeEnabled"]}
            valuePropName="checked"
            label={
              <TooltipLabel
                label={formatMessage(translations.triggerConnectionTimeLabel)}
                description={formatMessage(translations.triggerConnectionTimeLabelTooltip)}
              />
            }
          >
            <Switch
              disabled={disabled}
              defaultChecked={switchConnectionTimeTriggerValue}
              checkedChildren={formatMessage(globalTranslations.enabled)}
              unCheckedChildren={formatMessage(globalTranslations.disabled)}
              onChange={handleChangeTriggerConnection}
            />
          </Item>
        </Col>
        {switchConnectionTimeTriggerValue && (
          <Col span={16}>
            <InputNumberField
              disabled={disabled}
              name={[...prefix, "triggerConnectionTimeSecs"]}
              min={1}
              label={
                <span>
                  <FormattedMessage
                    id="InputStreamSectionBackupTriggers.maximumConnectionTime"
                    defaultMessage="Maximum connection time (s)"
                  />
                </span>
              }
              rules={[required]}
            />
          </Col>
        )}
        <Divider />
        <Col span={24}>
          <Item
            name={[...prefix, "triggerDisconnectEnabled"]}
            valuePropName="checked"
            label={
              <TooltipLabel
                label={formatMessage(translations.disconnectTriggerLabel)}
                description={formatMessage(translations.disconnectTriggerLabelTooltip)}
              />
            }
          >
            <Switch
              disabled={disabled}
              defaultChecked={false}
              checkedChildren={formatMessage(globalTranslations.enabled)}
              unCheckedChildren={formatMessage(globalTranslations.disabled)}
            />
          </Item>
        </Col>
      </Row>
    </Card>
  );
}

InputStreamSectionBackupTriggers.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialValue: PropTypes.shape({
    triggerBitrateEnabled: PropTypes.bool,
    triggerConnectionTimeEnabled: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
};

InputStreamSectionBackupTriggers.defaultProps = {
  initialValue: null,
  disabled: false,
};

export default InputStreamSectionBackupTriggers;
