import React from "react";
import { Col, Form, Select } from "antd";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { X264_PROFILE, SELECT_DEFAULT_AUTO, OUTPUT_RTMP_PROFILE } from "../../../../../lib/utils/constants";
import { required } from "../../../../../lib/utils/formValidation";

const { Item } = Form;
const { Option } = Select;

const X264VideoCodec = ({ prefix, fieldPrefix, disabled, form, isOutputRtmp }) => {
  const { getFieldValue, setFieldValue } = form;

  const handleChangeProfile = () => {
    const codec = getFieldValue(prefix);
    setFieldValue(prefix, { ...codec, pixFormat: SELECT_DEFAULT_AUTO.value });
  };

  const PARSED_PROFILES = isOutputRtmp ? OUTPUT_RTMP_PROFILE : X264_PROFILE;

  return (
    <>
      <Col span={8}>
        <Item
          name={[...fieldPrefix, "x264Profile"]}
          label={<FormattedMessage id="X264VideoCodec.x264Profile" defaultMessage="Profile" />}
          rules={[required]}
        >
          <Select disabled={disabled} onChange={handleChangeProfile}>
            {PARSED_PROFILES.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
    </>
  );
};

X264VideoCodec.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  fieldPrefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  disabled: PropTypes.bool,
  isOutputRtmp: PropTypes.bool.isRequired,
};

X264VideoCodec.defaultProps = {
  disabled: null,
};

export default X264VideoCodec;
