import { combineReducers } from "redux-immutable";
import account from "./account";
import admin from "./admin";
import cloudChannels from "./cloudChannels";
import lang from "./lang";
import license from "./license";
import navigation from "./navigation";
import nodeChannels from "./nodeChannels";
import loadingData from "./loadingData";
import node from "./node";
import logs from "./logs";
import nodeStatistics from "./nodeStatistics";
import nodeSystemStatistics from "./nodeSystemStatistics";
import nodeLicense from "./nodeLicense";
import nodeDevices from "./nodeDevices";
import nodeFormats from "./nodeFormats";
import probe from "./probe";
import nodeNotifications from "./nodeNotifications";
import organizations from "./organizations";
import mqtt from "./mqtt";
import nodeLiveStats from "./nodeLiveStats";
import nodeStatsV5 from "./nodeStatsV5";
import nodeAlerts from "./nodeAlerts";

const rootReducer = combineReducers({
  account,
  admin,
  cloudChannels,
  lang,
  license,
  loadingData,
  logs,
  navigation,
  node,
  nodeChannels,
  nodeDevices,
  nodeFormats,
  nodeLicense,
  nodeNotifications,
  nodeStatistics,
  nodeSystemStatistics,
  organizations,
  probe,
  mqtt,
  nodeLiveStats,
  nodeStatsV5,
  nodeAlerts,
});

export default rootReducer;
