import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Spin, Tooltip } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { QuestionCircleFilled } from "@ant-design/icons";

import { selectors as NODE_LIVE_STATISTIC_SELECTORS } from "../../../ducks/nodeLiveStats";

import { NODE_CHANNEL_STATE, INPUT_TYPES } from "../../../lib/utils/constants";
import themeColor from "../../../lib/style/theme";
import transferSpeedValue from "../../../lib/utils/transferSpeedValue";

function NodeChannelsListBitrate({ channelId, channelStatus, inputType, playingSource }) {
  const lastBitrateStat = useSelector((state) =>
    NODE_LIVE_STATISTIC_SELECTORS.getLastBitrateV5({ state, channelId, playingSource })
  );

  const isStarted = channelStatus === NODE_CHANNEL_STATE.STARTED;
  const isInputURLType = inputType === INPUT_TYPES.inputUrl.value;

  if (!isStarted) {
    return null;
  }

  if (!inputType) {
    return <StyledSpin />;
  }

  if (isInputURLType) {
    return (
      <>
        {!lastBitrateStat && lastBitrateStat === undefined && <StyledSpin />}
        {lastBitrateStat && <StyledBitrate>{transferSpeedValue(lastBitrateStat)}</StyledBitrate>}
      </>
    );
  }

  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage
          id="NodeChannelsListBitrate.dataNotAvailable"
          defaultMessage="Data not available for this input type"
        />
      }
    >
      <QuestionCircleFilled />
    </Tooltip>
  );
}

const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: ${themeColor.maritime};
  }
`;

const StyledBitrate = styled.span`
  display: block;
  color: ${themeColor.maritime};
`;

NodeChannelsListBitrate.propTypes = {
  inputType: PropTypes.string,
  channelStatus: PropTypes.string.isRequired,
  channelId: PropTypes.number.isRequired,
  playingSource: PropTypes.string.isRequired,
};

NodeChannelsListBitrate.defaultProps = {
  inputType: null,
};

export default NodeChannelsListBitrate;
