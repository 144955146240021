export default [
  {
    label: "Afar",
    value: "aar",
  },
  {
    label: "Abkhazian",
    value: "abk",
  },
  {
    label: "Afrikaans",
    value: "afr",
  },
  {
    label: "Amharic",
    value: "amh",
  },
  {
    label: "Arabic",
    value: "ara",
  },
  {
    label: "Azerbaijani",
    value: "aze",
  },
  {
    label: "Belarusian",
    value: "bel",
  },
  {
    label: "Bengali",
    value: "ben",
  },
  {
    label: "Bulgarian",
    value: "bul",
  },
  {
    label: "Catalan; Valencian",
    value: "cat",
  },
  {
    label: "Danish",
    value: "dan",
  },
  {
    label: "English",
    value: "eng",
  },
  {
    label: "Estonian",
    value: "est",
  },
  {
    label: "Finnish",
    value: "fin",
  },
  {
    label: "Irish",
    value: "gle",
  },
  {
    label: "Galician",
    value: "glg",
  },
  {
    label: "Hebrew",
    value: "heb",
  },
  {
    label: "Hindi",
    value: "hin",
  },
  {
    label: "Croatian",
    value: "hrv",
  },
  {
    label: "Hungarian",
    value: "hun",
  },
  {
    label: "Indonesian",
    value: "ind",
  },
  {
    label: "Italian",
    value: "ita",
  },
  {
    label: "Japanese",
    value: "jpn",
  },
  {
    label: "Kazakh",
    value: "kaz",
  },
  {
    label: "Korean",
    value: "kor",
  },
  {
    label: "Latin",
    value: "lat",
  },
  {
    label: "Latvian",
    value: "lav",
  },
  {
    label: "Lithuanian",
    value: "lit",
  },
  {
    label: "Luxembourgish; Letzeburgesch",
    value: "ltz",
  },
  {
    label: "Maltese",
    value: "mlt",
  },
  {
    label: "Mongolian",
    value: "mon",
  },
  {
    label: "Norwegian",
    value: "nob",
  },
  {
    label: "Norwegian",
    value: "nor",
  },
  {
    label: "Polish",
    value: "pol",
  },
  {
    label: "Portuguese",
    value: "por",
  },
  {
    label: "Russian",
    value: "rus",
  },
  {
    label: "Slovenian",
    value: "slv",
  },
  {
    label: "Spanish; Castilian",
    value: "spa",
  },
  {
    label: "Serbian",
    value: "srp",
  },
  {
    label: "Swedish",
    value: "swe",
  },
  {
    label: "Thai",
    value: "tha",
  },
  {
    label: "Turkish",
    value: "tur",
  },
  {
    label: "Ukrainian",
    value: "ukr",
  },
  {
    label: "Urdu",
    value: "urd",
  },
  {
    label: "Uzbek",
    value: "uzb",
  },
  {
    label: "Vietnamese",
    value: "vie",
  },
];
