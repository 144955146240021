import React from "react";
import { Badge, Tooltip, Avatar } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { CloudOutlined } from "@ant-design/icons";

import { LICENSES_TYPES } from "../../lib/utils/constants";
import themeColor from "../../lib/style/theme";

const translations = defineMessages({
  licenses: {
    id: "ValidLicenseBadge.licenses",
    defaultMessage: "licenses",
  },
  license: {
    id: "ValidLicenseBadge.license",
    defaultMessage: "license",
  },
  used: {
    id: "ValidLicenseBadge.used",
    defaultMessage: "used",
  },
});

const ValidLicenseBadge = ({ validLicense, licensesUsed, licenseId }) => {
  const { formatMessage } = useIntl();
  const isCLoudLicense = licenseId === LICENSES_TYPES.CLOUD;

  if (isCLoudLicense) {
    return (
      <Tooltip title={<FormattedMessage id="ValidLicenseBadge.cloudLicense" defaultMessage="Cloud license" />}>
        <Avatar style={{ backgroundColor: themeColor.cloudBlue, fontSize: "12px" }} size={20}>
          <CloudOutlined />
        </Avatar>
      </Tooltip>
    );
  }

  switch (validLicense) {
    case 1:
      return (
        <Tooltip
          title={
            <>
              <FormattedMessage id="ValidLicenseBadge.success" defaultMessage="License is valid" />
            </>
          }
        >
          <Badge
            showZero
            count={licensesUsed}
            style={{ backgroundColor: themeColor.green }}
            title={`${licensesUsed} ${
              licensesUsed > 1 ? formatMessage(translations.licenses) : formatMessage(translations.license)
            } ${formatMessage(translations.used)}`}
          />
        </Tooltip>
      );
    case 0:
      return (
        <Tooltip
          title={
            <FormattedMessage
              id="ValidLicenseBadge.warning"
              defaultMessage="The license is within 7 days expiry period "
            />
          }
        >
          <Badge
            showZero
            count={licensesUsed}
            style={{ backgroundColor: themeColor.orange }}
            title={`${licensesUsed} ${
              licensesUsed > 1 ? formatMessage(translations.licenses) : formatMessage(translations.license)
            } ${formatMessage(translations.used)}`}
          />
        </Tooltip>
      );
    case -1:
      return (
        <Tooltip title={<FormattedMessage id="ValidLicenseBadge.error" defaultMessage="License has expired" />}>
          <Badge
            showZero
            count={licensesUsed}
            style={{ backgroundColor: themeColor.darkRed }}
            title={`${licensesUsed} ${
              licensesUsed > 1 ? formatMessage(translations.licenses) : formatMessage(translations.license)
            } ${formatMessage(translations.used)}`}
          />
        </Tooltip>
      );
    case -2:
      return (
        <Tooltip title={<FormattedMessage id="ValidLicenseBadge.cloudError" defaultMessage="Cloud validation error" />}>
          <Badge
            showZero
            count={licensesUsed}
            style={{ backgroundColor: themeColor.darkRed }}
            title={`${licensesUsed} ${
              licensesUsed > 1 ? formatMessage(translations.licenses) : formatMessage(translations.license)
            } ${formatMessage(translations.used)}`}
          />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={<FormattedMessage id="ValidLicenseBadge.missingData" defaultMessage="Missing data" />}>
          <Badge showZero count={licensesUsed} style={{ backgroundColor: themeColor.logDebug }} />
        </Tooltip>
      );
  }
};

ValidLicenseBadge.propTypes = {
  validLicense: PropTypes.number.isRequired,
  licensesUsed: PropTypes.number.isRequired,
  licenseId: PropTypes.string.isRequired,
};

export default ValidLicenseBadge;
