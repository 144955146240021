import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../../ducks/cloudChannels";

import CloudChannelsServices from "../../../services/cloudChannels";

import { CHANNEL_SOURCE } from "../../../lib/utils/constants";
import { errorNotification } from "../../../lib/utils/notification";

const StatisticOutputTabName = ({ channelName, username, permissionId, channelId }) => {
  const { formatMessage } = useIntl();
  const { cwid } = useParams();
  const permissions = useSelector(CLOUD_CHANNELS_SELECTORS.getChannelPermissions);

  useEffect(() => {
    if (permissionId > 0) {
      const permissionDataExist = permissions && !!permissions[channelId];
      if (!permissionDataExist) {
        const cloudId = permissionId.toString().slice(0, 9);

        CloudChannelsServices.getChannelPermissionsDescription(
          { cloudIds: `[${cloudId}]`, channelId, cwid },
          {
            errorNotification: errorNotification(formatMessage),
          }
        );
      }
    }
  }, []);

  if (permissionId <= CHANNEL_SOURCE.nodeChannel) {
    return username;
  }

  return `${channelName} (${username})`;
};

export default StatisticOutputTabName;
