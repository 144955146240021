import React from "react";
import { Descriptions, Empty, Card, Badge } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import styled from "styled-components";

import { NODE_CHANNEL_STATE } from "../../../lib/utils/constants";

import { selectors as NODE_CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";

import TranslatedStatus from "../../TranslatedStatus";
import ClientsConnected from "./ClientsConnected";
import DetailSectionPermissionLabel from "./DetailSectionPermissionLabel";
import WrongVersionConfig from "../../WrongVersionConfig";

const { Item } = Descriptions;

const badgeStatus = (value) => {
  switch (value) {
    case NODE_CHANNEL_STATE.PLAYING:
      return "processing";
    case NODE_CHANNEL_STATE.STOPPED:
      return "warning";
    case NODE_CHANNEL_STATE.PROCESS_DISCONNECTED:
      return "error";
    case NODE_CHANNEL_STATE.CONNECTING:
      return "warning";
    case NODE_CHANNEL_STATE.CONNECTED:
      return "success";
    default:
      return "default";
  }
};

const DetailSection = ({ channels, channelId }) => {
  if (!channels || channels.size === 0) {
    return (
      <Empty
        description={
          <FormattedMessage
            id="NodeChannelDetailView.missingChannelData"
            defaultMessage="Missing channel data - make sure that the channel still exists (it may have been deleted)"
          />
        }
      />
    );
  }

  const {
    status: { channelStatusText, connected, outputs, configWrongVersionRunning },
    outputs: { outputsConfigs },
  } = channels.find((channel) => channel.channelId === channelId);

  return (
    <Card
      title={
        <>
          <FormattedMessage id="DetailSection.channelStatus" defaultMessage="Channel status" />
          {configWrongVersionRunning && <WrongVersionConfig />}
        </>
      }
    >
      <Descriptions bordered column={2} size="small">
        {/* <Item label={<FormattedMessage id="DetailSection.id" defaultMessage="Channel ID" />}>{channelId}</Item> */}
        <Item label={<FormattedMessage id="DetailSection.inputStatus" defaultMessage="Input status" />}>
          <Badge
            status={badgeStatus(channelStatusText)}
            text={
              <StyledTranslatedStatus>
                <TranslatedStatus channelStatus={channelStatusText} />
              </StyledTranslatedStatus>
            }
          />
        </Item>
        <Item label={<FormattedMessage id="DetailSection.clientsConnected" defaultMessage="Clients connected" />}>
          <ClientsConnected connected={connected} outputs={outputs} />
        </Item>
      </Descriptions>

      {outputs && outputs.length > 0 && (
        <StyledCard
          title={<FormattedMessage id="NodeChannelDetailView.outputsStatus" defaultMessage="Outputs status" />}
        >
          <Descriptions bordered column={2} size="small">
            {outputs.map((output) => (
              <Item
                key={output.permissionId}
                label={
                  <DetailSectionPermissionLabel
                    permissionId={output.permissionId}
                    channelId={channelId}
                    outputId={output?.outputId}
                    outputsConfigs={outputsConfigs}
                    info={output?.connectionInfo}
                  />
                }
              >
                <Badge
                  status={badgeStatus(output.threadStatusText)}
                  text={<TranslatedStatus channelStatus={output.threadStatusText} />}
                />
              </Item>
            ))}
          </Descriptions>
        </StyledCard>
      )}
    </Card>
  );
};

const StyledTranslatedStatus = styled.span`
  font-weight: bold !important;
`;

const StyledCard = styled(Card)`
  margin: 15px 0;
`;

DetailSection.propTypes = {
  channels: ImmutablePropTypes.map.isRequired,
  channelId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  channels: NODE_CHANNEL_SELECTORS.getChannels(state),
});

export default connect(mapStateToProps)(DetailSection);
