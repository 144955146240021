import { Col } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const MissingOrganizationData = () => {
  return (
    <Col span={24}>
      <StyledText>
        <FormattedMessage
          id="organizationDetail.organizationDataChanged"
          defaultMessage="Your organization data has changed recently and is waiting for admin approval."
        />
        <br />
        <FormattedMessage
          id="organizationDetail.organizationDataAnyQuestions"
          defaultMessage="If you have any questions please "
        />{" "}
        <a href="mailto: hello@quickstream.tech" aria-label="contact as">
          <FormattedMessage id="organizationDetail.contactUs" defaultMessage="contact us" />
        </a>
      </StyledText>
    </Col>
  );
};

const StyledText = styled.h2`
  text-align: center;
  margin-top: 2rem;
`;

export default MissingOrganizationData;
