import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

const TechnicalDescription = () => {
  return (
    <>
      <StyledLabel>
        <FormattedMessage id="TechnicalDescription.technical" defaultMessage="Technical" />
      </StyledLabel>
      <Tooltip
        title={
          <FormattedMessage
            id="TechnicalDescription.description"
            // eslint-disable-next-line max-len
            defaultMessage={`If you are technically responsible for channel reception or delivery
             - the following contact details will be visible to your technical partners.
            Exchanging technical contact details help to better manage your services.`}
          />
        }
      >
        <QuestionCircleFilled />
      </Tooltip>
    </>
  );
};

const StyledLabel = styled.span`
  margin-right: 5px;
`;

export default TechnicalDescription;
