import React, { useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { InputNumber, Form } from "antd";

const { Item } = Form;

function InputNumberField({ name, label, min, hidden, rules, ...rest }) {
  const inputEl = useRef(null);

  useLayoutEffect(() => {
    function handleMouseScroll() {
      inputEl.current.blur();
    }
    window.addEventListener("mousewheel", handleMouseScroll);

    return () => window.removeEventListener("mousewheel", handleMouseScroll);
  }, []);

  return (
    <Item name={name} label={label} rules={rules} hidden={hidden}>
      <InputNumber type="number" ref={inputEl} min={min} {...rest} />
    </Item>
  );
}

InputNumberField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]),
  min: PropTypes.number,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      pattern: PropTypes.shape(RegExp),
      message: PropTypes.element,
    })
  ),
  hidden: PropTypes.bool,
};

InputNumberField.defaultProps = {
  min: 0,
  rules: null,
  hidden: false,
  label: null,
};

export default InputNumberField;
