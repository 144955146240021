import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

const TEST_EN_TABLE_ID = "prctbl_1OZ769D5PQTAcOlDYDFhLI8S";
const TEST_PL_TABLE_ID = "prctbl_1OZ73XD5PQTAcOlDTM9Baz5c";
const TEST_EN_TABLE_ID_FREE = "prctbl_1OMpa1D5PQTAcOlDPgs8RYtU";
const TEST_PL_TABLE_ID_FREE = "prctbl_1OMpgxD5PQTAcOlDYmLSjCMW";

const PROD_EN_TABLE_ID = "prctbl_1OZA8WD5PQTAcOlD035rgXPi";
const PROD_PL_TABLE_ID = "prctbl_1OZA1RD5PQTAcOlDvYijXpDK";
const PROD_EN_TABLE_ID_FREE = "prctbl_1ONG28D5PQTAcOlDSiTglSmx";
const PROD_PL_TABLE_ID_FREE = "prctbl_1ONG4TD5PQTAcOlDCiLuP24a";

const PROD_STRIPE_PUBLIC_KEY =
  "pk_live_51Nt92qD5PQTAcOlDHpBU3fVFMOcbyzsAYiBGSIuUYVp3N5PlJyheUX3nLeBQJXODlI7ko85wM1vMJ6j8l4GdinFc009tW6c5WS";
const TEST_STRIPE_PUBLIC_KEY =
  "pk_test_51Nt92qD5PQTAcOlDwq7DgyCFtExk0LEeTA7Bqt50V5IGVIC9sOWOPVQN7LOAbU8ZaA6QW4eTMEMyOEgnMXxbDLC9001XRng9bd";

export default function StripePricingTable({ lng }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const userEmail = useSelector(ACCOUNT_SELECTORS.getUserEmail);
  const activeStripeSubscription = useSelector(ACCOUNT_SELECTORS.getActiveStripeSubscription);
  const isStripeCustomer = useSelector(ACCOUNT_SELECTORS.getStripeCustomer);

  const isProdMode = process.env.REACT_APP_API.includes("cloud.quickstream.tech");

  if (isStripeCustomer && !activeStripeSubscription) {
    // ------- Production mode ----------
    if (isProdMode && lng === "pl") {
      return React.createElement("stripe-pricing-table", {
        "pricing-table-id": PROD_PL_TABLE_ID,
        "publishable-key": PROD_STRIPE_PUBLIC_KEY,
        "customer-email": userEmail,
      });
    }

    if (isProdMode && lng === "en") {
      return React.createElement("stripe-pricing-table", {
        "pricing-table-id": PROD_EN_TABLE_ID,
        "publishable-key": PROD_STRIPE_PUBLIC_KEY,
        "customer-email": userEmail,
      });
    }

    //  ------- Test mode ----------
    if (!isProdMode && lng === "pl") {
      return React.createElement("stripe-pricing-table", {
        "pricing-table-id": TEST_PL_TABLE_ID,
        "publishable-key": TEST_STRIPE_PUBLIC_KEY,
        "customer-email": userEmail,
      });
    }

    if (!isProdMode && lng === "en") {
      return React.createElement("stripe-pricing-table", {
        "pricing-table-id": TEST_EN_TABLE_ID,
        "publishable-key": TEST_STRIPE_PUBLIC_KEY,
        "customer-email": userEmail,
      });
    }
  } else {
    // ------- Production mode ----------
    if (isProdMode && lng === "pl") {
      return React.createElement("stripe-pricing-table", {
        "pricing-table-id": PROD_PL_TABLE_ID_FREE,
        "publishable-key": PROD_STRIPE_PUBLIC_KEY,
        "customer-email": userEmail,
      });
    }

    if (isProdMode && lng === "en") {
      return React.createElement("stripe-pricing-table", {
        "pricing-table-id": PROD_EN_TABLE_ID_FREE,
        "publishable-key": PROD_STRIPE_PUBLIC_KEY,
        "customer-email": userEmail,
      });
    }

    //  ------- Test mode ----------
    if (!isProdMode && lng === "pl") {
      return React.createElement("stripe-pricing-table", {
        "pricing-table-id": TEST_PL_TABLE_ID_FREE,
        "publishable-key": TEST_STRIPE_PUBLIC_KEY,
        "customer-email": userEmail,
      });
    }

    if (!isProdMode && lng === "en") {
      return React.createElement("stripe-pricing-table", {
        "pricing-table-id": TEST_EN_TABLE_ID_FREE,
        "publishable-key": TEST_STRIPE_PUBLIC_KEY,
        "customer-email": userEmail,
      });
    }
  }

  return <span>Unsupported lng price table</span>;
}
