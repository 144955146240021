import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import InputNumberField from "../../../Fields/InputNumberField";

import { required } from "../../../../lib/utils/formValidation";
import globalTranslations from "../../../../lib/translations";

import { FormV5Context } from "../../NodeChannelFormV5";

const { Item } = Form;

export default function OtherSectionFormItem({ disabled, prefix }) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);
  const useHwDecoding = Form.useWatch([...prefix, "useHwDecoding"], form);

  return (
    <Row gutter={24}>
      <Col span={8}>
        <InputNumberField
          name={[...prefix, "clonedFramesBeforeEmergency"]}
          label={
            <FormattedMessage
              id="OtherSectionFormItem.clonedFramesBeforeEmergency"
              defaultMessage="Cloned frames before emergency"
            />
          }
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
      <Col span={8}>
        <InputNumberField
          name={[...prefix, "outputBufferLength"]}
          label={
            <FormattedMessage id="OtherSectionFormItem.outputBufferLength" defaultMessage="Output buffer length [ms]" />
          }
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
      <Col span={8}>
        <InputNumberField
          name={[...prefix, "playlistEndingAlert"]}
          label={
            <FormattedMessage id="OtherSectionFormItem.playlistEndingAlert" defaultMessage="Playlist ending alert" />
          }
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
      <Col span={8}>
        <InputNumberField
          name={[...prefix, "sourceAnalyzeDuration"]}
          label={
            <FormattedMessage
              id="OtherSectionFormItem.sourceAnalyzeDuration"
              defaultMessage="Source analyze duration [ms]"
            />
          }
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
      <Col span={8}>
        <InputNumberField
          name={[...prefix, "sourceProbeSize"]}
          label={<FormattedMessage id="OtherSectionFormItem.sourceProbeSize" defaultMessage="Source probe size [B]" />}
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
      <Col span={8}>
        <Item
          name={[...prefix, "useHwDecoding"]}
          valuePropName="checked"
          label={<FormattedMessage id="OtherSectionFormItem.useHwDecoding" defaultMessage="Use hw decoding" />}
          rules={[required]}
        >
          <Switch
            disabled={disabled}
            defaultChecked={useHwDecoding}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
          />
        </Item>
      </Col>
    </Row>
  );
}

OtherSectionFormItem.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
};
