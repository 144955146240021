/* eslint-disable consistent-return */
import { bindActionCreators } from "redux";
import { defineMessages } from "react-intl";
import store from "../store";

import { PlatformAPI } from "../lib/api";
import {
  SET_OWN_CHANNELS,
  SET_SHARED_CHANNELS,
  ADD_NEW_OWN_CHANNEL,
  REMOVE_OWN_CHANNEL,
  SET_ALL_CHANNELS,
  ADD_NEW_ALL_CHANNEL,
  REMOVE_SHARED_CHANNEL,
  REMOVE_ALL_CHANNEL,
  SET_CHANNEL_PERMISSIONS,
  UPDATE_OWN_CHANNEL,
  CHANGE_CHANNEL_STATUS,
  UPDATE_ALL_CHANNEL,
} from "../ducks/cloudChannels";
import getMessageFromErrorCode from "../lib/utils/getMessageFromErrorCode";

import { SKIP_QS_METHOD_ERROR } from "../lib/utils/constants";

const translations = defineMessages({
  removeSuccess: {
    id: "cloudChannels.removeSuccess",
    defaultMessage: "Channel was successfully removed",
  },
  addSuccess: {
    id: "cloudChannels.addSuccess",
    defaultMessage: "Channel was successfully added",
  },
  shareAddSuccess: {
    id: "cloudChannels.shareAddSuccess",
    defaultMessage: "Shares were successfully added",
  },
  shareRemoveSuccess: {
    id: "cloudChannels.shareRemoveSuccess",
    defaultMessage: "Share was successfully removed",
  },
  updateSuccess: {
    id: "cloudChannels.updateSuccess",
    defaultMessage: "Channel was successfully updated",
  },
});

const actions = bindActionCreators(
  {
    uniqueChannelNameValidation:
      (channelName, { errorNotification, setValidationData }) =>
      async () => {
        try {
          const uniqueData = await PlatformAPI.uniqueChannelNameValidation(channelName, { errorNotification });

          setValidationData(uniqueData.data);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    changeVirtualChannelStatus:
      (data, { errorNotification }) =>
      async (dispatch) => {
        try {
          const updatedChannel = await PlatformAPI.changeVirtualChannelStatus(data, { errorNotification });
          dispatch(CHANGE_CHANNEL_STATUS(updatedChannel.data));
          dispatch(UPDATE_OWN_CHANNEL(updatedChannel.data));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    createVirtualChannel:
      (data, { errorNotification, successNotification, setLoading, handleChangeStep, setCurrentCloudId }) =>
      async (dispatch) => {
        try {
          const newVirtualChannel = await PlatformAPI.createVirtualChannel(data, {
            errorNotification,
          });

          dispatch(ADD_NEW_OWN_CHANNEL(newVirtualChannel.data));
          dispatch(ADD_NEW_ALL_CHANNEL(newVirtualChannel.data));
          successNotification(translations.addSuccess);
          setCurrentCloudId(newVirtualChannel?.data?.cloudId);
          setLoading(false);
          handleChangeStep();
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setLoading(false);
        }
      },
    uploadChannelImages:
      (data, { errorNotification, token, cloudId, logoUrl, bgUrl, screenShotUrls, setLoading, handleChangeStep }) =>
      async (dispatch) => {
        try {
          const uploadResponse = await PlatformAPI.uploadChannelImages(data, token);
          const imagesData = await uploadResponse.json();

          const parsedImages = {
            screenShotUrls,
          };
          imagesData.data.forEach((image) => {
            if (image.type === "screenShotUrls") {
              parsedImages[image.type] = parsedImages[image.type]
                ? [...parsedImages[image.type], image.url]
                : [image.url];
            } else {
              parsedImages[image.type] = image.url;
            }
          });
          await PlatformAPI.editCloudChannel({ cloudId, logoUrl, bgUrl, ...parsedImages }, { errorNotification });
          dispatch(UPDATE_OWN_CHANNEL({ cloudId, logoUrl, bgUrl, ...parsedImages }));
          setLoading(false);
          handleChangeStep();
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setLoading(false);
        }
      },
    editCloudChannel:
      (data, { errorNotification, setLoading, handleChangeStep }) =>
      async (dispatch) => {
        try {
          setLoading(true);
          const updatedData = await PlatformAPI.editCloudChannel(data, { errorNotification });
          const userId = store.getState().getIn(["account", "id"]);
          if (userId !== updatedData?.data?.userId) {
            dispatch(REMOVE_OWN_CHANNEL(updatedData?.data?.cloudId));
          } else {
            dispatch(UPDATE_OWN_CHANNEL(updatedData.data));
          }
          dispatch(UPDATE_ALL_CHANNEL(updatedData.data));
          setLoading(false);
          if (handleChangeStep) {
            handleChangeStep();
          }
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setLoading(false);
        }
      },
    deleteVirtualChannelImg:
      (data, { errorNotification }) =>
      async () => {
        try {
          await PlatformAPI.deleteVirtualChannelImg(data, { errorNotification });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(`deleteVirtualChannelImg error: ${error}, data: ${data}`);
        }
      },
    removeChannel:
      (cloudId, { errorNotification, successNotification, setLoadingDeleteVChannel }) =>
      async (dispatch) => {
        try {
          await PlatformAPI.removeChannel(cloudId, { errorNotification });
          dispatch(REMOVE_OWN_CHANNEL(cloudId));
          dispatch(REMOVE_ALL_CHANNEL(cloudId));
          dispatch(REMOVE_SHARED_CHANNEL(cloudId));
          successNotification(translations.removeSuccess);
          if (setLoadingDeleteVChannel) {
            setLoadingDeleteVChannel(false);
          }
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          if (setLoadingDeleteVChannel) {
            setLoadingDeleteVChannel(false);
          }
        }
      },
    getAllChannels:
      ({ errorNotification }) =>
      async (dispatch) => {
        try {
          const allChannels = await PlatformAPI.getAllChannelsList({ errorNotification });
          dispatch(SET_ALL_CHANNELS(allChannels));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    getOwnChannels:
      ({ errorNotification }) =>
      async (dispatch) => {
        try {
          const ownChannelsList = await PlatformAPI.getOwnChannelsList({ errorNotification });
          dispatch(SET_OWN_CHANNELS(ownChannelsList));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    getSharedChannels:
      ({ errorNotification }) =>
      async (dispatch) => {
        try {
          const sharedChannelsList = await PlatformAPI.getSharedChannelsList({ errorNotification });
          dispatch(SET_SHARED_CHANNELS(sharedChannelsList));
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    getChannelPermissions:
      (id, { errorNotification }) =>
      async () => {
        try {
          const channelPermissions = await PlatformAPI.getChannelPermissions(id, { errorNotification });

          return channelPermissions;
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    getChannelPermissionsDescription:
      ({ cloudIds, channelId, cwid }, { errorNotification }) =>
      async (dispatch) => {
        try {
          const channelPermissionsDescription = await PlatformAPI.getChannelPermissionsDescription(
            { cloudIds, cwid },
            {
              errorNotification,
            }
          );

          dispatch(SET_CHANNEL_PERMISSIONS({ [channelId]: channelPermissionsDescription }));

          return channelPermissionsDescription;
        } catch (error) {
          if (+error.status === SKIP_QS_METHOD_ERROR) {
            return null;
          }
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    removeChannelPermission:
      (permissionId, { errorNotification, successNotification }) =>
      async () => {
        try {
          await PlatformAPI.removeChannelPermission(permissionId, { errorNotification });
          successNotification(translations.shareRemoveSuccess);

          return true;
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
        }
      },
    addChannelPermission:
      (data, { errorNotification, successNotification, setLoading }) =>
      async () => {
        try {
          await PlatformAPI.addChannelPermission(data, { errorNotification });
          successNotification(translations.shareAddSuccess);
          setLoading(false);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setLoading(false);
        }
      },
    invitationsPermission:
      (data, { errorNotification, setLoadingInvitations }) =>
      async () => {
        try {
          await PlatformAPI.invitationsPermission(data, { errorNotification });
          setLoadingInvitations(false);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          setLoadingInvitations(false);
        }
      },
  },
  store.dispatch
);

export default actions;
