import React, { useState, useContext } from "react";
import { Card, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import SubtitlesSectionFormItem from "./SubtitlesSectionFormItem";

import SectionSwitch from "../../../Fields/SectionSwitch";
import EnginePlayoutElement from "../../../EnginePlayoutElement";

import { FormV5Context } from "../../NodeChannelFormV5";

import useNodeChannelConfig from "../../../../hooks/useNodeChannelConfig";

export default function SubtitlesSection({ disabled, handleFinish }) {
  const [open, setOpen] = useState(false);
  const form = useContext(FormV5Context);

  const { channelConfig, subtitles } = useNodeChannelConfig();

  const { resetFields, getFieldValue, validateFields } = form;

  const handleSaveSubtitles = async () => {
    await validateFields();
    const formData = getFieldValue("subtitles");
    handleFinish({ ...channelConfig, subtitles: formData });
    setOpen(false);
  };

  return (
    <Card
      title={
        <>
          <FormattedMessage id="SubtitlesSection.subtitles" defaultMessage="Subtitles" />{" "}
          <SectionSwitch
            prefix={["subtitles", "enabled"]}
            disabled={disabled}
            handleFinish={handleFinish}
            handleOpen={() => setOpen(true)}
          />
        </>
      }
    >
      {subtitles?.enabled && (
        <EnginePlayoutElement
          label={<FormattedMessage id="SubtitlesSection.subtitles" defaultMessage="Subtitles" />}
          handleClick={() => setOpen(true)}
          data={subtitles}
        />
      )}
      <Modal
        title={<FormattedMessage id="SubtitlesSection.subtitles" defaultMessage="Subtitles" />}
        centered
        destroyOnClose
        open={open}
        onCancel={() => {
          resetFields();
          setOpen(false);
        }}
        onOk={handleSaveSubtitles}
        width={1280}
        footer={disabled ? null : undefined}
        okText={
          <span>
            <FormattedMessage id="general.save" defaultMessage="Save" />
          </span>
        }
        okButtonProps={{ icon: <SaveOutlined /> }}
      >
        <SubtitlesSectionFormItem prefix={["subtitles"]} disabled={disabled} />
      </Modal>
    </Card>
  );
}

SubtitlesSection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleFinish: PropTypes.func.isRequired,
};
