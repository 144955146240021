import React, { useContext } from "react";
import { Form, Row, Col, Select } from "antd";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { required } from "../../../../lib/utils/formValidation";
import defaultCGMode from "../../../../lib/utils/defaultValues/defaultCGMode";

import { FormV5Context } from "../../NodeChannelFormV5";
import CasparFormItem from "./CasparFormItem";
import SimpleLogoFormItem from "./SimpleLogoFormItem";

const { Item } = Form;

const translations = defineMessages({
  mode: {
    id: "CGSectionFormItem.mode",
    defaultMessage: "Mode",
  },
});

export default function CGSectionFormItem({ prefix, disabled }) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);
  const { setFieldValue } = form;

  const mode = Form.useWatch([...prefix, "mode"], form);
  const isCasparMode = mode === "caspar";
  const isSimpleLogoMode = mode === "simpleLogo";

  const handleChangeMode = (selectedMode) => {
    setFieldValue(prefix, defaultCGMode[selectedMode] || defaultCGMode.off);
  };

  return (
    <Row gutter={24}>
      <Col span={24}>
        <Item name={[...prefix, "mode"]} label={formatMessage(translations.mode)} rules={[required]}>
          <Select
            placeholder={<FormattedMessage id="CGSectionFormItem.mode" defaultMessage="Mode" />}
            optionFilterProp="children"
            onChange={handleChangeMode}
            disabled={disabled}
            options={[
              { value: "off", label: <FormattedMessage id="CGSectionFormItem.off" defaultMessage="Off" /> },
              { value: "caspar", label: <FormattedMessage id="CGSectionFormItem.caspar" defaultMessage="Caspar" /> },
              {
                value: "simpleLogo",
                label: <FormattedMessage id="CGSectionFormItem.simpleLogo" defaultMessage="Simple logo" />,
              },
            ]}
          />
        </Item>
      </Col>

      {isCasparMode && <CasparFormItem prefix={prefix} disabled={disabled} />}
      {isSimpleLogoMode && <SimpleLogoFormItem prefix={prefix} disabled={disabled} />}
    </Row>
  );
}

CGSectionFormItem.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
};
