import React from "react";
import { FormattedMessage } from "react-intl";
import { Spin, Skeleton } from "antd";

const OrganizationLoading = () => {
  return (
    <Spin
      tip={<FormattedMessage id="OrganizationLoading.loadingData" defaultMessage="Loading organizations data..." />}
    >
      <Skeleton active />
    </Spin>
  );
};

export default OrganizationLoading;
