export default (codecName) => {
  if (!codecName) {
    return "";
  }

  if (codecName.includes("pcm_")) {
    return `PCM (${codecName})`;
  }

  switch (codecName.toUpperCase()) {
    case "H264":
      return "H.264 (AVC)";
    case "MPEG2VIDEO":
      return "MPEG-2 Video";
    case "MPEG2AUDIO":
      return "MPEG-2 Audio";
    case "MP22":
      return "MPEG-2 Audio Layer II";
    case "HEVC":
      return "H.265 (HEVC)";
    case "AAC":
      return "AAC";
    case "RAWVIDEO":
      return "Raw Video";
    case "OPENH264":
      return "OPENH264";
    case "X264":
      return "H264";

    default:
      return codecName;
  }
};
