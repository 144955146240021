import React, { useCallback, useState, useEffect } from "react";
import { Col, Form, Divider, Input, Row, Popover } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import debounce from "lodash.debounce";
import { InfoCircleOutlined } from "@ant-design/icons";

import CloudChannelsService from "../../../../services/cloudChannels";

import VirtualChannelResolutions from "../Fields/VirtualChannelResolutions";
import VirtualChannelCodecs from "../Fields/VirtualChannelCodecs";
import VirtualChannelSubtitles from "../Fields/VirtualChannelSubtitles";
import VirtualChannelType from "../Fields/VirtualChannelType";
import VirtualChannelLanguages from "../Fields/VirtualChannelLanguages";
import VirtualChannelDescription from "../Fields/VirtualChannelDescription";
import VirtualChannelOrganization from "../Fields/VirtualChannelOrganization";
import VirtualChannelRegion from "../Fields/VirtualChannelRegion";

import VirtualChannelNavButtons from "../../../../components/VirtualChannelNavButtons";
import VirtualChannelItemLayout from "./VirtualChannelItemLayout";

import { max130characters, required, min3characters, trim } from "../../../../lib/utils/formValidation";
import { errorNotification, successNotification } from "../../../../lib/utils/notification";
import themeColor from "../../../../lib/style/theme";
import UniqHubChannelName from "./UniqHubChannelName";

import useRole from "../../../../hooks/useRole";

const { Item } = Form;

const translations = defineMessages({
  placeholder: {
    id: "VirtualChannelStep0.placeholder",
    defaultMessage: "Please input name",
  },
});

const VirtualChannelStep0 = ({ currentStep, handleChangeStep, initialValues, cloudId, setCurrentCloudId, form }) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const channelNameValue = Form.useWatch("channelName", form);
  const { isAdminOrSupport } = useRole();
  const [validationData, setValidationData] = useState([]);
  const noChannelName = !(channelNameValue && channelNameValue.length > 2);

  const { resetFields } = form;

  useEffect(() => {
    resetFields();
  }, []);

  const handleFinish = useCallback(
    (formData) => {
      if (cloudId) {
        setLoading(true);
        CloudChannelsService.editCloudChannel(
          { ...formData, cloudId },
          {
            errorNotification: errorNotification(formatMessage),
            setLoading,
            handleChangeStep: () => handleChangeStep(currentStep + 1),
          }
        );
      } else {
        setLoading(true);
        CloudChannelsService.createVirtualChannel(formData, {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
          setLoading,
          handleChangeStep: () => handleChangeStep(currentStep + 1),
          setCurrentCloudId,
        });
      }
    },
    [formatMessage, cloudId]
  );

  const onUniqueValidation = ({ name }) => {
    CloudChannelsService.uniqueChannelNameValidation(name, {
      errorNotification: errorNotification(formatMessage),
      setValidationData,
    });
  };

  const debouncedSearch = useCallback(
    debounce((nextValue) => onUniqueValidation({ name: nextValue }), 600),
    []
  );

  const uniqueChannelValidation = (event) => {
    event.persist();
    const { value } = event.target;

    const isTrimmed = trim.pattern.test(value);

    if (value === initialValues?.name) {
      return;
    }

    if (value && value.length > 1 && value.length <= 32 && isTrimmed) {
      debouncedSearch(value);
    } else {
      setValidationData(false);
    }
  };

  const ValidationSuffix =
    validationData.length > 0 ? (
      <Popover
        placement="right"
        title={
          <FormattedMessage
            id="VirtualChannelStep0.uniqChannelNameValidation"
            defaultMessage="Channel name already in use"
          />
        }
        content={<UniqHubChannelName channels={validationData} />}
        trigger="click"
      >
        <InfoCircleOutlined style={{ color: themeColor.orange }} />
      </Popover>
    ) : (
      <span />
    );

  return (
    <Form {...VirtualChannelItemLayout} onFinish={handleFinish} initialValues={initialValues} form={form}>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Item
            name="channelName"
            label={<FormattedMessage id="VirtualChannelStep0.name" defaultMessage="Name" />}
            rules={[max130characters, required, min3characters]}
          >
            <Input
              placeholder={formatMessage(translations.placeholder)}
              onChange={isAdminOrSupport && uniqueChannelValidation}
              suffix={isAdminOrSupport && ValidationSuffix}
            />
          </Item>
          <Divider />
          <VirtualChannelLanguages />
          <VirtualChannelRegion />
          <VirtualChannelType />
          <VirtualChannelResolutions />
          <VirtualChannelSubtitles />
          <VirtualChannelCodecs />
          {isAdminOrSupport && (
            <>
              <Divider />
              <VirtualChannelOrganization />
            </>
          )}
        </Col>
        <Col span={12}>
          <VirtualChannelDescription />
        </Col>
        <VirtualChannelNavButtons
          currentStep={currentStep}
          handleChangeStep={handleChangeStep}
          noChannelName={noChannelName}
          loading={loading}
        />
      </Row>
    </Form>
  );
};

export default VirtualChannelStep0;
