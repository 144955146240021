import React, { useState } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip, Button } from "antd";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";

import { PERMANENT_LICENSE, LICENSES_TYPES } from "../../../../lib/utils/constants";

import LicenseListTooltip from "./LicenseListTooltip";

const LicenseListLicenseToken = ({ record, isAdmin, handleShowModal, setShowEditLicenseModal }) => {
  const [copiedLicenseId, setCopiedLicenseId] = useState(null);

  const editAvailable = record.updatedAt && !moment(record.updatedAt).add(1, "days").isBefore(moment());
  const isPermanentLicense = record.licenseExpire === PERMANENT_LICENSE;
  const isFreeLicense = record.licenseType === LICENSES_TYPES.FREE;
  const isPlayoutLicense = record.licenseType === LICENSES_TYPES.PLAYOUT;

  const onCopy = (event, licenseId) => {
    const { target } = event;
    target.blur();
    setCopiedLicenseId(licenseId);
    setTimeout(() => {
      setCopiedLicenseId(null);
    }, 1000);
  };

  return (
    <StyledLicenseToken>
      <CopyToClipboard text={isPlayoutLicense ? record.licenseId : record?.encryptedLicenseToken}>
        <Tooltip
          title={<FormattedMessage id="LicenseList.copied" defaultMessage="Copied" />}
          placement="right"
          open={record.licenseId === copiedLicenseId}
        >
          <Button color="orange" onClick={(event) => onCopy(event, record.licenseId)}>
            <FormattedMessage id="LicenseList.copy" defaultMessage="Copy" />
          </Button>
        </Tooltip>
      </CopyToClipboard>
      <Tooltip title={isPlayoutLicense ? record.licenseId : record?.encryptedLicenseToken}>
        <StyledInfoCircleOutlined />
      </Tooltip>
      <Tooltip
        title={
          (!isAdmin && editAvailable) || isPermanentLicense || isFreeLicense || isPlayoutLicense ? (
            <LicenseListTooltip
              editAvailable={editAvailable}
              isAdmin={isAdmin}
              isFreeLicense={isFreeLicense}
              isPermanentLicense={isPermanentLicense}
              isPlayoutLicense={isPlayoutLicense}
            />
          ) : null
        }
        placement="top"
      >
        <Button
          type="dashed"
          onClick={() => handleShowModal(record.licenseId, setShowEditLicenseModal)}
          disabled={(!isAdmin && editAvailable) || isPermanentLicense || isFreeLicense || isPlayoutLicense}
        >
          <FormattedMessage id="general.edit" defaultMessage="Edit" />
        </Button>
      </Tooltip>
    </StyledLicenseToken>
  );
};

const StyledLicenseToken = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-left: 5px;
  margin-right: 5px;
`;

export default LicenseListLicenseToken;
