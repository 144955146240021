import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Select, Form } from "antd";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";

import { required } from "../../../lib/utils/formValidation";
import parseCloudId from "../../../lib/utils/parseCloudId";
import themeColor from "../../../lib/style/theme";

const { Option } = Select;

const { Item } = Form;

const translations = defineMessages({
  fieldRequired: {
    id: "CloudChannelList.fieldRequired",
    defaultMessage: "Please select cloud channel ID",
  },
  sameAsInputCloudId: {
    id: "CloudChannelList.sameAsInputCloudId",
    defaultMessage: "Cannot be the same as input stream cloud ID",
  },
  sameAsOutputCloudId: {
    id: "CloudChannelList.sameAsOutputCloudId",
    defaultMessage: "Cannot be the same as output stream cloud ID",
  },
});

const CloudChannelList = ({
  disabled,
  getFieldValue,
  name,
  label,
  initialValue,
  channelList,
  sameAsValidation,
  setFieldsValue,
  isOutput,
  isMuxedOutput,
  isInput,
}) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (initialValue?.permissionId) {
      setFieldsValue({ [name]: { cloudId: initialValue?.permissionId } });
    }
  }, [initialValue, setFieldsValue, name]);

  const handleChange = (selectedValue, option) => {
    const selectedChannelData =
      channelList.find((channel) => +channel.permissionId === +option.key) ||
      channelList.find((channel) => +channel.cloudId === +option.key);

    if (isMuxedOutput) {
      const muxedOutputs = getFieldValue(["muxedOutputs"]);
      muxedOutputs[name[1]].outputs[name[3]] = {
        ...muxedOutputs[name[1]].outputs[name[3]],
        cloudName: selectedChannelData.channelName,
      };

      setFieldsValue({ muxedOutputs });
    }

    if (isOutput) {
      const nonMuxedOutputs = getFieldValue(["nonMuxedOutputs"]);
      const outputFormData = getFieldValue(name);
      nonMuxedOutputs[name[1]] = { ...outputFormData, cloudName: selectedChannelData.channelName };

      setFieldsValue({ nonMuxedOutputs });
    }

    if (name[0] === "input") {
      setFieldsValue({ input: { mainConnection: { permissionId: selectedValue } } });
      setFieldsValue({ input: { mainConnection: { cloudId: selectedChannelData.cloudId } } });
      setFieldsValue({ input: { mainConnection: { description: selectedChannelData.description } } });
      setFieldsValue({ input: { mainConnection: { cloudName: selectedChannelData.channelName } } });
    }
  };

  const validateCloudId = (rule, value) => {
    const inputCloudId = getFieldValue(["input", "mainConnection", "cloudId"]);
    const outputCloudId = getFieldValue(["nonMuxedOutputs", 0, "cloudId"]);

    if (!value) {
      return Promise.reject(formatMessage(translations.fieldRequired));
    }
    if (sameAsValidation === "output" && value === outputCloudId) {
      return Promise.reject(formatMessage(translations.sameAsOutputCloudId));
    }
    if (sameAsValidation === "input" && value === inputCloudId) {
      return Promise.reject(formatMessage(translations.sameAsInputCloudId));
    }

    return Promise.resolve();
  };

  const checkIfDisable = ({ cwid, cloudId, permissionId }) => {
    if (initialValue?.cloudId) {
      if (cloudId === initialValue?.cloudId) {
        return false;
      }
    }

    if (initialValue?.permissionId) {
      if (permissionId === initialValue?.permissionId) {
        return false;
      }
    }

    return cwid;
  };

  return (
    <>
      <Item
        name={[...name, ...(isInput ? ["permissionId"] : ["cloudId"])]}
        label={label}
        rules={[{ validator: validateCloudId }, { ...required }]}
      >
        <Select onSelect={handleChange} disabled={disabled}>
          {channelList.map((channel) => (
            <Option
              key={channel.permissionId || channel.cloudId || "empty"}
              value={channel.permissionId || channel.cloudId}
              disabled={checkIfDisable({
                cwid: !!channel.cwid,
                permissionId: channel.permissionId,
                cloudId: channel.cloudId,
              })}
            >
              {channel.cloudId && `${parseCloudId(channel.cloudId)} `}
              {channel.description ? <span>{`${channel.description}`}</span> : <span>{`${channel.channelName}`}</span>}
              {channel.cnn && (
                <StyledSpan>
                  <>
                    {" "}
                    (<FormattedMessage id="CloudChannelList.usedOn" defaultMessage="Used on" /> {channel.cnn})
                  </>
                </StyledSpan>
              )}
            </Option>
          ))}
        </Select>
      </Item>
    </>
  );
};

CloudChannelList.propTypes = {
  disabled: PropTypes.bool,
  setFieldsValue: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.shape({
      cloudId: PropTypes.number.isRequired,
      channelName: PropTypes.string.isRequired,
    }),
  ]),
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  channelList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cloudId: PropTypes.number.isRequired,
        channelName: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isEncrypted: PropTypes.bool.isRequired,
        public: PropTypes.bool.isRequired,
        userId: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  sameAsValidation: PropTypes.string.isRequired,
  isOutput: PropTypes.bool,
  isMuxedOutput: PropTypes.bool,
  isInput: PropTypes.bool,
};

CloudChannelList.defaultProps = {
  disabled: null,
  initialValue: null,
  isOutput: false,
  isMuxedOutput: false,
  isInput: false,
};

const StyledSpan = styled.span`
  color: ${themeColor.orange};
`;

export default CloudChannelList;
