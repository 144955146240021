import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Table, Card } from "antd";
import { useParams } from "react-router-dom";

import LicenseServices from "../../../../services/license";
import { selectors as LICENSES_SELECTORS, actions as LICENSES_ACTIONS } from "../../../../ducks/license";
import store from "../../../../store";

import { errorNotification } from "../../../../lib/utils/notification";

import CloudLicensesListElement from "../../../../components/CloudLicensesListElement/CloudLicensesListElement";

const columns = [
  {
    title: <FormattedMessage id="CloudLicenses.date" defaultMessage="Date" />,
    dataIndex: "date",
    key: "date",
    fixed: "left",
  },
  {
    title: <FormattedMessage id="CloudLicenses.aac" defaultMessage="AAC" />,
    dataIndex: "codecAAC",
    key: "codecAAC",
    sorter: (currentValue, nextValue) => currentValue.codecAAC - nextValue.codecAAC,
    render: (codecAAC, record) => (
      <CloudLicensesListElement usage={codecAAC} usageLimit={record?.limits?.codecAAC} userList />
    ),
  },
  {
    title: <FormattedMessage id="CloudLicenses.x264" defaultMessage="X264" />,
    dataIndex: "codecX264",
    key: "codecX264",
    sorter: (currentValue, nextValue) => currentValue.codecX264 - nextValue.codecX264,
    render: (codecX264, record) => (
      <CloudLicensesListElement usage={codecX264} usageLimit={record?.limits?.codecX264} userList />
    ),
  },
  {
    title: <FormattedMessage id="CloudLicenses.x265" defaultMessage="X265" />,
    dataIndex: "codecX265",
    key: "codecX265",
    sorter: (currentValue, nextValue) => currentValue.codecX265 - nextValue.codecX265,
    render: (codecX265, record) => (
      <CloudLicensesListElement usage={codecX265} usageLimit={record?.limits?.codecX265} userList />
    ),
  },
  {
    title: <FormattedMessage id="CloudLicenses.inputsCount" defaultMessage="Inputs Count" />,
    dataIndex: "inputsCount",
    key: "inputsCount",
    sorter: (currentValue, nextValue) => currentValue.inputsCount - nextValue.inputsCount,
    render: (inputsCount, record) => (
      <CloudLicensesListElement usage={inputsCount} usageLimit={record?.limits?.inputsCount} userList />
    ),
  },
  {
    title: <FormattedMessage id="CloudLicenses.inputsNotEncoded" defaultMessage="Inputs not Encoded" />,
    dataIndex: "inputsNotEncoded",
    key: "inputsNotEncoded",
    sorter: (currentValue, nextValue) => currentValue.inputsNotEncoded - nextValue.inputsNotEncoded,
  },
  {
    title: <FormattedMessage id="CloudLicenses.inputsOther" defaultMessage="Inputs Other" />,
    dataIndex: "inputsOther",
    key: "inputsOther",
    sorter: (currentValue, nextValue) => currentValue.inputsOther - nextValue.inputsOther,
  },
  {
    title: <FormattedMessage id="CloudLicenses.inputsDirect" defaultMessage="Inputs Quickstream" />,
    dataIndex: "inputsQuickstream",
    key: "inputsQuickstream",
    sorter: (currentValue, nextValue) => currentValue.inputsQuickstream - nextValue.inputsQuickstream,
  },
  {
    title: <FormattedMessage id="CloudLicenses.outputsCount" defaultMessage="Outputs Count" />,
    dataIndex: "outputsCount",
    key: "outputsCount",
    sorter: (currentValue, nextValue) => currentValue.outputsCount - nextValue.outputsCount,
    render: (outputsCount, record) => (
      <CloudLicensesListElement usage={outputsCount} usageLimit={record?.limits?.outputsCount} userList />
    ),
  },
  {
    title: <FormattedMessage id="CloudLicenses.outputsDirect" defaultMessage="Outputs Direct" />,
    dataIndex: "outputsDirect",
    key: "outputsDirect",
    sorter: (currentValue, nextValue) => currentValue.outputsDirect - nextValue.outputsDirect,
  },
  {
    title: <FormattedMessage id="CloudLicenses.outputsEncoded" defaultMessage="Outputs Encoded" />,
    dataIndex: "outputsEncoded",
    key: "outputsEncoded",
    sorter: (currentValue, nextValue) => currentValue.outputsEncoded - nextValue.outputsEncoded,
  },
  {
    title: <FormattedMessage id="CloudLicenses.outputsNotEncoded" defaultMessage="Outputs not Encoded" />,
    dataIndex: "outputsNotEncoded",
    key: "outputsNotEncoded",
    sorter: (currentValue, nextValue) => currentValue.outputsNotEncoded - nextValue.outputsNotEncoded,
  },
  {
    title: <FormattedMessage id="CloudLicenses.outputsQuickstream" defaultMessage="Outputs Quickstream" />,
    dataIndex: "outputsQuickstream",
    key: "outputsQuickstream",
    sorter: (currentValue, nextValue) => currentValue.outputsQuickstream - nextValue.outputsQuickstream,
    render: (outputsQuickstream, record) => (
      <CloudLicensesListElement usage={outputsQuickstream} usageLimit={record?.limits?.outputsQuickstream} userList />
    ),
  },
];

const CloudUserLicensesList = () => {
  const { username } = useParams();
  const { formatMessage } = useIntl();
  const userLicenses = useSelector(LICENSES_SELECTORS.getCloudUserLicensesList);
  const userLicenseLimits = useSelector(LICENSES_SELECTORS.getCloudUserLicensesLimits);

  useEffect(() => {
    LicenseServices.getCloudUserLicensesList({ errorNotification: errorNotification(formatMessage), username });

    return () => {
      store.dispatch(LICENSES_ACTIONS.CLEAR_CLOUD_USER_LICENSES());
    };
  }, [formatMessage, username]);

  return (
    <Card
      title={
        <>
          <FormattedMessage id="CloudUserLicensesList.cloudLicenses" defaultMessage="Cloud licenses" />: {username}
        </>
      }
    >
      <Table
        columns={columns}
        dataSource={userLicenses}
        bordered
        role="table"
        rowKey={(record) => record.date}
        scroll={{ x: 1500, y: 500 }}
        pagination={false}
        summary={() => (
          <Table.Summary fixed="top">
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={1}>
                <b>
                  <FormattedMessage id="CloudLicenses.limits" defaultMessage="Limits" />
                </b>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <b>{userLicenseLimits?.codecAAC}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <b>{userLicenseLimits?.codecX264}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <b>{userLicenseLimits?.codecX265}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <b>{userLicenseLimits?.inputsCount}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell />
              <Table.Summary.Cell />
              <Table.Summary.Cell />
              <Table.Summary.Cell>
                <b>{userLicenseLimits?.outputsCount}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell />
              <Table.Summary.Cell />
              <Table.Summary.Cell />
              <Table.Summary.Cell>
                <b>{userLicenseLimits?.outputsQuickstream}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </Card>
  );
};

export default CloudUserLicensesList;
