import { ENGINE_TYPES, INPUT_STREAM_TYPE } from "./constants";

const getActiveInputType = ({ playingSource, config }) => {
  if (config?.type === ENGINE_TYPES.DIRECT) {
    return config?.input?.type;
  }

  const isMainInputActive = playingSource === INPUT_STREAM_TYPE.main;
  const isBackupInputActive = playingSource === INPUT_STREAM_TYPE.backup;
  const isEmergencyInputActive = playingSource === INPUT_STREAM_TYPE.emergency;

  if (config?.type === ENGINE_TYPES.TRANSCODING && isMainInputActive) {
    return config?.mainInput?.type;
  }

  if (config?.type === ENGINE_TYPES.TRANSCODING && isBackupInputActive) {
    return config?.backupInput?.type;
  }

  if (config?.type === ENGINE_TYPES.TRANSCODING && isEmergencyInputActive) {
    return INPUT_STREAM_TYPE.emergency;
  }

  if (config?.input?.type) {
    return config?.input?.type;
  }

  return "not supported input type";
};

export default getActiveInputType;
