import {
  SELECT_STREAM_CODEC,
  SELECT_STREAM_CODEC_RESIZE,
  SELECT_STREAM_CODEC_FPS,
  MPEG2VIDEO_PIX_FORMAT,
  SELECT_STREAM_CODEC_AUDIO,
  MPEG2AUDIO_BITRATE,
  MPEG2AUDIO_SAMPLE_FORMAT,
  MPEG2AUDIO_SAMPLE_RATE,
  STREAMS_TYPE,
  SELECT_DEFAULT_AUTO,
  AAC_PROFILE,
} from "../constants";

export const DEFAULT_VIDEO_STREAM = {
  autoPid: true,
  stream: {
    codec: {
      type: SELECT_STREAM_CODEC[2].value,
      resize: SELECT_STREAM_CODEC_RESIZE[0].value,
      fps: SELECT_STREAM_CODEC_FPS[0].value,
      pixFormat: MPEG2VIDEO_PIX_FORMAT[0].value,
      changeGOP: false,
      interlaced: false,
    },
    type: STREAMS_TYPE[0].value,
    selectStream: {
      streamId: SELECT_DEFAULT_AUTO.value,
    },
    filters: [],
  },
};

export const DEFAULT_AUDIO_STREAM = {
  autoPid: true,
  stream: {
    selectStream: {
      streamId: SELECT_DEFAULT_AUTO.value,
    },
    type: STREAMS_TYPE[1].value,
    filters: [],
    codec: {
      type: SELECT_STREAM_CODEC_AUDIO[1].value,
      b: MPEG2AUDIO_BITRATE[0].value,
      sampleFormat: MPEG2AUDIO_SAMPLE_FORMAT[0].value,
      sampleRate: MPEG2AUDIO_SAMPLE_RATE[0].value,
    },
  },
};

export const RTMP_AUDIO_STREAM = {
  autoPid: true,
  stream: {
    selectStream: {
      streamId: SELECT_DEFAULT_AUTO.value,
    },
    type: STREAMS_TYPE[1].value,
    filters: [],
    codec: {
      type: SELECT_STREAM_CODEC_AUDIO[2].value,
      b: MPEG2AUDIO_BITRATE[0].value,
      sampleFormat: MPEG2AUDIO_SAMPLE_FORMAT[0].value,
      sampleRate: MPEG2AUDIO_SAMPLE_RATE[0].value,
      aacProfile: AAC_PROFILE[0].value,
    },
  },
};
