import React from "react";
import { Modal, Divider, Row, Col, Typography, Button } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import Cookies from "universal-cookie";
import iconUrl from "../../lib/images/icon192x192.png";

const { Title, Text } = Typography;
const year = moment().year();
const cookies = new Cookies();

const TutorialsModal = ({ handleShowTutorialsModal, showTutorialsModal }) => {
  const handleSkipTutorials = () => {
    cookies.set("QuickstreamSkipTutorials", true);
    handleShowTutorialsModal();
  };

  return (
    <Modal
      title={<FormattedMessage id="TutorialsModal.title" defaultMessage="Quickstream Tutorials" />}
      open={showTutorialsModal}
      onCancel={handleShowTutorialsModal}
      width={600}
      footer={[
        <Button key="submit" type="primary" onClick={handleSkipTutorials}>
          <FormattedMessage id="TutorialsModal.doNotShowAgain" defaultMessage="Ok, don't show tutorials again" />
        </Button>,
      ]}
    >
      <Row type="flex" justify="center" gutter={12}>
        <Col span={6}>
          <StyledIcon />
        </Col>
        <Col span={18}>
          <Title level={4}>
            <FormattedMessage id="TutorialsModal.quickstream" defaultMessage=" How to get started with Quickstream?" />
          </Title>
          <StyledDiv>
            <Text>
              <FormattedMessage id="TutorialsModal.itsEasy" defaultMessage="It's easy, click" />{" "}
            </Text>
            <a href="https://quickstream.tech/tutorials" target="_blank" rel="noopener noreferrer" aria-label="here">
              <FormattedMessage id="general.here" defaultMessage="here" />
            </a>{" "}
            <Text>
              <FormattedMessage id="TutorialsModal.toWatchVideo" defaultMessage="to watch video explainers" />.
            </Text>
          </StyledDiv>
          <StyledDiv>
            <Text>
              <FormattedMessage id="TutorialsModal.forMoreDetails" defaultMessage="For more details: check our" />{" "}
            </Text>
            <a
              href="https://docs.cloud.quickstream.tech"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="knowledge base"
            >
              <FormattedMessage id="About.knowledgeBase" defaultMessage="knowledge base " />
            </a>
            .
          </StyledDiv>
        </Col>
      </Row>
      <Divider />
      <div>
        <span>{`© 2018–${year} tvhive sp. z o.o.`}</span>{" "}
        <FormattedMessage id="TutorialsModal.footer" defaultMessage="All rights reserved." />
      </div>
    </Modal>
  );
};

const StyledDiv = styled.div`
  margin-bottom: 10px;
`;

const StyledIcon = styled.div`
  width: 100px;
  height: 100px;
  background: url(${iconUrl});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

TutorialsModal.propTypes = {
  handleShowTutorialsModal: PropTypes.func.isRequired,
  showTutorialsModal: PropTypes.bool.isRequired,
};

export default TutorialsModal;
