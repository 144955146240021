import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";
import { createSelector } from "reselect";

import nodeTranslations from "../lib/translations/nodeTranslations";
import { INPUTS_STATUS_IDS, P2_CODECS_STATS } from "../lib/utils/constants";
import parseConnectionMethodName from "../lib/utils/parseConnectionMethodName";

export const actions = {
  SET_STATS_PARAMS_V5: createAction("STATS_V5/SET_STATS_PARAMS_V5"),
  SET_DATE_RANGE_PARAMS_V5: createAction("STATS_V5/SET_DATE_RANGE_PARAMS_V5"),
  SET_STAT_SCALE_V5: createAction("STATS_V5/SET_STAT_SCALE_V5"),
  SET_STATS_DATA_V5: createAction("STATS_V5/SET_STATS_DATA_V5"),
  CLEAR_STATS_V5_DATA: createAction("STATS_V5/CLEAR_STATS_V5_DATA"),
  CLEAR_STATS_V5: createAction("STATS_V5/CLEAR_STAT_V5"),
};

const defaultState = new Map({
  params: null,
  data: [],
  dateRange: 1,
  statScale: 1,
});

const reducer = handleActions(
  {
    [actions.SET_STATS_PARAMS_V5]: (state, { payload }) => state.merge({ params: payload }),
    [actions.SET_STATS_DATA_V5]: (state, { payload }) => state.merge({ data: payload }),
    [actions.SET_DATE_RANGE_PARAMS_V5]: (state, { payload }) => state.merge({ dateRange: payload }),
    [actions.SET_STAT_SCALE_V5]: (state, { payload }) => state.merge({ statScale: payload }),
    [actions.CLEAR_STATS_V5_DATA]: (state) => state.setIn(["data"], []),
    [actions.CLEAR_STATS_V5]: () => {
      return defaultState;
    },
  },
  defaultState
);

export const selectors = {
  getCodecStats: (state) => state.getIn(["nodeStatsV5", "params", "codecsStats"]),
  getInputStats: (state) => state.getIn(["nodeStatsV5", "params", "inputStats"]),
  getInputStreamStats: (state) => state.getIn(["nodeStatsV5", "params", "inputStreamsStats"]),
  getChannelStatsParams: (state) => state.getIn(["nodeStatsV5", "params", "channelStats"]),
  getChannelStats: (state) => state.getIn(["nodeStatsV5", "params", "channelStats", "n"]),
  getProcessingStatsSelector: (state) => {
    const switcherStats = state.getIn(["nodeStatsV5", "params", "switcherStats"]) || {};
    const { n: selectorValues, ...restParams } = switcherStats;

    const selectorData =
      selectorValues &&
      selectorValues.map((selectorValue) => ({
        value: selectorValue,
        label: (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
      }));

    return { selectorData, params: restParams };
  },
  getCodecsStatsSelector: (state) => {
    const codecsStatsArray = state.getIn(["nodeStatsV5", "params", "codecsStats"]) || [];
    const videoCodecStats = codecsStatsArray.find((inputData) => inputData.p2 === P2_CODECS_STATS.VIDEO) || {};
    const { n: videoSelectorValues, ...videoRestParams } = videoCodecStats;
    const audioCodecStats = codecsStatsArray.find((inputData) => inputData.p2 === P2_CODECS_STATS.AUDIO) || {};
    const { n: audioSelectorValues, ...audioRestParams } = audioCodecStats;

    const videoSelectorData =
      videoSelectorValues &&
      videoSelectorValues.map((selectorValue) => ({
        value: selectorValue,
        label: (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
      }));

    const audioSelectorData =
      audioSelectorValues &&
      audioSelectorValues.map((selectorValue) => ({
        value: selectorValue,
        label: (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
      }));

    return {
      videoSelectorData,
      audioSelectorData,
      audioCodecParams: audioRestParams,
      videoCodecParams: videoRestParams,
    };
  },
  getOutputsStatsSelector: (state, channelId) => {
    const channelOutputs = state.getIn(["nodeChannels", "list", channelId, "outputs", "outputsConfigs"]) || [];
    // const outputStatsParams = state.getIn(["nodeStatsV5", "params", "outputsStats"]) || [];
    const outputStreamStatsParams = state.getIn(["nodeStatsV5", "params", "outputStreamsStats"]) || [];
    const networkStreamStatsParams = state.getIn(["nodeStatsV5", "params", "networkStreamsStats"]) || [];
    const channelPermissions = state.getIn(["cloudChannels", "permissions", channelId]) || [];

    // const parsedOutputStatsParams = outputStatsParams.map((directStat) => ({
    //   ...directStat,
    //   outputId: directStat?.p1,
    //   parentOutputId: directStat?.p1,
    // }));

    const uniqueParentsOutputsIds = new Set();
    const uniqueQSChannelIds = new Set();
    const uniqueOutputsIds = {};
    const uniqueOutputStreamsIds = {};

    outputStreamStatsParams.forEach((directStat) => {
      uniqueParentsOutputsIds.add(directStat?.p1);
      const outputConfig = channelOutputs.find((channelOutput) => channelOutput.outputId === directStat.p1);

      const parsedOutputName = outputConfig?.name || outputConfig?.outputName;

      uniqueOutputStreamsIds[directStat.p1] = [
        ...(uniqueOutputStreamsIds[directStat.p1] ? uniqueOutputStreamsIds[directStat.p1] : []),
        {
          ...directStat,
          outputId: directStat?.p1,
          name: `${parsedOutputName} (${directStat.p2 === -1 ? "VIDEO STREAM" : "AUDIO STREAM"})`,
          type: outputConfig?.type,
          cloudName: outputConfig?.cloudName,
        },
      ];
    });

    const parsedNetworkStreamStatsParams = [];

    networkStreamStatsParams.forEach((networkStreamStatParam) => {
      networkStreamStatParam.outputsNonQs.forEach((outputNonQS) => {
        uniqueParentsOutputsIds.add(outputNonQS?.p1);
        const outputConfig = channelOutputs.find((channelOutput) => channelOutput.outputId === outputNonQS?.p2);

        uniqueOutputsIds[outputNonQS?.p1] = [
          ...(uniqueOutputsIds[outputNonQS?.p1] ? uniqueOutputsIds[outputNonQS?.p1] : []),
          {
            ...outputNonQS,
            outputId: outputNonQS?.p2,
            name: outputConfig?.name || outputConfig?.outputName,
            type: outputConfig?.type,
            cloudName: outputConfig?.cloudName,
            connectionMethod: `${parseConnectionMethodName(outputConfig?.type)}`,
          },
        ];

        parsedNetworkStreamStatsParams.push({
          ...outputNonQS,
          outputId: outputNonQS?.p2,
          parentOutputId: outputNonQS?.p1,
        });
      });
      networkStreamStatParam.outputsQS.forEach((outputQS) => {
        outputQS.receivers.forEach((receiver) => {
          uniqueParentsOutputsIds.add(receiver.p1);
          const cloudId = receiver?.p3 && receiver.p3.toString().slice(0, 9);
          uniqueQSChannelIds.add(+cloudId);
          const outputConfig = channelOutputs.find((channelOutput) => channelOutput.outputId === outputQS.outputId);

          const permissionData = channelPermissions.find((permission) => permission?.permissionId === receiver?.p3);
          const permissionTabLabel = permissionData?.description
            ? `${permissionData?.description} (${permissionData?.username})`
            : `Share: ${receiver?.p3}`;

          uniqueOutputsIds[receiver.p1] = [
            ...(uniqueOutputsIds[receiver.p1] ? uniqueOutputsIds[receiver.p1] : []),
            {
              ...receiver,
              outputId: receiver?.p2,
              name: permissionTabLabel || outputConfig?.name || outputConfig?.outputName,
              type: outputConfig?.type,
              cloudName: outputConfig?.cloudName,
            },
          ];

          parsedNetworkStreamStatsParams.push({
            ...receiver,
            parentOutputId: receiver.p1,
            outputId: receiver?.p2,
          });
        });
      });
    });

    const parentOutputs = [...uniqueParentsOutputsIds].map((outputId) => {
      const outputConfig = channelOutputs.find((channelOutput) => channelOutput.outputId === outputId);

      return {
        outputId,
        name: outputConfig?.name || outputConfig?.outputName,
        type: outputConfig?.type,
        cloudName: outputConfig?.cloudName,
        connectionMethod: `${parseConnectionMethodName(outputConfig?.type)}`,
      };
    });

    return {
      parentOutputs,
      uniqueOutputsIds,
      uniqueOutputStreamsIds,
      qsChannelIds: JSON.stringify(Array.from(uniqueQSChannelIds)),
    };
  },
  getOutputStats: (state) => state.getIn(["nodeStatsV5", "params", "outputsStats"]),
  getOutputSteamStats: (state) => state.getIn(["nodeStatsV5", "params", "outputStreamsStats"]),
  getNetworkSteamStats: (state) => state.getIn(["nodeStatsV5", "params", "networkStreamsStats"]),
  getSwitcherStats: (state) => state.getIn(["nodeStatsV5", "params", "switcherStats"]),
  getStatsData: (state) => state.getIn(["nodeStatsV5", "data"]),
  getStatsRangeParam: (state) => state.getIn(["nodeStatsV5", "dateRange"]),
  getStatsScale: (state) => state.getIn(["nodeStatsV5", "statScale"]),
};

export const getMainInputStatsSelector = createSelector(
  [selectors.getInputStats, selectors.getInputStreamStats],
  (inputsArray, inputStreamsArray) => {
    const mainInputStream =
      (inputStreamsArray && inputStreamsArray.filter((inputStream) => inputStream.p1 === INPUTS_STATUS_IDS.MAIN)) || [];
    const mainInputStreamSelectorData = [];

    if (mainInputStream) {
      mainInputStream.forEach((inputStream) => {
        const { n: selectorValues, ...restParams } = inputStream || {};
        selectorValues.forEach((selectorValue) => {
          mainInputStreamSelectorData.push({
            value: selectorValue,
            label:
              (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
            params: restParams,
          });
        });
      });
    }

    const mainInput = (inputsArray && inputsArray.find((inputData) => inputData.p1 === INPUTS_STATUS_IDS.MAIN)) || {};
    const { n: selectorValues, ...restParams } = mainInput;

    const mainInputSelectorData =
      selectorValues &&
      selectorValues.map((selectorValue) => ({
        value: selectorValue,
        label: (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
        params: restParams,
      }));

    return {
      selectorData: [...(mainInputSelectorData || []), ...mainInputStreamSelectorData],
    };
  }
);

export const getBackupInputStatsSelector = createSelector(
  [selectors.getInputStats, selectors.getInputStreamStats],
  (inputsArray, inputStreamsArray) => {
    const mainInputStream =
      (inputStreamsArray && inputStreamsArray.filter((inputStream) => inputStream.p1 === INPUTS_STATUS_IDS.BACKUP)) ||
      [];
    const backupInputStreamSelectorData = [];

    if (mainInputStream) {
      mainInputStream.forEach((inputStream) => {
        const { n: selectorValues, ...restParams } = inputStream || {};
        selectorValues.forEach((selectorValue) => {
          backupInputStreamSelectorData.push({
            value: selectorValue,
            label:
              (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
            params: restParams,
          });
        });
      });
    }

    const backupInput =
      (inputsArray && inputsArray.find((inputData) => inputData.p1 === INPUTS_STATUS_IDS.BACKUP)) || {};
    const { n: selectorValues, ...restParams } = backupInput;

    const backupInputSelectorData =
      selectorValues &&
      selectorValues.map((selectorValue) => ({
        value: selectorValue,
        label: (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
        params: restParams,
      }));

    return {
      selectorData: [...(backupInputSelectorData || []), ...backupInputStreamSelectorData],
    };
  }
);

export const getProcessingStatsSelector = createSelector([selectors.getSwitcherStats], (switcherStats) => {
  const { n: selectorValues, ...restParams } = switcherStats || {};

  const selectorData =
    selectorValues &&
    selectorValues.map((selectorValue) => ({
      value: selectorValue,
      label: (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
    }));

  return { selectorData, params: restParams };
});

export const getCodecsStatsSelector = createSelector([selectors.getCodecStats], (codecsStatsArray) => {
  const videoCodecStats =
    (codecsStatsArray && codecsStatsArray.find((inputData) => inputData.p2 === P2_CODECS_STATS.VIDEO)) || {};
  const { n: videoSelectorValues, ...videoRestParams } = videoCodecStats;
  const audioCodecStats =
    (codecsStatsArray && codecsStatsArray.find((inputData) => inputData.p2 === P2_CODECS_STATS.AUDIO)) || {};
  const { n: audioSelectorValues, ...audioRestParams } = audioCodecStats;

  const videoSelectorData =
    videoSelectorValues &&
    videoSelectorValues.map((selectorValue) => ({
      value: selectorValue,
      label: (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
    }));

  const audioSelectorData =
    audioSelectorValues &&
    audioSelectorValues.map((selectorValue) => ({
      value: selectorValue,
      label: (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
    }));

  return {
    videoSelectorData,
    audioSelectorData,
    audioCodecParams: audioRestParams,
    videoCodecParams: videoRestParams,
  };
});

export const getChannelStatsSelector = createSelector([selectors.getChannelStats], (selectorValues) => {
  const selectorData =
    selectorValues &&
    selectorValues.map((selectorValue) => ({
      value: selectorValue,
      label: (selectorValue in nodeTranslations && nodeTranslations[selectorValue].defaultMessage) || selectorValue,
    }));

  return selectorData;
});

export default reducer;
