import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Col, Spin, Badge, Button, Modal, Row, Select, Form, Card, Divider } from "antd";
import { useParams } from "react-router-dom";
import { SaveOutlined } from "@ant-design/icons";

import MQTTService from "../../../../../services/mqtt";
import { selectors as NODE_SELECTORS } from "../../../../../ducks/node";

import InputNumberField from "../../../../Fields/InputNumberField";
import IncompleteConfig from "../../../../IncompleteConfig";

import themeColor from "../../../../../lib/style/theme";
import {
  INPUT_STREAM_TYPE,
  SELECTOR_EMERGENCY_MODE,
  EMERGENCY_MODE,
  MESSAGE_TYPE,
} from "../../../../../lib/utils/constants";
import { required } from "../../../../../lib/utils/formValidation";

import BackupSwitchMsecsLabel from "./BackupSwitchMsecsLabel ";
import BackupReturnMsecsLabel from "./BackupReturnMsecsLabel";
import EmergencySwitchMsecsLabel from "./EmergencySwitchMsecsLabel";
import EmergencyReturnMsecsLabel from "./EmergencyReturnMsecsLabel";

const { Ribbon } = Badge;
const { Option } = Select;
const { Item } = Form;

function InputTranscodingEmergency({
  label,
  title,
  emergencyFile,
  emergencySwitchMsecs,
  emergencyReturnMsecs,
  backupSwitchMsecs,
  backupReturnMsecs,
  playingSource,
  detailView,
  handleOKInput,
  form,
}) {
  const [showEmergencyForm, setShowEmergencyForm] = useState(false);
  const EMERGENCY_FILES_LIST = useSelector(NODE_SELECTORS.getEmergencyFilesList);
  const { validateFields, setFieldValue, resetFields } = form;
  const selectedEmergencyMode = Form.useWatch(["emergencyMode"], form);
  const isEmergencyFileNeeded = selectedEmergencyMode !== EMERGENCY_MODE.NONE;
  const { cwid } = useParams();

  useEffect(() => {
    if (selectedEmergencyMode === EMERGENCY_MODE.STATIC) {
      MQTTService.sendMessageV5({
        topic: `node/${cwid}/in`,
        message: { msgType: MESSAGE_TYPE.LIST_EMERGENCY_IMAGES_FILES },
      });
    }

    if (selectedEmergencyMode === EMERGENCY_MODE.LOOP) {
      MQTTService.sendMessageV5({
        topic: `node/${cwid}/in`,
        message: { msgType: MESSAGE_TYPE.LIST_EMERGENCY_VIDEO_FILES },
      });
    }
  }, [selectedEmergencyMode]);

  const handleShowEmergency = () => {
    setShowEmergencyForm(true);
  };

  const handleCancelEmergency = () => {
    resetFields();
    setShowEmergencyForm(false);
  };

  const handleFinish = async () => {
    try {
      await validateFields();
      handleOKInput();
      setShowEmergencyForm(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("InputTranscodingEmergency form validation error", error);
    }
  };

  const handleChangeEmergencyType = (value) => {
    if (value === EMERGENCY_MODE.STATIC) {
      setFieldValue("emergencyFile", null);
      MQTTService.sendMessageV5({
        topic: `node/${cwid}/in`,
        message: { msgType: MESSAGE_TYPE.LIST_EMERGENCY_IMAGES_FILES },
      });
    }

    if (value === EMERGENCY_MODE.LOOP) {
      setFieldValue("emergencyFile", null);
      MQTTService.sendMessageV5({
        topic: `node/${cwid}/in`,
        message: { msgType: MESSAGE_TYPE.LIST_EMERGENCY_VIDEO_FILES },
      });
    }
  };

  return (
    <Col md={24} lg={24} xxl={8}>
      <StyledSpin
        indicator={<> </>}
        spinning={!!playingSource && playingSource !== INPUT_STREAM_TYPE.emergency && detailView}
        tip={<FormattedMessage id="InputTranscodingSection.inactive" defaultMessage="Inactive" />}
        onClick={handleShowEmergency}
      >
        <Ribbon text={<IncompleteConfig incomplete={[]} color="#FFFFFF" />} color={themeColor.green}>
          <StyledContainer onClick={handleShowEmergency}>
            <div>
              {backupSwitchMsecs && (
                <>
                  <StyledText>
                    <FormattedMessage id="InputTranscodingEmergency.switchTime" defaultMessage="Switch time [ms]" />:
                  </StyledText>
                  <StyledBoldText>{backupSwitchMsecs}</StyledBoldText>
                </>
              )}
              <Divider type="vertical" />
              {backupReturnMsecs && (
                <>
                  <StyledText>
                    <FormattedMessage id="InputTranscodingEmergency.returnTime" defaultMessage="Return time [ms]" />:
                  </StyledText>
                  <StyledBoldText>{backupReturnMsecs}</StyledBoldText>
                </>
              )}
            </div>
            <StyledAsideType>{label}</StyledAsideType>
            <StyledBoldText>{title}</StyledBoldText>
            {emergencyFile && (
              <div>
                <StyledText>
                  <FormattedMessage id="InputTranscodingEmergency.file" defaultMessage="File" />:
                </StyledText>
                <StyledBoldText>{emergencyFile}</StyledBoldText>
              </div>
            )}
            <div>
              {emergencySwitchMsecs && (
                <>
                  <StyledText>
                    <FormattedMessage
                      id="InputTranscodingEmergency.emergencySwitchMsecs"
                      defaultMessage="Emergency switch [ms]"
                    />
                    :
                  </StyledText>
                  <StyledBoldText>{emergencySwitchMsecs}</StyledBoldText>
                </>
              )}
              <Divider type="vertical" />
              {emergencyReturnMsecs && (
                <>
                  <StyledText>
                    <FormattedMessage
                      id="InputTranscodingEmergency.emergencyReturnMsecs"
                      defaultMessage="Emergency return [ms]"
                    />
                    :
                  </StyledText>
                  <StyledBoldText>{emergencyReturnMsecs}</StyledBoldText>
                </>
              )}
            </div>
          </StyledContainer>
        </Ribbon>
      </StyledSpin>
      <StyledModal
        destroyOnClose
        zIndex={100}
        centered
        title={<FormattedMessage id="InputTranscodingEmergency.switchingInputs" defaultMessage="Switching inputs" />}
        open={showEmergencyForm}
        onCancel={handleCancelEmergency}
        footer={
          detailView ? null : (
            <>
              <Button key="back" onClick={handleCancelEmergency}>
                <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
              </Button>
              <Button key="ok" type="primary" onClick={handleFinish} icon={<SaveOutlined />}>
                <span>
                  <FormattedMessage id="general.save" defaultMessage="Save" />
                </span>
              </Button>
            </>
          )
        }
      >
        <Row gutter={24}>
          <Col span={6}>
            <InputNumberField
              label={<BackupSwitchMsecsLabel />}
              name={["backupSwitchMsecs"]}
              disabled={detailView}
              rules={[required]}
            />
          </Col>
          <Col span={6}>
            <InputNumberField
              label={<BackupReturnMsecsLabel />}
              name={["backupReturnMsecs"]}
              disabled={detailView}
              rules={[required]}
            />
          </Col>
        </Row>
        <Card
          title={
            <FormattedMessage
              id="InputTranscodingEmergency.emergencyInputStreamFrom"
              defaultMessage="Emergency Input"
            />
          }
        >
          <Row gutter={24}>
            <Col span={6}>
              <Item
                name="emergencyMode"
                label={<FormattedMessage id="InputTranscodingEmergency.mode" defaultMessage="Mode" />}
                rules={[required]}
              >
                <Select onChange={handleChangeEmergencyType} disabled={detailView}>
                  {SELECTOR_EMERGENCY_MODE.map((option) => (
                    <Option key={option.value} value={option.value} disabled={detailView}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            {isEmergencyFileNeeded && (
              <Col span={6}>
                <Item
                  name="emergencyFile"
                  label={<FormattedMessage id="InputTranscodingEmergency.file" defaultMessage="File" />}
                  rules={[required]}
                >
                  <Select onChange={handleChangeEmergencyType} disabled={detailView}>
                    {EMERGENCY_FILES_LIST.map((option) => (
                      <Option key={option} value={option} disabled={detailView}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            )}
            <Col span={6}>
              <InputNumberField
                label={<EmergencySwitchMsecsLabel />}
                name={["emergencySwitchMsecs"]}
                disabled={detailView}
                rules={[required]}
              />
            </Col>
            <Col span={6}>
              <InputNumberField
                label={<EmergencyReturnMsecsLabel />}
                name={["emergencyReturnMsecs"]}
                disabled={detailView}
                rules={[required]}
              />
            </Col>
          </Row>
        </Card>
      </StyledModal>
    </Col>
  );
}

const StyledSpin = styled(Spin)`
  cursor: pointer;
  .ant-spin-text {
    top: 30% !important;
    font-weight: bold;
  }
`;
const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 105px;
  margin: 0 0 15px 30px;
  padding: 10px 10px 10px 35px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.maritime};
  color: #fff;
  padding: 5px;
  line-height: 24px;
  position: absolute;
  top: 104px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 105px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;

InputTranscodingEmergency.propTypes = {
  emergencyFile: PropTypes.string,
  emergencySwitchMsecs: PropTypes.number,
  emergencyReturnMsecs: PropTypes.number,
  backupReturnMsecs: PropTypes.number,
  backupSwitchMsecs: PropTypes.number,
  label: PropTypes.string.isRequired,
  title: PropTypes.element.isRequired,
  playingSource: PropTypes.string,
  detailView: PropTypes.bool.isRequired,
  handleOKInput: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

InputTranscodingEmergency.defaultProps = {
  backupSwitchMsecs: null,
  backupReturnMsecs: null,
  emergencyFile: null,
  emergencySwitchMsecs: null,
  emergencyReturnMsecs: null,
  playingSource: null,
};

export default InputTranscodingEmergency;
