import React, { useEffect } from "react";
import { Card, Row, Tabs, Col, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { WarningOutlined } from "@ant-design/icons";

import store from "../../../store";

import {
  actions as NODE_STATS_V5_ACTIONS,
  selectors as NODE_STATS_V5_SELECTOR,
  getMainInputStatsSelector,
  getBackupInputStatsSelector,
  getProcessingStatsSelector,
  getCodecsStatsSelector,
  getChannelStatsSelector,
} from "../../../ducks/nodeStatsV5";
import { selectors as NODE_LIVE_STATS_SELECTORS } from "../../../ducks/nodeLiveStats";
import { selectors as NODE_CHANNELS_SELECTOR } from "../../../ducks/nodeChannels";

import SelectEngine from "../../../components/SelectEngine";
import StatisticHistoricalV5Graph from "./StatisticHistoricalV5Graph";
import StatisticHistoricalV5Outputs from "./StatisticHistoricalV5Outputs";
import StatisticsView from "./StatisticsView";

import convertNodeStatsFormat from "../../../lib/utils/convertNodeStatsFormat";
import queryURL from "../../../lib/utils/queryURL";

export default function StatisticHistoricalV5({ channelDetailView }) {
  useEffect(() => {
    return () => {
      queryURL({ removeKey: "subTab" });
    };
  }, []);

  const { cwid, id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeSubTab = searchParams.get("subTab") || "channel";
  const activeSourceTab = searchParams.get("sourceTab");
  const channelId = channelDetailView ? id : searchParams.get("c");

  const channelStatsSelector = useSelector(getChannelStatsSelector) || [];
  const channelStatsParams = useSelector(NODE_STATS_V5_SELECTOR.getChannelStatsParams) || {};
  const isChannelStarted = useSelector((state) => NODE_CHANNELS_SELECTOR.getIsChannelStarted({ state, channelId }));

  const { selectorData: mainInputStatsSelector } = useSelector(getMainInputStatsSelector);

  const { selectorData: backupInputStatsSelector } = useSelector(getBackupInputStatsSelector);
  const { selectorData: processingStatsSelector, params: processingParams } = useSelector(getProcessingStatsSelector);
  const { videoSelectorData, audioSelectorData, audioCodecParams, videoCodecParams } =
    useSelector(getCodecsStatsSelector);

  const statisticsData = useSelector((state) => NODE_LIVE_STATS_SELECTORS.getLiveStatsV5({ state, cwid, channelId }));
  const newestChannelInputStats = statisticsData && statisticsData[statisticsData.length - 1].mainInput;
  const newestChannelBackupStats = statisticsData && statisticsData[statisticsData.length - 1].backupInput;
  const newestChannelStats = statisticsData && statisticsData[statisticsData.length - 1].channel;
  const newestChannelCodecsStats = statisticsData && statisticsData[statisticsData.length - 1].codecs;
  const newestSwitcherStats = statisticsData && statisticsData[statisticsData.length - 1].switcher;

  const handleChangeChannel = (selectedEngineId) => {
    searchParams.set("c", selectedEngineId);
    setSearchParams(searchParams);
  };

  const clearStatsV5Data = () => {
    store.dispatch(NODE_STATS_V5_ACTIONS.CLEAR_STATS_V5_DATA());
  };

  const handleClickTab = (selectedTab) => {
    searchParams.set("subTab", selectedTab);
    setSearchParams(searchParams);
  };

  const tabItems = [
    {
      label: <FormattedMessage id="StatisticsLiveNodeV5.channel" defaultMessage="Channel" />,
      key: "channel",
      children: (
        <Row justify="center" gutter={[24, 24]}>
          <Col span={24}>
            <StatisticHistoricalV5Graph
              channelId={channelId}
              selectorOptions={channelStatsSelector}
              channelParams={channelStatsParams}
            />
          </Col>
          {isChannelStarted && newestChannelStats && (
            <StatisticsView data={convertNodeStatsFormat(newestChannelStats)} />
          )}
        </Row>
      ),
    },
    {
      label: <FormattedMessage id="StatisticsLiveNodeV5.inputs" defaultMessage="Inputs" />,
      key: "inputs",
      children: (
        <Card>
          <Tabs
            onChange={clearStatsV5Data}
            defaultActiveKey={activeSourceTab}
            destroyInactiveTabPane
            items={[
              {
                label: <FormattedMessage id="StatisticsLiveNodeV5.mainInput" defaultMessage="Main input" />,
                key: "mainInput",
                children: (
                  <Row justify="center" gutter={[24, 24]}>
                    <Col span={24}>
                      <StatisticHistoricalV5Graph channelId={channelId} selectorOptions={mainInputStatsSelector} />
                    </Col>
                    {isChannelStarted && newestChannelInputStats && (
                      <StatisticsView data={convertNodeStatsFormat(newestChannelInputStats)} />
                    )}
                  </Row>
                ),
              },
              {
                label: <FormattedMessage id="StatisticsLiveNodeV5.backupInput" defaultMessage="Backup input" />,
                key: "backupInput",
                children: (
                  <Row justify="center" gutter={[24, 24]}>
                    <Col span={24}>
                      <StatisticHistoricalV5Graph channelId={channelId} selectorOptions={backupInputStatsSelector} />
                    </Col>
                    {isChannelStarted && newestChannelBackupStats && (
                      <StatisticsView data={convertNodeStatsFormat(newestChannelBackupStats)} />
                    )}
                  </Row>
                ),
              },
            ]}
          />
        </Card>
      ),
    },
    {
      label: <FormattedMessage id="StatisticsLiveNodeV5.processing" defaultMessage="Processing" />,
      key: "switcher",
      children: (
        <Row justify="center" gutter={[24, 24]}>
          <Col span={24}>
            <StatisticHistoricalV5Graph
              channelId={channelId}
              selectorOptions={processingStatsSelector}
              channelParams={processingParams}
            />
          </Col>
          {isChannelStarted && newestSwitcherStats && (
            <StatisticsView data={convertNodeStatsFormat(newestSwitcherStats)} />
          )}
        </Row>
      ),
    },
    {
      label: <FormattedMessage id="StatisticsLiveNodeV5.codecs" defaultMessage="Codecs" />,
      key: "codecs",
      children: (
        <Card>
          <Tabs
            onChange={clearStatsV5Data}
            defaultActiveKey="video"
            destroyInactiveTabPane
            items={[
              {
                label: <FormattedMessage id="StatisticsLiveNodeV5.video" defaultMessage="Video" />,
                key: "video",
                children: (
                  <Row justify="center" gutter={[24, 24]}>
                    <Col span={24}>
                      <StatisticHistoricalV5Graph
                        channelId={channelId}
                        selectorOptions={videoSelectorData}
                        channelParams={videoCodecParams}
                      />
                    </Col>
                    {isChannelStarted && newestChannelCodecsStats && (
                      <StatisticsView data={convertNodeStatsFormat(newestChannelCodecsStats)} />
                    )}
                  </Row>
                ),
              },
              {
                label: <FormattedMessage id="StatisticsLiveNodeV5.audio" defaultMessage="Audio" />,
                key: "audio",
                children: (
                  <Row justify="center" gutter={[24, 24]}>
                    <Col span={24}>
                      <StatisticHistoricalV5Graph
                        channelId={channelId}
                        selectorOptions={audioSelectorData}
                        channelParams={audioCodecParams}
                      />
                    </Col>
                    {isChannelStarted && newestChannelCodecsStats && (
                      <StatisticsView data={convertNodeStatsFormat(newestChannelCodecsStats)} />
                    )}
                  </Row>
                ),
              },
            ]}
          />
        </Card>
      ),
    },
    {
      label: <FormattedMessage id="StatisticsLiveNodeV5.outputs" defaultMessage="Outputs" />,
      key: "outputsV5",
      children: <StatisticHistoricalV5Outputs channelId={channelId} />,
    },
  ];

  return (
    <Card
      title={
        <>
          {!channelDetailView && (
            <>
              <FormattedMessage id="StatisticHistorical.channel" defaultMessage="Channel" />
              {": "}
              <SelectEngine channelId={channelId} handleSelectChange={handleChangeChannel} />
            </>
          )}
        </>
      }
    >
      <Spin
        spinning={!channelId}
        tip={<FormattedMessage id="StatisticsLiveNodeV5.pleaseSelectChannel" defaultMessage="Please select channel" />}
        indicator={<WarningOutlined />}
      >
        <Tabs
          activeKey={activeSubTab}
          items={tabItems}
          onChange={clearStatsV5Data}
          onTabClick={handleClickTab}
          destroyInactiveTabPane
        />
      </Spin>
    </Card>
  );
}

StatisticHistoricalV5.propTypes = {
  channelDetailView: PropTypes.bool,
};

StatisticHistoricalV5.defaultProps = {
  channelDetailView: null,
};
