import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Badge } from "antd";

import themeColor from "../../../lib/style/theme";
import { OUTPUTS_TYPES } from "../../../lib/utils/constants";

import IncompleteConfig from "../../IncompleteConfig";

const { Ribbon } = Badge;

const MuxedOutputElement = ({ handleClick, label, muxedOutput, incomplete, type }) => {
  const numberOfPrograms = muxedOutput?.muxer?.programs ? muxedOutput?.muxer?.programs.length : 0;
  const numberOfMuxedOutputs = muxedOutput && muxedOutput.outputs ? muxedOutput?.outputs.length : 0;
  const noIncomplete = !incomplete || (incomplete && incomplete.length === 0);
  const isOutputNdiType = type === OUTPUTS_TYPES.outputNdi.value;
  const isOutputUrlType = type === OUTPUTS_TYPES.outputUrl.value;
  const isOutputRtmpType = type === OUTPUTS_TYPES.outputRtmp.value;

  return (
    <Ribbon
      text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
      color={noIncomplete ? themeColor.green : themeColor.orange}
    >
      <StyledContainer onClick={handleClick}>
        <StyledAsideType>{label}</StyledAsideType>
        <StyledBoldText>{muxedOutput?.name}</StyledBoldText>
        {isOutputRtmpType && (
          <div>
            <StyledText>
              <FormattedMessage id="MuxedOutputElement.url" defaultMessage="Url" />:
            </StyledText>
            <StyledBoldText>{muxedOutput?.url}</StyledBoldText>
          </div>
        )}
        {isOutputNdiType && (
          <div>
            <StyledText>
              <FormattedMessage id="MuxedOutputElement.stream" defaultMessage="Stream" />:
            </StyledText>
            <StyledBoldText>{muxedOutput?.streamName}</StyledBoldText>
          </div>
        )}
        {isOutputUrlType && (
          <>
            <div>
              <StyledText>
                <FormattedMessage id="MuxedOutputElement.outputs" defaultMessage="Outputs" />:
              </StyledText>
              <StyledBoldText>{numberOfMuxedOutputs}</StyledBoldText>
            </div>
            <div>
              <StyledText>
                <FormattedMessage id="MuxedOutputElement.services" defaultMessage="Services" />:
              </StyledText>
              <StyledBoldText>{numberOfPrograms}</StyledBoldText>
            </div>
          </>
        )}
      </StyledContainer>
    </Ribbon>
  );
};

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 105px;
  margin: 0 0 15px 30px;
  padding: 10px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  color: #fff;
  padding: 5px;
  line-height: 24px;
  position: absolute;
  top: 104px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 105px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;

MuxedOutputElement.propTypes = {
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  muxedOutput: PropTypes.object,
  type: PropTypes.string,
  incomplete: PropTypes.arrayOf(PropTypes.number),
};

MuxedOutputElement.defaultProps = {
  muxedOutput: null,
  incomplete: null,
  type: null,
};

export default MuxedOutputElement;
