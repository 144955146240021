import React, { useCallback } from "react";
import styled from "styled-components";
import { Row, Badge, Button, Layout, Switch, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { BellOutlined, MoonOutlined, SunOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import store from "../../store";

import { selectors as NOTIFICATION_SELECTORS } from "../../ducks/nodeNotifications";
import { actions as ACCOUNT_ACTIONS } from "../../ducks/account";

import useMainTheme from "../../hooks/useMainTheme";

import { getBuildDate } from "../../lib/utils/getBuildTime";
import logoUrl from "../../lib/images/logo.png";
import packageJson from "../../../package.json";
import themeColor from "../../lib/style/theme";
import { MAIN_THEME } from "../../lib/utils/constants";

import SelectLang from "../SelectLang";
import UserMenu from "../UserMenu";

const { Header: HeaderAnt } = Layout;

const Header = () => {
  const notificationCounter = useSelector(NOTIFICATION_SELECTORS.getCounter);
  const { isLightTheme } = useMainTheme();
  const navigate = useNavigate();

  const handleNotification = useCallback(() => {
    navigate("/notifications");
  }, []);

  const handleChangeTheme = (selectedLightTheme) => {
    if (selectedLightTheme) {
      store.dispatch(ACCOUNT_ACTIONS.CHANGE_MAIN_THEME(MAIN_THEME.LIGHT));
      window.localStorage.setItem("mainTheme", MAIN_THEME.LIGHT);
    } else {
      store.dispatch(ACCOUNT_ACTIONS.CHANGE_MAIN_THEME(MAIN_THEME.DARK));
      window.localStorage.setItem("mainTheme", MAIN_THEME.DARK);
    }
  };

  return (
    <StyledHeader className="header">
      <Row>
        <Logo />
        <StyledBuildDate>
          <FormattedMessage id="general.build" defaultMessage="build" />: {getBuildDate(packageJson.buildDate)}
        </StyledBuildDate>
      </Row>
      <StyledOverlay>
        <Tooltip
          placement="bottom"
          title={
            isLightTheme ? (
              <FormattedMessage id="Header.darkTheme" defaultMessage="Dark Theme" />
            ) : (
              <FormattedMessage id="Header.LightTheme" defaultMessage="Light Theme" />
            )
          }
        >
          <Switch
            defaultChecked={isLightTheme}
            checkedChildren={<MoonOutlined />}
            unCheckedChildren={<SunOutlined />}
            onChange={handleChangeTheme}
          />
        </Tooltip>
        <StyledNotifications>
          <Badge count={notificationCounter}>
            <Button type="primary" shape="circle" icon={<BellOutlined />} onClick={handleNotification} />
          </Badge>
        </StyledNotifications>
        <SelectLang />
        <UserMenu />
      </StyledOverlay>
    </StyledHeader>
  );
};

const StyledBuildDate = styled.span`
  color: ${themeColor.defaultBackground};
`;

const StyledNotifications = styled.span`
  margin: 0 10px;

  .ant-scroll-number-only-unit {
    color: #fff;
  }
`;

const StyledHeader = styled(HeaderAnt)`
  position: fixed;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: space-between;

  &.ant-layout-header {
    padding-right: 0;
    padding-left: 20px;
  }
`;

const StyledOverlay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
`;

const Logo = styled.div`
  width: 300px;
  height: 64px;
  background: url(${logoUrl});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

export default Header;
