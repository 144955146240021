import notificationHandler from "./notificationHandler";

import CloudTranslations from "../translations/cloudTranslations";

const cloudNotificationHandler = ({ msgType, errorCode, extraMsg }) => {
  const {
    1: { defaultMessage },
  } = CloudTranslations;
  const translation = CloudTranslations[errorCode];

  if (extraMsg) {
    notificationHandler[msgType]({
      message: `${translation?.defaultMessage} ${extraMsg} ` || defaultMessage,
    });
  } else {
    notificationHandler[msgType]({
      message: translation?.defaultMessage || defaultMessage,
    });
  }
};

export default cloudNotificationHandler;
