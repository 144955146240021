import React from "react";
import styled from "styled-components";
import { Form, Card, Input, Button, Divider, Row, Alert } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { MailOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";

import useMainTheme from "../../hooks/useMainTheme";

import logoUrl from "../../lib/images/logo.png";
import logoLightUrl from "../../lib/images/logo-light.png";
import themeColor from "../../lib/style/theme";
import { required } from "../../lib/utils/formValidation";

import AccountService from "../../services/account";
import { errorNotification, successNotification } from "../../lib/utils/notification";

const { Item } = Form;

const translations = defineMessages({
  notValidEmail: {
    id: "general.notValidEmail",
    defaultMessage: "Invalid email format",
  },
  loginOrEmail: {
    id: "Login2FA.loginOrEmail",
    defaultMessage: "Please input your login or email",
  },
});

const Login2FA = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { login } = useParams();
  const { isDarkTheme } = useMainTheme();

  const handleFinish = (formData) => {
    AccountService.auth2fa(formData, {
      errorNotification: errorNotification(formatMessage),
      successNotification: successNotification(formatMessage),
    });
  };

  return (
    <StyledFormContainer>
      <Card
        title={<FormattedMessage id="Login2FA.twoFactorAuth" defaultMessage="Two factor authentication" />}
        extra={<img src={isDarkTheme ? logoUrl : logoLightUrl} alt="quickstream logo" width="200" />}
      >
        <StyledForm onFinish={handleFinish} form={form} initialValues={{ login }}>
          <Alert
            showIcon
            message={
              <FormattedMessage
                id="Login2FA.recaptchaErrorInfo"
                // eslint-disable-next-line max-len
                defaultMessage="For additional security, we sometimes ask users to click on the login link provided in the email."
              />
            }
            type="warning"
          />
          <StyledInput name="login" rules={[required]}>
            <Input prefix={<MailOutlined />} placeholder={formatMessage(translations.loginOrEmail)} />
          </StyledInput>
          <StyledButton type="primary" htmlType="submit">
            <FormattedMessage
              id="Login2FA.loginButton"
              defaultMessage="Send the login link to the account email address"
            />
          </StyledButton>
        </StyledForm>
        <Divider />
        <Row type="flex" justify="center" align="middle">
          <Link to="/login">
            <StyledOrangeURL>
              <FormattedMessage id="Login2FA.backToLogin" defaultMessage="Back to Login" />
            </StyledOrangeURL>
          </Link>
        </Row>
      </Card>
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledOrangeURL = styled.span`
  color: ${themeColor.orange};
  transition: color 0.2s ease;

  &:hover {
    color: ${themeColor.orangeHover};
  }
`;

const StyledInput = styled(Item)`
  display: none;
  visibility: hidden;
  margin: 15px;
`;

const StyledForm = styled(Form)`
  min-width: 400px;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
  margin: 20px 0 0 0;
`;

export default Login2FA;
