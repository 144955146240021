import React, { useCallback, useState } from "react";
import { Form, Card, Col, Button, Tooltip } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";

import CloudChannelsServices from "../../services/cloudChannels";
import AccountServices from "../../services/account";

import { errorNotification, successNotification } from "../../lib/utils/notification";

import PermissionInvitationDynamicForm from "./PermissionInvitationDynamicForm";

export default function HubChannelInvites({ channelData, fetchChannels }) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { resetFields, setFieldsValue, getFieldValue } = form;

  const [loadingInvitations, setLoadingInvitations] = useState(false);
  const invitationsForm = Form.useWatch("invitations", form);

  const handleFinishInvitation = useCallback(
    async ({ invitations }) => {
      if (invitations) {
        setLoadingInvitations(true);
        await CloudChannelsServices.invitationsPermission(
          { invitations, cloudId: channelData.id },
          {
            errorNotification: errorNotification(formatMessage),
            setLoadingInvitations,
          }
        );
        await AccountServices.invitationsAccount(
          { invitations, cloudId: channelData.id },
          {
            errorNotification: errorNotification(formatMessage),
            successNotification: successNotification(formatMessage),
            setLoadingInvitations,
          }
        );
        resetFields();
        fetchChannels();
      }
    },
    [channelData, fetchChannels, formatMessage, resetFields]
  );

  return (
    <Col xs={24} sm={24} md={24} lg={12}>
      <Form onFinish={handleFinishInvitation} form={form}>
        <Card
          title={
            <Tooltip
              title={
                <FormattedMessage
                  id="handleFinishInvitation.senInvitationTooltip"
                  defaultMessage="Send an email with invitation to Quickstream Cloud.
                            Your channel will be shared after user registers."
                />
              }
            >
              <StyledMarginRight>
                <FormattedMessage id="handleFinishInvitation.inviteNewUser" defaultMessage="Invite new user" />
              </StyledMarginRight>
              <QuestionCircleFilled />
            </Tooltip>
          }
        >
          <PermissionInvitationDynamicForm
            channelName={channelData?.channelName}
            setFieldsValue={setFieldsValue}
            getFieldValue={getFieldValue}
          />
          <Button type="primary" htmlType="submit" disabled={loadingInvitations || !invitationsForm} block>
            <span>
              <FormattedMessage id="handleFinishInvitation.invite" defaultMessage="Invite" />
            </span>
          </Button>
        </Card>
      </Form>
    </Col>
  );
}

const StyledMarginRight = styled.span`
  margin-right: 5px;
`;
