import React, { useEffect, useState } from "react";
import { Card, Table, DatePicker, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { MinusSquareOutlined, ReloadOutlined } from "@ant-design/icons";

import CloudServices from "../../services/cloud";
import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";

import { errorNotification } from "../../lib/utils/notification";
import { SELECT_AWS_REGIONS, MQTT_STATUS } from "../../lib/utils/constants";
import NodeNetworkOutput from "../../components/NodeNetworkOutput";

const { RangePicker } = DatePicker;

const CloudNodes = () => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [cloudNodes, setCloudNodes] = useState([]);
  const [userCloudNodesData, setUserCloudNodesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const nodesList = useSelector(MQTT_SELECTORS.getNodes);

  const [dates, setDates] = useState([dayjs().startOf("month"), dayjs().endOf("day")]);
  const [selectedDates, setSelectedDates] = useState([dayjs().startOf("month"), dayjs()]);

  useEffect(async () => {
    setLoading(true);
    const responseData = await CloudServices.getUsersCloudNodes({ errorNotification });
    setCloudNodes(responseData);
    setLoading(false);
  }, []);

  useEffect(async () => {
    setLoading(true);
    const responseData = await CloudServices.getUsersCloudNodes({ errorNotification });
    setCloudNodes(responseData);
    setLoading(false);

    setExpandedRowKeys([]);
  }, [selectedDates]);

  const userNodesColumns = [
    {
      title: <FormattedMessage id="CloudNodes.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
      render: (username) => <Link to={`/users/${username}`}>{username}</Link>,
    },
    {
      title: <FormattedMessage id="CloudNodes.cloudNodes" defaultMessage="Cloud nodes" />,
      dataIndex: "count",
      key: "count",
    },
    Table.EXPAND_COLUMN,
    {
      title: (
        <FormattedMessage id="CloudNodes.totalNetworkOutputTraffic" defaultMessage="Total network output traffic" />
      ),
      dataIndex: "totalNetworkOutput",
      key: "totalNetworkOutput",
      render: (totalNetworkOutput) => {
        return <NodeNetworkOutput totalNodesNetworkOutput={totalNetworkOutput} isCloudNode onDemand />;
      },
    },
  ];

  const expandedRowRender = () => {
    const columns = [
      {
        title: <FormattedMessage id="CloudNodes.node" defaultMessage="Node" />,
        dataIndex: "cnn",
        key: "cnn",
        render: (cnn, record) => {
          const { online } = nodesList.find((node) => node.cwid === record?.cwid) ?? {};
          const isOnline = online === MQTT_STATUS.CONNECTED;

          if (isOnline) {
            return <Link to={`/node/${record.cwid}/${cnn}/channels`}>{cnn}</Link>;
          }

          return <span>{cnn}</span>;
        },
      },
      {
        title: <FormattedMessage id="CloudNodes.cwid" defaultMessage="Cwid" />,
        dataIndex: "cwid",
        key: "cwid",
      },
      {
        title: <FormattedMessage id="CloudNodes.instanceType" defaultMessage="Instance type" />,
        dataIndex: "instanceType",
        key: "instanceType",
      },
      {
        title: <FormattedMessage id="CloudNodes.instanceRegion" defaultMessage="Instance Region" />,
        dataIndex: "instanceRegion",
        key: "instanceRegion",
        render: (region) => {
          const regionData = SELECT_AWS_REGIONS.find((selectorRegion) => selectorRegion.value === region);

          return regionData?.label;
        },
      },
      {
        title: <FormattedMessage id="CloudNodes.instanceId" defaultMessage="Instance ID" />,
        dataIndex: "instanceId",
        key: "instanceId",
      },
      {
        title: <FormattedMessage id="CloudNodes.networkOutputTraffic" defaultMessage="Network output traffic" />,
        dataIndex: "networkOutput",
        key: "networkOutput",
        filters: [
          {
            text: "Has traffic",
            value: "0",
          },
        ],
        defaultFilteredValue: ["0"],
        onFilter: (value, record) => {
          return record.networkOutput !== +value;
        },
        render: (networkOutput) => {
          return <NodeNetworkOutput totalNodesNetworkOutput={networkOutput} isCloudNode />;
        },
      },
    ];

    return (
      <Card>
        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={userCloudNodesData}
          rowKey={(record) => record.cnn}
        />
      </Card>
    );
  };

  const handleExpandUser = async (isExpand, record) => {
    if (isExpand) {
      setLoading(true);
      setUserCloudNodesData([]);
      setExpandedRowKeys([record.username]);
      const { nodes, totalNetworkOutput } = await CloudServices.getUserCloudNodes(
        {
          username: record.username,
          startDate: selectedDates[0].format("YYYY-MM-DD"),
          endDate: selectedDates[1].format("YYYY-MM-DD"),
        },
        {
          errorNotification,
        }
      );

      const parsedCloudNodes = cloudNodes.map((nodeData) => {
        if (nodeData.username === record.username) {
          return { ...nodeData, totalNetworkOutput };
        }

        return nodeData;
      });

      setCloudNodes(parsedCloudNodes);

      setUserCloudNodesData(nodes);
      setLoading(false);
    } else {
      setExpandedRowKeys([]);
      setUserCloudNodesData([]);
    }
  };

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = (dates[0] && current.diff(dates[0], "days") > 31) || (current && current > dayjs().endOf("day"));
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 31;

    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
    },
    {
      label: "Last Week",
      value: [dayjs().startOf("week"), dayjs().endOf("day")],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("day")],
    },
    {
      label: "Previous Month",
      value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")],
    },
  ];

  return (
    <Card>
      <FormattedMessage id="CloudNodes.selectedDate" defaultMessage="Selected date" />
      {": "}

      <RangePicker
        value={dates || selectedDates}
        disabledDate={disabledDate}
        onCalendarChange={(val) => setDates(val)}
        onChange={(val) => {
          setSelectedDates(val);
        }}
        onOpenChange={onOpenChange}
        allowClear={false}
        presets={rangePresets}
      />
      <StyledTable
        onExpand={handleExpandUser}
        rowKey={(record) => record.username}
        columns={userNodesColumns}
        expandable={{
          expandedRowRender,
          expandedRowKeys,
          expandIcon: ({ expanded, onExpand, record }) => {
            return expanded ? (
              <Button type="dashed" onClick={(e) => onExpand(record, e)} icon={<MinusSquareOutlined />}>
                <span>
                  <FormattedMessage id="CloudNodes.close" defaultMessage="Close row" />
                </span>
              </Button>
            ) : (
              <Button type="dashed" onClick={(e) => onExpand(record, e)} icon={<ReloadOutlined />}>
                <span>
                  <FormattedMessage id="CloudNodes.loadDataOnDemand" defaultMessage="Load data on demand" />
                </span>
              </Button>
            );
          },
        }}
        dataSource={cloudNodes}
        pagination={false}
      />
    </Card>
  );
};

const StyledTable = styled(Table)`
  margin-top: 15px;
`;

export default CloudNodes;
