import React, { useState } from "react";
import { Form, Select, Space, Divider, Input, Button } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";
import { required, trim } from "../../lib/utils/formValidation";

const { Item } = Form;
const { Option } = Select;

const translations = defineMessages({
  codecName: {
    id: "TechnicalRole.role",
    defaultMessage: "Enter custom role",
  },
});

const TechnicalRole = ({ name, selector }) => {
  const { formatMessage } = useIntl();
  const [roles, setRoles] = useState(selector);
  const [newRole, setNewRole] = useState("");

  const onChangeNewRole = (event) => {
    setNewRole(event.target.value);
  };

  const addCustomRole = (event) => {
    event.preventDefault();
    setRoles([...roles, newRole]);
    setNewRole("");
  };

  return (
    <Item
      label={<FormattedMessage id="general.yourRole" defaultMessage="Your role" />}
      name={name}
      rules={[required, trim]}
    >
      <Select
        allowClear
        placeholder={<FormattedMessage id="general.pleaseSelect" defaultMessage="Please select" />}
        optionFilterProp="children"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Space
              align="center"
              style={{
                padding: "0 8px 4px",
              }}
            >
              <Input placeholder={formatMessage(translations.codecName)} value={newRole} onChange={onChangeNewRole} />
              <Button
                disabled={newRole.length === 0}
                type="primary"
                shape="round"
                onClick={addCustomRole}
                icon={<PlusOutlined />}
              >
                <span>
                  <FormattedMessage id="TechnicalRole.addRole" defaultMessage="Add role" />
                </span>
              </Button>
            </Space>
          </>
        )}
      >
        {roles.map((role) => (
          <Option value={role} key={role}>
            {role}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default TechnicalRole;
