import React from "react";
import { Row, Button, Popconfirm, Divider } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { QuestionCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import MQTTService from "../../../services/mqtt";
import { selectors as NODE_SELECTORS } from "../../../ducks/node";

import { successNotification } from "../../../lib/utils/notification";
import { MESSAGE_TYPE } from "../../../lib/utils/constants";

import NodeV5ChannelListActionButtons from "../../../pages/Node/NodeV5ChannelsList/NodeV5ChannelListActionButtons";

const translations = defineMessages({
  sendRestartMessageSuccess: {
    id: "DetailSectionTitle.sendRestartMessageSuccess",
    defaultMessage: "Channel restart message has been sent",
  },
});

function DetailSectionTitle({ channelId, channelConfig, channelStatus }) {
  const { formatMessage } = useIntl();
  const cwid = useSelector(NODE_SELECTORS.getNodeCwid);

  const handleRestart = () => {
    MQTTService.sendMessageV5({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.KILL_CHANNEL,
        channelId,
      },
    });
    successNotification(formatMessage)(translations.sendRestartMessageSuccess);
  };

  return (
    <Row align="middle" justify="end">
      <NodeV5ChannelListActionButtons
        channelConfig={channelConfig}
        channelId={channelId}
        status={channelStatus}
        detailView
      />
      <Divider type="vertical" />
      <Popconfirm
        title={
          <FormattedMessage
            id="DetailSectionTitle.confirmRestart"
            defaultMessage="Are you sure you want to restart this channel?"
          />
        }
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        onConfirm={handleRestart}
        okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
        cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
      >
        <Button type="primary" danger>
          <FormattedMessage id="DetailSectionTitle.resetProcess" defaultMessage="Restart process" />
        </Button>
      </Popconfirm>
    </Row>
  );
}

DetailSectionTitle.propTypes = {
  channelId: PropTypes.string.isRequired,
  channelStatus: PropTypes.shape({
    channelStatusText: PropTypes.string.isRequired,
    requestedStatusText: PropTypes.string.isRequired,
  }).isRequired,
};

export default DetailSectionTitle;
