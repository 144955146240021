import React from "react";
import { Col, Row } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { IPFieldV5 } from "../../../Fields/Fields";
import InputNumberField from "../../../Fields/InputNumberField";

import { required } from "../../../../lib/utils/formValidation";

const translations = defineMessages({
  ip: {
    id: "TimeCodeSectionFormItem.ip",
    defaultMessage: "IP",
  },
});

export default function TimeCodeSectionFormItem({ prefix, disabled }) {
  const { formatMessage } = useIntl();

  return (
    <Row gutter={24}>
      <Col span={6}>
        <IPFieldV5 name={[...prefix, "ip"]} label={formatMessage(translations.ip)} disabled={disabled} />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "port"]}
          label={<FormattedMessage id="TimeCodeSectionFormItem.port" defaultMessage="Port" />}
          rules={[required]}
          disabled={disabled}
          min={1}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "interval"]}
          label={<FormattedMessage id="TimeCodeSectionFormItem.interval" defaultMessage="Interval [ms]" />}
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "instanceNumber"]}
          label={<FormattedMessage id="TimeCodeSectionFormItem.instanceNumber" defaultMessage="Instance number" />}
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "fpsNum"]}
          label={<FormattedMessage id="TimeCodeSectionFormItem.fpsNum" defaultMessage="FPS Num" />}
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
      <Col span={6}>
        <InputNumberField
          name={[...prefix, "fpsDen"]}
          label={<FormattedMessage id="TimeCodeSectionFormItem.fpsDen" defaultMessage="FPS Den" />}
          rules={[required]}
          disabled={disabled}
          min={0}
        />
      </Col>
    </Row>
  );
}

TimeCodeSectionFormItem.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
};
