import React from "react";
import { Modal, List, Empty, Popconfirm, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { SoundOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import MQTTService from "../../services/mqtt";
import { getNodeMutedSelector } from "../../ducks/nodeAlerts";

import { MESSAGE_TYPE } from "../../lib/utils/constants";

export default function NodeCardMutedModal({ isModalOpen, setIsModalOpen, cwid, cnn }) {
  const mutatedChannels = useSelector((state) => getNodeMutedSelector(state, { cwid }));

  const handleUnmute = ({ channelId }) => {
    MQTTService.sendMessageV5({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.NODE_MUTE_ALERTS,
        channelId,
        mute: false,
      },
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={<FormattedMessage id="NodeCard.mutedChannels" defaultMessage="Muted channels" />}
      open={isModalOpen}
      footer={null}
      onCancel={handleCloseModal}
    >
      <List
        dataSource={mutatedChannels}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<FormattedMessage id="NodeAlerts.noMutedChannels" defaultMessage="No muted channels" />}
            />
          ),
        }}
        renderItem={(item) => (
          <List.Item>
            <Link key={`/node/${cwid}/${cnn}/channel/${item?.id}`} to={`/node/${cwid}/${cnn}/channel/${item?.id}`}>
              {item?.name}
            </Link>{" "}
            <Popconfirm
              placement="top"
              title={
                <FormattedMessage id="NodeCard.unmuteChannelAlerts" defaultMessage="Unmute this channel alerts?" />
              }
              okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
              cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
              onConfirm={() => handleUnmute({ channelId: item?.id, cwid })}
            >
              <Button size="small" shape="circle" icon={<SoundOutlined style={{ fontSize: "11px" }} />} />
            </Popconfirm>
          </List.Item>
        )}
      />
    </Modal>
  );
}

NodeCardMutedModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  cwid: PropTypes.string.isRequired,
  cnn: PropTypes.string.isRequired,
};
