import React from "react";
import { FormattedMessage } from "react-intl";
import { Divider, Card } from "antd";
import PropTypes from "prop-types";

import ProbeDrawerElementText from "./ProbeDrawerElementText";
import ProbeDrawerElementStream from "./ProbeDrawerElementStream";

const ProbeDrawerElement = ({ data }) => {
  const { programs, streams } = data || {};

  const isPrograms = programs && programs.length > 0;

  return (
    <>
      {isPrograms &&
        programs.map((program) => (
          <Card
            title={
              <>
                <FormattedMessage id="ProbeDrawerElement.Program" defaultMessage="Program" /> #{program?.id}
              </>
            }
            key={program.id}
          >
            <ProbeDrawerElementText
              data={program.id}
              text={<FormattedMessage id="ProbeDrawerElement.serviceID" defaultMessage="Service ID" />}
              divided
            />
            <ProbeDrawerElementText
              data={program.pcrPid}
              text={<FormattedMessage id="ProbeDrawerElement.pcrPid" defaultMessage="PCR PID" />}
              divided
            />
            <ProbeDrawerElementText
              data={program.serviceName}
              text={<FormattedMessage id="ProbeDrawerElement.serviceName" defaultMessage="Service Name" />}
              divided
            />
            <ProbeDrawerElementText
              data={program.serviceProvider}
              text={<FormattedMessage id="ProbeDrawerElement.serviceProvider" defaultMessage="Service Provider" />}
            />
            <Divider />
            <ProbeDrawerElementStream programStreams={program?.streams || []} streams={streams || []} />
          </Card>
        ))}
      {!isPrograms && <ProbeDrawerElementStream streams={streams || []} />}
    </>
  );
};

ProbeDrawerElement.propTypes = {
  data: PropTypes.shape({
    errorCode: PropTypes.number.isRequired,
    inputDevice: PropTypes.string.isRequired,
    inputType: PropTypes.string.isRequired,
    msgType: PropTypes.string.isRequired,
    programs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        num: PropTypes.number.isRequired,
        pcrPid: PropTypes.number.isRequired,
        pmtPid: PropTypes.number.isRequired,
        serviceName: PropTypes.string.isRequired,
        serviceProvider: PropTypes.string.isRequired,
        streams: PropTypes.arrayOf(PropTypes.number),
      })
    ),
    streams: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProbeDrawerElement;
