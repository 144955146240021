import React from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col, Button, Popconfirm } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";

export default function InputTranscodingSectionTitle({
  setInputBackupStreamModalVisible,
  handleFinish,
  channelConfig,
  disabled,
}) {
  const handleRemove = () => {
    const { backupInput, backupSwitchMsecs, backupReturnMsecs, ...updatedChannelConfig } = channelConfig;

    handleFinish(updatedChannelConfig);

    setInputBackupStreamModalVisible(false);
  };

  return (
    <Row gutter={24} justify="space-between">
      <Col>
        <FormattedMessage id="InputTranscodingSectionTitle.title" defaultMessage="Backup Input" />
      </Col>
      <StyledCol>
        <Popconfirm
          placement="bottomLeft"
          title={
            <FormattedMessage
              id="InputTranscodingSectionTitle.confirmRemove"
              defaultMessage="Are you sure, you want to remove backup input?"
            />
          }
          okText="Yes"
          onConfirm={handleRemove}
        >
          <Button type="primary" danger disabled={disabled}>
            <FormattedMessage id="InputTranscodingSectionTitle.removeBackup" defaultMessage="Remove backup" />
          </Button>
        </Popconfirm>
      </StyledCol>
    </Row>
  );
}

const StyledCol = styled(Col)`
  margin-right: 30px;
`;

InputTranscodingSectionTitle.propTypes = {
  setInputBackupStreamModalVisible: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
  channelConfig: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};
