import React from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

const translations = defineMessages({
  label: {
    id: "UDPTTLLabel.label",
    defaultMessage: "TTL",
  },
  labelOutputTooltip: {
    id: "UDPTTLLabel.labelOutputTooltip",
    // eslint-disable-next-line max-len
    defaultMessage: "Select 0 for Automatic (set by operating system)",
  },
});

const UDPTTLLabel = () => {
  const { formatMessage } = useIntl();

  return (
    <StyledLabel>
      <span>{formatMessage(translations.label)}</span>
      <Tooltip placement="top" title={formatMessage(translations.labelOutputTooltip)}>
        <QuestionCircleFilled />
      </Tooltip>
    </StyledLabel>
  );
};

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

export default UDPTTLLabel;
