import React from "react";
import { Tabs, Card } from "antd";
import { useSelector } from "react-redux";

import StatisticsView from "./StatisticsView";
import StatisticsGraph from "./StatisticsGraph";

import convertNodeStatsFormat from "../../../lib/utils/convertNodeStatsFormat";
import parseCodecName from "../../../lib/utils/parseCodecName";
import { selectors as CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";

const StatisticsCodecs = ({ newestChannelCodecsStats, data, channelId, type, isChannelStarted }) => {
  const codecs = useSelector((state) => CHANNEL_SELECTORS.getChannelCodecs({ state, channelId }));

  if (!codecs) {
    return <span>Missing data...</span>;
  }

  const parsedCodecs = Object.entries(codecs);

  const tabItems = parsedCodecs.map((codec) => {
    const codecId = codec[0];
    const codecValue = codec[1];

    return codecValue.programs.map((program) => {
      const codecTabTile = `${codecValue?.name} (${program?.serviceName})`;

      return {
        label: codecTabTile,
        key: codecTabTile,
        children: (
          <Card>
            <Tabs
              items={program.streams.map((streamConfig) => {
                const streamTabTitle = `${parseCodecName(streamConfig?.stream?.codec?.type)} (${
                  streamConfig?.stream?.type
                })`;

                return {
                  label: streamTabTitle,
                  key: streamConfig?.stream?.outputId,
                  children: (
                    <>
                      {newestChannelCodecsStats && (
                        <StatisticsGraph
                          data={data}
                          channelId={channelId}
                          type={type}
                          codecId={codecId}
                          streamId={streamConfig?.stream?.outputId}
                          isChannelStarted={isChannelStarted}
                        />
                      )}
                      {newestChannelCodecsStats && (
                        <StatisticsView data={convertNodeStatsFormat(newestChannelCodecsStats)} />
                      )}
                    </>
                  ),
                };
              })}
            />
          </Card>
        ),
      };
    });
  });

  return (
    <Card>
      <Tabs items={tabItems.flat()} />
    </Card>
  );
};

export default StatisticsCodecs;
