import { bindActionCreators } from "redux";
import moment from "moment";
import dayjs from "dayjs";

import store from "../store";
import {
  LOAD_MORE_LOG_DATA,
  NEW_LOG_DATA,
  DELETE_LOGS,
  SET_LOG_LEVEL,
  SET_LOGS_PARAMS,
  SET_LOADING_LOGS,
} from "../ducks/logs";
import { CLEAR_ALL_STATISTIC_HIST } from "../ducks/nodeStatistics";
import { CLEAR_ALL_STATISTIC_LIVE, CLEAR_STATISTIC, SET_LIVE_STATISTIC } from "../ducks/nodeLiveStats";

import { actions as NODE_CHANNELS_ACTIONS } from "../ducks/nodeChannels";
import { actions as NODE_ACTIONS } from "../ducks/node";
import { actions as NODE_STATS_V5 } from "../ducks/nodeStatsV5";
import { actions as LOADING_ACTIONS } from "../ducks/loadingData";

import CloudChannelsServices from "./cloudChannels";

import { PlatformAPI } from "../lib/api";
import getMessageFromErrorCode from "../lib/utils/getMessageFromErrorCode";

const interval = {};

const actions = bindActionCreators(
  {
    connectFingerprintToChannel:
      (data, { errorNotification }) =>
      async () => {
        try {
          await PlatformAPI.connectFingerprintToChannel(data, { errorNotification });
          await CloudChannelsServices.getOwnChannels({ errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          throw new Error(`Error while connect fingerprint to cloud channel: ${error}`);
        }
      },
    connectFingerprintToPermission:
      (data, { errorNotification }) =>
      async () => {
        try {
          await PlatformAPI.connectFingerprintToPermission(data, { errorNotification });
          await CloudChannelsServices.getSharedChannels({ errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          throw new Error(`Error while connect fingerprint to cloud permission: ${error}`);
        }
      },
    updateChannelData:
      ({ oldCloudIds, oldPermissionId }, { errorNotification }) =>
      async () => {
        try {
          if (oldCloudIds && oldCloudIds.length > 0) {
            await PlatformAPI.removeFingerprintFromChannel({ oldCloudIds }, { errorNotification });
          }
          if (oldPermissionId) {
            await PlatformAPI.removeFingerprintFromPermission({ oldPermissionId }, { errorNotification });
          }
          await CloudChannelsServices.getOwnChannels({ errorNotification });
          await CloudChannelsServices.getSharedChannels({ errorNotification });
        } catch (error) {
          const errorMessage = getMessageFromErrorCode({ status: error.status, code: error.errorCode });
          errorNotification(errorMessage);
        }
      },
    updateChannel: (data) => (dispatch) => {
      dispatch(NODE_CHANNELS_ACTIONS.CHANGE_NODES_CHANNELS_STATUS(data));
    },
    updateChannelsList: (data) => (dispatch) => {
      const connectedNodeCWID = store.getState().getIn(["node", "cwid"]);
      const dataCWID = data.from && data.from.split("/")[1];

      if (connectedNodeCWID === dataCWID) {
        dispatch(NODE_CHANNELS_ACTIONS.SET_NODES_CHANNELS({ list: data?.list, cwid: connectedNodeCWID }));
        dispatch(LOADING_ACTIONS.CLEAR_LOADING());
      }
    },
    setNetworkInterfaces: (data) => (dispatch) => {
      dispatch(NODE_ACTIONS.SET_NETWORKINTERFACES(data));
      dispatch(LOADING_ACTIONS.CLEAR_LOADING());
    },
    setNodeLicenseList: (data) => (dispatch) => {
      dispatch(NODE_ACTIONS.SET_NODE_LICENSE_LIST(data));
      dispatch(LOADING_ACTIONS.CLEAR_LOADING());
    },
    setStundAddress: (data) => (dispatch) => {
      dispatch(NODE_ACTIONS.SET_STUND_ADDRESS(data));
      dispatch(LOADING_ACTIONS.CLEAR_LOADING());
    },
    setFingerprint: (data) => (dispatch) => {
      dispatch(NODE_ACTIONS.SET_NODE_FINGERPRINT(data));
      dispatch(LOADING_ACTIONS.CLEAR_LOADING());
    },
    setShortFingerprint: (data) => (dispatch) => {
      dispatch(NODE_ACTIONS.SET_SHORT_FINGERPRINT(data));
      dispatch(LOADING_ACTIONS.CLEAR_LOADING());
    },
    updateHistStatParamsV5:
      ({
        lc: channelStats,
        lco: codecsStats,
        li: inputStats,
        lis: inputStreamsStats,
        lo: outputsStats,
        los: outputStreamsStats,
        lou: networkStreamsStats,
        lsw: switcherStats,
        cwid,
      }) =>
      (dispatch) => {
        dispatch(
          NODE_STATS_V5.SET_STATS_PARAMS_V5({
            channelStats,
            codecsStats,
            inputStats,
            inputStreamsStats,
            outputsStats,
            outputStreamsStats,
            networkStreamsStats,
            switcherStats,
            cwid,
          })
        );
      },
    updateStatsDataV5:
      ({ timestampStart, values, resultsPeriodLen }) =>
      (dispatch) => {
        const parsedValues = values.map((value, index) => ({
          value,
          timestamp: dayjs
            .unix(timestampStart)
            .add(index * resultsPeriodLen, "second")
            .format("YYYY-MM-DD HH:mm:ss"),
        }));

        dispatch(NODE_STATS_V5.SET_STATS_DATA_V5(parsedValues));
      },
    updateChannelStatisticV5:
      ({
        c: channelId,
        lc: channel,
        lco: codecs,
        li: inputs,
        lis: inputStreams,
        lo: outputs,
        los: outputStreams,
        lou: networkStreams,
        lsw: switcher,
        t: timestamp,
        from,
      }) =>
      (dispatch) => {
        const channelTime = channel.map((channelElement) => ({ ...channelElement, time: timestamp }));
        const codecsTime = codecs.map((codecElement) => ({ ...codecElement, time: timestamp }));
        const inputsTime = inputs.map((inputElement) => ({ ...inputElement, time: timestamp }));
        const inputStreamsTime = inputStreams.map((inputStreamElement) => ({ ...inputStreamElement, time: timestamp }));
        const mainInputTime = inputsTime.filter((inputElement) => inputElement.p1 === 1);
        const mainInputStream = inputStreamsTime.filter((inputElement) => inputElement.p1 === 1);
        const backupInputTime = inputsTime.filter((inputElement) => inputElement.p1 === 2);
        const backupInputStream = inputStreamsTime.filter((inputElement) => inputElement.p1 === 2);

        const outputsTime = outputs.map((outputElement) => ({ ...outputElement, time: timestamp }));
        const outputStreamsTime = outputStreams.map((outputStreamElement) => ({
          ...outputStreamElement,
          time: timestamp,
        }));
        const networkStreamsTime = networkStreams.map((networkStreamElement) => ({
          ...networkStreamElement,
          time: timestamp,
        }));
        const switcherTime = switcher.map((switcherElement) => ({ ...switcherElement, time: timestamp }));
        let counter = 1;

        const addEmptyLiveStats = () => {
          const mqttConnection = store.getState().getIn(["mqtt", "mqttConnection"]);
          if (!mqttConnection) {
            return;
          }

          const newTimestamp = moment
            .unix(timestamp)
            .add(30 * counter, "seconds")
            .unix();

          const parsedChannelTime = channel.map((element) => ({ ...element, v: null, time: newTimestamp }));
          const parsedCodecsTime = codecs.map((element) => ({ ...element, v: null, time: newTimestamp }));

          const parsedMainInputTime = mainInputTime.map((element) => ({ ...element, v: null, time: newTimestamp }));
          const parsedMainInputStream = mainInputStream.map((element) => ({
            ...element,
            v: null,
            time: newTimestamp,
          }));
          const parsedBackupInputTime = backupInputTime.map((element) => ({
            ...element,
            v: null,
            time: newTimestamp,
          }));
          const parsedBackupInputStream = backupInputStream.map((element) => ({
            ...element,
            v: null,
            time: newTimestamp,
          }));
          const parsedOutputsTime = outputsTime.map((element) => ({ ...element, v: null, time: newTimestamp }));

          const parsedOutputStreams = outputStreams.map((element) => ({ ...element, v: null, time: newTimestamp }));
          const parsedNetworkStreamsTime = networkStreamsTime.map((element) => ({
            ...element,
            v: null,
            time: newTimestamp,
          }));
          const parsedSwitcherTime = switcherTime.map((element) => ({
            ...element,
            v: null,
            time: newTimestamp,
          }));

          counter += 1;
          dispatch(
            SET_LIVE_STATISTIC({
              data: {
                channel: parsedChannelTime,
                codecs: parsedCodecsTime,
                mainInput: [...parsedMainInputTime, ...parsedMainInputStream],
                backupInput: [...parsedBackupInputTime, ...parsedBackupInputStream],
                outputs: parsedOutputsTime,
                outputStreams: parsedOutputStreams,
                networkStreams: parsedNetworkStreamsTime,
                switcher: parsedSwitcherTime,
              },
              channelId,
              from,
            })
          );
        };

        if (!interval[`${from}-${channelId}`]) {
          interval[`${from}-${channelId}`] = setInterval(addEmptyLiveStats, 35000);
        } else {
          clearInterval(interval[`${from}-${channelId}`]);
          interval[`${from}-${channelId}`] = setInterval(addEmptyLiveStats, 35000);
        }

        dispatch(
          SET_LIVE_STATISTIC({
            data: {
              channel: channelTime,
              codecs: codecsTime,
              mainInput: [...mainInputTime, ...mainInputStream],
              backupInput: [...backupInputTime, ...backupInputStream],
              outputs: outputsTime,
              outputStreams: outputStreamsTime,
              networkStreams: networkStreamsTime,
              switcher: switcherTime,
            },
            channelId,
            from,
          })
        );
      },
    updateChannelStatistic:
      ({
        c: channelId,
        lc: channel,
        lco: codecs,
        li: input,
        lmn: muxedNonUrl,
        lmu: muxedUrl,
        lnm: nonMuxed,
        t: timestamp,
        from,
      }) =>
      (dispatch) => {
        const channelTime = channel.map((channelElement) => ({ ...channelElement, time: timestamp }));
        const codecsTime = codecs.map((codecElement) => ({ ...codecElement, time: timestamp }));
        const inputTime = input.map((inputElement) => ({ ...inputElement, time: timestamp }));
        const muxedNonUrlTime = muxedNonUrl.map((muxedNonUrlElement) => ({ ...muxedNonUrlElement, time: timestamp }));
        const muxedUrlTime = muxedUrl.map((muxedUrlElement) => ({ ...muxedUrlElement, time: timestamp }));
        const nonMuxedTime = nonMuxed.map((nonMuxedElement) => ({ ...nonMuxedElement, time: timestamp }));

        let counter = 1;

        const addEmptyLiveStats = () => {
          const mqttConnection = store.getState().getIn(["mqtt", "mqttConnection"]);
          if (!mqttConnection) {
            return;
          }

          const newTimestamp = moment
            .unix(timestamp)
            .add(30 * counter, "seconds")
            .unix();

          const parsedChannelTime = channel.map((element) => ({ ...element, v: null, time: newTimestamp }));
          const parsedCodecsTime = codecs.map((element) => ({ ...element, v: null, time: newTimestamp }));
          const parsedInputTime = input.map((element) => ({ ...element, v: null, time: newTimestamp }));
          const parsedMuxedNonUrl = muxedNonUrl.map((element) => ({ ...element, v: null, time: newTimestamp }));
          const parsedMuxedUrl = muxedUrl.map((element) => ({
            ...element,
            v: null,
            time: newTimestamp,
          }));
          const parsedNonMuxedTime = nonMuxed.map((element) => ({
            ...element,
            v: null,
            time: newTimestamp,
          }));

          counter += 1;
          dispatch(
            SET_LIVE_STATISTIC({
              data: {
                channel: parsedChannelTime,
                codecs: parsedCodecsTime,
                input: parsedInputTime,
                muxedNonUrl: parsedMuxedNonUrl,
                muxedUrl: parsedMuxedUrl,
                nonMuxed: parsedNonMuxedTime,
              },
              channelId,
              from,
            })
          );
        };

        if (!interval[`${from}-${channelId}`]) {
          interval[`${from}-${channelId}`] = setInterval(addEmptyLiveStats, 35000);
        } else {
          clearInterval(interval[`${from}-${channelId}`]);
          interval[`${from}-${channelId}`] = setInterval(addEmptyLiveStats, 35000);
        }

        dispatch(
          SET_LIVE_STATISTIC({
            data: {
              channel: channelTime,
              codecs: codecsTime,
              input: inputTime,
              muxedNonUrl: muxedNonUrlTime,
              muxedUrl: muxedUrlTime,
              nonMuxed: nonMuxedTime,
            },
            channelId,
            from,
          })
        );
      },
    clearStatistic:
      ({ channelId, from }) =>
      (dispatch) => {
        dispatch(CLEAR_STATISTIC({ channelId, from }));
      },
    loadMoreLogData:
      ({ logs, channelId }) =>
      (dispatch) => {
        dispatch(LOAD_MORE_LOG_DATA({ logs, channelId }));
        dispatch(SET_LOADING_LOGS({ loading: false }));
      },
    setLogParams:
      ({ sourceIds }) =>
      (dispatch) => {
        dispatch(SET_LOGS_PARAMS({ sourceIds }));
      },
    deleteLogs: () => (dispatch) => {
      dispatch(DELETE_LOGS());
    },
    clearAllStatistic: () => (dispatch) => {
      dispatch(CLEAR_ALL_STATISTIC_HIST());
      dispatch(CLEAR_ALL_STATISTIC_LIVE());
    },
    newLogData: (data) => (dispatch) => {
      dispatch(NEW_LOG_DATA(data));
    },
    updateNodeCapabilities: (data) => (dispatch) => {
      dispatch(NODE_ACTIONS.SET_NODE_CAPABILITIES(data));
    },
    updateLogLevel: (data) => (dispatch) => {
      dispatch(SET_LOG_LEVEL({ ...data }));
    },
    deleteChannel:
      ({ cloudData }, { errorNotification }) =>
      async () => {
        try {
          if (cloudData.hasPermissionId) {
            await PlatformAPI.removeFingerprintFromPermission(
              { oldPermissionId: cloudData.hasPermissionId },
              { errorNotification }
            );
          }
          if (cloudData.hasCloudId) {
            await PlatformAPI.removeFingerprintFromChannel({ oldCloudId: cloudData.hasCloudId }, { errorNotification });
          }
        } catch (error) {
          const errorMessage = getMessageFromErrorCode("platformConnectionError");
          errorNotification(errorMessage);
        }
      },
  },
  store.dispatch
);

export default actions;
