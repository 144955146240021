import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Badge, Divider, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { selectors as NODE_DEVICES_SELECTORS } from "../../../../ducks/nodeDevices";

import themeColor from "../../../../lib/style/theme";
import { OUTPUTS_TYPES, GLOBAL_VIDEO_RESIZE } from "../../../../lib/utils/constants";
import formatLabels from "../../../../lib/utils/formatLabels";

import IncompleteConfig from "../../../IncompleteConfig";
import DestinationsUrlTooltip from "../../../DestinationsUrlTooltip";

const { Ribbon } = Badge;

function EnginePlayoutOutputElement({ handleClick, label, outputData, incomplete, type }) {
  const numberOfDestinations = outputData && outputData.urlDestinations ? outputData?.urlDestinations.length : 0;
  const noIncomplete = !incomplete || (incomplete && incomplete.length === 0);
  const isOutputNdiType = type === OUTPUTS_TYPES.outputNdi.value;
  const isOutputUrlType = type === OUTPUTS_TYPES.outputUrl.value;
  const isOutputRtmpType = type === OUTPUTS_TYPES.outputRtmp.value;
  const isOutputSDIType = type === OUTPUTS_TYPES.outputDecklink.value;
  const decklinkOutputs = useSelector(NODE_DEVICES_SELECTORS.getDecklinkOutputs);
  const parsedDecklinkOutputDevice = (decklinkOutputs &&
    decklinkOutputs.find((output) => output.device === outputData?.device)) || {
    desc: outputData?.device,
  };

  const parsedOutputFormat =
    outputData?.format && outputData?.format in formatLabels ? formatLabels[outputData?.format] : outputData.format;
  const parsedVideoResize =
    GLOBAL_VIDEO_RESIZE.find((resize) => resize.value === outputData?.resize) || outputData?.resize;

  return (
    <Ribbon
      text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
      color={noIncomplete ? themeColor.green : themeColor.orange}
    >
      <StyledContainer onClick={handleClick}>
        <StyledAsideType>{label}</StyledAsideType>
        <StyledBoldText>{outputData?.name}</StyledBoldText>
        {isOutputSDIType && (
          <>
            <div>
              <StyledText>
                <FormattedMessage id="EnginePlayoutOutputElement.name" defaultMessage="Name" />:
              </StyledText>
              <StyledBoldText>{outputData?.name}</StyledBoldText>
            </div>
            <div>
              <StyledText>
                <FormattedMessage id="EnginePlayoutOutputElement.device" defaultMessage="Device" />:
              </StyledText>
              <StyledBoldText>{parsedDecklinkOutputDevice?.desc}</StyledBoldText>
            </div>
            <div>
              <StyledText>
                <FormattedMessage id="EnginePlayoutOutputElement.format" defaultMessage="Format" />:
              </StyledText>
              <StyledBoldText>{parsedOutputFormat}</StyledBoldText>
              <Divider type="vertical" />
              <StyledText>
                <FormattedMessage id="EnginePlayoutOutputElement.resize" defaultMessage="Resize" />:
              </StyledText>
              <StyledBoldText>{parsedVideoResize ? parsedVideoResize.label : outputData?.videoResize}</StyledBoldText>
              <Divider type="vertical" />
              <StyledText>
                <FormattedMessage id="EnginePlayoutOutputElement.audioChannels" defaultMessage="Audio channels" />:
              </StyledText>
              <StyledBoldText>{outputData?.audioChannels}</StyledBoldText>
            </div>
          </>
        )}
        {isOutputRtmpType && (
          <>
            <div>
              <StyledText>
                <FormattedMessage id="EnginePlayoutOutputElement.url" defaultMessage="Url" />:
              </StyledText>
              <StyledBoldText>{outputData?.url}</StyledBoldText>
            </div>

            {outputData?.key && (
              <div>
                <StyledText>
                  <FormattedMessage
                    id="EnginePlayoutOutputElement.streamNameOrKey"
                    defaultMessage="Stream name or Key"
                  />
                  :
                </StyledText>
                <StyledBoldText>{outputData?.key}</StyledBoldText>
              </div>
            )}
          </>
        )}
        {isOutputNdiType && (
          <div>
            <StyledText>
              <FormattedMessage id="EnginePlayoutOutputElement.stream" defaultMessage="Stream" />:
            </StyledText>
            <StyledBoldText>{outputData?.streamName}</StyledBoldText>
          </div>
        )}
        {isOutputUrlType && (
          <Tooltip
            placement="top"
            overlayStyle={{ minWidth: 800 }}
            title={<DestinationsUrlTooltip destinations={outputData.urlDestinations} />}
          >
            <div>
              <StyledText>
                <FormattedMessage id="EnginePlayoutOutputElement.destinations" defaultMessage="Destinations" />{" "}
                <QuestionCircleFilled />:
              </StyledText>
              <StyledBoldText>
                <Badge count={numberOfDestinations} showZero style={{ backgroundColor: themeColor.orange }} />
              </StyledBoldText>
            </div>
          </Tooltip>
        )}
      </StyledContainer>
    </Ribbon>
  );
}

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 105px;
  margin: 0 0 15px 30px;
  padding: 10px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  color: #fff;
  padding: 5px;
  line-height: 24px;
  position: absolute;
  top: 104px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 105px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;

EnginePlayoutOutputElement.propTypes = {
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.element.isRequired,
  outputData: PropTypes.object,
  type: PropTypes.string,
  incomplete: PropTypes.arrayOf(PropTypes.number),
};

EnginePlayoutOutputElement.defaultProps = {
  outputData: null,
  incomplete: null,
  type: null,
};

export default EnginePlayoutOutputElement;
