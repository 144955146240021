import React, { useState, useContext } from "react";
import { Card, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import FileSourceFormItem from "./FileSourceFormItem";

import { FormV5Context } from "../../NodeChannelFormV5";

import useNodeChannelConfig from "../../../../hooks/useNodeChannelConfig";

import EnginePlayoutElement from "../../../EnginePlayoutElement";

export default function FileSourceSection({ disabled, handleFinish }) {
  const [open, setOpen] = useState(false);
  const form = useContext(FormV5Context);

  const { channelConfig, fileSource } = useNodeChannelConfig();

  const { resetFields, getFieldValue } = form;

  const handleSaveFileSource = () => {
    const formData = getFieldValue("fileSource");
    handleFinish({ ...channelConfig, fileSource: formData });
    setOpen(false);
  };

  return (
    <Card title={<FormattedMessage id="FileSourceSection.fileSource" defaultMessage="File source" />}>
      <EnginePlayoutElement
        label={<FormattedMessage id="FileSourceSection.source" defaultMessage="Source" />}
        handleClick={() => setOpen(true)}
        data={fileSource}
      />
      <Modal
        title={<FormattedMessage id="FileSourceSection.fileSource" defaultMessage="File source" />}
        centered
        destroyOnClose
        open={open}
        onCancel={() => {
          resetFields();
          setOpen(false);
        }}
        onOk={handleSaveFileSource}
        width={1280}
        footer={disabled ? null : undefined}
        okText={
          <span>
            <FormattedMessage id="general.save" defaultMessage="Save" />
          </span>
        }
        okButtonProps={{ icon: <SaveOutlined /> }}
      >
        <FileSourceFormItem prefix={["fileSource"]} disabled={disabled} />
      </Modal>
    </Card>
  );
}

FileSourceSection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleFinish: PropTypes.func.isRequired,
};
