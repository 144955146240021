import React, { useEffect, useState } from "react";
import { Statistic, Row, Col, Card, Badge, Tooltip } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { QuestionCircleFilled } from "@ant-design/icons";

import LicenseServices from "../../services/license";
import MQTTService from "../../services/mqtt";
import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";

import { errorNotification } from "../../lib/utils/notification";
import themeColor from "../../lib/style/theme";
import { MESSAGE_TYPE, SELECT_NODE_TYPES } from "../../lib/utils/constants";

import LicensesListCloudNetworkOutput from "./LicensesListCloudNetworkOutput";
import LicenseListUserNodes from "../LicenseListUserNodes";

const translations = defineMessages({
  unlimited: {
    id: "LicensesListCloud.unlimited",
    defaultMessage: "Unlimited",
  },
});

export default function LicensesListCloud() {
  const { formatMessage } = useIntl();
  const [cloudLicensesUsage, setCloudLicensesUsage] = useState({});
  const cloudNodeLicenses = useSelector(MQTT_SELECTORS.getCloudNodeLicenses) || {};

  const cloudNodes = useSelector(MQTT_SELECTORS.getCloudNodes);
  const { nodesMedium, nodesLarge, nodesXLarge, nodes2XLarge, nodesTrial } = cloudNodeLicenses;

  const usedTrialNodes = cloudNodes.filter((node) => node.instanceType === SELECT_NODE_TYPES[0].value).length;
  const usedMediumNodes = cloudNodes.filter((node) => node.instanceType === SELECT_NODE_TYPES[1].value).length;
  const usedLargeNodes = cloudNodes.filter((node) => node.instanceType === SELECT_NODE_TYPES[2].value).length;
  const usedXLargeNodes = cloudNodes.filter((node) => node.instanceType === SELECT_NODE_TYPES[3].value).length;
  const used2XLargeNodes = cloudNodes.filter((node) => node.instanceType === SELECT_NODE_TYPES[4].value).length;

  useEffect(async () => {
    const cloudLicenseUsage = await LicenseServices.getUserCloudLicenseUsage({ errorNotification });
    setCloudLicensesUsage(cloudLicenseUsage);

    MQTTService.sendMessage({
      topic: `platform/in`,
      message: {
        msgType: MESSAGE_TYPE.CLOUD_NODE_LICENSES,
      },
    });
  }, []);

  const parsedLimit = (value) => {
    if (value === -1) {
      return formatMessage(translations.unlimited);
    }

    return value;
  };

  return (
    <Badge.Ribbon
      text={
        <span>
          <FormattedMessage id="LicensesListCloud.softQuota" defaultMessage="Soft quota" />
          {`: ${cloudLicensesUsage?.softQuota}`}
        </span>
      }
    >
      <Card
        title={
          <Tooltip
            placement="right"
            title={
              <FormattedMessage
                id="LicensesListCloud.channelsTitle"
                defaultMessage="Maximum usage of cloud licenses parameters
                (data taken from running channels within the current day) - each day data counted again"
              />
            }
          >
            <FormattedMessage id="LicensesListCloud.channels" defaultMessage="Channels" /> <QuestionCircleFilled />
          </Tooltip>
        }
      >
        <Row gutter={24}>
          {/* <Col span={4}>
            <StyledStatistic
              title={<FormattedMessage id="LicensesListCloud.inputs" defaultMessage="Inputs" />}
              value={cloudLicensesUsage?.inputsCount}
              suffix={`/ ${parsedLimit(cloudLicensesUsage?.licenseInputsCount)}`}
            />
          </Col> */}
          <Col span={4}>
            <StyledStatistic
              title={
                <Tooltip
                  placement="topLeft"
                  title={
                    <FormattedMessage
                      id="LicensesListCloud.outputsTooltip"
                      defaultMessage="Number of outputs used in running channels on your nodes"
                    />
                  }
                >
                  <FormattedMessage id="LicensesListCloud.outputs" defaultMessage="Outputs" /> <QuestionCircleFilled />
                </Tooltip>
              }
              value={cloudLicensesUsage?.outputsCount}
              suffix={`/ ${parsedLimit(cloudLicensesUsage?.licenseOutputsCount)}`}
            />
          </Col>
          {/* <Col span={4}>
            <StyledStatistic
              title={<FormattedMessage id="LicensesListCloud.codecX265" defaultMessage="AAC Codec" />}
              value={cloudLicensesUsage?.codecAAC}
              suffix={`/ ${parsedLimit(cloudLicensesUsage?.licenseCodecAAC)}`}
            />
          </Col> */}
          <Col span={4}>
            <StyledStatistic
              title={
                <Tooltip
                  placement="topLeft"
                  title={
                    <FormattedMessage
                      id="LicensesListCloud.codecH264Tooltip"
                      defaultMessage="Number of encoded H264 outputs used in running channels on your nodes"
                    />
                  }
                >
                  <FormattedMessage id="LicensesListCloud.codecH264" defaultMessage="H264 Codec" />{" "}
                  <QuestionCircleFilled />
                </Tooltip>
              }
              value={cloudLicensesUsage?.codecX264}
              suffix={`/ ${parsedLimit(cloudLicensesUsage?.licenseCodecX264)}`}
            />
          </Col>
          {/* <Col span={4}>
            <StyledStatistic
              title={<FormattedMessage id="LicensesListCloud.codecX265" defaultMessage="X265 Codec" />}
              value={cloudLicensesUsage?.codecX265}
              suffix={`/ ${parsedLimit(cloudLicensesUsage?.licenseCodecX265)}`}
            />
          </Col>
          <Col span={4}>
            <StyledStatistic
              title={<FormattedMessage id="LicensesListCloud.quickstream" defaultMessage="Quickstream" />}
              value={cloudLicensesUsage?.outputsQuickstream}
              suffix={`/ ${parsedLimit(cloudLicensesUsage?.licenseOutputsQuickstream)}`}
            />
          </Col> */}
        </Row>
      </Card>

      <StyledCard title={<LicensesListCloudNetworkOutput />}>
        <Row gutter={24}>
          <Col span={4}>
            <StyledStatistic
              title={<FormattedMessage id="LicensesListCloud.trial" defaultMessage="Trial" />}
              value={usedTrialNodes}
              suffix={`/ ${nodesTrial}`}
            />
          </Col>
          <Col span={4}>
            <StyledStatistic
              title={<FormattedMessage id="LicensesListCloud.medium" defaultMessage="Medium" />}
              value={usedMediumNodes}
              suffix={`/ ${nodesMedium}`}
            />
          </Col>
          <Col span={4}>
            <StyledStatistic
              title={<FormattedMessage id="LicensesListCloud.large" defaultMessage="Large" />}
              value={usedLargeNodes}
              suffix={`/ ${nodesLarge}`}
            />
          </Col>
          <Col span={4}>
            <StyledStatistic
              title={<FormattedMessage id="LicensesListCloud.xLarge" defaultMessage="XLarge" />}
              value={usedXLargeNodes}
              suffix={`/ ${nodesXLarge}`}
            />
          </Col>
          <Col span={4}>
            <StyledStatistic
              title={<FormattedMessage id="LicensesListCloud.2xLarge" defaultMessage="2XLarge" />}
              value={used2XLargeNodes}
              suffix={`/ ${nodes2XLarge}`}
            />
          </Col>
        </Row>
      </StyledCard>

      <LicenseListUserNodes />
    </Badge.Ribbon>
  );
}

const StyledCard = styled(Card)`
  margin-top: 15px;
`;

const StyledStatistic = styled(Statistic)`
  .ant-statistic-content-value {
    color: ${themeColor.orange};
  }
`;
