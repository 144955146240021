import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import themeColor from "../../lib/style/theme";

function CloudLicensesListElement({ usage, usageLimit, userList }) {
  if (usage === usageLimit && usageLimit !== -1) {
    return (
      <StyledWarningElement>
        <b>{usage}</b> {!userList && <>({usageLimit})</>}
      </StyledWarningElement>
    );
  }

  if (usage > usageLimit && usageLimit !== -1) {
    return (
      <StyledErrorElement>
        <b>{usage}</b> {!userList && <>({usageLimit})</>}
      </StyledErrorElement>
    );
  }

  return (
    <span>
      {usage} {!userList && <>({usageLimit})</>}
    </span>
  );
}

const StyledErrorElement = styled.span`
  color: ${themeColor.red};
`;

const StyledWarningElement = styled.span`
  color: ${themeColor.defaultBlueColor};
`;

CloudLicensesListElement.propTypes = {
  usage: PropTypes.number.isRequired,
  usageLimit: PropTypes.number.isRequired,
  userList: PropTypes.bool,
};

CloudLicensesListElement.defaultProps = {
  userList: false,
};

export default CloudLicensesListElement;
