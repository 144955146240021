import React from "react";
import styled from "styled-components";
import { Card, Statistic, Col, Row, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { QuestionCircleFilled } from "@ant-design/icons";

import { MQTT_STATUS } from "../../lib/utils/constants";
import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";
import themeColor from "../../lib/style/theme";

export default function LicenseListUserNodes() {
  const userNodes = useSelector(MQTT_SELECTORS.getUserNodes);
  const totalUserNodes = userNodes && userNodes.length;

  const userNodesOnline = userNodes.filter((node) => node.online === MQTT_STATUS.CONNECTED);
  const userNodesOffline = userNodes.filter((node) => node.online === MQTT_STATUS.DISCONNECTED);

  return (
    <StyledCard
      title={
        <>
          <Tooltip
            placement="topLeft"
            title={<FormattedMessage id="LicensesListCloud.numberOfUserNodes" defaultMessage="Number of user nodes" />}
          >
            <FormattedMessage id="LicenseListUserNodes.userNodes" defaultMessage="User Nodes" />{" "}
            <QuestionCircleFilled />
            {" : "}
          </Tooltip>

          <StyledLicenseListUserNodes>{totalUserNodes}</StyledLicenseListUserNodes>
        </>
      }
    >
      <Row gutter={24}>
        <Col span={4}>
          <StyledStatistic
            title={<FormattedMessage id="LicenseListUserNodes.online" defaultMessage="Online" />}
            value={userNodesOnline.length}
          />
        </Col>
        <Col span={4}>
          <StyledStatistic
            title={<FormattedMessage id="LicenseListUserNodes.offline" defaultMessage="Offline" />}
            value={userNodesOffline.length}
          />
        </Col>
      </Row>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  margin-top: 15px;
`;

const StyledLicenseListUserNodes = styled.span`
  color: ${themeColor.maritime};
`;

const StyledStatistic = styled(Statistic)`
  .ant-statistic-content-value {
    color: ${themeColor.orange};
  }
`;
