import { createAction, handleActions } from "redux-actions";
import { Map, removeIn } from "immutable";
import moment from "moment";

export const CLEAR_STATISTIC = createAction("STATISTIC/CLEAR_STATISTIC");
export const CLEAR_ALL_STATISTIC_HIST = createAction("STATISTICS/CLEAR_ALL_STATISTIC_HIST");
export const SET_HIST_STATS_IN_MIN = createAction("STATISTICS/HISTORICAL/IN_MIN");
export const SET_HIST_STATS_OUT_MIN = createAction("STATISTICS/HISTORICAL/OUT_MIN");
export const SET_HIST_STATS_IN = createAction("STATISTICS/HISTORICAL/IN");
export const SET_HIST_STATS_OUT = createAction("STATISTICS/HISTORICAL/OUT");
export const CLEAR_HIST_STATS_IN = createAction("STATISTICS/CLEAR/HISTORICAL/IN");
export const SET_HIST_OUT_PERMISSION_IDS = createAction("STATISTICS/HISTORICAL/PERMISSION_IDS");
export const CLEAR_HIST_STATISTIC = createAction("STATISTICS/HISTORICAL/CLEAR_DATA");

const defaultState = new Map({
  historicalData: {
    statInMin: moment().unix(),
    statOutMin: moment().unix(),
    statIn: [],
    statOut: [],
    permissionIds: [],
  },
});

const reducer = handleActions(
  {
    [SET_HIST_STATS_IN_MIN]: (state, { payload }) => {
      const oldState = state.get("historicalData");

      return state.merge({ historicalData: { ...oldState, statInMin: payload } });
    },
    [SET_HIST_STATS_OUT_MIN]: (state, { payload }) => {
      const oldState = state.get("historicalData");

      return state.merge({ historicalData: { ...oldState, statOutMin: payload } });
    },
    [SET_HIST_STATS_IN]: (state, { payload }) => {
      const oldState = state.get("historicalData");

      return state.merge({ historicalData: { ...oldState, statIn: payload } });
    },
    [SET_HIST_STATS_OUT]: (state, { payload }) => {
      const oldState = state.get("historicalData");

      return state.merge({ historicalData: { ...oldState, statOut: payload } });
    },
    [CLEAR_HIST_STATS_IN]: (state) => {
      const oldState = state.get("historicalData");

      return state.merge({ historicalData: { ...oldState, statIn: [] } });
    },
    [SET_HIST_OUT_PERMISSION_IDS]: (state, { payload }) => {
      const oldState = state.get("historicalData");

      return state.merge({ historicalData: { ...oldState, permissionIds: payload } });
    },
    [CLEAR_STATISTIC]: (state, { payload: { channelId, from } }) => removeIn(state, ["liveData", `${from}`, channelId]),
    [CLEAR_HIST_STATISTIC]: (state) => {
      const oldState = state.get("historicalData");

      return state.merge({ historicalData: { ...oldState, statIn: [], statOut: [] } });
    },
    [CLEAR_ALL_STATISTIC_HIST]: () => {
      return defaultState;
    },
  },
  defaultState
);

export const selectors = {
  getStatistics: ({ state, ownProps }) => {
    const { cwid } = ownProps;

    return state.getIn(["nodeStatistics", "liveData", `${cwid}`]);
  },
  getStatisticInMin: (state) => state.getIn(["nodeStatistics", "historicalData", "statInMin"]),
  getStatisticOutMin: (state) => state.getIn(["nodeStatistics", "historicalData", "statOutMin"]),
  getStatisticIn: (state) => state.getIn(["nodeStatistics", "historicalData", "statIn"]),
  getStatisticOut: (state) => state.getIn(["nodeStatistics", "historicalData", "statOut"]),
  getStatisticPermissionIds: (state) => state.getIn(["nodeStatistics", "historicalData", "permissionIds"]),
};

export default reducer;
