import React, { useState, useCallback } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Affix, Form } from "antd";
import { FormattedMessage } from "react-intl";

import NewChannelFormModal from "./NewChannelFormModal";

const CreateChannelModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentCloudId, setCurrentCloudId] = useState(null);
  const [form] = Form.useForm();

  const handleShowModal = useCallback(() => {
    setShowModal(!showModal);
    setCurrentStep(0);
    setCurrentCloudId(null);
  }, [showModal]);

  return (
    <>
      <Affix offsetTop={15}>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleShowModal}>
          <span>
            <FormattedMessage id="CreateChannelModal.createHubChannel" defaultMessage="Create hub channel" />
          </span>
        </Button>
      </Affix>
      <Modal
        destroyOnClose
        width={900}
        title={<FormattedMessage id="CreateChannelModal.newHubChannel" defaultMessage="New hub channel" />}
        open={showModal}
        footer={null}
        onCancel={handleShowModal}
      >
        <NewChannelFormModal
          handleShowModal={handleShowModal}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          form={form}
          currentCloudId={currentCloudId}
          setCurrentCloudId={setCurrentCloudId}
        />
      </Modal>
    </>
  );
};

export default CreateChannelModal;
