import {
  SELECT_STREAM_CODEC_AUDIO,
  MPEG2AUDIO_BITRATE,
  MPEG2AUDIO_SAMPLE_FORMAT,
  MPEG2AUDIO_SAMPLE_RATE,
  STREAMS_TYPE,
  SELECT_DEFAULT_AUTO,
  AAC_PROFILE,
} from "../constants";

export const DEFAULT_VIDEO_STREAM = {
  type: STREAMS_TYPE[0].value,
  convert: false,
  pid: 0,
  videoCodec: {
    interlaced: 0,
    changeGOP: false,
    bf: 4,
    g: 15,
    type: "X264",
    x264Level: "automatic",
    x264Preset: "veryfast",
    x264Profile: "high",
    x264Tune: "film",
    x264VbvBufsize: 4,
  },
};

export const DEFAULT_PLAYOUT_AUDIO_STREAM = {
  pid: 0,
  b: 256,
  type: "Aac",
  aacProfile: "aac_he",
  audioStreamSelector: 0,
  channels: 2,
  langCode: "eng",
  sampleFormat: "S16",
  sampleRate: "48000",
};

export const DEFAULT_AUDIO_STREAM = {
  type: "audio",
  pid: 0,
  audioCodec: {
    b: 256,
    type: "Aac",
    aacProfile: "aac_he",
  },
  convert: false,
  audioStreamSelector: 0,
};

export const RTMP_AUDIO_STREAM = {
  autoPid: true,
  stream: {
    selectStream: {
      streamId: SELECT_DEFAULT_AUTO.value,
    },
    type: STREAMS_TYPE[1].value,
    filters: [],
    codec: {
      type: SELECT_STREAM_CODEC_AUDIO[2].value,
      b: MPEG2AUDIO_BITRATE[9].value,
      sampleFormat: MPEG2AUDIO_SAMPLE_FORMAT[0].value,
      sampleRate: MPEG2AUDIO_SAMPLE_RATE[0].value,
      aacProfile: AAC_PROFILE[0].value,
    },
  },
};
