import React, { useState } from "react";
import { Card, Button, Badge, Popconfirm } from "antd";
import { SettingOutlined, MutedOutlined, OrderedListOutlined, DeleteOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import store from "../../store";

import MQTTService from "../../services/mqtt";
import { actions as NAVIGATION_ACTIONS } from "../../ducks/navigation";
import { DELETE_LOGS } from "../../ducks/logs";
import { CLEAR_HIST_STATISTIC } from "../../ducks/nodeStatistics";
import { CLEAR_DEVICES_LIST } from "../../ducks/nodeDevices";
import { CLEAR_FORMATS } from "../../ducks/nodeFormats";
import { actions as NODE_ACTIONS } from "../../ducks/node";
import { actions as NODE_CHANNELS_ACTIONS } from "../../ducks/nodeChannels";
import { getNodeMutedSelector } from "../../ducks/nodeAlerts";

import { MESSAGE_TYPE } from "../../lib/utils/constants";

import NodeCardMutedModal from "./NodeCardMutedModal";
import NodeAlerts from "../NodeAlerts";

export default function NodeCardAlertsElement({ nodeData }) {
  const mutatedChannels = useSelector((state) => getNodeMutedSelector(state, { cwid: nodeData?.cwid }));
  const numberOfMutatedChannels = (mutatedChannels && mutatedChannels.length) || 0;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleNodeChannels = ({ key }) => {
    store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    store.dispatch(NAVIGATION_ACTIONS.SET_CHANNELS_ACTIVE_TAB({ channelsActiveTab: "channelList" }));
    store.dispatch(NAVIGATION_ACTIONS.SET_ACTIVE_KEY({ activeKey: [key] }));
    store.dispatch(DELETE_LOGS());
    store.dispatch(CLEAR_HIST_STATISTIC());
    store.dispatch(NODE_CHANNELS_ACTIONS.CLEAR_NODES_CHANNELS());
    store.dispatch(CLEAR_DEVICES_LIST());
    store.dispatch(CLEAR_FORMATS());
  };

  const handleNodeSystem = ({ key }) => {
    store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    store.dispatch(NAVIGATION_ACTIONS.SET_ACTIVE_KEY({ activeKey: [key] }));
  };

  const handleClearAlerts = (cwid) => {
    MQTTService.sendMessageV5({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.RESET_NODE_ALERTS,
      },
    });
  };

  const handleShowModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Card
        actions={[
          <Button
            type="link"
            onClick={() =>
              handleNodeChannels({
                key: `/node/${nodeData.cwid}/${nodeData.cnn}/channels?tab=channelList`,
              })
            }
          >
            <Link
              key={`/node/${nodeData.cwid}/${nodeData.cnn}/channels?tab=channelList`}
              to={`/node/${nodeData.cwid}/${nodeData.cnn}/channels?tab=channelList`}
            >
              <>
                <OrderedListOutlined /> <FormattedMessage id="NodeCard.channels" defaultMessage="Channels" />
              </>
            </Link>
          </Button>,
          <Button
            type="link"
            onClick={() =>
              handleNodeSystem({
                key: `/node/${nodeData.cwid}/${nodeData.cnn}/system`,
              })
            }
          >
            <Link
              key={`/node/${nodeData.cwid}/${nodeData.cnn}/system`}
              to={`/node/${nodeData.cwid}/${nodeData.cnn}/system`}
            >
              <>
                <SettingOutlined /> <FormattedMessage id="NodeCard.system" defaultMessage="System" />
              </>
            </Link>
          </Button>,
          <Badge count={numberOfMutatedChannels} style={{ color: "#FFF" }}>
            <Button type="link" onClick={handleShowModal}>
              <Link key="/" to="/">
                <>
                  <MutedOutlined /> <FormattedMessage id="NodeCard.muted" defaultMessage="Muted" />
                </>
              </Link>
            </Button>
          </Badge>,
          <Popconfirm
            placement="top"
            title={
              <FormattedMessage
                id="NodeCard.deleteAlertsConfirmation"
                defaultMessage="Are you sure you want to delete alerts in this node?"
              />
            }
            okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
            cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
            onConfirm={() => handleClearAlerts(nodeData.cwid)}
          >
            <StyledButton type="link" danger>
              <>
                <DeleteOutlined /> <FormattedMessage id="NodeCard.deleteAlerts" defaultMessage="Delete alerts" />
              </>
            </StyledButton>
          </Popconfirm>,
        ]}
      >
        <NodeAlerts cwid={nodeData?.cwid} cnn={nodeData?.cnn} />
      </Card>
      {isModalOpen && (
        <NodeCardMutedModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          cwid={nodeData?.cwid}
          cnn={nodeData?.cnn}
        />
      )}
    </>
  );
}

const StyledButton = styled(Button)`
  span {
    &:hover {
      cursor: pointer !important;
    }
  }
`;

NodeCardAlertsElement.propTypes = {
  nodeData: PropTypes.shape({
    cnn: PropTypes.string.isRequired,
    cwid: PropTypes.string.isRequired,
    creator: PropTypes.number.isRequired,
    encryptedFingerprint: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    instanceType: PropTypes.string,
    instanceRegion: PropTypes.string,
    instanceId: PropTypes.string,
    owner: PropTypes.string.isRequired,
    cloudStatus: PropTypes.string.isRequired,
    online: PropTypes.string.isRequired,
    nodeVersion: PropTypes.string.isRequired,
  }).isRequired,
};
