import React from "react";
import styled from "styled-components";
import { Col, Popconfirm, Switch, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";

import store from "../../store";

import { selectors as ACCOUNT_SELECTORS, actions as ACCOUNT_ACTIONS } from "../../ducks/account";
import AccountService from "../../services/account";

import globalTranslation from "../../lib/translations";
import { errorNotification, successNotification } from "../../lib/utils/notification";

export default function Auth2fa() {
  const { formatMessage } = useIntl();
  const auth2fa = !!useSelector(ACCOUNT_SELECTORS.getAuth2FA);

  const onChangeAuth2FA = async () => {
    const auth2faSuccess = await AccountService.editAuth2FA(
      { auth2fa: !auth2fa },
      {
        errorNotification: errorNotification(formatMessage),
        successNotification: successNotification(formatMessage),
      }
    );

    if (auth2faSuccess) {
      store.dispatch(ACCOUNT_ACTIONS.SET_AUTH2FA(!auth2fa));
    }
  };

  return (
    <StyledCenterCol span={24}>
      <StyledBold>
        <FormattedMessage id="Auth2fa.auth2FA" defaultMessage="2FA authentication" />:{" "}
      </StyledBold>
      <Popconfirm
        placement="top"
        title={
          <FormattedMessage
            id="Auth2fa.auth2FAConfirm"
            defaultMessage="Are you sure you want to change two factor authentication?"
          />
        }
        onConfirm={onChangeAuth2FA}
        okText={formatMessage(globalTranslation.yes)}
        cancelText={formatMessage(globalTranslation.no)}
      >
        <Switch
          checkedChildren={<FormattedMessage id="general.on" defaultMessage="On" />}
          unCheckedChildren={<FormattedMessage id="general.off" defaultMessage="Off" />}
          checked={auth2fa}
        />{" "}
      </Popconfirm>
      <StyledTooltip
        placement="top"
        title={
          <>
            <FormattedMessage
              id="Auth2fa.auth2FADescription"
              // eslint-disable-next-line max-len
              defaultMessage="To improve security, enable two factor authentication (2FA). It requires you to confirm your identity using two different methods. After entering your login and password, we'll send a login link to your email. Use this link to access yor Quickstream Cloud"
            />
            .
          </>
        }
      >
        <QuestionCircleFilled style={{ fontSize: "18px" }} />
      </StyledTooltip>
    </StyledCenterCol>
  );
}

const StyledTooltip = styled(Tooltip)`
  margin: 0 5px;
`;

const StyledBold = styled.b`
  min-width: 100px;
  display: inline-block;
  margin-right: 15px;
`;

const StyledCenterCol = styled(Col)`
  margin: 5px 0px;
  display: flex;
  align-items: center;
`;
