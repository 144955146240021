import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Table, Tag, Divider, Popconfirm, Button } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import AdminService from "../../../services/admin";
import { selectors as ADMIN_SELECTORS } from "../../../ducks/admin";

import { errorNotification, successNotification } from "../../../lib/utils/notification";
import globalTranslation from "../../../lib/translations";
import roleColors from "../../../lib/utils/roleColors";

import CreateAccountModal from "../../../components/CreateAccountModal";
import GlobalErrorBoundaries from "../../../components/ErrorBoundaries/GlobalErrorBoundaries";

const translations = defineMessages({
  deleteAccountQuestionTitle: {
    id: "AccountsList.deleteAccountQuestionTitle",
    defaultMessage: "Are you sure you want to delete this account?",
  },
  deleteAccountQuestionInfo: {
    id: "AccountsList.deleteAccountQuestionInfo",
    defaultMessage: "Deleting an account results in irreversible loss of all data related to this account",
  },
  deleteAccountQuestionInfo2: {
    id: "AccountsList.deleteAccountQuestionInfo2",
    defaultMessage: "(e.g. licenses, shared channels and nodes in Quickstream Cloud)",
  },
});

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: (currentValue, nextValue) => currentValue.id - nextValue.id,
  },
  {
    title: <FormattedMessage id="AccountsList.username" defaultMessage="Username" />,
    dataIndex: "username",
    key: "username",
    sorter: (currentValue, nextValue) => currentValue.username.localeCompare(nextValue.username),
    render: (username) => {
      return <Link to={`/users/${username}`}>{username}</Link>;
    },
  },
  {
    title: <FormattedMessage id="AccountsList.email" defaultMessage="Email" />,
    dataIndex: "email",
    key: "email",
  },
  {
    title: <FormattedMessage id="AccountsList.role" defaultMessage="Role" />,
    dataIndex: "role",
    key: "role",
    sorter: (currentValue, nextValue) => currentValue.role.localeCompare(nextValue.role),
    render: (role, record) => (
      <Tag color={roleColors(role)} key={record.id}>
        {role.toUpperCase()}
      </Tag>
    ),
  },
  {
    title: <FormattedMessage id="AccountsList.createdAt" defaultMessage="Created At" />,
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: (currentValue, nextValue) => currentValue?.createdAt?.localeCompare(nextValue?.createdAt),
  },
  {
    title: <FormattedMessage id="AccountsList.action" defaultMessage="Action" />,
    key: "action",
    render: (action, record) => <ActionButtons username={record.username} />,
  },
];

const ActionButtons = ({ username }) => {
  const { formatMessage } = useIntl();

  const onConfirmRemove = useCallback(async () => {
    await AdminService.deleteAccount(username, {
      errorNotification: errorNotification(formatMessage),
      successNotification: successNotification(formatMessage),
    });
    await AdminService.getAccountList({ errorNotification: errorNotification(formatMessage) });
  }, [username, formatMessage]);

  return (
    <Popconfirm
      placement="top"
      title={
        <>
          <div>
            <b>{formatMessage(translations.deleteAccountQuestionTitle)}</b>
          </div>
          <div>{formatMessage(translations.deleteAccountQuestionInfo)}</div>
          <div>{formatMessage(translations.deleteAccountQuestionInfo2)}</div>
        </>
      }
      onConfirm={onConfirmRemove}
      okText={formatMessage(globalTranslation.yes)}
      cancelText={formatMessage(globalTranslation.no)}
    >
      <Button type="primary" danger onClick={() => null}>
        {formatMessage(globalTranslation.delete)}
      </Button>
    </Popconfirm>
  );
};

ActionButtons.propTypes = {
  username: PropTypes.string.isRequired,
};

const AccountsList = ({ accountList }) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    AdminService.getAccountList({ errorNotification: errorNotification(formatMessage) });
  }, [formatMessage]);

  return (
    <GlobalErrorBoundaries>
      <>
        <Table
          columns={columns}
          dataSource={accountList}
          pagination={false}
          bordered
          role="table"
          rowKey={(record) => record.id}
          scroll={{ x: "calc(100vh - 250px)" }}
        />
        <Divider />
        <CreateAccountModal />
      </>
    </GlobalErrorBoundaries>
  );
};

AccountsList.propTypes = {
  accountList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        role: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
};

const mapStateToProps = (state) => ({
  accountList: ADMIN_SELECTORS.getAccountList(state),
});

export default connect(mapStateToProps, null)(AccountsList);
