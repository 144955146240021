import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Divider, Typography, Badge } from "antd";

import IncompleteConfig from "../IncompleteConfig";

import themeColor from "../../lib/style/theme";
import { SELECT_DEFAULT_AUTO, QUICKSTREAM_METHOD_V2 } from "../../lib/utils/constants";
import parseCloudId from "../../lib/utils/parseCloudId";
import useMainTheme from "../../hooks/useMainTheme";

const { Paragraph } = Typography;
const { Ribbon } = Badge;

const StreamSourceElement = ({
  cloudId,
  connectionType,
  destinationAddress,
  destinationPort,
  handleClick,
  interfaceIp,
  ip,
  port,
  label,
  title,
  url,
  localPort,
  incomplete,
  keepDays,
  fileTimeSpan,
}) => {
  const { isDarkTheme } = useMainTheme();
  const isQSMethod = connectionType === QUICKSTREAM_METHOD_V2.outQSDirect.value;
  const parsedInterfaceIp =
    interfaceIp && interfaceIp.toLowerCase() === SELECT_DEFAULT_AUTO.value ? SELECT_DEFAULT_AUTO.label : interfaceIp;
  const noIncomplete = !incomplete || (incomplete && incomplete.length === 0);

  const isNetworkInterfaceAutomatic = parsedInterfaceIp === SELECT_DEFAULT_AUTO.label;

  return (
    <Ribbon
      text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
      color={noIncomplete ? themeColor.green : themeColor.orange}
    >
      <StyledContainer onClick={handleClick} $isDarkTheme={isDarkTheme}>
        <StyledAsideType>{label}</StyledAsideType>
        <StyledBoldText $isDarkTheme={isDarkTheme}>{title}</StyledBoldText>
        <div>
          {(destinationAddress || ip) && (
            <>
              <StyledText $isDarkTheme={isDarkTheme}>
                <FormattedMessage id="general.ip" defaultMessage="IP" />:
              </StyledText>
              {destinationAddress && (
                <StyledBoldText $isDarkTheme={isDarkTheme}>
                  <StyledParagraph
                    onClick={(event) => event.stopPropagation()}
                    copyable={{
                      tooltips: [
                        <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                        <FormattedMessage id="general.copied" defaultMessage="Copied" />,
                      ],
                    }}
                  >
                    {destinationAddress}
                  </StyledParagraph>
                </StyledBoldText>
              )}
              {ip && (
                <StyledBoldText $isDarkTheme={isDarkTheme}>
                  <StyledParagraph
                    onClick={(event) => event.stopPropagation()}
                    copyable={{
                      tooltips: [
                        <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                        <FormattedMessage id="general.copied" defaultMessage="Copied" />,
                      ],
                    }}
                  >
                    {ip}
                  </StyledParagraph>
                </StyledBoldText>
              )}
              <Divider type="vertical" />
            </>
          )}
          {!isQSMethod && (destinationPort || port) && (
            <>
              <StyledText $isDarkTheme={isDarkTheme}>
                <FormattedMessage id="general.port" defaultMessage="Port" />:
              </StyledText>
              {destinationPort && (
                <StyledBoldText $isDarkTheme={isDarkTheme}>
                  <StyledParagraph
                    onClick={(event) => event.stopPropagation()}
                    copyable={{
                      tooltips: [
                        <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                        <FormattedMessage id="general.copied" defaultMessage="Copied" />,
                      ],
                    }}
                  >
                    {destinationPort}
                  </StyledParagraph>
                </StyledBoldText>
              )}
              {port && (
                <StyledBoldText $isDarkTheme={isDarkTheme}>
                  <StyledParagraph
                    onClick={(event) => event.stopPropagation()}
                    copyable={{
                      tooltips: [
                        <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                        <FormattedMessage id="general.copied" defaultMessage="Copied" />,
                      ],
                    }}
                  >
                    {port}
                  </StyledParagraph>
                </StyledBoldText>
              )}
            </>
          )}
        </div>
        {isQSMethod && (
          <div>
            <StyledText $isDarkTheme={isDarkTheme}>
              <FormattedMessage id="StreamSourceElement.cloudId" defaultMessage="Cloud ID" />:
            </StyledText>
            {cloudId && <StyledBoldText $isDarkTheme={isDarkTheme}>{parseCloudId(cloudId)}</StyledBoldText>}
          </div>
        )}
        <div>
          {(localPort || localPort === 0) && (
            <>
              <StyledText $isDarkTheme={isDarkTheme}>
                <FormattedMessage id="general.localPort" defaultMessage="Local port" />:
              </StyledText>
              {(localPort || localPort === 0) && (
                <StyledBoldText $isDarkTheme={isDarkTheme}>
                  <StyledParagraph
                    onClick={(event) => event.stopPropagation()}
                    copyable={{
                      tooltips: [
                        <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                        <FormattedMessage id="general.copied" defaultMessage="Copied" />,
                      ],
                    }}
                  >
                    {localPort}
                  </StyledParagraph>
                </StyledBoldText>
              )}
            </>
          )}
        </div>
        {parsedInterfaceIp && (
          <div>
            <StyledText $isDarkTheme={isDarkTheme}>
              <FormattedMessage id="StreamSourceElement.networkInterface" defaultMessage="Network Interface" />:
            </StyledText>
            <StyledBoldText $isDarkTheme={isDarkTheme}>
              {isNetworkInterfaceAutomatic && parsedInterfaceIp}
              {!isNetworkInterfaceAutomatic && (
                <StyledParagraph
                  onClick={(event) => event.stopPropagation()}
                  copyable={{
                    tooltips: [
                      <FormattedMessage id="general.copy" defaultMessage="Copy" />,
                      <FormattedMessage id="general.copied" defaultMessage="Copied" />,
                    ],
                  }}
                >
                  {parsedInterfaceIp}
                </StyledParagraph>
              )}
            </StyledBoldText>
          </div>
        )}
        {url && (
          <>
            <StyledText $isDarkTheme={isDarkTheme}>
              <FormattedMessage id="general.url" defaultMessage="Url" />:
            </StyledText>
            <StyledBoldText $isDarkTheme={isDarkTheme}>{url}</StyledBoldText>
          </>
        )}
        {keepDays && fileTimeSpan && (
          <div>
            <StyledText $isDarkTheme={isDarkTheme}>
              <FormattedMessage id="StreamSourceElement.keepDays" defaultMessage="Keep days" />:
            </StyledText>
            <StyledBoldText $isDarkTheme={isDarkTheme}>{keepDays}</StyledBoldText>
          </div>
        )}
        {fileTimeSpan && (
          <div>
            <StyledText $isDarkTheme={isDarkTheme}>
              <FormattedMessage id="StreamSourceElement.fileTimeSpan" defaultMessage="File time span" />:
            </StyledText>
            <StyledBoldText $isDarkTheme={isDarkTheme}>{fileTimeSpan}</StyledBoldText>
          </div>
        )}
      </StyledContainer>
    </Ribbon>
  );
};

const StyledText = styled.span`
  margin-right: 5px;
  color: ${(props) => (props.$isDarkTheme ? "white" : "black")};
`;

const StyledBoldText = styled.span`
  font-weight: bold;
  color: ${(props) => (props.$isDarkTheme ? "white" : "black")};
`;

const StyledParagraph = styled(Paragraph)`
  display: inherit;

  & .anticon-copy {
    color: ${themeColor.orange};
  }
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;
  margin: 0 0 15px 30px;
  border: 1px solid ${themeColor.grayBorder};
  background: ${(props) => (props.$isDarkTheme ? themeColor.defaultDarkBGColor : themeColor.lightCardBackground)};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  color: #fff;
  border-bottom: 1px solid ${themeColor.grayBorder};
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  padding: 5px 0;
  line-height: 24px;
  position: absolute;
  top: 130px;
  left: 0px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 130px;
  text-align: center;
  border-radius: 2px;
`;

StreamSourceElement.propTypes = {
  connectionType: PropTypes.string,
  destinationAddress: PropTypes.string,
  destinationPort: PropTypes.number,
  handleClick: PropTypes.func.isRequired,
  interfaceIp: PropTypes.string,
  ip: PropTypes.string,
  label: PropTypes.string.isRequired,
  port: PropTypes.number,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  localPort: PropTypes.number,
  incomplete: PropTypes.arrayOf(PropTypes.number),
  keepDays: PropTypes.number,
  fileTimeSpan: PropTypes.number,
};

StreamSourceElement.defaultProps = {
  connectionType: null,
  destinationAddress: null,
  destinationPort: null,
  interfaceIp: null,
  ip: null,
  port: null,
  url: null,
  localPort: null,
  incomplete: null,
  keepDays: null,
  fileTimeSpan: null,
};

export default StreamSourceElement;
