import React from "react";
import { Col, Divider } from "antd";
import { useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { INPUT_URL_TYPE } from "../../lib/utils/constants";
import { max32characters, az09space, required } from "../../lib/utils/formValidation";

import { selectors as CHANNEL_SELECTORS } from "../../ducks/nodeChannels";

import globalTranslations from "../../lib/translations";
import InputTextFiled from "../Fields/InputTextField";
import InputNumberField from "../Fields/InputNumberField";
import NDISelectStream from "../NdiSelectStream";
import NDIVideoFormat from "../NDIVideoFormat";
import NDIAudioFormats from "../NDIAudioFormats";

const translations = defineMessages({
  streamName: {
    id: "OutputNDIFormItem.streamName",
    defaultMessage: "Stream name",
  },
  audioStream: {
    id: "OutputNDIFormItem.audioStream",
    defaultMessage: "Audio stream",
  },
  videoStream: {
    id: "OutputNDIFormItem.videoStream",
    defaultMessage: "Video stream",
  },
  nameAlreadyExist: {
    id: "OutputNDIFormItem.nameAlreadyExist",
    defaultMessage: "This output name already exist",
  },
});

const OutputNDIFormItem = ({ prefix, detailView, form, channelId, initialOutputName }) => {
  const { formatMessage } = useIntl();
  const { getFieldValue } = form;

  const nodeChannels = useSelector(CHANNEL_SELECTORS.getChannels);

  const {
    outputId: initialOutputId,
    audioStreamOutputId: initialAudioStreamOutputId,
    videoStreamOutputId: initialVideoStreamOutputId,
  } = getFieldValue(prefix);

  const inputType = getFieldValue(["input", "type"]);
  const isInputURLType = inputType === INPUT_URL_TYPE.value;

  const uniqueValidator = (rule, value) => {
    const outputNames = nodeChannels.getIn([`${channelId}`, "outputs", "outputsNames"]);

    const parsedOutputNames = outputNames && outputNames.filter((outName) => outName !== initialOutputName);
    const duplicatedMuxedOutputName = parsedOutputNames && parsedOutputNames.includes(value);

    if (duplicatedMuxedOutputName) {
      return Promise.reject(formatMessage(translations.nameAlreadyExist));
    }

    return Promise.resolve();
  };

  return (
    <>
      <Col span={12}>
        <InputTextFiled
          disabled={detailView}
          name={[...prefix, "name"]}
          label={formatMessage(globalTranslations.name)}
          rules={[{ validator: uniqueValidator }, max32characters, az09space, required]}
        />
      </Col>
      <Divider />
      <Col span={12}>
        <InputTextFiled
          disabled={detailView}
          name={[...prefix, "streamName"]}
          label={formatMessage(translations.streamName)}
          rules={[max32characters]}
        />
      </Col>

      {isInputURLType ? (
        <>
          <NDISelectStream
            disabled={detailView}
            prefix={[...prefix, "videoStream"]}
            form={form}
            label={formatMessage(translations.videoStream)}
          />
          <NDISelectStream
            disabled={detailView}
            prefix={[...prefix, "audioStream"]}
            form={form}
            label={formatMessage(translations.audioStream)}
          />
        </>
      ) : (
        <StyledHiddenCol>
          <NDISelectStream
            disabled={detailView}
            prefix={[...prefix, "videoStream"]}
            form={form}
            label={formatMessage(translations.videoStream)}
          />
          <NDISelectStream
            disabled={detailView}
            prefix={[...prefix, "audioStream"]}
            form={form}
            label={formatMessage(translations.audioStream)}
          />
        </StyledHiddenCol>
      )}
      <NDIVideoFormat prefix={[...prefix, "videoFormat"]} disabled={detailView} />
      <NDIAudioFormats prefix={[...prefix, "audioFormat"]} disabled={detailView} />

      {initialOutputId && (
        <StyledHiddenCol span={4}>
          <InputNumberField name={[...prefix, "outputId"]} label="outputId" disabled={detailView} />
        </StyledHiddenCol>
      )}
      {initialAudioStreamOutputId && (
        <StyledHiddenCol span={4}>
          <InputNumberField
            name={[...prefix, "audioStreamOutputId"]}
            label="audioStreamOutputId"
            disabled={detailView}
          />
        </StyledHiddenCol>
      )}
      {initialVideoStreamOutputId && (
        <StyledHiddenCol span={4}>
          <InputNumberField
            name={[...prefix, "videoStreamOutputId"]}
            label="videoStreamOutputId"
            disabled={detailView}
          />
        </StyledHiddenCol>
      )}
    </>
  );
};

const StyledHiddenCol = styled(Col)`
  visibility: hidden;
  display: none;
`;

OutputNDIFormItem.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  detailView: PropTypes.bool,
  form: PropTypes.object.isRequired,
  channelId: PropTypes.string.isRequired,
  initialOutputName: PropTypes.string,
};

OutputNDIFormItem.defaultProps = {
  detailView: false,
  initialOutputName: null,
};

export default OutputNDIFormItem;
