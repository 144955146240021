import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Tabs, Card } from "antd";
import { IdcardOutlined, UserSwitchOutlined } from "@ant-design/icons";

import OrganizationInformation from "../../components/OrganizationInformation";
import OrganizationsRolesList from "../../components/OrganizationsRolesList";
import { selectors as ORGANIZATION_SELECTORS } from "../../ducks/organizations";
import { errorNotification } from "../../lib/utils/notification";
import OrganizationsServices from "../../services/organizations";
import OrganizationStepIntroduction from "../../components/OrganizationStepIntroduction/OrganizationStepIntroduction";

const OrganizationPage = () => {
  const { formatMessage } = useIntl();
  // eslint-disable-next-line no-unused-vars
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    OrganizationsServices.getOrganizations({
      errorNotification: errorNotification(formatMessage),
      setLoadingData,
    });
  }, []);

  const organizationId = useSelector(ORGANIZATION_SELECTORS.getOrganizationId);

  const organizationItems = [
    {
      icon: <IdcardOutlined />,
      label: (
        <span>
          <FormattedMessage id="OrganizationPage.organization" defaultMessage="Organization" />
        </span>
      ),
      key: "detail",
      component: (
        <>
          {organizationId && <OrganizationInformation />}
          {!organizationId && <OrganizationStepIntroduction />}
        </>
      ),
    },
    {
      icon: <UserSwitchOutlined />,
      label: (
        <span>
          <FormattedMessage id="OrganizationPage.rolesVerification" defaultMessage="Roles verification" />
        </span>
      ),
      key: "roles",
      component: <OrganizationsRolesList />,
    },
  ];

  return (
    <Card>
      <Tabs
        defaultActiveKey="detail"
        items={organizationItems.map(({ icon, label, key, component }) => {
          return {
            icon,
            label,
            key,
            children: component,
          };
        })}
      />
    </Card>
  );
};

export default OrganizationPage;
