import React from "react";
import { Form } from "antd";
import PropTypes from "prop-types";

import GlobalAudioCodecFormItem from "./GlobalAudioCodecFormItem";

const { List } = Form;

function GlobalAudioCodecList({ prefix, disabled }) {
  return (
    <List name={[...prefix]}>
      {(fields) => (
        <>
          {fields.map((field, index) => (
            <GlobalAudioCodecFormItem key={`${field.name}`} disabled={disabled} prefix={[index]} />
          ))}
        </>
      )}
    </List>
  );
}

GlobalAudioCodecList.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

export default GlobalAudioCodecList;
